import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/MyRequestsMobUI.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import MyConnectionsUI from '../MyConnectionsUI';
import Switch from '@mui/material/Switch';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MyRequestsMobUI = () => {

//UseState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [toggle,setToggle] = useState(false);
const [ allStatus,setAllStatus] = useState("");
const [requests,setRequests]= useState([]);
const [selectedCommunity,setSelectedCommunity] = useState([])
const [communities,setCommunities] = useState([]);


 //UseEffect
 useEffect(()=>{
   
 
   if(user){
   
    // getStatus()
     getRequests()
    // getCommunities()
   }
},[user])
  
const ConnectionReq = (id,status)=>{  
  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
if(selectedCommunity==="undefined"){
  setSelectedCommunity(JSON.parse([]))
}
    let apiURL=process.env.REACT_APP_API_URL + "/connection/updateconnection/"+id;
    let apiParams = {
        method:"POST",
        headers:{
          status:status,
          user2selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        },
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        alert("Action Updated Successfully!!");
          
      //props.showAlert("Job Added Successfully","success")
         setTimeout(() => {
          // getStatus()
           getRequests()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
  
}


const getRequests = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getrequests/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          // console.log(repos.data)
          setRequests(repos.data)
          
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};


  return (
<div id="ReqqerMbrequest_mob">
	<svg class="ReqqerMbdesgin_con_page_new_pc">
		<rect id="ReqqerMbdesgin_con_page_new_pc" rx="0" ry="0" x="0" y="0" width="100%" height="203">
		</rect>
	</svg>	
	<div id="ReqqerMbScroll_Group_9">
	{(requests||[]).map((rs)=>(
		<div id="ReqqerMbcard_1_req_page_pc_inichan">
			<svg class="ReqqerMbover_all_card_erq_page_new_pc" onClick={()=>navigate("../"+(rs.user1Name===user.name ? rs.user2Image.nickName:rs.user1Image.nickName))}>
				<rect id="ReqqerMbover_all_card_erq_page_new_pc" rx="10" ry="10" x="0" y="0" width="166" height="287">
				</rect>
			</svg>
			<img id="ReqqerMbAnime_Edit_goku_thats_me" src={rs.user1Name===user.name ?  (rs.user2Image.imageUrl && rs.user2Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rs.user2Image.imageUrl.split('/')[5]):"./Peoples/DefaultImageCon.png":(rs.user1Image.imageUrl && rs.user1Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rs.user1Image.imageUrl.split('/')[5]):"./Peoples/DefaultImageCon.png"} />
				
			<div id="ReqqerMbdesignation_grp_req_page_new" onClick={()=>navigate("../"+(rs.user1Name===user.name ? rs.user2Image.nickName:rs.user1Image.nickName))}>
				<img id="ReqqerMbjob-seeker_img_erq_page_new_pc" src="./Peoples/job-seeker_img_erq_page_new_pc.png" srcset="./Peoples/job-seeker_img_erq_page_new_pc.png 1x" />
					
				<div id="ReqqerMberq_page_new_pc_job_name">
					<span>{rs.user1Name===user.name ?  rs.user2Image ?rs.user2Image.profession:"":rs.user1Image ? rs.user1Image.profession:""}</span>
				</div>
			</div>
			<div id="ReqqerMbMohammed_Affan_goku_v" onClick={()=>navigate("../"+(rs.user1Name===user.name ? rs.user2Image.nickName:rs.user1Image.nickName))}>
				<span>{rs.user1Name===user.name ? rs.user2Name:rs.user1Name}</span>
			</div>
			{user.name!=rs.user1Name ?
				<div title="Reject" id="ReqqerMbreject_erq_page_new_pc" onClick={(e) =>{ConnectionReq(rs._id,"Rejected")}}>
				<svg class="ReqqerMbreqj_erq_page_new_pc">
					<ellipse id="ReqqerMbreqj_erq_page_new_pc" rx="17" ry="17" cx="17" cy="17">
					</ellipse>
				</svg>
				<img id="ReqqerMbreject_img_erq_page_new_pc" src="reject_img_erq_page_new_pc.png" srcset="reject_img_erq_page_new_pc.png 1x" />
					
			</div>:""}
			{user.name==rs.user1Name ?  
			<div title="Cancel" id="ReqqerMbaccept_grp_erq_page_new_pc" onClick={(e) =>{ConnectionReq(rs._id,"Cancelled")}}>
				<svg class="ReqqerMbaccept_bdr_erq_page_new_pc">
					<ellipse id="ReqqerMbaccept_bdr_erq_page_new_pc" rx="17" ry="17" cx="17" cy="17">
					</ellipse>
				</svg>
				<img id="ReqqerMbaccept_img_erq_page_new_pc" src="./Peoples/reject_img_erq_page_new_pc.png" srcset="./Peoples/reject_img_erq_page_new_pc.png 1x" />
					
			</div>
			:
			<div title="Accept" id="ReqqerMbaccept_grp_erq_page_new_pc" onClick={(e) =>{ConnectionReq(rs._id,"Connected")}}>
				<svg class="ReqqerMbaccept_bdr_erq_page_new_pc">
					<ellipse id="ReqqerMbaccept_bdr_erq_page_new_pc" rx="17" ry="17" cx="17" cy="17">
					</ellipse>
				</svg>
				<img id="ReqqerMbaccept_img_erq_page_new_pc" src="accept_img_erq_page_new_pc.png" srcset="accept_img_erq_page_new_pc.png 1x" />
					
			</div> }
			{user.name!=rs.user1Name ?
			<div title="Ignore" id="ReqqerMbignore_grp_erq_page_new_pc" onClick={(e) =>{ConnectionReq(rs._id,"Ignored")}}>
				<svg class="ReqqerMbbg_ignore_erq_page_new_pc">
					<ellipse id="ReqqerMbbg_ignore_erq_page_new_pc" rx="17" ry="17" cx="17" cy="17">
					</ellipse>
				</svg>
				<img id="ReqqerMbignore_u_erq_page_new_pc" src="ignore_u_erq_page_new_pc.png" srcset="ignore_u_erq_page_new_pc.png 1x" />
					
			</div>:""}
			{user.name!=rs.user1Name ?
			<div title="Block" id="ReqqerMbblock_grp_erq_page_new_pc" onClick={(e) =>{ConnectionReq(rs._id,"Blocked")}}>
				<svg class="ReqqerMbblock_bg_erq_page_new_pc">
					<ellipse id="ReqqerMbblock_bg_erq_page_new_pc" rx="17" ry="17" cx="17" cy="17">
					</ellipse>
				</svg>
				<img id="ReqqerMbblock_img_erq_page_new_pc" src="block_img_erq_page_new_pc.png" srcset="block_img_erq_page_new_pc.png 1x" />
					
			</div>:""}
			<div id="ReqqerMbcompany_name_request_page_new_" onClick={()=>navigate("../"+(rs.user1Name===user.name ? rs.user2Image.nickName:rs.user1Image.nickName))}>
				<img id="ReqqerMboffice-building_req_page_new_p" src="./Peoples/office-building_req_page_new_p.png" srcset="./Peoples/office-building_req_page_new_p.png 1x" />
					
				<div id="ReqqerMbOP_conreqsug_erq_page_new_pc">
					<span>{rs.user1Name===user.name ?  rs.user2Image ?rs.user2Image.businessName:"":rs.user1Image ? rs.user1Image.businessName:""}</span>
				</div>
			</div>
			<marquee id="ReqqerMbmarquee_op_intro_person">
				<span>{rs.intro}</span>
			</marquee>
		</div>
		))}
	</div>
</div>

  )
}

export default MyRequestsMobUI
