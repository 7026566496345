import React from 'react';
import "../../../styles/PlantPlates.css";

function PlantPlates(props) {
  return (
    <div id="PlantPlateDelights">
      <div id="PPD_26_PCGroup_15086">
        <img id="PPD_26_PCMask_Group_1" src="./Themes/PlantPlates/Mask_Group_1.png" srcset="./Themes/PlantPlates/Mask_Group_1.png 1x" />
          
        <svg class="PPD_26_PCRectangle_3">
          <rect id="PPD_26_PCRectangle_3" rx="24.5" ry="24.5" x="0" y="0" width="177" height="49">
          </rect>
        </svg>
        <div id="PPD_26_PCOrder_Now">
          <span>Order Now</span>
        </div>
        <div id="PPD_26_PCHave_no_time_to_prepare_food">
          <span>Have no time<br/>to prepare</span><span style={{color:"rgba(103,123,200,1)"}}> food</span><span>?</span>
        </div>
        <div id="PPD_26_PCChoose_one_of_our_plans_enter_">
          <span>Choose one of our plans, enter delivery time<br/>and enjoy delicious food without leaving your home!</span>
        </div>
        <div id="PPD_26_PCFOODCOURT">
          <span>FOOD.</span><span style={{color:"rgba(103,123,200,1)"}}>COURT</span>
        </div>
      </div>
      <div id="PPD_26_PCGroup_15087">
        <img id="PPD_26_PCMask_Group_2" src="./Themes/PlantPlates/Mask_Group_2.png" srcset="./Themes/PlantPlates/Mask_Group_2.png 1x" />
          
        <div id="PPD_26_PCGroup_4">
          <svg class="PPD_26_PCRectangle_6">
            <rect id="PPD_26_PCRectangle_6" rx="25.5" ry="25.5" x="0" y="0" width="185" height="51">
            </rect>
          </svg>
          <div id="PPD_26_PCOrder_Now_bh">
            <span>Order Now</span>
          </div>
        </div>
        <div id="PPD_26_PCGroup_12">
          <svg class="PPD_26_PCLine_2" viewBox="0 0 200.907 7">
            <path id="PPD_26_PCLine_2" d="M 0 0 L 200.90673828125 0">
            </path>
          </svg>
          <div id="PPD_26_PCOUR_FOOD">
            <span>OUR FOOD</span>
          </div>
        </div>
        <div id="PPD_26_PCChoose_one_of_our_plans_enter__bl">
          <span>Choose one of our plans, enter delivery time<br/>and enjoy delicious food without leaving your home!</span>
        </div>
        <div id="PPD_26_PCChoose_one_of_our_plans_enter__bm">
          <span>Choose one of our plans, enter delivery time.</span>
        </div>
        <div id="PPD_26_PCChoose_one_of_our_plans_enter__bn">
          <span>Choose one of our plans, enter delivery time.</span>
        </div>
        <div id="PPD_26_PCGroup_9">
          <div id="PPD_26_PCAppetizers">
            <span>Appetizers:</span>
          </div>
          <div id="PPD_26_PCGroup_6">
            <svg class="PPD_26_PCEllipse_2">
              <ellipse id="PPD_26_PCEllipse_2" rx="23.056289672851562" ry="23.056289672851562" cx="23.056289672851562" cy="23.056289672851562">
              </ellipse>
            </svg>
            <div id="PPD_26_PCGroup_5">
              <svg class="PPD_26_PCLine_3" viewBox="0 0 17.238 4">
                <path id="PPD_26_PCLine_3" d="M 0 0 L 17.23811912536621 0">
                </path>
              </svg>
              <svg class="PPD_26_PCLine_4" viewBox="0 0 17.238 4">
                <path id="PPD_26_PCLine_4" d="M 0 0 L 17.23811912536621 0">
                </path>
              </svg>
            </div>
          </div>
        </div>
        <div id="PPD_26_PCGroup_10">
          <div id="PPD_26_PCMain_Courses">
            <span>Main Courses:</span>
          </div>
          <div id="PPD_26_PCGroup_7">
            <svg class="PPD_26_PCEllipse_2_by">
              <ellipse id="PPD_26_PCEllipse_2_by" rx="23.056289672851562" ry="23.056289672851562" cx="23.056289672851562" cy="23.056289672851562">
              </ellipse>
            </svg>
            <div id="PPD_26_PCGroup_5_bz">
              <svg class="PPD_26_PCLine_3_b" viewBox="0 0 17.238 4">
                <path id="PPD_26_PCLine_3_b" d="M 0 0 L 17.23811912536621 0">
                </path>
              </svg>
              <svg class="PPD_26_PCLine_4_b" viewBox="0 0 17.238 4">
                <path id="PPD_26_PCLine_4_b" d="M 0 0 L 17.23811912536621 0">
                </path>
              </svg>
            </div>
          </div>
        </div>
        <div id="PPD_26_PCGroup_11">
          <div id="PPD_26_PCDesserts">
            <span>Desserts:</span>
          </div>
          <div id="PPD_26_PCGroup_8">
            <svg class="PPD_26_PCEllipse_2_b">
              <ellipse id="PPD_26_PCEllipse_2_b" rx="23.056289672851562" ry="23.056289672851562" cx="23.056289672851562" cy="23.056289672851562">
              </ellipse>
            </svg>
            <div id="PPD_26_PCGroup_5_b">
              <svg class="PPD_26_PCLine_3_ca" viewBox="0 0 17.238 4">
                <path id="PPD_26_PCLine_3_ca" d="M 0 0 L 17.23811912536621 0">
                </path>
              </svg>
              <svg class="PPD_26_PCLine_4_ca" viewBox="0 0 17.238 4">
                <path id="PPD_26_PCLine_4_ca" d="M 0 0 L 17.23811912536621 0">
                </path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div id="PPD_26_PCGroup_15088">
        <svg class="PPD_26_PCPath_7778" viewBox="0 0 1361.056 600.745">
          <path id="PPD_26_PCPath_7778" d="M 0 600.7454833984375 C 0 600.7454833984375 346.0422058105469 479.9402160644531 686.30615234375 479.9402160644531 C 1026.5703125 479.9402160644531 1361.055786132812 600.7454833984375 1361.055786132812 600.7454833984375 L 1361.055786132812 0 L 0 0 L 0 600.7454833984375 Z">
          </path>
        </svg>
        <img id="PPD_26_PCMask_Group_7" src="./Themes/PlantPlates/Mask_Group_7.png" srcset="./Themes/PlantPlates/Mask_Group_7.png 1x" />
          
        <div id="PPD_26_PCGroup_13">
          <svg class="PPD_26_PCLine_2_cd" viewBox="0 0 93.939 7">
            <path id="PPD_26_PCLine_2_cd" d="M 0 0 L 93.93899536132812 0">
            </path>
          </svg>
          <div id="PPD_26_PCPLANS">
            <span>PLANS</span>
          </div>
        </div>
        <div id="PPD_26_PCScroll_Group_87">
          <div id="PPD_26_PCGroup_142">
            <svg class="PPD_26_PCRectangle_9">
              <rect id="PPD_26_PCRectangle_9" rx="50" ry="50" x="0" y="0" width="555.54" height="431.494">
              </rect>
            </svg>
            <img id="PPD_26_PCMask_Group_4" src="./Themes/PlantPlates/Mask_Group_4.png" srcset="./Themes/PlantPlates/Mask_Group_4.png 1x" />
              
            <svg class="PPD_26_PCRectangle_12">
              <rect id="PPD_26_PCRectangle_12" rx="50" ry="50" x="0" y="0" width="555.54" height="431.494">
              </rect>
            </svg>
            <div id="PPD_26_PCOrder_1">
              <span>Order 1</span>
            </div>
          </div>
          <div id="PPD_26_PCGroup_143">
            <svg class="PPD_26_PCRectangle_8">
              <rect id="PPD_26_PCRectangle_8" rx="50" ry="50" x="0" y="0" width="556.08" height="431.913">
              </rect>
            </svg>
            <img id="PPD_26_PCMask_Group_3" src="./Themes/PlantPlates/Mask_Group_3.png" srcset="./Themes/PlantPlates/Mask_Group_3.png 1x" />
              
            <svg class="PPD_26_PCvictoria-shes-4MEL9XS-3JQ-unsp">
              <rect id="PPD_26_PCvictoria-shes-4MEL9XS-3JQ-unsp" rx="50" ry="50" x="0" y="0" width="556.08" height="431.913">
              </rect>
            </svg>
            <div id="PPD_26_PCOrder_1_cp">
              <span>Order 1</span>
            </div>
          </div>
        </div>
      </div>
      <div id="PPD_26_PCGroup_15091">
        <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="PPD_26_PCText_Background">
          <rect id="PPD_26_PCText_Background" rx="0" ry="0" x="0" y="0" width="1366" height="335.64">
          </rect>
        </iframe>
        <div id="PPD_26_PCGroup_15089">
          <img id="PPD_26_PCsonny-mauricio-yhc4pSbl01A-uns" src="./Themes/PlantPlates/sonny-mauricio-yhc4pSbl01A-uns.png" srcset="./Themes/PlantPlates/sonny-mauricio-yhc4pSbl01A-uns.png 1x" />
            
          <svg class="PPD_26_PCsonny-mauricio-yhc4pSbl01A-uns_cu">
            <rect id="PPD_26_PCsonny-mauricio-yhc4pSbl01A-uns_cu" rx="0" ry="0" x="0" y="0" width="1366" height="287">
            </rect>
          </svg>
          <svg class="PPD_26_PCBorder" viewBox="0 -40 1366 0.909">
            <path id="PPD_26_PCBorder" d="M 0 -40 L 1366 -40 L 1366 -39.09079360961914 L 0 -39.09079360961914 L 0 -40 Z">
            </path>
          </svg>
          <div id="PPD_26_PCFollow_Us">
            <span>Follow Us</span>
          </div>
          <div id="PPD_26_PCn_Copyright_Operate_Live_OPC_P">
            <span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
          </div>
          <div id="PPD_26_PCn_234567890_mobile_number_shad">
            <span>{props.data.businessPhone}</span>
          </div>
          <div id="PPD_26_PCaffanghanioperatelive_email_sh">
            <span>{props.data.businessEmail}</span>
          </div>
          <div id="PPD_26_PCaddress_med_shadow_space_websi">
            <span>{props.data.businessAddress}</span>
          </div>
          <img id="PPD_26_PClocation_img_shadow_space_webs" src="./Themes/PlantPlates/location_img_shadow_space_webs.png" srcset="./Themes/PlantPlates/location_img_shadow_space_webs.png 1x" />
            
          <img id="PPD_26_PCemail_address_shadow_space_web" src="./Themes/PlantPlates/email_address_shadow_space_web.png" srcset="./Themes/PlantPlates/email_address_shadow_space_web.png 1x" />
            
          <img id="PPD_26_PCphone_number_shadow_space_webs" src="./Themes/PlantPlates/phone_number_shadow_space_webs.png" srcset="./Themes/PlantPlates/phone_number_shadow_space_webs.png 1x" />
            
          <div id="PPD_26_PCsocials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
            <div title="LinkedIn" id="PPD_26_PClinked_in_grp_shadow_space_web">
              <svg class="PPD_26_PCmed_shadow_space_website_7">
                <ellipse id="PPD_26_PCmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
              </svg>
              <div id="PPD_26_PClinked_in_shadow_space_website">
                <a href={props.data.linkedInUrl} target="_blank">
                <svg class="PPD_26_PCn_website_7_layout_" viewBox="-15.255 0 15.547 14.668">
                  <path id="PPD_26_PCn_website_7_layout_" d="M -5.526878833770752 3.814697038251325e-06 C -8.153567314147949 3.814697038251325e-06 -9.799471855163574 1.422712445259094 -10.10365676879883 2.419437646865845 L -10.10365676879883 0.1396603286266327 L -15.25499820709229 0.1396603286266327 C -15.1880407333374 1.350015878677368 -15.25499820709229 14.66775035858154 -15.25499820709229 14.66775035858154 L -10.10365676879883 14.66775035858154 L -10.10365676879883 6.806180477142334 C -10.10365676879883 6.369354248046875 -10.12470149993896 5.932529926300049 -9.992057800292969 5.618782520294189 C -9.637495994567871 4.745131969451904 -8.876718521118164 3.838321924209595 -7.508852481842041 3.838321924209595 C -5.720739364624023 3.838321924209595 -4.907035350799561 5.18195629119873 -4.907035350799561 7.149900913238525 L -4.907035350799561 14.66775035858154 L 0.2921361923217773 14.66775035858154 L 0.2921361923217773 6.589361190795898 C 0.2921361923217773 2.089746952056885 -2.268231630325317 3.814697038251325e-06 -5.526878833770752 3.814697038251325e-06">
                  </path>
                </svg>
              </a>
                <svg class="PPD_26_PClinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.585 14.529">
                  <path id="PPD_26_PClinked_in_i_shadow_space_websi" d="M 0.9309999942779541 -8.254270553588867 L 5.516065120697021 -8.254270553588867 L 5.516065120697021 -22.78300285339355 L 0.9309999942779541 -22.78300285339355 L 0.9309999942779541 -8.254270553588867 Z">
                  </path>
                </svg>
                <svg class="PPD_26_PCtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.566 5.041">
                  <path id="PPD_26_PCtop_dot_i_shadow_space_website" d="M -1.591219305992126 0 C -3.284313917160034 0 -4.392000198364258 1.087279200553894 -4.392000198364258 2.522743225097656 C -4.392000198364258 3.930147409439087 -3.316198587417603 5.041022300720215 -1.656264781951904 5.041022300720215 L -1.623104333877563 5.041022300720215 C 0.1005998626351357 5.041022300720215 1.17384946346283 3.926321506500244 1.17384946346283 2.5182785987854 C 1.140688419342041 1.084728360176086 0.1005998626351357 0 -1.591219305992126 0">
                  </path>
                </svg>
              </div>
            </div>:""}
			{props.data.instagramUrl?	
            <div title="Instagram" id="PPD_26_PCInsta_grp_shadow_space_website">
              <svg class="PPD_26_PCshadow_space_website_7">
                <ellipse id="PPD_26_PCshadow_space_website_7" rx="24.5" ry="24" cx="24.5" cy="24">
                </ellipse>
              </svg>
              <a href={props.data.instagramUrl} target="_blank">
              <img id="PPD_26_PCinstagramshadow_space_website_" src="./Themes/PlantPlates/instagramshadow_space_website_.png" srcset="./Themes/PlantPlates/instagramshadow_space_website_.png 1x" />
                
              </a>
            </div>:""}
			{props.data.whatsAppNumber?
            <div title="WhatsApp" id="PPD_26_PCwhatsapp_grp_shadow_space_webs">
              <svg class="PPD_26_PCmed_shadow_space_website_7_df">
                <ellipse id="PPD_26_PCmed_shadow_space_website_7_df" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
              </svg>
              <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="PPD_26_PCwhatsapp_lbl_shadow_space_webs" src="./Themes/PlantPlates/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/PlantPlates/whatsapp_lbl_shadow_space_webs.png 1x" />
                </a>
            </div>:""}
          </div>
        </div>
      </div>
      <div id="PPD_26_PCVISIT_US_">
        <span>VISIT US !!</span>
      </div>
    </div>
  )
}

export default PlantPlates