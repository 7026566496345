import React, { useState, useEffect, useContext } from "react";
import "../../styles/EditProfileMenuMobUI.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import EditGeneralTabMob from "./EditGeneralTabMob";
import EditLocationTabMob from "./EditLocationTabMob";
import EditBiilingTabMob from "./EditBiilingTabMob";



const EditProfileMenuMobUI = () => {

    //useState
    var navigate = useNavigate();
    const { user, setUser} = useContext(UserContext);
    const [img,setImg] = useState("");
    const [profile,setProfile]= useState("");
    const [mode,setMode] = useState("create");
    const [selectedTab,setSelectedTab] = useState("general");
    const [openMyComSlide,setOpenMyComSlide] = useState(false);
    const [userData,setUserData] = useState({});
    const [generalData,setGeneralData] = useState({});
    const [locationData,setLocationData] = useState({})

    useEffect(() => {
  
        if(user)
       
        getUserProfile();
           
         }, [user]);
       
         function arrayBufferToBase64(buffer) {
           var binary = "";
           var bytes = [].slice.call(new Uint8Array(buffer));
           bytes.forEach((b) => (binary += String.fromCharCode(b)));
           var base64Flag = "data:image/jpeg;base64,";
           return base64Flag + window.btoa(binary);
         }
       
       
     
       
         const handleImageUpload = async (e) => {
          //  if (e.target.files[0].size > 71680) {
          //    alert("Please choose a file with size below 70kb!");
          //    return;
          //  }
           handleFileSelect(e.target.files[0]);
           UploadImageToDrive(e.target.files[0])
          //  let formdata = new FormData();
          //  formdata.append("image", e.target.files[0], e.target.value);
          // updateProfilePic(formdata);
         };


         function handleFileSelect(f) {
          var reader = new FileReader();
          // Closure to capture the file information.
          reader.onload = (function (theFile) {
            return function (e) {
              var binaryData = e.target.result;
              //Converting Binary Data to base 64
              var base64String = window.btoa(binaryData);
              //showing file converted to base64
              setProfile("data:image/jpeg;base64," + base64String);
            };
          })(f);
          // Read in the image file as a data URL.
          reader.readAsBinaryString(f);
        }


         const updateProfilePic = (formdata) => {
           const apiurl = process.env.REACT_APP_API_URL + "/users/updateprofilepic/"+user._id;
           var apiparam = {
             method: "POST",
             // headers: {
             //   id: user._id,
             // },
             body:formdata,
           };
       
           fetch(apiurl, apiparam)
             .then((res) => (res.status === 200 ? res.json() : res.text()))
             .then((repos) => {
               if (repos.data) {
                 alert("Image Uploaded Successfully!!");
               } else alert(repos.message || repos);
             })
             .catch((err) => alert(err));
         };
       
         
         const getUserProfile = () => {
           let apiURL = process.env.REACT_APP_API_URL + "/users/getUserDetails/"+user._id;
           let apiParams = {
             method: "GET",
           };
           fetch(apiURL, apiParams)
             .then((res) => (res.status === 200 ? res.json() : res.text()))
             .then((repos) => {
               if (repos.data) {
                 //console.log(repos.data.imageUrl);
                 setUserData(repos.data);
                //  setAddress(repos.data.address);
                //  setDob(repos.data.dob.substring(0,10));
                //  setEmail(repos.data.email);
                //  setName(repos.data.name);
                //  setGender(repos.data.gender);
                //  setMobile(repos.data.phone);
                //  setCountry(repos.data.country);
                //  setState(repos.data.state);
                //  setCity(repos.data.city);
                //  setPincode(repos.data.pincode);
                //  setProfession(repos.data.profession);
                //  setIntroduction(repos.data.introduction);
                setImg(repos.data.imageUrl);
                  // setImg(arrayBufferToBase64(repos.data.image.data.data));
                //  setCurrency(repos.data.currency);
                //  setHourlyRate(repos.data.hourlyRate);
                //  setUpiId(repos.data.upiId);
                //  setVision(repos.data.vision);
                //  setMission(repos.data.mission);
                //  setPrinciples(repos.data.principles);
                 
               } else alert(repos.message || repos);
             })
             .catch((err) => alert(err));
         };
       
         const UpdateEmployeesDetails = (data) => {
         // console.log(data)
          if(data.name === undefined || (data.name).length === ""){
            return alert("Please Enter the Name !!")
          }
          if(data.mobile === undefined||(data.mobile).length === ""){
            return alert("Please Enter the Mobile No !!")
          }
          if(data.email === undefined||(data.email).length === ""){
            return alert("Please Enter the Email Address !!")
          }
          if(data.dob === undefined|| (data.dob).length === ""){
            return alert("Please Enter DOB !!")
          }
          if(data.country === undefined||(data.country).length === ""){
            return alert("Please Select your Country !!")
          }
          if(data.state === undefined ||(data.state).length === ""){
            return alert("Please Enter State !!")
          }
          if(data.city === undefined|| (data.city).length === ""){
            return alert("Please Enter City !!")
          }
         
            let apiURL = process.env.REACT_APP_API_URL + "/users/updateEmployees/" + user._id;
            let apiParams = {
              method: "POST",
              headers: {
                      name: data.name,
                      gender: data.gender||"",
                      dob: data.dob,
                      mobile: data.mobile,
                      email: data.email,
                      country: data.country,
                      state: data.state,
                      city: data.city,
                      address: data.address?encodeURIComponent(data.address):"",
                      pincode: data.pincode||"",
                      profession:data.profession||"",
                      introduction:data.introduction?encodeURIComponent(data.introduction):"",
                      currency:data.currency||"",
                      hourlyrate:data.hourlyRate||"",
                      upiid:data.upiId||"",
                      vision:data.vision?encodeURIComponent(data.vision):"",
                      mission:data.mission?encodeURIComponent(data.mission):"",
                      principles:data.principles?encodeURIComponent(data.principles):"",
                      maplatitude :data.mapLatitude||0,
                      maplongitude:data.mapLongitude||0,
                      businessname:data.businessName||"",
                      businessmobile:data.businessMobile||"",
                      businessemail:data.businessEmail||"",
                      businessaddress:data.businessAddress? encodeURIComponent(data.businessAddress):"",
                      businesswebsite:data.businessWebsite||"",
                      whatsappnumber:data.whatsAppNumber||"",
                      linkedinurl:data.linkedInUrl||"",
                      instagramurl:data.instagramUrl||"",
        
                // password: encodeURIComponent(password),
                // active: active,
              },
            };
            fetch(apiURL, apiParams)
              .then((res) => (res.status === 200 ? res.json() : res.text()))
              .then((repos) => {
                if (repos.data) {
                 
                 
                  alert("User Updated Successfully")
                    setTimeout(()=>{
                        navigate("/home")
                    },1000)
                }
              })
              .catch((err) => alert(err));
          };
      
           //Upload Image
    const UploadImageToDrive = async (document) =>{
     
      var file = document //the file
      var reader = new FileReader() //this for convert to Base64 
      reader.readAsDataURL(document) //start conversion...
      reader.onload = function (e) { //.. once finished..
        var rawLog = reader.result.split(',')[1]; //extract only thee file data part
        var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
       fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
          { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
          .then(res => res.json()).then((data) => {
            //See response
          
             SaveProfilePic(data)
             console.log(data.url)
             var loggedIn = JSON.parse(localStorage.getItem("loggedInUser"));
             loggedIn.imageId = (data.url).split('/')[5]
              localStorage.setItem("loggedInUser",JSON.stringify(loggedIn));
              setUser(loggedIn)
            // alert("Image Uploaded Successfully","success")
    
          }).catch(err => console.log(err)) // Or Error in console
      }
    }

    const SaveProfilePic = (data)=>{
    
      
      const apiurl = process.env.REACT_APP_API_URL + "/users/updateprofilepicnew/"+user._id;
              let apiParams = {
                  method:"POST",
                  headers:{
                    imageurl:data.url,
                  
                  }
            }
            fetch(apiurl, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                 
                  alert("Image Uploaded Successfully");
                }
              })
              .catch(e => console.log(e)) 
            }

      return (
    <div>
    <div id="general_mob_">
	<div id="edit_profile_general_mob">
    <label for="image">
                        <input id="image" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
                      {profile.length === 0 ?<img id="user_pfp_pop_lbl_profile_mob_1" width="100" height="100" src={user.imageId && user.imageId.length > 10?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+user.imageId:"user_pfp_pop_lbl_profile_mob.png"} style={{borderRadius:"75px",border:"1px solid black"}}/>:
                      <img id="user_pfp_pop_lbl_profile_mob_1" width="100" height="100" src={profile?profile:"user_pfp_pop_lbl_profile_mob.png"} style={{borderRadius:"75px",border:"1px solid black"}}/>}
                      </label>
	
			
		<div id="Edit_Profile_lbl_profile_mob_1">
			<span>Edit Profile</span>
		</div>

	</div>
    <img id="user_back_profile_mob_" src="back_mob.png.png" srcset="back_mob.png 1x" onClick={()=>navigate('/home')}/>
</div>
{
 selectedTab=== "general" ? <EditGeneralTabMob userData={userData} updateData={(data)=>setGeneralData(data)} selectedTab={(st)=>setSelectedTab(st)}/> :
 selectedTab=== "location" ? <EditLocationTabMob userData={userData} updateData={(data)=>setLocationData(data)} selectedTab={(st)=>setSelectedTab(st)}/>:
 selectedTab=== "billing" ? <EditBiilingTabMob userData={userData} updateData={(data)=>{UpdateEmployeesDetails({...generalData,...locationData,...data})}} selectedTab={(st)=>{setSelectedTab(st);getUserProfile()}}/> :""
}
        </div>
      )
    }
    
    export default EditProfileMenuMobUI
    