import React, { useEffect, useState, useContext} from 'react'
import "../../styles/CalanderAlarmsMob.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";

const CalanderAlarmsMob = (props) => {
useEffect(()=>{

},[props.data])

  return (
    <div id="mb_calender_page_Alarm_mob">

	<div id="mb_calender_page_Alarm_mob_grp">
		<svg class="mb_alarm_mob_calender">
			<rect id="mb_alarm_mob_calender" rx="22" ry="22" x="0" y="0" width="100%" height="764">
			</rect>
		</svg>
		<div id="mb_right_atlarm_mob_">
			<div id="mb_mobTasksalarm_lbl_" onClick={()=>props.selectedTab("tasks")}>
				<span>Tasks</span>
			</div>
			<div id="mb_Meetings_lbl_headingcalender_m" onClick={()=>props.selectedTab("meetings")}>
				<span>Meetings</span>
			</div>
			<div id="mb_Events_lbl_calender_mob" onClick={()=>props.selectedTab("events")}>
				<span>Events</span>
			</div>
			<div id="mb_alarm_mob_cal_mob">
				<div id="mb_mobAlarms_lbl_calender">
					<span>Alarms</span>
				</div>
				<svg class="mb_Line_29_alarm_mob" viewBox="0 0 1 42">
					<path id="mb_Line_29_alarm_mob" d="M 0 0 L 0 42">
					</path>
				</svg>
			</div>
		</div>
		<div id="mb_Scroll_alarm_mob_">
		{props.data.map((alrm)=>(
			<div id="mb_waking_up_all_grp_alarm_Mob">
				<div id="mb_Waking_Up_Early_lbl_calender_Mob">
					<span>{alrm.title}</span>
				</div>
				<div id="mb_n_00am_time_for_wake_up">
					<span>{alrm.startOn && new Date(alrm.startOn).getHours().toString().padStart(2,"0")+":"+new Date(alrm.startOn).getMinutes().toString().padStart(2,"0")}</span>
				</div>
				<svg class="mb_waking_up_early_line">
					<rect id="mb_waking_up_early_line" rx="0" ry="0" x="0" y="0" width="3.36" height="16.8">
					</rect>
				</svg>
				<div id="mb_n_1135am_time_for_snooze">
					<span> 11:35am</span>
				</div>
				<img id="mb_snooze_img_calender" src="./CalanderDashboard/snooze_img_calender.png" srcset="./CalanderDashboard/snooze_img_calender.png 1x" />
					
				<svg class="mb_green_ball">
					<ellipse id="mb_green_ball" rx="5.599999904632568" ry="5.599999904632568" cx="5.599999904632568" cy="5.599999904632568">
					</ellipse>
				</svg>
				<svg class="mb_arcane_top_line_calender_wa" viewBox="0 0 249.76 1">
					<path id="mb_arcane_top_line_calender_wa" d="M 0 0 L 249.7599945068359 0">
					</path>
				</svg>
			</div>
			))
}
		</div>
	</div>

</div>
  )
}

export default CalanderAlarmsMob
