import React, { useState, useEffect, useContext } from "react";
import { Button, Divider } from "@mui/material";
import "../../styles/AddTaskToCalander.css";
import Modal from "@mui/material/Modal";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";

const AddTaskToCalander = (props) => {

	const [searchParams] = useSearchParams();
    const { user, setUser } = useContext(UserContext);
    var navigate = useNavigate();
	const [taskLocation,setTaskLocation] = useState("");
	const [taskEndOn,setTaskEndOn] = useState(new Date().toISOString().substring(0,16));
	const [taskStartOn,setTaskStartOn] = useState(new Date().toISOString().substring(0,16));
	const [taskCategory,setTaskCategory] = useState("");
	const [taskDiscription,setTaskDiscription] = useState("");
	const [taskTitle,setTaskTitle] = useState("");
	

	useEffect(()=>{

		if(props.dateAndTime){
			setTaskStartOn(props.dateAndTime)
			setTaskEndOn(props.dateAndTime)
		}
		
		  },[props.dateAndTime])


	const addTask = () => {

        if(taskTitle.length === 0){
  
      return alert("Please Enter Task Title!!")
  
      }
  
      if(taskDiscription.length === undefined || taskDiscription.length === 0){
       
       return alert("Please Enter Task Description!!")
      }
      if(taskStartOn.length === undefined || taskStartOn.length ==="" ){
   
        return alert("Please select Task Start Details!!")
      }
      if(taskLocation.length === undefined || taskLocation.length ==="" ){
   
        return alert("Please Enter Task Location!!")
      }
  
     let apiURL = process.env.REACT_APP_API_URL + "/tasks/addtask";
     let apiParams = {
       method: "POST",
       headers: {

	   tasktitle:taskTitle,
       taskdiscription:encodeURIComponent(taskDiscription),
       taskstartson:taskStartOn,
       taskendson:taskEndOn,
	   taskcategory:taskCategory,
	   taskLocation:taskLocation,
       userid:user._id,
       username:user.name,
       
       },
     };
     fetch(apiURL, apiParams)
       .then((res) => (res.status === 200 ? res.json() : res.text()))
       .then((repos) => {
       if (repos.data) {
        props.close()
         alert("Task Added Successfully!!");

       } else alert(repos.message || repos);
       })
       .catch((err) => alert(err));
     };


  return (
	<div>
		 <div id="add_btn_grp_add_event_details" onClick={()=>{addTask()}}>
    <svg class="add_btn_bdr_add_calender_event">
        <rect id="add_btn_bdr_add_calender_event" rx="7" ry="7" x="0" y="0" width="120" height="40">
        </rect>
    </svg>
    <div id="Add_btn_add_calender_event">
        <span>Add</span>
    </div>
   </div>

	<div id="task_pop_up_calender_page">
	<div id="titile_grp_add_task_pop_up">
			<input placeholder="Title" class="title_bdr_add_task_pop_up" value={taskTitle}  onChange={(e)=>setTaskTitle(e.target.value)}/>
				
			<div id="title_lbl_add_task_pop_up">
				<span>Title</span>
			</div>
		</div>	
		<div id="start_at_grp_add_task_pop_up">
			<input type="datetime-local" class="start_at_bdr_add_event_details" value={taskStartOn} onChange={(e)=>setTaskStartOn(e.target.value)}/>
				
			<div id="start_at_lbl_add_task_pop_up">
				<span>Start At</span>
			</div>
		</div>
		<div id="ends_at_grp_add_task_pop_up">
			<input type="datetime-local" class="end_at_bdr_add_task_pop_up" value={taskEndOn} onChange={(e)=>setTaskEndOn(e.target.value)}/>
				
			<div id="end_at_lbl_add_task_pop_up">
				<span>Ends At</span>
			</div>
		</div>
		<div id="category_grp_add_task_pop_up">
			<input placeholder="Category" class="category_bdr_add_task_pop_up" value={taskCategory} onChange={(e)=>setTaskCategory(e.target.value)}/>
				
			<div id="Category_lbl_add_task_pop_up">
				<span>Category</span>
			</div>
		</div>
		<div id="loca_grp_add_task_pop_up">
			<input placeholder="Location" class="location_bdr_add_task_pop_up" value={taskLocation} onChange={(e)=>setTaskLocation(e.target.value)}/>
				
			<div id="Location_lbl_add_task_pop_up">
				<span>Location</span>
			</div>
		</div>
		<div id="description_grp_add_task_pop_u">
			<textarea placeholder="Description" class="descp_bdr_add_task_pop_up"  value={taskDiscription} onChange={(e)=>setTaskDiscription(e.target.value)}/>
			
			<div id="Description_lbl_add_task_pop_u">
				<span>Description</span>
			</div>
		</div>
		
		
	</div>
	</div>
  )
}

export default AddTaskToCalander
