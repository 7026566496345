import React, { useEffect, useState, useContext} from 'react'

import '../../styles/MyEventsandComEventsUI.css';
import Switch from '@mui/material/Switch';

import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import MyEventsUI from './MyEventsUI';
import CommunityEventsUI from './CommunityEventsUI';


const MyEventsandComEventsUI = (props) => {

//UseState
const [toggle,setToggle] = useState(false);
const { user, setUser} = useContext(UserContext);
const [eventsCount,setEventsCount] = useState([]);

useEffect(()=>{
	if(user){
		getEvents()
	}
	
},[user])

//get comunity
const getEvents = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/events/getallevents"; 

    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
         
          setEventsCount(repos.data.filter((rec)=>rec.organiserId === user._id).length);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  return (
    <div id="events_page_pc_new">

    <div id="top_grp_event_pc_page">
	{toggle=== false ?	<div id="details_events_new_events_page">
			<svg class="bdr_of_details">
				<rect id="bdr_of_details" rx="16" ry="16" x="0" y="0" width="824" height="116">
				</rect>
			</svg>
			<div id="grp_of_details">
				<div id="Events_lbl_new_events_page_pc">
					<span>Events</span>
				</div>
				<div id="n_023_new_events_page_pc">
					<span>{eventsCount}</span>
				</div>
				<svg class="btw_2_new_events_page_pc" viewBox="0 0 1 64">
					<path id="btw_2_new_events_page_pc" d="M 0 0 L 0 64">
					</path>
				</svg>
				<div id="Completed_Events_new_events_pa">
					<span>Completed Events</span>
				</div>
				<div id="n_323_new_events_page_pc">
					<span>{eventsCount}</span>
				</div>
				<svg class="btw_1_new_events_page_pc" viewBox="0 0 1 64">
					<path id="btw_1_new_events_page_pc" d="M 0 0 L 0 64">
					</path>
				</svg>
				<div id="Ongoing_Events_new_events_page">
					<span>Ongoing Events</span>
				</div>
				<div id="n_023_new_events_page_pc_l">
					<span>{eventsCount}</span>
				</div>
			</div>
		</div>:""}

		<div id="event_slecctor_new_events_page">
			<div id="req_lbl_new_events_page_pc" onClick={()=>props.selectedTab("requirements")}>
				<span>Requirements</span>
			</div>
			<div id="offering_lbl_new_events_page_p" onClick={()=>props.selectedTab("offerings")}>
				<span>Offerings</span>
			</div>
			<div id="event_llbl_new_events_page_pc">
				<span>Events</span>
			</div>
			<svg class="event_underline_new_events_pag">
				<rect id="event_underline_new_events_pag" rx="0" ry="0" x="0" y="0" width="51" height="3">
				</rect>
			</svg>
		</div>
		<div id="filters_area_new_events_page_mc">
			<svg class="bdr_new_events_page_pc_md">
				<rect id="bdr_new_events_page_pc_md" rx="5" ry="5" x="0" y="0" width="130" height="30">
				</rect>
			</svg>
			<div id="Find_Event_new_events_page_pc_me">
				<span>Filter</span>
			</div>
			<div id="category_grp_pc_mf">
				<select class="category_input_new_events_page_mg">
					<option>Product</option><option>Music</option><option>Workshop</option><option>UpSkill and Training</option><option>Others</option> 
				</select>
				<div id="Category_new_events_page_pc_mh">
					<span>Category</span>
				</div>
			</div>
			<div id="time_cate_gory_pc_mi">
				<select class="time_bdr_filter_new_events_pag_mj">
					<option>Recent</option><option>Today</option><option>This Week</option><option>This Month</option><option>This Year</option> 
				</select>
				<div id="Time_new_events_page_mk">
					<span>Time</span>
				</div>
			</div>
		</div>
	
		<div id="swtitch_grp_event_mq">
			<div id="Myevents_PC_mr">
				<span>My Events</span>
			</div>
			<div id="Community_eventsPC_ms">
				<span>Community Events</span>
			</div>
			<div class="switch_eent_off_PC_mt">
			<Switch id='switch_eent_off_PC_mt' onChange={(e)=>setToggle(e.target.checked)} checked={toggle} color="default" />
			</div>
		</div>
	</div>
	{toggle === false ? <MyEventsUI />:<CommunityEventsUI />}
    </div>
  )
}

export default MyEventsandComEventsUI
