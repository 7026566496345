import React from 'react'
import "../../../styles/ColorFusionMob.css";

function ColorFusionMob(props) {
  return (
    <div id="ColorFusion_Mob">
        <div id="CFM_35_MobGroup_15132">
            <div id="CFM_35_MobGroup_15050">
                <div id="CFM_35_Mobsocials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
                    <div title="LinkedIn" id="CFM_35_Moblinked_in_grp_shadow_space_web">
                        <svg class="CFM_35_Mobmed_shadow_space_website_7">
                            <ellipse id="CFM_35_Mobmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
                        <div id="CFM_35_Moblinked_in_shadow_space_website">
					<a href={props.data.linkedInUrl} target="_blank">
                            <svg class="CFM_35_Mobn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
                                <path id="CFM_35_Mobn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
                                </path>
                            </svg>
                        </a>
                            <svg class="CFM_35_Moblinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
                                <path id="CFM_35_Moblinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
                                </path>
                            </svg>
                            <svg class="CFM_35_Mobtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
                                <path id="CFM_35_Mobtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
                                </path>
                            </svg>
                        </div>
                    </div>:""}
			{props.data.instagramUrl?	
                    <div title="Instagram" id="CFM_35_MobInsta_grp_shadow_space_website">
                        <svg class="CFM_35_Mobshadow_space_website_7">
                            <ellipse id="CFM_35_Mobshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
				<a href={props.data.instagramUrl} target="_blank">
                        <img id="CFM_35_Mobinstagramshadow_space_website_" src="./Themes/ColorFusion/instagramshadow_space_website_.png" srcset="./Themes/ColorFusion/instagramshadow_space_website_.png 1x" />
                            
                        </a>
                    </div>:""}
			{props.data.whatsAppNumber?
                    <div title="WhatsApp" id="CFM_35_Mobwhatsapp_grp_shadow_space_webs">
                        <svg class="CFM_35_Mobmed_shadow_space_website_7_">
                            <ellipse id="CFM_35_Mobmed_shadow_space_website_7_" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
                        <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="CFM_35_Mobwhatsapp_lbl_shadow_space_webs" src="./Themes/ColorFusion/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/ColorFusion/whatsapp_lbl_shadow_space_webs.png 1x" />
                            </a>
                    </div>:""}
                </div>
                <div id="CFM_35_Mobn_234567890_mobile_number_shad">
                    <span>{props.data.businessPhone}</span>
                </div>
                <div id="CFM_35_Mobaffanghanioperatelive_email_sh">
                    <span>{props.data.businessEmail}</span>
                </div>
                <div id="CFM_35_Mobaddress_med_shadow_space_websi">
                    <span>{props.data.businessAddress}</span>
                </div>
                <img id="CFM_35_Moblocation_img_shadow_space_webs" src="./Themes/ColorFusion/location_img_shadow_space_webs.png" srcset="./Themes/ColorFusion/location_img_shadow_space_webs.png 1x" />
                    
                <img id="CFM_35_Mobemail_address_shadow_space_web" src="./Themes/ColorFusion/email_address_shadow_space_web.png" srcset="./Themes/ColorFusion/email_address_shadow_space_web.png 1x" />
                    
                <img id="CFM_35_Mobphone_number_shadow_space_webs" src="./Themes/ColorFusion/phone_number_shadow_space_webs.png" srcset="./Themes/ColorFusion/phone_number_shadow_space_webs.png 1x" />
                    
                <div id="CFM_35_Mobn_Copyright_Operate_Live_OPC_P">
                    <span>© Copyright Operate Live (OPC) Private Limited.<br/> All Rights Reserved</span>
                </div>
                <div id="CFM_35_MobContact_Us">
                    <span>Contact Us!</span>
                </div>
            </div>
            <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="CFM_35_MobRectangle_757">
                <rect id="CFM_35_MobRectangle_757" rx="0" ry="0" x="0" y="0" width="274" height="183">
                </rect>
            </iframe>
        </div>
        <div id="CFM_35_MobGroup_15117">
            <img id="CFM_35_Mobtheme-photos-Cl-OpYWFFm0-unspl" src="./Themes/ColorFusion/theme-photos-Cl-OpYWFFm0-unspl.png" srcset="./Themes/ColorFusion/theme-photos-Cl-OpYWFFm0-unspl.png 1x" />
                
            <svg class="CFM_35_MobRectangle_748">
                <rect id="CFM_35_MobRectangle_748" rx="0" ry="0" x="0" y="0" width="375" height="663">
                </rect>
            </svg>
            <img id="CFM_35_MobAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/ColorFusion/Anime_Edit.png"} />
                
            <div id="CFM_35_MobYour_Business_Name">
                <span>{props.data.businessName}</span>
            </div>
            <div id="CFM_35_MobDiscover_a_World_of_Possibilit">
            <span>"Discover a World of Possibilities in Every Brushstroke,<br/>with Our Exceptional Painting Skills, Add Depth and <br/>Life to Your Imagination."</span>
           </div>
            <div id="CFM_35_MobGroup_15113">
                <svg class="CFM_35_MobRectangle_3">
                    <rect id="CFM_35_MobRectangle_3" rx="24.5" ry="24.5" x="0" y="0" width="177" height="49">
                    </rect>
                </svg>
                <div id="CFM_35_MobAvailable_Now">
                    <span>Hire us now!</span>
                </div>
            </div>
        </div>
        <img id="CFM_35_Mobdavid-pisnoy-46juD4zY1XA-unspl" src="./Themes/ColorFusion/david-pisnoy-46juD4zY1XA-unspl.png" srcset="./Themes/ColorFusion/david-pisnoy-46juD4zY1XA-unspl.png 1x" />
            
        <div id="CFM_35_MobGroup_15174">
            <div id="CFM_35_MobGroup_15141">
                <svg class="CFM_35_MobRectangle_752">
                    <rect id="CFM_35_MobRectangle_752" rx="10" ry="10" x="0" y="0" width="332" height="546">
                    </rect>
                </svg>
                <img id="CFM_35_Mobyoann-siloine-LiDVw7tZPfY-unsp" src="./Themes/ColorFusion/yoann-siloine-LiDVw7tZPfY-unsp.png" srcset="./Themes/ColorFusion/yoann-siloine-LiDVw7tZPfY-unsp.png 1x" />
                    
                <div id="CFM_35_MobScroll_Group_28">
                    <div id="CFM_35_MobIt_is_a_long_established_fact_">
                        <span>{props.data.vision}</span>
                    </div>
                </div>
                <div id="CFM_35_MobVISION">
                    <span>VISION</span>
                </div>
            </div>
            <div id="CFM_35_MobGroup_15172">
                <svg class="CFM_35_MobRectangle_752_bt">
                    <rect id="CFM_35_MobRectangle_752_bt" rx="10" ry="10" x="0" y="0" width="332" height="546">
                    </rect>
                </svg>
                <img id="CFM_35_Mobtaelynn-christopher-pfSNx3Z12K" src="./Themes/ColorFusion/taelynn-christopher-pfSNx3Z12K.png" srcset="./Themes/ColorFusion/taelynn-christopher-pfSNx3Z12K.png 1x" />
                    
                <div id="CFM_35_MobScroll_Group_28_bv">
                    <div id="CFM_35_MobIt_is_a_long_established_fact__bw">
                        <span>{props.data.mission}</span>
                    </div>
                </div>
                <div id="CFM_35_MobMISSION">
                    <span>MISSION</span>
                </div>
            </div>
            <div id="CFM_35_MobGroup_15173">
                <svg class="CFM_35_MobRectangle_752_bz">
                    <rect id="CFM_35_MobRectangle_752_bz" rx="10" ry="10" x="0" y="0" width="332" height="546">
                    </rect>
                </svg>
                <img id="CFM_35_Mobdavid-pisnoy-46juD4zY1XA-unspl_b" src="./Themes/ColorFusion/david-pisnoy-46juD4zY1XA-unspl_b.png" srcset="./Themes/ColorFusion/david-pisnoy-46juD4zY1XA-unspl_b.png 1x" />
                    
                <div id="CFM_35_MobScroll_Group_28_b">
                    <div id="CFM_35_MobIt_is_a_long_established_fact__b">
                        <span>{props.data.principles}</span>
                    </div>
                </div>
                <div id="CFM_35_MobPRINCIPLES">
                    <span>PRINCIPLES</span>
                </div>
            </div>
        </div>
        <div id="CFM_35_MobGroup_15170">
            <div id="CFM_35_MobScroll_Group_91">
    {props.offeringsData.map((off,idx)=>(
                <div id="CFM_35_MobGroup_15144">
                    <svg class="CFM_35_MobRectangle_754">
                        <rect id="CFM_35_MobRectangle_754" rx="10" ry="10" x="0" y="0" width="187" height="270">
                        </rect>
                    </svg>
                    <img id="CFM_35_Mobanna-kolosyuk-D5nh6mCW52c-unsp" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/ColorFusion/anna-kolosyuk-D5nh6mCW52c-unsp.png"} />
                        
                    <div id="CFM_35_MobTitle">
                        <span>{off.offeringTitle}</span>
                    </div>
                </div>))}
            </div>
            <div id="CFM_35_MobGroup_15149">
                <div id="CFM_35_MobOur_Colours">
                    <span>Our Services</span>
                </div>
                <div id="CFM_35_MobGroup_15145">
                    <svg class="CFM_35_MobRectangle_753">
                        <rect id="CFM_35_MobRectangle_753" rx="0" ry="0" x="0" y="0" width="145" height="38">
                        </rect>
                    </svg>
                    <div id="CFM_35_MobView_More">
                        <span>View More</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ColorFusionMob