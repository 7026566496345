import React,{useState} from 'react'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import '../styles/ForgotPasswordUI.css';
import {Link, useNavigate} from "react-router-dom";
import { UserContext } from './UserContext';
import ChangePassword from './ChangePassword';


const ForgotPasswordUI = (props) => {
  var navigate = useNavigate();
const [ email,setEmail] = useState("")



const sendOtp = async () =>{
	
	if(email.length === 0){
	return	alert("Please Enter Email!!");
	}
let apiUrl = process.env.REACT_APP_API_URL + "/users/emailsend";
let apiParam = {
  method: 'POST',
  headers: {
     email: email,
   },
 
};
fetch(apiUrl, apiParam)
  .then((res) => (res.status === 200 ? res.json() : res.text()))
  .then((repos) => {
    if (repos.statusText ==="success") {
    
     alert("If the entered email is registered, OTP has been sent to eMail!","success")
      setTimeout(() => {
        navigate("/ChangePassword?email="+email);
       
      }, 1500);

    } else {
      props.showAlert("If the entered email is registered, OTP has been sent to eMail! ","success")
    }
  })
  .catch((err) => alert(JSON.stringify(err)));
};



const backtoLogin = () =>{
  navigate("/login");
}

  return (
    <div id="forgot_page_pc">
	<svg class="Path_1_pc" viewBox="1106.719 150.94 260.616 160.165">
		<path id="Path_1_pc" d="M 1106.718994140625 245.1532287597656 L 1109.500366210938 311.1045532226562 L 1367.334716796875 220.0757598876953 L 1367.334716796875 150.9395446777344 L 1106.718994140625 245.1532287597656 Z">
		</path>
	</svg>
	<svg class="Path_2_pc" viewBox="1230.339 -8.122 137.074 83.79">
		<path id="Path_2_pc" d="M 1230.338989257812 75.66712951660156 L 1230.338989257812 40.45851898193359 L 1354.740966796875 -8.12246036529541 L 1367.138427734375 -0.8549958467483521 L 1367.4130859375 26.84274482727051 L 1230.338989257812 75.66712951660156 Z">
		</path>
	</svg>
	<svg class="Path_5_pc" viewBox="1277.994 95.944 95.817 59.109">
		<path id="Path_5_pc" d="M 1277.994018554688 127.1390762329102 L 1289.892700195312 155.0538787841797 L 1373.810791015625 123.9319610595703 L 1365.97314453125 95.94441986083984 L 1277.994018554688 127.1390762329102 Z">
		</path>
	</svg>
	<svg class="Path_1_o_pc" viewBox="1083.29 150.902 284.045 160.202">
		<path id="Path_1_o_pc" d="M 1367.334838867188 251.9999847412109 L 1341.938598632812 311.1045532226562 L 1083.289916992188 220.0543212890625 L 1083.289916992188 150.9024963378906 L 1367.334838867188 251.9999847412109 Z">
		</path>
	</svg>
	<svg class="Path_2_p_pc" viewBox="1217.914 -8.93 149.499 84.597">
		<path id="Path_2_p_pc" d="M 1356.298217773438 75.66712188720703 L 1367.4130859375 42.74979019165039 L 1231.896240234375 -8.12246036529541 C 1231.896240234375 -8.12246036529541 1222.323486328125 -9.939326286315918 1219.22412109375 -8.12246036529541 C 1216.124755859375 -6.305594444274902 1219.498779296875 -0.8549967408180237 1219.498779296875 -0.8549967408180237 L 1219.22412109375 26.84273910522461 L 1356.298217773438 75.66712188720703 Z">
		</path>
	</svg>
	<svg class="Path_3_pc" viewBox="0 102.6 214.177 131.242">
		<path id="Path_3_pc" d="M 0 102.5998382568359 L 214.1771545410156 178.6947174072266 L 214.1771545410156 233.8421325683594 L 0 155.1822509765625 L 0 102.5998382568359 Z">
		</path>
	</svg>
	<div id="Forgot_Password_pc_lbl_">
		<span>Forgot Password</span>
	</div>
	<div id="Enter_the_email_address_associ_pc">
		<span>Enter the email address associated with your account<br/>and we'll send you a link to reset your password.</span>
	</div>
	<input placeholder="Email" class="ema_pc" value={email} onChange={(e)=>setEmail(e.target.value)}/>
		{/* <rect id="ema" rx="0" ry="0" x="0" y="0" width="312.5" height="31.599">
		</rect>
	</input> */}
	<button id="Group_9_pc_fp" onClick={()=>sendOtp()}>
		<svg class="Rectangle_pc_fp">
			<rect id="Rectangle_pc_fp" rx="3" ry="3" x="0" y="0" width="253.519" height="37.724">
			</rect>
		</svg>
		<div id="Continue_pc_fp">
			<span>Continue</span>
		</div>
	</button>
	<div onClick={()=>navigate('/signup')} id="Dont_have_an_account_Sign_up_pc_fp">
		<span>Don't have an account? </span><span style={{color:"blue"}}>Sign up</span>
	</div>
	<img id="image_forgotPw_pc" src="image.png" srcset="image.png 1x, image@2x.png 2x" />
		
	<div onClick={()=>backtoLogin()} id="Group_13_pc_fp">
		<div  id="Back_pc_fp">
			<span>Back</span>
		</div>
		<img id="back_fp_pc" src="back.png" srcset="back.png 1x, back@2x.png 2x" />
			
	</div>
</div>
  )
}

export default ForgotPasswordUI