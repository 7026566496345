import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/PeoplesConnectionsUI.css';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PeoplesConnectionsUI = (props) => {

//UseState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [users,setUsers] = useState("");
const [ allStatus,setAllStatus] = useState("");
const [requests,setRequests]= useState([]);
const [selectedCommunity,setSelectedCommunity] = useState([])
const [communities,setCommunities] = useState([]);


 //UseEffect
 useEffect(()=>{
   
 
   if(user){
   
    getStatus()
   
   }
},[user])
  
const ConnectionReq = (id,status)=>{  
  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
if(selectedCommunity==="undefined"){
  setSelectedCommunity(JSON.parse([]))
}
    let apiURL=process.env.REACT_APP_API_URL + "/connection/updateconnection/"+id;
    let apiParams = {
        method:"POST",
        headers:{
          status:status,
          user2selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        },
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        alert("Action Updated Successfully!!");
         
      //props.showAlert("Job Added Successfully","success")
         setTimeout(() => {
          getStatus()
        //   getRequests()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
  
}


const getStatus = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
           console.log(repos.data)
          setAllStatus(repos.data)
          
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};

//Converting Image
function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  var base64Flag = "data:image/jpeg;base64,";
  return base64Flag + window.btoa(binary);
}


  return (
   
  <div id="connection_page">
	<div id="heading_grp_conreqsug_con_page">
		<div id="conreqsug_con_page_new_pc_req" onClick={()=>props.selectedTab("Requests")}>
			<span>Request</span>
		</div>
		<div id="selected_grp_offreqevent_com_r">
			<div id="conreqsug_con_page_new_pc_conn">
				<span>Connections</span>
			</div>
		</div>
		<div id="conreqsug_con_page_new_pc_sug" onClick={()=>props.selectedTab("Suggestions")}>
			<span>Suggestions</span>
		</div>
		<svg class="underline_conreqsug_con_page_n">
			<rect id="underline_conreqsug_con_page_n" rx="0" ry="0" x="0" y="0" width="99" height="3">
			</rect>
		</svg>
	</div>
	<svg class="desgin_con_page_new_pc">
		<rect id="desgin_con_page_new_pc" rx="0" ry="0" x="0" y="0" width="100%" height="203">
		</rect>
	</svg>
	<div id="Scroll_connection_page_pc_new">
  {(allStatus||[]).map((us,idx)=>(
		<div id="overrall_car_conreqsug_con_pag">
			<svg class="over_all_bdr_sug_con_page_new_" onClick={()=>navigate("../"+(us.user1Name===user.name ? us.user2Image.nickName:us.user1Image.nickName))}>
				<rect id="over_all_bdr_sug_con_page_new_" rx="10" ry="10" x="0" y="0" width="185" height="287">
				</rect>
			</svg>
			<img id="Anime_Edit_con_page_new_pc" src={us.user1Name===user.name ?  (us.user2Image.imageUrl && us.user2Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(us.user2Image.imageUrl.split('/')[5]):"./Peoples/DefaultImageCon.png":(us.user1Image.imageUrl && us.user1Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(us.user1Image.imageUrl.split('/')[5]):"./Peoples/DefaultImageCon.png"} onClick={()=>navigate("../"+(us.user1Name===user.name ? us.user2Image.nickName:us.user1Image.nickName))}/>
				
			<div id="desgination_pc_con_page" onClick={()=>navigate("../"+(us.user1Name===user.name ? us.user2Image.nickName:us.user1Image.nickName))}>
				<img id="job-seeker_con_page_new_pc" src="./Peoples/job-seeker_con_page_new_pc.png" srcset="./Peoples/job-seeker_con_page_new_pc.png 1x" />
					
				<div id="con_page_new_pc_cor">
					<span>{us.user1Name===user.name ? us.user2Image.profession:us.user1Image.profession}</span>
				</div>
			</div>
			<div id="Mohammed_Affan_con_page_new_pc" onClick={()=>navigate("../"+(us.user1Name===user.name ? us.user2Image.nickName:us.user1Image.nickName))}>
				<span>{us.user1Name===user.name ? us.user2Name:us.user1Name}</span>
			</div>
      { user.name !==us.name ?<div>
			<div title="Block" id="block_btn_grp_con_page_new_pc" onClick={(e) =>{ConnectionReq(us._id,"Blocked")}}>
				<svg class="share_btn_con_page_new_pc">
					<ellipse id="share_btn_con_page_new_pc" rx="19" ry="19" cx="19" cy="19">
					</ellipse>
				</svg>
				<img id="share_img_con_page_new_pc" src="./Peoples/share_img_con_page_new_pc.png" srcset="./Peoples/share_img_con_page_new_pc.png 1x" />
					
			</div>
			<div title="Disconnect" id="disconnect_btn_grp_con_page_ne" onClick={(e) =>{ConnectionReq(us._id,"Disconnected")}}>
				<svg class="bdr_no_Copines">
					<ellipse id="bdr_no_Copines" rx="19" ry="19" cx="19" cy="19">
					</ellipse>
				</svg>
				<img id="no-connection_Copines" src="./Peoples/no-connection_Copines.png" srcset="./Peoples/no-connection_Copines.png 1x" />
					
			</div>
      </div>:""}
			<div id="company_anme_new_" onClick={()=>navigate("../"+(us.user1Name===user.name ? us.user2Image.nickName:us.user1Image.nickName))}>
				<img id="office-building_img_con_page_n" src="./Peoples/office-building_img_con_page_n.png" srcset="./Peoples/office-building_img_con_page_n.png 1x" />
					
				<div id="operate_live_lbl_con_page_new_">
					<span>{us.user1Name===user.name ? us.user2Image.businessName:us.user1Image.businessName}</span>
				</div>
			</div>
		</div>
    ))}
	</div>
</div>
  
  )
}

export default PeoplesConnectionsUI
