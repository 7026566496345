import React, { useEffect, useState, useContext} from 'react'
import '../../styles/CommunityEventsMobUI.css';
import { UserContext } from '../UserContext';
import TrophiesAndCountMob from './TrophiesAndCountMob';

const CommunityEventsMobUI = () => {
    const {user} = useContext(UserContext);
    const [events,setEvents] = useState([]);

useEffect(()=>{
	if(user){
		getEvents()
	}
	
},[user])

//get comunity
const getEvents = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/events/getallevents"; 

    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
         
          setEvents(repos.data);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  return (
    <div id="card_1_commu_recent_mob">

    <div id="n_st_card_grp_mob_recent_event">
        <svg class="recent_events_new_e_commu_rece_bq">
            <linearGradient id="recent_events_new_e_commu_rece_bq" spreadMethod="pad" x1="1.142" x2="0.009" y1="1.187" y2="0">
                <stop offset="0" stop-color="#40e196" stop-opacity="1"></stop>
                <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
            </linearGradient>
            <rect id="recent_events_new_e_commu_rece_bq" rx="10" ry="10" x="0" y="0" width="100%" height="100%">
            </rect>
        </svg>
        <svg class="recent_events_line_commu_recen" viewBox="0 0 5 26">
            <path id="recent_events_line_commu_recen" d="M 0 0 L 0 26">
            </path>
        </svg>
        <div id="recent_events_newcommu_recent_">
            <span>Recent Events</span>
        </div>
        <div style={{position:"absolute",width:"100%", height:"calc(100% - 35px)",left:"0px",top:"40px",overflow: "scroll",display:"flex",flexWrap:"wrap",justifyContent:"space-around",alignContent: "flex-start"}}>
        {events.filter(rec=>rec.organiserId!==user._id).map((eve)=>(

        <div id="card_1_recent_com_mob">
            <img id="img_event_commu_recent_mo" src={eve.eventImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+eve.eventImageUrl.split('/')[5]:"./MyPortfolio/Events/idk_vent_new_pc.png"} />
                
            <svg class="shade_img_commu_recent_mob">
                <rect id="shade_img_commu_recent_mob" rx="8" ry="8" x="0" y="0" width="177.664" height="98.427">
                </rect>
            </svg>
            <div id="date_of_event_mob">
                <svg class="bdr_date_commu_recent_mob">
                    <rect id="bdr_date_commu_recent_mob" rx="5" ry="5" x="0" y="0" width="73" height="31">
                    </rect>
                </svg>
                <div id="n_3th_Jan_2023_commu_recent_mo">
                    <span>{eve.startsOn.slice(0,10)} - {eve.startsOn.slice(11,16)} IST</span>
                </div>
            </div>
            <div id="event_name_mob">
                <svg class="op_live_bdr_name_commu_recent_">
                    <rect id="op_live_bdr_name_commu_recent_" rx="4" ry="4" x="0" y="0" width="161" height="21">
                    </rect>
                </svg>
                <div id="Operate_Live_commu_recent_mob">
                    <span>{eve.title}</span>
                </div>
            </div>
            <div id="trophie_recent_mob">
                <svg class="bdr_trophie_commu_recent_mob">
                    <rect id="bdr_trophie_commu_recent_mob" rx="5" ry="5" x="0" y="0" width="73" height="24.894">
                    </rect>
                </svg>
                {/* <img id="trophy_img_commu_recent_mob" src="./MyPortfolio/Events/trophy_img_commu_recent_mob.png" srcset="./MyPortfolio/Events/trophy_img_commu_recent_mob.png 1x" />
                    
                <div id="n_234_commu_recent_mob">
                    <span>1234</span>
                </div> */}

                <div id='trophy_img_commu_recent_mob'>
					<TrophiesAndCountMob portfolioType={"events"} portfolioId={eve._id}/>
					</div>

            </div>
        </div>
        ))}
        </div>
    </div>
</div>
  )
}

export default CommunityEventsMobUI
