import React, { useState, useEffect, useContext } from "react";
import { UserContext } from './UserContext';
import '../styles/SideNavBarPCUI.css'
import {useNavigate} from "react-router-dom";

const SideNavBarPCUI = (props) => {

//useState
var navigate = useNavigate();
const { user, setUser } = useContext(UserContext);


const meetingRoom = ()=>{
    props.goto(false);
    navigate('/meeting')
}

const dashboardRoom = ()=>{
    props.goto(false)
    navigate('/home')
}

const myPortfolio = ()=>{
    props.goto(false)
    navigate('/portfolio')
}

// const requirementRoom = ()=>{
//     props.goto(false)
//     navigate('/requirement')
// }

// const offeringRoom = ()=>{
//     props.goto(false)
//     navigate('/offerings')
// }
const myPeoplesRoom = ()=>{
    props.goto(false)
    navigate('/mypeople')
}

const suggestionsRoom = ()=>{
    props.goto(false)
    navigate('/suggestions')
}

const marketRoom = ()=>{
    props.goto(false)
    navigate('/marketpage')
}
const eventsRoom = ()=>{
    props.goto(false)
    navigate('/events')
}


const communities = ()=>{
    props.goto(false)
    navigate('/communities')
}

const handlelogout = () =>{
    localStorage.clear();
    setUser(null);
    window.location.href = "/login";
  }

  return (
	<div id="side_menu_bar_pc" className="slide-right">
	<svg class="side_nav_bar_border" onClick={()=>props.goto(false)}>
		<rect id="side_nav_bar_border" rx="0" ry="0" x="0" y="0" width="246" height="100%">
		</rect>
	</svg>
	<img id="side_logo_icon_expand" src="side_logo_icon_expand.png" srcset="side_logo_icon_expand.png 1x"  onClick={()=>props.goto(false)}/>
		
	<div title="Profile" id="dash_bdr_grp_expand" onClick={()=>dashboardRoom()}>
		<svg class="dash_pc">
			<rect id="dash_pc" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Dashboard_side_lbl_pc">
			<span>Profile</span>
		</div>
		<img id="dashboard_Icon_I_1" src="dashboard_Icon_I_expand.png" srcset="dashboard_Icon_I_expand.png 1x" />
			
	</div>
	<div title="Meeting Room" id="metting_room_grp_expand" onClick={()=>{meetingRoom()}}>
		<svg class="meet_pc">
			<rect id="meet_pc" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Meeting_Room_side_lbl_pc">
			<span>Meeting Room</span>
		</div>
		<img id="meeting_room_icon_I_1" src="meeting_room_icon_I_expand.png" srcset="meeting_room_icon_I_expand.png 1x" />
			
	</div>
	<div title="Portfolio" id="portfolio_grp_expand" onClick={()=>{myPortfolio()}}>
		<svg class="portfolio_pc">
			<rect id="portfolio_pc" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="portfolio_side_lbl_pc">
			<span>Portfolio</span>
		</div>
		<img id="portfolio_icon_I_1" src="./SideNav/portfolio_icon_mob.png" srcset="./SideNav/portfolio_icon_mob.png 1x" />
			
	</div>
	<div title="People" id="connection_grp_expand" onClick={()=>{myPeoplesRoom()}}>
		<svg class="con_pc">
			<rect id="con_pc" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="My_Connection_side_lbl_pc">
			<span>People</span>
		</div>
		<img id="my_connections_icon_I_1" src="my_connections_icon_I_expand.png" srcset="my_connections_icon_I_expand.png 1x" />
			
	</div>
	<div title="Market" id="market_grpp_expand" onClick={()=>{marketRoom()}}>
		<svg class="market_pc_ui">
			<rect id="market_pc_ui" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Market_side_lbl_pc">
			<span>Market</span>
		</div>
		<img id="market_icon_I_1" src="market_icon_I_expand.png" srcset="market_icon_I_expand.png 1x" />
			
	</div>
	<div title="Events" id="event_grp_expand" onClick={()=>{eventsRoom()}}>
		<svg class="event_pc">
			<rect id="event_pc" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Events_side_lbl_pc">
			<span>Broadcasts</span>
		</div>
		<img id="events_icon_I_1" src="broadcast_Nav_bar.png" srcset="broadcast_Nav_bar.png 1x" />
			
	</div>
	<div title="LogOut" id="logout_grp_expand" onClick={()=>{handlelogout()}}>
		<svg class="log_pc">
			<rect id="log_pc" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Logout_side_lbl_pc">
			<span>Logout</span>
		</div>
		<img id="logout_icon_I_1" src="logout_icon_I_expand.png" srcset="logout_icon_I_expand.png 1x" />
			
	</div>
	<div title="Communities" id="com_grp_expand" onClick={()=>{communities()}}>
		<svg class="com_pc">
			<rect id="com_pc" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Communities_side_lbl_pc">
			<span>Communities</span>
		</div>
		<img id="community_icon_I_1" src="./SideNav/community_icon_I_1_mob.png" srcset="./SideNav/community_icon_I_1_mob.png 1x" />
			
	</div>
</div>
  )
}

export default SideNavBarPCUI
