import React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './styles.css';
import {ContextProvider} from './SocketContext';

ReactDOM.render(
<App/>,
document.getElementById('root')
);

