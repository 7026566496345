import React, { useState, useEffect, useContext } from "react";
import "../../../styles/KnowledgeKornerMob.css";
import { UserContext } from '../../UserContext';
import { useNavigate,useLocation, Navigate } from "react-router-dom";

const KnowledgeKornerMob = (props) => {
  return (
    <div id="KnowledgeKorner_mob">
    <nav role="navigation">
	<div id="menuToggle">
	
	  <input type="checkbox" />
	
	  <span></span>
	  <span></span>
	  <span></span>
	
	  <ul id="menu">
      <a href="#Anime_Edit_213_website_6_mob" target=""><li>Home</li></a>
		<a href="#Our_Services_lbl_website_6_mob" target=""><li>Offerings</li></a>
		<a href="#oofering_lbl_med_kk_mob" target=""><li>Events</li></a>
		<a href="#footer_grp_website_6_mob" target=""><li>Contact</li></a>
	   
		{/* <a href="#bdr_contact_page_website_2mob" target=""><li>Contact</li></a>  */}
	  </ul>
	</div>
  </nav>
	<svg class="circle_4_website_6_mob">
		<ellipse id="circle_4_website_6_mob" rx="15" ry="15" cx="15" cy="15">
		</ellipse>
	</svg>
	<svg class="circle_3_website_6_mob">
		<ellipse id="circle_3_website_6_mob" rx="15" ry="15" cx="15" cy="15">
		</ellipse>
	</svg>
	<svg class="circel_2_website_6_mob">
		<ellipse id="circel_2_website_6_mob" rx="15" ry="15" cx="15" cy="15">
		</ellipse>
	</svg>
	<div id="header_overall_grp_mob_web_6_m">
		<svg class="shadow_grass_website_6_mob" viewBox="4476.914 17891.283 379.533 16.357">
			<path id="shadow_grass_website_6_mob" d="M 4478.31982421875 17891.283203125 L 4856.447265625 17891.283203125 L 4856.447265625 17905.17578125 C 4856.447265625 17905.17578125 4735.3876953125 17909.14453125 4690.40380859375 17901.20703125 C 4645.419921875 17893.26953125 4605.18505859375 17899.50390625 4605.18505859375 17899.50390625 C 4605.18505859375 17899.50390625 4569.353515625 17907.935546875 4550.98583984375 17907.6328125 C 4532.61865234375 17907.33203125 4476.9140625 17899.50390625 4476.9140625 17899.50390625 L 4476.9140625 17891.283203125 L 4478.31982421875 17891.283203125 Z">
			</path>
		</svg>
		<svg class="sky_mob_website_6_mob">
			<rect id="sky_mob_website_6_mob" rx="0" ry="0" x="0" y="0" width="379" height="198">
			</rect>
		</svg>
		<svg class="Path_449_grass_website_6_mob" viewBox="4476.914 17891.285 379.533 16.357">
			<path id="Path_449_grass_website_6_mob" d="M 4478.31982421875 17907.642578125 L 4856.447265625 17907.642578125 L 4856.447265625 17893.75 C 4856.447265625 17893.75 4735.3876953125 17889.78125 4690.40380859375 17897.71875 C 4645.419921875 17905.65625 4605.18505859375 17899.421875 4605.18505859375 17899.421875 C 4605.18505859375 17899.421875 4569.353515625 17890.990234375 4550.98583984375 17891.29296875 C 4532.61865234375 17891.59375 4476.9140625 17899.421875 4476.9140625 17899.421875 L 4476.9140625 17907.642578125 L 4478.31982421875 17907.642578125 Z">
			</path>
		</svg>
		<img id="Anime_Edit_213_website_6_mob" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/KnowledgeKorner/Anime_Edit_213_website_6_mob.png"}  />
			
		<div id="LETS_LEARN_AND_GAIN_NEW__KNOWL">
			<span>LET'S LEARN AND GAIN NEW <br/>KNOWLEDGE AND ABILITIES</span>
		</div>
		<div id="Lets_discover_new_knowledge_an">
			<span>Lets discover new knowledge and new friends and have a <br/>teaming experience with teachers</span>
		</div>
		<div id="n__card_grp_website_6_mob">
			<div id="shadow_grp_img_website_6_mob">
				<svg class="image_4_shadow_website_6_mob">
					<ellipse id="image_4_shadow_website_6_mob" rx="31" ry="2" cx="31" cy="2">
					</ellipse>
				</svg>
				<svg class="image_3_shadow_website_6_mob">
					<ellipse id="image_3_shadow_website_6_mob" rx="31.5" ry="2" cx="31.5" cy="2">
					</ellipse>
				</svg>
				<svg class="image_2_shadow_website_6_mob">
					<ellipse id="image_2_shadow_website_6_mob" rx="31" ry="2" cx="31" cy="2">
					</ellipse>
				</svg>
				<svg class="image_1_shadow_website_6_mob">
					<ellipse id="image_1_shadow_website_6_mob" rx="31.5" ry="1.5" cx="31.5" cy="1.5">
					</ellipse>
				</svg>
			</div>
			<img id="img_4_website_6_mob" src="./Themes/KnowledgeKorner/img_4_website_6_mob.png" srcset="./Themes/KnowledgeKorner/img_4_website_6_mob.png 1x" />
				
			<img id="img_3_website_6_mob" src="./Themes/KnowledgeKorner/img_3_website_6_mob.png" srcset="./Themes/KnowledgeKorner/img_3_website_6_mob.png 1x" />
				
			<img id="img_2_website_6_mob" src="./Themes/KnowledgeKorner/img_2_website_6_mob.png" srcset="./Themes/KnowledgeKorner/img_2_website_6_mob.png 1x" />
				
			<img id="img_1_website_6_mob" src="./Themes/KnowledgeKorner/img_1_website_6_mob.png" srcset="./Themes/KnowledgeKorner/img_1_website_6_mob.png 1x" />
				
		</div>
		<svg class="Icon_awesome-cloud_6_website_6_mob_cloud" viewBox="0 2.25 22.109 15.476">
			<path id="Icon_awesome-cloud_6_website_6_mob_cloud" d="M 18.5714168548584 8.972466468811035 C 18.71305274963379 8.602832794189453 18.79250717163086 8.19865608215332 18.79250717163086 7.777207374572754 C 18.79250717163086 5.946319580078125 17.30706787109375 4.460882663726807 15.47618198394775 4.460882663726807 C 14.79564380645752 4.460882663726807 14.16001510620117 4.668153285980225 13.63493156433105 5.020512104034424 C 12.67803192138672 3.362350463867188 10.89205360412598 2.249999761581421 8.843531608581543 2.249999761581421 C 5.789751052856445 2.249999761581421 3.316324710845947 4.723424911499023 3.316324710845947 7.777207374572754 C 3.316324710845947 7.870479583740234 3.319779396057129 7.96375036239624 3.323233842849731 8.057022094726562 C 1.388710856437683 8.73755931854248 0 10.58226490020752 0 12.75169467926025 C 0 15.49802780151367 2.228155612945557 17.72618103027344 4.9744873046875 17.72618103027344 L 17.68706321716309 17.72618103027344 C 20.12939834594727 17.72618103027344 22.10883140563965 15.746750831604 22.10883140563965 13.30441474914551 C 22.10883140563965 11.16607666015625 20.5888500213623 9.380097389221191 18.5714168548584 8.972466468811035 Z">
			</path>
		</svg>
		<svg class="Icon_awesome-cloud_5_website_6_mob_cloud" viewBox="0 2.25 17.134 11.994">
			<path id="Icon_awesome-cloud_5_website_6_mob_cloud" d="M 14.39284992218018 7.459910869598389 C 14.5026159286499 7.173445224761963 14.56419277191162 6.860208034515381 14.56419277191162 6.533585071563721 C 14.56419277191162 5.114647388458252 13.41297912597656 3.963433742523193 11.99404144287109 3.963433742523193 C 11.46662425994873 3.963433742523193 10.97401237487793 4.124068260192871 10.56707191467285 4.397146701812744 C 9.825474739074707 3.11207127571106 8.441341400146484 2.249999761581421 6.853737354278564 2.249999761581421 C 4.487056732177734 2.249999761581421 2.570151805877686 4.166904449462891 2.570151805877686 6.533585071563721 C 2.570151805877686 6.605871200561523 2.572829246520996 6.678155899047852 2.575506448745728 6.750441551208496 C 1.076250910758972 7.277857780456543 0 8.707504272460938 0 10.38881206512451 C 0 12.51722049713135 1.726820588111877 14.24404048919678 3.855227470397949 14.24404048919678 L 13.70747470855713 14.24404048919678 C 15.60028457641602 14.24404048919678 17.13434410095215 12.70998096466064 17.13434410095215 10.81717014312744 C 17.13434410095215 9.159957885742188 15.95635795593262 7.775825023651123 14.39284992218018 7.459910869598389 Z">
			</path>
		</svg>
		<svg class="Icon_awesome-cloud_4_website_6_mob_cloud" viewBox="0 2.25 22.109 15.476">
			<path id="Icon_awesome-cloud_4_website_6_mob_cloud" d="M 18.5714168548584 8.972466468811035 C 18.71305274963379 8.602832794189453 18.79250717163086 8.19865608215332 18.79250717163086 7.777207374572754 C 18.79250717163086 5.946319580078125 17.30706787109375 4.460882663726807 15.47618198394775 4.460882663726807 C 14.79564380645752 4.460882663726807 14.16001510620117 4.668153285980225 13.63493156433105 5.020512104034424 C 12.67803192138672 3.362350463867188 10.89205360412598 2.249999761581421 8.843531608581543 2.249999761581421 C 5.789751052856445 2.249999761581421 3.316324710845947 4.723424911499023 3.316324710845947 7.777207374572754 C 3.316324710845947 7.870479583740234 3.319779396057129 7.96375036239624 3.323233842849731 8.057022094726562 C 1.388710856437683 8.73755931854248 0 10.58226490020752 0 12.75169467926025 C 0 15.49802780151367 2.228155612945557 17.72618103027344 4.9744873046875 17.72618103027344 L 17.68706321716309 17.72618103027344 C 20.12939834594727 17.72618103027344 22.10883140563965 15.746750831604 22.10883140563965 13.30441474914551 C 22.10883140563965 11.16607666015625 20.5888500213623 9.380097389221191 18.5714168548584 8.972466468811035 Z">
			</path>
		</svg>
		<svg class="Icon_awesome-cloud_3_website_6_mob_cloud" viewBox="0 2.25 22.109 15.476">
			<path id="Icon_awesome-cloud_3_website_6_mob_cloud" d="M 18.5714168548584 8.972466468811035 C 18.71305274963379 8.602832794189453 18.79250717163086 8.19865608215332 18.79250717163086 7.777207374572754 C 18.79250717163086 5.946319580078125 17.30706787109375 4.460882663726807 15.47618198394775 4.460882663726807 C 14.79564380645752 4.460882663726807 14.16001510620117 4.668153285980225 13.63493156433105 5.020512104034424 C 12.67803192138672 3.362350463867188 10.89205360412598 2.249999761581421 8.843531608581543 2.249999761581421 C 5.789751052856445 2.249999761581421 3.316324710845947 4.723424911499023 3.316324710845947 7.777207374572754 C 3.316324710845947 7.870479583740234 3.319779396057129 7.96375036239624 3.323233842849731 8.057022094726562 C 1.388710856437683 8.73755931854248 0 10.58226490020752 0 12.75169467926025 C 0 15.49802780151367 2.228155612945557 17.72618103027344 4.9744873046875 17.72618103027344 L 17.68706321716309 17.72618103027344 C 20.12939834594727 17.72618103027344 22.10883140563965 15.746750831604 22.10883140563965 13.30441474914551 C 22.10883140563965 11.16607666015625 20.5888500213623 9.380097389221191 18.5714168548584 8.972466468811035 Z">
			</path>
		</svg>
		<svg class="Icon_awesome-cloud_2_website_6_mob_cloud" viewBox="0 2.25 22.109 15.476">
			<path id="Icon_awesome-cloud_2_website_6_mob_cloud" d="M 18.5714168548584 8.972466468811035 C 18.71305274963379 8.602832794189453 18.79250717163086 8.19865608215332 18.79250717163086 7.777207374572754 C 18.79250717163086 5.946319580078125 17.30706787109375 4.460882663726807 15.47618198394775 4.460882663726807 C 14.79564380645752 4.460882663726807 14.16001510620117 4.668153285980225 13.63493156433105 5.020512104034424 C 12.67803192138672 3.362350463867188 10.89205360412598 2.249999761581421 8.843531608581543 2.249999761581421 C 5.789751052856445 2.249999761581421 3.316324710845947 4.723424911499023 3.316324710845947 7.777207374572754 C 3.316324710845947 7.870479583740234 3.319779396057129 7.96375036239624 3.323233842849731 8.057022094726562 C 1.388710856437683 8.73755931854248 0 10.58226490020752 0 12.75169467926025 C 0 15.49802780151367 2.228155612945557 17.72618103027344 4.9744873046875 17.72618103027344 L 17.68706321716309 17.72618103027344 C 20.12939834594727 17.72618103027344 22.10883140563965 15.746750831604 22.10883140563965 13.30441474914551 C 22.10883140563965 11.16607666015625 20.5888500213623 9.380097389221191 18.5714168548584 8.972466468811035 Z">
			</path>
		</svg>
		<svg class="Icon_awesome-cloud_1_website_6_mob_cloud" viewBox="0 2.25 22.109 15.476">
			<path id="Icon_awesome-cloud_1_website_6_mob_cloud" d="M 18.5714168548584 8.972466468811035 C 18.71305274963379 8.602832794189453 18.79250717163086 8.19865608215332 18.79250717163086 7.777207374572754 C 18.79250717163086 5.946319580078125 17.30706787109375 4.460882663726807 15.47618198394775 4.460882663726807 C 14.79564380645752 4.460882663726807 14.16001510620117 4.668153285980225 13.63493156433105 5.020512104034424 C 12.67803192138672 3.362350463867188 10.89205360412598 2.249999761581421 8.843531608581543 2.249999761581421 C 5.789751052856445 2.249999761581421 3.316324710845947 4.723424911499023 3.316324710845947 7.777207374572754 C 3.316324710845947 7.870479583740234 3.319779396057129 7.96375036239624 3.323233842849731 8.057022094726562 C 1.388710856437683 8.73755931854248 0 10.58226490020752 0 12.75169467926025 C 0 15.49802780151367 2.228155612945557 17.72618103027344 4.9744873046875 17.72618103027344 L 17.68706321716309 17.72618103027344 C 20.12939834594727 17.72618103027344 22.10883140563965 15.746750831604 22.10883140563965 13.30441474914551 C 22.10883140563965 11.16607666015625 20.5888500213623 9.380097389221191 18.5714168548584 8.972466468811035 Z">
			</path>
		</svg>
		<img id="sheep_3_website_6_mob" src="./Themes/KnowledgeKorner/sheep_3_website_6_mob.png" srcset="./Themes/KnowledgeKorner/sheep_3_website_6_mob.png 1x" />
			
		<img id="sheep_2_website_6_mob" src="./Themes/KnowledgeKorner/sheep_2_website_6_mob.png" srcset="./Themes/KnowledgeKorner/sheep_2_website_6_mob.png 1x" />
			
		<img id="sheep_1_website_6_mob" src="./Themes/KnowledgeKorner/sheep_1_website_6_mob.png" srcset="./Themes/KnowledgeKorner/sheep_1_website_6_mob.png 1x" />
			
		<img id="dove_3_website_6_mob" src="./Themes/KnowledgeKorner/dove_3_website_6_mob.png" srcset="./Themes/KnowledgeKorner/dove_3_website_6_mob.png 1x" />
			
		<img id="dove_2_website_6_mob" src="./Themes/KnowledgeKorner/dove_2_website_6_mob.png" srcset="./Themes/KnowledgeKorner/dove_2_website_6_mob.png 1x" />
			
		<img id="dove_1_website_6_mob" src="./Themes/KnowledgeKorner/dove_1_website_6_mob.png" srcset="./Themes/KnowledgeKorner/dove_1_website_6_mob.png 1x" />
			
	</div>
	<div id="Scroll_partners_web_6_mov">
    {props.partnersData && props.partnersData.map(partner=>
        <div onClick={()=>window.location.href="https://"+partner.nickName+".operate.live"}>
		<div id="partner_card_1_grp_website_6_m">
			<img id="Anime_Edit_img_website_6_mob" src={partner.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(partner.imageUrl.split('/')[5]):"./Themes/KnowledgeKorner/Anime_Edit_img_website_6_mob.png"}  />
				
			<div id="Mohammed_Affan_website_6_mob">
				<span>{partner.businessName && partner.businessName.length>0?partner.businessName:partner.name}</span>
			</div>
		</div>
        </div>
            )}
	</div>
	<div id="Scroll_services_website_6_mob">
    {props.offeringsData.map((off,idx)=>(
		<div id="my_services_card_1_website_6_m">
			<svg class="services_card_bdr_website_6_mo">
				<rect id="services_card_bdr_website_6_mo" rx="10" ry="10" x="0" y="0" width="174" height="242">
				</rect>
			</svg>
			<img id="img_offering_website_6_mob" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/KnowledgeKorner/img_offering_website_6_mob.png"}  />
				
			<svg class="circle_deign_shadow_space_webs">
				<ellipse id="circle_deign_shadow_space_webs" rx="2.5" ry="2.5" cx="2.5" cy="2.5">
				</ellipse>
			</svg>
			<div id="My_Offerings_lbl_website_6_mob">
				<span>{off.offeringTitle}</span>
			</div>
		</div>
        ))}
	</div>
	<svg class="circle_1_website_6_mob">
		<ellipse id="circle_1_website_6_mob" rx="10" ry="10" cx="10" cy="10">
		</ellipse>
	</svg>
	<div id="Trusted_by_our_partners_lbl_we">
		<span>Trusted by our partners</span>
	</div>
	<div id="Scroll_vis_mis_and_prin">
		<div id="vis_mis_and_prin_grp_website_6">
			<div id="princi_grp_website_6_mob">
				<svg class="prini_overall_card_bdr_website">
					<rect id="prini_overall_card_bdr_website" rx="10" ry="10" x="0" y="0" width="174" height="242">
					</rect>
				</svg>
				<svg class="princi_bdr_website_6_mob">
					<rect id="princi_bdr_website_6_mob" rx="5" ry="5" x="0" y="0" width="38" height="38">
					</rect>
				</svg>
				<div id="Principles_lbl_website_6_mob">
					<span>Principles</span>
				</div>
				<div id="Scroll_princi_mob_web_6">
					<div id="Kono_sekai_wa_itami_o_shirudar">
						<span>{props.data.principles}</span>
					</div>
				</div>
				<img id="leadership_2_knowledge_webs_mob" src="./Themes/KnowledgeKorner/leadership_2_shadow_space_webs_mob.png" srcset="./Themes/KnowledgeKorner/leadership_2_shadow_space_webs_mob.png 1x" />
					
			</div>
			<div id="vision_grp_website_6_mob">
				<svg class="overall_card_bdr_website_6_mob">
					<rect id="overall_card_bdr_website_6_mob" rx="10" ry="10" x="0" y="0" width="173" height="242">
					</rect>
				</svg>
				<svg class="img_vision_bdr_website_6_mob">
					<rect id="img_vision_bdr_website_6_mob" rx="5" ry="5" x="0" y="0" width="38" height="38">
					</rect>
				</svg>
				<div id="Vision_website_6_mob">
					<span>Vision</span>
				</div>
				<div id="Scroll_vis_mob_web_6">
					<div id="Zaido_wa_gisei_ni_natte_korosa">
						<span>{props.data.vision}</span>
					</div>
				</div>
				<img id="target_1_kk_website_mob" src="./Themes/KnowledgeKorner/target_1_shadow_space_website__mob.png" srcset="./Themes/KnowledgeKorner/target_1_shadow_space_website__mob.png 1x" />
					
			</div>
			<div id="mission_grp_website_6_mob">
				<svg class="whole_card_bdr_website_6_mob">
					<rect id="whole_card_bdr_website_6_mob" rx="10" ry="10" x="0" y="0" width="174" height="242">
					</rect>
				</svg>
				<svg class="mision_bdr_website_6_mob">
					<rect id="mision_bdr_website_6_mob" rx="5" ry="5" x="0" y="0" width="37" height="37">
					</rect>
				</svg>
				<div id="Mission_website_6_mob">
					<span>Mission</span>
				</div>
				<div id="Scroll_mis_mob_web_6">
					<div id="Watashitachi_no_dry_no_zaido_o">
						<span>{props.data.mission}</span>
					</div>
				</div>
				<img id="principle_2_k_k_websi_mob" src="./Themes/KnowledgeKorner/principle_2_shadow_space_websi_mob.png" srcset="./Themes/KnowledgeKorner/principle_2_shadow_space_websi_mob.png 1x" />
					
			</div>
		</div>
	</div>
	<div id="Our_Services_lbl_website_6_mob">
		<span>Our Services</span>
	</div>
	<div id="Scroll_event_grp_mob">
    {props.eventsData && props.eventsData.map((eve)=>(
		<div id="event_grp__website_k_k_mob">
			<svg class="event_bg_card_1_k_k_space_mob">
				<rect id="event_bg_card_1_k_k_space_mob" rx="15" ry="15" x="0" y="0" width="442" height="286">
				</rect>
			</svg>
			<img id="Anime_Edit_k_k_websit_mob" src={eve.eventImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(eve.eventImageUrl.split('/')[5]):"./Themes/KnowledgeKorner/Anime_Edit_shadow_space_websit_mob.png"}  />
				
			<div id="oofering_lbl_k_k_websi_mob">
				<span>{eve.title}</span>
			</div>
			<div id="Scrolldesc_K_K_websit_mob">
				<div id="oofering_lbl_k_k_webs_mob">
					<span>{eve.description}</span>
				</div>
			</div>
			<div id="all_details_small_web_6__mob">
				<div id="dateshadow_space_website_6_mob">
					<span>{eve.startsOn.slice(0,10)}</span>
				</div>
				<img id="calendar_2_k_k_websit_mob" src="./Themes/KnowledgeKorner/calendar_2_shadow_space_websit_mob.png" srcset="./Themes/KnowledgeKorner/calendar_2_shadow_space_websit_mob.png 1x" />
					
				<div id="date_k_k_website_6_mob">
					<span>{eve.startsOn.slice(11,16)} IST</span>
				</div>
				<img id="clock_1_shadow_space_website_6_k_k_mob" src="./Themes/KnowledgeKorner/clock_1_shadow_space_website_6_mob.png" srcset="./Themes/KnowledgeKorner/clock_1_shadow_space_website_6_mob.png 1x" />
					
				<img id="man_2_shadow_space_website_6_m_k_k_mob" src="./Themes/KnowledgeKorner/man_2_shadow_space_website_6_m.png" srcset="./Themes/KnowledgeKorner/man_2_shadow_space_website_6_m.png 1x" />
					
				<div id="date_shadow_space_website_6_mo_eh_k_k_mob">
					<span>{eve.organiserName}</span>
				</div>
				<img id="seats_shadow_space_website_6_m_k_k_mob" src="./Themes/KnowledgeKorner/seats_shadow_space_website_6_m.png" srcset="./Themes/KnowledgeKorner/seats_shadow_space_website_6_m.png 1x" />
					
				<div id="date_shadow_space_website_6_mo_ej_k_k_mob">
					<span>{eve.seats}</span>
				</div>
				<img id="price-tag_1_shadow_space_websi_k_k_mob" src="./Themes/KnowledgeKorner/price-tag_1_shadow_space_websi_mob.png" srcset="./Themes/KnowledgeKorner/price-tag_1_shadow_space_websi_mob.png 1x" />
					
				<div id="date_shadow_space_website_6_mo_el_k_k_mob">
					<span>₹ {eve.cost}</span>
				</div>
			</div>
		</div>
        ))}
	</div>
	<div id="oofering_lbl_med_kk_mob">
		<span>My Events</span>
	</div>
	<div id="footer_grp_website_6_mob">
		<img id="n_3197677_SL-030720-28740-05" src="./Themes/KnowledgeKorner/n_3197677_SL-030720-28740-05_mob.png" srcset="./Themes/KnowledgeKorner/n_3197677_SL-030720-28740-05_mob.png 1x" />
			
		<div id="Group_maps_mob_kk">
			<svg class="Path_448_mob_kk" viewBox="4578.628 19892.477 323.11 195.998">
				<path id="Path_448_mob_kk" d="M 4578.6279296875 19893.13671875 L 4901.73779296875 19892.4765625 L 4900.9365234375 20080.05078125 L 4762.78173828125 20080.28125 L 4762.78173828125 20081.431640625 L 4760.4091796875 20081.431640625 L 4760.4091796875 20083.31640625 L 4814.71875 20083.31640625 L 4816.318359375 20088.474609375 L 4662.70361328125 20088.474609375 L 4664.38623046875 20083.31640625 L 4719.93701171875 20083.31640625 L 4719.93701171875 20081.431640625 L 4716.2373046875 20081.431640625 L 4716.2373046875 20080.28125 L 4578.6279296875 20080.05078125 L 4578.6279296875 19893.13671875 Z">
				</path>
			</svg>
			{/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15547.185741508381!2d77.64215715!3d13.04862565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1688370086554!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="Path_map_location_mob" viewBox="4586.469 19900.395 313.597 173.951">
				<path id="Path_map_location_mob" d="M 4588.0205078125 20074.345703125 L 4586.46875 19900.568359375 L 4900.0654296875 19900.39453125 L 4899.02685546875 20074.32421875 L 4588.0205078125 20074.345703125 Z">
				</path>
			</iframe> */}

            <iframe class="Path_map_location_mob" width="1366" height="330" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"}>
	<rect id="Path_map_location_mob" rx="0" ry="0" x="0" y="0" width="1366" height="330">
		</rect>
	</iframe>

		</div>
		<div id="n_k_k_mobile_number_shad_mob">
			<span>{props.data.businessPhone}</span>
		</div>
		<div id="k_k_email_mob_">
			<span>{props.data.businessEmail}</span>
		</div>
		<div id="address_med_k_k_websi_mob">
			<span>{props.data.businessAddress}</span>
		</div>
		<img id="location_img_shadow_space_webs_k_k_mob" src="./Themes/KnowledgeKorner/location_img_shadow_space_webs_mob.png" srcset="./Themes/KnowledgeKorner/location_img_shadow_space_webs_mob.png 1x" />
			
		<img id="email_address_shadow_space_web_k_k_mob" src="./Themes/KnowledgeKorner/email_address_shadow_space_web_mob.png" srcset="./Themes/KnowledgeKorner/email_address_shadow_space_web_mob.png 1x" />
			
		<img id="phone_number_shadow_space_webs_k_k_mob" src="./Themes/KnowledgeKorner/phone_number_shadow_space_webs_mob.png" srcset="./Themes/KnowledgeKorner/phone_number_shadow_space_webs_mob.png 1x" />
			
		<div id="Connect_with_us_and_unlock_lim_k_k_mob">
			<span>Embrace the unfamiliar, ignite your potential, and explore boundless possibilities.</span>
		</div>
		<img id="quote_left_med_shadow_space_we_k_k_mob" src="./Themes/KnowledgeKorner/quote_left_med_shadow_space_we_mob.png" srcset="./Themes/KnowledgeKorner/quote_left_med_shadow_space_we_mob.png 1x" />
			
		<img id="quote_right_med_shadow_space_w_k_k_mob" src="./Themes/KnowledgeKorner/quote_right_med_shadow_space_w.png" srcset="./Themes/KnowledgeKorner/quote_right_med_shadow_space_w.png 1x" />
			
		<div id="socials_grp_shadow_space_websi_k_k_mob">
        {props.data.linkedInUrl?
			<div title="LinkedIn" id="linked_in_grp_shadow_space_web_k_k_mob">
				<svg class="Ellipse_5_med_shadow_space_web_k_k_mob">
					<ellipse id="Ellipse_5_med_shadow_space_web_k_k_mob" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<div id="linked_in_shadow_space_website_k_k_mob">
					<a href={props.data.linkedInUrl} target="_blank">
					<svg class="n_website_mob_5layout__k_k_mob" viewBox="-15.255 0 15.439 14.566">
						<path id="n_website_mob_5layout__k_k_mob" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
						</path>
					</svg>
				</a>
					<svg class="linked_in_i_shadow_space_websi_k_k_mob" viewBox="0.931 -22.783 4.553 14.427">
						<path id="linked_in_i_shadow_space_websi_k_k_mob" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
						</path>
					</svg>
					<svg class="top_dot_i_shadow_space_website_k_k_mob" viewBox="-4.392 0 5.527 5.006">
						<path id="top_dot_i_shadow_space_website_k_k_mob" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
						</path>
					</svg>
				</div>
			</div>
            :""}
            {props.data.instagramUrl?	
			<div title="Instagram" id="Insta_grp_shadow_space_website_k_k_mob">
				<svg class="Ellipse_4_shadow_space_website_k_k_mob">
					<ellipse id="Ellipse_4_shadow_space_website_k_k_mob" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<a href={props.data.instagramUrl} target="_blank">
				<img id="instagramshadow_space_website__k_k_mob" src="./Themes/KnowledgeKorner/instagramshadow_space_website__mob.png" srcset="./Themes/KnowledgeKorner/instagramshadow_space_website__mob.png 1x" />
					
				</a>
			</div>
            :""}
            {props.data.whatsAppNumber?
			<div title="WhatsApp" id="whatsapp_grp_shadow_space_webs_k_k_mob">
				<svg class="Ellipse_3_med_shadow_space_web_k_k_mob">
					<ellipse id="Ellipse_3_med_shadow_space_web_k_k_mob" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
                <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="whatsapp_lbl_shadow_space_webs_k_k_mob" src="./Themes/KnowledgeKorner/whatsapp_lbl_shadow_space_webs_mob.png" srcset="./Themes/KnowledgeKorner/whatsapp_lbl_shadow_space_webs_mob.png 1x" />
				</a>	
			</div>
            :""}
		</div>
		<a href="https://operate.live" target="_blank">
		<div id="n_Copyright_Operate_Live_med_s_k_k_mob">
			<span>© Copyright Operate Live (OPC) Private Limited.<br/>All Rights Reserved</span>
		</div>
		</a>
	</div>
</div>
  )
}

export default KnowledgeKornerMob
