import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioRequirementMobUI.css';
import { UserContext } from '../UserContext';
import MyOffandComOffMob from './MyOffandComOffMob';
import MyReqandComReqMob from './MyReqandComReqMob';

const MyPortfolioRequirementMobUI = (props) => {
  return (
	<div id="portfolio_req">
	<div id="heading_grp_offreqevent_req_mo">
		<div id="req_offreqevent_req_mob">
			<span>Requirements</span>
		</div>
		<div id="selected_grp_offreqevent_req_m">
			<div id="requirements_offreqevent_req_m" onClick={()=>props.selectedTab("offerings")}>
				<span>Offerings</span>
			</div>
		</div>
		<div id="events_offreqevent_event_mob" onClick={()=>props.selectedTab("events")}>
			<span>Events</span>
		</div>
		<svg class="underline_offreqevent_req_mob">
			<rect id="underline_offreqevent_req_mob" rx="0" ry="0" x="0" y="0" width="91" height="3">
			</rect>
		</svg>
	</div>
	<img id="settings-sliders" src="./MyPortfolio/settings-sliders_offreqevent_o.png" srcset="./MyPortfolio/settings-sliders_offreqevent_o.png 1x"  />
		<MyReqandComReqMob />
</div>
    

  )
}

export default MyPortfolioRequirementMobUI
