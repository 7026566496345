import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioReqCardsMob.css';
import { UserContext } from '../UserContext';

const MyPortfolioReqCardsMob = (props) => {


	const {user} = useContext(UserContext);
	 


	const handleLike = (reqId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/requirement/updatetrophy/"+reqId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
		  };
	

  return (
	<div id="REQM_card_1_reqoffevent_off">
	<svg class="REQM_Base_reqoffevent_off">
		<rect id="REQM_Base_reqoffevent_off" rx="10" ry="10" x="0" y="0" width="170" height="170">
		</rect>
	</svg>
	<svg class="REQM_bdr_1_reqoffevent_off">
		<rect id="REQM_bdr_1_reqoffevent_off" rx="11" ry="11" x="0" y="0" width="40" height="22">
		</rect>
	</svg>
	<div id="REQM_reqoffevent_of">
		<span>{props.data.currency && JSON.parse(props.data.currency).symbol} {props.data.budget}</span>
	</div>
	<div id="REQM_Single_Tour_offevent_off">
		<span>{props.data.requirementTitle}</span>
	</div>
	<div id="REQM_date_reqoffevent_off">
		<span> {( props.data.deliveryDeadline ? (props.data.deliveryDeadline).substring(0,10):"")} | {props.data.deliveryLocation}</span>
	</div>
	<img id="REQM_online-shopping_reqoffevent_of" src={props.data.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultRequirementsImageUrl.split('/')[5]):"./MyPortfolio/Requirements/online-shopping_reqoffevent_of.png"} />
		
	<div id="REQM_Group_131">
		<svg class="REQM_Ellipse_1">
			<ellipse id="REQM_Ellipse_1" rx="13.5" ry="13.5" cx="13.5" cy="13.5">
			</ellipse>
		</svg>
		<img id="REQM_trophy_reqoffevent_off" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(props.data._id)} />
			
	</div>
	<div id="REQM_Group_130">
		<svg class="REQM_Rectangle_31">
			<rect id="REQM_Rectangle_31" rx="0" ry="0" x="0" y="0" width="24" height="15">
			</rect>
		</svg>
		<div id="REQM_n_234_reqoffevent_off">
			<span>{(JSON.parse(props.data.trophies)).length}</span>
		</div>
	</div>
	<div id="REQM_Group_132">
		<svg class="REQM_Ellipse_2">
			<ellipse id="REQM_Ellipse_2" rx="13.5" ry="13.5" cx="13.5" cy="13.5">
			</ellipse>
		</svg>
		<img id="REQM_share_1_reqoffevent_off" src="./MyPortfolio/Requirements/share_1_reqoffevent_off_PC.png" srcset="./MyPortfolio/Requirements/share_1_reqoffevent_off_PC.png 1x" />
			
	</div>
</div>
  )
}

export default MyPortfolioReqCardsMob
