import React from 'react'
import '../../styles/ADProfileUI.css'
const ADProfileUI = () => {
  return (
    <div>
      <div id="ad_profile_page_pc">
	<svg class="ad_bdr">
		<rect id="ad_bdr" rx="10" ry="10" x="0" y="0" width="100%" height="90">
		</rect>
	</svg>
	<div id="AD_AREA_lbl">
		{/* <span>AD AREA</span> */}
	</div>
</div>
    </div>
  )
}

export default ADProfileUI
