import React from 'react'
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import MyConnectionsAndRequestsMobUI from './Components/MobUI/MyConnectionsAndRequestsMobUI';
import MyReqandComReq from './Components/MyReqandComReq';
import SuggestionsMobUI from './Components/MobUI/SuggestionsMobUI';
import MeetingHomeMobUI from './Components/MobUI/MeetingHomeMobUI';
import MyCommunitiesNewMobUI from './Components/MobUI/MyCommunitiesNewMobUI';
import MarketMobUI from './Components/PC/MarketMobUI';
import MyOffandComOffMob from './Components/MobUI/MyOffandComOffMob';
import MyReqandComReqMob from './Components/MobUI/MyReqandComReqMob';
import ProfileMobUI from './Components/MobUI/ProfileMobUI';
import EditProfileMenuMobUI from './Components/MobUI/EditProfileMenuMobUI';
import PartnerProfileMobUI from './Components/MobUI/PartnerProfileMobUI';
import ViewOfferingMobUI from './Components/MobUI/ViewOfferingMobUI';
import ViewRequirementsMobUI from './Components/MobUI/ViewRequirementsMobUI';
import PeoplesConnectionsMobUI from './Components/MobUI/PeoplesConnectionsMobUI';
import PeoplesRequestsMobUI from './Components/MobUI/PeoplesRequestsMobUI';
import PeoplesSuggestionsMobUI from './Components/MobUI/PeoplesSuggestionsMobUI';
import PeoplesMobUI from './Components/MobUI/PeoplesMobUI';
import MyPortfolioOfferingMobUI from './Components/MobUI/MyPortfolioOfferingMobUI';
import MyPortfolioRequirementMobUI from './Components/MobUI/MyPortfolioRequirementMobUI';
import MyPortfolioMobUI from './Components/MobUI/MyPortfolioMobUI';
import CalanderDashboardMob from './Components/MobUI/CalanderDashboardMob';


function MobRoutes() {
  return (
    
    <Routes>
   <Route exact path="/" element={<ProfileMobUI/>}/>
    <Route exact path="/login" element={<ProfileMobUI/>}/>
    <Route exact path="/home" element={<ProfileMobUI/>}/>
     <Route exact path="/calenderDasboard" element={<CalanderDashboardMob/>}/> 
    <Route exact path="/myconnections" element={<MyConnectionsAndRequestsMobUI/>}/>
    <Route exact path="/communities" element={<MyCommunitiesNewMobUI/>}/>
    <Route exact path="/suggestions" element={<SuggestionsMobUI  />}/>
    <Route exact path="/offerings" element={<MyOffandComOffMob />}/>
    <Route exact path="/requirement" element={<MyReqandComReqMob />}/>
    <Route exact path="/market" element={<MarketMobUI/>}/>
    <Route exact path="/events" element={<MyReqandComReq/>}/>
    <Route exact path="/meeting" element={<MeetingHomeMobUI/>}/> 

    <Route exact path="/mypeople" element={<PeoplesMobUI/>}/>
    <Route exact path="/portfolio" element={<MyPortfolioMobUI/>}/>

    {/* <Route exact path="/test" element={<MyPortfolioOfferingMobUI/>}/> 
    <Route exact path="/test12" element={<MyPortfolioRequirementMobUI/>}/>  */}

    <Route path="/editprofile" element={<EditProfileMenuMobUI/>}/> 
    <Route
            path="*"
            element={ <PartnerProfileMobUI/> }
          />
    </Routes> 
  )
}

export default MobRoutes
