import React,{useState,useEffect,useContext} from 'react'
import { UserContext } from '../UserContext';
import '../../styles/EventsProfilePCUI.css'

const ProfileEventsPCUI = (props) => {
	const { user, setUser} = useContext(UserContext);
    const [events,setEvents] = useState([]);

useEffect(()=>{
	if(user)
	props.events && setEvents(props.events)
},[user,props.events])



  return (
      <div id="event_feed">
	<div id="Events_Feed_lbl_profile_page">
		<span>Events</span>
	</div>
	<div id="event_feed_scroll">
	{events.map((eve)=>(
		<div id="event_feed_grp">
			<svg class="bdr_card_1">
				<rect id="bdr_card_1" rx="10" ry="10" x="0" y="0" width="350" height="100">
				</rect>
			</svg>
			<img id="img_card_1_hmm" src={eve.eventImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+eve.eventImageUrl.split('/')[5]:"img_card_1_hmm.png"}  />
				
			<div id="Events_name_lbl">
				<span>{eve.title}</span>
			</div>
			<div id="Events_Feed_lbl_profile_page_l">
				<span>Starts </span><span style={{fontStyle:"normal",fontWeight:"normal"}}>{eve.startsOn.slice(0,10)} - {eve.startsOn.slice(11,16)} IST</span><br/><span>Ends </span><span style={{fontStyle:"normal",fontWeight:"normal"}}>{eve.endsOn.slice(0,10)} - {eve.endsOn.slice(11,16)} IST</span>
			</div>
			<div id="Category_-_Education_lbl">
				<span>Category - {eve.category}</span>
			</div>
			<div id="n_50000_lbl">
				<span>₹ {eve.cost}</span>
			</div>
		</div>
	))}
	</div>
</div>
  )
}

export default ProfileEventsPCUI
