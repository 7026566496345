import React,{useState,useEffect,useContext} from 'react'
import { UserContext } from '../UserContext';
import '../../styles/OfferingProfileFeed.css';

const ProfileOfferingsPC = (props) => {
const { user, setUser} = useContext(UserContext);
const [offerings,setOfferings] = useState([]);

useEffect(()=>{
	if(user)
	//getOfferings()
	props.offerings && setOfferings(props.offerings)
},[user,props.offerings])

	//Get applicant by id
	const getOfferings = ()=>{
		
	 let apiURL=process.env.REACT_APP_API_URL + "/offerings/getallofferings";
	 let apiParams = {
		 method:"GET",
	 };
	 fetch(apiURL, apiParams)
	 .then((res)=> (res.status===200 ? res.json() : res.text()))
	 .then((repos)=> {
		 if (repos.data) {
	 
		 setOfferings(repos.data)
		  
		 
		 }else alert(repos.message || repos);  
	 }) 
	 .catch((err) => alert(err));
   }
 

  return (
    <div>
      <div id="off_feed">
	<div id="offering_ffeed_grp">
		<svg class="off_feed_bdr">
			<rect id="off_feed_bdr" rx="15" ry="15" x="0" y="0" width="100%" height="251">
			</rect>
		</svg>
		<div id="Offerings_Feed_lbl">
			<span>Offerings</span>
		</div>
		<div id="off_feed_scroll">

			{offerings.map((rec,idx)=>(
			
			<div id="card_1_grp_profile_off" style={{left:idx*95+"px"}}>
				<img id="card_1_of_feed_1" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"profilebackground.png"} />
					
				<div id="Offering_lbl">
					<span>{rec.offeringTitle}</span>
				</div>
				<div id="Mohammed_Affan_lbl_1">
					<span>{rec.vendorName}</span>
				</div>
			</div>
		
			))
			}
		</div>
	</div>
</div>
    </div>
  )
}

export default ProfileOfferingsPC
