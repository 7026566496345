import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from './UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import '../styles/SuggestionPageIntroPopUp.css';
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SuggestionPageIntroPopUp = (props) => {

    var navigate = useNavigate();
    const { user, setUser} = useContext(UserContext);
    const [openGridModel,setOpenGridModel] = useState(false);
    const [cName,setCName]= useState("");
    const [cid,setCid] = useState("");
    const [intro,setIntro] = useState("");
    const [selectedCommunity,setSelectedCommunity] = useState([])
    const [communities,setCommunities] = useState([]);


  //UseEffect 

   useEffect(()=>{
if(user){

    if(props.openSuggestionModal){
        setOpenGridModel(props.openSuggestionModal)
        getCommunities()
      
    }
}    

   },[props.openSuggestionModal])


  useEffect(()=>{

    if(props.introData){
       
        setCid(props.introData.id)
        setCName(props.introData.name)
    }
  },[props.introData]) 


    const closeGridModel = ()=>{
        setOpenGridModel(false)
        props.closeSuggestionModal()
    }

//Get my Communities
const getCommunities = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/community/getmycommunity/"+user._id; 
  
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
           
          setCommunities(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  const addIntro = ()=>{

    ConnectionReq(cid,cName,"Requested");
    setOpenGridModel(false)
  
  }


  const ConnectionReq = async (id,name,status)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/connection/addconnections";
    let apiParams = {
        method:"POST",
        headers:{
          user1id:user._id,
          user1name:user.name,
          status:status,
           user2id:id,
           user2name:name,  
           intro:encodeURIComponent(intro),
           user1selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        },
        
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
      
          alert("Action Updated Successfully!!");
          
      //    props.showAlert("Job Added Successfully","success")
       
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
  
}

  return (
    <div>
    <Modal
        open={openGridModel}
        onClose={closeGridModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
      <div id="pop_up_intro_suggestion">
	<img id="suggestionBgImgPop" src="n_6170.png" srcset="n_6170.png 1x, n_6170@2x.png 2x" />
		
	{/* <div id="suggestions_pfp_name">
		<span>Submit</span>
	</div> */}

	<svg class="sug_border_title">
		<rect id="sug_border_title" rx="48" ry="48" x="0" y="0" width="118" height="265">
		</rect>
	</svg>

	<div id="suggestions_pfp_name_pop">
		<span>Introduce Yourself</span>
	</div>

	<textarea placeholder="Intro" class="intro_suggestion_pop" value={intro} onChange={(e)=> {
       setIntro(e.target.value);}} />
	

{/* 
     <Autocomplete
      multiple
    //   id="Rectangle_28"
      options={communities}
      size="small"
      disableCloseOnSelect
      getOptionLabel={(option) => option.communityName}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            //style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.communityName}
        </li>
        
      )}
      onChange={(event, newValue) => {
      setSelectedCommunity(newValue);
    }}
       sx={{width: '26ch' }}
      renderInput={(params) => (
        <TextField {...params} className="mycommunities_pop_sug" label="My Communities" placeholder="Communities" />
      )}
    />  */}

	<button class="cssbuttons-io-button" onClick={()=>addIntro()}>Submit</button>

    <img title="Close" id="close_sugesstion_PopUp_pc"  onClick={()=>{closeGridModel()}} src="close2.png" srcset="close2.png 1x, close2@2x.png 2x" />
</div>
</Modal>
    </div>
  )
}

export default SuggestionPageIntroPopUp
