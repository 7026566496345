import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/PeoplesRequestsMobUI.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import MyConnectionsUI from '../MyConnectionsUI';
import Switch from '@mui/material/Switch';
import MyRequestsMobUI from './MyRequestsMobUI';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PeoplesRequestsMobUI = (props) => {

//UseState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [toggle,setToggle] = useState(false);
const [ allStatus,setAllStatus] = useState("");
const [requests,setRequests]= useState([]);
const [selectedCommunity,setSelectedCommunity] = useState([])
const [communities,setCommunities] = useState([]);


 //UseEffect
 useEffect(()=>{
   
 
   if(user){
   
   
     getRequests()
    
   }
},[user])
  
const ConnectionReq = (id,status)=>{  
  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
if(selectedCommunity==="undefined"){
  setSelectedCommunity(JSON.parse([]))
}
    let apiURL=process.env.REACT_APP_API_URL + "/connection/updateconnection/"+id;
    let apiParams = {
        method:"POST",
        headers:{
          status:status,
          user2selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        },
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        alert("Action Updated Successfully!!");
          
      //props.showAlert("Job Added Successfully","success")
         setTimeout(() => {
          // getStatus()
           getRequests()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
  
}



//Converting Image
function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  var base64Flag = "data:image/jpeg;base64,";
  return base64Flag + window.btoa(binary);
}


const getRequests = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getrequests/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          // console.log(repos.data)
          setRequests(repos.data)
          
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};


  return (
    <div id="people_req">
    <div id="heading_grp_conreqsug_mob_req">
      <div id="req_conreqsug_mob_req">
        <span>Requests</span>
      </div>
      <div id="selected_grp_conreqsug_mob_req">
        <div id="con_conreqsug_mob_req" onClick={()=>props.selectedTab("Connections")}>
          <span>Connections</span>
        </div>
      </div>
      <div id="sug_conreqsug_mob_req" onClick={()=>props.selectedTab("Suggestions")}>
        <span>Suggestions</span>
      </div>
      <svg class="underline_conreqsug_mob_req">
        <rect id="underline_conreqsug_mob_req" rx="0" ry="0" x="0" y="0" width="59" height="3">
        </rect>
      </svg>
    </div>
      <MyRequestsMobUI />
  </div>
  )
}

export default PeoplesRequestsMobUI
