import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyEventsMobUI.css';
import { UserContext } from '../UserContext';
import EventsPopUI from '../PC/EventsPopUI';
import TrophiesAndCountMob from './TrophiesAndCountMob';

const MyEventsMobUI = () => {

    const {user} = useContext(UserContext)
    const [openEvents,setOpenEvents] = useState(false);
    const [events,setEvents] = useState([]);

    useEffect(()=>{
        if(user){
            getEvents()
        }
        
    },[user])
    
    
    
    //get comunity
    const getEvents = ()=>{
        
        let apiURL=process.env.REACT_APP_API_URL + "/events/getallevents"; 
    
        fetch(apiURL)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
             
              setEvents(repos.data);
            }else alert(repos.message || repos);  
        }) 
        .catch((err) => alert(err));
      }
    

  return (
    <div>
    <img id="add_event_offreqevent_event_mo" src="./MyPortfolio/Events/add_event_offreqevent_event_mo.png" onClick={()=>setOpenEvents(true)} /> 
    
    <div id="all_event_card_grp_mob">
		
			<div id="recent_evetns_overall_grp_mob">
				<svg class="recent_events_new_events_mob_ct">
					<linearGradient id="recent_events_new_events_mob_ct" spreadMethod="pad" x1="1.142" x2="0.009" y1="1.187" y2="0">
						<stop offset="0" stop-color="#40e196" stop-opacity="1"></stop>
						<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
					</linearGradient>
					<rect id="recent_events_new_events_mob_ct" rx="10" ry="10" x="0" y="0" width="100%" height="300">
					</rect>
				</svg>
				<svg class="recent_events_line_new_events_" viewBox="0 0 5 26">
					<path id="recent_events_line_new_events_" d="M 0 0 L 0 26">
					</path>
				</svg>
				<div id="recent_events_new_events_mob_cv">
					<span>Recent Events</span>
				</div>
				<div id="Scroll_recent_mob">
                {events.filter(rec=>rec.organiserId===user._id).map((eve)=>(
					<div id="card_recent_event_grp_mob">
						<div id="op_live_name_card_hodler_mob">
							<svg class="bdr_name_op_mob">
								<rect id="bdr_name_op_mob" rx="4" ry="4" x="0" y="0" width="161" height="21">
								</rect>
							</svg>
							<div id="Operate_Live_Event_lbl_mob">
								<span>{eve.title}</span>
							</div>
						</div>
						<img id="img_event_new_mob" src={eve.eventImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+eve.eventImageUrl.split('/')[5]:"./MyPortfolio/Events/idk_vent_new_pc.png"}  />
							
						<svg class="shade_img_event_new_mob">
							<rect id="shade_img_event_new_mob" rx="8" ry="8" x="0" y="0" width="177.664" height="98.427">
							</rect>
						</svg>
						<div id="date_of_evnet_mob">
							<svg class="bdr_date_mob">
								<rect id="bdr_date_mob" rx="5" ry="5" x="0" y="0" width="73" height="31">
								</rect>
							</svg>
							<div id="n_3th_Jan_2023_mob_event">
								<span>{eve.startsOn.slice(0,10)} - {eve.startsOn.slice(11,16)} IST</span>
							</div>
						</div>
						<div id="recent_ceven_trophie_mob">
							<svg class="bdr_trophie_mob">
								<rect id="bdr_trophie_mob" rx="5" ry="5" x="0" y="0" width="73" height="25">
								</rect>
							</svg>
							{/* <img id="trophy_img_event_new_mob" src="./MyPortfolio/Events/trophy_img_event_new_mob.png" srcset="./MyPortfolio/Events/trophy_img_event_new_mob.png 1x" />
								
							<div id="n_234_event_recent_mob">
								<span>1234</span>
							</div> */}

							<div id='trophy_img_event_new_mob'>
					<TrophiesAndCountMob portfolioType={"events"} portfolioId={eve._id}/>
					</div>

						</div>
					</div>
                    ))}
				</div>
			</div>
            <div id="trending_events_overall_grp_mo">
				<svg class="trending_events_new_events_mob_ca">
					<linearGradient id="trending_events_new_events_mob_ca" spreadMethod="pad" x1="1.142" x2="0.009" y1="1.187" y2="0">
						<stop offset="0" stop-color="#ff2626" stop-opacity="1"></stop>
						<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
					</linearGradient>
					<rect id="trending_events_new_events_mob_ca" rx="10" ry="10" x="0" y="0" width="100%" height="300">
					</rect>
				</svg>
				<svg class="trending_events_line_new_event" viewBox="0 0 5 26">
					<path id="trending_events_line_new_event" d="M 0 0 L 0 26">
					</path>
				</svg>
				<div id="trending_events_new_events_mob_cc">
					<span>Trending Events</span>
				</div>
				<div id="Scrolltrending_mob">
				{events.filter(rec=>rec.organiserId===user._id).map((eve)=>(
					<div id="trending_events_card_1_grp_mob">
						<div id="title_card_1_trend">
							<svg class="op_name_trend_mob">
								<rect id="op_name_trend_mob" rx="4" ry="4" x="0" y="0" width="161" height="21">
								</rect>
							</svg>
							<div id="Operate_Live_Event_trend_mob">
								<span>{eve.title}</span>
							</div>
						</div>
						<img id="img_event_trend_mob" src={eve.eventImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+eve.eventImageUrl.split('/')[5]:"./MyPortfolio/Events/idk_vent_new_pc.png"} />
							
						<svg class="shade_img_event_trend_mob">
							<rect id="shade_img_event_trend_mob" rx="8" ry="8" x="0" y="0" width="177.664" height="98.427">
							</rect>
						</svg>
						<div id="trohpie_trend_mob">
							<svg class="bdr_trophie_trend_mob">
								<rect id="bdr_trophie_trend_mob" rx="5" ry="5" x="0" y="0" width="73" height="25">
								</rect>
							</svg>
							{/* <img id="trophy_img_event_trend_mob" src="./MyPortfolio/Events/trophy_img_event_trend_mob.png" srcset="./MyPortfolio/Events/trophy_img_event_trend_mob.png 1x" />
								
							<div id="n_234_com_event_trend_mob">
								<span>1234</span>
							</div> */}
							<div id='trophy_img_event_trend_mob'>
					<TrophiesAndCountMob portfolioType={"events"} portfolioId={eve._id}/>
					</div>
						</div>
						<div id="date_ofg_event_trend_mob">
							<svg class="bdr_trophie_trend_mob_cp">
								<rect id="bdr_trophie_trend_mob_cp" rx="5" ry="5" x="0" y="0" width="73" height="31">
								</rect>
							</svg>
							<div id="n_3th_Jan_2023_trend_mob">
								<span>{eve.startsOn.slice(0,10)} - {eve.startsOn.slice(11,16)} IST</span>
							</div>
						</div>
					</div>
					))}
				</div>
			</div>
            <div id="completed_events_overall_grp_m">
				<svg class="completed_events_new_events_pa_br">
					<linearGradient id="completed_events_new_events_pa_br" spreadMethod="pad" x1="1.142" x2="0.009" y1="1.187" y2="0">
						<stop offset="0" stop-color="#26a1ff" stop-opacity="1"></stop>
						<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
					</linearGradient>
					<rect id="completed_events_new_events_pa_br" rx="10" ry="10" x="0" y="0" width="100%" height="300">
					</rect>
				</svg>
				<svg class="completed_events_line_new_even" viewBox="0 0 5 26">
					<path id="completed_events_line_new_even" d="M 0 0 L 0 26">
					</path>
				</svg>
				<div id="completed_events_new_events_mo">
					<span>Completed Events</span>
				</div>
				<div id="Scroll_completed">
				{events.filter(rec=>rec.organiserId===user._id).map((eve)=>(
					<div id="completed_events_mob_grp">
						<div id="op_live_name_title_mob">
							<svg class="op_live_bdr_com_mob">
								<rect id="op_live_bdr_com_mob" rx="4" ry="4" x="0" y="0" width="161" height="21">
								</rect>
							</svg>
							<div id="Operate_Live_Event_com_mob">
								<span>{eve.title}</span>
							</div>
						</div>
						<img id="img_event_new_com_mob" src={eve.eventImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+eve.eventImageUrl.split('/')[5]:"./MyPortfolio/Events/idk_vent_new_pc.png"} />
							
						<svg class="shade_img_event_com_mob">
							<rect id="shade_img_event_com_mob" rx="8" ry="8" x="0" y="0" width="177.664" height="98.427">
							</rect>
						</svg>
						<div id="trohpie_completed_events">
							<svg class="bdr_trophie_com_mob">
								<rect id="bdr_trophie_com_mob" rx="5" ry="5" x="0" y="0" width="73" height="23">
								</rect>
							</svg>
							{/* <img id="trophy_img_event_com_mob" src="./MyPortfolio/Events/trophy_img_event_com_mob.png" srcset="./MyPortfolio/Events/trophy_img_event_com_mob.png 1x" />
								
							<div id="n_234_com_event_com_mob">
								<span>1234</span>
							</div> */}
							<div id='trophy_img_event_com_mob'>
					<TrophiesAndCountMob portfolioType={"events"} portfolioId={eve._id}/>
					</div>
						</div>
						<div id="date_com_event_mob">
							<svg class="bdr_trophie_mob_com">
								<rect id="bdr_trophie_mob_com" rx="5" ry="5" x="0" y="0" width="73" height="31">
								</rect>
							</svg>
							<div id="n_3th_Jan_2023__mob_com_event">
								<span>{eve.startsOn.slice(0,10)} - {eve.startsOn.slice(11,16)} IST</span>
								
							</div>
						</div>
					</div>
					))}
				</div>
			</div>
			
		</div>
        {openEvents=== true ?<EventsPopUI  openEvents={openEvents} closeEvents={()=>{setOpenEvents(false);getEvents()}}/>:""}
        </div>    
  )
}

export default MyEventsMobUI
