import React, { useContext } from 'react'
import {Button} from "@mui/material";
import { SocketContext } from '../../SocketContext';
import '../../styles/NotificationsMobUI.css';

const NotificationsMobUI = () => {
	
    const {answerCall,rejectCall,call,callAccepted,callRejected,leaveCall,me,
      setName,} = useContext(SocketContext);


  return (
    <div>
   {call.isRecievedCall && !(callAccepted || callRejected) &&  (   
  <div id="pop_caller_mob">
	<img id="bg_design_callpop_mob" src="bg_design_callpop.png" />
		
	<div id="lbl_name_callpop_mob">
		<span>{call.name} is calling : </span>
	</div>
	<button id="btn_grp_popcall_mob" onClick={answerCall}>
		<svg class="rec_accept_popcall_mob">
			<rect id="rec_accept_popcall_mob" rx="10" ry="10" x="0" y="0" width="53" height="25">
			</rect>
		</svg>
		<img id="accept_call_popcall_mob" src="accept_call_popcall.png" srcset="accept_call_popcall.png 1x, accept_call_popcall@2x.png 2x" />
			
	</button>
	<button id="btn_grp_decline_popcall_mob" onClick={rejectCall}>
		<svg class="decline_rec_popcall_mob">
			<rect id="decline_rec_popcall_mob" rx="10" ry="10" x="0" y="0" width="53" height="25">
			</rect>
		</svg>
		<img id="decline_img_popcall_mob" src="decline_img_popcall.png" srcset="decline_img_popcall.png 1x, decline_img_popcall@2x.png 2x" />
			
	</button>
  </div>
        )}

   { callRejected && (
        <div id="pop_caller_mob">
	<img id="bg_design_callpop_mob" src="bg_design_callpop.png" srcset="bg_design_callpop.png 1x, bg_design_callpop@2x.png 2x" />
		
	<div id="lbl_name_callpop_mob">
		<span>Call has been rejected!!! </span>
	</div>
  </div>
  )}
    </div>
  )
}

export default NotificationsMobUI