import React from 'react';
import "../../../styles/CycleHavenMob.css";

function CycleHavenMob(props) {
  return (
    <div id="CycleHaven_mob">
	<img id="CHMAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/CycleHaven/Anime_Edit.png"} />
		
	<div id="CHMGroup_15122">
		<div id="CHMGroup_15040">
			<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="CHMRectangle_737">
				<rect id="CHMRectangle_737" rx="0" ry="0" x="0" y="0" width="281" height="158">
				</rect>
			</iframe>
		</div>
		<div id="CHMGroup_15050">
			<div id="CHMsocials_grp_shadow_space_websi">
{props.data.linkedInUrl?
				<div title="LinkedIn" id="CHMlinked_in_grp_shadow_space_web">
					<svg class="CHMmed_shadow_space_website_7">
						<ellipse id="CHMmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="CHMlinked_in_shadow_space_website">
						<a href={props.data.linkedInUrl} target="_blank">
						<svg class="CHMn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="CHMn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="CHMlinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="CHMlinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="CHMtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="CHMtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>
:""}
{props.data.instagramUrl?	
				<div title="Instagram" id="CHMInsta_grp_shadow_space_website">
					<svg class="CHMshadow_space_website_7">
						<ellipse id="CHMshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={props.data.instagramUrl} target="_blank">
					<img id="CHMinstagramshadow_space_website_" src="./Themes/CycleHaven/instagramshadow_space_website_.png" srcset="./Themes/CycleHaven/instagramshadow_space_website_.png 1x" />
						
					</a>
				</div>		
:""}
{props.data.whatsAppNumber?
				<div title="WhatsApp" id="CHMwhatsapp_grp_shadow_space_webs">
					<svg class="CHMmed_shadow_space_website_7_bk">
						<ellipse id="CHMmed_shadow_space_website_7_bk" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
<img id="CHMwhatsapp_lbl_shadow_space_webs" src="./Themes/CycleHaven/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/CycleHaven/whatsapp_lbl_shadow_space_webs.png 1x" />
</a>		
				</div>:""}
			</div>
			<div id="CHMn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="CHMaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="CHMaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="CHMlocation_img_shadow_space_webs" src="./Themes/CycleHaven/location_img_shadow_space_webs.png" srcset="./Themes/CycleHaven/location_img_shadow_space_webs.png 1x" />
				
			<img id="CHMemail_address_shadow_space_web" src="./Themes/CycleHaven/email_address_shadow_space_web.png" srcset="./Themes/CycleHaven/email_address_shadow_space_web.png 1x" />
				
			<img id="CHMphone_number_shadow_space_webs" src="./Themes/CycleHaven/phone_number_shadow_space_webs.png" srcset="./Themes/CycleHaven/phone_number_shadow_space_webs.png 1x" />
				
			<div id="CHMn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited.<br/> All Rights Reserved</span>
			</div>
		</div>
		<div id="CHMContact_Us">
			<span>Contact Us!</span>
		</div>
	</div>
	<div id="CHMCTA">
		<div id="CHMScroll_Group_92">
			<div id="CHMLorem_ipsum_dolor_sit_amet_con">
				<span>{props.data.introduction}</span>
			</div>
		</div>
		<div id="CHMCompany_Name">
			<span>{props.data.businessName}</span>
		</div>
	</div>
	<div id="CHMGroup_15155">
		<svg class="CHMEllipse_45">
			<ellipse id="CHMEllipse_45" rx="126" ry="4" cx="126" cy="4">
			</ellipse>
		</svg>
		<img id="CHMwhite-bicycle-standing-park" src="./Themes/CycleHaven/white-bicycle-standing-park.png" srcset="./Themes/CycleHaven/white-bicycle-standing-park.png 1x" />
			
	</div>
	<div id="CHMGroup_15109">
		<div id="CHMGroup_15105">
			<img id="CHMmika-baumeister-dp9AxaKHdVg-un" src="./Themes/CycleHaven/mika-baumeister-dp9AxaKHdVg-un.png" srcset="./Themes/CycleHaven/mika-baumeister-dp9AxaKHdVg-un.png 1x" />
				
			<div id="CHMGroup_15104">
				<div id="CHMScroll_Group_28_cb">
					<div id="CHMIt_is_a_long_established_fact__cc">
						<span>{props.data.vision}</span>
					</div>
				</div>
				<div id="CHMVISION_cd">
					<span>VISION</span>
				</div>
			</div>
		</div>
		<div id="CHMGroup_15106">
			<img id="CHMmika-baumeister-dp9AxaKHdVg-un_b" src="./Themes/CycleHaven/mika-baumeister-dp9AxaKHdVg-un_b.png" srcset="./Themes/CycleHaven/mika-baumeister-dp9AxaKHdVg-un_b.png 1x" />
				
			<div id="CHMGroup_15104_ca">
				<div id="CHMScroll_Group_28_cb">
					<div id="CHMIt_is_a_long_established_fact__cc">
                    <span>{props.data.vision}</span>
					</div>
				</div>
				<div id="CHMVISION_cd">
					<span>VISION</span>
				</div>
			</div>
		</div>
		<div id="CHMGroup_15106_ce">
			<img id="CHMpatrick-tomasso-1NTFSnV-KLs-un" src="./Themes/CycleHaven/patrick-tomasso-1NTFSnV-KLs-un.png" srcset="./Themes/CycleHaven/patrick-tomasso-1NTFSnV-KLs-un.png 1x" />
				
			<div id="CHMGroup_15104_cg">
				<div id="CHMScroll_Group_28_ch">
					<div id="CHMIt_is_a_long_established_fact__ci">
                    <span>{props.data.mission}</span>
					</div>
				</div>
				<div id="CHMMISSION">
					<span>MISSION</span>
				</div>
			</div>
		</div>
		<div id="CHMGroup_15106_ck">
			<img id="CHMframes-for-your-heart-iOLHAIax" src="./Themes/CycleHaven/frames-for-your-heart-iOLHAIax.png" srcset="./Themes/CycleHaven/frames-for-your-heart-iOLHAIax.png 1x" />
				
			<div id="CHMGroup_15104_cm">
				<div id="CHMScroll_Group_28_cn">
					<div id="CHMIt_is_a_long_established_fact__co">
                    <span>{props.data.principles}</span>
					</div>
				</div>
				<div id="CHMPRINCIPLES">
					<span>PRINCIPLES</span>
				</div>
			</div>
		</div>
	</div>
	<div id="CHMScroll_Group_62">
		<div id="CHMGroup_15031">
			<svg class="CHMRectangle_112">
				<rect id="CHMRectangle_112" rx="15" ry="15" x="0" y="0" width="296" height="240">
				</rect>
			</svg>
			<img id="CHMMask_Group_4" src="./Themes/CycleHaven/Mask_Group_4.png" srcset="./Themes/CycleHaven/Mask_Group_4.png 1x" />
				
			<div id="CHMGroup_9694">
				<div id="CHMBangalore">
					<span>Bangalore</span>
				</div>
				<div id="CHMgeo-alt">
					<svg class="CHMPath_2927" viewBox="4.5 0 12.521 17.482">
						<path id="CHMPath_2927" d="M 10.76074028015137 17.482421875 C 10.76074028015137 17.482421875 17.02148246765137 11.26984977722168 17.02148246765137 6.555908679962158 C 17.02148246765137 2.935179948806763 14.21845054626465 0 10.76074028015137 0 C 7.303027153015137 0 4.499998092651367 2.935180187225342 4.499998092651367 6.555908679962158 C 4.499999046325684 11.26984977722168 10.76074028015137 17.482421875 10.76074028015137 17.482421875 Z M 10.76074028015137 9.833863258361816 C 12.48959636688232 9.833863258361816 13.89111137390137 8.36627197265625 13.89111137390137 6.555908679962158 C 13.89111137390137 4.745543956756592 12.48959636688232 3.277954339981079 10.76074028015137 3.277954339981079 C 9.03188419342041 3.277954339981079 7.630370140075684 4.745543956756592 7.630370140075684 6.555908679962158 C 7.630370140075684 8.36627197265625 9.03188419342041 9.833863258361816 10.76074028015137 9.833863258361816 Z">
						</path>
					</svg>
				</div>
			</div>
			<div id="CHMCycle">
				<span>Cycle</span>
			</div>
		</div>
	</div>
	<div id="CHMOur_Collection">
		<span>Our Collection</span>
	</div>
</div>
  )
}

export default CycleHavenMob