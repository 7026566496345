import React from 'react'
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import MyConnectionsAndRequestsUI from './Components/MyConnectionsAndRequestsUI';
import MyOffandComOff from './Components/MyOffandComOff';
import MyReqandComReq from './Components/MyReqandComReq';
import SuggestionsUI from './Components/SuggestionsUI';
import MeetingHomeUI from './MeetingHomeUI';
import MyCommunitiesNewUI from './Components/PC/MyCommunitiesNewUI';
import MarketPCUI from './Components/PC/MarketPCUI';
import ProfileUIPC from './Components/PC/ProfileUIPC';
import PartnerProfileUIPC from './Components/PC/PartnerProfileUIPC';
import MyOffandComOffNewUI from './Components/PC/MyOffandComOffNewUI';
import MyReqandComReqNewUI from './Components/PC/MyReqandComReqNewUI';
import MyEventsandComEventsUI from './Components/PC/MyEventsandComEventsUI';
import MyPortfolioUI from './Components/PC/MyPortfolioUI';
import {RemoveTrailingSlash} from './RemoveTrailingSlash';
import BroadcastsUI from './Components/PC/BroadcastsUI';
import PeoplesConnectionsUI from './Components/PC/PeoplesConnectionsUI';
import PeoplesRequestsUI from './Components/PC/PeoplesRequestsUI';
import PeoplesSuggestionsUI from './Components/PC/PeoplesSuggestionsUI';
import PeoplesUI from './Components/PC/PeoplesUI';
import CalanderDashboard from './Components/PC/CalanderDashboard';

function PCRoutes() {
  return (
    <>
    <RemoveTrailingSlash />
    <Routes>
   <Route exact path="/" element={<ProfileUIPC/>}/>
    <Route exact path="/login" element={<ProfileUIPC/>}/>
    <Route exact path="/home" element={<ProfileUIPC/>}/> 
    <Route exact path="/calenderDasboard" element={<CalanderDashboard/>}/> 
    <Route exact path="/myconnections" element={<MyConnectionsAndRequestsUI/>}/>
    <Route exact path="/communities" element={<MyCommunitiesNewUI/>}/>
    <Route exact path="/suggestions" element={<SuggestionsUI/>}/>

    <Route exact path="/portfolio" element={<MyPortfolioUI/>}/>
    <Route exact path="/mypeople" element={<PeoplesUI/>}/>

    {/* <Route exact path="/test" element={<PeoplesRequestsUI/>}/>
    <Route exact path="/test1" element={<PeoplesConnectionsUI/>}/>
    <Route exact path="/test2" element={<PeoplesSuggestionsUI/>}/> */}
    {/* <Route exact path="/requirement" element={<MyReqandComReqNewUI />}/> */}
    <Route exact path="/marketpage" element={<MarketPCUI/>}/>
    <Route exact path="/events" element={<BroadcastsUI/>}/>
    <Route exact path="/meeting" element={<MeetingHomeUI />}/> 
    <Route
            path="*"
            element={<PartnerProfileUIPC/>}
          />
    </Routes> 
</>
  )
}

export default PCRoutes
