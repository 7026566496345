import React, { useEffect, useState, useContext} from 'react'
import '../../styles/ImageSet.css'

const ImageSet = (props) => {
    const [selectedImage,setSelectedImage] = useState(1);

    useEffect(()=>{

    },[selectedImage])

    const handleChangeRightImage = (count)=>{
   
        if((count -(selectedImage +1)> 1)){
            setSelectedImage(selectedImage+1)
        }
       
      }

  
      const handleChangeLeftImage = (count) =>{
      
      if(selectedImage  > 1){
        setSelectedImage(selectedImage-1)
      }
        
      }
  

  return (

      <div id="card_grp_photos_com_off_pcZ">
    {/* {console.log(JSON.parse(props.imageArray).length)} */}
{/* {JSON.parse(props.imageArray) && console.log(JSON.parse(props.imageArray)[0] && (JSON.parse(props.imageArray)[0]).split('/')[5])}
{JSON.parse(props.imageArray) && console.log(JSON.parse(props.imageArray)[0] && (JSON.parse(props.imageArray)[0]))} */}

			<img id="photo_3_com_off_pcZ"  src={JSON.parse(props.imageArray).length > 1?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(JSON.parse(props.imageArray)[selectedImage] && (JSON.parse(props.imageArray)[selectedImage]).split('/')[5]):"photo_3_com_off_pcZ.png"} />
				
			<img id="photo_2_com_off_pcZ" title={((JSON.parse(props.imageArray).length) -(selectedImage +1)> 1)?"Click To View Other Images":'This is Last Image'} src={JSON.parse(props.imageArray).length > 2?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(JSON.parse(props.imageArray)[selectedImage + 1] && (JSON.parse(props.imageArray)[selectedImage + 1]).split('/')[5]):"photo_2_com_off_pcZ.png"} onClick={()=> handleChangeRightImage(JSON.parse(props.imageArray).length)}  />
				
			<img id="photo_1_com_off_pcZ" title={(selectedImage > 1) ?"Click To View Other Images":'This is First Image'} src={JSON.parse(props.imageArray).length > 0?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(JSON.parse(props.imageArray)[selectedImage - 1] && (JSON.parse(props.imageArray)[selectedImage - 1]).split('/')[5]):"photo_1_com_off_pcZ.png"} onClick={()=> handleChangeLeftImage(JSON.parse(props.imageArray).length)}/>
				 
		</div>
    
  )
}

export default ImageSet
