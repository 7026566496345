import React, { useEffect, useState, useContext} from 'react'
//import '../../styles/MyPortfolioMobUI.css';
import { UserContext } from '../UserContext';
import MyOffandComOffNewUI from '../PC/MyOffandComOffNewUI';
import MyReqandComReqNewUI from '../PC/MyReqandComReqNewUI';
import MyEventsandComEventsUI from '../PC/MyEventsandComEventsUI';
import MyPortfolioOfferingMobUI from './MyPortfolioOfferingMobUI';
import MyPortfolioRequirementMobUI from './MyPortfolioRequirementMobUI';
import MyPortfolioEventMobUI from './MyPortfolioEventMobUI';

const MyPortfolioMobUI = () => {

    const [selectedTab,setSelectedTab] = useState("offerings");

  return (
    <div>
    {selectedTab==="offerings" ? <MyPortfolioOfferingMobUI selectedTab={(rec)=>setSelectedTab(rec)} />:""}
    {selectedTab==="requirements" ?  <MyPortfolioRequirementMobUI selectedTab={(rec)=>setSelectedTab(rec)}/>:""}
    {selectedTab==="events" ?   <MyPortfolioEventMobUI selectedTab={(rec)=>setSelectedTab(rec)}/>:""}
    </div>
  )
}

export default MyPortfolioMobUI
