import React from 'react'
import "../../../styles/FashionFindsMob.css";

function FashionFindsMob(props) {
  return (
    <div id="Fashion_Finds_mob">
	<div id="vision_all_grp_web_9_mob">
		<div id="Scroll_vis_web_9_mob">
			<div id="BinQasem_vision_lbl_web_9_mob">
				<span>{props.data.vision}</span><br/>
			</div>
		</div>
		<div id="vision_lbl_web_9_mob">
			<span>vision</span>
		</div>
		<img id="Mask_vision_web_9_mob" src="./Themes/FashionFinds/Mask_vision_web_9_mob.png" srcset="./Themes/FashionFinds/Mask_vision_web_9_mob.png 1x" />
	</div>
	<div id="mision_grp_web_9_mob">
		<div id="Scroll_mission_web_9_mob">
			<div id="BinQasem_provid_web_mis_9_mob">
				<span>{props.data.mission}<br/></span><br/>
			</div>
		</div>
		<div id="MISSION_lbl_web_9_mob">
			<span>MISSION</span>
		</div>
		<img id="Mask_mission_web_9_mob" src="./Themes/FashionFinds/Mask_mission_web_9_mob.png" srcset="./Themes/FashionFinds/Mask_mission_web_9_mob.png 1x" />
	</div>
	<div id="princi_all_grp_web_9_mob">
		<div id="Scroll_princi_web_9_mob">
			<div id="BinQasem_provi_web_9_mob">
				<span>{props.data.principles}<br/></span><br/>
			</div>
		</div>
		<div id="principle_lbl_web_9_mob">
			<span>principle</span>
		</div>
		<img id="Mask_for_princi_web_9_mob" src="./Themes/FashionFinds/Mask_for_princi_web_9_mob.png" srcset="./Themes/FashionFinds/Mask_for_princi_web_9_mob.png 1x" />
	</div>
	<div id="our_services_web_9_mob_">
		<svg class="line_for_hanger_web_9_mob">
			<rect id="line_for_hanger_web_9_mob" rx="0" ry="0" x="0" y="0" width="357" height="5">
			</rect>
		</svg>
		<div id="Scroll_card_service_mob_9_them">
		{props.offeringsData.map((off,idx)=>(
			<div id="card_1_services_web_9_mob">
				<img id="hanger_img_web_9_mob" src="./Themes/FashionFinds/hanger_img_web_9_mob.png" srcset="./Themes/FashionFinds/hanger_img_web_9_mob.png 1x" />
					
				<img id="Mask_web_9_mob_card_1" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/FashionFinds/Mask_web_9_mob_card_1.png"}  />
				<div id="Bunda_Clothes_web_9_mob">
					<span>{off.offeringTitle}</span>
				</div>
				<svg class="badr_path_web_9_mob">
					<rect id="badr_path_web_9_mob" rx="0" ry="0" x="0" y="0" width="57" height="58">
					</rect>
				</svg>
				<div id="law_6_web_9_mob">
					<svg class="Path_6945_web_9_mob" viewBox="43.745 0 10.408 11.439">
						<path id="Path_6945_web_9_mob" d="M 43.94781112670898 8.609637260437012 L 47.85966873168945 11.35140132904053 C 48.07478713989258 11.50219345092773 48.37422561645508 11.45296287536621 48.52771377563477 11.23819541931152 L 49.63519668579102 9.688663482666016 C 49.7880973815918 9.474717140197754 49.73692321777344 9.178543090820312 49.52087783813477 9.027116775512695 L 49.30508422851562 8.875848770141602 C 50.46244430541992 7.970175743103027 51.58222198486328 6.383370399475098 52.06735992431641 4.997570991516113 L 52.2895622253418 5.153299331665039 C 52.50557708740234 5.304661750793457 52.8046875 5.25403881072998 52.95760345458984 5.040093898773193 L 54.06508636474609 3.490561008453369 C 54.2179069519043 3.276774406433105 54.16680908203125 2.980409383773804 53.95077133178711 2.829015016555786 L 50.03887939453125 0.08731424063444138 C 49.82286834716797 -0.06411128491163254 49.52375411987305 -0.01345682516694069 49.3708381652832 0.2005195468664169 L 48.26335525512695 1.75002133846283 C 48.11053466796875 1.963839530944824 48.16165924072266 2.260172605514526 48.3776741027832 2.411566257476807 L 48.6002311706543 2.567548274993896 C 47.42156982421875 3.497710704803467 46.29459762573242 5.07929515838623 45.82519149780273 6.436872959136963 L 45.60898208618164 6.285352230072021 C 45.39297103881836 6.13395881652832 45.09385299682617 6.18458080291748 44.94094467163086 6.398557662963867 L 43.83346557617188 7.948059558868408 C 43.68067169189453 8.161877632141113 43.73176574707031 8.45824146270752 43.94781112670898 8.609636306762695 Z">
						</path>
					</svg>
					<svg class="Path_6946_web_9_mob" viewBox="265.077 203.692 7.394 6.182">
						<path id="Path_6946_web_9_mob" d="M 271.8635864257812 207.2879638671875 L 266.73291015625 203.6919708251953 C 266.3019714355469 204.5116424560547 265.7181701660156 205.3356323242188 265.0769958496094 206.0199127197266 L 270.2023620605469 209.6122131347656 C 270.8513488769531 210.0670928955078 271.7475280761719 209.9148101806641 272.20654296875 209.2725982666016 C 272.6656188964844 208.6302947998047 272.5122680664062 207.7425689697266 271.8635864257812 207.2879638671875 Z">
						</path>
					</svg>
					<svg class="Path_6947_web_9_mob" viewBox="15.495 392.001 10.543 3.797">
						<path id="Path_6947_web_9_mob" d="M 25.55923652648926 392.9501037597656 L 25.07998466491699 392.9501037597656 C 24.81572914123535 392.9501037597656 24.60073661804199 392.7372436523438 24.60073661804199 392.4755859375 C 24.60073661804199 392.2134399414062 24.38616180419922 392.0009460449219 24.12148666381836 392.0009460449219 L 17.4119930267334 392.0009460449219 C 17.14731788635254 392.0009460449219 16.93274116516113 392.2134399414062 16.93274116516113 392.4755859375 C 16.93274116516113 392.7372436523438 16.71775054931641 392.9501037597656 16.4534912109375 392.9501037597656 L 15.97424125671387 392.9501037597656 C 15.70957183837891 392.9501037597656 15.4949951171875 393.1626586914062 15.4949951171875 393.4247741699219 L 15.4949951171875 395.3230590820312 C 15.4949951171875 395.5851745605469 15.70957183837891 395.7976684570312 15.97424125671387 395.7976684570312 L 25.55923652648926 395.7976684570312 C 25.82390594482422 395.7976684570312 26.03848266601562 395.5851745605469 26.03848266601562 395.3230590820312 L 26.03848266601562 393.4247741699219 C 26.03848266601562 393.1626586914062 25.82394027709961 392.9501037597656 25.55923652648926 392.9501037597656 Z">
						</path>
					</svg>
				</div>
			</div>
			))}
		</div>
		<svg class="holder_knob_web_9_mob">
			<ellipse id="holder_knob_web_9_mob" rx="13.5" ry="13.5" cx="13.5" cy="13.5">
			</ellipse>
		</svg>
	</div>
	<div id="our_services_lbl_web_9_mob">
		<span>our services</span>
	</div>
	<div id="footer_all_grp_web_9_mob">
		<svg class="footer_bg_web_9_mob">
			<rect id="footer_bg_web_9_mob" rx="0" ry="0" x="0" y="0" width="375" height="607">
			</rect>
		</svg>
		<div id="footer_all_design_web_9_mob">
			<img id="clothing_store_remove_bg" src="./Themes/FashionFinds/clothing_store_remove_bg.png" srcset="./Themes/FashionFinds/clothing_store_remove_bg.png 1x" />
				
			{/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15547.185741508381!2d77.64215715!3d13.04862565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1688370086554!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="Rectangle_680">
				<rect id="Rectangle_680" rx="0" ry="0" x="0" y="0" width="322" height="145">
				</rect>
			</iframe> */}
			<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="grp_map_ff_mob">
				<rect id="grp_map_ff_mob" rx="15" ry="15" x="0" y="0" width="385" height="211">
				</rect>
			</iframe>
		</div>
		<div id="For_Queries_info_web_9_mob_">
			<span>For Queries:{props.data.businessEmail}</span>
		</div>
		<div id="For_call__920012888_web_9_mob">
			<span>For call : {props.data.businessPhone}</span>
		</div>
		<svg class="telephone_web_9_mob" viewBox="0 0 13.412 14.475">
			<path id="telephone_web_9_mob" d="M 6.229238510131836 13.22547817230225 L 1.157747030258179 7.751975059509277 C -0.3816386461257935 6.090506553649902 -0.3897591829299927 3.395328283309937 1.157747030258179 1.725093960762024 L 2.6413893699646 0.1238417699933052 C 2.809903144836426 -0.05808338522911072 3.089877128601074 -0.03747367486357689 3.233531475067139 0.1692453175783157 L 5.758536815643311 3.802800416946411 C 5.87586498260498 3.97160792350769 5.860279083251953 4.207841396331787 5.722046852111816 4.357057094573975 L 4.212942600250244 5.985816955566406 C 4.037827968597412 6.17480993270874 4.037539958953857 6.481298446655273 4.212942600250244 6.670602798461914 L 7.23115062713623 9.928093910217285 C 7.406266689300537 10.11703205108643 7.690221309661865 10.11734199523926 7.865676403045654 9.928066253662109 L 9.374780654907227 8.299335479736328 C 9.513064384460449 8.150092124938965 9.731952667236328 8.133354187011719 9.888338088989258 8.259953498840332 L 13.25501918792725 10.98509979248047 C 13.44618892669678 11.13985443115234 13.46607112884521 11.44184875488281 13.29708957672119 11.62416934967041 L 11.81342029571533 13.22544860839844 C 10.27123260498047 14.88988876342773 7.77344274520874 14.8920955657959 6.229237079620361 13.22547817230225 Z">
			</path>
		</svg>
		<svg class="send_1_web_9_mob" viewBox="0 0 15.446 14.281">
			<path id="send_1_web_9_mob" d="M 15.44587898254395 0 L 0 8.033196449279785 L 4.93620777130127 9.723559379577637 L 12.54980564117432 3.12402606010437 L 6.758654594421387 10.34767818450928 L 6.76336145401001 10.34930324554443 L 6.757591724395752 10.34767818450928 L 6.757591724395752 14.28124904632568 L 9.525790214538574 11.29522895812988 L 13.03249454498291 12.49610424041748 L 15.44587898254395 0 Z">
			</path>
		</svg>
		<div id="socials_grp_med_web_9_mob">
		{props.data.linkedInUrl?
			<div title="LinkedIn" id="linked_in_grp_med_web_9_mob">
				<svg class="Ellipse_5_med_web_9_mob">
					<ellipse id="Ellipse_5_med_web_9_mob" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<div id="linked_in_website_9_layout">
					<a href={props.data.linkedInUrl} target="_blank">
					<svg class="n_website_9_layout" viewBox="-15.255 0 16.092 15.182">
						<path id="n_website_9_layout" d="M -5.186071872711182 9.679508821136551e-07 C -7.904781818389893 9.679508821136551e-07 -9.60834789276123 1.472553014755249 -9.923189163208008 2.504196882247925 L -9.923189163208008 0.1445503830909729 L -15.2549991607666 0.1445503830909729 C -15.18569564819336 1.397309064865112 -15.2549991607666 15.18160820007324 -15.2549991607666 15.18160820007324 L -9.923189163208008 15.18160820007324 L -9.923189163208008 7.044621467590332 C -9.923189163208008 6.592491626739502 -9.944971084594727 6.140363693237305 -9.807681083679199 5.815624713897705 C -9.440696716308594 4.911367893218994 -8.653267860412598 3.972789287567139 -7.237480163574219 3.972789287567139 C -5.38672399520874 3.972789287567139 -4.544512748718262 5.363495349884033 -4.544512748718262 7.400383949279785 L -4.544512748718262 15.18160820007324 L 0.8368034362792969 15.18160820007324 L 0.8368034362792969 6.820206165313721 C 0.8368034362792969 2.162956237792969 -1.813262462615967 9.679508821136551e-07 -5.186071872711182 9.679508821136551e-07">
						</path>
					</svg>
				</a>
					<svg class="linked_in_i_down_web_9_mob" viewBox="0.931 -22.783 4.746 15.038">
						<path id="linked_in_i_down_web_9_mob" d="M 0.9310001134872437 -7.745282173156738 L 5.676695346832275 -7.745282173156738 L 5.676695346832275 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -7.745282173156738 Z">
						</path>
					</svg>
					<svg class="top_dot_i_website_9_layout" viewBox="-4.392 0 5.761 5.218">
						<path id="top_dot_i_website_9_layout" d="M -1.493098974227905 0 C -3.245508193969727 0 -4.392000198364258 1.125370144844055 -4.392000198364258 2.611123323440552 C -4.392000198364258 4.06783390045166 -3.278510093688965 5.217626571655273 -1.560423135757446 5.217626571655273 L -1.526101112365723 5.217626571655273 C 0.2579902410507202 5.217626571655273 1.368839383125305 4.063873767852783 1.368839383125305 2.606502532958984 C 1.334516644477844 1.122730016708374 0.2579902410507202 0 -1.493098974227905 0">
						</path>
					</svg>
				</div>
			</div>
			:""}
			{props.data.instagramUrl?
			<div title="Instagram" id="Insta_grp_med_web_9_mob">
				<svg class="Ellipse_4_med_web_9_mob">
					<ellipse id="Ellipse_4_med_web_9_mob" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<a href={props.data.instagramUrl} target="_blank">
				<img id="instagram_website_9_layout" src="./Themes/FashionFinds/instagram_website_9_layout.png" srcset="./Themes/FashionFinds/instagram_website_9_layout.png 1x" />
					
				</a>
			</div>
			:""}
			{props.data.whatsAppNumber?
			<div title="WhatsApp" id="whatsapp_grp_med_web_9_mob">
				<svg class="Ellipse_3_med_web_9_mob">
					<ellipse id="Ellipse_3_med_web_9_mob" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="whatsapp_lbl_website9_layout" src="./Themes/FashionFinds/whatsapp_lbl_website9_layout.png" srcset="./Themes/FashionFinds/whatsapp_lbl_website9_layout.png 1x" />
				</a>
			</div>
			:""}
		</div>
		<svg class="Icon_metro-location_web_9_mob" viewBox="8.355 1.928 11.381 18.209">
			<path id="Icon_metro-location_web_9_mob" d="M 14.04507637023926 1.928033471107483 C 10.90240859985352 1.928033471107483 8.354801177978516 4.475640773773193 8.354801177978516 7.618309020996094 C 8.354801177978516 13.30858421325684 14.04507637023926 20.13691520690918 14.04507637023926 20.13691520690918 C 14.04507637023926 20.13691520690918 19.7353515625 13.30858421325684 19.7353515625 7.618309020996094 C 19.7353515625 4.475640773773193 17.18770790100098 1.928033709526062 14.04507637023926 1.928033709526062 Z M 14.04507637023926 11.10360240936279 C 12.12019920349121 11.10360240936279 10.55978298187256 9.543186187744141 10.55978298187256 7.618309020996094 C 10.55978298187256 5.69343090057373 12.12019920349121 4.133015155792236 14.04507637023926 4.133015155792236 C 15.9699535369873 4.133015155792236 17.53037071228027 5.69343090057373 17.53037071228027 7.618309020996094 C 17.53037071228027 9.543186187744141 15.9699535369873 11.10360240936279 14.04507637023926 11.10360240936279 Z M 11.84009552001953 7.618309020996094 C 11.84009552001953 6.40053653717041 12.82730388641357 5.413327217102051 14.04507637023926 5.413327217102051 C 15.26284980773926 5.413327217102051 16.25005722045898 6.40053653717041 16.25005722045898 7.618309020996094 C 16.25005722045898 8.836081504821777 15.26284980773926 9.823290824890137 14.04507637023926 9.823290824890137 C 12.82730388641357 9.823290824890137 11.84009552001953 8.836081504821777 11.84009552001953 7.618309020996094 Z">
			</path>
		</svg>
		<div id="For_call__920012888_web_9_mob_dr">
			<span>For call : {props.data.businessAddress}</span>
		</div>
		<div id="n_Copyrig_website_9_mobop_live">
			<span>© Copyright Operate Live (OPC) Private Limited.<br/>All Rights Reserved</span>
		</div>
	</div>
	<div id="header_all_grp_web_9_mob">
		<img id="n_02307280_web_9_mob" src="./Themes/FashionFinds/n_02307280_web_9_mob.png" srcset="./Themes/FashionFinds/n_02307280_web_9_mob.png 1x" />
			
		<svg class="shade_img_web_9_mob">
			<rect id="shade_img_web_9_mob" rx="0" ry="0" x="0" y="0" width="375" height="771">
			</rect>
		</svg>
		<div id="pfp_pic_web_9_mob">
			<svg class="bdr_pfp_web_9_mob">
				<ellipse id="bdr_pfp_web_9_mob" rx="35" ry="35" cx="35" cy="35">
				</ellipse>
			</svg>
			<img id="zane-lee-bXbi_web_9_mob" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/FashionFinds/zane-lee-bXbi_web_9_mob.png"}  />
				
		</div>
		<div id="ACT_NOW__SEIZE_THE_DEAL_web_9_">
			<span>ACT NOW &<br/>SEIZE THE DEAL</span>
		</div>
		<div id="Sell_btn_web_9_mob">
			<svg class="bdr_bids_web_9_mob">
				<rect id="bdr_bids_web_9_mob" rx="0" ry="0" x="0" y="0" width="145" height="38">
				</rect>
			</svg>
			<div id="START_BIDDING_web_9_mob">
				<span>START SHOPPING</span>
			</div>
		</div>
	</div>
</div>
  )
}

export default FashionFindsMob
