import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
 import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../styles/RequirementPopUpPC.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Currencies from "./Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RequirementPopUpPC = (props) => {

  //useState
const { user, setUser} = useContext(UserContext);
const [openRequirementPopUp,setOpenRequirementPopUp] = useState(false);
const [deliveryDeadline, setDeliveryDeadline] = useState(new Date().toISOString());
const [deliveryLocation, setDeliveryLocation] = useState("");
const [description, setDescription] = useState("");
const [services, setServices] = useState("");
const [budget,setBudget] = useState("");
const [remarks,setRemarks] = useState("");
const [canBeDelivered,setCanBeDelivered]= useState(false);
const [currency,setCurrency] = useState("")
const [estimatedCurrency,setEstimatedCurrency] = useState("");
const [selectedCommunity,setSelectedCommunity] = useState([]);
const [ communities,setCommunities] = useState([]);
const [img1,setImg1]= useState("");
const [img2,setImg2]= useState("");
const [img3,setImg3]= useState("");
const [requirementPictures,setRequirementPictures] = useState([])
const [selectedImagePosition,setSelectedImagePosition] = useState(0);
const [defaultPic,setDefaultPic] = useState("")
const [centerImage,setCenterImage] = useState("")
const [requirementId,setRequirementId] = useState("")
const [category,setCategory] = useState("")
const [requirementType,setRequirementType] = useState("Product")
const [minimumRequiredQuantity,setMinimumRequiredQuantity] = useState("")



  //useEffect
  useEffect (()=>{
if(props){
 
  if(props.openReqPopUp){
    setOpenRequirementPopUp(props.openReqPopUp)
    if(props.data){
      console.log(props.data)
      setRequirementId(props.data._id);
      setServices(props.data.requirementTitle);
      setBudget(props.data.budget);
      setCurrency(props.data.currency && props.data.currency);
      setDeliveryDeadline((props.data.deliveryDeadline).slice(0,10));
      setDeliveryLocation(props.data.deliveryLocation);
      setDescription(props.data.description);
      setCategory(props.data.category);
      setRequirementType(props.data.requirementType);
      setMinimumRequiredQuantity(props.data.minimumRequiredQuantity)

    }
  }
 
  
}
  },[props.openReqPopUp])


  useEffect(() => {
    if (requirementPictures && requirementPictures.length > 0) {
      setSelectedImagePosition(requirementPictures.length - 1);
      handleLeftFileSelect(requirementPictures[requirementPictures.length - 1]);
      if (requirementPictures.length > 2)
        handleRightFileSelect(requirementPictures[requirementPictures.length - 3]);
      if (requirementPictures.length > 1)
        handleFileSelect(requirementPictures[requirementPictures.length - 2]);
    }
  }, [requirementPictures]);

  useEffect(() => {}, [selectedImagePosition]);

const handleClose = (id)=>{
  setOpenRequirementPopUp(false)
  props.closeReqPopUp(id)
}

const addRequirements = ()=>{
 
  if(services.length === 0){
    return alert("Please Enter Service Or Product Name!!")
    }
    if(budget.length === undefined || budget.length === 0){
      return alert("Please Enter Budget Amount!!")
    }
    if(currency.length === undefined || currency.length ==="" ){

      return alert("Please Enter Currency type!!")
    }

    if(deliveryLocation.length === 0 || deliveryLocation.length === "" ){

      return alert("Please Enter Delivery Location!!")
    }
   
  setOpenRequirementPopUp(false)
let apiURL=process.env.REACT_APP_API_URL + "/requirement/addrequirements";
let apiParams = {
    method:"POST",
    headers:{
      requirementtitle:services,
      budget:budget,
      currency:currency?encodeURIComponent(currency):"",
      deliverylocation:deliveryLocation?encodeURIComponent(deliveryLocation):"",
      description:encodeURIComponent(description),
      deliverydeadline:deliveryDeadline,
      category:category,
      requirementtype:requirementType,
      minimumrequiredquantity:minimumRequiredQuantity,
      // selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
      customerid:user._id,
      customername:user.name,
      status:"Created"

    },
   
};
fetch(apiURL, apiParams)
.then((res)=> (res.status===200 ? res.json() : res.text()))
.then((repos)=> {
    if (repos.data) {
      cleartext()
      alert("Requirement Added Successfully");
      handleClose()
      requirementPictures && UploadImageToDrive(repos.data._id,requirementPictures)
 
 
    
    }else alert(repos.message || repos);
}) 
.catch((err) => alert(err));
}



const updateRequirements = ()=>{
 
  if(services.length === 0){
    return alert("Please Enter Service Or Product Name!!")
    }
    if(budget.length === undefined || budget.length === 0){
      return alert("Please Enter Budget Amount!!")
    }
    if(currency.length === undefined || currency.length ==="" ){

      return alert("Please Enter Currency type!!")
    }

    if(deliveryLocation.length === 0 || deliveryLocation.length === "" ){

      return alert("Please Enter Delivery Location!!")
    }
   

let apiURL=process.env.REACT_APP_API_URL + "/requirement/updateRequirement/"+requirementId;
let apiParams = {
    method:"POST",
    headers:{
      requirementtitle:services,
      budget:budget,
      currency:currency?encodeURIComponent(currency):"",
      deliverylocation:deliveryLocation?encodeURIComponent(deliveryLocation):"",
      description:encodeURIComponent(description),
      deliverydeadline:deliveryDeadline,
      category:category,
      requirementtype:requirementType,
      minimumrequiredquantity:minimumRequiredQuantity,
      // selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
      customerid:user._id,
      customername:user.name,
    },
   
};
fetch(apiURL, apiParams)
.then((res)=> (res.status===200 ? res.json() : res.text()))
.then((repos)=> {
    if (repos.data) {
      cleartext()
      alert("Requirement Updated Successfully");
      handleClose(repos.data._id)
      requirementPictures && UploadImageToDrive(repos.data._id,requirementPictures)
 
   
    
    }else alert(repos.message || repos);
}) 
.catch((err) => alert(err));
}



const cleartext = ()=>{
  setServices("");
  setDescription("");
  setDeliveryLocation("");
  setDeliveryDeadline("");
  setBudget("");
  setCurrency("");
}

const handleImageUpload = (e) => {
  if (e.target.files.length > 0) {
    if(defaultPic === ""){
        
      setDefaultPic(e.target.files[0].name)
    }
    // for(let i=0;i<e.target.files.length;i++)
    // {
     
    // if (e.target.files[i].size > 71680) {
    //   alert("Please choose files with size below 70kb!");
    //   return;
    // }

    // }
    const newImgArray = requirementPictures;
    setRequirementPictures([...newImgArray, ...e.target.files]);
   
  }

};

function handleLeftFileSelect(f) {
  var reader = new FileReader();
  // Closure to capture the file information.
  reader.onload = (function(theFile) {
    return function(e) {
      
      var binaryData = e.target.result;
      //Converting Binary Data to base 64
      var base64String = window.btoa(binaryData);
      //showing file converted to base64
      setImg3("data:image/jpeg;base64," + base64String);
    };
  })(f);
  // Read in the image file as a data URL.
  reader.readAsBinaryString(f);
}

function handleFileSelect(f) {
  
  var reader = new FileReader();
  // Closure to capture the file information.
  reader.onload = (function(theFile) {
    return function(e) {
      setCenterImage(f.name)
      var binaryData = e.target.result;
      //Converting Binary Data to base 64
      var base64String = window.btoa(binaryData);
      //showing file converted to base64
      setImg2("data:image/jpeg;base64," + base64String);
    };
  })(f);
  // Read in the image file as a data URL.
  reader.readAsBinaryString(f);
}

function handleRightFileSelect(f) {
  var reader = new FileReader();
  // Closure to capture the file information.
  reader.onload = (function(theFile) {
    return function(e) {
      var binaryData = e.target.result;
      //Converting Binary Data to base 64
      var base64String = window.btoa(binaryData);
      //showing file converted to base64
      setImg1("data:image/jpeg;base64," + base64String);
    };
  })(f);
  // Read in the image file as a data URL.
  reader.readAsBinaryString(f);
}

//Upload Image
const UploadImageToDrive = async (requirementId,document) =>{
 
// Using a forEach loop
document.forEach(function(images) {
 
  var file = images //the file
  var reader = new FileReader() //this for convert to Base64 
  reader.readAsDataURL(images) //start conversion...
  reader.onload = function (e) { //.. once finished..
    var rawLog = reader.result.split(',')[1]; //extract only thee file data part
   
    var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
   fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
      { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
      .then(res => res.json()).then(async(data) => {
        //See response
        await SaveDocumentsURL(data,requirementId,defaultPic === file.name)
        alert("Document Uploaded Successfully")

      }).catch(err => console.log(err)) // Or Error in console
  }
});
}


const SaveDocumentsURL = (data,requirementId,isDefault)=>{
  
if(requirementId){

let apiURL= process.env.REACT_APP_API_URL + "/requirement/saveReqImages/" + requirementId;
let apiParams = {
    method:"POST",
    headers:{
      images:data.url,
      isdefault:isDefault
    }
}
fetch(apiURL, apiParams)
.then((res)=> (res.status===200 ? res.json() : res.text()))
.then((repos)=> {
  if (repos.data) {
   

  }
})
.catch(e => console.log(e)) 
} 
}

const handleRightImageClick = () =>{
  if( requirementPictures.length > 3 && selectedImagePosition <3 ){
    setSelectedImagePosition(selectedImagePosition + 1);
    }else if(requirementPictures.length < (selectedImagePosition+1) ){
      setSelectedImagePosition(selectedImagePosition - 1);
    }
  
      if (requirementPictures && selectedImagePosition > 2) {   
       
            if(requirementPictures.length >= (selectedImagePosition+1)){
               if(selectedImagePosition == 3){
              
                handleLeftFileSelect(requirementPictures[selectedImagePosition]);
                handleFileSelect(requirementPictures[selectedImagePosition - 1]);
                handleRightFileSelect(requirementPictures[selectedImagePosition - 2]);
               
                  setSelectedImagePosition(selectedImagePosition + 1);
            
                
               }else if(selectedImagePosition < 3){
                handleLeftFileSelect(requirementPictures[selectedImagePosition]);
                handleFileSelect(requirementPictures[selectedImagePosition]);
                handleRightFileSelect(requirementPictures[selectedImagePosition - 1]);
             
               }else if(selectedImagePosition > 3){
                handleLeftFileSelect(requirementPictures[selectedImagePosition]);
                handleFileSelect(requirementPictures[selectedImagePosition-1]);
                handleRightFileSelect(requirementPictures[selectedImagePosition - 2]);
                  setSelectedImagePosition(selectedImagePosition + 1);
                 
               }
           
            }
           
      }
}


const handleLeftImageClick = ()=>{
  if(requirementPictures.length < selectedImagePosition){
    setSelectedImagePosition(JSON.parse(selectedImagePosition) - 2);
  }
   if (selectedImagePosition > 3) {
  setSelectedImagePosition(JSON.parse(selectedImagePosition) - 1);
   }
  if (requirementPictures && selectedImagePosition > 0) {
   
    if (selectedImagePosition > 2) {
     
      handleLeftFileSelect(requirementPictures[selectedImagePosition - 1]);
      handleFileSelect(requirementPictures[selectedImagePosition - 2]);
      handleRightFileSelect(requirementPictures[selectedImagePosition - 3]);
    }


  }
 
}

const handledefaultPic = () =>{
  setDefaultPic(centerImage)
  if(centerImage){
    alert("Image Has been Set to default")
  }
  
  }



  return (
    <div>
        <Modal
        open={openRequirementPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
 
 <div id="pop_up_requirement">

 <div id="Requirements_lbl_pop_requu">
		<span>Requirements</span>
	</div>

	<svg class="low_poly_banner_design_pop_req_i">
		<linearGradient id="low_poly_banner_design_pop_req_i" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
			<stop offset="0" stop-color="#86d8e5" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="low_poly_banner_design_pop_req_i" rx="0" ry="0" x="0" y="0" width="307" height="77">
		</rect>
	</svg>
	<input placeholder="Services/Product" class="serv_pro_pop_requu" value={services} onChange={(e)=> {
         setServices(e.target.value)}}/>
		
	<input placeholder="Budget Amount" class="bud_amt_pop_requu" value={budget} onChange={(e)=> {
         setBudget(e.target.value);}}/>
		
	<select class="idk_drop_pop_requu" value={currency && currency}
                                onChange={(e) => {
                                  setCurrency(e.target.value);  
                                }}>
		
    <option>---Select---</option>          
         {Currencies &&
              Currencies.map((currency) => (
                <option value={JSON.stringify(currency)}>
                  {" "}
                  {currency.name}
                </option>
              ))}
		
	</select>
	<input placeholder="Delivery Location" class="Dl_pop_requu" value={deliveryLocation} onChange={(e)=> {
       setDeliveryLocation(e.target.value); }}/>
		
	<input placeholder="Categories" class="cat_pop_requu"  value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}/>
		
	<textarea placeholder="Description" class="desc_pop_requu"  value={description} onChange={(e)=> {
       setDescription(e.target.value);   
  }}/>
	
	
	
	<input type="date" size="40px" class="Enter_your_name_pop_requu" value={deliveryDeadline} 
onChange={(value) => {
 setDeliveryDeadline(value.target.value);
}}/>
	
	<div id="Delivery_Deadline_lbl_pop_requ">
		<span>Delivery Deadline</span>
	</div>
	<div id="grp_req_upload_pop_requu">

		<img id="profile__3_pop_requu" src={img2?img2:"profilebackground.png"}  />
			
		<img id="profile_2_pop_requu" src={img1?img1:"profilebackground.png"} onClick={()=>handleRightImageClick()}/>
			
		<img id="profile_1_pop_requu" src={img3?img3:"profilebackground.png"} onClick={()=>handleLeftImageClick()}/>
			

    <label for="image">
        <input id="image" type="file" name="image" multiple hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
        <img id="add_more_pop_requu" width="100" height="100" src="close.png" title="Upload Image" alt="Reload?" />
   </label>

		{/* <img title="Upload Image" id="add_more_pop_requu" src="add_more_pop_requu.png" srcset="add_more_pop_requu.png 1x" /> */}
			
		<img title="Remove Image" id="close_pop_requu_" src="close_pop_requu_.png" srcset="close_pop_requu_.png 1x" onClick={()=>handleClose()} />
			
		<img title="Default" id="accept_pop_requu" src="accept_pop_requu.png" srcset="accept_pop_requu.png 1x"  onClick={() => handledefaultPic()}/>
			
	</div>
	
		
	<div id="Group_radio_pop_requu">
		<input type="radio" name="offeringtype" class="rad_pro_pop_requu" value={requirementType} checked={requirementType==="Product"} onChange={()=>setRequirementType("Product")}/>
		
		<div id="Product_pop_requu">
			<span>Product</span>
		</div>
		<input type="radio" name="offeringtype" class="rad_ser_pop_requu" value={requirementType} checked={requirementType==="Service"} onChange={()=>setRequirementType("Service")}/>
		
		<div id="Service_pop_requu">
			<span>Service</span>
		</div>
	</div>
	<input placeholder="Required Quantity" class="req_quantity_pop_requu" value={minimumRequiredQuantity}
            onChange={(e) => {
              setMinimumRequiredQuantity(e.target.value);
            }}/>

<img title="Close" id="close2_pop_requu" src="close2_pop_requu.png" srcset="close2_pop_requu.png 1x" onClick={()=>handleClose()}/>

  <div id="submit_grp_pop_requu" onClick={()=>requirementId.length > 0 ? updateRequirements() : addRequirements()}>
		<svg class="bdr_sub_pop_requu">
			<rect id="bdr_sub_pop_requu" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Submit_pop_requu">
			<span>{requirementId.length > 0 ?"Update":"Submit"}</span>
		</div>
	</div>
	
</div>
      </Modal>
    </div>
  )
}

export default RequirementPopUpPC
