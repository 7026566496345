import React, { useState, useEffect, useContext } from "react";
import { Button, Divider } from "@mui/material";
import "../../styles/AddMeetingToCalander.css";
import Modal from "@mui/material/Modal";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import AddAttendiesPopUp from "./AddAttendiesPopUp";


const AddMeetingToCalander = (props) => {

	const [searchParams] = useSearchParams();
    const { user, setUser } = useContext(UserContext);
    var navigate = useNavigate();
	const [meetingTitle,setMeetingTitle] = useState("")
	const [meetingStartDateTime,setMeetingStartDateTime] = useState(new Date().toISOString().substring(0,16))
	const [meetingEndDateTime,setMeetingEndDateTime] = useState(new Date().toISOString().slice(0,16))
	const [agenda,setAgenda] = useState("")
	const [meetingRemarks,setMeetingRemarks] = useState("")
	const [vendorId,setVendorId] = useState("")
	const [vendorName,setVendorName] = useState("")
	const [openAttendies,setOpenAttendies] = useState(false);
	const [attendies,setAttendies] = useState({});

	useEffect(()=>{

		if(props.dateAndTime){
			setMeetingStartDateTime(props.dateAndTime)
			setMeetingEndDateTime(props.dateAndTime)
		}
		
		  },[props.dateAndTime])

	const ScheduleMeeting = () =>{
		if(meetingTitle ===""||undefined)
		{
		  return alert("Please Enter Meeting Title !!")
		}else if(agenda===""||undefined){
		  return alert("Please Enter Agenda Of Meeting !!")
		}else if(vendorId===""||undefined){
		  return alert("Please Select the Person !!")
		}else if(vendorName===""||undefined){
		  return alert("Please Select the Person !!")
		}
		
		let apiURL=process.env.REACT_APP_API_URL + "/meetings/schedulemeetings";
		  let apiParams = {
			  method:"POST",
			  headers:{
				requesterid:user._id,
				requestername:user.name,
				respondentid:vendorId,
				respondentname:vendorName,
				meetingtitle:meetingTitle,
				meetingstartdatetime:meetingStartDateTime,
				meetingenddatetime:meetingEndDateTime,
				agenda:encodeURIComponent(agenda),
				remarks:encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+meetingRemarks),
				status:"Requested",
	  
			  },
			 
		  };
		  fetch(apiURL, apiParams)
		  .then((res)=> (res.status===200 ? res.json() : res.text()))
		  .then((repos)=> {
			  if (repos.data) {
				props.close()
				alert("Meeting Scheduled Successfully");
			  }else alert(repos.message || repos);
		  }) 
		  .catch((err) => alert(err));
	  
	   }


  return (
	<div>
		 <div id="add_btn_grp_add_event_details" onClick={()=>{ScheduleMeeting()}}>
    <svg class="add_btn_bdr_add_calender_event">
        <rect id="add_btn_bdr_add_calender_event" rx="7" ry="7" x="0" y="0" width="120" height="40">
        </rect>
    </svg>
    <div id="Add_btn_add_calender_event">
        <span>Add</span>
    </div>
   </div>

   <div id="meeting_calender_pop_up">
		<div id="attendies_grp_add_meeting_cale">
			<div id="attendies_lbl_meeting_calender">
				<span>Attendies</span>
			</div>
			<img id="add_attendies_meeting_calender" src="./CalanderDashboard/add_attendies_meeting_calender.png" srcset="./CalanderDashboard/add_attendies_meeting_calender.png 1x" onClick={()=>setOpenAttendies(true)}/>
				
			{vendorName	?<img id="img_imreanmeeting_calender" src={attendies.user1Name===user.name ?  (attendies.user2Image.imageUrl && attendies.user2Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(attendies.user2Image.imageUrl.split('/')[5]):"./CalanderDashboard/img_imreanmeeting_calender.png":(attendies.user1Image.imageUrl && attendies.user1Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(attendies.user1Image.imageUrl.split('/')[5]):"./CalanderDashboard/img_imreanmeeting_calender.png"}/>:""}
				
			{vendorName	?	<div id="imran_fool_meeting_calender">
				<span>{vendorName}</span>
			</div>:""}
			
			{vendorName	?<div id="Full_Stack_Developer_meeting_c">
				<span>Full Stack Developer</span>
			</div>:""}
		</div>
		<div id="end_time_grp_add_meeting_calen">
			<input type="datetime-local" class="end_time_bdr_add_meeting_calen" value={meetingEndDateTime} onChange={(e)=>setMeetingEndDateTime(e.target.value)}/>
				
			<div id="end_Time_lbl_add_meeting_calen">
				<span>Ends At</span>
			</div>
		</div>
		<div id="sstart_at_grp_add_meeting_cale">
			<input type="datetime-local" class="start_at__bdr_add_meeting_cale" value={meetingStartDateTime} onChange={(e)=>setMeetingStartDateTime(e.target.value)}/>
				
			<div id="start_at_lbl_add_meeting_calen">
				<span>Start At</span>
			</div>
		</div>
		<div id="category_grp_add_meeting_calen">
			<textarea placeholder="Remarks" class="category_bdr_add_meeting_calen" value={meetingRemarks} onChange={(e)=> {
          setMeetingRemarks(e.target.value); }}/>
				
			<div id="Category_lbl_add_meeting_calen">
				<span>Remarks</span>
			</div>
		</div>
		<div id="agenda_grp_add_meeting_calende">
			<textarea placeholder="Agenda" class="event_agenda_bdr_add_event_det"  value={agenda} onChange={(e)=> {
       setAgenda(e.target.value);}}/>
				
			<div id="agenda_lbl_add_meeting_calende">
				<span>Agenda</span>
			</div>
		</div>
		
		<div id="title_type_grp_add_meeting_cal">
			<input placeholder="Title" class="titletype_bdr_add_meeting_cale"  value={meetingTitle} onChange={(e)=> {
       setMeetingTitle(e.target.value); }} />
				
			<div id="titletype_lbl_add_meeting_cale">
				<span>Title</span>
			</div>
		</div>
	</div>

	<AddAttendiesPopUp openPeoplePop={openAttendies}  closePop = {(data)=>{setOpenAttendies(false); data && setVendorId(data.user1Id===user._id ? data.user2Id:data.user1Id);setVendorName(data.user1Name===user.name ? data.user2Name:data.user1Name);setAttendies(data)}}/>
	</div>
  )
}

export default AddMeetingToCalander
