import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from './UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import '../styles/AddCommunitesOffPopUp.css';
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const AddCommunitiesOffPopUp = (props) => {

    //useState
    var navigate = useNavigate();
    const communityId = props.communityId;
    const communityName = props.communityName;
    const { user, setUser} = useContext(UserContext);
    const [openGridModel,setOpenGridModel] = useState(false);
    const [oddOfferings,setOddOfferings] = useState([]);


useEffect(()=>{
if(user){
    if(props.openComOff)
    {
        setOpenGridModel(props.openComOff)
        getOfferingsWithoutCommunity()
        
    }
}    


},[props.openComOff])

const closeGridModel = ()=>{
    setOpenGridModel(false)
    props.closeComOff()
}

 //Get offering not in community
 const getOfferingsWithoutCommunity = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getofferingwithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddOfferings(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


//adding Community to Offerings
const updateOfferingStatus = (offeringId)=>{
   
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/addcommunitytooffering/"+ offeringId ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:communityId,
            communityname:communityName,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
       
          getOfferingsWithoutCommunity()
          props.closeComOff()
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
   


  return (
    <div>
           {/* Bids data in data grid for requirement which was added */}
<Modal
        open={openGridModel}
        onClose={closeGridModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
<div id="offering_pop_up">
	<img id="bg_design_pop_off" src="bg_design_pop_off.png" srcset="bg_design_pop_off.png 1x, bg_design_pop_off@2x.png 2x" />
		
	<svg class="bg_design_s">
		<linearGradient id="bg_design_s" spreadMethod="pad" x1="0" x2="1" y1="0" y2="1">
			<stop offset="0" stop-color="#dbf2e7" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="bg_design_s" rx="0" ry="0" x="0" y="0" width="265" height="30">
		</rect>
	</svg>
	<div id="heading_title_pop">
		<span>Offerings</span>
	</div>
	<div id="grp_useless">
		<div id="off_add">
			<span>Title</span>
		</div>
		<div id="Add_title_off">
			<span>Add</span>
		</div>
	</div>

	<div id="off_pop_scroll">
    {oddOfferings.filter(req=>req.vendorId === user._id).map((off,idx) => (
        <div style={{top:(idx*40).toString()+"px",position:"relative",left:"0px"}}>
		<div id="Group_off_com_">
			<svg class="border_off_com">
				<rect id="border_off_com" rx="6" ry="6" x="0" y="0" width="253" height="37">
				</rect>
			</svg>
			<div id="Testing_title_com_off">
				<span>{off.offeringTitle}</span>
			</div>
			<img id="add_off_comm_" src="plusOff.png" srcset="plusOff.png 1x, plusOff@2x.png 2x" onClick={()=>{updateOfferingStatus(off._id);}}/>		
		</div>
        </div>
    ))}
	</div>

    <img title="Close" id="close_comoff_pc"  onClick={()=>{closeGridModel()}} src="close2.png" srcset="close2.png 1x, close2@2x.png 2x" />
</div>
       </Modal>
    </div>
  )
}

export default AddCommunitiesOffPopUp
