import React, { useState, useEffect, useContext } from "react";
// import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider } from "@mui/material";
import "../../styles/EditProfilePopUpPc.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
// import { id } from 'date-fns/locale';
import { UserContext } from "../UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { autocompleteClasses } from "@material-ui/core";
import countries from "../Countries.json";
import Currencies from "../Currencies.json";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const EditProfilePopUpPc = (props) => {
  const label = { inputProps: { "aria-label": "Schedule Interview" } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule] = React.useState();
  const [searchParams] = useSearchParams();

  const { user, setUser } = useContext(UserContext);
  var navigate = useNavigate();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState(new Date());
  const [whatsNumber, setWhatsNumber] = useState("");
  const [img, setImg] = useState("");
  const [gender, setGender] = useState("");
  const [open, setOpen] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportingToId, setReportingToId] = useState("");
  const [reportingToName, setReportingToName] = useState("");
  const [accessType, setAccessType] = useState("");
  const [country, setCountry] = useState({});
  const [hourlyRate, setHourlyRate] = useState("");
  const [currency, setCurrency] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [profession, setProfession] = useState("");
  const [active, setActive] = useState(true);
  const [confirmPass, setConfirmPass] = useState("");
  const [password, setPassword] = useState("");
  const [pincode, setPincode] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [openChgPwd, setOpenChgPwd] = React.useState(false);
  const [previousPass, setPreviousPass] = useState("");
  const [upiId, setUpiId] = useState("");
  const [principles, setPrinciples] = useState("");
  const [mission, setMission] = useState("");
  const [vision, setVision] = useState("");
  const [mapLatitude, setMapLatitude] = useState("");
  const [mapLongitude, setMapLongitude] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessMobile, setBusinessMobile] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [profile, setProfile] = useState("");

  useEffect(() => {
    if (user)
      if (props.openPopup) {
        setOpen(props.openPopup);
      }
    getUserProfile();
  }, [user]);

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function(theFile) {
      return function(e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setProfile("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const handleImageUpload = (e) => {

    handleFileSelect(e.target.files[0]);
    UploadImageToDrive(e.target.files[0]);
    // let formdata = new FormData();
    // formdata.append("image", e.target.files[0], e.target.value);
    // updateProfilePic(formdata);
  };

  //Upload Image
  const UploadImageToDrive = async (document) => {
    var file = document; //the file
    var reader = new FileReader(); //this for convert to Base64
    reader.readAsDataURL(document); //start conversion...
    reader.onload = function(e) {
      //.. once finished..
      var rawLog = reader.result.split(",")[1]; //extract only thee file data part
      var dataSend = {
        dataReq: { data: rawLog, name: file.name, type: file.type },
        fname: "uploadFilesToGoogleDrive",
      }; //preapre info to send to API
      fetch(
        process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
        { method: "POST", body: JSON.stringify(dataSend) }
      ) //send to Api
        .then((res) => res.json())
        .then((data) => {
          //See response

          SaveProfilePic(data);

          var loggedIn = JSON.parse(localStorage.getItem("loggedInUser"));
          loggedIn.imageId = data.url.split("/")[5];
          localStorage.setItem("loggedInUser", JSON.stringify(loggedIn));

          setUser(loggedIn);

          // alert("Image Uploaded Successfully","success")
        })
        .catch((err) => console.log(err)); // Or Error in console
    };
  };

  const SaveProfilePic = (data) => {
    const apiurl =
      process.env.REACT_APP_API_URL + "/users/updateprofilepicnew/" + user._id;
    let apiParams = {
      method: "POST",
      headers: {
        imageurl: data.url,
      },
    };
    fetch(apiurl, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Image Uploaded Successfully");
        }
      })
      .catch((e) => console.log(e));
  };

  const updateProfilePic = (formdata) => {
    const apiurl =
      process.env.REACT_APP_API_URL + "/users/updateprofilepic/" + user._id;
    var apiparam = {
      method: "POST",
      // headers: {
      //   id: user._id,
      // },
      body: formdata,
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Image Uploaded Successfully!!");
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const getUserProfile = () => {
    let apiURL =
      process.env.REACT_APP_API_URL + "/users/getUserDetails/" + user._id;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          //console.log(repos.data);
          setEmployees(repos.data);
          setAddress(repos.data.address);
          setDob(repos.data.dob && repos.data.dob.substring(0, 10));
          setEmail(repos.data.email);
          setName(repos.data.name);
          setGender(repos.data.gender);
          setMobile(repos.data.phone);
          setCountry(repos.data.country);
          setState(repos.data.state);
          setCity(repos.data.city);
          setPincode(repos.data.pincode);
          setProfession(repos.data.profession);
          setIntroduction(repos.data.introduction);
          // setImg(arrayBufferToBase64(repos.data.image.data.data));
          setImg(repos.data.imageUrl);
          setCurrency(repos.data.currency);
          setHourlyRate(repos.data.hourlyRate);
          setUpiId(repos.data.upiId);
          setVision(repos.data.vision);
          setMission(repos.data.mission);
          setPrinciples(repos.data.principles);
          setMapLatitude(repos.data.mapLatitude);
          setMapLongitude(repos.data.mapLongitude);
          setBusinessName(repos.data.businessName);
          setBusinessMobile(repos.data.businessPhone);
          setBusinessEmail(repos.data.businessEmail);
          setBusinessAddress(repos.data.businessAddress);
          setBusinessWebsite(repos.data.businessWebsite);
          setWhatsAppNumber(repos.data.whatsAppNumber);
          setLinkedInUrl(repos.data.linkedInUrl);
          setInstagramUrl(repos.data.instagramUrl);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const UpdateEmployeesDetails = () => {
    if (name === undefined || name.length === "") {
      return alert("Please Enter the Name !!");
    }
    if (mobile === undefined || mobile.length === "") {
      return alert("Please Enter the Mobile No !!");
    }
    if (email === undefined || email.length === "") {
      return alert("Please Enter the Email Address !!");
    }
    if (country === undefined || country.length === "") {
      return alert("Please Select your Country !!");
    }
    if (state === undefined || state.length === "") {
      return alert("Please Enter State !!");
    }
    if (city === undefined || city.length === "") {
      return alert("Please Enter City !!");
    }
    if (dob === undefined || dob.length === "") {
      return alert("Please Enter DOB !!");
    }
    let apiURL =
      process.env.REACT_APP_API_URL + "/users/updateEmployees/" + user._id;
    let apiParams = {
      method: "POST",
      headers: {
        name: name,
        gender: gender || "",
        dob: dob,
        mobile: mobile,
        email: email,
        country: country,
        state: state,
        city: city,
        address: encodeURIComponent(address || ""),
        pincode: pincode || "",
        profession: profession || "",
        introduction: encodeURIComponent(introduction || ""),
        currency: currency || "",
        hourlyrate: hourlyRate || "",
        upiid: upiId || "",
        vision: encodeURIComponent(vision || ""),
        mission: encodeURIComponent(mission || ""),
        principles: encodeURIComponent(principles || ""),
        maplatitude: mapLatitude || "",
        maplongitude: mapLongitude || "",
        businessname: businessName || "",
        businessmobile: businessMobile || "",
        businessemail: businessEmail || "",
        businessaddress: encodeURIComponent(businessAddress || ""),
        businesswebsite: businessWebsite || "",
        whatsappnumber: whatsAppNumber || "",
        linkedinurl: linkedInUrl || "",
        instagramurl: instagramUrl || "",

        // password: encodeURIComponent(password),
        // active: active,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setIsLoading(false);
          alert("User Updated Successfully");
          handleClose();
        }
      })
      .catch((err) => alert(err));
  };

  const PasswordChange = () => {
    if (password !== confirmPass) {
      alert("Password and Confirm Password don't match!!");
      return;
    }
    setOpenChgPwd(false);
    let apiURL =
      process.env.REACT_APP_API_URL + "/users/updatePassword/" + user._id;
    let apiParams = {
      method: "POST",
      headers: {
        previouspass: encodeURIComponent(previousPass),
        newpassword: encodeURIComponent(password),
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setIsLoading(false);
          alert("Password Changed Successfully!!!");
          handlelogout();
        } else alert(repos);
      })
      .catch((err) => alert(err));
  };

  const handlelogout = () => {
    localStorage.clear();
    setUser(null);
    window.location.href = "/login";
  };

  const handleClosePwd = () => {
    setOpenChgPwd(false);
  };

  const handleClose = () => {
    setOpen(false);
    props.closePopUp();
  };

  return (
    <div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflow: "scroll", textAlign: "center" }}
        >
          <div id="profile_edit_pop">
            <img
              id="pop_bg_desig"
              src="pop_bg_desig.png"
              srcset="pop_bg_desig.png 1x"
            />

            <label for="imageProfile">
              <input
                id="imageProfile"
                type="file"
                name="imageProfile"
                hidden
                accept="image/*"
                onChange={(e) => handleImageUpload(e)}
              />
              {profile.length === 0 ? (
                <img
                  id="user_pfp_pop_lbl_profile_pc"
                  width="100"
                  height="100"
                  src={
                    user.imageId && user.imageId.length
                      ? process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG +
                        user.imageId
                      : "user_pfp_pop_lbl_profile_pc.png"
                  }
                  style={{ borderRadius: "75px", border: "1px solid black" }}
                />
              ) : (
                <img
                  id="user_pfp_pop_lbl_profile_pc"
                  width="100"
                  height="100"
                  src={profile ? profile : "user_pfp_pop_lbl_profile_pc.png"}
                  style={{ borderRadius: "75px", border: "1px solid black" }}
                />
              )}
            </label>
            {/* <img id="user_pfp_pop_lbl_profile_pc" src="user_pfp_pop_lbl_profile_pc.png" srcset="user_pfp_pop_lbl_profile_pc.png 1x" />
             */}
            <div id="Edit_Profile_lbl_profile_pc">
              <span>Edit Profile</span>
            </div>

            <img
              title="Close"
              id="close2_edit_profile_page"
              src="close2_edit_profile_page.png"
              srcset="close2_edit_profile_page.png 1x"
              onClick={() => handleClose()}
            />

            <div id="scroll_profile_page_edit">
              <input
                class="name_input_lbl_profile_pc"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />

              <div id="Name_lbl_profile_pc">
                <span>Name</span>
              </div>
              <input
                class="number_lbl_profile_pc_input"
                type="number"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />

              <div id="Mobile_Number_lbl_profile_pc">
                <span>Mobile Number</span>
              </div>
              <div id="Gender_lbl_profile_pc">
                <span>Gender</span>
              </div>
              <div
                title="Male"
                id="men_btn_grp_pc"
                value={gender}
                onClick={() => {
                  setGender("male");
                }}
              >
                <svg class="btn_bdr_pop">
                  <ellipse
                    id="btn_bdr_pop"
                    rx="20"
                    ry="20"
                    cx="20"
                    cy="20"
                    style={{ fill: gender === "male" ? "#a3e9c8 " : "white" }}
                  ></ellipse>
                </svg>
                <img
                  id="man_btn_img_pc"
                  src="man_btn_img.png"
                  srcset="man_btn_img.png 1x"
                />
              </div>
              <div
                title="Female"
                id="women_btn_grp_pc"
                value={gender}
                onClick={() => {
                  setGender("female");
                }}
              >
                <svg class="btn_bdr_pc_pop">
                  <ellipse
                    id="btn_bdr_pc_pop"
                    rx="20"
                    ry="20"
                    cx="20"
                    cy="20"
                    style={{ fill: gender === "female" ? "#a3e9c8 " : "white" }}
                  ></ellipse>
                </svg>
                <img
                  id="woman_btn_pc"
                  src="woman_btn_.png"
                  srcset="woman_btn_.png 1x"
                />
              </div>
              <div
                title="Others"
                id="other_btn_gender_pc"
                value={gender}
                onClick={() => {
                  setGender("others");
                }}
              >
                <svg class="bdr_other_btn_pc">
                  <ellipse
                    id="bdr_other_btn_pc"
                    rx="20"
                    ry="20"
                    cx="20"
                    cy="20"
                    style={{ fill: gender === "others" ? "#a3e9c8 " : "white" }}
                  ></ellipse>
                </svg>
                <img
                  id="gender-neutral_btn_img_pc"
                  src="gender-neutral_btn_img.png"
                  srcset="gender-neutral_btn_img.png 1x"
                />
              </div>

              <select
                class="country_drop_lbl_profile_pc"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
                {countries &&
                  countries.map((country) => (
                    <option value={country.name}>{country.name}</option>
                  ))}
              </select>
              <div id="Country_lbl_profile_pc">
                <span>Country</span>
              </div>
              <input
                class="state_drop_lbl_profile_pc"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              />

              <div id="State_lbl_profile_pc">
                <span>State</span>
              </div>
              <input
                class="city_input_profile_pc"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />

              <div id="City_lbl_profile_pc">
                <span>City</span>
              </div>
              <textarea
                class="address_input_lbl_profile_pc"
                value={address || ""}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />

              <div id="Address_lbl_profile_pc">
                <span>Address</span>
              </div>
              <input
                class="pincode_bdr_input_pc"
                value={pincode || ""}
                onChange={(e) => {
                  setPincode(e.target.value);
                }}
              />

              <div id="Pincode_lbl_lbl_profile_pc">
                <span>Pincode</span>
              </div>

              <input
                class="map_lattitude_input_pc"
                value={mapLatitude || ""}
                onChange={(e) => {
                  setMapLatitude(e.target.value);
                }}
              />

              <div id="map_lattitute_lbl_lbl_profile_pc">
                <span>Latitude</span>
              </div>

              <input
                class="map_longitude_input_pc"
                value={mapLongitude || ""}
                onChange={(e) => {
                  setMapLongitude(e.target.value);
                }}
              />

              <div id="map_longitude_lbl_profile_pc">
                <span>Longitude</span>
              </div>

              <textarea
                class="introduction_input_lbl_profile_pc"
                value={introduction || ""}
                onChange={(e) => {
                  setIntroduction(e.target.value);
                }}
              />

              <div id="Introduction_lbl_profile_pc">
                <span>Introduction</span>
              </div>
              <input
                class="email_input_lbl_profile_pc"
                value={email || ""}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <div id="Email_lbl_profile_pc">
                <span>Email</span>
              </div>
              <div id="Generallbl_profile_pc">
                <span>General</span>
              </div>
              <div id="Location_lbl_profile_pc">
                <span>Location</span>
              </div>
              <input
                type="Date"
                class="DDOB_lbl_profile_pc"
                value={dob}
                // max={new Date().getFullYear()+"-"+(new Date().getMonth()+1).toString().padStart(2, '0')+"-"+new Date().getDay().toString().padStart(2, '0')}
                onChange={(value) => {
                  setDob(value.target.value);
                }}
              />

              <div id="DOB_lbl_profile_pc">
                <span>DOB</span>
              </div>
              <div id="Payment_Details_lbl_profile_pc">
                <span>Business Details</span>
              </div>
              <input
                class="HBR_input_lbl_profile_pc"
                value={hourlyRate}
                onChange={(e) => {
                  setHourlyRate(e.target.value);
                }}
              />

              <div id="Hour_Billing_Rate_lbl_profile_pc">
                <span>Hour Billing Rate</span>
              </div>
              <select
                class="billing_currency_input_edit_pc"
                value={currency}
                // defaultValue={companyName}
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
              >
                {Currencies &&
                  Currencies.map((currency) => (
                    <option value={currency.name}>{currency.name}</option>
                  ))}
              </select>

              <div id="Billing_Currency_lbl_profile_pop">
                <span>Billing Currency</span>
              </div>
              <input
                class="profession_input_lbl_profile_pop"
                value={profession}
                onChange={(e) => {
                  setProfession(e.target.value);
                }}
              />

              <div id="Profession_lbl_profile_pc">
                <span>Profession</span>
              </div>
              <img
                id="dossier_lbl_profile_pc"
                src="dossier_lbl_profile_pc.png"
                srcset="dossier_lbl_profile_pc.png 1x"
              />

              <img
                id="placeholder_lbl_profile_pc"
                src="placeholder_lbl_profile_pc.png"
                srcset="placeholder_lbl_profile_pc.png 1x"
              />

              <img
                id="card_lbl_profile_pc"
                src="card_lbl_profile_pc.png"
                srcset="card_lbl_profile_pc.png 1x"
              />

              <textarea
                class="Vision_bdr_bdr_input_pc"
                value={vision}
                onChange={(e) => {
                  setVision(e.target.value);
                }}
              />

              <div id="Vision_lbl_profile_pc">
                <span>Vision</span>
              </div>
              <textarea
                class="mission_bdr_input_lbl_profile_pop"
                value={mission}
                onChange={(e) => {
                  setMission(e.target.value);
                }}
              />

              <div id="Mission_lbl_profile_pc">
                <span>Mission</span>
              </div>
              <textarea
                class="principle_bdr_lbl_profile_pc"
                value={principles}
                onChange={(e) => {
                  setPrinciples(e.target.value);
                }}
              />

              <div id="Principles_lbl_profile_pc">
                <span>Principles</span>
              </div>
              <input
                class="UPI_input_lbl_profile_pc"
                value={upiId}
                onChange={(e) => {
                  setUpiId(e.target.value);
                }}
              />

              <div id="UPI_ID_lbl_profile_pc">
                <span>UPI ID</span>
              </div>
              <input
                class="business_name_input_edit_pc"
                value={businessName}
                onChange={(e) => {
                  setBusinessName(e.target.value);
                }}
              />

              <div id="business_name_lbl_edit_pc">
                <span>Business Name</span>
              </div>
              <input
                class="business_mobile_input_edit_pro"
                value={businessMobile}
                onChange={(e) => {
                  setBusinessMobile(e.target.value);
                }}
              />

              <div id="business_mobile_lbl_edit_profu">
                <span>Business Phone</span>
              </div>
              <input
                class="business_email_input_pc"
                type="email"
                value={businessEmail}
                onChange={(e) => {
                  setBusinessEmail(e.target.value);
                }}
              />

              <div id="business_email_lbl_edit_profil">
                <span>Business Email</span>
              </div>
              <textarea
                class="business_address_input_edit_pr"
                value={businessAddress}
                onChange={(e) => {
                  setBusinessAddress(e.target.value);
                }}
              />

              <div id="UPI_ID_lbl_profile_paymet_page_pc">
                <span>Business Address</span>
              </div>
              <input
                class="web_bus_edit_profile_pc"
                value={businessWebsite}
                onChange={(e) => {
                  setBusinessWebsite(e.target.value);
                }}
              />

              <div id="website_business_edit_profile_pc">
                <span>Business Website</span>
              </div>
              <div id="socials_lbl_edit_profile">
                <span>Social </span>
                <span style={{ fontStyle: "normal", fontWeight: "normal" }}>
                  (Optional)
                </span>
              </div>
              <img
                id="social_img_edit_profile_pc"
                src="social_img_edit_profile_pc.png"
                srcset="social_img_edit_profile_pc.png 1x"
              />

              <input
                class="whatsapp_input_edit_profile"
                value={whatsAppNumber}
                onChange={(e) => {
                  setWhatsAppNumber(e.target.value);
                }}
              />

              <div id="whats_app_number_lbl_edit_pc">
                <span>WhatsApp Number</span>
              </div>
              <input
                class="linked_in_edit_profile"
                value={linkedInUrl}
                onChange={(e) => {
                  setLinkedInUrl(e.target.value);
                }}
              />

              <div id="linked_in_lbl_edit_profile">
                <span>LinkedIn URL</span>
              </div>
              <input
                class="insta_url_box_edit_profile"
                value={instagramUrl}
                onChange={(e) => {
                  setInstagramUrl(e.target.value);
                }}
              />

              <div id="insta_url_lbl_edit_profile">
                <span>Instagram URL</span>
              </div>
            </div>
            {/* <div id="cancel_btn_grp_profile">
		<svg class="cancel_bdr_btn_rpofile">
			<rect id="cancel_bdr_btn_rpofile" rx="8" ry="8" x="0" y="0" width="109" height="36">
			</rect>
		</svg>
		<div id="cancel_lbl_profile">
			<span>Reset Password</span>
		</div>
	</div> */}
            <div
              id="btn_save_changes_profile_edit_"
              onClick={() => UpdateEmployeesDetails()}
            >
              <svg class="save_update_bg_btn">
                <rect
                  id="save_update_bg_btn"
                  rx="8"
                  ry="8"
                  x="0"
                  y="0"
                  width="109"
                  height="36"
                ></rect>
              </svg>
              <div id="save_updates_lbl_btn">
                <span>Save Update</span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default EditProfilePopUpPc;
