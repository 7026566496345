import React, { useEffect, useState, useContext} from 'react'
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/RequirementsBottomMobUI.css';

import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { clear } from '@testing-library/user-event/dist/clear';
import Currencies from "../Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import  RequirementPopUpPC from '../RequirementPopUpPC';
import ComReqSlide from './ComReqSlide';

const style4 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 383,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const style5 = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 325,
    maxWidth: 385,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
  
  };

  const style7 = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 325,
    maxWidth: 385,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
  
  };

  const BidsModalStyle = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 600,
    maxWidth: 1200,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
  
  }; 
const RequirementsBottomMobUI = (props) => {

  //usestate
  var navigate = useNavigate();
    const [name, setName] = useState("");
    const [open, setOpen] = React.useState(false);
    const [openApplicant, setOpenApplicant] = React.useState(false);
    const [openModel,setOpenModel] = useState(false);
    const [openGridModel,setOpenGridModel] = useState(false);

    const [jobId, setJobId] = React.useState();
    const [jobTitle, setJobTitle] = React.useState("");
    const [biddings,setBiddings] = useState("");
    const [deliveryDeadline, setDeliveryDeadline] = useState(new Date().toISOString());
    const [deliveryLocation, setDeliveryLocation] = useState();
    const [description, setDescription] = useState("");
    const [services, setServices] = useState("");
    const [budget,setBudget] = useState(0);
    const [remarks,setRemarks] = useState("");
    const [canBeDelivered,setCanBeDelivered]= useState(false);
    const [companyName, setCompanyName] = useState("");
    const [hours, setHours]= useState("hours");
    const [estimatedTime,setEstimatedTime] = useState("");
    const [estimatedAmount,setEstimatedAmount] = useState("");
    const [searchParams] = useSearchParams();
    const [requirements,setRequirements]=useState([]);
    const [requirementId,setRequirementId]=useState("");
    const [title,setTitle]= useState("");
    const [createdById,setCreatedById]=useState("");
    const [createdByName,setCreatedByName]=useState("");
    const [currency,setCurrency] = useState("")
    const [estimatedCurrency,setEstimatedCurrency] = useState("");
    const { user, setUser} = useContext(UserContext);
    const [status,setStatus] = useState([]);
    const [openMeetingModel,setOpenMeetingModel] = useState(false);
    const [meetingTitle,setMeetingTitle] = useState("");
    const [agenda,setAgenda] = useState("");
    const [meetingRemarks,setMeetingRemarks] = useState("");
    const [ requirementTitle,setRequirementTitle] = useState("");
    const [ vendorName,setVendorName] = useState("");
    const [ vendorId,setVendorId] = useState("");
    const [meetingDateTime,setMeetingDateTime] = useState(new Date().toISOString().substring(0,16));
    const [previousRemarks,setPreviousRemarks] = useState("");
    const [bids,setBids]= useState([]);
    const [quotationId,setQuotationId] = useState("");
    const [requirementStatus,setRequirementStatus] = useState("");
    const [ communities,setCommunities] = useState([]);
    const [selectedCommunity,setSelectedCommunity] = useState([]);
    const [selectedCommunities,setSelectedCommunities] = useState([]);
    const [requirementPopup,setRequirementPopup] = useState(false)
    const [viewMorePopup,setViewMorePopup] = useState(false);
    const [viewMoreData,setViewMoreData] = useState("");
    const [ openReqSlide,setOpenReqSlide] = useState(false);

    //UseEffect
      useEffect(()=>{
        cleartext()
    },[openApplicant])
      
    useEffect(()=>{
        clearBox()
    },[openModel])

    useEffect(()=>{
      if(user)
          getStatus()
      },[user])
  
      useEffect(()=>{
        if(user){
            getRequirements()
           
        }
        },[user])
    

      
    var cols = [
        {field:'id', headerName: 'id', hide: true },
        {field:'requirementId', headerName: 'reqId',hide: true  },
        {field:'accept', headerName: 'Accept',width: 60, renderCell: (params) =>{
         return[
           <div>
         {params.row.status==="Rejected" ? "": <CheckIcon style={{color:params.row && params.row.status==="Accepted"?"green":""}} onClick={()=>{updateStatus(params.row,"Accepted")}}/>}
          </div>
          ];},},  
          {field:'reject', headerName: 'Reject',width: 60, renderCell: (params) =>{
            return[
             <div>
           {params.row.status==="Accepted" ? "": <ClearIcon style={{color:params.row && params.row.status==="Rejected"?"red":""}} onClick={()=>{updateStatus(params.row,"Rejected")}}/>}
            </div>
            ];},}, 
            {field:'meeting', headerName: 'Meeting',width: 70, renderCell: (params) =>{
              return[
               <div>
              <CalendarMonthIcon  onClick={()=>{openScheduleModel(params.row)}}/>
              </div>
              ];},},
        {field:'requirementTitle', headerName: 'Title',width: 200,  },
        {field:'estimatedAmount', headerName: 'Amount' ,width: 100, },      
        {field:'estimatedHours', headerName: 'Hours' ,width: 100, },      
        {field:'canBeDelivered', headerName: 'Can Be Delivered' ,width: 150,},      
        {field:'vendorName', headerName: 'Vendor Name' ,width: 150, }, 
        {field:'remarks', headerName: 'Remarks' ,width: 400, },   
        {field:'vendorId', headerName: 'Vendor ID',hide: true,width: 100,},      
       
    ];
    

  const addBid=(id,title,createdById,createdByName)=>{
    setRequirementId(id);
    setTitle(title);
    setCreatedById(createdById);
    setCreatedByName(createdByName);
    getBids(id)
    setOpenModel(true);

    }



 const showGrid = (id)=>{
    
    getAllBiddings(id);
    setOpenGridModel(true)
 }   
   
 const handleClose = () => setOpenApplicant(false)

 const closeModel = () => setOpenModel(false);

 const closeMeetingModel = () => setOpenMeetingModel(false);

 const closeGridModel = () => setOpenGridModel(false);


const openScheduleModel = (row) =>{
 
  // setRequirementId(row.requirementId);
  // setRequirementTitle(row.requirementTitle);
  setMeetingTitle(row.requirementTitle)
  setVendorId(row.vendorid);
  setVendorName(row.vendorName)
  setOpenMeetingModel(true);
}

// //Get requirement by id
  const getRequirements = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getallrequirementsbyuser/"+ user._id ;
    let apiParams = {
        method:"GET",
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
      
        setRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  const updateStatus = (row,status)=>{

  let apiURL=process.env.REACT_APP_API_URL + "/quotation/updatequotation/"+row.id;
  let apiParams = {
      method:"POST",
      headers:{
      requirementid:row.requirementId,  
      status:status,

      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
       
        alert("Quatation Status Updated Successfully!!");
        
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
  }


const getStatus = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
         
          setStatus(repos.data)
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};
 
  const cleartext = ()=>{
    setServices("");
    setDescription("");
    setDeliveryLocation("");
    setDeliveryDeadline("");
    setBudget("");
    setCurrency("");
  }

  const clearBox = ()=>{
  
    setEstimatedAmount("")
    setEstimatedTime("")
    setHours("hours")
    setCanBeDelivered(false)
    setRemarks("")
  }

//Add Jobs
const addRequirement = () =>{
  setOpenApplicant(true)
  getCommunities()
}

const addRequirements = ()=>{
    setOpenApplicant(false)
  let apiURL=process.env.REACT_APP_API_URL + "/requirement/addrequirements";
  let apiParams = {
      method:"POST",
      headers:{
        requirementtitle:services,
        budget:budget,
        currency:encodeURIComponent(JSON.stringify(currency)),
        deliverylocation:encodeURIComponent(deliveryLocation),
        description:encodeURIComponent(description),
        deliverydeadline:deliveryDeadline,
        selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        customerid:user._id,
        customername:user.name,
        status:"Created"

      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        cleartext()
        alert("Requirement Added Successfully");
        
    //    props.showAlert("Job Added Successfully","success")
       setTimeout(() => {
        getRequirements()
      }, 1500);
      
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
}

const addQuotation = ()=>{
   
    setOpenModel(false)
    let EstimatedTime=0;
    if(hours==="hours"){
        EstimatedTime = estimatedTime;
    }else{
        EstimatedTime = estimatedTime*8;
    }
    let apiURL=process.env.REACT_APP_API_URL + "/quotation/addquotation";
    //console.log(JSON.stringify(Currencies.filter(curr=>curr.cc===estimatedCurrency)[0] ))
    let apiParams = {
        method:"POST",
        headers:{
          requirementtitle:title,
          requirementid:requirementId,
          customerid:createdById,
          customername:createdByName,
          estimatedamount:estimatedAmount,
          estimatedcurrency:encodeURIComponent(JSON.stringify(Currencies.filter(curr=>curr.cc===estimatedCurrency)[0] )),
          estimatedhours:EstimatedTime,
          canbedelivered:canBeDelivered,
          remarks:encodeURIComponent(remarks),
          vendorid:user._id,
          vendorname:user.name,
          status:"Submitted"
        },
       
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          cleartext()
          alert("Quatations Added Successfully");
          
         setTimeout(() => {
          getRequirements()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
}



const setCloseGridModel = ()=>{
    setOpenGridModel(false);
}

const getAllBiddings = (requirementId)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/quotation/getallquotations/"+requirementId; 
  
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        
          setBiddings(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  //Get my Communities
  const getCommunities = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/community/getmycommunity/"+user._id; 
  
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        setCommunities(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


//get bid of comunity
  const getBids = (id)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/quotation/getbiddings/"+user._id; 
    let apiParams = {
      headers:{
        requirementid:id,
    }}
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          
           setQuotationId(repos.data[0] && repos.data[0]._id)
          setEstimatedAmount(repos.data[0] && repos.data[0].estimatedAmount);
         setEstimatedCurrency(repos.data[0] && repos.data[0].estimatedCurrency ? JSON.parse(repos.data[0].estimatedCurrency).cc:"INR");
         setEstimatedTime(repos.data[0] && repos.data[0].estimatedHours);
         setCanBeDelivered(repos.data[0] && repos.data[0].canBeDelivered);
         setRemarks(repos.data[0] && repos.data[0].remarks);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }



 const openPopUp = ()=>{
  setRequirementPopup(true)
}


const openViewMorePopUp = (rec)=>{
  setViewMoreData(rec)
  setViewMorePopup(true)
}

const ScheduleMeeting = () =>{
  
  if(meetingTitle ===""||undefined)
  {
    return alert("Please Enter Meeting Title !!")
  }else if(agenda===""||undefined){
    return alert("Please Enter Agenda Of Meeting !!")
  }
  setOpenMeetingModel(false)
  let apiURL=process.env.REACT_APP_API_URL + "/meetings/schedulemeetings";
    let apiParams = {
        method:"POST",
        headers:{
          // requirementtitle:requirementTitle,
          // requirementid:requirementId,
          requesterid:user._id,
          requestername:user.name,
          respondentid:vendorId,
          respondentname:vendorName,
          meetingtitle:meetingTitle,
          meetingdatetime:meetingDateTime,
          agenda:encodeURIComponent(agenda),
          remarks:encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+meetingRemarks),
          status:"Requested",

        },
       
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        
          alert("Meeting Scheduled Successfully");
          
         setTimeout(() => {
          getRequirements()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));

 }

 
//Disable Job
const updateIsActive = (id) =>{
  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
  let apiURL=process.env.REACT_APP_API_URL + "/requirement/updaterequirementsstatus/" +id;
  let apiParams = {
    method:"POST",
    headers:{
      active:false
    },
 };

 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
       alert("Requirement Removed Successfully");
      // props.showAlert("Job Removed Successfully","success")
      setTimeout(() => {
        getRequirements()
      }, 2000);
     }else alert(repos.message || repos);
 }) 
 .catch((err) => alert(err));
 
}



const handleOpenReqSlide = ()=>{
  setOpenReqSlide(true)
}

    


// const rowData = [];      

const rowData = (biddings||[]).map((bid) => {
       
  return{
 
  id: bid._id,
  requirementId:bid.requirementId,
  requirementTitle:bid.requirementTitle,
  estimatedAmount:bid.estimatedAmount,
  estimatedHours:bid.estimatedHours,
  canBeDelivered:bid.canBeDelivered,
  vendorName:bid.vendorName,
  vendorid:bid.vendorId,
  remarks:bid.remarks,
  status:bid.status,
  };
});

const showMonth = (month)=>{
  
  switch (month){
  case 0:
  return "Jan"
    break;
  case 1:
    return "Feb"
    break;
    case 2:
      return "Mar"
      break;
      case 3:
    return "Apr"
    break;
    case 4:
    return "May"
    break;
    case 5:
    return "Jun"
    break;
    case 6:
    return "Jul"
    break;
    case 7:
    return "Aug"
    break;
    case 8:
    return "Sep"
    break;
    case 9:
    return "Oct"
    break;
    case 10:
    return "Nov"
    break;
    case 11:
    return "Dec"
    break;
}
}


  return (
   <div >
	<div id="req_overll_mob_grp">
		<button title="Add Requirements" id="my_off_btn_com_req_mob" onClick={()=>openPopUp()}>
			<svg class="off_btn_rec_com_off_mob">
				<rect id="off_btn_rec_com_off_mob" rx="14.5" ry="14.5" x="0" y="0" width="29" height="40">
				</rect>
			</svg>
			<img id="plus_img_btn_com_off_mob" src="plus_img_btn_com_off_mob.png" srcset="plus_img_btn_com_off_mob.png 1x" />	
		</button>

		<div id="com_off_scroll_down_mob">
    <div style={{height:"100%",display:"flex"}}>
    
    {requirements.map((req,idx)=>(
      <div style={{left:(idx*10).toString()+"px",position:"relative",display:"inline-flex"}}>
			<div title={req.description} id="off_card_com_off_mob">
				<svg class="bdr_com_off_mob_bi">
					<linearGradient id="bdr_com_off_mob_bi" spreadMethod="pad" x1="0.13" x2="0.89" y1="0.039" y2="1">
						<stop offset="0" stop-color="#65ff7f" stop-opacity="1"></stop>
						<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
					</linearGradient>
					<rect id="bdr_com_off_mob_bi" rx="24.5" ry="24.5" x="0" y="0" width="180" height="49">
					</rect>
				</svg>
				<img id="pfp_pic_com_off_mob" src={req.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(req.defaultRequirementsImageUrl.split('/')[5]):"profilebackground.png"} />
					
				<marquee id="software_developer_lbl_com_off">
					<span>{req.requirementTitle}</span>
				</marquee>
				<div id="inr_and_date_com_off_mob">
        <span> {req.currency && JSON.parse(req.currency).symbol} {req.budget}</span><span> | </span><span>{req.deliveryDeadline && new Date(req.deliveryDeadline).getDate() +" "+ showMonth(new Date(req.deliveryDeadline).getMonth())} </span>
				</div>
				<img id="minus_2_close_comoff_mob" src="minus_2_close_comoff_mob.png" srcset="minus_2_close_comoff_mob.png 1x" onClick={()=>updateIsActive(req._id)}/>
					
			</div>
      </div>
      ))}
    </div>
		</div>
		<img id="up-arrow_for_bottom_off" src="up-arrow_for_bottom_off.png" srcset="up-arrow_for_bottom_off.png 1x" onClick={()=>handleOpenReqSlide()}/>
			
	</div>

  {openReqSlide===true? <ComReqSlide openReqSlide={openReqSlide} closeReqSlide={()=>setOpenReqSlide(false)}/>:""}

  <RequirementPopUpPC  openReqPopUp={requirementPopup} closeReqPopUp={()=>{setRequirementPopup(false);getRequirements()}} />
   </div>
  );
}

export default RequirementsBottomMobUI  
