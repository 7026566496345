import React,{useState,useEffect} from 'react'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import  '../styles/EmailSend.css';
import {Link, useNavigate} from "react-router-dom";
import { UserContext } from './UserContext';
import { useSearchParams } from 'react-router-dom';


const ChangePassword = (props) => {
    var navigate = useNavigate();
    const [ email,setEmail] = useState("");
    const [searchParams] = useSearchParams();
    const [inputField,setInputField] = useState({
        otpCode:"",
        password:"",
        cpassword:"",
    });
    const [errField,setErrField] = useState({
        otpCodeErr:"",
        passwordErr:"",
        cpasswordErr:"",
    });


    useEffect(() => {
        if(searchParams.get('email')){
      
        setEmail(searchParams.get('email'))
       }
    }, [])
      
   
    const validForm = () =>{
        let formIsValid = true;
        setErrField({
        otpCodeErr:"",
        passwordErr:"",
        cpasswordErr:"",
        })
        if(inputField.otpCode == ''){
           formIsValid = false
            setErrField(prevstate => ({
                ...prevstate,otpCodeErr:"Please Enter OTP"
            }))
        }
        if(inputField.password == ''){
           formIsValid = false
            setErrField(prevstate => ({
                ...prevstate,passwordErr:"Please Enter Password"
            }))
        } 
       
        if(inputField.password != inputField.cpassword){
           formIsValid = false
            setErrField(prevstate=>({
                ...prevstate,cpasswordErr:'Password are Not Matched'
            }))
        }
        
        return formIsValid

    }

    const inputHandler = (name,value)=>{
        setInputField({...inputField,[name]:value})
    }

const sendUpdatedPassword = async()=>{
if(validForm()){
    let apiUrl = process.env.REACT_APP_API_URL + "/users/changepassword";
    let apiParam = {
          method: 'POST',
            headers:{
                email:email,
                ...inputField
            },
 
        };
    
fetch(apiUrl, apiParam)
.then((res) => (res.status === 200 ? res.json() : res.text()))
.then((repos) => {
  if (repos.statusText ==="success") {
    
   alert("Password has been reset successfully","success")
navigate('/')

  } else {
    alert("Invalid OTP ","error")
  }
})
.catch((err) => alert(JSON.stringify(err)));

}
else{
    alert("Form Invalid ","error")
}
}

const backtoLogin = () =>{
    navigate("/login");
  }
  

  return (
    <div>
        <div className='child'>
    <h3 className='reset' ><b>Reset Password</b></h3>
   <br/>
    <TextField id="" sx={{ m: 2, width: '35ch' }} label="Enter OTP" size="small" variant="outlined"  value={inputField.otpCode} onChange={ e=>
     inputHandler("otpCode",e.target.value)
     }/>{
        errField.otpCodeErr.length > 0 && <span className ='error'><br/>{errField.otpCodeErr}</span>
     }
     <br/>
      <TextField id="" sx={{ m: 2, width: '35ch' }} label="Enter New Password" size="small" variant="outlined" type="password"  value={inputField.password} onChange={e=>
    inputHandler("password",e.target.value)
     }/>{
        errField.passwordErr.length > 0 && <span className ='error'><br/>{errField.passwordErr}</span>
     }
     <br/>
      <TextField id="" sx={{ m: 2, width: '35ch' }} label="Confirm Password" size="small" variant="outlined" type="password"  value={inputField.cpassword} onChange={e=>
     inputHandler("cpassword",e.target.value)
     }/>{
        errField.cpasswordErr.length > 0 && <span className ='error'><br/>{errField.cpasswordErr}</span>
     }
  <br/>
  <br/>
  <Button  variant="contained" sx={{ m: 1, width: '23.5ch',backgroundColor:"#14c871" }} onClick={(e)=>sendUpdatedPassword()}>Change Password</Button>
  <Button variant="contained" sx={{m:1, width: '12ch',backgroundColor:"#14c871" }}   style={{float:"right"}} onClick={(e)=>backtoLogin()}>Back</Button>
    </div>
    </div>
  )
}

export default ChangePassword