import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/PeoplesSuggestionsUI.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import SuggestionPageIntroPopUp from '../SuggestionPageIntroPopUp';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style5 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const PeoplesSuggestionsUI = (props) => {

//UseState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [users,setUsers] = useState("");
const [ allStatus,setAllStatus] = useState("");
const [follow,setFollow] = useState("");
const [openModel,setOpenModel] = useState(false);
const [intro,setIntro] = useState("");
const [cid,setCId] = useState("");
const [name,setName] = useState("");
const [statusReq,setReqq] = useState("");
const [selectedCommunity,setSelectedCommunity] = useState([])
const [communities,setCommunities] = useState([]);
const [openIntroModal,setOpenIntroModal] = useState(false);
const [introData,setIntroData] = useState(false);


 //UseEffect
 useEffect(()=>{
  getAllUsers()
  
},[user]) 
  
const ConnectionReq = async (id,name,status)=>{
  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
    let apiURL=process.env.REACT_APP_API_URL + "/connection/addconnections";
    let apiParams = {
        method:"POST",
        headers:{
          user1id:user._id,
          user1name:user.name,
          status:status,
           user2id:id,
           user2name:name,  
           intro:encodeURIComponent(intro),
           user1selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        },
        
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
      
          alert("Action Updated Successfully!!");
          
      //    props.showAlert("Job Added Successfully","success")
      
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err)); 
}

const openModal = (id,name)=>{

  setOpenModel(true);
  setCId(id);
  setName(name);

}


const getStatus = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          console.log(repos.data)
          setAllStatus(repos.data)
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};


//Converting Image
function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  var base64Flag = "data:image/jpeg;base64,";
  return base64Flag + window.btoa(binary);
}

const getAllUsers = ()=>{
    let apiURL = process.env.REACT_APP_API_URL + "/users/getUserSuggestions/"+user._id;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
            console.log(repos.data)
         setUsers(repos.data)
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
};

const setCloseModel = ()=>{
  setOpenModel(false);
}
  
const closeModel = () => setOpenModel(false);

const addIntro = ()=>{

  ConnectionReq(cid,name,"Requested");
  setOpenModel(false)

}


const openConnectionModal = (id,name)=>{
  
setIntroData({id,name})
 setOpenIntroModal(true)
}

  return (
    <div>
  <div id="Suggestions_page">
	<div id="heading_grp_conreqsug_sug_page">
		<div id="conreqsug_sug_page_pc_new_requ" onClick={()=>props.selectedTab("Requests")}>
			<span>Request</span>
		</div>
		<div id="conreqsug_sug_page_pc_new_conn" onClick={()=>props.selectedTab("Connections")}>
			<span>Connections</span>
		</div>
		<div id="conreqsug_sug_page_pc_new_sug">
			<span>Suggestions</span>
		</div>
		<svg class="underline_conreqsug_sug_page_p">
			<rect id="underline_conreqsug_sug_page_p" rx="0" ry="0" x="0" y="0" width="99" height="3">
			</rect>
		</svg>
	</div>
	<svg class="desgin_3_sug_page_pc_new">
		<rect id="desgin_3_sug_page_pc_new" rx="0" ry="0" x="0" y="0" width="100%" height="203">
		</rect>
	</svg>
	<div id="Scroll_suggestion_new_layout_c">
  {(users||[]).map((us)=>(
    <>
    { user.name !==us.name ?  
		<div id="card_1_suggestion_page_new">
			<svg class="bdr_card_1_sug_sug_page_pc_new" onClick={()=>navigate("../"+ us.nickName)}>
				<rect id="bdr_card_1_sug_sug_page_pc_new" rx="10" ry="10" x="0" y="0" width="185" height="319">
				</rect>
			</svg>
			<img id="Anime_Edit_sug_page_pc_new" src={us.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(us.imageUrl.split('/')[5]):"./Peoples/DefaultImageCon.png"} onClick={()=>navigate("../"+ us.nickName)} />
				
			<div id="job_work_new_layout" onClick={()=>navigate("../"+ us.nickName)}>
				<img id="job-seeker_sug_page_pc_new" src="./Peoples/job-seeker_sug_page_pc_new.png" srcset="./Peoples/job-seeker_sug_page_pc_new.png 1x" />
					
				<div id="recently_affreqevent_sug_page_">
					<span>{us.profession}</span>
				</div>
			</div>
			<div id="Mohammed_Affan_sug_page_pc_new">
				<span>{us.name}</span>
			</div>

		

			<div title="Connect" id="connect_grp_sug_page_pc_new" onClick={()=>{openConnectionModal(us._id,us.name)}}>
				<svg class="con_bdr_sug_page_pc_new">
					<ellipse id="con_bdr_sug_page_pc_new" rx="19" ry="19" cx="19" cy="19">
					</ellipse>
				</svg>
				<img id="conn_n_sug_page_pc_new" src="./Peoples/conn_n_sug_page_pc_new.png" srcset="./Peoples/conn_n_sug_page_pc_new.png 1x" />
					
			</div>

      <div title="Follow" id="follow_grp_sug_page_pc_new" onClick={(e) =>{ConnectionReq(us._id,us.name,"Following")}}>
				<svg class="bdr_gollow_sug_page_pc_new">
					<ellipse id="bdr_gollow_sug_page_pc_new" rx="19" ry="19" cx="19" cy="19">
					</ellipse>
				</svg>
				<img id="folow_img_sug_page_pc_new" src="./Peoples/folow_img_sug_page_pc_new.png" srcset="./Peoples/folow_img_sug_page_pc_new.png 1x" /> 
					
			</div>

			<div title="Ignore" id="ignore_grp_sug_page_pc_new" onClick={(e) =>{ConnectionReq(us._id,us.name,"Ignored")}}>
				<svg class="bfdr_sug_page_pc_new">
					<ellipse id="bfdr_sug_page_pc_new" rx="19" ry="19" cx="19" cy="19">
					</ellipse>
				</svg>
				<img id="ignore_sig_sug_page_pc_new" src="./Peoples/ignore_sig_sug_page_pc_new.png" srcset="./Peoples/ignore_sig_sug_page_pc_new.png 1x" />		
			</div>

			<div title="Block" id="block_grp_sug_page_pc_new" onClick={(e) =>{ConnectionReq(us._id,us.name,"Blocked")}}>
				<svg class="bdr_block_sug_page_pc_new">
					<ellipse id="bdr_block_sug_page_pc_new" rx="19" ry="19" cx="19" cy="19">
					</ellipse>
				</svg>
				<img id="boock_img_sug_page_pc_new" src="./Peoples/boock_img_sug_page_pc_new.png" srcset="./Peoples/boock_img_sug_page_pc_new.png 1x" />
					
			</div>

      <div id="scroll_and_intro_detail_new" >
				<img id="presentation_sug_page_pc_new" src="./Peoples/presentation_sug_page_pc_new.png" srcset="./Peoples/presentation_sug_page_pc_new.png 1x" />
					
				<div id="Scroll_intro_pc_sug_page">
					<div id="recently_event_com_suggestion_p">
						<span>{us.introduction}</span>
					</div>
				</div>
			</div>
		</div>
    :""}
    </>
    ))}
	</div>
</div>

<SuggestionPageIntroPopUp  openSuggestionModal={openIntroModal}  introData={introData} closeSuggestionModal={()=>setOpenIntroModal(false)}/>
    </div>
  )
}

export default PeoplesSuggestionsUI
