import React, { useContext, useState, useEffect } from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import '../../styles/ComOffSlide.css';
import { UserContext } from "../UserContext";
import CreateCommunityPopUpUI from "../CreateCommunityPopUpUI";
import OfferingPopUpPC from "../OfferingPopUpPC";

const ComOffSlide = (props) => {
  
    const { user, setUser } = useContext(UserContext);
    const [openCommunityModal,setOpenCommunityModal] = useState(false);
    const [searchParams] = useSearchParams();
    const [communityDescription,setCommunityDescription] = useState("");
    const [offeringPopup,setOfferingPopup] = useState(false);
    const [offerings,setOfferings] = useState([])
    
useEffect(()=>{
    if(user){
        if(props.openOffSlide)
        {
          getOfferings()  
        }
    }
    
    },[props.openOffSlide])


    //Get applicant by id
  const getOfferings = ()=>{
       
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getallofferingsbyuser/" +user._id ;
    let apiParams = {
        method:"GET",
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        // console.log(repos.data)
        setOfferings(repos.data)
         
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
  
const handleClose = ()=>{
    props.closeOffSlide()
}

  
const openPopUp = ()=>{
  setOfferingPopup(true)
}

   //Converting Image
   function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

  
//Disable Job
const updateIsActive = (id) =>{

  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
  let apiURL=process.env.REACT_APP_API_URL + "/offerings/updateofferingstatus/" +id;
  let apiParams = {
    method:"POST",
    headers:{
      active:false
    },
 };

 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
       alert("Offering Removed Successfully");
      // props.showAlert("Job Removed Successfully","success")
      setTimeout(() => {
        getOfferings()
      }, 2000);
     }else alert(repos.message || repos);
 }) 
 .catch((err) => alert(err));

}



  return (
    <div>
   <div id="off_down">
	<svg class="off_bhack_bg_y">
		<linearGradient id="off_bhack_bg_y" spreadMethod="pad" x1="1" x2="0" y1="1" y2="0">
			<stop offset="0" stop-color="#65ff7f" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="off_bhack_bg_y" rx="0" ry="0" x="0" y="0" width="100%" height="100%">
		</rect>
	</svg>

	<div id="Scroll_overall_off_down">
  <div style={{height:"100%",display:'block'}}>
    {offerings.map((off)=>(
      <div style={{position:"relative",display:"inline-block",padding:"5px",textAlign:"center"}}>
		<div id="off_card__req_">
			<svg class="bdr_com_off_mob_">
				<linearGradient id="bdr_com_off_mob_" spreadMethod="pad" x1="0.13" x2="0.89" y1="0.039" y2="1">
					<stop offset="0" stop-color="#65ff7f" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="bdr_com_off_mob_" rx="24.5" ry="24.5" x="0" y="0" width="180" height="49">
				</rect>
			</svg>
			<img id="pfp_pic_cooff_mob" src="pfp_pic_cooff_mob.png" srcset="pfp_pic_cooff_mob.png 1x" />
				
			<marquee id="Mohammed_SD_lbl_com_off_mob">
				<span>{off.offeringTitle}</span>
			</marquee>
			<div id="inr_and_date_comoffmob">
				<span>{off.offeredcurrency && JSON.parse(off.offeredcurrency).symbol} {off.budget} </span><span> | </span><span>{( off.deliveryDuration ? off.deliveryDuration:"")} Hours</span>
			</div>
			<img id="minus_2_close_comoff_mob" src="minus_2_close_comoff_mob.png" srcset="minus_2_close_comoff_mob.png 1x" onClick={()=>updateIsActive(off._id)}/>
				
		</div>
    </div>
    ))}
    </div>

		<button title="Add Pfferings" id="my_offbtn_com_req_mob" onClick={()=>openPopUp()}>
			<svg class="off_btn_com_req_mob">
				<rect id="off_btn_com_req_mob" rx="20" ry="20" x="0" y="0" width="139" height="40">
				</rect>
			</svg>
			<div id="My_OFF_lbl_btn_com_req_mob">
				<span>My Offerings</span>
			</div>
			<img id="plus_img_btn_comoff_mob" src="plus_img_btn_comoff_mob.png" srcset="plus_img_btn_comoff_mob.png 1x" />
		</button>
	</div>
	<img id="down-arrow_for_top_off" src="down-arrow_for_top_off.png" srcset="down-arrow_for_top_off.png 1x" onClick={()=>handleClose()}/>
		
</div>
<OfferingPopUpPC   openOffpopup={offeringPopup} closeOffpopup={()=>{setOfferingPopup(false);getOfferings()}}/>
    </div>
  )
}

export default ComOffSlide
