import React from 'react';
import "../../../styles/ElectroTech.css";

function ElectroTech(props) {
  return (
    <div id="ElectroTech_Solutions">
      <div id="ETS_27_PCGroup_15103">
        <img id="ETS_27_PCcopy-space-colorful-sewing-thr" src="./Themes/ElectroTech/copy-space-colorful-sewing-thr.png" srcset="./Themes/ElectroTech/copy-space-colorful-sewing-thr.png 1x" />
          
        <div id="ETS_27_PCPowering_Your_World__Expert_El">
          <span>"Powering Your World: <br/>Expert Electrician Services <br/>for a Brighter Tomorrow"</span>
        </div>
        <div id="ETS_27_PCJoin_the_Spark_of_Excellence_I">
          <span>"Join the Spark of Excellence: Illuminating Possibilities Together!"</span>
        </div>
        <div id="ETS_27_PCGroup_15102">
          <svg class="ETS_27_PCRectangle_747">
            <rect id="ETS_27_PCRectangle_747" rx="0" ry="0" x="0" y="0" width="374" height="374">
            </rect>
          </svg>
          <img id="ETS_27_PCelectrical-shielded-cable-with" src="./Themes/ElectroTech/electrical-shielded-cable-with.png" srcset="./Themes/ElectroTech/electrical-shielded-cable-with.png 1x" />
            
        </div>
        <div id="ETS_27_PCGroup_15111">
          <img id="ETS_27_PCAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/ElectroTech/Anime_Edit.png"} />
            
          <div id="ETS_27_PCElectician">
            <span>{props.data.businessName}</span>
          </div>
        </div>
      </div>
      <div id="ETS_27_PCGroup_15039">
        <div id="ETS_27_PCGroup_15033">
          <img id="ETS_27_PCmika-baumeister-dp9AxaKHdVg-un" src="./Themes/ElectroTech/mika-baumeister-dp9AxaKHdVg-un.png" srcset="./Themes/ElectroTech/mika-baumeister-dp9AxaKHdVg-un.png 1x" />
            
          <div id="ETS_27_PCGroup_15038">
            <div id="ETS_27_PCScroll_Group_28">
              <div id="ETS_27_PCIt_is_a_long_established_fact_">
                <span>{props.data.vision}</span>
              </div>
            </div>
            <div id="ETS_27_PCVISION">
              <span>VISION</span>
            </div>
          </div>
        </div>
        <div id="ETS_27_PCGroup_15034">
          <img id="ETS_27_PCpatrick-tomasso-1NTFSnV-KLs-un" src="./Themes/ElectroTech/patrick-tomasso-1NTFSnV-KLs-un.png" srcset="./Themes/ElectroTech/patrick-tomasso-1NTFSnV-KLs-un.png 1x" />
            
          <div id="ETS_27_PCGroup_15037">
            <div id="ETS_27_PCScroll_Group_28_">
              <div id="ETS_27_PCIt_is_a_long_established_fact__">
                <span>{props.data.mission}</span>
              </div>
            </div>
            <div id="ETS_27_PCMISSION">
              <span>MISSION</span>
            </div>
          </div>
        </div>
        <div id="ETS_27_PCGroup_15035">
          <img id="ETS_27_PCframes-for-your-heart-iOLHAIax" src="./Themes/ElectroTech/frames-for-your-heart-iOLHAIax.png" srcset="./Themes/ElectroTech/frames-for-your-heart-iOLHAIax.png 1x" />
            
          <div id="ETS_27_PCScroll_Group_28_bc">
            <div id="ETS_27_PCIt_is_a_long_established_fact__bd">
              <span>{props.data.principles}</span>
            </div>
          </div>
          <div id="ETS_27_PCPRINCIPLES">
            <span>PRINCIPLES</span>
          </div>
        </div>
      </div>
      <div id="ETS_27_PCGroup_15107">
        <div id="ETS_27_PCOur_Services">
          <span>Our Services</span>
        </div>
        <img id="ETS_27_PCsteve-johnson-hokONTrHIAQ-unsp" src="./Themes/ElectroTech/steve-johnson-hokONTrHIAQ-unsp.png" srcset="./Themes/ElectroTech/steve-johnson-hokONTrHIAQ-unsp.png 1x" />
          
        <div id="ETS_27_PCScroll_Group_77">
	{props.offeringsData.map((off,idx)=>(
          <div id="ETS_27_PCn_">
            <img id="ETS_27_PCMask_Group_22" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/ElectroTech/Mask_Group_22.png"} />
              
            <svg class="ETS_27_PCRectangle_664">
              <rect id="ETS_27_PCRectangle_664" rx="0" ry="0" x="0" y="0" width="307" height="46">
              </rect>
            </svg>
            <div id="ETS_27_PCSomething">
              <span>{off.offeringTitle}</span>
            </div>
            <div id="ETS_27_PCGroup_10773">
              <svg class="ETS_27_PCEllipse_38">
                <ellipse id="ETS_27_PCEllipse_38" rx="2.5" ry="2.5" cx="2.5" cy="2.5">
                </ellipse>
              </svg>
              <svg class="ETS_27_PCEllipse_39">
                <ellipse id="ETS_27_PCEllipse_39" rx="3" ry="2.5" cx="3" cy="2.5">
                </ellipse>
              </svg>
              <svg class="ETS_27_PCEllipse_40">
                <ellipse id="ETS_27_PCEllipse_40" rx="3" ry="2.5" cx="3" cy="2.5">
                </ellipse>
              </svg>
              <svg class="ETS_27_PCEllipse_41">
                <ellipse id="ETS_27_PCEllipse_41" rx="3" ry="2.5" cx="3" cy="2.5">
                </ellipse>
              </svg>
              <svg class="ETS_27_PCEllipse_42">
                <ellipse id="ETS_27_PCEllipse_42" rx="3" ry="2.5" cx="3" cy="2.5">
                </ellipse>
              </svg>
            </div>
          </div>))}
        </div>
      </div>
      <div id="ETS_27_PCGroup_15101">
        <div id="ETS_27_PCGroup_15041">
          <div id="ETS_27_PCGroup_15040">
            <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="ETS_27_PCRectangle_737">
              <rect id="ETS_27_PCRectangle_737" rx="0" ry="0" x="0" y="0" width="538" height="302">
              </rect>
            </iframe>
          </div>
          <div id="ETS_27_PCsocials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
            <div title="LinkedIn" id="ETS_27_PClinked_in_grp_shadow_space_web">
              <svg class="ETS_27_PCmed_shadow_space_website_7">
                <ellipse id="ETS_27_PCmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
              </svg>
              <div id="ETS_27_PClinked_in_shadow_space_website">
                <a href={props.data.linkedInUrl} target="_blank">
                <svg class="ETS_27_PCn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
                  <path id="ETS_27_PCn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
                  </path>
                </svg>
              </a>
                <svg class="ETS_27_PClinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
                  <path id="ETS_27_PClinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
                  </path>
                </svg>
                <svg class="ETS_27_PCtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
                  <path id="ETS_27_PCtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
                  </path>
                </svg>
              </div>
            </div>:""}
			{props.data.instagramUrl?	
            <div title="Instagram" id="ETS_27_PCInsta_grp_shadow_space_website">
              <svg class="ETS_27_PCshadow_space_website_7">
                <ellipse id="ETS_27_PCshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
              </svg>
              <a href={props.data.instagramUrl} target="_blank">
              <img id="ETS_27_PCinstagramshadow_space_website_" src="./Themes/ElectroTech/instagramshadow_space_website_.png" srcset="./Themes/ElectroTech/instagramshadow_space_website_.png 1x" />
                
              </a>
            </div>:""}
			{props.data.whatsAppNumber?
            <div title="WhatsApp" id="ETS_27_PCwhatsapp_grp_shadow_space_webs">
              <svg class="ETS_27_PCmed_shadow_space_website_7_b">
                <ellipse id="ETS_27_PCmed_shadow_space_website_7_b" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
              </svg>
              <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="ETS_27_PCwhatsapp_lbl_shadow_space_webs" src="./Themes/ElectroTech/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/ElectroTech/whatsapp_lbl_shadow_space_webs.png 1x" />
                </a>
            </div>:""}
          </div>
          <div id="ETS_27_PCn_234567890_mobile_number_shad">
            <span>{props.data.businessPhone}</span>
          </div>
          <div id="ETS_27_PCaffanghanioperatelive_email_sh">
            <span>{props.data.businessEmail}</span>
          </div>
          <div id="ETS_27_PCaddress_med_shadow_space_websi">
            <span>{props.data.businessAddress}</span>
          </div>
          <img id="ETS_27_PClocation_img_shadow_space_webs" src="./Themes/ElectroTech/location_img_shadow_space_webs.png" srcset="./Themes/ElectroTech/location_img_shadow_space_webs.png 1x" />
            
          <img id="ETS_27_PCemail_address_shadow_space_web" src="./Themes/ElectroTech/email_address_shadow_space_web.png" srcset="./Themes/ElectroTech/email_address_shadow_space_web.png 1x" />
            
          <img id="ETS_27_PCphone_number_shadow_space_webs" src="./Themes/ElectroTech/phone_number_shadow_space_webs.png" srcset="./Themes/ElectroTech/phone_number_shadow_space_webs.png 1x" />
            
          <div id="ETS_27_PCn_Copyright_Operate_Live_OPC_P">
            <span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
          </div>
        </div>
        <div id="ETS_27_PCContact_Us">
          <span>Contact Us!</span>
        </div>
      </div>
    </div>
  )
}

export default ElectroTech