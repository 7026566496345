import React from 'react';
import "../../../styles/PerfumePulseMob.css";

function PerfumePulseMob(props) {
  return (
    <div id="PerfumePulse_mob">
	<div id="PPMOBGroup_15109">
		<div id="PPMOBGroup_15105">
			<img id="PPMOBmika-baumeister-dp9AxaKHdVg-un"  src="./Themes/PerfumePulse/mika-baumeister-dp9AxaKHdVg-un.png"  srcset="./Themes/PerfumePulse/mika-baumeister-dp9AxaKHdVg-un.png 1x" />
				
			<div id="PPMOBGroup_15104">
				<div id="PPMOBScroll_Group_28_bb">
					<div id="PPMOBIt_is_a_long_established_fact__bc">
						<span>{props.data.vision}</span>
					</div>
				</div>
				<div id="PPMOBVISION_bd">
					<span>VISION</span>
				</div>
			</div>
		</div>
		<div id="PPMOBGroup_15106">
			<img id="PPMOBbundo-kim-ur2zmbseUIA-unsplash"  src="./Themes/PerfumePulse/bundo-kim-ur2zmbseUIA-unsplash.png"  srcset="./Themes/PerfumePulse/bundo-kim-ur2zmbseUIA-unsplash.png 1x" />
				
			<div id="PPMOBGroup_15104_ba">
				<div id="PPMOBScroll_Group_28_bb">
					<div id="PPMOBIt_is_a_long_established_fact__bc">
						<span>{props.data.vision}</span>
					</div>
				</div>
				<div id="PPMOBVISION_bd">
					<span>VISION</span>
				</div>
			</div>
		</div>
		<div id="PPMOBGroup_15106_be">
			<img id="PPMOBhanna-balan-d1GwyeOlZDw-unspla"  src="./Themes/PerfumePulse/hanna-balan-d1GwyeOlZDw-unspla.png"  srcset="./Themes/PerfumePulse/hanna-balan-d1GwyeOlZDw-unspla.png 1x" />
				
			<div id="PPMOBGroup_15104_bg">
				<div id="PPMOBScroll_Group_28_bh">
					<div id="PPMOBIt_is_a_long_established_fact__bi">
						<span>{props.data.mission}</span>
					</div>
				</div>
				<div id="PPMOBMISSION">
					<span>MISSION</span>
				</div>
			</div>
		</div>
		<div id="PPMOBGroup_15106_bk">
			<img id="PPMOBemily-wang-a5917t2ea8I-unsplas"  src="./Themes/PerfumePulse/emily-wang-a5917t2ea8I-unsplas.png"  srcset="./Themes/PerfumePulse/emily-wang-a5917t2ea8I-unsplas.png 1x" />
				
			<div id="PPMOBGroup_15104_bm">
				<div id="PPMOBScroll_Group_28_bn">
					<div id="PPMOBIt_is_a_long_established_fact__bo">
						<span>{props.data.principles}</span>
					</div>
				</div>
				<div id="PPMOBPRINCIPLES">
					<span>PRINCIPLES</span>
				</div>
			</div>
		</div>
	</div>
	<div id="PPMOBGroup_15108">
		<div id="PPMOBGroup_15040">
        <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="PPMOBRectangle_737">
				<rect id="PPMOBRectangle_737" rx="0" ry="0" x="0" y="0" width="281" height="158">
				</rect>
			</iframe>
		</div>
		<div id="PPMOBGroup_15050">
			<div id="PPMOBsocials_grp_shadow_space_websi">
{props.data.linkedInUrl?
				<div title="LinkedIn" id="PPMOBlinked_in_grp_shadow_space_web">
					<svg class="PPMOBmed_shadow_space_website_7">
						<ellipse id="PPMOBmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="PPMOBlinked_in_shadow_space_website">
						<a href={props.data.linkedInUrl} target="_blank">
						<svg class="PPMOBn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="PPMOBn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="PPMOBlinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="PPMOBlinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="PPMOBtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="PPMOBtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>
:""}
			

{props.data.instagramUrl?
				<div title="Instagram" id="PPMOBInsta_grp_shadow_space_website">
					<svg class="PPMOBshadow_space_website_7">
						<ellipse id="PPMOBshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={props.data.instagramUrl} target="_blank">
					<img id="PPMOBinstagramshadow_space_website_"  src="./Themes/PerfumePulse/instagramshadow_space_website_.png"  srcset="./Themes/PerfumePulse/instagramshadow_space_website_.png 1x" />
						
					</a>
				</div>:""}
 
 {props.data.whatsAppNumber?
				<div title="WhatsApp" id="PPMOBwhatsapp_grp_shadow_space_webs">
					<svg class="PPMOBmed_shadow_space_website_7_b">
						<ellipse id="PPMOBmed_shadow_space_website_7_b" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
<img id="PPMOBwhatsapp_lbl_shadow_space_webs"  src="./Themes/PerfumePulse/whatsapp_lbl_shadow_space_webs.png"  srcset="./Themes/PerfumePulse/whatsapp_lbl_shadow_space_webs.png 1x" />
					</a>	
				</div>:""}
			</div>
			<div id="PPMOBn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="PPMOBaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="PPMOBaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="PPMOBlocation_img_shadow_space_webs"  src="./Themes/PerfumePulse/location_img_shadow_space_webs.png"  srcset="./Themes/PerfumePulse/location_img_shadow_space_webs.png 1x" />
				
			<img id="PPMOBemail_address_shadow_space_web"  src="./Themes/PerfumePulse/email_address_shadow_space_web.png"  srcset="./Themes/PerfumePulse/email_address_shadow_space_web.png 1x" />
				
			<img id="PPMOBphone_number_shadow_space_webs"  src="./Themes/PerfumePulse/phone_number_shadow_space_webs.png"  srcset="./Themes/PerfumePulse/phone_number_shadow_space_webs.png 1x" />
				
			<div id="PPMOBn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited.<br/> All Rights Reserved</span>
			</div>
		</div>
		<div id="PPMOBVisit_Us_">
			<span>Visit Us !!</span>
		</div>
	</div>
	<div id="PPMOBGroup_15152">
		<div id="PPMOBText">
			<div id="PPMOBExperience_the_finest_in_premi">
				<span>Experience the finest in premium perfume </span>
			</div>
			<div id="PPMOBCompany__Name">
				<span>{props.data.businessName}</span>
			</div>
		</div>
		<div id="PPMOBTop_Nav">
			<div id="PPMOBMenu_Button">
				<svg class="PPMOBLine_1" viewBox="0 0 23.417 3">
					<path id="PPMOBLine_1" d="M 0 0 L 23.41714286804199 0">
					</path>
				</svg>
				<svg class="PPMOBLine_2" viewBox="0 0 46.834 3">
					<path id="PPMOBLine_2" d="M 0 0 L 46.83428573608398 0">
					</path>
				</svg>
				<svg class="PPMOBLine_3" viewBox="0 0 23.417 3">
					<path id="PPMOBLine_3" d="M 0 0 L 23.41714286804199 0">
					</path>
				</svg>
			</div>
		</div>
		<img id="PPMOBAnime_Edit"  src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/PerfumePulse/Anime_Edit.png"} />
			
	</div>
	<div id="PPMOBScroll_Group_77">
	{props.offeringsData.map((rec,idx)=>(  
		<div id="PPMOBn_">
			<img id="PPMOBMask_Group_22" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"./Themes/PerfumePulse/Mask_Group_22.png"} />
				
			<svg class="PPMOBRectangle_664">
				<rect id="PPMOBRectangle_664" rx="0" ry="0" x="0" y="0" width="247" height="46">
				</rect>
			</svg>
			<div id="PPMOBSomething">
				<span>{rec.offeringTitle}</span>
			</div>
			<div id="PPMOBGroup_10773">
				<svg class="PPMOBEllipse_38">
					<ellipse id="PPMOBEllipse_38" rx="2.5" ry="2.5" cx="2.5" cy="2.5">
					</ellipse>
				</svg>
				<svg class="PPMOBEllipse_39">
					<ellipse id="PPMOBEllipse_39" rx="3" ry="2.5" cx="3" cy="2.5">
					</ellipse>
				</svg>
				<svg class="PPMOBEllipse_40">
					<ellipse id="PPMOBEllipse_40" rx="3" ry="2.5" cx="3" cy="2.5">
					</ellipse>
				</svg>
				<svg class="PPMOBEllipse_41">
					<ellipse id="PPMOBEllipse_41" rx="3" ry="2.5" cx="3" cy="2.5">
					</ellipse>
				</svg>
				<svg class="PPMOBEllipse_42">
					<ellipse id="PPMOBEllipse_42" rx="3" ry="2.5" cx="3" cy="2.5">
					</ellipse>
				</svg>
			</div>
		</div>))}
	</div>
	<div id="PPMOBIn_Stock">
		<span>In Stock</span>
	</div>
	<div id="PPMOBBGs">
		<div id="PPMOBSee_the_Collection">
			<svg class="PPMOBBG">
				<rect id="PPMOBBG" rx="0" ry="0" x="0" y="0" width="195" height="204">
				</rect>
			</svg>
			<div id="PPMOBIcon_feather-arrow-right-circl">
				<svg class="PPMOBPath_1" viewBox="3 3 16.69 16.69">
					<path id="PPMOBPath_1" d="M 19.68985748291016 11.34494781494141 C 19.68985748291016 15.95373344421387 15.95370864868164 19.68988800048828 11.34492969512939 19.68988800048828 C 6.736152648925781 19.68988800048828 3 15.95373344421387 3 11.34494781494141 C 3 6.736159801483154 6.736152648925781 3 11.34492969512939 3 C 15.95370864868164 3 19.68985748291016 6.736159801483154 19.68985748291016 11.34494781494141 Z">
					</path>
				</svg>
				<svg class="PPMOBPath_2" viewBox="18 12 3.338 6.676">
					<path id="PPMOBPath_2" d="M 17.99999809265137 18.67595672607422 L 21.33797073364258 15.33797836303711 L 17.99999809265137 12">
					</path>
				</svg>
				<svg class="PPMOBPath_3" viewBox="12 18 6.676 3">
					<path id="PPMOBPath_3" d="M 12 18 L 18.67594528198242 18">
					</path>
				</svg>
			</div>
			<div id="PPMOBSee_the_Collection_c">
				<div id="PPMOBSee_the">
					<span>See the</span>
				</div>
				<div id="PPMOBcollection">
					<span>collection</span>
				</div>
			</div>
		</div>
		<img id="PPMOBmiguel-andrade-potCPE_Cw8A-uns"  src="./Themes/PerfumePulse/miguel-andrade-potCPE_Cw8A-uns.png"  srcset="./Themes/PerfumePulse/miguel-andrade-potCPE_Cw8A-uns.png 1x" />
			
		<img id="PPMOBjeroen-den-otter-2b0JeJTEclQ-u"  src="./Themes/PerfumePulse/jeroen-den-otter-2b0JeJTEclQ-u.png"  srcset="./Themes/PerfumePulse/jeroen-den-otter-2b0JeJTEclQ-u.png 1x" />
			
	</div>
</div>
  )
}

export default PerfumePulseMob