import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioSmallReqCard.css';
import { UserContext } from '../UserContext';


const MyPortfolioSmallReqCard = (props) => {
	
	const {user} = useContext(UserContext);
	 
	const handleLike = (reqId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/requirement/updatetrophy/"+reqId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
		  };
	
  return (
   
	// <div id="n__card_grp_reqoff_req_Pc">
	<div id="small_4_trending_req_Pc">
		<svg class="all_bdr_small_4_trending_req_Pc">
			<rect id="all_bdr_small_4_trending_req_Pc" rx="10" ry="10" x="0" y="0" width="255" height="313">
			</rect>
		</svg>
		<img id="Image_small_4_trending_req_Pc" src={props.data.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultRequirementsImageUrl.split('/')[5]):"./MyPortfolio/Requirements/Image_small_4_trending_off_PC.png"} />
			
		<svg class="bdr_amount_small_4_trending_req_Pc">
			<rect id="bdr_amount_small_4_trending_req_Pc" rx="17" ry="17" x="0" y="0" width="75" height="34">
			</rect>
		</svg>
		<div id="n_9_small_4_trending_req_Pc">
			<span>{props.data.currency && JSON.parse(props.data.currency).symbol} {props.data.budget}</span>
		</div>
		<div id="NikeCourt_Air_small_4_trending_req_Pc">
			<span>{props.data.requirementTitle}</span>
		</div>
		<div id="Single_Tour_small_4_trending_req_Pc">
			<span>{( props.data.deliveryDeadline ? (props.data.deliveryDeadline).substring(0,10):"")} | {props.data.deliveryLocation}</span>
		</div>
		<svg class="number_small_4_trending_req_Pc">
			<rect id="number_small_4_trending_req_Pc" rx="0" ry="0" x="0" y="0" width="39" height="21">
			</rect>
		</svg>
		<svg class="trohpie_bdr_small_4_trending_req_Pc">
			<ellipse id="trohpie_bdr_small_4_trending_req_Pc" rx="19" ry="18.5" cx="19" cy="18.5">
			</ellipse>
		</svg>
		<img id="trophy_small_4_trending_req_Pc" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(props.data._id)} />
			
		<div id="n_234_small_4_trending_req_Pc">
			<span>{(JSON.parse(props.data.trophies)).length}</span>
		</div>
		<svg class="share_bdr_small_4_trending_req_Pc">
			<ellipse id="share_bdr_small_4_trending_req_Pc" rx="19" ry="19" cx="19" cy="19">
			</ellipse>
		</svg>
		<img id="share_2_reqoffevent_req_Pc" src="./MyPortfolio/Requirements/share_2_reqoffevent_off_PC.png" srcset="./MyPortfolio/Requirements/share_2_reqoffevent_off_PC.png 1x" />
			
	</div>


// </div>
      
  )
}

export default MyPortfolioSmallReqCard
