import React, { useState, useEffect, useContext } from "react";
// import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider } from "@mui/material";
import "../../styles/ProfileMobUI.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
// import { id } from 'date-fns/locale';
import { UserContext } from "../UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { autocompleteClasses } from "@material-ui/core";
import countries from "../Countries.json";
import Currencies from "../Currencies.json";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import UserInfoMobUI from "./UserInfoMobUI";
import ADProfileUI from "../PC/ADProfileUI";
import OfferingProfileFeedPC from "../PC/OfferingProfileFeedPC";
import RequirementProfileFeedPC from "../PC/RequirementProfileFeedPC";
import NotificationProfileUI from "../PC/NotificationProfileUI";
import CalenderProfileUI from "../PC/CalenderProfileUI";
import EventsProfilePCUI from "../PC/EventsProfilePCUI";
import SuggestionProfilePCUI from "../PC/SuggestionProfilePCUI";
import ADProfileMobUI from "./ADProfileMobUI";
import ProfileOfferingsMobUI from "./ProfileOfferingsMobUI";
import ProfileRequirementsMobUI from "./ProfileRequirementsMobUI";
import ProfileEventsMobUI from "./ProfileEventsMobUI";

const PartnerProfileMobUI = (props) => {
  const style6 = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 445,
    maxWidth: 600,
    margin: "auto",
    scrollY: "auto",
    // bgcolor: "background.paper",
    border: "2px solid #000",
  };

  const style7 = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 250,
    maxWidth: 350,
    margin: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
  };

  const label = { inputProps: { "aria-label": "Schedule Interview" } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule] = React.useState();
  const [searchParams] = useSearchParams();

  const { user, setUser } = useContext(UserContext);
  var navigate = useNavigate();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState(new Date());
  const [whatsNumber, setWhatsNumber] = useState("");
  const [img, setImg] = useState("");
  const [gender, setGender] = useState("");
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportingToId, setReportingToId] = useState("");
  const [reportingToName, setReportingToName] = useState("");
  const [accessType, setAccessType] = useState("");
  const [country, setCountry] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [currency, setCurrency] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [profession, setProfession] = useState("");
  const [active, setActive] = useState(true);
  const [confirmPass, setConfirmPass] = useState("");
  const [password, setPassword] = useState("");
  const [pincode, setPincode] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [openChgPwd, setOpenChgPwd] = React.useState(false);
  const [previousPass, setPreviousPass] = useState("");
  const [profile, setProfile] = useState({});
  const [requirements, setRequirements] = useState([]);
  const [offerings, setOfferings] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (user) {console.log(window.location.pathname);getUserProfile(window.location.pathname);}
  }, [user,window.location.pathname]);

  const getUserProfile = (nickName) => {
    let apiURL = process.env.REACT_APP_API_URL + "/public/getprofile"+nickName;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          console.log(repos.data);
          setProfile(repos.data.profile);
          setRequirements(repos.data.requirements);
          setOfferings(repos.data.offerings)
          setEvents(repos.data.events);
        //  setWebData(repos.data.profile);   
        //  setOfferingsData(repos.data.offerings)
        //  setEmployees(repos.data);
         setAddress(repos.data.profile.address);
         setDob(repos.data.profile.dob && repos.data.profile.dob.substring(0, 10));
         setEmail(repos.data.profile.email);
         setName(repos.data.profile.name);
         setGender(repos.data.profile.gender);
         setMobile(repos.data.profile.phone);
         setCountry(repos.data.profile.country);
         setState(repos.data.profile.state);
         setCity(repos.data.profile.city);
         setPincode(repos.data.profile.pincode);
         setProfession(repos.data.profile.profession);
         setIntroduction(repos.data.profile.introduction);
        //  setImg(arrayBufferToBase64(repos.data.profile.image.data.data));
         setCurrency(repos.data.profile.currency);
         setHourlyRate(repos.data.profile.hourlyRate);
        } 
        // else alert("Invalid Url");
      })
      .catch((err) => alert(err));
  };
  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function(theFile) {
      return function(e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const handleImageUpload = async (e) => {
    // if (e.target.files[0].size > 71680) {
    //   alert("Please choose a file with size below 70kb!");
    //   return;
    // }
    handleFileSelect(e.target.files[0]);
    let formdata = new FormData();
    formdata.append("image", e.target.files[0], e.target.value);
    updateProfilePic(formdata);
  };
  const updateProfilePic = (formdata) => {
    const apiurl =
      process.env.REACT_APP_API_URL + "/users/updateprofilepic/" + user._id;
    var apiparam = {
      method: "POST",
      // headers: {
      //   id: user._id,
      // },
      body: formdata,
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Image Uploaded Successfully!!");
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  // const getUserProfile = () => {
  //   let apiURL =
  //     process.env.REACT_APP_API_URL + "/users/getUserDetails/" + user._id;
  //   let apiParams = {
  //     method: "GET",
  //   };
  //   fetch(apiURL, apiParams)
  //     .then((res) => (res.status === 200 ? res.json() : res.text()))
  //     .then((repos) => {
  //       if (repos.data) {
  //         console.log(repos.data);
  //         setEmployees(repos.data);
  //         setAddress(repos.data.address);
  //         setDob(repos.data.dob.substring(0, 10));
  //         setEmail(repos.data.email);
  //         setName(repos.data.name);
  //         setGender(repos.data.gender);
  //         setMobile(repos.data.phone);
  //         setCountry(repos.data.country);
  //         setState(repos.data.state);
  //         setCity(repos.data.city);
  //         setPincode(repos.data.pincode);
  //         setProfession(repos.data.profession);
  //         setIntroduction(repos.data.introduction);
  //         setImg(arrayBufferToBase64(repos.data.image.data.data));
  //         setCurrency(repos.data.currency);
  //         setHourlyRate(repos.data.hourlyRate);
  //       } else alert(repos.message || repos);
  //     })
  //     .catch((err) => alert(err));
  // };

  const UpdateEmployeesDetails = (id) => {
    let apiURL =
      process.env.REACT_APP_API_URL + "/users/updateEmployees/" + user._id;
    let apiParams = {
      method: "POST",
      headers: {
        name: name,
        gender: gender,
        dob: dob,
        mobile: mobile,
        email: email,
        country: country,
        state: state,
        city: city,
        address: encodeURIComponent(address),
        pincode: pincode,
        profession: profession,
        introduction: encodeURIComponent(introduction),
        currency: currency,
        hourlyrate: hourlyRate,
        // password: encodeURIComponent(password),
        // active: active,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setIsLoading(false);
          alert("User Updated Successfully");
          // props.showAlert("User Updated Successfully", "success");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch((err) => alert(err));
  };

  const PasswordChange = () => {
    if (password !== confirmPass) {
      alert("Password and Confirm Password don't match!!");
      return;
    }
    setOpenChgPwd(false);
    let apiURL =
      process.env.REACT_APP_API_URL + "/users/updatePassword/" + user._id;
    let apiParams = {
      method: "POST",
      headers: {
        previouspass: encodeURIComponent(previousPass),
        newpassword: encodeURIComponent(password),
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setIsLoading(false);
          alert("Password Changed Successfully!!!");
          handlelogout();
        } else alert(repos);
      })
      .catch((err) => alert(err));
  };

  const handlelogout = () => {
    localStorage.clear();
    setUser(null);
    window.location.href = "/login";
  };

  const handleClosePwd = () => {
    setOpenChgPwd(false);
  };

  return (
    <div className="main-page-mob">
      <div className="container-row-mob">
        <div className="main-content-mob">
        <div style={{overflowY:"scroll",height:"calc(100vh - 75px)"}}>
          <div className="inner-table-mob">
            <div className="container-row-mob">
              <div className="container-cell-mob">
                <UserInfoMobUI profile={profile}/>
              </div>
            </div>
            <div className="container-row-mob">
              <div className="container-cell-mob">
                <ADProfileMobUI />
              </div>
            </div>
            <div className="container-row-mob">
              <div className="container-cell-mob">
                <ProfileOfferingsMobUI offerings={offerings} />
              </div>
            </div>
            <div className="container-row-mob">
              <div className="container-cell-mob">
                <ProfileRequirementsMobUI requirements={requirements} />
              </div>
            </div>
            <div className="container-row-mob">
              <div className="container-cell-mob">
                <ProfileEventsMobUI events={events}/>
              </div>
            </div>
          </div>
          </div>
        </div>
     
      </div>
    </div>
  );
};

export default PartnerProfileMobUI;
