import React, { useState, useEffect, useContext } from "react";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import "../styles/loginMobUI.css";
import {Link, useNavigate} from "react-router-dom";
import { UserContext } from './UserContext';

const LoginMobUI = () => {
var navigate = useNavigate();
const [userName, setUserName] = useState ("");
const [password, setPassword] = useState ("");
const [name,setName] = useState("");
const { user, setUser} = useContext(UserContext);


useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const redirect = () =>{
		if(userName.length === 0){
			return	alert("Please Enter Email!!");
			}
		if(password.length === 0){
			return	alert("Please Enter Password!!");
			}
        const apiUrl = process.env.REACT_APP_API_URL + "/users/login";
        var apiParam = {
          method: 'POST',
          headers: { email: userName, password: password },
          credentials: 'same-origin'
        };
        fetch(apiUrl, apiParam)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
              localStorage.setItem("loggedintoken", repos.data);
              localStorage.setItem("loggedInUser", atob(repos.data.split(".")[1]));
              setUser(JSON.parse(atob(repos.data.split(".")[1])))
              setName(JSON.parse(atob(repos.data.split(".")[1])).name)
              navigate("/home");
            } else alert(JSON.stringify(repos));
          })
          .catch((err) => alert(JSON.stringify(err)));
      };



  return (
    <>
	<div id="index_page_Mob_Log">
	<img id="Logo_Mob_Login" src="n_6170.png" srcset="n_6170.png 1x, n_6170@2x.png 2x" />
		
	<svg class="Top_Login_Notch">
		<ellipse id="Top_Login_Notch" rx="236" ry="103.62948608398438" cx="236" cy="103.62948608398438">
		</ellipse>
	</svg>
	<svg class="Top_Login_border_M">
		<rect id="Top_Login_border_M" rx="65" ry="65" x="0" y="0" width="349" height="316">
		</rect>
	</svg>
	<svg class="Mob_Login_Border_">
		<rect id="Mob_Login_Border_" rx="36" ry="36" x="0" y="0" width="349" height="667">
		</rect>
	</svg>
	<img id="image_login_Mob" src="image.png" srcset="image.png 1x, image@2x.png 2x" />
		
	<div id="OPERATE_LIVE_Mob_Login">
		<span>OPERATE </span><span style={{color:"red"}}>LIVE</span>
	</div>
	<input placeholder="Password" class="txtPassword_Mob_" type="password" tabIndex={2}  value={password}  onChange={(e)=>setPassword(e.target.value)}/>
		{/* <rect id="txtPassword_Mob_" rx="18.5" ry="18.5" x="0" y="0" width="269" height="37">
		</rect>
	</input> */}
	<input placeholder="Email" class="txtEmail_Mob_" tabIndex={1} value={userName} onChange={(e)=>setUserName(e.target.value)} />
		{/* <rect id="txtEmail" rx="18.5" ry="18.5" x="0" y="0" width="269" height="37">
		</rect>
	</input> */}
	<svg class="Ellipse_5_Mob_Log">
		<ellipse id="Ellipse_5_Mob_Log" rx="35" ry="35" cx="35" cy="35">
		</ellipse>
	</svg>

	{/* <img id="right-arrow1_M_Log" src="right-arrow1.png" srcset="right-arrow1.png 1x, right-arrow1@2x.png 2x" /> */}
	<img id="right-arrow1_M_Log" src="padlock_login_pageMob.png" srcset="padlock_login_pageMob.png 1x" />
		

	<img id="slazzer-edit-image1_M_Log" src="slazzer-edit-image1.png" srcset="slazzer-edit-image1.png 1x, slazzer-edit-image1@2x.png 2x" />
		
	<div id="Login_button_Mob_Log" tabIndex={3} onClick={()=>redirect()}>
		
			<button id="Login_button_z_Mob">
				<svg class="Rectangle_2_Mob">
					<rect id="Rectangle_2_Mob" rx="19.5" ry="19.5" x="0" y="0" width="155" height="39">
					</rect>
				</svg>
				<div id="LOGIN__">
					<span>LOGIN </span>
				</div>
			</button>
		
	</div>
	<div onClick={()=>navigate('/sendmail')} id="Forgot_Password_Mob_Log">
		<span>Forgot Password?</span>
	</div>
	<div onClick={()=>navigate('/signup')} id="Dont_have_an_Account_Mob_Log">
		<span>Sign-Up for Free!!</span>
	</div>
</div>
   </> 
  )
}

export default LoginMobUI