import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioBigOffCard.css';
import { UserContext } from '../UserContext';

const MyPortfolioBigOffCard = (props) => {
	const {user} = useContext(UserContext);
	 


	const handleLike = (offId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/offerings/updatetrophy/"+offId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
		  };
	
  return (

      	<div id="big_1_trending_off_PC">
					<svg class="bdr_all_trending_off_PC">
						<rect id="bdr_all_trending_off_PC" rx="10" ry="10" x="0" y="0" width="540" height="680">
						</rect>
					</svg>
					<svg class="bdr_amount_trending_off_PC">
						<rect id="bdr_amount_trending_off_PC" rx="17" ry="17" x="0" y="0" width="63" height="34">
						</rect>
					</svg>
					<div id="n_9_trending_off_PC">
						<span>{props.data.offeredcurrency && JSON.parse(props.data.offeredcurrency).symbol} {props.data.budget}</span>
					</div>
					<img id="Image_trending__off_PC" src={props.data.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultOfferingsImageUrl.split('/')[5]):"./MyPortfolio/Offerings/Image_trending__off_PC.png"}  />
						
					<div id="Nike_Air_Force_1_Sha_trending_">
						<span>{props.data.offeringTitle}</span>
					</div>
					<div id="Single_Tour_trending_off_PC">
						<span>{( props.data.deliveryDuration ? props.data.deliveryDuration:"")} Hours | {props.data.deliveryLocation}</span>
					</div>
					<svg class="likes_trending_off_PC">
						<rect id="likes_trending_off_PC" rx="0" ry="0" x="0" y="0" width="39" height="21">
						</rect>
					</svg>
					<svg class="bdr_1_trending_off_PC">
						<ellipse id="bdr_1_trending_off_PC" rx="19" ry="18.5" cx="19" cy="18.5">
						</ellipse>
					</svg>
					<img id="trophy_trending_off_PC" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(props.data._id)} />
						
					<div id="n_234_like_trending_off_PC">
						<span>{(JSON.parse(props.data.trophies)).length}</span>
					</div>
                    <svg class="trohpie_bdr_small_7_trending_o">
					<ellipse id="trohpie_bdr_small_7_trending_o" rx="19" ry="19" cx="19" cy="19">
					</ellipse>
				</svg> 
				<img id="share_1_reqoffevent_off_PC_da" src="./MyPortfolio/Offerings/share_1_reqoffevent_off_PC_da.png" srcset="./MyPortfolio/Offerings/share_1_reqoffevent_off_PC_da.png 1x" />
					
				</div>
  
  )
}

export default MyPortfolioBigOffCard
