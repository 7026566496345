import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from './UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import '../styles/OfferingsViewMorePopUp.css';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const OfferingsViewMorePopUp = (props) => {

    var navigate = useNavigate();
    const communityId = props.communityId;
    const communityName = props.communityName;
    const { user, setUser} = useContext(UserContext);
    const [openGridViewModel,setOpenGridViewModel] = useState(false);
    const [offDescription,setOffDescription] =useState("");
    const [offTitle,setOffTitle] = useState("");
    const [ communities,setCommunities] = useState([]);
    const [selectedCommunity,setSelectedCommunity] = useState([]);
    const [selectedCommunities,setSelectedCommunities] = useState([]);

useEffect(()=>{
    if(user){
        if(props.openViewMore){
            setOpenGridViewModel(props.openViewMore)
            if(props.viewMoreData){
                setOffDescription(props.viewMoreData.description?props.viewMoreData.description:"")
                setOffTitle(props.viewMoreData.offeringTitle?props.viewMoreData.offeringTitle:"")
                setSelectedCommunities(props.viewMoreData.selectedCommunity?JSON.parse(props.viewMoreData.selectedCommunity):[])
            }
        }
    }

},[props.openViewMore])


const closeGridModel = ()=>{
    setOpenGridViewModel(false)
    props.closeViewMore()
}


  return (
    <div>
    <Modal
        open={openGridViewModel}
        onClose={closeGridModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
      <div id="view_more_pop_up_off">
	<div id="Group_pop_off_vm">
		<img id="bg_vm_pop_pff" src="n_6170.png"  />
			
		
		<div id="Description_viewPop_off_">
			<span>Description</span>
		</div>
		<svg class="border_viewpop_off_">
			<rect id="border_viewpop_off_" rx="15" ry="15" x="0" y="0" width="172" height="30">
			</rect>
		</svg>
		<div id="Website_Development_title_off_vm">
			<span>{offTitle}</span>
		</div>
		<div id="Scroll_description_vm_pop_" style={{height:props.type==="CO"?"150px":""}}>
			<div id="des_a_website_off_vm"  style={{height:props.type==="CO"?"150px":""}}>
				<span>{offDescription}</span>
			</div>
		</div>
         
        {/* {props.type==="O" ?	<div id="My_Community_title_off_vm">
			<span>My Community</span>
		</div>:""} */}

        {/* <select class="border_des_vm_pop">
			<rect id="border_des_vm_pop" rx="9" ry="9" x="0" y="0" width="167" height="69">
			</rect>
		</select> */}
        {/* {props.type==="O" ?    <Autocomplete
      multiple
      limitTags={1}
      id="checkboxes-tags-demo"
      options={communities}
      size="small"
      disableCloseOnSelect
      getOptionLabel={(option) => option.communityName}
      isOptionEqualToValue={(o, v) => o._id === v._id}
      defaultValue={selectedCommunities?selectedCommunities:[]}
      renderOption={(props, option, { selected }) => (
        
        <li {...props}>
       
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            //style={{ marginRight: 8 }}
            checked={selected}
          />
        
          {option.communityName}
         
        </li>
        
      )}
      onChange={(event, newValue) => {
      setSelectedCommunities(newValue);
    }}
      sx={{width: '25ch' }}
      renderInput={(params) => (
        <TextField {...params} className="border_des_vm_pop" label="My Communities" placeholder="Communities" />
      )}
    />:""} */}


	</div>
	<img id="img_view_pop_off" src="coding.png" srcset="coding.png 1x, coding@2x.png 2x" />
    <img title="Close" id="close_Offerings_view_pc"  onClick={()=>{closeGridModel()}} src="close2.png" srcset="close2.png 1x, close2@2x.png 2x" />
      </div>
    </Modal>
    </div>
  )
}

export default OfferingsViewMorePopUp
