import React, { useContext, useState,useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Paper,
} from "@mui/material";

import { UserContext } from './UserContext';
import { makeStyles } from "@material-ui/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Assignment, Phone, PhoneDisabled } from "@material-ui/icons";
import { SocketContext } from "../SocketContext";
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  gridContainer: {
    width: "100%",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        flexDirection: "column",
      },
    },
  },
  container: {
    width: "600px",
    margin: "35px 0",
    padding: 0,
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        width: "80%",
      },
    },
    margin1: {
      marginTop: 20,
    },
    padding1: {
      padding: 20,
    },
    paper: {
      padding: "10px 20px",
      border: "2px solid black",
    },
  },
}));

const Options = ({ children }) => {
  
  const { user, setUser} = useContext (UserContext);
  const {
    me,
    callAccepted,
    name,
    setName,
    mute,
    setMute,
    videoOff,
    setVideoOff,
    isOnline,
    callEnded,
    leaveCall,
    callUser,
  } = useContext(SocketContext);

  const [idToCall, setIdToCall] = useState("");

  useEffect(()=>user&&setName(user.name),[user])
 
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Paper elevation={10} className={classes.paper}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} md={6} className={classes.padding1}>
              <Typography gutterBottom variant="h6">
                Account Info
              </Typography>
              <TextField
                label="Name"
                value={(user && user.name)||"Name"}
                //onChange={(e) =>setName(user.name)}
                fullWidth
              />
              <CopyToClipboard text={me} className={classes.margin1}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={e=>setIdToCall(me)}
                  startIcon={<Assignment fontSize="large" />}
                >
                
                </Button>
              </CopyToClipboard>
              </Grid>
            <Grid item xs={12} md={6} className={classes.padding1}>
              <Typography gutterBottom variant="h6">
                Make a Call
              </Typography>
              <TextField
                label="Mobile to Call"
                value={me}
                onChange={(e) => setIdToCall(e.target.value)}
                fullWidth
              />
             {callAccepted && !callEnded ? (
                <Button variant="contained" color="secondary" startIcon={<PhoneDisabled fontSize="large"/>} fullWidth onClick={leaveCall} className={classes.margin}></Button>
             ):(
                <Button variant="contained" color="primary" startIcon={<Phone fontSize="large"/>} fullWidth onClick={()=>{setName(user.name);callUser(idToCall)}} className={classes.margin}></Button>
             )}
            </Grid>
 
          </Grid>
        </form>
        {children}
      </Paper>

     
    </Container>
  );
};

export default Options;
