import React from 'react'

const BargainTimeLineMob = (props) => {

  return (
<div id="Scroll_timeline_view_page__mob_off">
{props.bargainsHistory.map((rec)=>(
    <div id="timeline_grp_view_page_mob_off">
    <svg class="behind_gren_line__view_page_mo">
        <rect id="behind_gren_line__view_page_mo" rx="6.5" ry="6.5" x="0" y="0" width="100" height="13">
        </rect>
    </svg>
    <svg class="bg_circle_green__view_page_mob">
        <ellipse id="bg_circle_green__view_page_mob" rx="11" ry="11" cx="11" cy="11">
        </ellipse>
    </svg>
    <svg class="first_circ__view_page_mob_off">
        <ellipse id="first_circ__view_page_mob_off" rx="5" ry="5" cx="5" cy="5">
        </ellipse>
    </svg>
    <svg class="first_green__view_page_mob_off">
        <rect id="first_green__view_page_mob_off" rx="3" ry="3" x="0" y="0" width="90" height="6">
        </rect>
    </svg>
    <div id="Bargained__view_page_mob_off">
    <span>{rec.status}</span><br/>
	<span>{rec.userName}</span>
    </div>
    <div id="n_4__12__view_page_mob_off">
        <span>{rec.createdAt.toLocaleString().substring(0,10)}</span>
    </div>
    </div>
    ))
}
</div>
  )
}

export default BargainTimeLineMob
