import React from 'react'
import "../../../styles/JourneyJunkiesMob.css";

function JourneyJunkiesMob(props) {
  return (
    <div id="Journey_Junkies_Mob">
	<svg class="JJ_Mb_Rectangle_682_cb">
		<linearGradient id="JJ_Mb_Rectangle_682_cb" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
			<stop offset="0" stop-color="#d5ffd9" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="JJ_Mb_Rectangle_682_cb" rx="0" ry="0" x="0" y="0" width="375" height="786">
		</rect>
	</svg>
	<img id="JJ_Mb_leaf" src="./Themes/JourneyJunkies/leaf.png" srcset="./Themes/JourneyJunkies/leaf.png 1x" />
		
	<div id="JJ_Mb_explore_Unwind_Discover">
		<span>explore<br/>Unwind<br/>Discover</span>
	</div>
	<div id="JJ_Mb_Group_14874">
		<svg class="JJ_Mb_path_3" viewBox="0 0 334.237 323.476">
			<path id="JJ_Mb_path_3" d="M 293.6080932617188 97.44513702392578 C 293.6080932617188 97.44513702392578 223.8881072998047 -69.26080322265625 104.0122833251953 33.41767120361328 C -15.86363697052002 136.0960845947266 -25.9797534942627 198.3101348876953 42.30398559570312 258.5010070800781 C 110.5876846313477 318.6917724609375 228.9461517333984 323.2440795898438 228.9461517333984 323.2440795898438 C 228.9461517333984 323.2440795898438 370.0658569335938 335.88916015625 325.5550231933594 174.5373077392578 C 319.7607421875 153.5331573486328 293.6080932617188 97.44513702392578 293.6080932617188 97.44513702392578 Z">
			</path>
		</svg>
		<svg class="JJ_Mb_path_2" viewBox="0 0 334.237 323.476">
			<path id="JJ_Mb_path_2" d="M 293.6080932617188 97.44513702392578 C 293.6080932617188 97.44513702392578 223.8881072998047 -69.26080322265625 104.0122833251953 33.41767120361328 C -15.86363697052002 136.0960845947266 -25.9797534942627 198.3101348876953 42.30398559570312 258.5010070800781 C 110.5876846313477 318.6917724609375 228.9461517333984 323.2440795898438 228.9461517333984 323.2440795898438 C 228.9461517333984 323.2440795898438 370.0658569335938 335.88916015625 325.5550231933594 174.5373077392578 C 319.7607421875 153.5331573486328 293.6080932617188 97.44513702392578 293.6080932617188 97.44513702392578 Z">
			</path>
		</svg>
		<img id="JJ_Mb_path_1" src="./Themes/JourneyJunkies/path_1.png" srcset="./Themes/JourneyJunkies/path_1.png 1x" />
			
	</div>
	<div id="JJ_Mb_Group_14875">
		<div id="JJ_Mb_Scroll_Group_19">
			<div id="JJ_Mb_Lorem_ipsum_dolor_sit_amet_con">
				<span>{props.data.introduction}</span>
			</div>
		</div>
		<svg class="JJ_Mb_Line_1" viewBox="0 0 4 254.938">
			<path id="JJ_Mb_Line_1" d="M 0 0 L 0 254.9384307861328">
			</path>
		</svg>
	</div>
	<div id="JJ_Mb_Group_14876">
		<img id="JJ_Mb_joaquin-3VK6Urf2vE8-unsplash" src="./Themes/JourneyJunkies/joaquin-3VK6Urf2vE8-unsplash.png" srcset="./Themes/JourneyJunkies/joaquin-3VK6Urf2vE8-unsplash.png 1x" />
			
		<img id="JJ_Mb_ammie-ngo-vcu-OZBxxRk-unsplash" src="./Themes/JourneyJunkies/ammie-ngo-vcu-OZBxxRk-unsplash.png" srcset="./Themes/JourneyJunkies/ammie-ngo-vcu-OZBxxRk-unsplash.png 1x" />
			
		<img id="JJ_Mb_daniel-burka-facU72FcKBI-unspl" src="./Themes/JourneyJunkies/daniel-burka-facU72FcKBI-unspl.png" srcset="./Themes/JourneyJunkies/daniel-burka-facU72FcKBI-unspl.png 1x" />
			
		<div id="JJ_Mb_Group_4">
			<svg class="JJ_Mb_Ellipse_2">
				<ellipse id="JJ_Mb_Ellipse_2" rx="19" ry="19" cx="19" cy="19">
				</ellipse>
			</svg>
			<div id="JJ_Mb_Icon_feather-arrow-down-right">
				<svg class="JJ_Mb_Path_2_cw" viewBox="0 0 5 5">
					<path id="JJ_Mb_Path_2_cw" d="M 5 5.900714419577839e-15 L 5 5 L 5.900714419577839e-15 5">
					</path>
				</svg>
			</div>
		</div>
		<div id="JJ_Mb_Group_3">
			<svg class="JJ_Mb_Rectangle_3">
				<rect id="JJ_Mb_Rectangle_3" rx="19" ry="19" x="0" y="0" width="128" height="38">
				</rect>
			</svg>
			<div id="JJ_Mb_See_all_photos">
				<span>See all photos</span>
			</div>
		</div>
	</div>
	<div id="JJ_Mb_Group_14870">
		<svg class="JJ_Mb_Rectangle_683">
			<rect id="JJ_Mb_Rectangle_683" rx="0" ry="0" x="0" y="0" width="305" height="438">
			</rect>
		</svg>
		<img id="JJ_Mb_pietro-de-grandi-T7K4aEPoGGk-u" src="./Themes/JourneyJunkies/pietro-de-grandi-T7K4aEPoGGk-u.png" srcset="./Themes/JourneyJunkies/pietro-de-grandi-T7K4aEPoGGk-u.png 1x" />
			
		<div id="JJ_Mb_VISION">
			<span>VISION</span>
		</div>
		<div id="JJ_Mb_Lorem_ipsum_dolor_sit_amet_con_c">
			<span>{props.data.vision}</span>
		</div>
	</div>
	<div id="JJ_Mb_Group_14877">
		<svg class="JJ_Mb_Rectangle_683_c">
			<rect id="JJ_Mb_Rectangle_683_c" rx="0" ry="0" x="0" y="0" width="305" height="438">
			</rect>
		</svg>
		<img id="JJ_Mb_jake-blucker-tMzCrBkM99Y-unspl" src="./Themes/JourneyJunkies/jake-blucker-tMzCrBkM99Y-unspl.png" srcset="./Themes/JourneyJunkies/jake-blucker-tMzCrBkM99Y-unspl.png 1x" />
			
		<div id="JJ_Mb_MISSION">
			<span>MISSION</span>
		</div>
		<div id="JJ_Mb_Lorem_ipsum_dolor_sit_amet_con_da">
			<span>{props.data.mission}</span>
		</div>
	</div>
	<div id="JJ_Mb_Group_14878">
		<svg class="JJ_Mb_Rectangle_683_db">
			<rect id="JJ_Mb_Rectangle_683_db" rx="0" ry="0" x="0" y="0" width="305" height="438">
			</rect>
		</svg>
		<img id="JJ_Mb_tom-barrett-M0AWNxnLaMw-unspla" src="./Themes/JourneyJunkies/tom-barrett-M0AWNxnLaMw-unspla.png" srcset="./Themes/JourneyJunkies/tom-barrett-M0AWNxnLaMw-unspla.png 1x" />
			
		<div id="JJ_Mb_PRINCIPLES">
			<span>PRINCIPLES</span>
		</div>
		<div id="JJ_Mb_Lorem_ipsum_dolor_sit_amet_con_de">
			<span>{props.data.principles}</span>
		</div>
	</div>
	<div id="JJ_Mb_FIND_POPULAR__DESTINATION_">
		<span>FIND POPULAR </span><br/><span style={{color:"rgba(255,52,52,1)"}}>DESTINATION</span><span> !!</span>
	</div>

	<div id="JJ_Mb_Group_14871">
		<img id="JJ_Mb_taneli-lahtinen-2gJJrH4OnNk-un" src="./Themes/JourneyJunkies/taneli-lahtinen-2gJJrH4OnNk-un.png" srcset="./Themes/JourneyJunkies/taneli-lahtinen-2gJJrH4OnNk-un.png 1x" />
			
		<div id="JJ_Mb_Scroll_Group_30">
		{props.offeringsData.map((rec,idx)=>(
			<div id="JJ_Mb_Group_14872">
				<svg class="JJ_Mb_Rectangle_688">
					<rect id="JJ_Mb_Rectangle_688" rx="10" ry="10" x="0" y="0" width="198" height="278">
					</rect>
				</svg>
				<img id="JJ_Mb_dariusz-sankowski-3OiYMgDKJ6k-" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"./Themes/JourneyJunkies/dariusz-sankowski-3OiYMgDKJ6k-.png"} />
					
				<div id="JJ_Mb_Travel_One">
					<span>{rec.offeringTitle}</span>
				</div>
			</div>
			))}
		</div>
	</div>
	



	<div id="JJ_Mb_Group_14879">
		<div id="JJ_Mb_n_234567890_mobile_number_shad">
			<span>{props.data.businessPhone}</span>
		</div>
		<div id="JJ_Mb_affanghanioperatelive_email_sh">
			<span>{props.data.businessEmail}</span>
		</div>
		<div id="JJ_Mb_address_med_shadow_space_websi">
			<span>{props.data.businessAddress}</span>
		</div>
		<img id="JJ_Mb_location_img_shadow_space_webs" src="./Themes/JourneyJunkies/location_img_shadow_space_webs.png" srcset="./Themes/JourneyJunkies/location_img_shadow_space_webs.png 1x" />
			
		<img id="JJ_Mb_email_address_shadow_space_web" src="./Themes/JourneyJunkies/email_address_shadow_space_web.png" srcset="./Themes/JourneyJunkies/email_address_shadow_space_web.png 1x" />
			
		<img id="JJ_Mb_phone_number_shadow_space_webs" src="./Themes/JourneyJunkies/phone_number_shadow_space_webs.png" srcset="./Themes/JourneyJunkies/phone_number_shadow_space_webs.png 1x" />
			
	</div>
	<div id="JJ_Mb_Group_14873">
		<img id="JJ_Mb_kisspng-billboard-advertising-" src="./Themes/JourneyJunkies/kisspng-billboard-advertising-.png" srcset="./Themes/JourneyJunkies/kisspng-billboard-advertising-.png 1x" />
			
		<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="JJ_Mb_Rectangle_689">
			<rect id="JJ_Mb_Rectangle_689" rx="0" ry="0" x="0" y="0" width="329" height="153">
			</rect>
		</iframe>
	</div>
	<svg class="JJ_Mb_Line_28" viewBox="0 0 340 2">
		<path id="JJ_Mb_Line_28" d="M 0 0 L 340 0">
		</path>
	</svg>
	<div id="JJ_Mb_n_Copyright_Operate_Live_OPC_P">
		<span>© Copyright Operate Live (OPC) Private Limited. <br/>All Rights Reserved</span>
	</div>
	<div id="JJ_Mb_socials_grp_shadow_space_websi">
    {props.data.linkedInUrl?
		<div title="LinkedIn" id="JJ_Mb_linked_in_grp_shadow_space_web">
			<svg class="JJ_Mb_med_shadow_space_website_7">
				<ellipse id="JJ_Mb_med_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
				</ellipse>
			</svg>
			<div id="JJ_Mb_linked_in_shadow_space_website">
				<a href={props.data.linkedInUrl} target="_blank">
				<svg class="JJ_Mb_n_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
					<path id="JJ_Mb_n_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
					</path>
				</svg>
			</a>
				<svg class="JJ_Mb_linked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
					<path id="JJ_Mb_linked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
					</path>
				</svg>
				<svg class="JJ_Mb_top_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
					<path id="JJ_Mb_top_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
					</path>
				</svg>
			</div>
		</div>:""}
        
        {props.data.instagramUrl?	
		<div title="Instagram" id="JJ_Mb_Insta_grp_shadow_space_website">
			<svg class="JJ_Mb_shadow_space_website_7">
				<ellipse id="JJ_Mb_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
				</ellipse>
			</svg>
			<a href={props.data.instagramUrl} target="_blank">
			<img id="JJ_Mb_instagramshadow_space_website_" src="./Themes/JourneyJunkies/instagramshadow_space_website_.png" srcset="./Themes/JourneyJunkies/instagramshadow_space_website_.png 1x" />
				
			</a>
		</div>:""}
		{props.data.whatsAppNumber?
		<div title="WhatsApp" id="JJ_Mb_whatsapp_grp_shadow_space_webs">
			<svg class="JJ_Mb_med_shadow_space_website_7_eb">
				<ellipse id="JJ_Mb_med_shadow_space_website_7_eb" rx="24" ry="24" cx="24" cy="24">
				</ellipse>
			</svg>
			<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
			<img id="JJ_Mb_whatsapp_lbl_shadow_space_webs" src="./Themes/JourneyJunkies/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/JourneyJunkies/whatsapp_lbl_shadow_space_webs.png 1x" />
				</a>
		</div>:""}
	</div>
</div>
  )
}

export default JourneyJunkiesMob
