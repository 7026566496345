import React,{useState,useMemo, useEffect} from 'react'
import { makeStyles } from '@material-ui/styles';
import './App.css'
import { UserContext } from "./Components/UserContext";
import { BrowserRouter as Router, Route, Routes,useLocation} from "react-router-dom";
import LoginUI from './Components/LoginUI';
import LoginMobUI from './Components/LoginMobUI';
import SideNavBarMobUI from './Components/SideNavBarMobUI';
import SideNavBarIconUI from './Components/SideNavBarIconUI';
import SideNavBarPCUI from './Components/SideNavBarPCUI';
import TopNavBarMobUI from './Components/TopNavBarMobUI';
import TopNavBarUI from './Components/TopNavBarUI';
import BottomNavBarMobUI from './Components/BottomNavBarMobUI';
import MobRoutes from './MobRoutes';
import PCRoutes from './PCRoutes';
import MobThemeRoutes from './MobThemeRoutes';
import PCThemeRoutes from './PCThemeRoutes';
import SignUpUI from './Components/SignUpUI';
import ForgotPasswordUI from './Components/ForgotPasswordUI';
import SignUpMobUI from './Components/SignUpMobUI';
import ForgotPasswordMobUI from './Components/ForgotPasswordMobUI';
import ChangePassword from './Components/ChangePassword';


const useStyles = makeStyles((theme) => ({
    appBar: {
      borderRadius: 15,
      margin: '30px 100px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '600px',
      border: '2px solid black',
      "@media (max-width: 1440px)": {
        mobileMenuStyle: {
            width: '90%',
         },
        }
    },
    // image: {
    //   marginLeft: '15px',
    // },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      width: '95%',
      marginTop: "15px"
    },
  }));


const App = () => {
  const classes = useStyles();
  // const userlocation = useLocation();
  const [user, setUser] = useState(null);
  const [sideNavBar,setSideNavBar] = useState(false);
  const [pageName,setPageName] = useState(false);
  const [webData,setWebData] = useState({});
  const [offeringsData,setOfferingsData] = useState([])
  const [partnersData,setPartnersData] = useState([])
  const [eventsData,setEventsData] = useState([])

  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [
      user,
      setUser,
    ]
    );

    
useEffect(()=>{
  let storedUser = localStorage.getItem("loggedInUser");
  if(storedUser){
    setUser(JSON.parse(storedUser));
  }
  else
  {
    // getUserProfile(window.location.hostname.split('.').slice(0, -2).join('.'))
    getUserProfile(window.location.hostname.replace(".operate.live",""))
  }
},[]);



const getUserProfile = (nickName) => {
    let apiURL = process.env.REACT_APP_API_URL + "/public/getprofile/"+nickName;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
         //  console.log(repos.data);
         setWebData(repos.data.profile);   
         setOfferingsData(repos.data.offerings)
         setPartnersData(repos.data.partners)
         setEventsData(repos.data.events)
        //   setAddress(repos.data.address);
        //   setDob(repos.data.dob.substring(0,10));
        //   setEmail(repos.data.email);
         //  setName(repos.data.name);
        //   setGender(repos.data.gender);
        //   setMobile(repos.data.phone);
        //   setCountry(repos.data.country);
        //   setState(repos.data.state);
        //   setCity(repos.data.city);
        //   setPincode(repos.data.pincode);
        //   setProfession(repos.data.profession);
          // setIntroduction(repos.data.introduction);
        //   setImg(arrayBufferToBase64(repos.data.image.data.data));
        //   setCurrency(repos.data.currency);
        //   setHourlyRate(repos.data.hourlyRate);
        //   setUpiId(repos.data.upiId);
         //  setVision(repos.data.vision);
         //  setMission(repos.data.mission);
          // setPrinciples(repos.data.principles);
          
        } 
        // else alert("Invalid Url");
      })
      .catch((err) => alert(err));
  };

  return (
    <>
    
    <UserContext.Provider value={value}>
    <Router>
    {user?pageName===true?(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<SideNavBarMobUI goto={(e)=>setPageName(e)} />:<><TopNavBarUI /><SideNavBarPCUI goto={(e)=>setPageName(e)} /></>:(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<><TopNavBarMobUI goto={(e)=>setPageName(e)}/><BottomNavBarMobUI /></>:<><TopNavBarUI /><SideNavBarIconUI goto={(e)=>setPageName(e)}/></>:""}
   
    {
      (window.location.hostname.split('.').slice(0, -2).join('.').length > 0 && window.location.hostname.split('.').slice(0, -2).join('.') !== "sn" && window.location.hostname !== "localhost") ? 
      (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<MobThemeRoutes webData={webData} offeringsData={offeringsData} partnersData={partnersData}  eventsData={eventsData}/>:<PCThemeRoutes webData={webData} offeringsData={offeringsData} partnersData={partnersData} eventsData={eventsData}/>
:
    user?(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<MobRoutes/>:<PCRoutes/>:
      <Routes>
      <Route exact path="/signup" element={(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<SignUpMobUI/>:<SignUpUI/>}/>
    <Route path="/sendmail" element={(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<ForgotPasswordMobUI/>:<ForgotPasswordUI/>}/>
    <Route path="/ChangePassword" element={(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<ChangePassword/>:<ChangePassword/>}/>

      <Route
            path="*"
            element={ (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<LoginMobUI/>:<LoginUI/>}
          />
      </Routes>
      }
    </Router>
 
</UserContext.Provider>  
    </>
  )
}

export default App