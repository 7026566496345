import React, { useEffect, useState, useContext} from 'react'
import "../../styles/CalanderEventsMob.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";

const CalanderEventsMob = (props) => {

	
	useEffect(()=>{

	},[props.data])


  return (
    <div id="calender_page_event_mob">
	<div id="calender_page_event_mob_grp">
		<svg class="bdr_calender_page_event_mob">
			<rect id="bdr_calender_page_event_mob" rx="22" ry="22" x="0" y="0" width="100%" height="799">
			</rect>
		</svg>
		<div id="menu_right_part_evnt_mob">
			<div id="Alarms_lbl_calender_mob" onClick={()=>props.selectedTab("alarms")}>
				<span>Alarms</span>
			</div>
			<div id="Tasks_lbl_mob" onClick={()=>props.selectedTab("tasks")}>
				<span>Tasks</span>
			</div>
			<div id="Meetings_lbl_headingcalender_m" onClick={()=>props.selectedTab("meetings")}>
				<span>Meetings</span>
			</div>
			<div id="events_grp_calender_page_event">
				<div id="Events_lbl_calendermob">
					<span>Events</span>
				</div>
				<svg class="Line_29event_mob" viewBox="0 0 1 40">
					<path id="Line_29event_mob" d="M 0 0 L 0 40">
					</path>
				</svg>
			</div>
		</div>
		<div id="calender_page_event_mob_scroll">
		{props.data.map((eve)=>(
			<div id="events_page_calender">
				<div id="Dragon_Ball_Super_lbl_mob">
					<span>{eve.title}</span>
				</div>
				<div id="KAMEHAMEHA_123">
					<span>{eve.startsOn && new Date(eve.startsOn).getHours().toString().padStart(2,"0")+":"+new Date(eve.startsOn).getMinutes().toString().padStart(2,"0")} - {eve.endsOn && new Date(eve.endsOn).getHours().toString().padStart(2,"0")+":"+new Date(eve.endsOn).getMinutes().toString().padStart(2,"0")} IST</span>
				</div>
				<svg class="dragon_ball_sup_">
					<rect id="dragon_ball_sup_" rx="0" ry="0" x="0" y="0" width="3.36" height="16.8">
					</rect>
				</svg>
				<svg class="active_events_calender_mob">
					<ellipse id="active_events_calender_mob" rx="5.599999904632568" ry="5.599999904632568" cx="5.599999904632568" cy="5.599999904632568">
					</ellipse>
				</svg>
				<svg class="arcane_eventline_calender_mob" viewBox="0 0 249.76 1">
					<path id="arcane_eventline_calender_mob" d="M 0 0 L 249.7599945068359 0">
					</path>
				</svg>
			</div>
			))
		}	
		</div>
	</div>
</div>
  )
}

export default CalanderEventsMob
