import React, { useContext } from 'react'
import {Button} from "@mui/material";
import { SocketContext } from '../SocketContext';
import '../styles/NotificationsUI.css';

const NotificationsUI = () => {

    const {answerCall,rejectCall,call,callAccepted,callRejected,leaveCall,me,
      setName,} = useContext(SocketContext);

  return (
    <div>
   {call.isRecievedCall && !(callAccepted || callRejected) && (   
  <div id="pop_caller">
  
	<img id="bg_design_callpop" src="bg_design_callpop.png" srcset="bg_design_callpop.png 1x, bg_design_callpop@2x.png 2x" />
		
	<div id="lbl_name_callpop">
		<span>{call.name} is calling : </span>
	</div>
	<button id="btn_grp_popcall" onClick={answerCall}>
		<svg class="rec_accept_popcall">
			<rect id="rec_accept_popcall" rx="10" ry="10" x="0" y="0" width="53" height="25">
			</rect>
		</svg>
		<img id="accept_call_popcall" src="accept_call_popcall.png" srcset="accept_call_popcall.png 1x, accept_call_popcall@2x.png 2x" />
			
	</button>
	<button id="btn_grp_decline_popcall" onClick={rejectCall}>
		<svg class="decline_rec_popcall">
			<rect id="decline_rec_popcall" rx="10" ry="10" x="0" y="0" width="53" height="25">
			</rect>
		</svg>
		<img id="decline_img_popcall" src="decline_img_popcall.png" srcset="decline_img_popcall.png 1x, decline_img_popcall@2x.png 2x" />
			
	</button>
  </div>
        ) }

   { callRejected && (
        <div id="pop_caller">
	<img id="bg_design_callpop" src="bg_design_callpop.png" srcset="bg_design_callpop.png 1x, bg_design_callpop@2x.png 2x" />
		
	<div id="lbl_name_callpop">
		<span>Call has been rejected!!! </span>
	</div>
  </div>
  )}
    </div>
  )
}

export default NotificationsUI