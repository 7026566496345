import React from 'react';
import "../../../styles/VerdantHarvestMob.css";

function VerdantHarvestMob(props) {
  return (
    <div id="VerdantHarvestGrove_Mob">
        <img id="VHG_MOB_29mk-s-tHHFiw6GNEU-unsplash" src="./Themes/VerdantHarvest/mk-s-tHHFiw6GNEU-unsplash.png" srcset="./Themes/VerdantHarvest/mk-s-tHHFiw6GNEU-unsplash.png 1x" />
            
        <svg class="VHG_MOB_29Rectangle_748">
            <rect id="VHG_MOB_29Rectangle_748" rx="0" ry="0" x="0" y="0" width="390" height="663">
            </rect>
        </svg>
        <img id="VHG_MOB_29amjd-rdwan-CEAPSFlMLbk-unsplas" src="./Themes/VerdantHarvest/amjd-rdwan-CEAPSFlMLbk-unsplas.png" srcset="./Themes/VerdantHarvest/amjd-rdwan-CEAPSFlMLbk-unsplas.png 1x" />
            
        <img id="VHG_MOB_29Anime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/VerdantHarvest/Anime_Edit.png"} />
            
        <div id="VHG_MOB_29Your_Business_Name">
            <span>{props.data.businessName}</span>
        </div>
        <div id="VHG_MOB_29Cultivating_Wellness_from_Farm">
            <span>"Cultivating Wellness from Farm to Table: <br/>Discover the Freshest Flavors and <br/>Nutrient-Rich Delights in Every Veggie<br/>Bite!"</span>
        </div>
        <div id="VHG_MOB_29Group_15113">
            <svg class="VHG_MOB_29Rectangle_3">
                <rect id="VHG_MOB_29Rectangle_3" rx="24.5" ry="24.5" x="0" y="0" width="177" height="49">
                </rect>
            </svg>
            <div id="VHG_MOB_29Order_Now">
                <span>Order Now</span>
            </div>
        </div>
        <div id="VHG_MOB_29Group_15123">
            <div id="VHG_MOB_29Group_15114">
                <img id="VHG_MOB_29tandem-x-visuals-dqZ0JREudKw-u" src="./Themes/VerdantHarvest/tandem-x-visuals-dqZ0JREudKw-u.png" srcset="./Themes/VerdantHarvest/tandem-x-visuals-dqZ0JREudKw-u.png 1x" />
                    
                <div id="VHG_MOB_29Scroll_Group_28">
                    <div id="VHG_MOB_29It_is_a_long_established_fact_">
                        <span>{props.data.vision}</span>
                    </div>
                </div>
                <div id="VHG_MOB_29VISION">
                    <span>VISION</span>
                </div>
            </div>
            <div id="VHG_MOB_29Group_15120">
                <img id="VHG_MOB_29mk-s-7GeBVqFwOl4-unsplash" src="./Themes/VerdantHarvest/mk-s-7GeBVqFwOl4-unsplash.png" srcset="./Themes/VerdantHarvest/mk-s-7GeBVqFwOl4-unsplash.png 1x" />
                    
                <div id="VHG_MOB_29Scroll_Group_28_">
                    <div id="VHG_MOB_29It_is_a_long_established_fact__">
                        <span>{props.data.mission}</span>
                    </div>
                </div>
                <div id="VHG_MOB_29MISSION">
                    <span>MISSION</span>
                </div>
            </div>
            <div id="VHG_MOB_29Group_15121">
                <img id="VHG_MOB_29ricardo-gomez-angel-I5XthC605H" src="./Themes/VerdantHarvest/ricardo-gomez-angel-I5XthC605H.png" srcset="./Themes/VerdantHarvest/ricardo-gomez-angel-I5XthC605H.png 1x" />
                    
                <div id="VHG_MOB_29Scroll_Group_28_bd">
                    <div id="VHG_MOB_29It_is_a_long_established_fact__be">
                        <span>{props.data.principles}</span>
                    </div>
                </div>
                <div id="VHG_MOB_29PRINCIPLES">
                    <span>PRINCIPLES</span>
                </div>
            </div>
        </div>
        <div id="VHG_MOB_29Scroll_Group_77">
	{props.offeringsData.map((off,idx)=>(
            <div id="VHG_MOB_29n_">
                <img id="VHG_MOB_29Mask_Group_22" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/VerdantHarvest/Mask_Group_22.png"} />
                    
                <svg class="VHG_MOB_29Rectangle_664">
                    <rect id="VHG_MOB_29Rectangle_664" rx="0" ry="0" x="0" y="0" width="247" height="46">
                    </rect>
                </svg>
                <div id="VHG_MOB_29Something">
                    <span>{off.offeringTitle}</span>
                </div>
                <div id="VHG_MOB_29Group_10773">
                    <svg class="VHG_MOB_29Ellipse_38">
                        <ellipse id="VHG_MOB_29Ellipse_38" rx="2.5" ry="2.5" cx="2.5" cy="2.5">
                        </ellipse>
                    </svg>
                    <svg class="VHG_MOB_29Ellipse_39">
                        <ellipse id="VHG_MOB_29Ellipse_39" rx="3" ry="2.5" cx="3" cy="2.5">
                        </ellipse>
                    </svg>
                    <svg class="VHG_MOB_29Ellipse_40">
                        <ellipse id="VHG_MOB_29Ellipse_40" rx="3" ry="2.5" cx="3" cy="2.5">
                        </ellipse>
                    </svg>
                    <svg class="VHG_MOB_29Ellipse_41">
                        <ellipse id="VHG_MOB_29Ellipse_41" rx="3" ry="2.5" cx="3" cy="2.5">
                        </ellipse>
                    </svg>
                    <svg class="VHG_MOB_29Ellipse_42">
                        <ellipse id="VHG_MOB_29Ellipse_42" rx="3" ry="2.5" cx="3" cy="2.5">
                        </ellipse>
                    </svg>
                </div>
            </div>))}
        </div>
        <div id="VHG_MOB_29PRINCIPLES_br">
            <span>Avaliable Now</span>
        </div>
        <div id="VHG_MOB_29Group_15122">
            <div id="VHG_MOB_29Group_15040">
                <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="VHG_MOB_29Rectangle_737">
                    <rect id="VHG_MOB_29Rectangle_737" rx="0" ry="0" x="0" y="0" width="281" height="158">
                    </rect>
                </iframe>
            </div>
            <div id="VHG_MOB_29Group_15050">
                <div id="VHG_MOB_29socials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
                    <div title="LinkedIn" id="VHG_MOB_29linked_in_grp_shadow_space_web">
                        <svg class="VHG_MOB_29med_shadow_space_website_7">
                            <ellipse id="VHG_MOB_29med_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
                        <div id="VHG_MOB_29linked_in_shadow_space_website">
					<a href={props.data.linkedInUrl} target="_blank">
                            <svg class="VHG_MOB_29n_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
                                <path id="VHG_MOB_29n_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
                                </path>
                            </svg>
                        </a>
                            <svg class="VHG_MOB_29linked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
                                <path id="VHG_MOB_29linked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
                                </path>
                            </svg>
                            <svg class="VHG_MOB_29top_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
                                <path id="VHG_MOB_29top_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
                                </path>
                            </svg>
                        </div>
                    </div>:""}
			{props.data.instagramUrl?	
                    <div title="Instagram" id="VHG_MOB_29Insta_grp_shadow_space_website">
                        <svg class="VHG_MOB_29shadow_space_website_7">
                            <ellipse id="VHG_MOB_29shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
				<a href={props.data.instagramUrl} target="_blank">
                        <img id="VHG_MOB_29instagramshadow_space_website_" src="./Themes/VerdantHarvest/instagramshadow_space_website_.png" srcset="./Themes/VerdantHarvest/instagramshadow_space_website_.png 1x" />
                            
                        </a>
                    </div>:""}
			{props.data.whatsAppNumber?
                    <div title="WhatsApp" id="VHG_MOB_29whatsapp_grp_shadow_space_webs">
                        <svg class="VHG_MOB_29med_shadow_space_website_7_b">
                            <ellipse id="VHG_MOB_29med_shadow_space_website_7_b" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
                        <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				 <img id="VHG_MOB_29whatsapp_lbl_shadow_space_webs" src="./Themes/VerdantHarvest/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/VerdantHarvest/whatsapp_lbl_shadow_space_webs.png 1x" />
                            </a>
                    </div>:""}
                </div>
                <div id="VHG_MOB_29n_234567890_mobile_number_shad">
                    <span>{props.data.businessPhone}</span>
                </div>
                <div id="VHG_MOB_29affanghanioperatelive_email_sh">
                    <span>{props.data.businessEmail}</span>
                </div>
                <div id="VHG_MOB_29address_med_shadow_space_websi">
                    <span>{props.data.businessAddress}</span>
                </div>
                <img id="VHG_MOB_29location_img_shadow_space_webs" src="./Themes/VerdantHarvest/location_img_shadow_space_webs.png" srcset="./Themes/VerdantHarvest/location_img_shadow_space_webs.png 1x" />
                    
                <img id="VHG_MOB_29email_address_shadow_space_web" src="./Themes/VerdantHarvest/email_address_shadow_space_web.png" srcset="./Themes/VerdantHarvest/email_address_shadow_space_web.png 1x" />
                    
                <img id="VHG_MOB_29phone_number_shadow_space_webs" src="./Themes/VerdantHarvest/phone_number_shadow_space_webs.png" srcset="./Themes/VerdantHarvest/phone_number_shadow_space_webs.png 1x" />
                    
                <div id="VHG_MOB_29n_Copyright_Operate_Live_OPC_P">
                    <span>© Copyright Operate Live (OPC) Private Limited.<br/> All Rights Reserved</span>
                </div>
            </div>
            <div id="VHG_MOB_29Contact_Us">
                <span>Contact Us!</span>
            </div>
        </div>
    </div>
  )
}

export default VerdantHarvestMob