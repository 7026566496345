import React from 'react';
import "../../../styles/ChaatsParadiceMob.css";

function ChaatsParadiceMob(props) {
  return (
    <div id="ChaatsParadiseMob">
        <img id="CHAAT_22_MOBpexels-vinit-jain-16171917" src="./Themes/ChaatsParadice/pexels-vinit-jain-16171917.png" srcset="./Themes/ChaatsParadice/pexels-vinit-jain-16171917.png 1x" />
            
        <div id="CHAAT_22_MOBGroup_107">
            <svg class="CHAAT_22_MOBRectangle_78">
                <rect id="CHAAT_22_MOBRectangle_78" rx="0" ry="0" x="0" y="0" width="320" height="500">
                </rect>
            </svg>
            <div id="CHAAT_22_MOBTaste_the_Best_Right_Now">
                <span>Taste the Best Right Now!</span>
            </div>
            <div id="CHAAT_22_MOBAny_succesfull_career_starts__">
                <span>Any succesfull career starts <br/><br/>with good education</span>
            </div>
            <img id="CHAAT_22_MOBlogo" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/ChaatsParadice/logo.png"} />
                
            <div id="CHAAT_22_MOBName">
                <span>{props.data.businessName}</span>
            </div>
        </div>
        <div id="CHAAT_22_MOBGroup_15066">
            <svg class="CHAAT_22_MOBRectangle_743">
                <rect id="CHAAT_22_MOBRectangle_743" rx="0" ry="0" x="0" y="0" width="320" height="2062">
                </rect>
            </svg>
            <div id="CHAAT_22_MOBCard_01_website_14_web_pc">
                <div id="CHAAT_22_MOBScroll_Group_10">
                    <div id="CHAAT_22_MOBintro_w14_web_pc">
                        <span>{props.data.vision}</span>
                    </div>
                </div>
                <div id="CHAAT_22_MOBVision_14_web_pc">
                    <span>Vision</span>
                </div>
                <img id="CHAAT_22_MOBaarn-giri-3lGi014_web_pc" src="./Themes/ChaatsParadice/aarn-giri-3lGi014_web_pc.png" srcset="./Themes/ChaatsParadice/aarn-giri-3lGi014_web_pc.png 1x" />
                    
            </div>
            <div id="CHAAT_22_MOBCard_01_website_14_web_pc_z">
                <div id="CHAAT_22_MOBScroll_Group_10_">
                    <div id="CHAAT_22_MOBintro_w14_web_pc_">
                        <span>{props.data.mission}</span>
                    </div>
                </div>
                <div id="CHAAT_22_MOBVision_14_web_pc_">
                    <span>Mission</span>
                </div>
                <img id="CHAAT_22_MOBaarn-giri-3lGi014_web_pc_" src="./Themes/ChaatsParadice/aarn-giri-3lGi014_web_pc_.png" srcset="./Themes/ChaatsParadice/aarn-giri-3lGi014_web_pc_.png 1x" />
                    
            </div>
            <div id="CHAAT_22_MOBCard_01_website_14_web_pc_">
                <div id="CHAAT_22_MOBScroll_Group_10_ba">
                    <div id="CHAAT_22_MOBintro_w14_web_pc_ba">
                        <span>{props.data.principles}</span>
                    </div>
                </div>
                <div id="CHAAT_22_MOBVision_14_web_pc_ba">
                    <span>Principles</span>
                </div>
                <img id="CHAAT_22_MOBaarn-giri-3lGi014_web_pc_ba" src="./Themes/ChaatsParadice/aarn-giri-3lGi014_web_pc_ba.png" srcset="./Themes/ChaatsParadice/aarn-giri-3lGi014_web_pc_ba.png 1x" />
                    
            </div>
        </div>
        <div id="CHAAT_22_MOBOur_Chaats">
            <span>Our Chaats</span>
        </div>
        <div id="CHAAT_22_MOBScroll_Group_78">
	{props.offeringsData.map((off,idx)=>(
            <div id="CHAAT_22_MOBGroup_14872">
                <svg class="CHAAT_22_MOBRectangle_688">
                    <rect id="CHAAT_22_MOBRectangle_688" rx="10" ry="10" x="0" y="0" width="213" height="317">
                    </rect>
                </svg>
                <img id="CHAAT_22_MOBpexels-shahbaz-ansari-13063300" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/ChaatsParadice/pexels-shahbaz-ansari-13063300.png"} />
                    
                <div id="CHAAT_22_MOBPani_Puri">
                    <span>{off.offeringTitle}</span>
                </div>
            </div>))}
        </div>
        <svg class="CHAAT_22_MOBLine_27" viewBox="0 0 311 1">
            <path id="CHAAT_22_MOBLine_27" d="M 0 0 L 311 0">
            </path>
        </svg>
        <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="CHAAT_22_MOBRectangle_744">
            <rect id="CHAAT_22_MOBRectangle_744" rx="0" ry="0" x="0" y="0" width="266" height="207">
            </rect>
        </iframe>
        <div id="CHAAT_22_MOBGroup_15065">
            <div id="CHAAT_22_MOBsocials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
                <div title="LinkedIn" id="CHAAT_22_MOBlinked_in_grp_shadow_space_web">
                    <svg class="CHAAT_22_MOBmed_shadow_space_website_7">
                        <ellipse id="CHAAT_22_MOBmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                        </ellipse>
                    </svg>
                    <div id="CHAAT_22_MOBlinked_in_shadow_space_website">
				<a href={props.data.linkedInUrl} target="_blank">
                        <svg class="CHAAT_22_MOBn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
                            <path id="CHAAT_22_MOBn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
                            </path>
                        </svg>
                    </a>
                        <svg class="CHAAT_22_MOBlinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
                            <path id="CHAAT_22_MOBlinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
                            </path>
                        </svg>
                        <svg class="CHAAT_22_MOBtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
                            <path id="CHAAT_22_MOBtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
                            </path>
                        </svg>
                    </div>
                </div>:""}
			{props.data.instagramUrl?	
                <div title="Instagram" id="CHAAT_22_MOBInsta_grp_shadow_space_website">
                    <svg class="CHAAT_22_MOBshadow_space_website_7">
                        <ellipse id="CHAAT_22_MOBshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                        </ellipse>
                    </svg>
				<a href={props.data.instagramUrl} target="_blank">
                    <img id="CHAAT_22_MOBinstagramshadow_space_website_" src="./Themes/ChaatsParadice/instagramshadow_space_website_.png" srcset="./Themes/ChaatsParadice/instagramshadow_space_website_.png 1x" />
                        
                    </a>
                </div>:""}
			{props.data.whatsAppNumber?
                <div title="WhatsApp" id="CHAAT_22_MOBwhatsapp_grp_shadow_space_webs">
                    <svg class="CHAAT_22_MOBmed_shadow_space_website_7_bt">
                        <ellipse id="CHAAT_22_MOBmed_shadow_space_website_7_bt" rx="24" ry="24" cx="24" cy="24">
                        </ellipse>
                    </svg>
                    <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="CHAAT_22_MOBwhatsapp_lbl_shadow_space_webs" src="./Themes/ChaatsParadice/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/ChaatsParadice/whatsapp_lbl_shadow_space_webs.png 1x" />
                        </a>
                </div>:""}
            </div>
            <div id="CHAAT_22_MOBn_234567890_mobile_number_shad">
                <span>{props.data.businessPhone}</span>
            </div>
            <div id="CHAAT_22_MOBaffanghanioperatelive_email_sh">
                <span>{props.data.businessEmail}</span>
            </div>
            <div id="CHAAT_22_MOBaddress_med_shadow_space_websi">
                <span>{props.data.businessAddress}</span>
            </div>
            <img id="CHAAT_22_MOBlocation_img_shadow_space_webs" src="./Themes/ChaatsParadice/location_img_shadow_space_webs.png" srcset="./Themes/ChaatsParadice/location_img_shadow_space_webs.png 1x" />
                
            <img id="CHAAT_22_MOBemail_address_shadow_space_web" src="./Themes/ChaatsParadice/email_address_shadow_space_web.png" srcset="./Themes/ChaatsParadice/email_address_shadow_space_web.png 1x" />
                
            <img id="CHAAT_22_MOBphone_number_shadow_space_webs" src="./Themes/ChaatsParadice/phone_number_shadow_space_webs.png" srcset="./Themes/ChaatsParadice/phone_number_shadow_space_webs.png 1x" />
                
            <div id="CHAAT_22_MOBn_Copyright_Operate_Live_OPC_P">
                <span>© Copyright Operate Live (OPC) Private Limited. <br/>All Rights Reserved</span>
            </div>
        </div>
    </div>
  )
}

export default ChaatsParadiceMob