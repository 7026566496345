import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioSmallReqCardMob.css';
import { UserContext } from '../UserContext';


const MyPortfolioSmallReqCardMob = (props) => {
	
	const {user} = useContext(UserContext);
	 
	const handleLike = (reqId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/requirement/updatetrophy/"+reqId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
		  };
	
  return (
   
	<div id="REQM_small_4_trending_com_off">
				<svg class="REQM_all_bdr_small_4_trending_com">
					<rect id="REQM_all_bdr_small_4_trending_com" rx="10" ry="10" x="0" y="0" width="160" height="197">
					</rect>
				</svg>
				<img id="REQM_Image_small_4_trending_com" src={props.data.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultRequirementsImageUrl.split('/')[5]):"./MyPortfolio/Requirements/Image_small_4_trending_off_PC.png"} />
					
				<svg class="REQM_bdr_amount_small_4_trending_co">
					<rect id="REQM_bdr_amount_small_4_trending_co" rx="10.5" ry="10.5" x="0" y="0" width="39" height="21">
					</rect>
				</svg>
				<div id="REQM_small_4_trending_com">
					<span>{props.data.currency && JSON.parse(props.data.currency).symbol} {props.data.budget}</span>
				</div>
				<div id="REQM_NikeCrt_Air_small_4_trending_c">
					<span>{props.data.requirementTitle}</span>
				</div>
				<div id="REQM_Sinr_small_4_trending_com_off">
					<span>{( props.data.deliveryDeadline ? (props.data.deliveryDeadline).substring(0,10):"")} | {props.data.deliveryLocation}</span>
				</div>
				<svg class="REQM_number_small_4_trending_com">
					<rect id="REQM_number_small_4_trending_com" rx="0" ry="0" x="0" y="0" width="24" height="13">
					</rect>
				</svg>
				<svg class="REQM_trohpie_bdr_small_4_tre">
					<ellipse id="REQM_trohpie_bdr_small_4_tre" rx="12" ry="12" cx="12" cy="12">
					</ellipse>
				</svg>
				<img id="REQM_trophy_small_4_trending_c" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(props.data._id)} />
					
				<div id="REQM_small_4_trending_com_off_cz">
					<span>{(JSON.parse(props.data.trophies)).length}</span>
				</div>
				<svg class="REQM_sharebrsmall_4_trending_com_of">
					<ellipse id="REQM_sharebrsmall_4_trending_com_of" rx="12.5" ry="12.5" cx="12.5" cy="12.5">
					</ellipse>
				</svg>
				<img id="REQM_share_2_com_off" src="./MyPortfolio/Requirements/share_2_reqoffevent_off_PC.png" srcset="./MyPortfolio/Requirements/share_2_reqoffevent_off_PC.png 1x" />
					
			</div>      
  )
}

export default MyPortfolioSmallReqCardMob
