import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
 import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../styles/MyReqandComReq.css';
import Switch from '@mui/material/Switch';

import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Currencies from "./Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import OfferingsUI from './OfferingsUI';
import CommunityOfferingsUI from './CommunityOfferingsUI';
import MyRequirementsUI from './MyRequirementsUI';
import CommunityRequirementsUI from './CommunityRequirementsUI';
import CommunityRequirementsMobUI from './MobUI/CommunityRequirementsMobUI';


const MyReqandComReq = () => {   

//UseState
    const [toggle,setToggle] = useState(false);
    const { user, setUser} = useContext(UserContext);


//UseEffect

  return (
    <div>
  <div id="reqandcomreq_pc">
	<img id="bg_design_reqandcomreq" src="cube_green_design.png" srcset="cube_green_design.png 1x, cube_green_design@2x.png 2x" />
		
	<div id="My_Requirements_lbl_1_pc_">
		<span>My Requirements</span>
	</div>
	<div id="Community_Requirements_lbl_1_pc">
		<span>Community Requirements</span>
	</div>
  <svg class="Line_req_border_cr" viewBox="0 0 308 1">
		<path id="Line_req_border_cr" d="M 0 0 L 380 0">
		</path>
	</svg>

  <div class="switch_toggle_req">
	<Switch id='switch_toggle_req' onChange={(e)=>setToggle(e.target.checked)} checked={toggle} color="default" />
	</div>

  {toggle===false ? <MyRequirementsUI/> : <CommunityRequirementsUI />}

  </div>
    </div>
  )
}

export default MyReqandComReq
