import React, { useState, useEffect, useContext } from "react";
import "../../styles/EditBillingTabMob.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import Currencies from "../Currencies.json";


const EditBiilingTabMob = (props) => {

     //useState
     var navigate = useNavigate();
     const { user, setUser} = useContext(UserContext);
     const [upiId,setUpiId] = useState("");
     const [hourlyRate,setHourlyRate] = useState("");
     const [currency, setCurrency] = useState("");
     const [businessName,setBusinessName] = useState("");
     const [businessMobile,setBusinessMobile] = useState("");
     const [businessEmail,setBusinessEmail] = useState("");
     const [businessAddress,setBusinessAddress] = useState("");
     const [businessWebsite,setBusinessWebsite] = useState("");
 
     useEffect(()=>{
        if(user){
            if(props.userData){
         console.log(props.userData)
         setCurrency(props.userData.currency);
         setHourlyRate(props.userData.hourlyRate);
         setUpiId(props.userData.upiId);
         setBusinessName(props.userData.businessName);
          setBusinessMobile(props.userData.businessPhone);
          setBusinessEmail(props.userData.businessEmail);
          setBusinessAddress(props.userData.businessAddress);
          setBusinessWebsite(props.userData.businessWebsite);

            }
        }
    },[props.userData])

    const handleUpdateData = () =>{
      
        props.updateData({
            upiId:upiId,
            hourlyRate:hourlyRate,
            currency:currency,
            businessName:businessName,
            businessMobile:businessMobile,
            businessEmail:businessEmail,
            businessAddress:businessAddress,
            businessWebsite:businessWebsite,
        })
    }

   

  return (
    <div>
    <div id="payment_tab">
	<img id="billing_down_holder_mob_eit_pa" src="billing_down_holder_mob_eit_pa.png" srcset="billing_down_holder_mob_eit_pa.png 1x" />
		
	<img id="billing_top_holder_mob_eit_pag" src="billing_top_holder_mob_eit_pag.png" srcset="billing_top_holder_mob_eit_pag.png 1x" />
		
	<div id="top_grp_heading_payment">
		<div id="Generallbl_profile_mob_payment"  onClick={()=>props.selectedTab("general")}>
			<span>General</span>
		</div>
		<img id="dossier_lbl_profile_mob_paymen" src="dossier_lbl_profile_mob_paymen.png" srcset="dossier_lbl_profile_mob_paymen.png 1x"  onClick={()=>props.selectedTab("general")}/>
			
		<div id="Location_lbl_profile_mob_payme" onClick={()=>props.selectedTab("location")}>
			<span>Location</span>
		</div>
		<img id="placeholder_lbl_profile_mob_pa" src="placeholder_lbl_profile_mob_pa.png" srcset="placeholder_lbl_profile_mob_pa.png 1x" onClick={()=>props.selectedTab("location")}/>
			
		<div id="Payment_Details_lbl_profile_mo">
			<span>Business</span>
		</div>
		<img id="card_lbl_profile_mob_payment" src="card_lbl_profile_mob_payment.png" srcset="card_lbl_profile_mob_payment.png 1x" />
			
	</div>
	<div id="finish_btn_payment_paymet_page" onClick={() => handleUpdateData()}>
		<svg class="finish_bdr_paymet_page_mob">
			<rect id="finish_bdr_paymet_page_mob" rx="8" ry="8" x="0" y="0" width="109" height="36">
			</rect>
		</svg>
		<div id="finish_lbkl_paymet_page_mob">
			<span>Finish</span>
		</div>
	</div>
  
	<div id="Scroll_payment_tab">
		<div id="payment_grp_all">
			<div id="UPI_ID_grp_payment">
				<input class="UPI_input_lbl_profile_paymet_p"  value={upiId}
                              onChange={(e) => {
                                setUpiId(e.target.value);
                              }}/>
				
				<div id="UPI_ID_lbl_profile_paymet_page">
					<span>UPI ID</span>
				</div>
			</div>
			<div id="BC_grp_payment">
				<select class="nilling_currency_input_paymet_" value={currency}
                                // defaultValue={companyName}
                                onChange={(e) => {
                                  setCurrency(e.target.value);
                                }}>
{Currencies &&
                                  Currencies.map((currency) => (
                                    <option value={currency.name}>
                                      {currency.name}
                                    </option>
                                  ))}
        </select>
				
				<div id="Billing_Currency_lbl_profile__">
					<span>Billing Currency</span>
				</div>
			</div>
			<div id="HBR_grp_paymen">
				<input class="HBR_input_lbl_profile_paymet_p" value={hourlyRate}
                              onChange={(e) => {
                                setHourlyRate(e.target.value);
                              }}/>
					
				<div id="Hour_Billing_Rate_lbl_profile_">
					<span>Hour Billing Rate</span>
				</div>
			</div>
		</div>
		<div id="business_grp_details">
			<div id="UPI_ID_grp_payment_cb">
				<input class="UPI_input_lbl_profile_paymet_p_cc" value={businessName}
                              onChange={(e) => {
                                setBusinessName(e.target.value);
                              }}/>
					
				<div id="UPI_ID_lbl_profile_paymet_page_cd">
					<span>Business Name</span>
				</div>
			</div>
			<div id="UPI_ID_grp_payment_ce">
				<input class="UPI_input_lbl_profile_paymet_p_cf" value={businessAddress}
                              onChange={(e) => {
                                setBusinessAddress(e.target.value);
                              }}/>
				
				<div id="UPI_ID_lbl_profile_paymet_page_cg">
					<span>Business Address</span>
				</div>
			</div>
			<div id="UPI_ID_grp_payment_ch">
				<input class="UPI_input_lbl_profile_paymet_p_ci" value={businessMobile}
                              onChange={(e) => {
                                setBusinessMobile(e.target.value);
                              }}/>
				
				<div id="UPI_ID_lbl_profile_paymet_page_cj">
					<span>Business Mobile</span>
				</div>
			</div>
			<div id="UPI_ID_grp_payment_ck">
				<input class="UPI_input_lbl_profile_paymet_p_cl" type="email" value={businessEmail}
                              onChange={(e) => {
                                setBusinessEmail(e.target.value);
                              }}/>
					
				<div id="UPI_ID_lbl_profile_paymet_page_cm">
					<span>Business Email</span>
				</div>
			</div>
			<div id="UPI_ID_grp_payment_cn">
				<input class="UPI_input_lbl_profile_paymet_p_co" value={businessWebsite}
                              onChange={(e) => {
                                setBusinessWebsite(e.target.value);
                              }}/>
				
				<div id="UPI_ID_lbl_profile_paymet_page_cp">
					<span>Business Website</span>
				</div>
			</div>
		</div>
	</div>
</div>

    </div>
  )
}

export default EditBiilingTabMob
