import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
 import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/OfferingsBottomMobUI.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Currencies from "../Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import OfferingPopUpPC from '../OfferingPopUpPC';
import OfferingsViewMorePopUp from '../OfferingsViewMorePopUp';
import MeetingPopUpUI from '../MeetingPopUpUI';
import ComOffSlide from './ComOffSlide';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const OfferingsBottomMobUI = (props) => {

  //usestate
  var navigate = useNavigate();
    const [jobsData,setJobsData]=useState([]);
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [mobile, setMobile] = useState("");
   
    const [open, setOpen] = React.useState(false);
    const [openApplicant, setOpenApplicant] = React.useState(false);
    const [jobId, setJobId] = React.useState();
    const [jobTitle, setJobTitle] = React.useState("");
    const [openGridModel,setOpenGridModel] = useState(false);
   
    const [offeredCurrency,setOfferedCurrency] = useState("");
    const [openModel,setOpenModel] = useState(false);
    const [openMeetingModel,setOpenMeetingModel] = useState(false);
    const [status,setStatus] = useState([]);
    const [biddings,setBiddings] = useState("");
    const [deliveryDeadline, setDeliveryDeadline] = useState(new Date().toISOString());
    const [deliveryLocation, setDeliveryLocation] = useState();
    const [description, setDescription] = useState("");
    const [services, setServices] = useState("");
    const [budget,setBudget] = useState(0);
    const [remarks,setRemarks] = useState("");
    const [needToBeDelivered,setNeedToBeDelivered]= useState(false);
    const [companyName, setCompanyName] = useState("");
    const [hours, setHours]= useState("hours");
    const [bargainedTime,setBargainedTime] = useState("");
    const [bargainedAmount,setBargainedAmount] = useState("");
  
   const [requirementId,setRequirementId]=useState("");
    const [offeringId,setOfferingId]=useState("");

    const [title,setTitle]= useState("");
    const [createdById,setCreatedById]=useState("");
    const [createdByName,setCreatedByName]=useState("");
  
    const [bargainedCurrency,setBargainedCurrency] = useState("")
   
    const [searchParams] = useSearchParams();
    const [offerings,setOfferings]=useState([]);

    const { user, setUser} = useContext(UserContext);
    const[isViewMoreShown,setViewMoreShown]=useState(false)
    const[isViewMore,setViewMore]=useState(false)
    const [meetingTitle,setMeetingTitle] = useState("");
    const [agenda,setAgenda] = useState("");
    const [meetingRemarks,setMeetingRemarks] = useState("");
    const [ requirementTitle,setRequirementTitle] = useState("");
    const [meetingDateTime,setMeetingDateTime] = useState(new Date().toISOString().substring(0,16));
    const [previousRemarks,setPreviousRemarks] = useState("");
    const [customerId,setCustomerId] = useState("");
    const [customerName,setCustomerName] = useState("");
    const [bargainedId,setBargainedId] = useState();
    const [offeringTime,setOfferingTime] = useState("")
    const [offHours,setOffHours] = useState("hours")
    const [ selectedCommunity,setSelectedCommunity] = useState([])
    const [communities,setCommunities] = useState([]);
    const [selectedCommunities,setSelectedCommunities] = useState([]);
    const [offeringPopup,setOfferingPopup] = useState(false);
    const [viewMorePopup,setViewMorePopup] = useState(false);
    const [viewMoreData,setViewMoreData] = useState(false);
    const [meetingData,setMeetingData] = useState({});
    const [ openOffSlide,setOpenOffSlide] = useState(false);

    //UseEffect
    useEffect(()=>{
        if(user){
          getStatus()
          getOfferings()
        }
       
        
    },[user])
      
    useEffect(()=>{
        cleartext()
    },[openApplicant])
      

 
  const setCloseModel = ()=>{
    setOpenModel(false);
}

  const addBid=(id,title,vendorId,vendorName)=>{
    setOfferingId(id);
    setTitle(title);
    setCreatedById(vendorId);
    setCreatedByName(vendorName);
    getBargains(id)
    setOpenModel(true);

    }

    const setCloseGridModel = ()=>{
      setOpenGridModel(false);
  }

  const openScheduleModel = (row) =>{
   
    //  setOfferingsId(row.offeringsId);
     setMeetingTitle(row.offeringsTitle);
     setCustomerId(row.customerid);
     setCustomerName(row.customerName)
   
    setOpenMeetingModel(true);
  }


  //get Bargains of comunity
  const getBargains = (id)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/bargain/getbargain/"+user._id; 
    let apiParams = {
      headers:{
        offeringsid:id,
    }}
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
            
           setBargainedId(repos.data[0] && repos.data[0]._id)
           setBargainedAmount(repos.data[0] && repos.data[0].bargainedAmount);
           setBargainedCurrency(repos.data[0] && repos.data[0].bargainedCurrency ? JSON.parse(repos.data[0].bargainedCurrency).cc:"INR");
           setBargainedTime(repos.data[0] && repos.data[0].bargainedHours);
           setNeedToBeDelivered(repos.data[0] && repos.data[0].needToBeDelivered);
           setRemarks(repos.data[0] && repos.data[0].remarks);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
  
  const updateStatus = (row,status)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/bargain/updatebargains/"+row.id;
    let apiParams = {
        method:"POST",
        headers:{
        // requirementid:row.requirementId,  
        status:status,
  
        },
       
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
         
          alert("Offering Status Updated Successfully!!");
          
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
    }
    
 const closeGridModel = () => setOpenGridModel(false);

    const addBargains = ()=>{
      
       setOpenModel(false)
       let BargainedTime=0;
       if(hours==="hours"){
        BargainedTime = bargainedTime;
       }else{
        BargainedTime = bargainedTime*8;
       }
       let apiURL=process.env.REACT_APP_API_URL + "/bargain/addbargains";
       let apiParams = {
           method:"POST",
           headers:{
            offeringtitle:title,
             offeringid:offeringId,
             vendorid:createdById,
             vendorname:createdByName,
             bargainedamount:bargainedAmount,
             bargainedcurrency:encodeURIComponent(JSON.stringify(bargainedCurrency)),
             bargainedhours:BargainedTime,
             needtobedelivered:needToBeDelivered,
             remarks:encodeURIComponent(remarks),
             customerid:user._id,
             customername:user.name,
             status:"Submitted"
           },
          
       };
       fetch(apiURL, apiParams)
       .then((res)=> (res.status===200 ? res.json() : res.text()))
       .then((repos)=> {
           if (repos.data) {
             cleartext()
             alert("Bargained Added Successfully");
             
            setTimeout(() => {
              getOfferings()
           }, 1500);
           
           }else alert(repos.message || repos);
       }) 
       .catch((err) => alert(err));
   }

   const updateBargain = (id)=>{
   
    setOpenModel(false)
       let BargainedTime=0;
       if(hours==="hours"){
        BargainedTime = bargainedTime;
       }else{
        BargainedTime = bargainedTime*8;
       }
       let apiURL=process.env.REACT_APP_API_URL + "/bargain/updatebargainsdata/"+id;
       let apiParams = {
           method:"POST",
           headers:{
           
             bargainedamount:bargainedAmount,
             bargainedcurrency:encodeURIComponent(JSON.stringify(Currencies.filter(curr=>curr.cc===bargainedCurrency)[0])),
             bargainedhours:BargainedTime,
             needtobedelivered:needToBeDelivered,
             remarks:encodeURIComponent(remarks),
             status:"Submitted"
           },
          
       };
       fetch(apiURL, apiParams)
       .then((res)=> (res.status===200 ? res.json() : res.text()))
       .then((repos)=> {
           if (repos.data) {

             alert("Bargained Updated Successfully");
             
          //   setTimeout(() => {
          //     getOfferings()
          //  }, 1500);
           
           }else alert(repos.message || repos);
       }) 
       .catch((err) => alert(err));
   }
   
   const cancelBargain = (id,status)=>{
    
    let apiURL=process.env.REACT_APP_API_URL + "/bargain/updatebargains/"+id;
    let apiParams = {
        method:"POST",
        headers:{ 
        status:status,
        },
       
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
         
          alert("Offering Status Updated Successfully!!");
          
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
  
   }

   const getStatus = ()=>{
 
    let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
        
            setStatus(repos.data)
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };
   
 const closeModel = () => setOpenModel(false);

   
 const handleClose = () => {
    setOpenApplicant(false); 
}


 const handleView = () => {

  //  setOpenView(true);
}


   //Get my Communities
   const getCommunities = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/community/getmycommunity/"+user._id; 
  
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
            // console.log(repos.data) 
          setCommunities(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  
//Get applicant by id
  const getOfferings = ()=>{
       
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getallofferingsbyuser/" +user._id ;
    let apiParams = {
        method:"GET",
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        // console.log(repos.data)
        setOfferings(repos.data)
         
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
 

  const cleartext = ()=>{
    setServices("");
    setDescription("");
    setDeliveryLocation("");
    setOfferingTime("");
    setBudget("");
    setOfferedCurrency("")
  }


//Add Jobs
const addOffering = () =>{
  setOpenApplicant(true)
  getCommunities()
}


const addOfferings = ()=>{
    setOpenApplicant(false)
    let OfferingTime=0;
    if(offHours==="hours"){
      OfferingTime = offeringTime;
    }else{
      OfferingTime = offeringTime*8;
    }
  let apiURL=process.env.REACT_APP_API_URL + "/offerings/addoffering";
  let apiParams = {
      method:"POST",
      headers:{
        offeringtitle:services,
        budget:budget,
        offeredcurrency:encodeURIComponent(JSON.stringify(offeredCurrency)),
        deliverylocation:encodeURIComponent(deliveryLocation),
        description:encodeURIComponent(description),
        selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        deliveryduration:OfferingTime,
        vendorid:user._id,
        vendorname:user.name,

      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        cleartext();
        alert("Offerings Added Successfully");
      
    //    props.showAlert("Job Added Successfully","success")
       setTimeout(() => {
        getOfferings()
      }, 1500);
      
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
}

const showGrid = (id)=>{
    
  getAllBiddings(id);
  setOpenGridModel(true)
}   

const closeMeetingModel = () => setOpenMeetingModel(false);

const setCloseMeetingModel = ()=>{
  setOpenMeetingModel(false);
}

const getAllBiddings = (offeringId)=>{

  let apiURL=process.env.REACT_APP_API_URL + "/bargain/getallbargains/"+offeringId; 

  fetch(apiURL)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
       
        setBiddings(repos.data)
       
      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}


  const setClose = ()=>{
    setOpenApplicant(false)
}

const openPopUp = ()=>{
  setOfferingPopup(true)
}

const openViewMorePopUp = (rec)=>{
  setViewMoreData(rec)
  setViewMorePopup(true)
}


//Disable Job
const updateIsActive = (id) =>{

  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
  let apiURL=process.env.REACT_APP_API_URL + "/offerings/updateofferingstatus/" +id;
  let apiParams = {
    method:"POST",
    headers:{
      active:false
    },
 };

 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
       alert("Offering Removed Successfully");
      // props.showAlert("Job Removed Successfully","success")
      setTimeout(() => {
        getOfferings()
      }, 2000);
     }else alert(repos.message || repos);
 }) 
 .catch((err) => alert(err));

}

const handleOpenOffSlide = ()=>{
  setOpenOffSlide(true)
}



const rowData = (biddings||[]).map((bid) => {
       
  return{
 
  id: bid._id,
  offeringsId:bid.offeringsId,
  offeringsTitle:bid.offeringsTitle,
  bargainedAmount:bid.bargainedAmount,
  bargainedHours:bid.bargainedHours,
  needToBeDelivered:bid.needToBeDelivered,
  customerName:bid.customerName,
  customerid:bid.customerId,
  remarks:bid.remarks,
  status:bid.status,
  };
});


  return (
    <div>
	<div id="down_off_grp_overall">
	
  <button title="Add Requirements" id="my_requirment_btn_com_req_mob" onClick={()=>openPopUp()}>
    <svg class="req_btn_rec_com_req_mob">
      <rect id="req_btn_rec_com_req_mob" rx="14.5" ry="14.5" x="0" y="0" width="29" height="40">
      </rect>
    </svg>
    <img id="plus_img_btn_com_req_mob" src="plus_img_btn_com_req_mob.png" srcset="plus_img_btn_com_req_mob.png 1x" />
      
  </button>
  <div id="com_req_mob_down_scroll">
  <div style={{height:"100%",display:'flex'}}>
    {offerings.map((off,idx)=>(
      <div style={{left:(idx*10).toString()+"px",position:"relative",display:"inline-flex"}}>
    <div title={off.description} id="req_card_com_req_mob">
      <svg class="bdr_com_req_mob_bj">
        <linearGradient id="bdr_com_req_mob_bj" spreadMethod="pad" x1="0.13" x2="0.89" y1="0.039" y2="1">
          <stop offset="0" stop-color="#65ff7f" stop-opacity="1"></stop>
          <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
        </linearGradient>
        <rect id="bdr_com_req_mob_bj" rx="24.5" ry="24.5" x="0" y="0" width="180" height="49">
        </rect>
      </svg>
      <img id="pfp_pic_com_req_mob" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"profilebackground.png"} />
        
      <marquee id="software_developer_lbl_com_req">
        <span>{off.offeringTitle}</span>
      </marquee>
      <div id="inr_and_date_com_req_mob">
      <span>{off.offeredcurrency && JSON.parse(off.offeredcurrency).symbol} {off.budget} </span><span> | </span><span>{( off.deliveryDuration ? off.deliveryDuration:"")} Hours</span>
      </div>
      <img id="minus_2_close_com_req_mob" src="com_req_mob_minus.png" srcset="com_req_mob_minus.png 1x" onClick={()=>updateIsActive(off._id)}/>
        
    </div>
    </div>
    ))}
    </div>
  </div>
  <img id="up-arrow_for_bottom" src="up-arrow_for_bottom.png" srcset="up-arrow_for_bottom.png 1x" onClick={()=>handleOpenOffSlide()}/>
    
</div>

 {openOffSlide===true? <ComOffSlide openOffSlide={openOffSlide} closeOffSlide={()=>setOpenOffSlide(false)}/>:""}
 
  <OfferingPopUpPC   openOffpopup={offeringPopup} closeOffpopup={()=>{setOfferingPopup(false);getOfferings()}}/>

</div>
  
  );
}

export default OfferingsBottomMobUI
