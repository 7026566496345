import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from './UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import '../styles/AddCommunitesReqPopUp.css';
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const AddCommunitiesReqPopUp = (props) => {

    //useState
    var navigate = useNavigate();
    const communityId = props.communityId;
    const communityName = props.communityName;
    const { user, setUser} = useContext(UserContext);
    const [openGridModel,setOpenGridModel] = useState(false);
    const [oddRequirements,setOddRequirements] = useState([]);


useEffect(()=>{
if(user){
    if(props.openComReq)
    {
        setOpenGridModel(props.openComReq)
        getRequirementswithoutcommunity()
    }
}    


},[props.openComReq])

const closeGridModel = ()=>{
    setOpenGridModel(false)
    props.closeComReq()
}


 //Get requirement not in community
 const  getRequirementswithoutcommunity = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getrequirementswithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


  
//adding community to requirements 
const updateStatus = (requirementId)=>{
   
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/addcommunitytorequirement/"+ requirementId ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:communityId,
            communityname:communityName,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
       
          getRequirementswithoutcommunity()
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   


  return (
    <div>
           {/* Bids data in data grid for requirement which was added */}
<Modal
        open={openGridModel}
        onClose={closeGridModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
<div id="req_pop_up">
	<img id="bg_pop_req_design" src="bg_pop_req_design.png" srcset="bg_pop_req_design.png 1x, bg_pop_req_design@2x.png 2x" />
		
	<svg class="dsfndsfd_s">
		<linearGradient id="dsfndsfd_s" spreadMethod="pad" x1="0" x2="1" y1="0" y2="1">
			<stop offset="0" stop-color="#dbf2e7" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="dsfndsfd_s" rx="0" ry="0" x="0" y="0" width="265" height="30">
		</rect>
	</svg>
	<div id="Requirements_pop_na">
		<span>Requirements</span>
	</div>
	{/* <div id="grp_useless_req">
		<div id="req_add">
			<span>Title</span>
		</div>
		<div id="Add_title_req">
			<span>Add</span>
		</div>
	</div> */}

	<div id="req_pop_scroll">
   { oddRequirements.filter(req=>req.customerId === user._id).map((re,idx) => (
    <div style={{top:(idx*40).toString()+"px",position:"relative",left:"0px"}}>
		<div id="Group_req_com_">
			<svg class="border_data_req_com">
				<rect id="border_data_req_com" rx="6" ry="6" x="0" y="0" width="253" height="37">
				</rect> 
			</svg>
			<div id="Testing_data_val_req">
				<span>{re.requirementTitle}</span>
			</div>
			<img id="plus1_add_req" src="plus1.png" srcset="plus1.png 1x, plus1@2x.png 2x" onClick={()=>{updateStatus(re._id)}}/>	
		</div>
        </div>
   ))}

	</div>

    <img title="Close" id="close_comreq_pc"  onClick={()=>{closeGridModel()}} src="close2.png" srcset="close2.png 1x, close2@2x.png 2x" />
    
</div>
       </Modal>
    </div>
  )
}

export default AddCommunitiesReqPopUp
