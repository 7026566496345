import React, { useEffect, useState, useContext} from 'react'
import "../../styles/MyCommunitiesNewMobUI.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import CreateCommunityPopUpUI from '../CreateCommunityPopUpUI';
import CommunitiesConnectedMembersNewMobUI from '../MobUI/CommunitiesConnectedMembersNewMobUI';
import CommunitiesOfferingPageNewMobUI from '../MobUI/CommunitiesOfferingsPageNewMobUI';
import CommunitiesRequirementsPageNewMobUI from '../MobUI/CommunitiesRequirementsPageNewMobUI';
import FooterPcUI from '../PC/FooterPcUI';
import ComMyComSlide from './ComMyComSlide';


const MyCommunitiesNewMobUI = () => {

 //useState
    var navigate = useNavigate();
    const { user, setUser} = useContext(UserContext);
    const [openCommunityModal,setOpenCommunityModal] = useState(false);
    const [communityName,setCommunityName] = useState("");
    const [selectedCommunityId,setSelectedCommunityId] = useState("");
    const [selectedCommunityName,setSelectedCommunityName] = useState("");
    const [communityDescription,setCommunityDescription] = useState("");
    const [communities,setCommunities] = useState("");
    const [img,setImg] = useState("default.jpg");
    const [profile,setProfile]= useState("");
    const [mode,setMode] = useState("create");
    const [selectedTab,setSelectedTab] = useState("requirements");
    const [openMyComSlide,setOpenMyComSlide] = useState(false);



    //UseEffect
    useEffect(()=>{
        if(user)
        getCommunity()
    },[user])


    useEffect(()=>{


    },[selectedCommunityId])

    // useEffect(()=>{
        
    // },[])
    //To Upload Picture
    const handleImageUpload = async (e) => {
        // if (e.target.files[0].size > 71680) {
        //   alert("Please choose a file with size below 70kb!");
        //   return;
        // }
        handleFileSelect(e.target.files[0]);
        let formdata = new FormData();
        formdata.append("image", e.target.files[0], e.target.value);
        setProfile(formdata);
      };

      //Select file to upload
  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const createCommunity = () => {
    setCloseButton()
    const apiurl = process.env.REACT_APP_API_URL + "/community/createcommunity";
    var apiparam = {
      method: "POST",
      headers: {
        communityname:communityName,
        communitydiscription:encodeURIComponent(communityDescription),
        createdbyid:user._id,
        createdbyname:user.name,
        
      },
      body:profile,
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Community Created Successfully!!");
          getCommunity()
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };


const closeCommunity =()=>{
    setOpenCommunityModal(false)
}
const loadCommunity= (communityId)=>{

}


const setCloseButton = ()=>{
    setOpenCommunityModal(false)
    setCommunityDescription("");
    setCommunityName("");
    setProfile("");
    setImg("");
}

//get comunity
const getCommunity = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/community/getcommunity/"+user._id; 

    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          repos.data[0] && setSelectedCommunityId(repos.data[0]._id)
          repos.data[0] && setSelectedCommunityName(repos.data[0].communityName)

          setCommunities(repos.data);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   //Converting Image
function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  var base64Flag = "data:image/jpeg;base64,";
  return base64Flag + window.btoa(binary);
}


const openCreatePopUp = ()=>{
  setMode("create")
  setOpenCommunityModal(true)
}

const handleopenMycomSlide = ()=>{
  setOpenMyComSlide(true)
}


  return (
  <div>
<div id="my_com_mob_version_20">
	<div id="req_top_grp_overall">
  
		<div id="community_scroll_mob">
    <div style={{width:"100%",display:"flex"}}>
    {(communities||[]).map((com,idx)=>(
      <div style={{left:(idx*180).toString()+"px",position:"relative",display:"inline-flex"}}>
			<button  title={"Description: "+com.communityDiscription} id="com_btn_grp_com_req_mob" className={com._id===selectedCommunityId ?"com_top_com_req_glow backInLeft":"backInLeft"} onClick={()=>{setSelectedCommunityId(com._id);setSelectedCommunityName(com.communityName)}}>
				<svg class="bdr_com_mob_v">
					{/* <linearGradient id="bdr_com_mob_v" spreadMethod="pad" x1="0.657" x2="0.666" y1="1" y2="0">
						<stop offset="0" stop-color="#65ff7f" stop-opacity="1"></stop>
						<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
					</linearGradient> */}
					<rect id="bdr_com_mob_v" rx="24.5" ry="24.5" x="0" y="0" width="168" height="49">
					</rect>
				</svg>
				<img id="pfp_piccom_mob" src={com.profilePic  ?arrayBufferToBase64(com.profilePic.data.data):"coding.png"} />
					
				<div id="cricketlover_lbl_com_req_mob">
					<span><marquee>{com.communityName}</marquee></span>
				</div>
			</button>
      </div>
      ))}
		</div>
   
		</div>
		<img id="down-arrow_for_top" src="down-arrow_for_top.png" srcset="down-arrow_for_top.png 1x" onClick={()=>handleopenMycomSlide()} />
			
		<button title="Add Requirements" id="my_community_btn_com_req_mob" onClick={()=>openCreatePopUp()}>
			<svg class="req_btn_rec_com_mob">
				<rect id="req_btn_rec_com_mob" rx="14.5" ry="14.5" x="0" y="0" width="29" height="40">
				</rect>
			</svg>
			<img id="plus_img_btn_com_mob" src="plus_img_btn_com_mob.png" srcset="plus_img_btn_com_mob.png 1x" />
				
		</button>
	</div>
</div>

  <div>
 {
   selectedTab=== "members" ?<CommunitiesConnectedMembersNewMobUI communityId={selectedCommunityId} communityName={selectedCommunityName} selectedTab={(st)=>setSelectedTab(st)}/> : 
    selectedTab === "offerings" ?<> <CommunitiesOfferingPageNewMobUI communityId={selectedCommunityId} communityName={selectedCommunityName} selectedTab={(st)=>setSelectedTab(st)}/></> :
   selectedTab === "requirements" ?<> <CommunitiesRequirementsPageNewMobUI communityId={selectedCommunityId}  communityName={selectedCommunityName} selectedTab={(st)=>setSelectedTab(st)}/></> :  
  "" 
  } 
  </div>
  {openMyComSlide===true?<ComMyComSlide  openMyComSlide={openMyComSlide} closeMyComSlide={(id,name)=>{setOpenMyComSlide(false); id && setSelectedCommunityId(id);name && setSelectedCommunityName(name)}}/>:""}
  <CreateCommunityPopUpUI  openComModal={openCommunityModal} mode={mode} closeComModal={()=>{setOpenCommunityModal(false);getCommunity()}}/>
</div>
 
  )
}

export default MyCommunitiesNewMobUI

