import React, { useEffect, useState, useContext} from 'react'
import "../../styles/CalanderMeetingsMob.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";

const CalanderMeetingsMob = (props) => {

	useEffect(()=>{

	},[props.data])

  return (
    <div id="calender_page_meeting_mob">
	<div id="calender_page_meeting_mob_all_">
		<svg class="bdr_meeting_mob">
			<rect id="bdr_meeting_mob" rx="22" ry="22" x="0" y="0" width="100%" height="805">
			</rect>
		</svg>
		<div id="right_menu_meeting_mob">
			<div id="mobAlarms_lbl_meeting" onClick={()=>props.selectedTab("alarms")}>
				<span>Alarms</span>
			</div>
			<div id="Tasks_lbl_meet_mob" onClick={()=>props.selectedTab("tasks")}>
				<span>Tasks</span>
			</div>
			<div id="Events_lbl_calender_mob" onClick={()=>props.selectedTab("events")}>
				<span>Events</span>
			</div>
			<div id="selected_mob_meeting">
				<div id="Meetings_lbl_headingcalendermo">
					<span>Meetings</span>
				</div>
				<svg class="mobLine_29_meeting" viewBox="0 0 1 58">
					<path id="mobLine_29_meeting" d="M 0 0 L 0 58">
					</path>
				</svg>
			</div>
		</div>
		<div id="meeting_mob_allscroll">
		{props.data.map((meet)=>(
			<div id="mobmeeting_grp_calender_page">
				<div id="mobArcane_Series_lbl_calender">
					<span>{meet.meetingTitle}</span>
				</div>
				<div id="mob1025pm_-_1200am_arcane">
					<span>{meet.meetingStartDateTime && new Date(meet.meetingStartDateTime).getHours().toString().padStart(2,"0")+":"+new Date(meet.meetingStartDateTime).getMinutes().toString().padStart(2,"0")} - {meet.meetingEndDateTime && new Date(meet.meetingEndDateTime).getHours().toString().padStart(2,"0")+":"+new Date(meet.meetingEndDateTime).getMinutes().toString().padStart(2,"0")} IST</span>
				</div>
				<svg class="mobarcane_mode_meetincalender">
					<rect id="mobarcane_mode_meetincalender" rx="0" ry="0" x="0" y="0" width="3.36" height="16.8">
					</rect>
				</svg>
				<svg class="mobinacvtice_status_calender">
					<ellipse id="mobinacvtice_status_calender" rx="5.60000467300415" ry="5.60000467300415" cx="5.60000467300415" cy="5.60000467300415">
					</ellipse>
				</svg>
				<svg class="top_line_calendermeetmob" viewBox="0 0 249.76 1">
					<path id="top_line_calendermeetmob" d="M 0 0 L 249.7599945068359 0">
					</path>
				</svg>
			</div>
			))
		}
		</div>
	</div>
</div>
  )
}

export default CalanderMeetingsMob
