import React from 'react';
import "../../../styles/ChefsKitchenMob.css";

function ChefsKitchenMob(props) {
  return (
    <div id="Chefs_Kitchen_Mob">
	<img id="chefs_13_moblcz9nxhoslo-brooke-lark" src="./Themes/ChefsKitchen/lcz9nxhoslo-brooke-lark.png" srcset="./Themes/ChefsKitchen/lcz9nxhoslo-brooke-lark.png 1x" />
		
	<div id="chefs_13_mobGroup_14943">
		<img id="chefs_13_moblidye-1Shk_PkNkNw-unsplash" src="./Themes/ChefsKitchen/lidye-1Shk_PkNkNw-unsplash.png" srcset="./Themes/ChefsKitchen/lidye-1Shk_PkNkNw-unsplash.png 1x" />
			
		<div id="chefs_13_mobFood_unites_cultures_evokes__m">
			<span>"Food unites cultures, evokes <br/>memories, and nourishes <br/>souls, bridging gaps, <br/>celebrating life's moments, <br/>and inspiring culinary <br/>creativity."</span>
		</div>
		<div id="chefs_13_mobGroup_14929">
			<svg class="chefs_13_mobRectangle_89">
				<rect id="chefs_13_mobRectangle_89" rx="0" ry="0" x="0" y="0" width="204" height="48">
				</rect>
			</svg>
			<div id="chefs_13_mobKnow_More_About_Us">
				<span>Know More About Us</span>
			</div>
		</div>
	</div>
	<div id="chefs_13_mobGroup_14944">
		<div id="chefs_13_mobGroup_14931">
			<img id="chefs_13_mobbrooke-lark-08bOYnH_r_E-unspla" src="./Themes/ChefsKitchen/brooke-lark-08bOYnH_r_E-unspla.png" srcset="./Themes/ChefsKitchen/brooke-lark-08bOYnH_r_E-unspla.png 1x" />
				
			<img id="chefs_13_mobo9kwrr26" src="./Themes/ChefsKitchen/o9kwrr26.png" srcset="./Themes/ChefsKitchen/o9kwrr26.png 1x" />
				
			<img id="chefs_13_mobjoseph-gonzalez-zcUgjyqEwe8-un" src="./Themes/ChefsKitchen/joseph-gonzalez-zcUgjyqEwe8-un.png" srcset="./Themes/ChefsKitchen/joseph-gonzalez-zcUgjyqEwe8-un.png 1x" />
				
			<img id="chefs_13_mobjoseph-gonzalez-fdlZBWIP0aM-un" src="./Themes/ChefsKitchen/joseph-gonzalez-fdlZBWIP0aM-un.png" srcset="./Themes/ChefsKitchen/joseph-gonzalez-fdlZBWIP0aM-un.png 1x" />
				
		</div>
		<div id="chefs_13_mobFood_a_universal_language_a_ta">
			<span>"Food: a universal language, a tapestry of flavors, a conduit of memories, a catalyst for connection, and a celebration of life."</span>
		</div>
		<div id="chefs_13_mobGroup_14932">
			<svg class="chefs_13_mobRectangle_89_cy">
				<rect id="chefs_13_mobRectangle_89_cy" rx="0" ry="0" x="0" y="0" width="204" height="48">
				</rect>
			</svg>
			<div id="chefs_13_mobKnow_More_About_Us_cz">
				<span>Know More About Us</span>
			</div>
		</div>
	</div>
	<div id="chefs_13_mobGroup_14934">
		<svg class="chefs_13_mobRectangle_696">
			<rect id="chefs_13_mobRectangle_696" rx="13" ry="13" x="0" y="0" width="321.641" height="433.428">
			</rect>
		</svg>
		<img id="chefs_13_mobeaters-collective-172257-unspl" src="./Themes/ChefsKitchen/eaters-collective-172257-unspl.png" srcset="./Themes/ChefsKitchen/eaters-collective-172257-unspl.png 1x" />
			
		<div id="chefs_13_mobScroll_Group_44">
			<div id="chefs_13_mobLorem_ipsum_dolor_sit_amet_con">
				<span>{props.data.vision}</span>
			</div>
		</div>
		<div id="chefs_13_mobVision">
			<span>Vision</span>
		</div>
	</div>
	<div id="chefs_13_mobGroup_14933">
		<svg class="chefs_13_mobRectangle_698">
			<rect id="chefs_13_mobRectangle_698" rx="13" ry="13" x="0" y="0" width="321.641" height="433.428">
			</rect>
		</svg>
		<img id="chefs_13_moban_vision-1188492-unsplash" src="./Themes/ChefsKitchen/an_vision-1188492-unsplash.png" srcset="./Themes/ChefsKitchen/an_vision-1188492-unsplash.png 1x" />
			
		<div id="chefs_13_mobScroll_Group_45">
			<div id="chefs_13_mobLorem_ipsum_dolor_sit_amet_con_da">
				<span>{props.data.mission}</span>
			</div>
		</div>
		<div id="chefs_13_mobMission">
			<span>Mission</span>
		</div>
	</div>
	<div id="chefs_13_mobGroup_14935">
		<svg class="chefs_13_mobRectangle_697">
			<rect id="chefs_13_mobRectangle_697" rx="13" ry="13" x="0" y="0" width="321.641" height="433.428">
			</rect>
		</svg>
		<img id="chefs_13_mobn_es93lms" src="./Themes/ChefsKitchen/n_es93lms.png" srcset="./Themes/ChefsKitchen/n_es93lms.png 1x" />
			
		<div id="chefs_13_mobScroll_Group_46">
			<div id="chefs_13_mobLorem_ipsum_dolor_sit_amet_con_dg">
				<span>{props.data.principles}</span>
			</div>
		</div>
		<div id="chefs_13_mobPrinciples">
			<span>Principles</span>
		</div>
	</div>
	<img id="chefs_13_mobgood-soul-shop-DlO0yz4U67A-uns" src="./Themes/ChefsKitchen/good-soul-shop-DlO0yz4U67A-uns.png" srcset="./Themes/ChefsKitchen/good-soul-shop-DlO0yz4U67A-uns.png 1x" />
		
	<div id="chefs_13_mobExplore_Our_Foods">
		<span>Explore Our Foods</span>
	</div>
	<div id="chefs_13_mobScroll_Group_41">
    {props.offeringsData.map((off,idx)=>(<>
		<div id="chefs_13_mobGroup_14920">
			<div id="chefs_13_mobGroup_14941">
				<img id="chefs_13_mobkisspng-plate-circle-platter-t" src="./Themes/ChefsKitchen/kisspng-plate-circle-platter-t.png" srcset="./Themes/ChefsKitchen/kisspng-plate-circle-platter-t.png 1x" />
					
				<img id="chefs_13_mobanh-nguyen-kcA-c3f_3FE-unsplas" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/ChefsKitchen/anh-nguyen-kcA-c3f_3FE-unsplas.png"} />
					
			</div>
		</div>
		<div id="chefs_13_mobSalad">
			<span>{off.offeringTitle}</span>
		</div></>))}
	</div>
	<div id="chefs_13_mobGroup_14936">
		<svg class="chefs_13_mobRectangle_107">
			<rect id="chefs_13_mobRectangle_107" rx="0" ry="0" x="0" y="0" width="255" height="70">
			</rect>
		</svg>
		<div id="chefs_13_mobView_More_-_">
			<span>View More - &gt;</span>
		</div>
	</div>
	<div id="chefs_13_mobGroup_14945">
		<svg class="chefs_13_mobRectangle_678">
			<rect id="chefs_13_mobRectangle_678" rx="0" ry="0" x="0" y="0" width="403" height="837">
			</rect>
		</svg>
		<div id="chefs_13_mobFor_Queries_infoandroomedanet">
			<span>For Queries: {props.data.businessEmail}</span>
		</div>
		<div id="chefs_13_mobFor_call__920012888">
			<span>For call : {props.data.businessPhone}</span>
		</div>
		<svg class="chefs_13_mobtelephone" viewBox="0 0 11.38 10.522">
			<path id="chefs_13_mobtelephone" d="M 5.285655498504639 9.613908767700195 L 0.9823954105377197 5.635064125061035 C -0.3238036036491394 4.427295207977295 -0.3306940197944641 2.468093156814575 0.9823954105377197 1.253952264785767 L 2.241295099258423 0.08995640277862549 C 2.384282350540161 -0.04229017719626427 2.621845722198486 -0.02730839140713215 2.743739366531372 0.1229615285992622 L 4.886256217956543 2.764296293258667 C 4.985811233520508 2.887007236480713 4.972586154937744 3.058732271194458 4.855293750762939 3.167201519012451 L 3.574788808822632 4.351193428039551 C 3.42620062828064 4.488577842712402 3.425956249237061 4.711372852325439 3.574788808822632 4.848983764648438 L 6.13579797744751 7.216946601867676 C 6.284387111663818 7.354290962219238 6.525328159332275 7.354516506195068 6.674205303192139 7.216926574707031 L 7.954710006713867 6.032955169677734 C 8.072046279907227 5.924466133117676 8.257777214050293 5.912298679351807 8.390473365783691 6.004327774047852 L 11.24716854095459 7.985313892364502 C 11.40937995910645 8.097809791564941 11.42625045776367 8.317337989807129 11.28286647796631 8.449872016906738 L 10.02394390106201 9.613887786865234 C 8.715367317199707 10.82381629943848 6.595943450927734 10.82542037963867 5.285654544830322 9.613908767700195 Z">
			</path>
		</svg>
		<svg class="chefs_13_mobsend_1" viewBox="0 0 11.38 10.522">
			<path id="chefs_13_mobsend_1" d="M 11.3803596496582 0 L 0 5.918774127960205 L 3.636945486068726 7.164215564727783 L 9.246563911437988 2.301749229431152 L 4.979704856872559 7.624059200286865 L 4.98317289352417 7.625257015228271 L 4.978921890258789 7.624059200286865 L 4.978921890258789 10.52227306365967 L 7.018501281738281 8.32220458984375 L 9.602203369140625 9.206996917724609 L 11.3803596496582 0 Z">
			</path>
		</svg>
		<div id="chefs_13_mobsocials_grp_med_web_4_mob">
		{props.data.linkedInUrl?
			<div title="LinkedIn" id="chefs_13_moblinked_in_grp_med_web_4_pc">
				<svg class="chefs_13_mobEllipse_5_med_web_4_pc">
					<ellipse id="chefs_13_mobEllipse_5_med_web_4_pc" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<div id="chefs_13_moblinked_in_website_4_layout">
					<a href={props.data.linkedInUrl} target="_blank">
					<svg class="chefs_13_mobn_website_2_layout" viewBox="-15.255 0 16.092 15.182">
						<path id="chefs_13_mobn_website_2_layout" d="M -5.186071872711182 9.679508821136551e-07 C -7.904781818389893 9.679508821136551e-07 -9.60834789276123 1.472553014755249 -9.923189163208008 2.504196882247925 L -9.923189163208008 0.1445503830909729 L -15.2549991607666 0.1445503830909729 C -15.18569564819336 1.397309064865112 -15.2549991607666 15.18160820007324 -15.2549991607666 15.18160820007324 L -9.923189163208008 15.18160820007324 L -9.923189163208008 7.044621467590332 C -9.923189163208008 6.592491626739502 -9.944971084594727 6.140363693237305 -9.807681083679199 5.815624713897705 C -9.440696716308594 4.911367893218994 -8.653267860412598 3.972789287567139 -7.237480163574219 3.972789287567139 C -5.38672399520874 3.972789287567139 -4.544512748718262 5.363495349884033 -4.544512748718262 7.400383949279785 L -4.544512748718262 15.18160820007324 L 0.8368034362792969 15.18160820007324 L 0.8368034362792969 6.820206165313721 C 0.8368034362792969 2.162956237792969 -1.813262462615967 9.679508821136551e-07 -5.186071872711182 9.679508821136551e-07">
						</path>
					</svg>
				</a>
					<svg class="chefs_13_moblinked_in_i_down" viewBox="0.931 -22.783 4.746 15.038">
						<path id="chefs_13_moblinked_in_i_down" d="M 0.9310001134872437 -7.745282173156738 L 5.676695346832275 -7.745282173156738 L 5.676695346832275 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -7.745282173156738 Z">
						</path>
					</svg>
					<svg class="chefs_13_mobtop_dot_i_website_2_layout" viewBox="-4.392 0 5.761 5.218">
						<path id="chefs_13_mobtop_dot_i_website_2_layout" d="M -1.493098974227905 0 C -3.245508193969727 0 -4.392000198364258 1.125370144844055 -4.392000198364258 2.611123323440552 C -4.392000198364258 4.06783390045166 -3.278510093688965 5.217626571655273 -1.560423135757446 5.217626571655273 L -1.526101112365723 5.217626571655273 C 0.2579902410507202 5.217626571655273 1.368839383125305 4.063873767852783 1.368839383125305 2.606502532958984 C 1.334516644477844 1.122730016708374 0.2579902410507202 0 -1.493098974227905 0">
						</path>
					</svg>
				</div>
			</div>:""}
			{props.data.instagramUrl?	
			<div title="Instagram" id="chefs_13_mobInsta_grp_med_web_4_pc">
				<svg class="chefs_13_mobEllipse_4_med_web_4_pc">
					<ellipse id="chefs_13_mobEllipse_4_med_web_4_pc" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<a href={props.data.instagramUrl} target="_blank">
				<img id="chefs_13_mobinstagram_website_4_layout" src="./Themes/ChefsKitchen/instagram_website_4_layout.png" srcset="./Themes/ChefsKitchen/instagram_website_4_layout.png 1x" />
					
				</a>
			</div>:""}
			{props.data.whatsAppNumber?
			<div title="WhatsApp" id="chefs_13_mobwhatsapp_grp_med_web_4_pc">
				<svg class="chefs_13_mobEllipse_3_med_web_4_pc">
					<ellipse id="chefs_13_mobEllipse_3_med_web_4_pc" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="chefs_13_mobwhatsapp_lbl_website4_layout" src="./Themes/ChefsKitchen/whatsapp_lbl_website4_layout.png" srcset="./Themes/ChefsKitchen/whatsapp_lbl_website4_layout.png 1x" />
					</a>
			</div>:""}
		</div>
		<svg class="chefs_13_mobIcon_metro-location" viewBox="8.355 1.928 11.381 18.209">
			<path id="chefs_13_mobIcon_metro-location" d="M 14.04507637023926 1.928033471107483 C 10.90240859985352 1.928033471107483 8.354801177978516 4.475640773773193 8.354801177978516 7.618309020996094 C 8.354801177978516 13.30858421325684 14.04507637023926 20.13691520690918 14.04507637023926 20.13691520690918 C 14.04507637023926 20.13691520690918 19.7353515625 13.30858421325684 19.7353515625 7.618309020996094 C 19.7353515625 4.475640773773193 17.18770790100098 1.928033709526062 14.04507637023926 1.928033709526062 Z M 14.04507637023926 11.10360240936279 C 12.12019920349121 11.10360240936279 10.55978298187256 9.543186187744141 10.55978298187256 7.618309020996094 C 10.55978298187256 5.69343090057373 12.12019920349121 4.133015155792236 14.04507637023926 4.133015155792236 C 15.9699535369873 4.133015155792236 17.53037071228027 5.69343090057373 17.53037071228027 7.618309020996094 C 17.53037071228027 9.543186187744141 15.9699535369873 11.10360240936279 14.04507637023926 11.10360240936279 Z M 11.84009552001953 7.618309020996094 C 11.84009552001953 6.40053653717041 12.82730388641357 5.413327217102051 14.04507637023926 5.413327217102051 C 15.26284980773926 5.413327217102051 16.25005722045898 6.40053653717041 16.25005722045898 7.618309020996094 C 16.25005722045898 8.836081504821777 15.26284980773926 9.823290824890137 14.04507637023926 9.823290824890137 C 12.82730388641357 9.823290824890137 11.84009552001953 8.836081504821777 11.84009552001953 7.618309020996094 Z">
			</path>
		</svg>
		<div id="chefs_13_mobFor_call__920012888_eg">
			<span>{props.data.businessAddress}</span>
		</div>
		<div id="chefs_13_mobn_Copyright_Operate_Live_websi">
			<span>© Copyright Operate Live (OPC) Private Limited.<br/>All Rights Reserved</span>
		</div>
		<div id="chefs_13_mobGroup_14926">
			<img id="chefs_13_mobkisspng-tray-glass-plastic-pla" src="./Themes/ChefsKitchen/kisspng-tray-glass-plastic-pla.png" srcset="./Themes/ChefsKitchen/kisspng-tray-glass-plastic-pla.png 1x" />
				
			<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="chefs_13_mobRectangle_701">
				<rect id="chefs_13_mobRectangle_701" rx="0" ry="0" x="0" y="0" width="267" height="172">
				</rect>
			</iframe>
		</div>
		<div id="chefs_13_mobn_Copyright_Operate_website_9_">
			<span>Visit Us !!</span>
		</div>
	</div>
	<div id="chefs_13_mobGroup_14942">
		<div id="chefs_13_mobGroup_14927">
			<img id="chefs_13_mobstyle_3" src="./Themes/ChefsKitchen/style_3.png" srcset="./Themes/ChefsKitchen/style_3.png 1x" />
				
			<svg class="chefs_13_mobRectangle_695_eq">
				<linearGradient id="chefs_13_mobRectangle_695_eq" spreadMethod="pad" x1="0" x2="1" y1="0.521" y2="0.529">
					<stop offset="0" stop-color="#chefs_13_mob291d1b" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#chefs_13_mob30201e" stop-opacity="0.949"></stop>
				</linearGradient>
				<rect id="chefs_13_mobRectangle_695_eq" rx="0" ry="0" x="0" y="0" width="390" height="228">
				</rect>
			</svg>
			<svg class="chefs_13_mobRectangle_84">
				<rect id="chefs_13_mobRectangle_84" rx="0" ry="0" x="0" y="0" width="85" height="20">
				</rect>
			</svg>
			<div id="chefs_13_mobGood_food_choices_are_good_inv">
				<span>Good food choices are<br/>good investments.</span>
			</div>
			<div id="chefs_13_mobExplore_Now_">
				<span>Explore Now !</span>
			</div>
			<div id="chefs_13_mobScroll_Group_43">
				<div id="chefs_13_mobThere_is_a_powerful_need_for_s">
					<span>There is a powerful need for symbolism, and that means the architecture must have something that appeals to the human heart.</span>
				</div>
			</div>
			<img id="chefs_13_mobzane-lee-bXbi1jJVDf0-unsplash_" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/ChefsKitchen/zane-lee-bXbi1jJVDf0-unsplash_.png"} />
				
			<div id="chefs_13_mobOperate_Live">
				<span>{props.data.businessName}</span>
			</div>
		</div>
		<svg class="chefs_13_mobRectangle_700">
			<rect id="chefs_13_mobRectangle_700" rx="0" ry="0" x="0" y="0" width="390" height="177">
			</rect>
		</svg>
	</div>
</div>
  )
}

export default ChefsKitchenMob
