import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../styles/suggestionUI.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import SuggestionPageIntroPopUp from './SuggestionPageIntroPopUp';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style5 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const SuggestionsUI = () => {

//UseState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [users,setUsers] = useState("");
const [ allStatus,setAllStatus] = useState("");
const [follow,setFollow] = useState("");
const [openModel,setOpenModel] = useState(false);
const [intro,setIntro] = useState("");
const [cid,setCId] = useState("");
const [name,setName] = useState("");
const [statusReq,setReqq] = useState("");
const [selectedCommunity,setSelectedCommunity] = useState([])
const [communities,setCommunities] = useState([]);
const [openIntroModal,setOpenIntroModal] = useState(false);
const [introData,setIntroData] = useState(false);


 //UseEffect
 useEffect(()=>{
  getAllUsers()
  //  if(user){
  //   getStatus()
  //   getCommunities()
  //  }
},[user]) 
  
const ConnectionReq = async (id,name,status)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/connection/addconnections";
    let apiParams = {
        method:"POST",
        headers:{
          user1id:user._id,
          user1name:user.name,
          status:status,
           user2id:id,
           user2name:name,  
           intro:encodeURIComponent(intro),
           user1selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        },
        
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
      
          alert("Action Updated Successfully!!");
          
      //    props.showAlert("Job Added Successfully","success")
         setTimeout(() => {
          
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err)); 
}

const openModal = (id,name)=>{

  setOpenModel(true);
  setCId(id);
  setName(name);

}


const getStatus = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          console.log(repos.data)
          setAllStatus(repos.data)
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};


//Converting Image
function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  var base64Flag = "data:image/jpeg;base64,";
  return base64Flag + window.btoa(binary);
}

const getAllUsers = ()=>{
    let apiURL = process.env.REACT_APP_API_URL + "/users/getUserSuggestions/"+user._id;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
            console.log(repos.data)
         setUsers(repos.data)
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
};

const setCloseModel = ()=>{
  setOpenModel(false);
}
  
const closeModel = () => setOpenModel(false);

const addIntro = ()=>{

  ConnectionReq(cid,name,"Requested");
  setOpenModel(false)

}

//Get my Communities
const getCommunities = ()=>{

  let apiURL=process.env.REACT_APP_API_URL + "/community/getmycommunity/"+user._id; 

  fetch(apiURL)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
          console.log(repos.data)
        setCommunities(repos.data)
      
      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}

const openConnectionModal = (id,name)=>{
  
setIntroData({id,name})
 setOpenIntroModal(true)
}

  return (
    <div>
   <div id="suggestions_Pc_page">

		<img id="suggestion_bg_image" src="suggestion_bg_image.png" srcset="suggestion_bg_image.png 1x, suggestion_bg_image@2x.png 2x" />
			
		<div id="PC_Suggestions_heading">
			<span>Suggestions</span>
		</div>
		<svg class="suggestions_Line_1" viewBox="0 0 125 1">
			<path id="suggestions_Line_1" d="M 0 0 L 125 0">
			</path>
		</svg>
    <div style={{height:"100%",overflowY:"auto"}}>
    {(users||[]).map((us)=>(
     
     <div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
		 { user.name !==us.name ? <div id="card_suggestion_pc" >
			
			<svg class="suggestions_card_" onClick={()=>navigate("../"+ us.nickName)}>
				<rect id="suggestions_card_" rx="0" ry="0" x="0" y="0" width="359" height="209">
				</rect>
			</svg>
			<img id="suggestions_pfp" src={us.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(us.imageUrl.split('/')[5]):"default.jpg"}  />
				
			<div id="suggestion_info_grp">
				<div id="suggestion_profession">
					<span>Profession :</span>
				</div>
				<div id="suggestion_Connected_On">
					<span>Introduction</span>
				</div>
				<div id="suggestion_SD">
					<span>{us.profession}</span>
				</div>
				{/* <div id="suggestion_11-02-2020">
					<span>11-02-2020</span>
				</div> */}
			</div>
			<div id="suggestion_Scroll_Group_1">
				<div id="scrolable_data_suggestions">
					<span>{us.introduction}</span>
				</div>
			</div>
			<svg class="suggestions_name_box">
				<rect id="suggestions_name_box" rx="0" ry="0" x="0" y="0" width="220" height="30">
				</rect>
			</svg>
			<div id="suggestions_pfp_name_pc">
				<span>{us.name}</span>
			</div>
			<div id="suggestion_card_button_grp">
				<svg title="Connect" class="suggestion_round_1">
					<ellipse id="suggestion_round_1" rx="18" ry="18" cx="18" cy="18">
					</ellipse>
				</svg>
				<img title="Connect" id="suggestion_link_img" onClick={()=>{openConnectionModal(us._id,us.name)}} src="suggestion_link_img.png" srcset="suggestion_link_img.png 1x, suggestion_link_img@2x.png 2x" />
					
				<svg title="Follow" class="suggestion_round_2" >
					<ellipse id="suggestion_round_2" rx="18" ry="18" cx="18" cy="18">
					</ellipse>
				</svg>
				<img title="Follow" id="suggestion_follow_img" onClick={(e) =>{ConnectionReq(us._id,us.name,"Following")}} src="suggestion_mob_img_follow.png" srcset="suggestion_mob_img_follow.png 1x" />
					
				<svg title="Block" class="suggestion_round_3">
					<ellipse id="suggestion_round_3" rx="18" ry="18" cx="18" cy="18">
					</ellipse>
				</svg>
				<img title="Block" id="suggestion_prohibition_img" onClick={(e) =>{ConnectionReq(us._id,us.name,"Blocked")}} src="suggestion_prohibition_img.png" srcset="suggestion_prohibition_img.png 1x, suggestion_prohibition_img@2x.png 2x" />
					
				<svg title="Ignore" class="suggestion_final_round" >
					<ellipse id="suggestion_final_round" rx="18" ry="18" cx="18" cy="18">
					</ellipse>
				</svg>
				<img title="Ignore" id="suggestion_user_img" onClick={(e) =>{ConnectionReq(us._id,us.name,"Ignored")}} src="btn_img_ignore_mob.png" srcset="btn_img_ignore_mob.png 1x" />
       
			</div>
		</div>:""}
    </div>
    ))}
</div>

<SuggestionPageIntroPopUp  openSuggestionModal={openIntroModal}  introData={introData} closeSuggestionModal={()=>setOpenIntroModal(false)}/>
</div>
    </div>
  )
}

export default SuggestionsUI
