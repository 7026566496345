import React from 'react';
import "../../../styles/RadiantLocksMob.css";

function RadiantLocksMob(props) {
  return (
    <div id="RadiantLocks_Mob">
	<div id="RLMOBGroup_15132">
		<div id="RLMOBGroup_15040">
			<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="RLMOBRectangle_737">
				<rect id="RLMOBRectangle_737" rx="0" ry="0" x="0" y="0" width="281" height="158">
				</rect>
			</iframe>
		</div>
		<div id="RLMOBGroup_15050">
			<div id="RLMOBsocials_grp_shadow_space_websi">
{props.data.linkedInUrl?
				<div title="LinkedIn" id="RLMOBlinked_in_grp_shadow_space_web">
					<svg class="RLMOBmed_shadow_space_website_7">
						<ellipse id="RLMOBmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="RLMOBlinked_in_shadow_space_website">
						<a href={props.data.linkedInUrl} target="_blank">
						<svg class="RLMOBn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="RLMOBn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="RLMOBlinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="RLMOBlinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="RLMOBtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="RLMOBtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>
:""}
{props.data.instagramUrl?
				<div title="Instagram" id="RLMOBInsta_grp_shadow_space_website">
					<svg class="RLMOBshadow_space_website_7">
						<ellipse id="RLMOBshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={props.data.instagramUrl} target="_blank">
					<img id="RLMOBinstagramshadow_space_website_" src="./Themes/RadiantLocks/instagramshadow_space_website_.png" srcset="./Themes/RadiantLocks/instagramshadow_space_website_.png 1x" />
						
					</a>
				</div>
:""}
{props.data.whatsAppNumber?
				<div title="WhatsApp" id="RLMOBwhatsapp_grp_shadow_space_webs">
					<svg class="RLMOBmed_shadow_space_website_7_bn">
						<ellipse id="RLMOBmed_shadow_space_website_7_bn" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
<img id="RLMOBwhatsapp_lbl_shadow_space_webs" src="./Themes/RadiantLocks/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/RadiantLocks/whatsapp_lbl_shadow_space_webs.png 1x" />
</a>
				</div>:""}
			</div>
			<div id="RLMOBn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="RLMOBaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="RLMOBaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="RLMOBlocation_img_shadow_space_webs" src="./Themes/RadiantLocks/location_img_shadow_space_webs.png" srcset="./Themes/RadiantLocks/location_img_shadow_space_webs.png 1x" />
				
			<img id="RLMOBemail_address_shadow_space_web" src="./Themes/RadiantLocks/email_address_shadow_space_web.png" srcset="./Themes/RadiantLocks/email_address_shadow_space_web.png 1x" />
				
			<img id="RLMOBphone_number_shadow_space_webs" src="./Themes/RadiantLocks/phone_number_shadow_space_webs.png" srcset="./Themes/RadiantLocks/phone_number_shadow_space_webs.png 1x" />
				
			<div id="RLMOBn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited.<br/> All Rights Reserved</span>
			</div>
			<div id="RLMOBContact_Us">
				<span>Contact Us!</span>
			</div>
		</div>
	</div>
	<div id="RLMOBGroup_15139">
		<svg class="RLMOBRectangle_752">
			<rect id="RLMOBRectangle_752" rx="0" ry="0" x="0" y="0" width="375" height="109">
			</rect>
		</svg>
		<div id="RLMOBScroll_Group_93">
			<div id="RLMOBIntroduction">
				<span>Introduction</span>
			</div>
		</div>
	</div>
	<div id="RLMOBGroup_15164">
		<div id="RLMOBGroup_15141">
			<svg class="RLMOBRectangle_752_b">
				<rect id="RLMOBRectangle_752_b" rx="0" ry="0" x="0" y="0" width="337" height="594">
				</rect>
			</svg>
			<img id="RLMOBjamie-street-aMuq-Xz7R_M-unspl" src="./Themes/RadiantLocks/jamie-street-aMuq-Xz7R_M-unspl.png" srcset="./Themes/RadiantLocks/jamie-street-aMuq-Xz7R_M-unspl.png 1x" />
				
			<div id="RLMOBScroll_Group_28">
				<div id="RLMOBIt_is_a_long_established_fact_">
					<span>{props.data.vision}</span>
				</div>
			</div>
			<div id="RLMOBVISION">
				<span>VISION</span>
			</div>
		</div>
		<div id="RLMOBGroup_15160">
			<svg class="RLMOBRectangle_752_ca">
				<rect id="RLMOBRectangle_752_ca" rx="0" ry="0" x="0" y="0" width="337" height="594">
				</rect>
			</svg>
			<img id="RLMOBjamie-street-aMuq-Xz7R_M-unspl_ca" src="./Themes/RadiantLocks/jamie-street-aMuq-Xz7R_M-unspl_ca.png" srcset="./Themes/RadiantLocks/jamie-street-aMuq-Xz7R_M-unspl_ca.png 1x" />
				
			<div id="RLMOBScroll_Group_28_cb">
				<div id="RLMOBIt_is_a_long_established_fact__cc">
					<span>{props.data.mission}</span>
				</div>
			</div>
			<div id="RLMOBMISSION">
				<span>MISSION</span>
			</div>
		</div>
		<div id="RLMOBGroup_15161">
			<svg class="RLMOBRectangle_752_cf">
				<rect id="RLMOBRectangle_752_cf" rx="0" ry="0" x="0" y="0" width="337" height="594">
				</rect>
			</svg>
			<img id="RLMOBjamie-street-aMuq-Xz7R_M-unspl_cg" src="./Themes/RadiantLocks/jamie-street-aMuq-Xz7R_M-unspl_cg.png" srcset="./Themes/RadiantLocks/jamie-street-aMuq-Xz7R_M-unspl_cg.png 1x" />
				
			<div id="RLMOBScroll_Group_28_ch">
				<div id="RLMOBIt_is_a_long_established_fact__ci">
					<span>{props.data.principles}</span>
				</div>
			</div>
			<div id="RLMOBPRINCIPLE">
				<span>PRINCIPLE</span>
			</div>
		</div>
	</div>
	<div id="RLMOBGroup_15163">
		<div id="RLMOBScroll_Group_91">
			<div id="RLMOBGroup_15144">
				<svg class="RLMOBRectangle_754">
					<rect id="RLMOBRectangle_754" rx="10" ry="10" x="0" y="0" width="221" height="301">
					</rect>
				</svg>
				<img id="RLMOBthibaut-burckel-n2V5MLDPE-k-un" src="./Themes/RadiantLocks/thibaut-burckel-n2V5MLDPE-k-un.png" srcset="./Themes/RadiantLocks/thibaut-burckel-n2V5MLDPE-k-un.png 1x" />
					
				<div id="RLMOBTitle">
					<span>Title</span>
				</div>
				<div id="RLMOBn_10000">
					<span>₹ 10,000</span>
				</div>
			</div>
		</div>
		<div id="RLMOBGroup_15162">
			<div id="RLMOBOfferings">
				<span>Offerings</span>
			</div>
			<div id="RLMOBGroup_15145">
				<svg class="RLMOBRectangle_753">
					<rect id="RLMOBRectangle_753" rx="3" ry="3" x="0" y="0" width="123" height="49">
					</rect>
				</svg>
				<div id="RLMOBView_More">
					<span>View More</span>
				</div>
			</div>
		</div>
	</div>
	<div id="RLMOBGroup_15098" class="fadeIn">
		<img id="RLMOBMask_Group_1" src="./Themes/RadiantLocks/Mask_Group_1.png" srcset="./Themes/RadiantLocks/Mask_Group_1.png 1x" />
			
		<div id="RLMOBGroup_15092">
			<svg class="RLMOBRectangle_3">
				<rect id="RLMOBRectangle_3" rx="17.5" ry="17.5" x="0" y="0" width="127" height="35">
				</rect>
			</svg>
			<div id="RLMOBOrder_Now">
				<span>Order Now</span>
			</div>
		</div>
		<div id="RLMOBWhere_Beauty_Meets__Confidence">
			<span>"Where Beauty Meets <br/>Confidence, Every <br/>Appointment."</span>
		</div>
		<div id="RLMOBChoose_one_of_our_plans_enter_">
			<span>Choose one of our plans, enter delivery time<br/>and enjoy delicious food without leaving your home!</span>
		</div>
		<div id="RLMOBCompany_Name">
			<span>{props.data.businessName}</span>
		</div>
	</div>
</div>
  )
}

export default RadiantLocksMob