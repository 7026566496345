import React, { useState, useEffect, useContext } from "react";
import { Button, Divider } from "@mui/material";
import "../../styles/AddEventsToCalander.css";
import Modal from "@mui/material/Modal";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";

const AddEventsToCalander = (props) => {

    const [searchParams] = useSearchParams();
    const { user, setUser } = useContext(UserContext);
    var navigate = useNavigate();
    const [open,setOpen] = useState(false);
	const [eventLocation,setEventLocation] = useState("");
	const [eventCost,setEventCost] = useState("");
	const [eventEndOn,setEventEndOn] = useState(new Date().toISOString().substring(0,16));
	const [eventSeats,setEventSeats] = useState("");
	const [eventStartOn,setEventStartOn] = useState(new Date().toISOString().substring(0,16));
	const [eventCategory,setEventCategory] = useState("");
	const [eventDiscription,setEventDiscription] = useState("");
	const [eventName,setEventName] = useState("");
	const [eventPictures,setEventPictures] = useState("");
	const [eventBanner,setEventBanner] = useState("");


  useEffect(()=>{

if(props.dateAndTime){
  setEventStartOn(props.dateAndTime)
  setEventEndOn(props.dateAndTime)
}

  },[props.dateAndTime])
	
	  function handleFileSelect(f) {
		var reader = new FileReader();
		// Closure to capture the file information.
		reader.onload = (function(theFile) {
		  return function(e) {
			var binaryData = e.target.result;
			//Converting Binary Data to base 64
			var base64String = window.btoa(binaryData);
			//showing file converted to base64
	
			setEventBanner("data:image/jpeg;base64," + base64String);
	
		  };
		})(f);
		// Read in the image file as a data URL.
		reader.readAsBinaryString(f);
	  }
	
	  const handleImageUpload = (e) => {
	
		handleFileSelect(e.target.files[0]);
		//UploadImageToDrive(e.target.files[0]);
		setEventPictures(e.target.files[0])
		
	  };

      const addEvent = () => {

        if(eventName.length === 0){
  
      return alert("Please Enter Event Name!!")
  
      }
  
      if(eventCategory.length === undefined || eventCategory.length === 0){
       
       return alert("Please select Event Category!!")
      }
      if(eventStartOn.length === undefined || eventStartOn.length ==="" ){
   
        return alert("Please select Event Start Details!!")
      }
   
      if(eventLocation.length === 0 || eventLocation.length ==="" ){
   
       return alert("Please Enter Event Location!!")
      }
  
     let apiURL = process.env.REACT_APP_API_URL + "/events/addevent";
     let apiParams = {
       method: "POST",
       headers: {
       eventname:eventName,
       eventdiscription:encodeURIComponent(eventDiscription),
       eventcategory:eventCategory,
       eventstarton:eventStartOn,
       eventendon:eventEndOn,
       eventseats:eventSeats,
       eventcost:eventCost,
       eventlocation:eventLocation,
       organiserid:user._id,
       organisername:user.name,
       organiserimageid:user.imageId,
       },
     };
     fetch(apiURL, apiParams)
       .then((res) => (res.status === 200 ? res.json() : res.text()))
       .then((repos) => {
       if (repos.data) {
        props.close()
         alert("Events Added Successfully");
   
        eventPictures &&
         UploadImageToDrive(repos.data._id, eventPictures);
       } else alert(repos.message || repos);
       })
       .catch((err) => alert(err));
     };
  
  
   //Upload Image
   const UploadImageToDrive = async (id,document) => {
    var file = document; //the file
    var reader = new FileReader(); //this for convert to Base64
    reader.readAsDataURL(document); //start conversion...
    reader.onload = function(e) {
      //.. once finished..
      var rawLog = reader.result.split(",")[1]; //extract only thee file data part
      var dataSend = {
      dataReq: { data: rawLog, name: file.name, type: file.type },
      fname: "uploadFilesToGoogleDrive",
      }; //preapre info to send to API
      fetch(
      process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
      { method: "POST", body: JSON.stringify(dataSend) }
      ) //send to Api
      .then((res) => res.json())
      .then((data) => {
        //See response
  
        SaveEventBanner(data,id);
  
        // alert("Image Uploaded Successfully","success")
      })
      .catch((err) => console.log(err)); // Or Error in console
    };
    };
  
    const SaveEventBanner = (data,id) => {
    const apiurl =
      process.env.REACT_APP_API_URL + "/events/updateeventbannerimage/" + id;
    let apiParams = {
      method: "POST",
      headers: {
      eventimageurl: data.url,
      },
    };
    fetch(apiurl, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
      if (repos.data) {
        alert("Image Uploaded Successfully");
      }
      })
      .catch((e) => console.log(e));
    };
	
	 

  return (
    <div>	
    <div id="add_btn_grp_add_event_details" onClick={()=>{addEvent()}}>
    <svg class="add_btn_bdr_add_calender_event">
        <rect id="add_btn_bdr_add_calender_event" rx="7" ry="7" x="0" y="0" width="120" height="40">
        </rect>
    </svg>
    <div id="Add_btn_add_calender_event">
        <span>Add</span>
    </div>
   </div>

	<div id="Scroll_grp_events_pc">
		<div id="add_calender_event_grp">
			<div id="upload_banner_grp_add_add_cale">
				<div id="Upload_Banner_IMG_add_calender">
					<span>Upload Banner Image</span>
				</div>
                <label for="imageProfile">
              <input
                id="imageProfile"
                type="file"
                name="imageProfile"
                hidden
                accept="image/*"
                onChange={(e) => handleImageUpload(e)}
              />
				<img id="upload_imgadd_calender_event" src="./CalanderDashboard/upload_imgadd_calender_event.png" srcset="./CalanderDashboard/upload_imgadd_calender_event.png 1x" />
                </label>

				<img id="img_preview_section_add_calend" src={eventBanner?eventBanner:"./CalanderDashboard/img_preview_section_add_calend.png"}  />
			</div>

			<div id="add_calender_event_loca_grp">
				<input placeholder="Location" class="location_bdr_add_calender_even"  value={eventLocation} onChange={(e)=>setEventLocation(e.target.value)}/>
					
				<div id="Location_lbl_add_calender_even">
					<span>Location</span>
				</div>
			</div>
			<div id="add_calender_event_grp_end_atd">
				<input type="datetime-local" class="end_bdr_add_calender_event" value={eventEndOn} onChange={(e)=>setEventEndOn(e.target.value)}/>
					
				<div id="ends_lbl_add_calender_event">
					<span>Ends At</span>
				</div>
			</div>
			<div id="add_calender_event_grp_cq">
				<input type="datetime-local" class="date_bdr_add_calender_event" value={eventStartOn} onChange={(e)=>setEventStartOn(e.target.value)}/>
					
				<div id="Date_lbl_add_calender_event">
					<span>Start At</span>
				</div>
			</div>
			<div id="add_calender_event_category_gr">
				<select placeholder="Category" class="category_bdr_add_calender_even" value={eventCategory} onChange={(e)=>setEventCategory(e.target.value)}>
                <option value="">---select---</option>
					<option value="Announcements">Announcements</option>
					<option value="Education">Education</option>
					<option value="Competetions">Competetions</option>
					<option value="Entertainment">Entertainment</option>
					<option value="Others">Others</option>
                </select>
					
				<div id="Category_lbl_add_calender_even">
					<span>Category</span>
				</div>
			</div>
			<div id="add_calender_event_description">
				<textarea placeholder="Description" class="descp_bdr_add_calender_event" value={eventDiscription} onChange={(e)=>setEventDiscription(e.target.value)}/>
				
				<div id="Description_lbl_add_calender_e">
					<span>Description</span>
				</div>
			</div>
			<div id="event_name_grp_add_event_detai">
				<input class="event_name_bdr_add_event_detai" value={eventName}  onChange={(e)=>setEventName(e.target.value)}/>
					
				<div id="Event_Name_lbl_add_event_detai">
					<span>Event Name</span>
				</div>
			</div>
			<div id="event_cost_grp_add_event_detai">
				<input class="event_cost_bdr_add_event_detai" value={eventCost} onChange={(e)=>setEventCost(e.target.value)}/>
					
				<div id="Event_Cost_lbl_add_event_detai">
					<span>Event Cost</span>
				</div>
			</div>
			<div id="event_seats_grp_add_event_deta">
				<input class="event_seats_bdr_add_event_deta" value={eventSeats} onChange={(e)=>setEventSeats(e.target.value)}/>
					
				<div id="Event_Seats_lbl_add_event_deta">
					<span>Event Seats</span>
				</div>
			</div>
		</div>
	</div>
    </div>
  )
}

export default AddEventsToCalander
