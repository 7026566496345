import React, { useContext, useState, useEffect,useRef } from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { SocketContext } from "../SocketContext";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicOffIcon from "@mui/icons-material/MicOff";
import "../styles/MeetingRoomUI.css"
import { Assignment, ChatSharp, Phone, PhoneDisabled } from "@material-ui/icons";
import MicIcon from "@mui/icons-material/Mic";
import { io } from "socket.io-client";
import { useSearchParams } from 'react-router-dom';
import Peer from "simple-peer";
import { UserContext } from "./UserContext";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import {ContextProvider} from '../SocketContext';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Link,ListItem,ListItemButton,List,IconButton} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { ListItemIcon } from '@material-ui/core'
import MeetingPopUpUI from "./MeetingPopUpUI";
import NameLabel from "./NameLabel";

const style5 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};




const useStyles = makeStyles((theme) => ({
  video: {
    width: "821px",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        width: "250px",
      },
    },
  },

  uservideo: {
    width: "321px",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        width: "150px",
      },
    },
  },
  gridContainer: {
    justifyContent: "center",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        flexDirection: "column",
      },
    },
  },
  paper: {
    padding: "10px",
    border: "2px solid black",
    margin: "0px",
  },
}));

const socket = io(process.env.REACT_APP_API_URL
  , {
  reconnect:true,
  autoConnect:true,
  reconnectionDelay: 1000,
  reconnectionDelayMax : 5000,
  reconnectionAttempts: Infinity      
}
);

const MeetingRoomUI = () => {
  const {
    me,
    name,
    callAccepted,
    myVideo,
    userVideo,
    callEnded,
    stream,
    call,
    mute,
    setMute,
    videoOff,
    setVideoOff,
    callUser,
    leaveCall,
    response,
    answerCall,
    setResponse,
    msgUser,
    onMyVideo,
    offMyVideo
  } = useContext(SocketContext);
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [idToCall, setIdToCall] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState([]);
  const [status,setStatus] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [ availableMeetings,setAvailableMeetings] = useState([]);
  const [individualMeetings,setIndividualMeetings] = useState([]);
  const [openMeetingModel,setOpenMeetingModel] = useState(false);
  const [meetingTitle,setMeetingTitle] = useState("");
  const [agenda,setAgenda] = useState("");
  const [meetingRemarks,setMeetingRemarks] = useState("");
  const [meetingDateTime,setMeetingDateTime] = useState(new Date().toISOString().substring(0,16));
  const [previousRemarks,setPreviousRemarks] = useState("");
  const [requesterId,setRequesterId]= useState("");
  const [meetingId,setMeetingId] = useState("");
  const [callerName,setCallerName] = useState("");
  const [msg,setMsg] = useState("");
  const [prevResponse,setPrevResponse] = useState("");
  const [otherUserId,setOtherUserId] = useState("");
  const [otherUserName,setOtherUserName] = useState("");
  const [openDrawer,setOpenDrawer] = useState(false);
  const [openDrawer2,setOpenDrawer2] = useState(true);
  const [meetingData,setMeetingData]= useState({})
  const [mainVideo,setMainVideo] = useState(false);
  const checkOnlineStatusTimer = useRef();

  useEffect(() => {
    if(user){
    
      //findOnlineUser();
      getMeetings()
      clearInterval(checkOnlineStatusTimer.current)
        checkOnlineStatusTimer.current = setInterval(() => findOnlineUsers(), 5000);
    //   clearInterval()
    // setInterval(() => findOnlineUser(), 15000);
    }
    
  }, [user]);

  
  useEffect(() => {
   setPrevResponse(prevResponse+"\n"+response)
  }, [response]);

  useEffect(() => {
    
    if(searchParams.get('meetingid')){
      getParticularMeetings(searchParams.get('meetingid'))
    }
    
   }, []);

  

//Getting Perticular meeting by meeting ID
const getParticularMeetings = (id)=>{

  let apiURL = process.env.REACT_APP_API_URL + "/meetings/getmeetings/" + id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          console.log(repos.data)
          openScheduler(repos.data)
          //setIndividualMeetings(repos.data)
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};

 



///To get all meetings available for the logged in user
const getMeetings = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/meetings/getallmeetings/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          // console.log(repos.data)
          setAvailableMeetings(repos.data)
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};


  const findOnlineUsers = () => {
    if(user){

    let apiURL = process.env.REACT_APP_API_URL + "/status/onlinestatus/"+user._id;

    fetch(apiURL)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
        
          setOnlineUsers(repos.data.filter(rec=>(rec.mobile!=user.mobile.substring(user.mobile.length-10))));
        } else alert(repos.message || repos);
      })
      .catch((err) => console.log(err));
  }
      
};

  const isUserOnline = (id,name) => {
    setOpenMeetingModel(false)
    let apiURL = process.env.REACT_APP_API_URL + "/status/isonline/"+id;

    fetch(apiURL)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          
          callUser(repos.data[0].socketId,name)
         
        } else alert( name + " is not Online, please try after sometime!!");
      })
      .catch(() => alert(name + " is not Online, please try after sometime!!"));
  };

  const start = () => {
    recordScreen();

    // let node = document.createElement("p");
    // node.textContent = "Started recording";
    // document.body.appendChild(node);
  };

  const stop = () => {
    mediaRecorder && mediaRecorder.stop();

    // let node = document.createElement("p");
    //  node.textContent = "Stopped recording";
    // document.body.appendChild(node);
  };

  const mergeAudioStreams = (localStream, remoteStreams = false) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasVoice = false;
    let hasRemote = false;

    if (localStream && localStream.getAudioTracks().length > 0) {
      const source1 = context.createMediaStreamSource(localStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source1.connect(voiceGain).connect(destination);
      hasVoice = true;
    }
    
    remoteStreams.forEach((stream, peerId) => {
      if (stream && stream.getAudioTracks().length > 0) {
        const source2 = context.createMediaStreamSource(stream);
        const voiceGain = context.createGain();
        voiceGain.gain.value = 0.7;
        source2.connect(voiceGain).connect(destination);
        hasRemote = true;
      }
    });
    // console.log(hasVoice,hasRemote)
    return hasVoice || hasRemote ? destination.stream.getAudioTracks() : [];
  };

  function recordScreen() {
    let audioTrack, videoTrack, stream;

    [audioTrack] = mergeAudioStreams(myVideo.current.srcObject, [
      userVideo.current.srcObject,
    ]);

    navigator.mediaDevices
      .getDisplayMedia({ audio: true, video: true, preferCurrentTab: true })
      .then((displayStream) => {
        [videoTrack] = displayStream.getVideoTracks();
        stream = new MediaStream([videoTrack, audioTrack]);
        setMediaRecorder(createRecorder(stream));
      })
      .catch((e) => console.log(e));
  }


  function createRecorder(stream) {
    // the stream data is stored in this array
    let recordedChunks = [];

    const mediaRecorderNew = new MediaRecorder(stream);

    mediaRecorderNew.ondataavailable = function(e) {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    };

    mediaRecorderNew.onstop = function() {
      saveFile(recordedChunks);
      recordedChunks = [];
    };
    mediaRecorderNew.start(); // For every 200ms the stream data will be stored in a separate chunk.
    return mediaRecorderNew;
  }

  const closeMeetingModel = () => setOpenMeetingModel(false);

  const setCloseMeetingModel = ()=>{
    setOpenMeetingModel(false);
  }
  

  const ScheduleMeeting = (meetingId,status) =>{
    setOpenMeetingModel(false);
    let apiURL=process.env.REACT_APP_API_URL + "/meetings/updatemeetings/"+ meetingId;
      let apiParams = {
          method:"POST",
          headers:{
            meetingtitle:meetingTitle,
            meetingdatetime:meetingDateTime,
            agenda:encodeURIComponent(agenda),
            remarks:encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+meetingRemarks+"\n"+(previousRemarks?previousRemarks:"")+"\n"),
            status:status,
          },
         
      };
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
          
            alert("Meeting Updated Successfully");
            getMeetings()
          
          
          }else alert(repos.message || repos);
      }) 
      .catch((err) => alert(err));
  
   }

  const openScheduler = (data)=>{
   setMeetingData(data)
    // if(data.requesterId!==user._id){
    //   setIdToCall(data.requesterId)
    //   setCallerName(data.requesterName)
      
    // }else if(data.requesterId===user._id && data.respondentId!==user._id){
    //   setIdToCall(data.respondentId)
    //   setCallerName(data.respondentName)
      
    // }
   
    // setStatus(data.status)
    // setMeetingId(data._id)
    // setMeetingTitle(data.meetingTitle);
    // setRequesterId(data.requesterId);
    // setMeetingDateTime(data.meetingDateTime.substring(0,16));
    // setAgenda(data.agenda);
    // setPreviousRemarks(data.remarks);
    // setMeetingRemarks("");
    setOpenMeetingModel(true)
  }



  const Chats = ()=>{
    msgUser(otherUserId,msg,otherUserName)
  }





  // const RetrieveResume = (data)=>{

  //   let apiURL=process.env.REACT_APP_API_URL + "/applicants/saveResumeUrl/" + applicantId;
  //   let apiParams = {
  //       method:"POST",
  //       headers:{
  //         resumeurl:data.url,
  //       }
  // }
  // fetch(apiURL, apiParams)
  // .then((res)=> (res.status===200 ? res.json() : res.text()))
  // .then((repos)=> {
  //     if (repos.data) {
  //     setResumeUrl(data.url)
  //     }
  //   })
  // // }
  // function blobToFile(theBlob, fileName){
  //   //A Blob() is almost a File() - it's just missing the two properties below which we will add
  //   theBlob.lastModifiedDate = new Date();
  //   theBlob.name = fileName;
  //   return theBlob;
  // }

  function saveFile(recordedChunks) {
    const blob = new Blob(recordedChunks, {
      type: "video/mp4",
    });

    // let confirm = window.confirm(
    //   "Do you want to save in google Drive else it will be saved in your local system ??"
    // );

    // if (!confirm) {
      var file = window.prompt("Enter File Name") //the file
      let type = blob.type;
const buffer =  blob.arrayBuffer();
let bytes = new Int8Array(buffer);
let newLink = saveByteArray(file,blob );
          newLink.link.click();
// }else {
//       let filename = window.prompt("Enter File Name", ".mp4");
//       var reader = new FileReader(); //this for convert to Base64
//       reader.readAsDataURL(blob); //start conversion...
//       reader.onload = function() {
//         //.. once finished..
//         var rawLog = reader.result.split(",")[1]; //extract only thee file data part
//         var dataSend = {
//           dataReq: { data: rawLog, name: filename, type: blob.type },
//           fname: "uploadFilesToGoogleDrive",
//         }; //preapre info to send to API
//         fetch(
//           process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
//           { method: "POST", body: JSON.stringify(dataSend) }
//         ) //send to Api
//           .then((res) => res.json())
//           .then((data) => {
//             console.log(data);
//             if (data) {
//               alert("Video Saved to google drive Successfully!!!");
//             }
//           })
//           .catch((e) => console.log(e)); // Or Error in console
//       };
//     }
  }

  function saveByteArray(reportName, byte) {
    let blob = byte;
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
  
    let fileName = reportName;
    link.download = fileName;
  
    let newLink = {
      name: fileName,
      link: link,
    };
    return newLink;
  }



  return (
   <div>
 <div id="meeting_room_pc">
	{/* <img id="meeting_room_pc_bg_" src="meeting_room_pc_bg_.png" srcset="meeting_room_pc_bg_.png 1x, meeting_room_pc_bg_@2x.png 2x" /> */}
		
  {/* {stream && ( */}
          {/* <Paper className={classes.paper}> */}
            {/* <Grid item xs={12} md={12}> */}
            <div id="grp_vid_cam_2">
		<video id="vid_cam_2" playsInline muted ref={callAccepted && !callEnded ?setVideoOff===true ? null:myVideo:null} autoPlay className={classes.uservideo} onClick={()=>setMainVideo(true)}/>
    <NameLabel callAccepted={callAccepted} callEnded={callEnded} name={name} size={"small"}/>
		{/* <div id="vid_cam_lbl_2">
			<span>{callAccepted && !callEnded?user.name:name}</span>
		</div> */}
	</div>
  {/* </Grid> */}
          {/* </Paper> */}
        {/* )} */}

  {/* {callAccepted && !callEnded && ( */}
  {/* {stream && ( */}
          {/* <Paper className={classes.paper}> */}
            {/* <Grid item xs={12} md={12}> */}
  	
		<div id="cam_2_grp_with_btn">
    <NameLabel callAccepted={callAccepted} callEnded={callEnded} name={name} size={"big"}/>
    {/* <div id="vid_cam_lbl_1">
			<span>{callAccepted && !callEnded?name:user.name}</span>
		</div> */}
			<video id="vid_cam_1" playsInline muted={callAccepted && !callEnded?false:true} ref={callAccepted && !callEnded? userVideo:myVideo} autoPlay className={classes.video} />
      <div style={{textAlign:"-webkit-center",position:"relative",top:"calc(100% - 60px)",display:"inline-flex",flexDirection: "row"}}>
      {mute ? (	
			<div id="group_mic_pc" fullWidth
      //  style={{left:callAccepted && !callEnded  ?"":"42%",top:callAccepted && !callEnded  ?"":"calc(100% - 60px)"}}
            value={mute}
            title="Mic Off"
            onClick={() => setMute(false)}>
				<svg class="mic_border_pc">
					<rect id="mic_border_pc" rx="5" ry="5" x="0" y="0" width="60" height="40">
					</rect>
				</svg>
				<img id="micro_on" src="microphone-off.png" />	
			</div>  ) : (
        <div id="group_mic_pc" fullWidth
        //  style={{ left:callAccepted && !callEnded  ?"":"42%",top:callAccepted && !callEnded  ?"":"calc(100% - 60px)"}}
            value={mute}
            title="Mic On"
            onClick={() => setMute(true)}>
				<svg class="mic_border_pc">
					<rect id="mic_border_pc" rx="5" ry="5" x="0" y="0" width="60" height="40">
					</rect>
				</svg>
				<img id="micro_on" src="micro_on.png" srcset="micro_on.png 1x, micro_on@2x.png 2x" />	
			</div> )}

      {videoOff === true? (
			<div id="group_cam_pc" value={videoOff}
            // style={{ left:callAccepted && !callEnded  ?"":"52%",top:callAccepted && !callEnded  ?"":"calc(100% - 60px)"}}
            title="Video Off"
            onClick={() => setVideoOff(false)} >
				<svg class="cam_border_pc">
					<rect id="cam_border_pc" rx="5" ry="5" x="0" y="0" width="60" height="40">
					</rect>
				</svg>
				<img id="cam_img_pc" src="video-camera-off.png" />	
			</div> ) : (
        <div id="group_cam_pc" title="Video On"
        //  style={{ left:callAccepted && !callEnded  ?"":"52%",top:callAccepted && !callEnded  ?"":"calc(100% - 60px)"}}
            fullWidth
            value={videoOff}
            onClick={() => setVideoOff(true)} >
				<svg class="cam_border_pc">
					<rect id="cam_border_pc" rx="5" ry="5" x="0" y="0" width="60" height="40">
					</rect>
				</svg>
				<img id="cam_img_pc" src="cam_img_pc.png" srcset="cam_img_pc.png 1x, cam_img_pc@2x.png 2x" />	
			</div>)}

      {callAccepted && !callEnded ? (
			<div id="grp_dis_con_pc" onClick={leaveCall} title="Hang Up">
				<svg class="cutcall_bdr_pc">
					<rect id="cutcall_bdr_pc" rx="5" ry="5" x="0" y="0" width="60" height="40">
					</rect>
				</svg>
				<img id="cutcall_img_pc" src="call-end-mob.png" srcset="call-end-mob.png 1x, call-end-mob@2x.png 2x" />
					
			</div>
      ) : ""}

      {callAccepted && !callEnded ? (
			<div id="grp_rec_start_pc_1"  title="Start Recording"
          fullWidth
          onClick={() => start()}>
				<svg class="rec_brd_pc_1">
					<rect id="rec_brd_pc_1" rx="5" ry="5" x="0" y="0" width="60" height="40">
					</rect>
				</svg>
				<img id="rec_img_pc_1" src="rec_img_pc_1.png" srcset="rec_img_pc_1.png 1x, rec_img_pc_1@2x.png 2x" />	
			</div>):""}
      {callAccepted && !callEnded ? (  
			<div id="grp_rec_stop_grp1"  title="Stop Recording"
          fullWidth
          onClick={() => stop()}>
				<svg class="rec_stop_img_1">
					<rect id="rec_stop_img_1" rx="5" ry="5" x="0" y="0" width="60" height="40">
					</rect>
				</svg>
				<img id="rec_stop_img_1_cq" src="rec_stop_img_1_cq.png" srcset="rec_stop_img_1_cq.png 1x, rec_stop_img_1_cq@2x.png 2x" />	
			</div>
      ):""}
      </div>
		</div>
	
  {/* </Grid> */}
          {/* </Paper> */}
  {/* )} */}

{/* Online Users*/}

{callAccepted && !callEnded ? 
<div id="online_user_grp_card">
		<img id="online_user_bg_pc_img" src="online_user_bg_pc_img.png" srcset="online_user_bg_pc_img.png 1x, online_user_bg_pc_img@2x.png 2x" />
			
		<div id="Group_online_user_border">
			<svg class="Online_user_bdr">
				<rect id="Online_user_bdr" rx="16.5" ry="16.5" x="0" y="0" width="267" height="33">
				</rect>
			</svg>
			<div id="online_user_lbl_pc">
				<span>Online Users</span>
			</div>
		</div>
		<div id="online_user_scroll">
    {(onlineUsers||[]).map((ou) => (
			<div id="online_user_per_1_grp"  onClick={() =>{ setOtherUserId(ou.socketId);setOtherUserName(ou.name);callUser(ou.socketId,ou.name)}}
              title="Call">
				<img id="user_1_img_tag" src="user_pfp_profile_page.png" />
					
				<button id="btn_user_grp">
					<svg class="user_bdr_btn_call">
						<rect id="user_bdr_btn_call" rx="5" ry="5" x="0" y="0" width="62" height="21">
						</rect>
					</svg>
					<div id="user_lbl_call">
						<span><marquee scrollamount="4">{user.name!=ou.name?ou.name:""}</marquee></span>
					</div>
				</button>
			</div>
      ))}
		</div>
	</div>: <div id="online_user_extended_pc_new">
		<img id="online_user_bg_pc_img_new" src="online_user_bg_pc_img_new.png" srcset="online_user_bg_pc_img_new.png 1x, online_user_bg_pc_img_new@2x.png 2x" />
			
		<svg class="Online_user_bdr_new">
			<rect id="Online_user_bdr_new" rx="16.5" ry="16.5" x="0" y="0" width="267" height="33">
			</rect>
		</svg>
		<div id="online_user_lbl_pc_new">
			<span>Online Users</span>
		</div>
		<div id="online_user_scroll_new">
    {(onlineUsers||[]).map((ou) => (		
			<div id="person_1_bdr_user_new">
      <img id="user_1_img_tag_new" src="user_pfp_profile_page.png" />
      
				<div id="btn_grp_user_online_new"  onClick={() =>{ setOtherUserId(ou.socketId);setOtherUserName(user.name);callUser(ou.socketId,user.name)}}>
       
					<svg class="user_bdr_btn_call_new">
						<rect id="user_bdr_btn_call_new" rx="5" ry="5" x="0" y="0" width="62" height="21">
						</rect>
					</svg>
          <div id="user_lbl_call_cv_new">
					<span><marquee scrollamount="4">{user.name!=ou.name?ou.name:""}</marquee></span>
				  </div>
					<div id="user_lbl_call_new" >
						<span>Call</span>
					</div>
				</div>
			
			</div>
      ))}
		</div>
	</div>
  }

{/* Chats with Connected Member*/}
{callAccepted && !callEnded ? <div id="grp_chat_1_pc">
		<img id="chat_pc_bg" src="chat_pc_bg.png" srcset="chat_pc_bg.png 1x, chat_pc_bg@2x.png 2x" />
			
		<input placeholder="Type...." class="chat_msg_input_" />
		
		<div id="user_1_txt_grp">
			<svg class="chat_1_design_" viewBox="3493.508 306.913 214.246 45.469">
				<path id="chat_1_design_" d="M 3493.50830078125 306.9134216308594 C 3546.449462890625 306.9134216308594 3707.75439453125 306.9134216308594 3707.75439453125 306.9134216308594 L 3707.75439453125 352.382080078125 L 3534.041259765625 352.382080078125 L 3503.98828125 352.382080078125 L 3503.98828125 317.3764343261719 L 3493.50830078125 306.9134216308594 Z">
				</path>
			</svg>
			<div id="chat_1_time">
				<span>12:00</span>
			</div>
			<div id="chat_1_username">
				<span>Mohammed Zaid</span>
			</div>
			<div id="chat_1_input">
				<span>hiiii</span>
			</div>
			<img id="online_user_pf_1_img" src="online_user_pf_1_img.png" srcset="online_user_pf_1_img.png 1x, online_user_pf_1_img@2x.png 2x" />
				
		</div>
		<div id="user_2_text_grp">
			<svg class="chat_2_design" viewBox="3493.508 306.913 219.256 45.469">
				<path id="chat_2_design" d="M 3712.7646484375 306.9134216308594 C 3658.585693359375 306.9134216308594 3493.50830078125 306.9134216308594 3493.50830078125 306.9134216308594 L 3493.50830078125 352.382080078125 L 3671.28369140625 352.382080078125 L 3702.03955078125 352.382080078125 L 3702.03955078125 317.3764343261719 L 3712.7646484375 306.9134216308594 Z">
				</path>
			</svg>
			<div id="chat_2_time">
				<span>12:00</span>
			</div>
			<div id="chat_2_username">
				<span>Affan</span>
			</div>
			<div id="chat_2_txt_input">
				<span>Hloooo</span>
			</div>
			<img id="online_user_pf_2_img" src="online_user_pf_2_img.png" srcset="online_user_pf_2_img.png 1x, online_user_pf_2_img@2x.png 2x" />
				
		</div>
		<img id="send-message" src="send-message.png" srcset="send-message.png 1x, send-message@2x.png 2x" />
			
	</div>:""
  }

  <button title="Meetings" id="meeting_fly_pop_up_grp">
		<svg class="meeting_fly_pop_up_rec">
			<ellipse id="meeting_fly_pop_up_rec" rx="30" ry="30" cx="30" cy="30">
			</ellipse>
		</svg>
		<img id="meeting_fly_pop_up" src="meeting_fly_pop_up.png" srcset="meeting_fly_pop_up.png 1x, meeting_fly_pop_up@2x.png 2x" />
	</button>
	<div id="meetings_scroll_grp">
  {availableMeetings.map((off,idx) => (
    <div style={{left:(idx*175).toString()+"px",top:"0px",position:"relative"}}>

			<div id="Group_indiv_meetings_pt" onClick={()=>openScheduler(off)}>
				<svg class="meeting_border_om">
					<rect id="meeting_border_om" rx="14" ry="14" x="0" y="0" width="165" height="113">
					</rect>
				</svg>
				<div id="Web_Development_lbl_val_om">
					<span><marquee>{off.meetingTitle?off.meetingTitle:""}</marquee></span>
				</div>
				<div id="Cancelled_lbl_val_om">
					<span>{off.status}</span>
				</div>
				<div id="date_lbl_val_om">
					<span>{off.meetingDateTime?off.meetingDateTime.split("T")[0]:""}</span>
				</div>
				<div id="tine_lbl_val_om">
					<span>{off.meetingDateTime?off.meetingDateTime.split("T")[1].slice(0,5):0}</span>
				</div>
				<img id="date_img_pop_om" src="date_img_pop.png" srcset="date_img_pop.png 1x, date_img_pop@2x.png 2x" />
					
				<img id="status_img_pop_om" src="status_img_pop.png" srcset="status_img_pop.png 1x, status_img_pop@2x.png 2x" />
					
				<img id="time_img_pop_om" src="time_img_pop.png" srcset="time_img_pop.png 1x, time_img_pop@2x.png 2x" />
      </div>
      </div>

      ))}
    </div>
    <MeetingPopUpUI  openMeeetingPopUp = {openMeetingModel} meetingData={meetingData} closeMeetingPopup = {()=>{setOpenMeetingModel(false); getMeetings()}}/>
</div>
   </div>
  );
};

export default MeetingRoomUI;
