import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../styles/MyConnectionsAndRequestsUI.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import MyConnectionsUI from './MyConnectionsUI';
import Switch from '@mui/material/Switch';
import MyRequestsUI from './MyRequestsUI';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MyConnectionsAndRequestsUI = () => {

//UseState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [toggle,setToggle] = useState(false);
const [ allStatus,setAllStatus] = useState("");
const [requests,setRequests]= useState([]);
const [selectedCommunity,setSelectedCommunity] = useState([])
const [communities,setCommunities] = useState([]);


 //UseEffect
 useEffect(()=>{
   
 
   if(user){
   
    // getStatus()
    // getRequests()
    // getCommunities()
   }
},[user])
  
const ConnectionReq = (id,status)=>{  
if(selectedCommunity==="undefined"){
  setSelectedCommunity(JSON.parse([]))
}
    let apiURL=process.env.REACT_APP_API_URL + "/connection/updateconnection/"+id;
    let apiParams = {
        method:"POST",
        headers:{
          status:status,
          user2selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        },
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        alert("Action Updated Successfully!!");
          
      //props.showAlert("Job Added Successfully","success")
         setTimeout(() => {
          // getStatus()
          // getRequests()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
  
}


const getStatus = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          console.log(repos.data)
          setAllStatus(repos.data)
          
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};

//Converting Image
function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  var base64Flag = "data:image/jpeg;base64,";
  return base64Flag + window.btoa(binary);
}


const getRequests = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getrequests/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          console.log(repos.data)
          setRequests(repos.data)
          
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};


//Get my Communities
const getCommunities = ()=>{

  let apiURL=process.env.REACT_APP_API_URL + "/community/getmycommunity/"+user._id; 

  fetch(apiURL)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
          console.log(repos.data)
        setCommunities(repos.data)
      
      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}




  return (
    <>
    <div>
   
  <div id="my_connection-and-requests">
	<img id="ConnectionAndRequestBg" src="n_.png" srcset="n_.png 1x, n_@2x.png 2x" />
		
	<header>
	<div id="My_Connections_lbl">
		<span>My Connections</span>
	</div>
	</header>
	<svg class="Line_1_border_cr" viewBox="0 0 308 1">
		<path id="Line_1_border_cr" d="M 0 0 L 308 0">
		</path>
	</svg>
	   
	<div className='switch_cr_'>
	<Switch id='switch_cr_' onChange={(e)=>setToggle(e.target.checked)} checked={toggle} color="default" />
	</div>
	<div id="My_Request_lbl_">
		<span>My Request</span>
	</div>
	

{toggle===false ? <MyConnectionsUI />	:<MyRequestsUI /> }
</div>
    </div>
    </>
  )
}

export default MyConnectionsAndRequestsUI
