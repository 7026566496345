import React, { useEffect, useState, useContext} from 'react'
import "../../styles/CalanderTasksMob.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";

const CalanderTasksMob = (props) => {
	useEffect(()=>{

	},[props.data])

  return (
    <div id="calender_page_Task_mob">
	<div id="calender_page_Task_mob_grp">
		<svg class="calender_page_Task_mob_bdr">
			<rect id="calender_page_Task_mob_bdr" rx="22" ry="22" x="0" y="0" width="100%" height="796">
			</rect>
		</svg>
		<div id="right_menu_calender_page_Task_">
			<div id="mobtrask_mob_lbl_calender" onClick={()=>props.selectedTab("alarms")}>
				<span>Alarms</span>
			</div>
			<div id="mobMeetings_lbl_headingcalende" onClick={()=>props.selectedTab("meetings")}>
				<span>Meetings</span>
			</div>
			<div id="Events_lbl_calender_mob" onClick={()=>props.selectedTab("events")}>
				<span>Events</span>
			</div>
			<div id="underline_task_mob">
				<div id="mobTasks_lbl_">
					<span>Tasks</span>
				</div>
				<svg class="mobLine_29" viewBox="0 0 1 32">
					<path id="mobLine_29" d="M 0 0 L 0 32">
					</path>
				</svg>
			</div>
		</div>
		<div id="task_mobc_cal_mob">
		{props.data.map((tsk)=>(
			<div id="card_1_task_mob">
				<div id="calender_page_mob_lbl">
					<span>{tsk.title}</span>
				</div>
				<div id="calender_time_mob">
					<span>{tsk.startsAt && new Date(tsk.startsAt).getHours().toString().padStart(2,"0")+":"+new Date(tsk.startsAt).getMinutes().toString().padStart(2,"0")} - {tsk.endsAt && new Date(tsk.endsAt).getHours().toString().padStart(2,"0")+":"+new Date(tsk.endsAt).getMinutes().toString().padStart(2,"0")} IST</span>
				</div>
				<svg class="mobcaleneder_mode_calender">
					<rect id="mobcaleneder_mode_calender" rx="0" ry="0" x="0" y="0" width="3.36" height="16.8">
					</rect>
				</svg>
				<svg class="mobactive_calender_pagemobcale">
					<ellipse id="mobactive_calender_pagemobcale" rx="5.599999904632568" ry="5.599999904632568" cx="5.599999904632568" cy="5.599999904632568">
					</ellipse>
				</svg>
				<img title="Urgent" id="immediate_1_calender_mob" src="./CalanderDashboard/immediate_1_calender_mob.png" srcset="./CalanderDashboard/immediate_1_calender_mob.png 1x" />
					
				<svg class="arcane_toptaskcalender_mob" viewBox="0 0 249.76 1">
					<path id="arcane_toptaskcalender_mob" d="M 0 0 L 249.7599945068359 0">
					</path>
				</svg>
			</div>
			))
		}	
		</div>
	</div>
</div>
  )
}

export default CalanderTasksMob
