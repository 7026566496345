import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioSmallOffCardMob.css';
import { UserContext } from '../UserContext';


const MyPortfolioSmallOffCardMob = (props) => {
	const {user} = useContext(UserContext);
	 


	const handleLike = (offId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/offerings/updatetrophy/"+offId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
		  };
	

  return (
   
	<div class="OFFM_small_4_trending_com_of">
	<svg class="OFFM_all_bdr_small_4_trending_com">
		<rect id="OFFM_all_bdr_small_4_trending_com" rx="10" ry="10" x="0" y="0" width="160" height="197">
		</rect>
	</svg>
	<img id="OFFM_Image_small_4_trendin" src={props.data.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultOfferingsImageUrl.split('/')[5]):"./MyPortfolio/Offerings/Image_small_4_trending_off_PC.png"} />
		
	<svg class="OFFM_bdr_amount_small_4_trending_co">
		<rect id="OFFM_bdr_amount_small_4_trending_co" rx="10.5" ry="10.5" x="0" y="0" width="45" height="21">
		</rect>
	</svg>
	<div id="OFFM_n_9_small_4_trending_com">
		<span>{props.data.offeredcurrency && JSON.parse(props.data.offeredcurrency).symbol} {props.data.budget}</span>
	</div>
	<div id="OFFM_NikeCourt_Air_small_4_trending">
		<span>{props.data.offeringTitle}</span>
	</div>
	<div id="OFFM_Single_Tour_small_4_trending">
		<span>{( props.data.deliveryDuration ? props.data.deliveryDuration:"")} Hours | {props.data.deliveryLocation}</span>
	</div>
	<svg class="OFFM_number_small_4_trending">
		<rect id="OFFM_number_small_4_trending" rx="0" ry="0" x="0" y="0" width="24" height="13">
		</rect>
	</svg>
	<svg class="OFFM_trohpie_bdr_small_4_trendi">
		<ellipse id="OFFM_trohpie_bdr_small_4_trendi" rx="12" ry="12" cx="12" cy="12">
		</ellipse>
	</svg>
	<img id="OFFM_trophy_small_4_trending_com_of" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(props.data._id)} />
		
	<div id="OFFM_n_234_small_4_trending_com_off">
		<span>{(JSON.parse(props.data.trophies)).length}</span>
	</div>
	<svg class="OFFM_share_bdr_small_4_trending_com">
		<ellipse id="OFFM_share_bdr_small_4_trending_com" rx="12.5" ry="12.5" cx="12.5" cy="12.5">
		</ellipse>
	</svg>
	<img id="OFFM_share_2_com_of" src="./MyPortfolio/Offerings/share_2_reqoffevent_off_PC.png" srcset="./MyPortfolio/Offerings/share_2_reqoffevent_off_PC.png 1x" />
		
</div>
  )
}

export default MyPortfolioSmallOffCardMob
