import React, { useContext, useState, useEffect,useRef } from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import {SocketContext} from '../../SocketContext';
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicOffIcon from "@mui/icons-material/MicOff";
import "./../../styles/MeetingRoomMobUI.css"
import { Assignment, ChatSharp, Phone, PhoneDisabled } from "@material-ui/icons";
import MicIcon from "@mui/icons-material/Mic";
import { io } from "socket.io-client";
import { useSearchParams } from 'react-router-dom';
import Peer from "simple-peer";
import { UserContext } from ".././UserContext";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DataGrid, GridToolbar} from '@mui/x-data-grid'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Link,ListItem,ListItemButton,List,IconButton} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { ListItemIcon } from '@material-ui/core'
import MeetingPopUpUI from '../MeetingPopUpUI';
import MeetingRoomOnlineUsersSlide from "./MeetingRoomOnlineUsersSlide";
import MeetingRoomScheduleSlide from "./MeetingRoomScheduleSlide";
import NameLabel from "../NameLabel";

const style5 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};


const useStyles = makeStyles((theme) => ({
  videomob: {
     width: "821px",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        width: "325px",
      },
    },
  },

  uservideomob: {
    width: "321px",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        width: "325px",
      },
    },
  },
  gridContainer: {
    justifyContent: "center",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        flexDirection: "column",
      },
    },
  },
  papermob: {
    height:"100%",
    padding: "10px",
    border: "2px solid black",
    margin: "0px",
  },
}));

const socket = io(process.env.REACT_APP_API_URL
  , {
  reconnect:true,
  autoConnect:true,
  reconnectionDelay: 1000,
  reconnectionDelayMax : 5000,
  reconnectionAttempts: Infinity      
}
);

const MeetingRoomMobUI = () => {

  const {
    me,
    name,
    callAccepted,
    myVideo,
    userVideo,
    callEnded,
    stream,
    call,
    mute,
    setMute,
    videoOff,
    setVideoOff,
    callUser,
    leaveCall,
    response,
    answerCall,
    setResponse,
    msgUser
  } = useContext(SocketContext);
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [idToCall, setIdToCall] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState([]);
  const [status,setStatus] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [ availableMeetings,setAvailableMeetings] = useState([]);
  const [individualMeetings,setIndividualMeetings] = useState([]);
  const [openMeetingModel,setOpenMeetingModel] = useState(false);
  const [meetingTitle,setMeetingTitle] = useState("");
  const [agenda,setAgenda] = useState("");
  const [meetingRemarks,setMeetingRemarks] = useState("");
  const [meetingDateTime,setMeetingDateTime] = useState(new Date().toISOString().substring(0,16));
  const [previousRemarks,setPreviousRemarks] = useState("");
  const [requesterId,setRequesterId]= useState("");
  const [meetingId,setMeetingId] = useState("");
  const [callerName,setCallerName] = useState("");
  const [msg,setMsg] = useState("");
  const [prevResponse,setPrevResponse] = useState("");
  const [otherUserId,setOtherUserId] = useState("");
  const [otherUserName,setOtherUserName] = useState("");
  const [openDrawer,setOpenDrawer] = useState(false);
  const [openDrawer2,setOpenDrawer2] = useState(true);
  const [meetingData,setMeetingData]= useState({})
  const [onlineUserSlide,setOnlineUserSlide] = useState(false);
  const [scheduleSlide,setScheduleSlide] = useState(false);
  const checkOnlineStatusTimer = useRef();

  useEffect(() => {
    if(user){
      clearInterval(checkOnlineStatusTimer.current)
        checkOnlineStatusTimer.current = setInterval(() => findOnlineUsers(), 5000);
      getMeetings()
    }
    
  }, [user]);

  
  useEffect(() => {
   setPrevResponse(prevResponse+"\n"+response)
  }, [response]);

  useEffect(() => {
    
    if(searchParams.get('meetingid')){
      getParticularMeetings(searchParams.get('meetingid'))
    }
   }, []);

  

//Getting Perticular meeting by meeting ID
const getParticularMeetings = (id)=>{

  let apiURL = process.env.REACT_APP_API_URL + "/meetings/getmeetings/" + id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          console.log(repos.data)
          openScheduler(repos.data)
          //setIndividualMeetings(repos.data)
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};

 



///To get all meetings available for the logged in user
const getMeetings = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/meetings/getallmeetings/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          console.log(repos.data)
          setAvailableMeetings(repos.data)
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};


  const findOnlineUsers = () => {
    if(user){

    let apiURL = process.env.REACT_APP_API_URL + "/status/onlinestatus/"+user._id;

    fetch(apiURL)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOnlineUsers(repos.data.filter(rec=>(rec.mobile!=user.mobile.substring(user.mobile.length-10))));
        } else alert(repos.message || repos);
      })
      .catch((err) => console.log(err));
  }
      
};

  const isUserOnline = (id,name) => {
    setOpenMeetingModel(false)
    let apiURL = process.env.REACT_APP_API_URL + "/status/isonline/"+id;

    fetch(apiURL)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          
          callUser(repos.data[0].socketId,name)
         
        } else alert( name + " is not Online, please try after sometime!!");
      })
      .catch(() => alert(name + " is not Online, please try after sometime!!"));
  };

  const start = () => {
    recordScreen();

    // let node = document.createElement("p");
    // node.textContent = "Started recording";
    // document.body.appendChild(node);
  };

  const stop = () => {
    mediaRecorder && mediaRecorder.stop();

    // let node = document.createElement("p");
    //  node.textContent = "Stopped recording";
    // document.body.appendChild(node);
  };

  const mergeAudioStreams = (localStream, remoteStreams = false) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasVoice = false;
    let hasRemote = false;

    if (localStream && localStream.getAudioTracks().length > 0) {
      const source1 = context.createMediaStreamSource(localStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source1.connect(voiceGain).connect(destination);
      hasVoice = true;
    }
    
    remoteStreams.forEach((stream, peerId) => {
      if (stream && stream.getAudioTracks().length > 0) {
        const source2 = context.createMediaStreamSource(stream);
        const voiceGain = context.createGain();
        voiceGain.gain.value = 0.7;
        source2.connect(voiceGain).connect(destination);
        hasRemote = true;
      }
    });
    // console.log(hasVoice,hasRemote)
    return hasVoice || hasRemote ? destination.stream.getAudioTracks() : [];
  };

  function recordScreen() {
    let audioTrack, videoTrack, stream;

    [audioTrack] = mergeAudioStreams(myVideo.current.srcObject, [
      userVideo.current.srcObject,
    ]);

    navigator.mediaDevices
      .getDisplayMedia({ audio: true, video: true, preferCurrentTab: true })
      .then((displayStream) => {
        [videoTrack] = displayStream.getVideoTracks();
        stream = new MediaStream([videoTrack, audioTrack]);
        setMediaRecorder(createRecorder(stream));
      })
      .catch((e) => console.log(e));
  }


  function createRecorder(stream) {
    // the stream data is stored in this array
    let recordedChunks = [];

    const mediaRecorderNew = new MediaRecorder(stream);

    mediaRecorderNew.ondataavailable = function(e) {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    };

    mediaRecorderNew.onstop = function() {
      saveFile(recordedChunks);
      recordedChunks = [];
    };
    mediaRecorderNew.start(); // For every 200ms the stream data will be stored in a separate chunk.
    return mediaRecorderNew;
  }

  const closeMeetingModel = () => setOpenMeetingModel(false);

  const setCloseMeetingModel = ()=>{
    setOpenMeetingModel(false);
  }
  

  const ScheduleMeeting = (meetingId,status) =>{
    setOpenMeetingModel(false);
    let apiURL=process.env.REACT_APP_API_URL + "/meetings/updatemeetings/"+ meetingId;
      let apiParams = {
          method:"POST",
          headers:{
            meetingtitle:meetingTitle,
            meetingdatetime:meetingDateTime,
            agenda:encodeURIComponent(agenda),
            remarks:encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+meetingRemarks+"\n"+(previousRemarks?previousRemarks:"")+"\n"),
            status:status,
          },
         
      };
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
          
            alert("Meeting Updated Successfully");
            getMeetings()
          
          
          }else alert(repos.message || repos);
      }) 
      .catch((err) => alert(err));
  
   }

  const openScheduler = (data)=>{
   setMeetingData(data)
    // if(data.requesterId!==user._id){
    //   setIdToCall(data.requesterId)
    //   setCallerName(data.requesterName)
      
    // }else if(data.requesterId===user._id && data.respondentId!==user._id){
    //   setIdToCall(data.respondentId)
    //   setCallerName(data.respondentName)
      
    // }
   
    // setStatus(data.status)
    // setMeetingId(data._id)
    // setMeetingTitle(data.meetingTitle);
    // setRequesterId(data.requesterId);
    // setMeetingDateTime(data.meetingDateTime.substring(0,16));
    // setAgenda(data.agenda);
    // setPreviousRemarks(data.remarks);
    // setMeetingRemarks("");
    setOpenMeetingModel(true)
  }



  const Chats = ()=>{
    msgUser(otherUserId,msg,otherUserName)
  }





  // const RetrieveResume = (data)=>{

  //   let apiURL=process.env.REACT_APP_API_URL + "/applicants/saveResumeUrl/" + applicantId;
  //   let apiParams = {
  //       method:"POST",
  //       headers:{
  //         resumeurl:data.url,
  //       }
  // }
  // fetch(apiURL, apiParams)
  // .then((res)=> (res.status===200 ? res.json() : res.text()))
  // .then((repos)=> {
  //     if (repos.data) {
  //     setResumeUrl(data.url)
  //     }
  //   })
  // // }
  // function blobToFile(theBlob, fileName){
  //   //A Blob() is almost a File() - it's just missing the two properties below which we will add
  //   theBlob.lastModifiedDate = new Date();
  //   theBlob.name = fileName;
  //   return theBlob;
  // }

  function saveFile(recordedChunks) {
    const blob = new Blob(recordedChunks, {
      type: "video/mp4",
    });

    // let confirm = window.confirm(
    //   "Do you want to save in google Drive else it will be saved in your local system ??"
    // );

    // if (!confirm) {
      var file = window.prompt("Enter File Name") //the file
      let type = blob.type;
const buffer =  blob.arrayBuffer();
let bytes = new Int8Array(buffer);
let newLink = saveByteArray(file,blob );
          newLink.link.click();
// }else {
//       let filename = window.prompt("Enter File Name", ".mp4");
//       var reader = new FileReader(); //this for convert to Base64
//       reader.readAsDataURL(blob); //start conversion...
//       reader.onload = function() {
//         //.. once finished..
//         var rawLog = reader.result.split(",")[1]; //extract only thee file data part
//         var dataSend = {
//           dataReq: { data: rawLog, name: filename, type: blob.type },
//           fname: "uploadFilesToGoogleDrive",
//         }; //preapre info to send to API
//         fetch(
//           process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
//           { method: "POST", body: JSON.stringify(dataSend) }
//         ) //send to Api
//           .then((res) => res.json())
//           .then((data) => {
//             console.log(data);
//             if (data) {
//               alert("Video Saved to google drive Successfully!!!");
//             }
//           })
//           .catch((e) => console.log(e)); // Or Error in console
//       };
//     }
  }

  function saveByteArray(reportName, byte) {
    let blob = byte;
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
  
    let fileName = reportName;
    link.download = fileName;
  
    let newLink = {
      name: fileName,
      link: link,
    };
    return newLink;
  }



  return (
   <div>
<div id="Meeting_room_cam_mob">
{/* Chats with Connected Member*/}
{callAccepted && !callEnded ? 
	<div id="chat_full_grp_mob">
		<img id="chat_bg_mob_" src="chat_bg_mob_.png" srcset="chat_bg_mob_.png 1x" />
			
		<div id="user_2_text_grp_mob">
			<svg class="chat_2_design_mob" viewBox="3493.508 306.913 250.082 41.683">
				<path id="chat_2_design_mob" d="M 3743.59033203125 306.9134216308594 C 3681.79443359375 306.9134216308594 3493.50830078125 306.9134216308594 3493.50830078125 306.9134216308594 L 3493.50830078125 348.5960693359375 L 3696.27734375 348.5960693359375 L 3731.357421875 348.5960693359375 L 3731.357421875 316.5052185058594 L 3743.59033203125 306.9134216308594 Z">
				</path>
			</svg>
			<div id="chat_2_time_mob">
				<span>12:00</span>
			</div>
			<div id="chat_2_username_mob">
				<span>Affan</span>
			</div>
			<div id="chat_2_txt_input_mob">
				<span>Hiiii</span>
			</div>
			<img id="online_user_pf_2_img_mob" src="online_user_pf_2_img.png" srcset="online_user_pf_2_img.png 1x" />	
		</div>

		<div id="user_1_txt_grp_mob">
			<svg class="chat_1_design_mob" viewBox="3493.508 306.913 244.864 41.683">
				<path id="chat_1_design_mob" d="M 3493.50830078125 306.9134216308594 C 3554.01513671875 306.9134216308594 3738.3720703125 306.9134216308594 3738.3720703125 306.9134216308594 L 3738.3720703125 348.5960693359375 L 3539.833984375 348.5960693359375 L 3505.48583984375 348.5960693359375 L 3505.48583984375 316.5052185058594 L 3493.50830078125 306.9134216308594 Z">
				</path>
			</svg>
			<div id="chat_1_time_mob">
				<span>12:00</span>
			</div>
			<div id="chat_1_username_mob">
				<span>Affan</span>
			</div>
			<div id="chat_1_input_mob">
				<span>Hlo</span>
			</div>
			<img id="online_user_pf_1_img_mob" src="online_user_pf_1_img.png" srcset="online_user_pf_1_img.png 1x" />
				
		</div>
		<div id="Group_input_text_mob">
			<input placeholder="Type...." class="chat_msg_input_mob" />
			
			<img id="send-message_input_mob" src="send-message-btn-mob.png" srcset="send-message-btn-mob.png 1x" />
				
		</div>
	</div>:""}

	<div id="vids_mob">

  {/* Video 1 */}
  {/* {stream && ( */}
          {/* <Paper className={classes.papermob}> */}
            <Grid item xs={12} md={12}>

		<div id="vid_1_mob">
       <video id="vid_cam_1_mob" playsInline muted={callAccepted && !callEnded?false:true} ref={callAccepted && !callEnded? userVideo:myVideo} autoPlay className={classes.videomob} />
       {callAccepted && !callEnded ? (	
			<div id="Group_call_ended_mob" onClick={leaveCall} title="Hang Up">
				<svg class="border_call_end_mob">
					<rect id="border_call_end_mob" rx="6" ry="6" x="0" y="0" width="41" height="33.077">
					</rect>
				</svg>
				<img id="call-end_mob" src="call-end-mob.png" srcset="call-end-mob.png 1x" />	
			</div>
      ) : ""}

      {videoOff ? (
			<div id="Group_video_off_mob" value={videoOff}
             style={{ left:callAccepted && !callEnded  ?"":"110px"}}
            title="Video Off"
            onClick={() => setVideoOff(false)} >
				<svg class="border_cameraonoff">
					<rect id="border_cameraonoff" rx="6" ry="6" x="0" y="0" width="42" height="33.077">
					</rect>
				</svg>
				<img id="video-camera3_mob" src="video-camera-off.png" srcset="video-camera-off.png 1x" />	
			</div> ) : (
        <div id="Group_video_off_mob"  title="Video On"
         style={{ left:callAccepted && !callEnded  ?"":"110px"}}
            fullWidth
            value={videoOff}
            onClick={() => setVideoOff(true)} >
				<svg class="border_cameraonoff">
					<rect id="border_cameraonoff" rx="6" ry="6" x="0" y="0" width="42" height="33.077">
					</rect>
				</svg>
				<img id="video-camera3_mob" src="video-camera-on-mob.png" srcset="video-camera-on-mob.png 1x" />	
			</div>)}

      {callAccepted && !callEnded ? (
			<div id="Group_start_recording_mob" title="Start Recording"
          fullWidth
          onClick={() => start()}>
				<svg class="border_start_mob">
					<rect id="border_start_mob" rx="6" ry="6" x="0" y="0" width="42" height="33.077">
					</rect>
				</svg>
				<img id="rec-button_mob" src="rec-button-mob.png" srcset="rec-button-mob.png 1x" />	
			</div>):""}

      {callAccepted && !callEnded ? (
        	<div id="Group_stop_recording" title="Stop Recording"
          fullWidth
          onClick={() => stop()}>
				<svg class="border_start_mob">
					<rect id="border_start_mob" rx="6" ry="6" x="0" y="0" width="42" height="33.077">
					</rect>
				</svg>
				<img id="rec-button_mob" src="rec_stop_img_1_cq.png" srcset="rec_stop_img_1_cq.png 1x" />	
			</div>
      ):""}

      {mute ? (	
			<div id="Group_micoff_mob" fullWidth
        style={{left:callAccepted && !callEnded  ?"":"172px"}}
            value={mute}
            title="Mic Off"
            onClick={() => setMute(false)}>
				<svg class="border_micoffon_mob">
					<rect id="border_micoffon_mob" rx="6" ry="6" x="0" y="0" width="42" height="33.077">
					</rect>
				</svg>
				<img id="mic-on-off-ou" src="microphone-off.png" srcset="microphone-off.png 1x" />
			</div>  ) : (
			<div id="Group_micoff_mob"  fullWidth
          style={{ left:callAccepted && !callEnded  ?"":"172px"}}
            value={mute}
            title="Mic On"
            onClick={() => setMute(true)}>
				<svg class="border_micoffon_mob">
					<rect id="border_micoffon_mob" rx="6" ry="6" x="0" y="0" width="42" height="33.077">
					</rect>
				</svg>
				<img id="mic-on-off-ou" src="mic-on-mob.png" srcset="mic-on-mob.png 1x" />
			</div> )}

			<div id="Group_outer_name_lbl">
      <NameLabel callAccepted={callAccepted} callEnded={callEnded} name={name} size={"big"}/>
      
				{/* <div id="video_name_lbl_value">
					<span>{callAccepted && !callEnded?name:user.name}</span>
				</div> */}
			</div>
		</div>

    </Grid>
          {/* </Paper> */}
  {/* )} */}
    
{/* Video 2 */}
{/* {stream && ( */}
          {/* <Paper style={{border:"2px solid"}}> */}
            <Grid item xs={12} md={12}>
  	<div id="opposite_person_mob">	
      <video id="vid_cam_2_mob" playsInline muted ref={callAccepted && !callEnded ?setVideoOff===true ? null: myVideo :null} autoPlay className={classes.uservideomob} />
			<div id="Mohammed_Zaid_bl">
				<span><marquee>{callAccepted && !callEnded ?setVideoOff===true ? null: user.name :null}</marquee></span>
			</div>
		</div>
    </Grid>
          {/* </Paper> */}
        {/* )} */}

	</div>

	{scheduleSlide!==true ?
  <div id="upcome_meet_scroll_mob">
		<img id="upmeet_design_mob" src="upmeet_design_mob.png" srcset="upmeet_design_mob.png 1x" />
    {availableMeetings.map((off,idx) => (
    <div style={{left:(idx*175).toString()+"px",top:"0px",position:"relative"}}>

		<div id="upcome_pop_mob" onClick={()=>openScheduler(off)}>
			<img id="n_6170" src="upcomingmeetingbgimg.png" srcset="upcomingmeetingbgimg.png 1x" />
				
			<div id="Web_Development">
				<span><marquee>{off.meetingTitle?off.meetingTitle:""}</marquee></span>
			</div>
			<div id="Remaining">
				<span>Remaining</span>
			</div>
			<div id="n_3056">
				<span>{off.meetingDateTime?off.meetingDateTime.split("T")[1].slice(0,5):0}</span>
			</div>
		</div>
    </div>
))}
	</div>:<MeetingRoomScheduleSlide  openScheduleSlide={scheduleSlide} closescheduleSlide={(data,status)=>{setScheduleSlide(false);data && setMeetingData(data);status && setOpenMeetingModel(status);}}/>
  }

	<img id="right-arrow" src="arrow-right_open_close.png" srcset="arrow-right_open_close.png 1x, arrow-right_open_close@2x.png 2x" onClick={()=>setScheduleSlide(!scheduleSlide)}/>


	{onlineUserSlide!==true ?<div id="online_users_mob">
  
		<img id="ou-bg-img" src="ou-mob-users.png" srcset="ou-mob-users.png 1x" onClick={()=>setOnlineUserSlide(true)}/>
			
		<div id="Scroll_Group_1">
    {(onlineUsers||[]).map((ou) => (	
			<div id="Online_users_mob_1" onClick={() =>{ setOtherUserId(ou.socketId);setOtherUserName(ou.name);callUser(ou.socketId,ou.name)}}
              title="Call">
				<img id="user_1_img_tag"  src="user_pfp_profile_page.png" />	
			</div>
      ))}
		</div>

		<img id="arrow-right_ou" src="arrow-right_ou.png" srcset="arrow-right_ou.png 1x" onClick={()=>setOnlineUserSlide(true)}/>
	</div>:<MeetingRoomOnlineUsersSlide onlineUsers={onlineUsers} openSlide={onlineUserSlide}  closeSlide={(ou)=>{setOnlineUserSlide(false); ou && callUser(ou.id,ou.name)}}/>}

{/*Meeting Room Pop Up  */}
  <MeetingPopUpUI  openMeeetingPopUp = {openMeetingModel} meetingData={meetingData} closeMeetingPopup = {()=>{setOpenMeetingModel(false); getMeetings()}}/>

</div>

   </div>
  );
};

export default MeetingRoomMobUI;
