import React, { useEffect, useState, useContext} from 'react'
import '../../styles/CalenderAppointmentCard.css';

const CalenderAppointmentCard = (props) => {


    useEffect(()=>{
  //console.log(props.data) 
    
    },[props.data])


  return (
<div id="calender_event_part">
	<div id="Scroll_weekly_sync_up">

{props.data && props.data.map((rec)=>

		<div id="wekluy_sync_up_grp">
			<svg class="over_all_calender_card_bdr">
				<rect id="over_all_calender_card_bdr" rx="5" ry="5" x="0" y="0" width="100%" height="53">
				</rect>
			</svg>
			<div id="Weekly_Sync_Up_lbl_name">
				<span>{rec.title && rec.title}</span>
			</div>
			<div id="n_30am_-_1030am_wekluy_sync_up">
				<span>{rec.startTime && new Date(rec.startTime).getHours().toString().padStart(2,"0")+":"+new Date(rec.startTime).getMinutes().toString().padStart(2,"0")} - {rec.endTime && new Date(rec.endTime).getHours().toString().padStart(2,"0")+":"+new Date(rec.endTime).getMinutes().toString().padStart(2,"0")}</span>
			</div>
			<svg class="img_bdr_wekluy_sync_up">
				<rect id="img_bdr_wekluy_sync_up" rx="5" ry="5" x="0" y="0" width="34" height="34">
				</rect>
			</svg>
			{rec.type ==="alarm"?<img id="bell_wekluy_sync_up" src="./CalanderDashboard/bell_wekluy_sync_up.png" srcset="./CalanderDashboard/bell_wekluy_sync_up.png 1x" />:""}
				
		</div>
		
        ) 
}
	</div>
	{/* <div id="line_below_event_card">
		<svg class="calendar_line_event_part" viewBox="0 0 280 1">
			<path id="calendar_line_event_part" d="M 0 0 L 280 0">
			</path>
		</svg>
		<svg class="line_left_dot_caleneder">
			<rect id="line_left_dot_caleneder" rx="0" ry="0" x="0" y="0" width="5" height="5">
			</rect>
		</svg>
	</div> */}
</div>
  )
}

export default CalenderAppointmentCard
