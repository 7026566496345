import React from 'react';
import "../../../styles/PunctureWizardsMob.css";

function PunctureWizardsMob(props) {
  return (
    <div id="PunctureWizards_Mob">
	<div id="PWMOBGroup_15114">
		<img id="PWMOBstopped-white-car-with-punctur" src="./Themes/PunctureWizards/stopped-white-car-with-punctur.png" srcset="./Themes/PunctureWizards/stopped-white-car-with-punctur.png 1x" />
			
		<div id="PWMOBScroll_Group_28">
			<div id="PWMOBIt_is_a_long_established_fact_">
				<span>{props.data.vision}</span>
			</div>
		</div>
		<div id="PWMOBVISION">
			<span>VISION</span>
		</div>
	</div>
	<div id="PWMOBGroup_15120">
		<img id="PWMOBshane-rounce-mgt2BlBgprI-unspl" src="./Themes/PunctureWizards/shane-rounce-mgt2BlBgprI-unspl.png" srcset="./Themes/PunctureWizards/shane-rounce-mgt2BlBgprI-unspl.png 1x" />
			
		<div id="PWMOBScroll_Group_28_">
			<div id="PWMOBIt_is_a_long_established_fact__ba">
				<span>{props.data.mission}</span>
			</div>
		</div>
		<div id="PWMOBMISSION">
			<span>MISSION</span>
		</div>
	</div>
	<div id="PWMOBGroup_15121">
		<img id="PWMOBmason-jones-9e_ssIz4jB4-unspla" src="./Themes/PunctureWizards/mason-jones-9e_ssIz4jB4-unspla.png" srcset="./Themes/PunctureWizards/mason-jones-9e_ssIz4jB4-unspla.png 1x" />
			
		<div id="PWMOBScroll_Group_28_be">
			<div id="PWMOBIt_is_a_long_established_fact__bf">
				<span>{props.data.principles}</span>
			</div>
		</div>
		<div id="PWMOBPRINCIPLES">
			<span>PRINCIPLES</span>
		</div>
	</div>
	<div id="PWMOBGroup_15122">
		<div id="PWMOBGroup_15040">
			<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="PWMOBRectangle_737">
				<rect id="PWMOBRectangle_737" rx="0" ry="0" x="0" y="0" width="309" height="174">
				</rect>
			</iframe>
		</div>
		<div id="PWMOBGroup_15050">
			<div id="PWMOBsocials_grp_shadow_space_websi">
{props.data.linkedInUrl?
				<div title="LinkedIn" id="PWMOBlinked_in_grp_shadow_space_web">
					<svg class="PWMOBmed_shadow_space_website_7">
						<ellipse id="PWMOBmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="PWMOBlinked_in_shadow_space_website">
						<a href={props.data.linkedInUrl} target="_blank">
						<svg class="PWMOBn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="PWMOBn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="PWMOBlinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="PWMOBlinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="PWMOBtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="PWMOBtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>
:""}
			

{props.data.instagramUrl?	
				<div title="Instagram" id="PWMOBInsta_grp_shadow_space_website">
					<svg class="PWMOBshadow_space_website_7">
						<ellipse id="PWMOBshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={props.data.instagramUrl} target="_blank">
					<img id="PWMOBinstagramshadow_space_website_" src="./Themes/PunctureWizards/instagramshadow_space_website_.png" srcset="./Themes/PunctureWizards/instagramshadow_space_website_.png 1x" />
						
					</a>
				</div>:""}
 
 {props.data.whatsAppNumber?
 
				<div title="WhatsApp" id="PWMOBwhatsapp_grp_shadow_space_webs">
					<svg class="PWMOBmed_shadow_space_website_7_bw">
						<ellipse id="PWMOBmed_shadow_space_website_7_bw" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
<img id="PWMOBwhatsapp_lbl_shadow_space_webs" src="./Themes/PunctureWizards/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/PunctureWizards/whatsapp_lbl_shadow_space_webs.png 1x" />
</a>	
				</div>
:""}
			</div>
			<div id="PWMOBn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="PWMOBaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="PWMOBaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="PWMOBlocation_img_shadow_space_webs" src="./Themes/PunctureWizards/location_img_shadow_space_webs.png" srcset="./Themes/PunctureWizards/location_img_shadow_space_webs.png 1x" />
				
			<img id="PWMOBemail_address_shadow_space_web" src="./Themes/PunctureWizards/email_address_shadow_space_web.png" srcset="./Themes/PunctureWizards/email_address_shadow_space_web.png 1x" />
				
			<img id="PWMOBphone_number_shadow_space_webs" src="./Themes/PunctureWizards/phone_number_shadow_space_webs.png" srcset="./Themes/PunctureWizards/phone_number_shadow_space_webs.png 1x" />
				
			<div id="PWMOBn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited.<br/> All Rights Reserved</span>
			</div>
		</div>
		<div id="PWMOBContact_Us">
			<span>Contact Us!</span>
		</div>
	</div>
	<div id="PWMOBGroup_15152">
		<div id="PWMOBText">
			<div id="PWMOBCompany__Name">
				<span>{props.data.businessName}</span>
			</div>
		</div>
		<img id="PWMOBAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/PunctureWizards/Anime_Edit.png"} />
			
	</div>
	<div id="PWMOBBGs">
		<img id="PWMOBtechnician-is-repairing-car-fl" src="./Themes/PunctureWizards/technician-is-repairing-car-fl.png" srcset="./Themes/PunctureWizards/technician-is-repairing-car-fl.png 1x" />
			
	</div>
	<div id="PWMOBGroup_15133">
		<div id="PWMOBOur_Offerings">
			<span>Our Offerings</span>
		</div>
		<div id="PWMOBScroll_Group_90">
			<div id="PWMOBGroup_15128">
				<svg class="PWMOBRectangle_750_ci">
					<linearGradient id="PWMOBRectangle_750_ci" spreadMethod="pad" x1="-0.871" x2="1" y1="-0.401" y2="1">
						<stop offset="0" stop-color="#eba163" stop-opacity="1"></stop>
						<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
					</linearGradient>
					<rect id="PWMOBRectangle_750_ci" rx="7" ry="7" x="0" y="0" width="203" height="254">
					</rect>
				</svg>
				<img id="PWMOBtechnician-is-repairing-car-fl_cj" src="./Themes/PunctureWizards/technician-is-repairing-car-fl_cj.png" srcset="./Themes/PunctureWizards/technician-is-repairing-car-fl_cj.png 1x" />
					
				<div id="PWMOBPUNCTURE">
					<span>PUNCTURE</span>
				</div>
			</div>
		</div>
	</div>
</div>
  )
}

export default PunctureWizardsMob