import React, { useState, useEffect, useContext } from "react";
import "../../styles/EventsPopUI.css";
import Modal from "@mui/material/Modal";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";


const EventsPopUI = (props) => {

    const [searchParams] = useSearchParams();
    const { user, setUser } = useContext(UserContext);
    var navigate = useNavigate();
    const [open,setOpen] = useState(false);
	const [eventLocation,setEventLocation] = useState("");
	const [eventCost,setEventCost] = useState("");
	const [eventEndOn,setEventEndOn] = useState(new Date());
	const [eventSeats,setEventSeats] = useState("");
	const [eventStartOn,setEventStartOn] = useState(new Date());
	const [eventCategory,setEventCategory] = useState("");
	const [eventDiscription,setEventDiscription] = useState("");
	const [eventName,setEventName] = useState("");
	const [eventPictures,setEventPictures] = useState("");
	const [eventBanner,setEventBanner] = useState("");

    useEffect(() => {
        if (user)
          if (props.openEvents) {
            setOpen(props.openEvents);
          }
      
      }, [user]);

      const handleClose = () =>{
        setOpen(false)
        props.closeEvents()
      }

	  const addEvent = () => {

	     if(eventName.length === 0){

		 return alert("Please Enter Event Name!!")

		 }

		 if(eventCategory.length === undefined || eventCategory.length === 0){
			
		  return alert("Please select Event Category!!")
		 }
		 if(eventStartOn.length === undefined || eventStartOn.length ==="" ){
	
		   return alert("Please select Event Start Details!!")
		 }
	
		 if(eventLocation.length === 0 || eventLocation.length ==="" ){
	
		  return alert("Please Enter Event Location!!")
		 }
	   
		setOpen(false);
		
		let apiURL = process.env.REACT_APP_API_URL + "/events/addevent";
		let apiParams = {
		  method: "POST",
		  headers: {
			eventname:eventName,
			eventdiscription:eventDiscription,
			eventcategory:eventCategory,
			eventstarton:eventStartOn,
			eventendon:eventEndOn,
			eventseats:eventSeats,
			eventcost:eventCost,
			eventlocation:eventLocation,
			organiserid:user._id,
			organisername:user.name,
			organiserimageid:user.imageId,
		  },
		};
		fetch(apiURL, apiParams)
		  .then((res) => (res.status === 200 ? res.json() : res.text()))
		  .then((repos) => {
			if (repos.data) {
			  handleClose();
			  alert("Events Added Successfully");
	
			  eventPictures &&
				UploadImageToDrive(repos.data._id, eventPictures);
			} else alert(repos.message || repos);
		  })
		  .catch((err) => alert(err));
	  };

	  function handleFileSelect(f) {
		var reader = new FileReader();
		// Closure to capture the file information.
		reader.onload = (function(theFile) {
		  return function(e) {
			var binaryData = e.target.result;
			//Converting Binary Data to base 64
			var base64String = window.btoa(binaryData);
			//showing file converted to base64
	
			setEventBanner("data:image/jpeg;base64," + base64String);
	
		  };
		})(f);
		// Read in the image file as a data URL.
		reader.readAsBinaryString(f);
	  }
	
	  const handleImageUpload = (e) => {
	
		handleFileSelect(e.target.files[0]);
		//UploadImageToDrive(e.target.files[0]);
		setEventPictures(e.target.files[0])
		
	  };
	
	  //Upload Image
	  const UploadImageToDrive = async (id,document) => {
		var file = document; //the file
		var reader = new FileReader(); //this for convert to Base64
		reader.readAsDataURL(document); //start conversion...
		reader.onload = function(e) {
		  //.. once finished..
		  var rawLog = reader.result.split(",")[1]; //extract only thee file data part
		  var dataSend = {
			dataReq: { data: rawLog, name: file.name, type: file.type },
			fname: "uploadFilesToGoogleDrive",
		  }; //preapre info to send to API
		  fetch(
			process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
			{ method: "POST", body: JSON.stringify(dataSend) }
		  ) //send to Api
			.then((res) => res.json())
			.then((data) => {
			  //See response
	
			  SaveEventBanner(data,id);
	
			  // alert("Image Uploaded Successfully","success")
			})
			.catch((err) => console.log(err)); // Or Error in console
		};
	  };

	  const SaveEventBanner = (data,id) => {
		const apiurl =
		  process.env.REACT_APP_API_URL + "/events/updateeventbannerimage/" + id;
		let apiParams = {
		  method: "POST",
		  headers: {
			eventimageurl: data.url,
		  },
		};
		fetch(apiurl, apiParams)
		  .then((res) => (res.status === 200 ? res.json() : res.text()))
		  .then((repos) => {
			if (repos.data) {
			  alert("Image Uploaded Successfully");
			}
		  })
		  .catch((e) => console.log(e));
	  };

  return (
 
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflow: "scroll", textAlign: "center" }}
        >
            <div id="add_event">
	<img id="over_all_bg_of_event_pop_up" src="over_all_bg_of_event_pop_up.png"  />
		
	<img id="close_btn_add_event_details" src="close_btn_add_event_details.png" onClick={()=>handleClose()} />
		
	<div id="Enter_Event_Details_lbl_add_ev">
		<span>Enter Event Details</span>
	</div>
	<div id="add_btn_grp_add_event_details" onClick={()=>addEvent()}> 
		<svg class="add_btn_bdr_add_event_details">
			<rect id="add_btn_bdr_add_event_details" rx="7" ry="7" x="0" y="0" width="120" height="40">
			</rect>
		</svg>
		<div id="Add_btn_add_event_details">
			<span>Add</span>
		</div>
	</div>
	<div id="Scroll_add_event_pc">
		<div id="all_details_fill_grp_event_add">
			<div id="upload_banner_grp_add_event_de">
				<div id="Upload_Banner_IMG_add_event_de">
					<span>Upload Banner IMG</span>
				</div>
				<label for="imageProfile">
              <input
                id="imageProfile"
                type="file"
                name="imageProfile"
                hidden
                accept="image/*"
                onChange={(e) => handleImageUpload(e)}
              />
				<img id="upload_img_add_event_details" src="upload_img_add_event_details.png"  />
			  </label>
			  {/* {profile.length === 0 ? (

                <img
                  id="user_pfp_pop_lbl_profile_pc"
                  width="100"
                  height="100"
                  src={
                    user.imageId && user.imageId.length
                      ? process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG +
                        user.imageId
                      : "img_preview_section_event_deta.png"
                  }
                 
                />
              ) : (
                <img
                  id="user_pfp_pop_lbl_profile_pc"
                  width="100"
                  height="100"
                  src={profile ? profile : "img_preview_section_event_deta.png"}
                  
                />
              )} */}

				<img id="img_preview_section_event_deta" src={eventBanner?eventBanner:"img_preview_section_event_deta.png"} />

			</div>
			<div id="events_loca_grp_add_event_deta">
				<input class="event_location_bdr_add_event_d" value={eventLocation} onChange={(e)=>setEventLocation(e.target.value)}/>
					
				<div id="Event_Location_lbl_add_event_d">
					<span>Event Location</span>
				</div>
			</div>
			<div id="event_cost_grp_add_event_detai">
				<input class="event_cost_bdr_add_event_detai" value={eventCost} onChange={(e)=>setEventCost(e.target.value)}/>
					
				<div id="Event_Cost_lbl_add_event_detai">
					<span>Event Cost</span>
				</div>
			</div>
			<div id="event_time_grp_add_event_detai">
				<input type="datetime-local" class="event_time_bdr_add_event_detai" value={eventEndOn} onChange={(e)=>setEventEndOn(e.target.value)}/>
					
				<div id="Event_Time_lbl_add_event_detai">
					<span>Event End On</span>
				</div>
			</div>
			<div id="event_seats_grp_add_event_deta">
				<input class="event_seats_bdr_add_event_deta" value={eventSeats} onChange={(e)=>setEventSeats(e.target.value)}/>
					
				<div id="Event_Seats_lbl_add_event_deta">
					<span>Event Seats</span>
				</div>
			</div>
			<div id="event_date_grp_add_event_detai">
				<input type="datetime-local" class="event_date_bdr_add_event_detai" value={eventStartOn} onChange={(e)=>setEventStartOn(e.target.value)}/>
					
				<div id="Event_Date_lbl_add_event_detai">
					<span>Event Start On</span>
				</div>
			</div>
			<div id="event_category_grp_add_event_d">
				<select class="evnet_category_bdr_add_event_d" value={eventCategory} onChange={(e)=>setEventCategory(e.target.value)}>
					<option value="">---select---</option>
					<option value="Announcements">Announcements</option>
					<option value="Education">Education</option>
					<option value="Competetions">Competetions</option>
					<option value="Entertainment">Entertainment</option>
					<option value="Others">Others</option>
				</select>
				<div id="Event_Category_lbl_add_event_d">
					<span>Event Category</span>
				</div>
			</div>
			<div id="event_description_grp_add_even">
				<textarea class="event_descp_bdr_add_event_deta" value={eventDiscription} onChange={(e)=>setEventDiscription(e.target.value)}/>
					
				<div id="Event_Description_lbl_add_even">
					<span>Event Description</span>
				</div>
			</div>
			<div id="event_name_grp_add_event_detai">
				<input class="event_name_bdr_add_event_detai" value={eventName}  onChange={(e)=>setEventName(e.target.value)}/>
					
				<div id="Event_Name_lbl_add_event_detai">
					<span>Event Name</span>
				</div>
			</div>
		</div>
	</div>
</div>
        </Modal>

  )
}

export default EventsPopUI
