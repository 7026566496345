import React from 'react';
import "../../../styles/MobileMarvelsMob.css";

function MobileMarvelsMob(props) {
  return (
    <div id="Mobile_Stock_mob">
	<div id="theme11_stock_mobBanner_Slider">
		<svg class="theme11_stock_mobPath_2" viewBox="0 0 425.89 222.257">
			<path id="theme11_stock_mobPath_2" d="M 0 0 L 425.8901672363281 0 L 425.8901672363281 135.0589599609375 C 425.8901672363281 135.0589599609375 405.4183654785156 222.2567596435547 298.94580078125 222.2567596435547 C 238.6421356201172 222.2567596435547 120.2044219970703 187.4464569091797 64.62977600097656 162.4950103759766 C 47.08462524414062 154.6177368164062 33.54290008544922 149.4594879150391 33.54290008544922 149.4594879150391 C 33.54290008544922 149.4594879150391 24.63382339477539 144.9729766845703 20.82080841064453 143.0289306640625 C 8.162073135375977 136.5750579833984 0 133.8247528076172 0 133.8247528076172 L 0 0 Z">
			</path>
		</svg>
		<img id="theme11_stock_mobdugba-cauley-hushie-6MNmDi1hc_" src="./Themes/MobileMarvels/dugba-cauley-hushie-6MNmDi1hc_.png" srcset="./Themes/MobileMarvels/dugba-cauley-hushie-6MNmDi1hc_.png 1x" />
			
		<svg class="theme11_stock_mobPath_1527" viewBox="0 0 425.89 207.917">
			<path id="theme11_stock_mobPath_1527" d="M -3.683020258904435e-05 0 L 425.89013671875 0 L 425.89013671875 144.6697235107422 C 425.89013671875 144.6697235107422 406.7190246582031 207.9172973632812 300.2465209960938 207.9172973632812 C 193.7739410400391 207.9172973632812 -3.683020258904435e-05 144.6697235107422 -3.683020258904435e-05 144.6697235107422 L -3.683020258904435e-05 0 Z">
			</path>
		</svg>
		<div id="theme11_stock_mobYour_Mobile_Paradise__Where_Dr">
			<span>"Your Mobile Paradise: <br/>Where Dreams Take Shape!"</span>
		</div>
		<div id="theme11_stock_mobBtg">
			<svg class="theme11_stock_mobRectangle_77">
				<rect id="theme11_stock_mobRectangle_77" rx="9.74830436706543" ry="9.74830436706543" x="0" y="0" width="72.37" height="19.497">
				</rect>
			</svg>
			<div id="theme11_stock_mobExplore_More">
				<span>Explore More</span>
			</div>
		</div>
	</div>
	<div id="theme11_stock_mobGroup_14896">
		<div id="theme11_stock_mobGroup_14887">
			<div id="theme11_stock_mobstyle">
				<svg class="theme11_stock_mobPath_28" viewBox="0 -72.597 284.648 286.727">
					<path id="theme11_stock_mobPath_28" d="M 284.6482543945312 -72.5966796875 L 0 6.857738494873047 L 0 161.1604309082031 L 284.6482543945312 214.1299743652344 L 284.6482543945312 -72.5966796875 Z">
					</path>
				</svg>
				<svg class="theme11_stock_mobEllipse_6">
					<ellipse id="theme11_stock_mobEllipse_6" rx="119.27678680419922" ry="119.27678680419922" cx="119.27678680419922" cy="119.27678680419922">
					</ellipse>
				</svg>
				<svg class="theme11_stock_mobEllipse_5">
					<ellipse id="theme11_stock_mobEllipse_5" rx="67.92711639404297" ry="67.92711639404297" cx="67.92711639404297" cy="67.92711639404297">
					</ellipse>
				</svg>
			</div>
			<img id="theme11_stock_mobneil-soni-6wdRuK7bVTE-unsplash" src="./Themes/MobileMarvels/neil-soni-6wdRuK7bVTE-unsplash.png" srcset="./Themes/MobileMarvels/neil-soni-6wdRuK7bVTE-unsplash.png 1x" />
				
		</div>
		<div id="theme11_stock_mobGroup_14888">
			<div id="theme11_stock_mobScroll_Group_24">
				<div id="theme11_stock_mobIt_is_a_long_established_fact_">
					<span>{props.data.mission}</span>
				</div>
			</div>
			<div id="theme11_stock_mobMISSION">
				<span>MISSION</span>
			</div>
		</div>
	</div>
	<div id="theme11_stock_mobGroup_14895">
		<div id="theme11_stock_mobstyle_cx">
			<svg class="theme11_stock_mobPath_28_cy" viewBox="0 -72.597 295.924 298.085">
				<path id="theme11_stock_mobPath_28_cy" d="M 0 -72.5966796875 L 295.9239196777344 10.00514221191406 L 295.9239196777344 170.420166015625 L 0 225.4879913330078 L 0 -72.5966796875 Z">
				</path>
			</svg>
			<svg class="theme11_stock_mobEllipse_6_cz">
				<ellipse id="theme11_stock_mobEllipse_6_cz" rx="124.00165557861328" ry="124.00165557861328" cx="124.00165557861328" cy="124.00165557861328">
				</ellipse>
			</svg>
			<svg class="theme11_stock_mobEllipse_5_c">
				<ellipse id="theme11_stock_mobEllipse_5_c" rx="70.61788940429688" ry="70.61788940429688" cx="70.61788940429688" cy="70.61788940429688">
				</ellipse>
			</svg>
		</div>
		<img id="theme11_stock_mobdaniel-korpai-H0vKZjt2k1E-unsp_c" src="./Themes/MobileMarvels/daniel-korpai-H0vKZjt2k1E-unsp_c.png" srcset="./Themes/MobileMarvels/daniel-korpai-H0vKZjt2k1E-unsp_c.png 1x" />
			
		<div id="theme11_stock_mobGroup_14886">
			<div id="theme11_stock_mobScroll_Group_24_c">
				<div id="theme11_stock_mobIt_is_a_long_established_fact__c">
					<span>{props.data.vision}</span>
				</div>
			</div>
			<div id="theme11_stock_mobVISION">
				<span>VISION</span>
			</div>
		</div>
		<img id="theme11_stock_mobdaniel-korpai-H0vKZjt2k1E-unsp_c" src="./Themes/MobileMarvels/daniel-korpai-H0vKZjt2k1E-unsp_c.png" srcset="./Themes/MobileMarvels/daniel-korpai-H0vKZjt2k1E-unsp_c.png 1x" />
			
	</div>
	<div id="theme11_stock_mobstyle_c">
		<svg class="theme11_stock_mobPath_28_c" viewBox="0 -72.597 295.924 298.085">
			<path id="theme11_stock_mobPath_28_c" d="M 0 -72.5966796875 L 295.9239196777344 10.00514221191406 L 295.9239196777344 170.420166015625 L 0 225.4879913330078 L 0 -72.5966796875 Z">
			</path>
		</svg>
		<svg class="theme11_stock_mobEllipse_6_c">
			<ellipse id="theme11_stock_mobEllipse_6_c" rx="124.00165557861328" ry="124.00165557861328" cx="124.00165557861328" cy="124.00165557861328">
			</ellipse>
		</svg>
		<svg class="theme11_stock_mobEllipse_5_da">
			<ellipse id="theme11_stock_mobEllipse_5_da" rx="70.61788940429688" ry="70.61788940429688" cx="70.61788940429688" cy="70.61788940429688">
			</ellipse>
		</svg>
	</div>
	<img id="theme11_stock_mobdaniel-korpai-H0vKZjt2k1E-unsp_db" src="./Themes/MobileMarvels/daniel-korpai-H0vKZjt2k1E-unsp_db.png" srcset="./Themes/MobileMarvels/daniel-korpai-H0vKZjt2k1E-unsp_db.png 1x" />
		
	<div id="theme11_stock_mobGroup_14889">
		<div id="theme11_stock_mobScroll_Group_24_dd">
			<div id="theme11_stock_mobIt_is_a_long_established_fact__de">
				<span>{props.data.principles}</span>
			</div>
		</div>
		<div id="theme11_stock_mobPRINCIPLE">
			<span>PRINCIPLE</span>
		</div>
	</div>
	<img id="theme11_stock_mobjoel-rohland-MCL2xxjZJvs-unspl" src="./Themes/MobileMarvels/joel-rohland-MCL2xxjZJvs-unspl.png" srcset="./Themes/MobileMarvels/joel-rohland-MCL2xxjZJvs-unspl.png 1x" />
		
	<div id="theme11_stock_mobour_Services">
		<span>our Services</span>
	</div>
	<div id="theme11_stock_mobGroup_14890">
    {props.offeringsData.map((rec,idx)=>(<>
		<div id="theme11_stock_mobGroup_14885">
			<img id="theme11_stock_mobiPhone_X_-_Light2x" src="./Themes/MobileMarvels/iPhone_X_-_Light2x.png" srcset="./Themes/MobileMarvels/iPhone_X_-_Light2x.png 1x" />
				
			<img id="theme11_stock_mobdariusz-sankowski-3OiYMgDKJ6k-" src="./Themes/MobileMarvels/dariusz-sankowski-3OiYMgDKJ6k-.png" srcset="./Themes/MobileMarvels/dariusz-sankowski-3OiYMgDKJ6k-.png 1x" />
				
		</div>
		<div id="theme11_stock_mobGroup_14884">
			<img id="theme11_stock_mobiPhone_X_-_Light2x_dn" src="./Themes/MobileMarvels/iPhone_X_-_Light2x_dn.png" srcset="./Themes/MobileMarvels/iPhone_X_-_Light2x_dn.png 1x" />
				
			<img id="theme11_stock_mobdariusz-sankowski-3OiYMgDKJ6k-_do" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"./Themes/MobileMarvels/dariusz-sankowski-3OiYMgDKJ6k-.png"} />
				
		</div>
		<div id="theme11_stock_mobGroup_14883">
			<img id="theme11_stock_mobiPhone_X_-_Light2x_dq" src="./Themes/MobileMarvels/iPhone_X_-_Light2x_dq.png" srcset="./Themes/MobileMarvels/iPhone_X_-_Light2x_dq.png 1x" />
				
			<img id="theme11_stock_mobdariusz-sankowski-3OiYMgDKJ6k-_dr" src="./Themes/MobileMarvels/dariusz-sankowski-3OiYMgDKJ6k-.png" srcset="./Themes/MobileMarvels/dariusz-sankowski-3OiYMgDKJ6k-.png 1x" />
				
		</div></>   ))}
	</div>
	<div id="theme11_stock_mobGroup_14891">
		<div id="theme11_stock_mobTitle">
			<span>Title</span>
		</div>
		<div id="theme11_stock_mobScroll_Group_25">
			<div id="theme11_stock_mobDescription">
				<span>Description</span>
			</div>
		</div>
	</div>
	<div id="theme11_stock_mobGroup_14897">
		<div id="theme11_stock_mobButtom">
			<svg class="theme11_stock_mobRectangle_53">
				<rect id="theme11_stock_mobRectangle_53" rx="0" ry="0" x="0" y="0" width="375" height="704">
				</rect>
			</svg>
		</div>
		<div id="theme11_stock_mobn_234567890_mobile_number_shad">
			<span>{props.data.businessPhone}</span>
		</div>
		<div id="theme11_stock_mobaffanghanioperatelive_email_sh">
			<span>{props.data.businessEmail}</span>
		</div>
		<div id="theme11_stock_mobaddress_med_shadow_space_websi">
			<span>{props.data.businessAddress}</span>
		</div>
		<img id="theme11_stock_moblocation_img_shadow_space_webs" src="./Themes/MobileMarvels/location_img_shadow_space_webs.png" srcset="./Themes/MobileMarvels/location_img_shadow_space_webs.png 1x" />
			
		<img id="theme11_stock_mobemail_address_shadow_space_web" src="./Themes/MobileMarvels/email_address_shadow_space_web.png" srcset="./Themes/MobileMarvels/email_address_shadow_space_web.png 1x" />
			
		<img id="theme11_stock_mobphone_number_shadow_space_webs" src="./Themes/MobileMarvels/phone_number_shadow_space_webs.png" srcset="./Themes/MobileMarvels/phone_number_shadow_space_webs.png 1x" />
			
		<div id="theme11_stock_mobsocials_grp_shadow_space_websi">
        {props.data.linkedInUrl?	
            <div title="LinkedIn" id="theme11_stock_moblinked_in_grp_shadow_space_web">
				<svg class="theme11_stock_mobmed_shadow_space_website_7">
					<ellipse id="theme11_stock_mobmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<div id="theme11_stock_moblinked_in_shadow_space_website">
				<a href={props.data.linkedInUrl} target="_blank">
					<svg class="theme11_stock_mobn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
						<path id="theme11_stock_mobn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
						</path>
					</svg>
				</a>
					<svg class="theme11_stock_moblinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
						<path id="theme11_stock_moblinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
						</path>
					</svg>
					<svg class="theme11_stock_mobtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
						<path id="theme11_stock_mobtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
						</path>
					</svg>
				</div>
			</div>:""}
            {props.data.instagramUrl?	
			<div title="Instagram" id="theme11_stock_mobInsta_grp_shadow_space_website">
				<svg class="theme11_stock_mobshadow_space_website_7">
					<ellipse id="theme11_stock_mobshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
			<a href={props.data.instagramUrl} target="_blank">
				<img id="theme11_stock_mobinstagramshadow_space_website_" src="./Themes/MobileMarvels/instagramshadow_space_website_.png" srcset="./Themes/MobileMarvels/instagramshadow_space_website_.png 1x" />
					
				</a>
			</div>:""}
			{props.data.whatsAppNumber?<div title="WhatsApp" id="theme11_stock_mobwhatsapp_grp_shadow_space_webs">
				<svg class="theme11_stock_mobmed_shadow_space_website_7_ei">
					<ellipse id="theme11_stock_mobmed_shadow_space_website_7_ei" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
                <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
			<img id="theme11_stock_mobwhatsapp_lbl_shadow_space_webs" src="./Themes/MobileMarvels/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/MobileMarvels/whatsapp_lbl_shadow_space_webs.png 1x" />
					</a>
			</div>:""}
		</div>
		<div id="theme11_stock_mobGroup_14892">
			<img id="theme11_stock_mobkisspng-laptop-macbook-pro-mac" src="./Themes/MobileMarvels/kisspng-laptop-macbook-pro-mac.png" srcset="./Themes/MobileMarvels/kisspng-laptop-macbook-pro-mac.png 1x" />
				
			<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="theme11_stock_mobRectangle_690">
				<rect id="theme11_stock_mobRectangle_690" rx="7" ry="7" x="0" y="0" width="231" height="149">
				</rect>
			</iframe>
		</div>
		<div id="theme11_stock_mobn_Copyright_Operate_Live_OPC_P">
			<span>© Copyright Operate Live (OPC) Private Limited. <br/>All Rights Reserved</span>
		</div>
	</div>
	<svg class="theme11_stock_mobEllipse_62">
		<ellipse id="theme11_stock_mobEllipse_62" rx="17.5" ry="17.5" cx="17.5" cy="17.5">
		</ellipse>
	</svg>
	<img id="theme11_stock_mobjakob-rosen-FsBbavP9YA4-unspla" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/MobileMarvels/jakob-rosen-FsBbavP9YA4-unspla.png"} />
		
</div>
  )
}

export default MobileMarvelsMob
