import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioOffCards.css';
import { UserContext } from '../UserContext';

const MyPortfolioOffCards = (props) => {

	const {user} = useContext(UserContext);
	 


	const handleLike = (offId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/offerings/updatetrophy/"+offId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
			  return false
		  };
	

  return (
    <div>
      	<div id="card_1_reqoffevent_off_PC" style={{left:props.idx+"0px"}} >
				<svg class="Base_reqoffevent_off_PC">
					<rect id="Base_reqoffevent_off_PC" rx="10" ry="10" x="0" y="0" width="270" height="270">
					</rect>
				</svg>
				<svg class="bdr_1_reqoffevent_off_PC">
					<rect id="bdr_1_reqoffevent_off_PC" rx="15.5" ry="15.5" x="0" y="0" width="80" height="31">
					</rect>
				</svg>
				<div id="n_90_reqoffevent_off_PC">
					<span>{props.data.offeredcurrency && JSON.parse(props.data.offeredcurrency).symbol} {props.data.budget}</span>
				</div>
				<div id="Single_Tour_reqoffevent_off_PC">
					<span>{props.data.offeringTitle}</span>
				</div>
				<div id="date_reqoffevent_off_PC">
					<span>{( props.data.deliveryDuration ? props.data.deliveryDuration:"")} Hours | {props.data.deliveryLocation}</span>
				</div>
				<img id="online-shopping_reqoffevent_of" src={props.data.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultOfferingsImageUrl.split('/')[5]):"./MyPortfolio/Offerings/online-shopping_reqoffevent_of.png"}  />
				<svg class="trohpie_bdr_small_4_trendingre_off_PC">
					<ellipse id="trohpie_bdr_small_4_trendingre_off_PC" rx="20" ry="18.5" cx="20" cy="18.5">
					</ellipse>
				</svg>
				<img id="trophy_reqoffevent_off_PC" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(props.data._id)} />
				<svg class="number_small_4_trending_offn_Pc">
			     <rect id="number_small_4_trending_offn_Pc" rx="0" ry="0" x="0" y="0" width="39" height="21">
			     </rect>
		        </svg>
				<div id="n_234_reqoffevent_off_PC">
					<span>{(JSON.parse(props.data.trophies)).length}</span>
				</div>
				<img id="share_1_reqoffevent_off_PC" src="./MyPortfolio/Offerings/share_1_reqoffevent_off_PC.png" srcset="./MyPortfolio/Offerings/share_1_reqoffevent_off_PC.png 1x" />
					
			</div>
    </div>
  )
}

export default MyPortfolioOffCards
