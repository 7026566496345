import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "./UserContext";

function NameLabel(props) {
    const { user, setUser } = useContext(UserContext);
    useEffect(()=>{},[props])
  return (
    <div style={{zIndex:1,position:"relative",border:"1px solid black", textAlign:"center",backgroundColor:"white",width:"auto"}}>
			<span>{props.size === "big"? props.callAccepted && !props.callEnded? props.name: user.name:props.callAccepted && !props.callEnded && user.name || props.name}</span>
	</div>
  )
}

export default NameLabel
