import React from 'react'
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import LoginMobUI from './Components/LoginMobUI';
import SignUpMobUI from './Components/SignUpMobUI';
import ForgotPasswordMobUI from './Components/ForgotPasswordMobUI';
import FlamingoFiestaMob from './Components/MobUI/Themes/FlamingoFiestaMob';
import MarketMobUI from './Components/PC/MarketMobUI';
import OceanicBliss from './Components/PC/Themes/OceanicBliss';
import OceanicBlissMob from './Components/MobUI/Themes/OceanicBlissMob';
import EnchantingEmeraldMob from './Components/MobUI/Themes/EnchantingEmeraldMob';
import HarmonyHavenMob from './Components/MobUI/Themes/HarmonyHavenMob';
import ShadowSpaceMob from './Components/MobUI/Themes/ShadowSpaceMob';
import KnowledgeKornerMob from './Components/MobUI/Themes/KnowledgeKornerMob';
import DriveVelocityMob from './Components/MobUI/Themes/DriveVelocityMob';
import RideRevolutionMob from './Components/MobUI/Themes/RideRevolutionMob';
import FashionFindsMob from './Components/MobUI/Themes/FashionFindsMob';
import JourneyJunkiesMob from './Components/MobUI/Themes/JourneyJunkiesMob';
import MobileMarvelsMob from './Components/MobUI/Themes/MobileMarvelsMob';
import PawPalaceMob from './Components/MobUI/Themes/PawPalaceMob';
import ChefsKitchenMob from './Components/MobUI/Themes/ChefsKitchenMob';
import FeatheredScapeMob from './Components/MobUI/Themes/FeatheredScapeMob';
import FurniGemMob from './Components/MobUI/Themes/FurniGemMob';
import GamersHubMob from './Components/MobUI/Themes/GamersHubMob';
import TeaCornerMob from './Components/MobUI/Themes/TeaCornerMob';
import SharpStylesMob from './Components/MobUI/Themes/SharpStylesMob';
import RealtyScapeMob from './Components/MobUI/Themes/RealtyScapeMob';
import BakersStopMob from './Components/MobUI/Themes/BakersStopMob';
import KnowledgeSpaceMob from './Components/MobUI/Themes/KnowledgeSpaceMob';
import HealthHubMob from './Components/MobUI/Themes/HealthHubMob';
import ChaatsParadiceMob from './Components/MobUI/Themes/ChaatsParadiceMob';
import FlowMastersMob from './Components/MobUI/Themes/FlowMastersMob';
import CaptureSphereMob from './Components/MobUI/Themes/CaptureSphereMob';
import PlantPlatesMob from './Components/MobUI/Themes/PlantPlatesMob';
import ElectroTechMob from './Components/MobUI/Themes/ElectroTechMob';
import VerdantHarvestMob from './Components/MobUI/Themes/VerdantHarvestMob';
import FruitFiestaMob from './Components/MobUI/Themes/FruitFiestaMob';
import FreshSipMob from './Components/MobUI/Themes/FreshSipMob';
import SoleCraftedMob from './Components/MobUI/Themes/SoleCraftedMob';
import ShoeStrideMob from './Components/MobUI/Themes/ShoeStrideMob';
import InkwellHavenMob from './Components/MobUI/Themes/InkwellHavenMob';
import TextureFusionMob from './Components/MobUI/Themes/TextureFusionMob';
import ColorFusionMob from './Components/MobUI/Themes/ColorFusionMob';
import CleanFabricaMob from './Components/MobUI/Themes/CleanFabricaMob';
import PerfumePulseMob from './Components/MobUI/Themes/PerfumePulseMob';
import PunctureWizardsMob from './Components/MobUI/Themes/PunctureWizardsMob';
import SweetTreatMob from './Components/MobUI/Themes/SweetTreatMob';
import CycleHavenMob from './Components/MobUI/Themes/CycleHavenMob';
import RadiantLocksMob from './Components/MobUI/Themes/RadiantLocksMob';
import TrueVisionMob from './Components/MobUI/Themes/TrueVisionMob';
import TechTrekkerMob from './Components/MobUI/Themes/TechTrekkerMob';

function MobThemeRoutes(props) {
  const selectTheme =()=>{

    switch (props.webData.theme){
      case "Enchanting Emerald": 
     return <div><EnchantingEmeraldMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/></div>
      break;
      case "Flamingo Fiesta" :
     return <FlamingoFiestaMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
      break;
      case "Oceanic Bliss" :
     return <div><OceanicBlissMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/></div>
      break;
      case "Harmony Haven" :
      return <HarmonyHavenMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
      break;
      case "Shadow Space" :
      return <ShadowSpaceMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
         break;
      case "Knowledge Korner" :
      return <KnowledgeKornerMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
         break;
      case "Drive Velocity" :
      return <DriveVelocityMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
         break;
      case "Ride Revolution" :
      return <RideRevolutionMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
         break;
      case "Fashion Finds" :
      return <FashionFindsMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
         break;
      case "Journey Junkies" :
      return <JourneyJunkiesMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
         break;
         case "Mobile Marvels" :
         return <MobileMarvelsMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
            break;
            case "Paw Palace" :
            return <PawPalaceMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
               break;
            case "Chef's Kitchen" :
            return <ChefsKitchenMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
               break;
               case "Feathered Scape" :
               return <FeatheredScapeMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                  break;
                  case "Furni Gem" :
                  return <FurniGemMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                     break;
                     case "Gamers Hub" :
                     return <GamersHubMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                        break;
                     case "Tea Corner" :
                     return <TeaCornerMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                        break;
                     case "Sharp Styles" :
                     return <SharpStylesMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                        break;
                     case "Realty Scape" :
                     return <RealtyScapeMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                        break;
                     case "Knowledge Space" :
                     return <KnowledgeSpaceMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                        break;
                     case "Bakers Stop" :
                     return <BakersStopMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                        break;
                        case "Health Hub" :
                        return <HealthHubMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                           break;
                        case "Chaats Paradice" :
                        return <ChaatsParadiceMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                           break;
                           case "Flow Masters" :
                           return <FlowMastersMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                              break;
                              case "Capture Sphere" :
                             return <CaptureSphereMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                               break;
                               case "Plant Plates" :
                              return <PlantPlatesMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                break;
                                case "Electro Tech" :
                               return <ElectroTechMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                 break;
                                 case "Verdant Harvest" :
                                return <VerdantHarvestMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                  break;
                                  case "Fruit Fiesta" :
                                 return <FruitFiestaMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                   break;
                                   case "Fresh Sip" :
                                  return <FreshSipMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                    break;
                                    case "Sole Crafted" :
                                   return <SoleCraftedMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                     break;
                                     case "Shoe Stride" :
                                    return <ShoeStrideMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                      break;
                                      case "Inkwell Haven" :
                                     return <InkwellHavenMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                       break;
                                       case "Texture Fusion" :
                                      return <TextureFusionMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                        break;
                                        case "Color Fusion" :
                                       return <ColorFusionMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                         break;
                                         case "Clean Fabrica" :
                                        return <CleanFabricaMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                          break;
                                          case "Perfume Pulse" :
                                         return <PerfumePulseMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                           break;


                                           
                                     case "Puncture Wizards" :
                                       return <PunctureWizardsMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                         break;
                                         case "Cycle Haven" :
                                        return <CycleHavenMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                          break;
                                          case "Sweet Treat" :
                                         return <SweetTreatMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                           break;
                                           case "Radiant Locks" :
                                          return <RadiantLocksMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                            break;
                                            case "True Vision" :
                                           return <TrueVisionMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                             break;
                                             case "Tech Trekker" :
                                            return <TechTrekkerMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                                              break;
         
      default :return <ShadowSpaceMob data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/> 
    }
  
  } 
  return (
    <Routes>
    <Route
         path="/"
         element={selectTheme()
           }
       />
       <Route exact path="/login" element={<LoginMobUI />}/>
        <Route exact path="/signup" element={<SignUpMobUI/>}/>
    <Route path="/sendmail" element={<ForgotPasswordMobUI/>}/>
    </Routes>
  )
}

export default MobThemeRoutes
