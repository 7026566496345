import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
 import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../styles/QuotationPopUpPC.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Currencies from "./Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const QuotationPopUpPC = (props) => {

  //useState
  const { user, setUser} = useContext(UserContext);
  const [openQuotationPopUp,setOpenQuotationPopUp] = useState(false);
  const [biddings,setBiddings] = useState("");
  const [deliveryDeadline, setDeliveryDeadline] = useState(new Date().toISOString());
  const [deliveryLocation, setDeliveryLocation] = useState();
  const [description, setDescription] = useState("");
  const [services, setServices] = useState("");
  const [budget,setBudget] = useState(0);
  const [remarks,setRemarks] = useState("");
  const [canBeDelivered,setCanBeDelivered]= useState(false);
  const [companyName, setCompanyName] = useState("");
  const [hours, setHours]= useState("hours");
  const [estimatedTime,setEstimatedTime] = useState("");
  const [estimatedAmount,setEstimatedAmount] = useState("");
  const [currency,setCurrency] = useState("")
  const [estimatedCurrency,setEstimatedCurrency] = useState("");
  const [requirementId,setRequirementId]=useState("");
  const [title,setTitle]= useState("");
  const [createdById,setCreatedById]=useState("");
  const [createdByName,setCreatedByName]=useState("");
  const [quotationId,setQuotationId] = useState("");
  const [requirementStatus,setRequirementStatus] = useState("");
  const [ communities,setCommunities] = useState([]);

  //useEffect
  useEffect (()=>{
if(user){
  if(props){
    if(props.openQuoPopUp){
      setOpenQuotationPopUp(props.openQuoPopUp)

    if(props.selectedQuotation){
      setRequirementId(props.selectedQuotation.id);
      setTitle(props.selectedQuotation.title);
      setCreatedById(props.selectedQuotation.createdById);
      setCreatedByName(props.selectedQuotation.createdByName);
      getBids(props.selectedQuotation.id)
    }
  }
  }
}    

  },[props.openQuoPopUp])

const handleClose = ()=>{
  setOpenQuotationPopUp(false)
  props.closeQuoPopUp()
}


useEffect(()=>{

  clearBox()

},[openQuotationPopUp])

//get bid of comunity
const getBids = (id)=>{

  let apiURL=process.env.REACT_APP_API_URL + "/quotation/getbiddings/"+user._id; 
  let apiParams = {
    headers:{
      requirementid:id,
  }}
  fetch(apiURL,apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        
         setQuotationId(repos.data[0] && repos.data[0]._id)
        setEstimatedAmount(repos.data[0] && repos.data[0].estimatedAmount);
       setEstimatedCurrency(repos.data[0] && repos.data[0].estimatedCurrency && repos.data[0].estimatedCurrency);
       setEstimatedTime(repos.data[0] && repos.data[0].estimatedHours);
       setCanBeDelivered(repos.data[0] && repos.data[0].canBeDelivered);
       setRemarks(repos.data[0] && repos.data[0].remarks);
      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}

const clearBox = ()=>{
  
  setEstimatedAmount("")
  setEstimatedTime("")
  setHours("hours")
  setCanBeDelivered(false)
  setRemarks("")
}

const updateQuotation = (id)=>{
  setOpenQuotationPopUp(false)
  let EstimatedTime=0;
  if(hours==="hours"){
      EstimatedTime = estimatedTime;
  }else{
      EstimatedTime = estimatedTime*8;
  }
  let apiURL=process.env.REACT_APP_API_URL + "/quotation/updatequotationdata/"+ id;
  let apiParams = {
      method:"POST",
      headers:{
        requirementid:requirementId,
        estimatedamount:estimatedAmount,
        estimatedcurrency:encodeURIComponent(estimatedCurrency),
        estimatedhours:EstimatedTime,
        canbedelivered:canBeDelivered,
        remarks:encodeURIComponent(remarks),
        userid:user._id,
        username:user.name,
        status:"Updated"
      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
      
        alert("Quatations Updated Successfully");
        handleClose()
      //  setTimeout(() => {
      //   getRequirements()
      // }, 1500);
      
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
}


const addQuotation = ()=>{
   
  setOpenQuotationPopUp(false)
  let EstimatedTime=0;
  if(hours==="hours"){
      EstimatedTime = estimatedTime;
  }else{
      EstimatedTime = estimatedTime*8;
  }
  let apiURL=process.env.REACT_APP_API_URL + "/quotation/addquotation";
  //console.log(JSON.stringify(Currencies.filter(curr=>curr.cc===estimatedCurrency)[0] ))
  let apiParams = {
      method:"POST",
      headers:{
        requirementtitle:title,
        requirementid:requirementId,
        customerid:createdById,
        customername:createdByName,
        estimatedamount:estimatedAmount,
        estimatedcurrency:encodeURIComponent(estimatedCurrency),
        estimatedhours:EstimatedTime,
        canbedelivered:canBeDelivered,
        remarks:encodeURIComponent(remarks),
        vendorid:user._id,
        vendorname:user.name,
        status:"Submitted"
      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        clearBox()
        alert("Quatations Added Successfully");
        handleClose()
      //  setTimeout(() => {
      //   getRequirements()
      // }, 1500);
      
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
}

const cancelQuotation = (id,status) =>{
 
  let apiURL=process.env.REACT_APP_API_URL + "/quotation/updatequotation/"+id;
  let apiParams = {
      method:"POST",
      headers:{
      requirementid:requirementId,
      userid:user._id,
      username:user.name,
      status:status,
      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
       
        alert("Quatation Status Updated Successfully!!");
        handleClose()
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
  
}


  return (
    <div>
        <Modal
        open={openQuotationPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
  <div id="pop_up-2_Req_quo_model">

	<img id="quotation_bg_img" src="n_411.png" srcset="n_411.png 1x, n_411@2x.png 2x" />
		
	<svg class="Quotation_border_pop">
		<rect id="Quotation_border_pop" rx="48" ry="48" x="0" y="0" width="118" height="346">
		</rect>
	</svg>
	<div id="Quotation_lbl_pop">
		<span>Quotation</span>
	</div>
	<input placeholder="Estimated Amount" class="estimated_quo_pop"  value={estimatedAmount} onChange={(e)=> {
         setEstimatedAmount(e.target.value);}}/>
		
	<select class="currency_quo_pop" value={estimatedCurrency}
                                onChange={(e) => {
                                  setEstimatedCurrency(e.target.value);    
                                }}>
        <option>----Select----</option>
            {Currencies &&
              Currencies.map((currency) => (
                <option value={JSON.stringify(currency)}>
                  {" "}
                  {currency.name}
                </option>
              ))}                    
	
	</select>

	<input placeholder="Estimated Time" class="estimated_time_quo_pop" value={estimatedTime} onChange={(e)=> {
         setEstimatedTime(e.target.value);}}/>
	
	<select class="estimated_days_pop_quo" value={hours}
                                onChange={(e) => {
                                  setHours(e.target.value);
                                 
                                }}>
		<option>Hours</option>
    <option>Days</option>
	</select>
	<div id="Group_check_can_be_delivered">
		<input type="checkbox" placeholder="Remarks/Info" class="chck_pop_quo" checked={canBeDelivered} onChange={()=>setCanBeDelivered(!canBeDelivered)}/>
			{/* <rect id="Rectangle_25" rx="0" ry="0" x="0" y="0" width="15" height="15">
			</rect>
		</input> */}
		<div id="Can_be_Delivered_to_location_lbl_quo">
			<span>Can be Delivered to location</span>
		</div>
	</div>
	<textarea placeholder="Remark/Info" class="remarks_quo_pop" value={remarks} onChange={(e)=> {
       setRemarks(e.target.value);   
  }}/>
		
	<img title="Close" id="close_pop_quo" src="close2.png" srcset="close2.png 1x, close2@2x.png 2x" onClick={()=>handleClose()}/>
		
  {quotationId && quotationId.length > 0 ?	
  <div id="Group_btn_up_pop" onClick={()=>updateQuotation(quotationId)}>
		<svg class="border_btn_quo_">
			<rect id="border_btn_quo_" rx="15" ry="15" x="0" y="0" width="101" height="37">
			</rect>
		</svg>
		<div id="Update_lbl_quo_">
			<span>Update</span>
		</div>
	</div>:<div id="Group_btn_up_pop" onClick={()=>addQuotation()}>
		<svg class="border_btn_quo_">
			<rect id="border_btn_quo_" rx="15" ry="15" x="0" y="0" width="101" height="37">
			</rect>
		</svg>
		<div id="Update_lbl_quo_">
			<span>Submit</span>
		</div>
	</div>}
  {quotationId && quotationId.length > 0 ?		<div id="Group_btn_dis_pop" style={{left:quotationId.length < 0 ?"220px":"189px"}} onClick={()=>cancelQuotation(quotationId,"Cancelled")}>
		<svg class="border_btn_dis_">
			<rect id="border_btn_dis_" rx="15" ry="15" x="0" y="0" width="101" height="37">
			</rect>
		</svg>
		<div id="Discard_lbl_pop">
			<span>Discard</span>
		</div>
	</div>:""}
</div>

      </Modal>
    </div>
  )
}

export default QuotationPopUpPC
