import React, { useState, useEffect, useContext } from "react";
// import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider } from "@mui/material";
import "../../styles/ProfileUIPC.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
// import { id } from 'date-fns/locale';
import { UserContext } from "../UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { autocompleteClasses } from "@material-ui/core";
import countries from "../Countries.json";
import Currencies from "../Currencies.json";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import UserInfoUI from "./UserInfoUI";
import ADProfileUI from "./ADProfileUI";
import OfferingProfileFeedPC from "./OfferingProfileFeedPC";
import RequirementProfileFeedPC from "./RequirementProfileFeedPC";
import NotificationProfileUI from "./NotificationProfileUI";
import CalenderProfileUI from "./CalenderProfileUI";
import EventsProfilePCUI from "./EventsProfilePCUI";
import SuggestionProfilePCUI from "./SuggestionProfilePCUI";
import ViewRequirementsUI from "./ViewRequirementsUI";
import ViewOfferingUI from "./ViewOfferingUI";

const ProfileUIPC = (props) => {
  const style6 = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 445,
    maxWidth: 600,
    margin: "auto",
    scrollY: "auto",
    // bgcolor: "background.paper",
    border: "2px solid #000",
  };

  const style7 = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 250,
    maxWidth: 350,
    margin: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
  };

  const label = { inputProps: { "aria-label": "Schedule Interview" } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule] = React.useState();
  const [searchParams] = useSearchParams();

  const { user, setUser } = useContext(UserContext);
  var navigate = useNavigate();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState(new Date());
  const [whatsNumber, setWhatsNumber] = useState("");
  const [img, setImg] = useState("");
  const [gender, setGender] = useState("");
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportingToId, setReportingToId] = useState("");
  const [reportingToName, setReportingToName] = useState("");
  const [accessType, setAccessType] = useState("");
  const [country, setCountry] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [currency, setCurrency] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [profession, setProfession] = useState("");
  const [active, setActive] = useState(true);
  const [confirmPass, setConfirmPass] = useState("");
  const [password, setPassword] = useState("");
  const [pincode, setPincode] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [openChgPwd, setOpenChgPwd] = React.useState(false);
  const [previousPass, setPreviousPass] = useState("");
  const [connections, setConnections] = useState(0);
  const [selectedOffering,setSelectedOffering] = useState(null)
  const [selectedRequirement,setSelectedRequirement] = useState(null)

  // useEffect(() => {
  //   if (user) getUserProfile();
  // }, [user]);

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function(theFile) {
      return function(e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const handleImageUpload = async (e) => {
    // if (e.target.files[0].size > 71680) {
    //   alert("Please choose a file with size below 70kb!");
    //   return;
    // }
    handleFileSelect(e.target.files[0]);
    let formdata = new FormData();
    formdata.append("image", e.target.files[0], e.target.value);
    updateProfilePic(formdata);
  };
  const updateProfilePic = (formdata) => {
    const apiurl =
      process.env.REACT_APP_API_URL + "/users/updateprofilepic/" + user._id;
    var apiparam = {
      method: "POST",
      // headers: {
      //   id: user._id,
      // },
      body: formdata,
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Image Uploaded Successfully!!");
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const getUserProfile = () => {
    let apiURL =
      process.env.REACT_APP_API_URL + "/users/getUserDetails/" + user._id;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          console.log(repos.data);
          setEmployees(repos.data);
          setAddress(repos.data.address);
          setDob(repos.data.dob.substring(0, 10));
          setEmail(repos.data.email);
          setName(repos.data.name);
          setGender(repos.data.gender);
          setMobile(repos.data.phone);
          setCountry(repos.data.country);
          setState(repos.data.state);
          setCity(repos.data.city);
          setPincode(repos.data.pincode);
          setProfession(repos.data.profession);
          setIntroduction(repos.data.introduction);
          setImg(arrayBufferToBase64(repos.data.image.data.data));
          setCurrency(repos.data.currency);
          setHourlyRate(repos.data.hourlyRate);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const UpdateEmployeesDetails = (id) => {
    let apiURL =
      process.env.REACT_APP_API_URL + "/users/updateEmployees/" + user._id;
    let apiParams = {
      method: "POST",
      headers: {
        name: name,
        gender: gender,
        dob: dob,
        mobile: mobile,
        email: email,
        country: country,
        state: state,
        city: city,
        address: encodeURIComponent(address),
        pincode: pincode,
        profession: profession,
        introduction: encodeURIComponent(introduction),
        currency: currency,
        hourlyrate: hourlyRate,
        // password: encodeURIComponent(password),
        // active: active,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setIsLoading(false);
          alert("User Updated Successfully");
          // props.showAlert("User Updated Successfully", "success");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch((err) => alert(err));
  };

  const PasswordChange = () => {
    if (password !== confirmPass) {
      alert("Password and Confirm Password don't match!!");
      return;
    }
    setOpenChgPwd(false);
    let apiURL =
      process.env.REACT_APP_API_URL + "/users/updatePassword/" + user._id;
    let apiParams = {
      method: "POST",
      headers: {
        previouspass: encodeURIComponent(previousPass),
        newpassword: encodeURIComponent(password),
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setIsLoading(false);
          alert("Password Changed Successfully!!!");
          handlelogout();
        } else alert(repos);
      })
      .catch((err) => alert(err));
  };

  const handlelogout = () => {
    localStorage.clear();
    setUser(null);
    window.location.href = "/login";
  };

  const handleClosePwd = () => {
    setOpenChgPwd(false);
  };

  return (
    <>
  { selectedOffering===null && selectedRequirement === null?
     <div className="main-page">
      <div className="container-row">
        <div className="main-content">
        <div style={{overflowY:"scroll",height: "100vh"}}>
          <div className="inner-table">
            <div className="container-row">
              <div className="container-cell">
                <UserInfoUI />
              </div>
            </div>
            <div className="container-row">
              <div className="container-cell">
                <ADProfileUI />
              </div>
            </div>
            <div className="container-row">
              <div className="container-cell">
                <OfferingProfileFeedPC data={(rec)=>setSelectedOffering(rec)}/>
              </div>
            </div>
            <div className="container-row">
              <div className="container-cell">
                <RequirementProfileFeedPC data={(rec)=>setSelectedRequirement(rec)}/>
              </div>
            </div>
            <div className="container-row">
              <div className="container-cell">
                <EventsProfilePCUI />
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="right-content">
        <div style={{overflowY:"scroll",height: "100vh"}}>
        <div className="inner-table">
          <div className="container-row">
            <div className="container-cell">
              <CalenderProfileUI />
            </div>
          </div>
          <div className="container-row">
            <div className="container-cell">
              <NotificationProfileUI />
            </div>
          </div>
          <div className="container-row">
            <div className="container-cell">
              <SuggestionProfilePCUI />
            </div>
          </div>
       </div>
        </div>
        </div>
      </div>
    </div>:
    <div className="main-page">
   
   { selectedOffering ?
   
     <ViewOfferingUI selectedOffering={selectedOffering} selectedOfferingsId={selectedOffering._id} isOffering={selectedOffering.vendorId!== user._id} backOff={()=>setSelectedOffering(null)}/>
     :
     <ViewRequirementsUI selectedRequirement={selectedRequirement} selectedRequirementsId={selectedRequirement._id} isRequirement={selectedRequirement.customerId!== user._id} backReq={()=>setSelectedRequirement(null)}/> 
     }
     </div>
      }
    </>
  );
};

export default ProfileUIPC;
