import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Button, CardActionArea, CardActions } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import '../../styles/CommunitiesOfferingsPageNewUI.css';
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AddCommunitiesOffPopUp from '../AddCommunitiesOffPopUp';
import FooterPcUI from './FooterPcUI';
import MyCommunitiesNewUI from './MyCommunitiesNewUI';
import RequirementsSideUI from './RequirementsSideUI';
import BargainPopUpPC from '../BargainPopUpPC';
import OfferingsViewMorePopUp from '../OfferingsViewMorePopUp';
import ImageSet from './ImageSet';


const CommunitiesOfferingPageNewUI = (props) => {

//useStates
    
    var navigate = useNavigate();
    const { user, setUser} = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const communityId = props.communityId;
    const communityName = props.communityName;
    const [requirements,setRequirements]=useState([]);
    const [ offerings,setOfferings] = useState([]);
    const [allStatus,setAllStatus] = useState([]);
    const [checked, setChecked] = React.useState([true, false]);
    const [openGridModel,setOpenGridModel] = useState(false);
    const [openGridOfferingModel,setOpenGridOfferingModel] = useState(false);
    const [openGridConnectionModel,setOpenGridConnectionModel] = useState(false);
    const [oddRequirements,setOddRequirements] = useState([]);
    const [oddOfferings,setOddOfferings] = useState([]);
    const [oddConnections,setOddConnections] = useState([]);
    const [ selectedCommunity,setSelectedCommunity] = useState([])
    const [communities,setCommunities] = useState([]);
    const [selectedCommunities,setSelectedCommunities] = useState([]);
    const [viewMorePopup,setViewMorePopup] = useState(false);
    const [viewMoreData,setViewMoreData] = useState(false);
    const [openBargainModel,setOpenBargainModel] = useState(false);
    const [openMeetingModel,setOpenMeetingModel] = useState(false);
    const [selectedBargain,setSelectedBargain]=useState({});
   

//UseEffects
useEffect(()=>{
    if(user){
        if(props.communityId){
            // getRequirements()
            getOfferings()
            // getStatus()
        }
    }
    },[props.communityId])



    // //Get offerings by id
const getOfferings = ()=>{
      
  let apiURL=process.env.REACT_APP_API_URL + "/offerings/getofferingsbycommunity/" +user._id ;
  let apiParams = {
      method:"GET",
      headers:{
          communityid:props.communityId,
      }
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
  
      setOfferings(repos.data)
      
      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}


    //Converting Image
function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

     //Get requirement by communityId
  const getRequirements = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getrequirementsbycommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
        setRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


   //Get requirement not in community
   const getRequirementswithoutcommunity = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getrequirementswithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   //Get offering not in community
   const getOfferingsWithoutCommunity = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getofferingwithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddOfferings(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
   //Get connection not in community
   const getConnectionWithoutCommunity = async()=>{
    let apiURL=process.env.REACT_APP_API_URL + "/connection/getconnectionwithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddConnections(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   


  //Get Connection List
  const getStatus = ()=>{
 
    let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatusbycommunity/" + user._id;
    let apiParams = {
      method: "GET",
      headers:{
        communityid:communityId,
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
            
            setAllStatus(repos.data)
            
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

//
  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };



  const RemoveOffering = (offeringId)=>{
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/removeofferingsbycommunity/"+ offeringId._id ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
          getOfferings()

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  const closeGridModel = () => setOpenGridModel(false);
  const closeGridOfferingModel = () => setOpenGridOfferingModel(false);
  const closeGridConnectionModel = () => setOpenGridConnectionModel(false);




  const openGridModal = ()=>{
    setOpenGridModel(true)
    // getRequirementswithoutcommunity()

  }

  const openGridOfferingModal = ()=>{
    setOpenGridOfferingModel(true)
    // getOfferingsWithoutCommunity()

  }

  const openGridConnectionModal = ()=>{
    setOpenGridConnectionModel(true)
    // getConnectionWithoutCommunity()

  }

  const addBid=(id,title,vendorId,vendorName)=>{
    setSelectedBargain({id,title,vendorId,vendorName})
    
    setOpenBargainModel(true);

    }
    

    const openViewMorePopUp = (rec)=>{
      setViewMoreData(rec)
      setViewMorePopup(true)
    }

  

  return (
  <div>
  
  <div id="community_off__1"> 
	<div id="over_all_design_grp_com_off_pc">
  <svg class="all_desgincom_off_pcZ" viewBox="2950.898 1057.904 3088.332 1907">
			<path id="all_desgincom_off_pcZ" d="M 2951 1139.474487304688 L 2951 2964.90380859375 L 6039.23046875 2964.90380859375 L 6039.23046875 1112.510864257812 L 3330.455078125 1112.510864257812 C 3330.455078125 1112.510864257812 3311.71923828125 1115.42236328125 3311.179931640625 1103.3427734375 C 3310.64111328125 1091.26318359375 3311.179931640625 1068.553344726562 3311.179931640625 1068.553344726562 C 3311.179931640625 1068.553344726562 3312.9765625 1057.923461914062 3294.658203125 1057.923461914062 C 3276.33935546875 1057.923461914062 3156.96484375 1057.923461914062 3156.96484375 1057.923461914062 C 3156.96484375 1057.923461914062 3139.146240234375 1056.956909179688 3138.607666015625 1068.553344726562 C 3138.068359375 1080.150024414062 3138.607666015625 1103.3427734375 3138.607666015625 1103.3427734375 C 3138.607666015625 1103.3427734375 3136.810546875 1112.503295898438 3127.59326171875 1112.510864257812 C 3112.50830078125 1112.523315429688 2968.551025390625 1112.510864257812 2968.551025390625 1112.510864257812 C 2968.551025390625 1112.510864257812 2951.227783203125 1111.073486328125 2951 1121.220336914062 C 2950.771484375 1131.367309570312 2951 1139.474487304688 2951 1139.474487304688 Z">
			</path>
		</svg>
	</div>
	<div id="com_off_3_tabs_lbl_com_off_pcZ">
		<div id="Offerings_com_off_pcZ" >
			<span>Offerings</span>
		</div>
		<div id="Requirement_com_off_pcZ" onClick={()=>props.selectedTab('requirements')}>
			<span>Requirement</span>
		</div>
		<div id="Members_com_off_pcZ" onClick={()=>props.selectedTab('members')}>
			<span>Members</span>
		</div>
	</div>

  
    <div id="Scroll_Group_off_">
    { offerings.map((off)=>(
    <div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
	<div id="card_offering_page_details_com" className='fadeInRight'>
		<svg class="full_card_bdr_offering_com_off_">
			<linearGradient id="full_card_bdr_offering_com_off_" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
				<stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#cfffd7" stop-opacity="1"></stop>
			</linearGradient>
			<rect id="full_card_bdr_offering_com_off_" rx="10" ry="10" x="0" y="0" width="199" height="243">
			</rect>
		</svg>
		<svg class="card_bg_design_com_off_pcZ_" viewBox="768 1996.457 199 47.484">
			<linearGradient id="card_bg_design_com_off_pcZ_" spreadMethod="pad" x1="0" x2="1" y1="0" y2="0.639">
				<stop offset="0" stop-color="#cfffd7" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#80ff95" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#cfffd7" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#f8f848" stop-opacity="1"></stop>
			</linearGradient>
			<path id="card_bg_design_com_off_pcZ_" d="M 768 1996.45654296875 L 967 1996.45654296875 L 967 2026.984619140625 L 933.4641723632812 2043.941040039062 L 809.4276123046875 2043.941040039062 L 768 2026.984619140625 L 768 1996.45654296875 Z">
			</path>
		</svg>
		<svg class="lbl_website_off_rec_com_off_pc">
			<rect id="lbl_website_off_rec_com_off_pc" rx="0" ry="0" x="0" y="0" width="169.518" height="20.793">
			</rect>
		</svg>
		<div id="Website_Development_com_off_pc">
			<span>{off.offeringTitle}</span>
		</div>
		<img title="Budget" id="req_budget_img_com_off_pcZ" src="req_budget_img_com_off_pcZ.png" srcset="req_budget_img_com_off_pcZ.png 1x" />
			
		<img title=" Delivery Time" id="req_location_img_com_off_pcZ" src="req_location_img_com_off_pcZ.png" srcset="req_location_img_com_off_pcZ.png 1x" />
			
		<img title="Delivery Location" id="req_duration_img_com_off_pcZ" src="req_duration_img_com_off_pcZ.png" srcset="req_duration_img_com_off_pcZ.png 1x" />
			
		<div id="budget_lbl_card_com_off_pcZ">
			<span>{off.budget}</span>
		</div>
		<div id="delivery_lbl_card_com_off_pcZ">
			<span>{off.deliveryDuration} HR</span>
		</div>
		<div id="Online_Deployment_lbl_card_com">
			<span>{off.deliveryLocation}</span>
		</div>
		<button title="Bargains" id="bid_btn_card_com_off_pcZ" onClick={()=>addBid(off._id,off.offeringTitle,off.vendorId,off.vendorName)}>
			<svg class="bdr_btn_com_off_pcZ">
				<rect id="bdr_btn_com_off_pcZ" rx="13.5" ry="13.5" x="0" y="0" width="87" height="27">
				</rect>
			</svg>
			<div id="Bid_lbl_btn_com_off_pcZ">
				<span>Bargains</span>
			</div>
			<img title="Bids" id="bid_img_card_com_off_pcZ" src="bid_img_card_com_off_pcZ.png" srcset="bid_img_card_com_off_pcZ.png 1x" />
				
		</button>
		<button title="Details" id="details_btn_card_com_off_pcZ" onClick={()=>openViewMorePopUp(off)}>
			<svg class="details_btn_rec_com_off_pcZ">
				<rect id="details_btn_rec_com_off_pcZ" rx="13.5" ry="13.5" x="0" y="0" width="72" height="27">
				</rect>
			</svg>
			<div id="Details_lbl_btn_com_off_pcZ">
				<span>Details</span>
			</div>
			<img title="View More" id="detail_img_btn_com_off_pcZ" src="detail_img_btn_com_off_pcZ.png" srcset="detail_img_btn_com_off_pcZ.png 1x"  />
				
		</button>
   <ImageSet imageArray={off.offeringsImagesUrl}/>
		
	</div>
  </div>

  ))}
  </div>
  <RequirementsSideUI />

</div>


<BargainPopUpPC  openBarPopUp={openBargainModel} selectedBargain={selectedBargain} closeBarPopUp={()=>{setOpenBargainModel(false);}}/>

{/* View More Pop Up */}

<OfferingsViewMorePopUp type="CO" openViewMore={viewMorePopup} viewMoreData={viewMoreData} closeViewMore={()=>setViewMorePopup(false)}/>

{/* off pop up */}
<AddCommunitiesOffPopUp communityId={props.communityId} communityName={props.communityName} openComOff={openGridOfferingModel} closeComOff={()=>{setOpenGridOfferingModel(false);getOfferings()}}/>

</div>

  )
}

export default CommunitiesOfferingPageNewUI
