import React, { useEffect, useState, useContext } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import "../styles/OfferingPopUpPC.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Currencies from "./Currencies.json";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const OfferingPopUpPC = (props) => {
  //useState
  const { user, setUser } = useContext(UserContext);
  const [openOfferingPopUp, setOpenOfferingPopUp] = useState(false);
  const [offeredCurrency, setOfferedCurrency] = useState({});
  const [openModel, setOpenModel] = useState(false);
  const [openMeetingModel, setOpenMeetingModel] = useState(false);
  const [status, setStatus] = useState([]);
  const [biddings, setBiddings] = useState("");
  const [deliveryDeadline, setDeliveryDeadline] = useState(
    new Date().toISOString()
  );
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [description, setDescription] = useState("");
  const [services, setServices] = useState("");
  const [budget, setBudget] = useState("");
  const [remarks, setRemarks] = useState("");
  const [needToBeDelivered, setNeedToBeDelivered] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [hours, setHours] = useState("hours");
  const [bargainedTime, setBargainedTime] = useState("");
  const [bargainedAmount, setBargainedAmount] = useState("");
  const [offeringTime, setOfferingTime] = useState("");
  const [offHours, setOffHours] = useState("hours");
  const [selectedCommunity, setSelectedCommunity] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [requirementId, setRequirementId] = useState("");
  const [offeringId, setOfferingId] = useState("");

  const [minimumOrderQuantity, setMinimumOrderQuantity] = useState("");
  const [offeringType, setOfferingType] = useState("Product");
  const [category, setCategory] = useState("");
  const [redirectionURL, setRedirectionURL] = useState("");

  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [offeringPictures, setOfferingPictures] = useState([]);
  const [bargainedCurrency, setBargainedCurrency] = useState("");
  const [selectedImagePosition, setSelectedImagePosition] = useState(0);
  const [defaultPic,setDefaultPic] = useState("")
  const [centerImage,setCenterImage] = useState("")

  //useEffect
  useEffect(() => {
    if(user){
    if (props) {
      if (props.openOffpopup) setOpenOfferingPopUp(props.openOffpopup);
      if(props.data){
        // console.log(props.data)
        setOfferingId(props.data._id)
        setDescription(props.data.description);
        setServices(props.data.offeringTitle);
        setBudget(props.data.budget);
       setOfferedCurrency(props.data.offeredcurrency && (props.data.offeredcurrency));
        setOfferingType(props.data.offeringType);
        setMinimumOrderQuantity(props.data.minimumOrderQuantity);
        setCategory(props.data.category);
        setRedirectionURL(props.data.redirectionURL);
       setOfferingTime(props.data.deliveryDuration);
        setDeliveryLocation(props.data.deliveryLocation);

      } 
    }
  }
  }, [props.openOffpopup]);

  //useEffect

  

  useEffect(() => {
    if (offeringPictures && offeringPictures.length > 0) {
      setSelectedImagePosition(offeringPictures.length - 1);
      handleLeftFileSelect(offeringPictures[offeringPictures.length - 1]);
      if (offeringPictures.length > 2)
        handleRightFileSelect(offeringPictures[offeringPictures.length - 3]);
      if (offeringPictures.length > 1)
        handleFileSelect(offeringPictures[offeringPictures.length - 2]);
    }
  }, [offeringPictures]);

  useEffect(() => {}, [selectedImagePosition]);

  useEffect(() => {}, [defaultPic]);

  useEffect(() => {}, [offeredCurrency]);

  const UpdateImages = () => {
    if (
      offeringPictures &&
      offeringPictures.length > 0 &&
      selectedImagePosition > -1
    ) {
      handleLeftFileSelect(offeringPictures[selectedImagePosition]);
      if (offeringPictures.length > 2)
        handleRightFileSelect(offeringPictures[offeringPictures.length - 3]);
      if (offeringPictures.length > 1)
        handleFileSelect(offeringPictures[offeringPictures.length - 2]);
    }
  };

  const handleClose = (id) => {
    setOpenOfferingPopUp(false);
    props.closeOffpopup(id);
  };

  //Get my Communities
  const getCommunities = () => {
    let apiURL =
      process.env.REACT_APP_API_URL + "/community/getmycommunity/" + user._id;

    fetch(apiURL)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          // console.log(repos.data)
          setCommunities(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const addOfferings = () => {

    if(services.length === 0){
    return alert("Please Enter Service Or Product Name!!")
    }
    if(budget.length === undefined || budget.length === 0){
      return alert("Please Enter Budget Amount!!")
    }
    if(offeredCurrency.length === undefined || offeredCurrency.length ==="" ){

      return alert("Please Enter Currency type!!")
    }

    if(deliveryLocation.length === 0 || deliveryLocation.length ==="" ){

      return alert("Please Enter Delivery Location!!")
    }
   
    setOpenOfferingPopUp(false);
    let OfferingTime = 0;
    if (offHours === "hours") {
      OfferingTime = offeringTime;
    } else {
      OfferingTime = offeringTime * 8;
    }
    let apiURL = process.env.REACT_APP_API_URL + "/offerings/addoffering";
    let apiParams = {
      method: "POST",
      headers: {
        offeringtitle: services,
        budget: budget,
        offeredcurrency: encodeURIComponent(offeredCurrency),
        deliverylocation:deliveryLocation? encodeURIComponent(deliveryLocation):"",
        description: description ? encodeURIComponent(description):"",
        offeringtype:offeringType,
        category:category,
        redirectionurl:redirectionURL,
        minimumorderquantity:minimumOrderQuantity,
        // selectedcommunity: encodeURIComponent(
        //   JSON.stringify(selectedCommunity)
        // ),
        deliveryduration: OfferingTime,
        vendorid: user._id,
        vendorname: user.name,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          cleartext();
          handleClose();
          alert("Offerings Added Successfully");

          offeringPictures &&
            UploadImageToDrive(repos.data._id, offeringPictures);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };


  const UpdateOfferings = () => {

    if(services.length === 0){
    return alert("Please Enter Service Or Product Name!!")
    }
    if(budget.length === undefined || budget.length === 0){
      return alert("Please Enter Budget Amount!!")
    }
    if(offeredCurrency.length === undefined || offeredCurrency.length ==="" ){

      return alert("Please Enter Currency type!!")
    }

    if(deliveryLocation.length === 0 || deliveryLocation.length ==="" ){

      return alert("Please Enter Delivery Location!!")
    }
   
    setOpenOfferingPopUp(false);
    let OfferingTime = 0;
    if (offHours === "hours") {
      OfferingTime = offeringTime;
    } else {
      OfferingTime = offeringTime * 8;
    }
    let apiURL = process.env.REACT_APP_API_URL + "/offerings/updateOffering/"+offeringId;
    let apiParams = {
      method: "POST",
      headers: {
        offeringtitle: services,
        budget: budget,
        offeredcurrency: encodeURIComponent(offeredCurrency),
        deliverylocation:deliveryLocation? encodeURIComponent(deliveryLocation):"",
        description: description ? encodeURIComponent(description):"",
        offeringtype:offeringType,
        category:category,
        redirectionurl:redirectionURL,
        minimumorderquantity:minimumOrderQuantity,
        // selectedcommunity: encodeURIComponent(
        //   JSON.stringify(selectedCommunity)
        // ),
        deliveryduration: OfferingTime,
        vendorid: user._id,
        vendorname: user.name,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          cleartext();
          handleClose(repos.data._id);
          alert("Offerings Updated Successfully");

          offeringPictures &&
            UploadImageToDrive(repos.data._id, offeringPictures);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const cleartext = () => {
    setServices("");
    setDescription("");
    setDeliveryLocation("");
    setOfferingTime("");
    setBudget("");
    setOfferedCurrency("");
  };

  const handleImageUpload = (e) => {
    if (e.target.files.length > 0) {
      if(defaultPic === ""){
        setDefaultPic(e.target.files[0].name)
      }
      // for(let i=0;i<e.target.files.length;i++)
      // {
       
      // if (e.target.files[i].size > 71680) {
      //   alert("Please choose files with size below 70kb!");
      //   return;
      // }

      // }
      const newImgArray = offeringPictures;
      setOfferingPictures([...newImgArray, ...e.target.files]);
     
    }

  };

  function handleLeftFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function(theFile) {
      return function(e) {
       
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg3("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function(theFile) {
      return function(e) {
        setCenterImage(f.name)
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg2("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  function handleRightFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function(theFile) {
      return function(e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg1("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }
  //Upload Image
  const UploadImageToDrive = (offeringId, document) => {
   
    // Using a forEach loop
    document.forEach(function(images) {
      var file = images; //the file
      var reader = new FileReader(); //this for convert to Base64
      reader.readAsDataURL(images); //start conversion...
      reader.onload = function(e) {
        //.. once finished..
        var rawLog = reader.result.split(",")[1]; //extract only thee file data part

        var dataSend = {
          dataReq: { data: rawLog, name: file.name, type: file.type },
          fname: "uploadFilesToGoogleDrive",
        }; //preapre info to send to API
        fetch(
          process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
          { method: "POST", body: JSON.stringify(dataSend) }
        ) //send to Api
          .then((res) => res.json())
          .then(async (data) => {
            //See response
               
                SaveDocumentsURL(data, offeringId,file.name === defaultPic);
              
           
            alert("Document Uploaded Successfully");
          })
          .catch((err) => console.log(err)); // Or Error in console
      };
    });
  };

  const SaveDocumentsURL = (data, offeringId,isDefault) => {
  
    if (offeringId) {
      let apiURL = process.env.REACT_APP_API_URL + "/offerings/saveOffImages/" +offeringId;
      let apiParams = {
        method: "POST",
        headers: {
          images: data.url,
          isdefault:isDefault
        },
      };
      fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const handleRightImageClick = () => {

   if( offeringPictures.length > 3 && selectedImagePosition <3 ){
  setSelectedImagePosition(selectedImagePosition + 1);
  }else if(offeringPictures.length < (selectedImagePosition+1) ){
    setSelectedImagePosition(selectedImagePosition - 1);
  }

    if (offeringPictures && selectedImagePosition > 2) {   
     
          if(offeringPictures.length >= (selectedImagePosition+1)){
             if(selectedImagePosition == 3){
            
              handleLeftFileSelect(offeringPictures[selectedImagePosition]);
              handleFileSelect(offeringPictures[selectedImagePosition - 1]);
              handleRightFileSelect(offeringPictures[selectedImagePosition - 2]);
             
                setSelectedImagePosition(selectedImagePosition + 1);
          
              
             }else if(selectedImagePosition < 3){
              handleLeftFileSelect(offeringPictures[selectedImagePosition]);
              handleFileSelect(offeringPictures[selectedImagePosition]);
              handleRightFileSelect(offeringPictures[selectedImagePosition - 1]);
           
             }else if(selectedImagePosition > 3){
              handleLeftFileSelect(offeringPictures[selectedImagePosition]);
              handleFileSelect(offeringPictures[selectedImagePosition-1]);
              handleRightFileSelect(offeringPictures[selectedImagePosition - 2]);
                setSelectedImagePosition(selectedImagePosition + 1);
               
             }
         
          }
         
    }
  };

  const handleLeftImageClick = () => {
    
    if(offeringPictures.length < selectedImagePosition){
      setSelectedImagePosition(JSON.parse(selectedImagePosition) - 2);
    }
     if (selectedImagePosition > 3) {
    setSelectedImagePosition(JSON.parse(selectedImagePosition) - 1);
     }
    if (offeringPictures && selectedImagePosition > 0) {
     
      if (selectedImagePosition > 2) {
       
        handleLeftFileSelect(offeringPictures[selectedImagePosition - 1]);
        handleFileSelect(offeringPictures[selectedImagePosition - 2]);
        handleRightFileSelect(offeringPictures[selectedImagePosition - 3]);
      }

    }
  };


  const handledefaultPic = () =>{

  setDefaultPic(centerImage)
  
  if(centerImage){
    alert("Image Has been Set to default")
  }
  
  }

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

  return (
    <div>
      <Modal
        open={openOfferingPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll", textAlign: "center" }}
      >
    <div id="pop_up_offering">

    <div id="Offerings_lbl_offerings_pop">
		<span>Offerings</span>
	</div>
	<svg class="top_bdr_green_i">
		<linearGradient id="top_bdr_green_i" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
			<stop offset="0" stop-color="#86e5a2" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="top_bdr_green_i" rx="0" ry="0" x="0" y="0" width="308" height="77">
		</rect>
	</svg>
	<input placeholder="Services/Product" class="serivs_promise_"  value={services}
            onChange={(e) => {
              setServices(e.target.value);
            }}/>
	
	<input placeholder="Budget Amount" class="budget_amount__offerings_pop"  value={budget}
            onChange={(e) => {
              setBudget(e.target.value);
            }}/>
		
	<select class="idk_offerings_pop"  value={offeredCurrency && offeredCurrency}
            onChange={(e) => {
              setOfferedCurrency(e.target.value);
            }}>
		<option>----Select----</option>
            {Currencies &&
              Currencies.map((currency) => (
                <option value={JSON.stringify(currency)}>
                  {" "}
                  {currency.name}
                </option>
              ))}
	</select>
	<input placeholder="Estimated Duration" class="estimated_duration_offerings_p"  value={offeringTime}
            onChange={(e) => {
              setOfferingTime(e.target.value);
            }}/>
		
	<select class="hrs_place_offerings_pop"  value={offHours}
            onChange={(e) => {
              setOffHours(e.target.value);
            }}>
	
            <option value="hours">Hours</option>
            <option value="days">Days</option>
	</select>
	<input placeholder="Delivery Location" class="del_loc_offerings_pop"  value={deliveryLocation}
            onChange={(e) => {
              setDeliveryLocation(e.target.value);
            }}/>
	
	<input placeholder="Minimum Order Quantity" class="min_order_quantity_offerings_p"  value={minimumOrderQuantity}
            onChange={(e) => {
              setMinimumOrderQuantity(e.target.value);
            }}/>
	
	<textarea placeholder="Description" class="desc_offerings_pop"  value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}/>
		
	
	
	<div id="grp_off_upload_offerings_pop">

		<img id="profile_3_offerings_pop" src={img2 ? img2 : "profilebackground.png"}  />
			
		<img id="profile_2_offerings_pop" src={img1 ? img1 : "profilebackground.png"}
              onClick={() => handleRightImageClick()} />
			
		<img id="profile_1_offerings_pop" src={img3 ? img3 : "profilebackground.png"}
              onClick={() => handleLeftImageClick()} />
			

      <label for="image">
              <input
                id="image"
                type="file"
                name="image"
                multiple
                hidden
                accept="image/*"
                onChange={(e) => handleImageUpload(e)}
              />
              <img
                id="add_offerings_pop"
                width="100"
                height="100"
                src="close.png"
                title="Upload Image"
                alt="Reload?"
              />
            </label>
		{/* <img title="Upload Image" id="add_offerings_pop" src="add_offerings_pop.png" srcset="add_offerings_pop.png 1x" /> */}
			
		<img title="Remove Image" id="cancel_offerings_pop" src="cancel_offerings_pop.png" srcset="cancel_offerings_pop.png 1x" />
			
		<img title="Default" id="accept_offerings_pop" src="accept_offerings_pop.png" srcset="accept_offerings_pop.png 1x" onClick={() => handledefaultPic()}/>
			
	</div>
	<img title="Close" id="close2_offerings_pop" src="close2_offerings_pop.png" srcset="close2_offerings_pop.png 1x"  onClick={() => handleClose()}/>
		
	<div id="pro_and_ser_grp_off">
		<input type="radio" name="offeringtype" class="pro_radio_off" value={offeringType} checked={offeringType==="Product"} onChange={()=>setOfferingType("Product")}/>
			
		<div id="product_lbl_off">
			<span>Product</span>
		</div>
		<input type="radio" name="offeringtype" class="serv_radio_ogg" value={offeringType} checked={offeringType==="Service"} onChange={()=>setOfferingType("Service")}/>
			
		<div id="Service_lbl_off_">
			<span>Service</span>
		</div>
	</div>
	<input placeholder="Category" class="del_loc_offerings_pop_be" value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}/>
	
	<input placeholder="Redirection URL" class="URKL_offerings_input" value={redirectionURL}
            onChange={(e) => {
              setRedirectionURL(e.target.value);
            }}/>
		
  <div id="seb_grp_offerings_pop" onClick={() => {offeringId.length > 0 ?UpdateOfferings():addOfferings()}}>
		<svg class="bdr_sub_offerings_pop">
			<rect id="bdr_sub_offerings_pop" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Submit_offerings_pop">
			<span>{offeringId && offeringId.length > 0 ?"Update":"Submit"}</span>
		</div>
	</div>

</div>
      </Modal>
    </div>
  );
};

export default OfferingPopUpPC;
