import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Button, CardActionArea, CardActions } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import '../../styles/CommunitiesOfferingsPageNewMobUI.css';
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AddCommunitiesOffPopUp from '../AddCommunitiesOffPopUp';
import FooterPcUI from '../PC/FooterPcUI';
import MyCommunitiesNewUI from '../PC/MyCommunitiesNewUI';
import RequirementsSideUI from '../PC/RequirementsSideUI';
import BargainPopUpPC from '../BargainPopUpPC';
import OfferingsViewMorePopUp from '../OfferingsViewMorePopUp';
import ImageSet from '../PC/ImageSet';
import RequirementsBottomMobUI from './RequirementsBottomMobUI';


const CommunitiesOfferingPageNewUI = (props) => {

//useStates
    
    var navigate = useNavigate();
    const { user, setUser} = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const communityId = props.communityId;
    const communityName = props.communityName;
    const [requirements,setRequirements]=useState([]);
    const [ offerings,setOfferings] = useState([]);
    const [allStatus,setAllStatus] = useState([]);
    const [checked, setChecked] = React.useState([true, false]);
    const [openGridModel,setOpenGridModel] = useState(false);
    const [openGridOfferingModel,setOpenGridOfferingModel] = useState(false);
    const [openGridConnectionModel,setOpenGridConnectionModel] = useState(false);
    const [oddRequirements,setOddRequirements] = useState([]);
    const [oddOfferings,setOddOfferings] = useState([]);
    const [oddConnections,setOddConnections] = useState([]);
    const [ selectedCommunity,setSelectedCommunity] = useState([])
    const [communities,setCommunities] = useState([]);
    const [selectedCommunities,setSelectedCommunities] = useState([]);
    const [viewMorePopup,setViewMorePopup] = useState(false);
    const [viewMoreData,setViewMoreData] = useState(false);
    const [openBargainModel,setOpenBargainModel] = useState(false);
    const [openMeetingModel,setOpenMeetingModel] = useState(false);
    const [selectedBargain,setSelectedBargain]=useState({});
   

//UseEffects
useEffect(()=>{
    if(user){
        if(props.communityId){
            // getRequirements()
            getOfferings()
            // getStatus()
        }
    }
    },[props.communityId])



    // //Get offerings by id
const getOfferings = ()=>{
      
  let apiURL=process.env.REACT_APP_API_URL + "/offerings/getofferingsbycommunity/" +user._id ;
  let apiParams = {
      method:"GET",
      headers:{
          communityid:props.communityId,
      }
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
  
      setOfferings(repos.data)
      
      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}


    //Converting Image
function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

     //Get requirement by communityId
  const getRequirements = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getrequirementsbycommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
        setRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


   //Get requirement not in community
   const getRequirementswithoutcommunity = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getrequirementswithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   //Get offering not in community
   const getOfferingsWithoutCommunity = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getofferingwithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddOfferings(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
   //Get connection not in community
   const getConnectionWithoutCommunity = async()=>{
    let apiURL=process.env.REACT_APP_API_URL + "/connection/getconnectionwithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddConnections(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   


  //Get Connection List
  const getStatus = ()=>{
 
    let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatusbycommunity/" + user._id;
    let apiParams = {
      method: "GET",
      headers:{
        communityid:communityId,
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
            
            setAllStatus(repos.data)
            
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

//
  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };



  const RemoveOffering = (offeringId)=>{
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/removeofferingsbycommunity/"+ offeringId._id ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
          getOfferings()

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  const closeGridModel = () => setOpenGridModel(false);
  const closeGridOfferingModel = () => setOpenGridOfferingModel(false);
  const closeGridConnectionModel = () => setOpenGridConnectionModel(false);




  const openGridModal = ()=>{
    setOpenGridModel(true)
    // getRequirementswithoutcommunity()

  }

  const openGridOfferingModal = ()=>{
    setOpenGridOfferingModel(true)
    // getOfferingsWithoutCommunity()

  }

  const openGridConnectionModal = ()=>{
    setOpenGridConnectionModel(true)
    // getConnectionWithoutCommunity()

  }

  const addBid=(id,title,vendorId,vendorName)=>{
    setSelectedBargain({id,title,vendorId,vendorName})
    
    setOpenBargainModel(true);

    }
    

    const openViewMorePopUp = (rec)=>{
      setViewMoreData(rec)
      setViewMorePopup(true)
    }

  

  return (
  <div>
  
  <div id="community_off_mob1">
	<img id="com_off_bg_img_new" src="com_off_bg_img.png" srcset="com_off_bg_img.png 1x"  />
		
  <div id="community_off_side_menu">
	<img id="com_off_bg_side_menu" src="com_off_bg_side_menu.png" srcset="com_off_bg_side_menu.png 1x"  />
		
	<div id="off_com_overall">
		<div id="com_off_req_txt" onClick={()=>props.selectedTab('requirements')}>
			<span>Requirements</span>
		</div>
		<div id="com_off_off_txt">
			<span>Offerings</span>
		</div>
		<div id="com_off_mem_txt_1" onClick={()=>props.selectedTab('members')}>
			<span>Members</span>
		</div>
	</div>
</div>

  
	<div id="com_off_mid_mob">
  { offerings.map((off)=>(
    <div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
		<div id="card_grp_over_all_com_off">
			<svg class="community_page_card_off">
				<rect id="community_page_card_off" rx="13" ry="13" x="0" y="0" width="90%" height="89">
				</rect>
			</svg>
			<img id="community_page_mob_pfp_new" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"profilebackground.png"} />
				
			<div id="community_page_md_zaid">
				<span>{off.offeringTitle}</span>
			</div>
			<svg class="community_page_card_design" viewBox="17.761 54.55 117.404 28.566">
				<path id="community_page_card_design" d="M 17.93937301635742 72.22697448730469 L 54.18844604492188 83.11607360839844 C 54.18844604492188 83.11607360839844 55.80867004394531 78.82169342041016 59.8450927734375 74.31443023681641 C 62.31302261352539 71.55460357666016 65.82218933105469 67.43408966064453 74.36148071289062 66.66352081298828 C 77.51936340332031 66.37856292724609 81.06272125244141 66.79751586914062 83.42662048339844 67.50247192382812 C 84.86332702636719 67.92860412597656 87.85828399658203 69.11642456054688 89.34918975830078 69.92697143554688 C 98.34956359863281 74.81540679931641 100.6986465454102 83.11607360839844 100.6986465454102 83.11607360839844 L 111.5691680908203 79.64503479003906 L 134.8008728027344 72.22697448730469 C 134.8008728027344 72.22697448730469 138.4102020263672 53.33117294311523 122.8901214599609 54.61224365234375 C 107.3700561523438 55.89331817626953 27.96507453918457 54.61224365234375 27.96507453918457 54.61224365234375 C 27.96507453918457 54.61224365234375 21.55619812011719 54.73288345336914 18.70760536193848 60.51025772094727 C 17.75597381591797 62.43505477905273 17.56718254089355 65.52426147460938 17.93937301635742 69.37132263183594 C 18.33065032958984 73.41566467285156 17.89220428466797 71.28224182128906 17.93937301635742 72.22697448730469 C 18.02732086181641 73.85850524902344 17.93937301635742 72.22697448730469 17.93937301635742 72.22697448730469 Z">
				</path>
			</svg>

			{/* <img id="com_off_minus_new" src="com_off_minus_mob.png" srcset="com_off_minus_mob.png 1x" /> */}
				
			<img title="Profession" id="com_off_budget_png_new" src="com_off_budget_png_mob.png" srcset="com_off_budget_png_mob.png 1x" />
				
			<img title="Joined" id="com_off_delivery_png_new" src="com_off_delivery_png_mob.png" srcset="com_off_delivery_png_mob.png 1x" />
				
			<div id="com_off_budget_txt_new">
				<span>{off.budget}</span>
			</div>
			<div id="com_off_dep_txt_new">
				<span>{off.deliveryLocation}</span>
			</div>
			<img title="Joined" id="com_off_location_png_new" src="com_off_location_png_mob.png" srcset="com_off_location_png_mob.png 1x" />
				
			<div id="com_off__timg_txt">
				<span>{off.deliveryDuration} HR</span>
			</div>
		</div>
    </div>
))}
	</div>
<RequirementsBottomMobUI />
</div>

{openBargainModel?
<BargainPopUpPC  openBarPopUp={openBargainModel} selectedBargain={selectedBargain} closeBarPopUp={()=>{setOpenBargainModel(false);}}/>
:""}
{/* View More Pop Up */}
{viewMorePopup?
<OfferingsViewMorePopUp type="CO" openViewMore={viewMorePopup} viewMoreData={viewMoreData} closeViewMore={()=>setViewMorePopup(false)}/>
:""}
{/* off pop up */}
{openGridOfferingModel?
<AddCommunitiesOffPopUp communityId={props.communityId} communityName={props.communityName} openComOff={openGridOfferingModel} closeComOff={()=>{setOpenGridOfferingModel(false);getOfferings()}}/>
:""}
</div>

  )
}

export default CommunitiesOfferingPageNewUI
