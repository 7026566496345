import React from 'react';
import "../../../styles/CaptureSphereMob.css";

function CaptureSphereMob(props) {
  return (
    <div id="CaptureSphere_Mob">
      <div id="capTure_25_MobGroup_15085">
        <svg class="capTure_25_MobRectangle_749">
          <rect id="capTure_25_MobRectangle_749" rx="10" ry="10" x="0" y="0" width="390" height="495">
          </rect>
        </svg>
        <div id="capTure_25_MobWe_are_Experienced">
          <span>We are Experienced</span>
        </div>
        <div id="capTure_25_MobPhotographers">
          <span>Photographers</span>
        </div>
        <img id="capTure_25_MobSubtraction_8" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/CaptureSphere/Anime_Edit.png"} />
      
        <div id="capTure_25_MobOperate_Live">
          <span>{props.data.businessName}</span>
        </div>
        <div id="capTure_25_MobGroup_15077">
          <img id="capTure_25_MobMask_Group_83" src="./Themes/CaptureSphere/Mask_Group_83.png" srcset="./Themes/CaptureSphere/Mask_Group_83.png 1x" />
            
          <img id="capTure_25_MobMask_Group_84" src="./Themes/CaptureSphere/Mask_Group_84.png" srcset="./Themes/CaptureSphere/Mask_Group_84.png 1x" />
            
          <img id="capTure_25_MobMask_Group_85" src="./Themes/CaptureSphere/Mask_Group_85.png" srcset="./Themes/CaptureSphere/Mask_Group_85.png 1x" />
            
          <img id="capTure_25_MobMask_Group_86" src="./Themes/CaptureSphere/Mask_Group_86.png" srcset="./Themes/CaptureSphere/Mask_Group_86.png 1x" />
            
          <img id="capTure_25_MobMask_Group_87" src="./Themes/CaptureSphere/Mask_Group_87.png" srcset="./Themes/CaptureSphere/Mask_Group_87.png 1x" />
            
          <img id="capTure_25_MobMask_Group_88" src="./Themes/CaptureSphere/Mask_Group_88.png" srcset="./Themes/CaptureSphere/Mask_Group_88.png 1x" />

          <img id="capTure_25_MobMask_Group_89" src="./Themes/CaptureSphere/Mask_Group_89.png" srcset="./Themes/CaptureSphere/Mask_Group_89.png 1x" />
            
        </div>
      </div>
      <div id="capTure_25_MobGroup_15084">
        <svg class="capTure_25_MobRectangle_391">
          <rect id="capTure_25_MobRectangle_391" rx="0" ry="0" x="0" y="0" width="390" height="1096.896">
          </rect>
        </svg>
        <div id="capTure_25_MobGroup_57">
          <div id="capTure_25_MobWe_are__Professional">
            <span>We are</span><span style={{fontStyle:"normal",fontWeight:"normal"}}> </span><br/><span style={{fontStyle:"normal",fontWeight:"normal",fontSize:"50px"}}>Professional</span>
          </div>
          <div id="capTure_25_Mobphotographers">
            <span>photographers</span>
          </div>
        </div>
        <div id="capTure_25_MobScroll_Group_85">
          <div id="capTure_25_MobLorem_Ipsum_is_simply_dummy_te">
            <span>{props.data.introduction}</span>
          </div>
        </div>
        <div id="capTure_25_MobScroll_Group_86">
          <div id="capTure_25_MobGroup_15078">
            <div id="capTure_25_Moboffering_card_1_shadow_space_w">
              <svg class="capTure_25_Mobcard_bg_offeringshadow_space_w">
                <rect id="capTure_25_Mobcard_bg_offeringshadow_space_w" rx="10" ry="10" x="0" y="0" width="281.482" height="397.746">
                </rect>
              </svg>
              <svg class="capTure_25_Mobhalf_circle_shadow_space_websi" viewBox="16.614 0 22 42">
                <path id="capTure_25_Mobhalf_circle_shadow_space_websi" d="M 17.67409896850586 0 C 29.23910713195801 0 38.61439895629883 9.402020454406738 38.61439895629883 21 C 38.61439895629883 32.59798049926758 29.23910713195801 42 17.67409896850586 42 C 16.38000297546387 42 16.14710998535156 0 17.67409896850586 0 Z">
                </path>
              </svg>
              <svg class="capTure_25_Mobcircle_deign_shadow_space_webs">
                <ellipse id="capTure_25_Mobcircle_deign_shadow_space_webs" rx="4.283419609069824" ry="4.283419609069824" cx="4.283419609069824" cy="4.283419609069824">
                </ellipse>
              </svg>
              <div id="capTure_25_Moboofering_lbl_med_shadow_space_">
                <span>Vision</span>
              </div>
              <div id="capTure_25_MobScroll_Group_82">
                <div id="capTure_25_Mobintro_lbl_14_web_pc">
                  <span>{props.data.vision}</span>
                </div>
              </div>
            </div>
            <div id="capTure_25_Moboffering_card_1_shadow_space_w_bz">
              <svg class="capTure_25_Mobcard_bg_offeringshadow_space_w_b">
                <rect id="capTure_25_Mobcard_bg_offeringshadow_space_w_b" rx="10" ry="10" x="0" y="0" width="281.482" height="397.746">
                </rect>
              </svg>
              <svg class="capTure_25_Mobhalf_circle_shadow_space_websi_b" viewBox="16.614 0 22 42">
                <path id="capTure_25_Mobhalf_circle_shadow_space_websi_b" d="M 17.67409896850586 0 C 29.23910713195801 0 38.61439895629883 9.402020454406738 38.61439895629883 21 C 38.61439895629883 32.59798049926758 29.23910713195801 42 17.67409896850586 42 C 16.38000297546387 42 16.14710998535156 0 17.67409896850586 0 Z">
                </path>
              </svg>
              <svg class="capTure_25_Mobcircle_deign_shadow_space_webs_b">
                <ellipse id="capTure_25_Mobcircle_deign_shadow_space_webs_b" rx="4.283419609069824" ry="4.283419609069824" cx="4.283419609069824" cy="4.283419609069824">
                </ellipse>
              </svg>
              <div id="capTure_25_Moboofering_lbl_med_shadow_space__b">
                <span>Mission</span>
              </div>
              <div id="capTure_25_MobScroll_Group_83">
                <div id="capTure_25_Mobintro_lbl_14_web_pc_b">
                  <span>{props.data.mission}</span>
                </div>
              </div>
            </div>
            <div id="capTure_25_Moboffering_card_1_shadow_space_w_b">
              <svg class="capTure_25_Mobcard_bg_offeringshadow_space_w_ca">
                <rect id="capTure_25_Mobcard_bg_offeringshadow_space_w_ca" rx="10" ry="10" x="0" y="0" width="281.482" height="397.746">
                </rect>
              </svg>
              <svg class="capTure_25_Mobhalf_circle_shadow_space_websi_ca" viewBox="16.614 0 22 42">
                <path id="capTure_25_Mobhalf_circle_shadow_space_websi_ca" d="M 17.67409896850586 0 C 29.23910713195801 0 38.61439895629883 9.402020454406738 38.61439895629883 21 C 38.61439895629883 32.59798049926758 29.23910713195801 42 17.67409896850586 42 C 16.38000297546387 42 16.14710998535156 0 17.67409896850586 0 Z">
                </path>
              </svg>
              <svg class="capTure_25_Mobcircle_deign_shadow_space_webs_ca">
                <ellipse id="capTure_25_Mobcircle_deign_shadow_space_webs_ca" rx="4.283419609069824" ry="4.283419609069824" cx="4.283419609069824" cy="4.283419609069824">
                </ellipse>
              </svg>
              <div id="capTure_25_Moboofering_lbl_med_shadow_space__ca">
                <span>Principles</span>
              </div>
              <div id="capTure_25_MobScroll_Group_84">
                <div id="capTure_25_Mobintro_lbl_14_web_pc_cc">
                  <span>{props.data.principles}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="capTure_25_MobGroup_15083">
        <svg class="capTure_25_MobRectangle_489">
          <rect id="capTure_25_MobRectangle_489" rx="0" ry="0" x="0" y="0" width="390" height="1754.229">
          </rect>
        </svg>
        <div id="capTure_25_MobEvents_we_Cover">
          <span>Events we Cover</span>
        </div>
        <div id="capTure_25_MobGroup_15079">
          <img id="capTure_25_MobMask_Group_106" src="./Themes/CaptureSphere/Mask_Group_106.png" srcset="./Themes/CaptureSphere/Mask_Group_106.png 1x" />
            
          <img id="capTure_25_MobMask_Group_108" src="./Themes/CaptureSphere/Mask_Group_108.png" srcset="./Themes/CaptureSphere/Mask_Group_108.png 1x" />
            
          <img id="capTure_25_MobMask_Group_111" src="./Themes/CaptureSphere/Mask_Group_111.png" srcset="./Themes/CaptureSphere/Mask_Group_111.png 1x" />
            
          <img id="capTure_25_MobMask_Group_112" src="./Themes/CaptureSphere/Mask_Group_112.png" srcset="./Themes/CaptureSphere/Mask_Group_112.png 1x" />
            
          <img id="capTure_25_MobMask_Group_115" src="./Themes/CaptureSphere/Mask_Group_115.png" srcset="./Themes/CaptureSphere/Mask_Group_115.png 1x" />
            
          <img id="capTure_25_MobMask_Group_116" src="./Themes/CaptureSphere/Mask_Group_116.png" srcset="./Themes/CaptureSphere/Mask_Group_116.png 1x" />
            
        </div>
        <div id="capTure_25_MobGroup_60">
          <svg class="capTure_25_MobRectangle_196">
            <rect id="capTure_25_MobRectangle_196" rx="0" ry="0" x="0" y="0" width="239" height="50">
            </rect>
          </svg>
          <div id="capTure_25_MobGroup_15080">
            <div id="capTure_25_MobLoad_more_Images">
              <span>Load more Images</span>
            </div>
            <div id="capTure_25_MobGroup_13">
              <svg class="capTure_25_MobPath_13" viewBox="0 0 13.098 6.549">
                <path id="capTure_25_MobPath_13" d="M 0 6.54931640625 L 6.549072265625 0 L 13.0980224609375 6.54931640625">
                </path>
              </svg>
              <svg class="capTure_25_MobLine_14" viewBox="0 0 19.451 1">
                <path id="capTure_25_MobLine_14" d="M 19.451171875 0 L 0 0">
                </path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div id="capTure_25_MobGroup_15082">
        <svg class="capTure_25_MobRectangle_747">
          <rect id="capTure_25_MobRectangle_747" rx="10" ry="10" x="0" y="0" width="390" height="664">
          </rect>
        </svg>
        <div id="capTure_25_MobGroup_15081">
          <img id="capTure_25_Mobkisspng-paper-area-rectangle-s" src="./Themes/CaptureSphere/kisspng-paper-area-rectangle-s.png" srcset="./Themes/CaptureSphere/kisspng-paper-area-rectangle-s.png 1x" />
            
          <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="capTure_25_MobRectangle_748">
            <rect id="capTure_25_MobRectangle_748" rx="0" ry="0" x="0" y="0" width="314" height="209">
            </rect>
          </iframe>
        </div>
        <div id="capTure_25_MobGroup_15023">
          <div id="capTure_25_Mobsocials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
            <div title="LinkedIn" id="capTure_25_Moblinked_in_grp_shadow_space_web">
              <svg class="capTure_25_Mobmed_shadow_space_website_7">
                <ellipse id="capTure_25_Mobmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
              </svg>
              <div id="capTure_25_Moblinked_in_shadow_space_website">
                <a href={props.data.linkedInUrl} target="_blank">
                <svg class="capTure_25_Mobn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
                  <path id="capTure_25_Mobn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
                  </path>
                </svg>
              </a>
                <svg class="capTure_25_Moblinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
                  <path id="capTure_25_Moblinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
                  </path>
                </svg>
                <svg class="capTure_25_Mobtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
                  <path id="capTure_25_Mobtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
                  </path>
                </svg>
              </div>
            </div>:""}
			{props.data.instagramUrl?	
            <div title="Instagram" id="capTure_25_MobInsta_grp_shadow_space_website">
              <svg class="capTure_25_Mobshadow_space_website_7">
                <ellipse id="capTure_25_Mobshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
              </svg>
              <a href={props.data.instagramUrl} target="_blank">
              <img id="capTure_25_Mobinstagramshadow_space_website_" src="./Themes/CaptureSphere/instagramshadow_space_website_.png" srcset="./Themes/CaptureSphere/instagramshadow_space_website_.png 1x" />
                
              </a>
            </div>:""}
			{props.data.whatsAppNumber?
            <div title="WhatsApp" id="capTure_25_Mobwhatsapp_grp_shadow_space_webs">
              <svg class="capTure_25_Mobmed_shadow_space_website_7_db">
                <ellipse id="capTure_25_Mobmed_shadow_space_website_7_db" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
              </svg>
              <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				 <img id="capTure_25_Mobwhatsapp_lbl_shadow_space_webs" src="./Themes/CaptureSphere/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/CaptureSphere/whatsapp_lbl_shadow_space_webs.png 1x" />
                </a>
            </div>:""}
          </div>
          <div id="capTure_25_Mobn_234567890_mobile_number_shad">
            <span>{props.data.businessPhone}</span>
          </div>
          <div id="capTure_25_Mobaffanghanioperatelive_email_sh">
            <span>{props.data.businessEmail}</span>
          </div>
          <div id="capTure_25_Mobaddress_med_shadow_space_websi">
            <span>{props.data.businessAddress}</span>
          </div>
          <img id="capTure_25_Moblocation_img_shadow_space_webs" src="./Themes/CaptureSphere/location_img_shadow_space_webs.png" srcset="./Themes/CaptureSphere/location_img_shadow_space_webs.png 1x" />
            
          <img id="capTure_25_Mobemail_address_shadow_space_web" src="./Themes/CaptureSphere/email_address_shadow_space_web.png" srcset="./Themes/CaptureSphere/email_address_shadow_space_web.png 1x" />
            
          <img id="capTure_25_Mobphone_number_shadow_space_webs" src="./Themes/CaptureSphere/phone_number_shadow_space_webs.png" srcset="./Themes/CaptureSphere/phone_number_shadow_space_webs.png 1x" />
            
          <div id="capTure_25_Mobn_Copyright_Operate_Live_OPC_P">
            <span>© Copyright Operate Live (OPC) Private Limited. <br/>All Rights Reserved</span>
          </div>
        </div>
        <div id="capTure_25_MobVisit_Us_">
          <span>Visit Us !!</span>
        </div>
      </div>
    </div>
  )
}

export default CaptureSphereMob