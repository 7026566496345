import React, { useState, useEffect, useContext } from "react";
import "../../../styles/SharpStylesMob.css";

function SharpStylesMob(props) {
  return (
    <div id="SharpStyles_Mob">
	<div id="SharpStyles_18_mobGroup_15027">
		<div id="SharpStyles_18_mobGroup_15021">
			<svg class="SharpStyles_18_mobRectangle_724">
				<rect id="SharpStyles_18_mobRectangle_724" rx="20" ry="20" x="0" y="0" width="180.531" height="171.935">
				</rect>
			</svg>
			<img id="SharpStyles_18_mobMy_project" src="./Themes/SharpStyles/My_project.png" srcset="./Themes/SharpStyles/My_project.png 1x" />
				
			<img id="SharpStyles_18_mobtrimmer" src="./Themes/SharpStyles/trimmer.png" srcset="./Themes/SharpStyles/trimmer.png 1x" />
				
		</div>
		<img id="SharpStyles_18_mobAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/SharpStyles/Anime_Edit.png"} />
			
		<div id="SharpStyles_18_mobOni_Chan_Haircut">
			<span>{props.data.businessName && props.data.businessName.length > 0?props.data.businessName:props.data.name}</span>
		</div>
		<div id="SharpStyles_18_mobGroup_15022">
			<div id="SharpStyles_18_mobWe_are_Desperate_to_make_you_M">
				<span style={{textTransform:"capitalize"}}>We are Desperate<br/>to make you More </span><br/><span style={{color:"rgba(123,153,166,1)",textTransform:"capitalize"}}>handsome</span>
			</div>
			<div id="SharpStyles_18_mobYou_will_leave_looking_sharp_r">
				<span>You will leave looking sharp, relaxed and ready to<br/>take on the world, with that swagger in your stride.</span>
			</div>
		</div>
	</div>
	<div id="SharpStyles_18_mobGroup_15029">
		<div id="SharpStyles_18_mobGroup_14870">
			<svg class="SharpStyles_18_mobRectangle_683">
				<rect id="SharpStyles_18_mobRectangle_683" rx="0" ry="0" x="0" y="0" width="305" height="438">
				</rect>
			</svg>
			<img id="SharpStyles_18_mobagustin-fernandez-Znyjl9pbaUs-" src="./Themes/SharpStyles/agustin-fernandez-Znyjl9pbaUs-.png" srcset="./Themes/SharpStyles/agustin-fernandez-Znyjl9pbaUs-.png 1x" />
				
			<div id="SharpStyles_18_mobVISION">
				<span>VISION</span>
			</div>
			<div id="SharpStyles_18_mobScroll_Group_58">
				<div id="SharpStyles_18_mobLorem_ipsum_dolor_sit_amet_con">
					<span>{props.data.vision}</span>
				</div>
			</div>
		</div>
		<div id="SharpStyles_18_mobGroup_14877">
			<svg class="SharpStyles_18_mobRectangle_683_dj">
				<rect id="SharpStyles_18_mobRectangle_683_dj" rx="0" ry="0" x="0" y="0" width="305" height="438">
				</rect>
			</svg>
			<img id="SharpStyles_18_mobnathon-oski-EW_rqoSdDes-unspla" src="./Themes/SharpStyles/nathon-oski-EW_rqoSdDes-unspla.png" srcset="./Themes/SharpStyles/nathon-oski-EW_rqoSdDes-unspla.png 1x" />
				
			<div id="SharpStyles_18_mobMISSION">
				<span>MISSION</span>
			</div>
			<div id="SharpStyles_18_mobLorem_ipsum_dolor_sit_amet_con_dm">
				<span>{props.data.mission}</span>
			</div>
		</div>
		<div id="SharpStyles_18_mobGroup_14878">
			<svg class="SharpStyles_18_mobRectangle_683_do">
				<rect id="SharpStyles_18_mobRectangle_683_do" rx="0" ry="0" x="0" y="0" width="305" height="438">
				</rect>
			</svg>
			<img id="SharpStyles_18_mobapothecary-87-Wg3J83R1YSQ-unsp" src="./Themes/SharpStyles/apothecary-87-Wg3J83R1YSQ-unsp.png" srcset="./Themes/SharpStyles/apothecary-87-Wg3J83R1YSQ-unsp.png 1x" />
				
			<div id="SharpStyles_18_mobPRINCIPLES">
				<span>PRINCIPLES</span>
			</div>
			<div id="SharpStyles_18_mobScroll_Group_57">
				<div id="SharpStyles_18_mobLorem_ipsum_dolor_sit_amet_con_ds">
					<span>{props.data.principles}</span>
				</div>
			</div>
		</div>
	</div>
	<div id="SharpStyles_18_mobScroll_services_website_6_mob">
	{props.offeringsData.map((off,idx)=>(
		<div id="SharpStyles_18_mobmy_services_card_1_website_6_m">
			<svg class="SharpStyles_18_mobservices_card_bdr_website_6_mo">
				<rect id="SharpStyles_18_mobservices_card_bdr_website_6_mo" rx="10" ry="10" x="0" y="0" width="174" height="242">
				</rect>
			</svg>
			<img id="SharpStyles_18_mobimg_offering_website_6_mob" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/SharpStyles/img_offering_website_6_mob.png"} />
				
			<svg class="SharpStyles_18_mobcircle_deign_shadow_space_webs">
				<ellipse id="SharpStyles_18_mobcircle_deign_shadow_space_webs" rx="2.5" ry="2.5" cx="2.5" cy="2.5">
				</ellipse>
			</svg>
			<div id="SharpStyles_18_mobMy_Offerings_lbl_website_6_mob">
				<span>{off.offeringTitle}</span>
			</div>
		</div>))}
	</div>
	<div id="SharpStyles_18_mobOur_Services">
		<span>Our Services</span>
	</div>
	<div id="SharpStyles_18_mobGallery">
		<span>Gallery</span>
	</div>
	<div id="SharpStyles_18_mobGroup_15019">
		<img id="SharpStyles_18_mobobi-pixel7propix--sRVfY0f2d8-u" src="./Themes/SharpStyles/obi-pixel7propix--sRVfY0f2d8-u.png" srcset="./Themes/SharpStyles/obi-pixel7propix--sRVfY0f2d8-u.png 1x" />
			
		<img id="SharpStyles_18_moballef-vinicius-IvQeAVeJULw-uns" src="./Themes/SharpStyles/allef-vinicius-IvQeAVeJULw-uns.png" srcset="./Themes/SharpStyles/allef-vinicius-IvQeAVeJULw-uns.png 1x" />
			
		<img id="SharpStyles_18_mobarthur-humeau-Twd3yaqA2NM-unsp" src="./Themes/SharpStyles/arthur-humeau-Twd3yaqA2NM-unsp.png" srcset="./Themes/SharpStyles/arthur-humeau-Twd3yaqA2NM-unsp.png 1x" />
			
		<img id="SharpStyles_18_mobagustin-fernandez-Znyjl9pbaUs-_d" src="./Themes/SharpStyles/agustin-fernandez-Znyjl9pbaUs-_d.png" srcset="./Themes/SharpStyles/agustin-fernandez-Znyjl9pbaUs-_d.png 1x" />
			
		<img id="SharpStyles_18_mobdelfina-pan-wJoB8D3hnzc-unspla" src="./Themes/SharpStyles/delfina-pan-wJoB8D3hnzc-unspla.png" srcset="./Themes/SharpStyles/delfina-pan-wJoB8D3hnzc-unspla.png 1x" />
			
		<img id="SharpStyles_18_mobnathon-oski-fE42nRlBcG8-unspla" src="./Themes/SharpStyles/nathon-oski-fE42nRlBcG8-unspla.png" srcset="./Themes/SharpStyles/nathon-oski-fE42nRlBcG8-unspla.png 1x" />
			
	</div>
	<div id="SharpStyles_18_mobGroup_15025">
		<div id="SharpStyles_18_mobGroup_15023">
			<div id="SharpStyles_18_mobsocials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
				<div title="LinkedIn" id="SharpStyles_18_moblinked_in_grp_shadow_space_web">
					<svg class="SharpStyles_18_mobmed_shadow_space_website_7">
						<ellipse id="SharpStyles_18_mobmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="SharpStyles_18_moblinked_in_shadow_space_website">
				<a href={props.data.linkedInUrl} target="_blank">
						<svg class="SharpStyles_18_mobn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="SharpStyles_18_mobn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="SharpStyles_18_moblinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="SharpStyles_18_moblinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="SharpStyles_18_mobtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="SharpStyles_18_mobtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>
			:""}
			{props.data.instagramUrl?	
				<div title="Instagram" id="SharpStyles_18_mobInsta_grp_shadow_space_website">
					<svg class="SharpStyles_18_mobshadow_space_website_7">
						<ellipse id="SharpStyles_18_mobshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
				<a href={props.data.instagramUrl} target="_blank">
					<img id="SharpStyles_18_mobinstagramshadow_space_website_" src="./Themes/SharpStyles/instagramshadow_space_website_.png" srcset="./Themes/SharpStyles/instagramshadow_space_website_.png 1x" />
						
					</a>
				</div>:""}
			{props.data.whatsAppNumber?
				<div title="WhatsApp" id="SharpStyles_18_mobwhatsapp_grp_shadow_space_webs">
					<svg class="SharpStyles_18_mobmed_shadow_space_website_7_en">
						<ellipse id="SharpStyles_18_mobmed_shadow_space_website_7_en" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="SharpStyles_18_mobwhatsapp_lbl_shadow_space_webs" src="./Themes/SharpStyles/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/SharpStyles/whatsapp_lbl_shadow_space_webs.png 1x" />
						</a>
				</div>:""}
			</div>
			<div id="SharpStyles_18_mobn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="SharpStyles_18_mobaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="SharpStyles_18_mobaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="SharpStyles_18_moblocation_img_shadow_space_webs" src="./Themes/SharpStyles/location_img_shadow_space_webs.png" srcset="./Themes/SharpStyles/location_img_shadow_space_webs.png 1x" />
				
			<img id="SharpStyles_18_mobemail_address_shadow_space_web" src="./Themes/SharpStyles/email_address_shadow_space_web.png" srcset="./Themes/SharpStyles/email_address_shadow_space_web.png 1x" />
				
			<img id="SharpStyles_18_mobphone_number_shadow_space_webs" src="./Themes/SharpStyles/phone_number_shadow_space_webs.png" srcset="./Themes/SharpStyles/phone_number_shadow_space_webs.png 1x" />
				
			<div id="SharpStyles_18_mobn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited. <br/>All Rights Reserved</span>
			</div>
		</div>
		<div id="SharpStyles_18_mobVisit_Us_and_Get_the_Stylist_H">
			<span>Visit Us and Get the Stylist Haircut !!</span>
		</div>
		<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="SharpStyles_18_mobRectangle_735">
			<rect id="SharpStyles_18_mobRectangle_735" rx="0" ry="0" x="0" y="0" width="341" height="222">
			</rect>
		</iframe>
	</div>
</div>
  )
}

export default SharpStylesMob
