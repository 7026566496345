import React from 'react'
import "../../../styles/FeatheredScapeMob.css";

function FeatheredScapeMob(props) {
  return (
    <div id="FeatheredScapeMob">
	<div id="n_14_webmobGroup_14927">
		<img id="n_14_webmobstyle_3" src="./Themes/FeatheredScape/style_3.png" srcset="./Themes/FeatheredScape/style_3.png 1x" />
			
		<svg class="n_14_webmobRectangle_84">
			<rect id="n_14_webmobRectangle_84" rx="0" ry="0" x="0" y="0" width="67" height="23">
			</rect>
		</svg>
		<div id="n_14_webmobDiscover_a_new_world_of_birdin">
			<span>"Discover a new world of birding!"</span>
		</div>
		<div id="n_14_webmobExplore_Now_">
			<span>Explore Now !</span>
		</div>
		<div id="n_14_webmobScroll_Group_42">
			<div id="n_14_webmobintro_part">
				<span>There is a powerful need for symbolism, and that means the architecture must have something that appeals to the human heart.</span>
			</div>
		</div>
		<img id="n_14_webmobzane-lee-bXbi1jJVDf0-unsplash_" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/FeatheredScape/zane-lee-bXbi1jJVDf0-unsplash_.png"} />
			
		<div id="n_14_webmobOperate_Live">
			<span>{props.data.businessName||""}</span>
		</div>
	</div>
	<div id="n_14_webmobGroup_14943">
		<img id="n_14_webmobmark-olsen-tjZPseTxe6k-unsplas" src="./Themes/FeatheredScape/mark-olsen-tjZPseTxe6k-unsplas.png" srcset="./Themes/FeatheredScape/mark-olsen-tjZPseTxe6k-unsplas.png 1x" />
			
		<div id="n_14_webmobEmbark_on_an_Avian_Adventure_E">
			<span>"Embark on an Avian Adventure: Exploring the Enchanting Realm of Birds, One Feathered Friend at a Time!"</span>
		</div>
		<div id="n_14_webmobGroup_14929">
			<svg class="n_14_webmobRectangle_89">
				<rect id="n_14_webmobRectangle_89" rx="0" ry="0" x="0" y="0" width="204" height="48">
				</rect>
			</svg>
			<div id="n_14_webmobKnow_More_About_Us">
				<span>Know More About Us</span>
			</div>
		</div>
	</div>
	<div id="n_14_webmobGroup_14953">
		<div id="n_14_webmobCard_01_website_3">
			<div id="n_14_webmobScroll_Group_10">
				<div id="n_14_webmobintro_website_3">
					<span>{props.data.vision}</span>
				</div>
			</div>
			<div id="n_14_webmobVision_website_3">
				<span>Vision</span>
			</div>
			<img id="n_14_webmobaarn-giri-3lGi0BXJ1W0-unsplash" src="./Themes/FeatheredScape/aarn-giri-3lGi0BXJ1W0-unsplash.png" srcset="./Themes/FeatheredScape/aarn-giri-3lGi0BXJ1W0-unsplash.png 1x" />
				
		</div>
		<div id="n_14_webmobCard_02_website_3">
			<div id="n_14_webmobScroll_Group_11">
				<div id="n_14_webmobintro_2_website_3">
					<span>{props.data.mission}</span>
				</div>
			</div>
			<div id="n_14_webmobmission_lbl_website_3">
				<span>Mission</span>
			</div>
			<img id="n_14_webmobzdenek-machacek-cfIcTiopin4-un" src="./Themes/FeatheredScape/zdenek-machacek-cfIcTiopin4-un.png" srcset="./Themes/FeatheredScape/zdenek-machacek-cfIcTiopin4-un.png 1x" />
				
		</div>
		<div id="n_14_webmobCard_03_website_3">
			<div id="n_14_webmobScroll_Group_12">
				<div id="n_14_webmobintro_lbl_website_3">
					<span>{props.data.principles}</span>
				</div>
			</div>
			<div id="n_14_webmobprinciples_lbl_website_3">
				<span>Principles</span>
			</div>
			<svg class="n_14_webmobIcon_top_website_3" viewBox="1.03 0 18.923 3.37">
				<path id="n_14_webmobIcon_top_website_3" d="M 4.081475257873535 0 L 1.029673933982849 3.369782447814941 L 16.89017677307129 3.369782447814941 L 19.95275115966797 0 L 4.081475257873535 0 Z">
				</path>
			</svg>
			<img id="n_14_webmobkevin-mueller-aeNg4YA41P8-unsp" src="./Themes/FeatheredScape/kevin-mueller-aeNg4YA41P8-unsp.png" srcset="./Themes/FeatheredScape/kevin-mueller-aeNg4YA41P8-unsp.png 1x" />
				
		</div>
	</div>
	<div id="n_14_webmobOur_Collection">
		<span>Our Collection</span>
	</div>
	<div id="n_14_webmobScroll_Group_47">
    {props.offeringsData.map((off,idx)=>(
		<div id="n_14_webmobGroup_14950">
			<svg class="n_14_webmobRectangle_703">
				<rect id="n_14_webmobRectangle_703" rx="0" ry="0" x="0" y="0" width="231" height="317">
				</rect>
			</svg>
			<img id="n_14_webmobkevin-mueller-aeNg4YA41P8-unsp_ds" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/FeatheredScape/kevin-mueller-aeNg4YA41P8-unsp_ds.png"} />
				
			<div id="n_14_webmobParrot">
				<span>{off.offeringTitle}</span>
			</div>
		</div>))}
	</div>
	<svg class="n_14_webmobfooter_bg_web_9_pc">
		<rect id="n_14_webmobfooter_bg_web_9_pc" rx="0" ry="0" x="0" y="0" width="390" height="774">
		</rect>
	</svg>
	<div id="n_14_webmobGroup_14951">
		<img id="n_14_webmobMy_project" src="./Themes/FeatheredScape/My_project.png" srcset="./Themes/FeatheredScape/My_project.png 1x" />
			
		<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"}  width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="n_14_webmobdisplay_web_9_pc">
			<rect id="n_14_webmobdisplay_web_9_pc" rx="0" ry="0" x="0" y="0" width="285" height="150">
			</rect>
		</iframe>
		<div id="n_14_webmobVISIT_US">
			<span>VISIT US!!</span>
		</div>
	</div>
	<div id="n_14_webmobFor_Queries_infoandroom_web_9_">
		<span>For Queries: {props.data.businessEmail}</span>
	</div>
	<div id="n_14_webmobFor_call__920012888_web_9_pc">
		<span>For call : {props.data.businessPhone}</span>
	</div>
	<svg class="n_14_webmobtelephone_web_9_pc" viewBox="0 0 11.38 10.522">
		<path id="n_14_webmobtelephone_web_9_pc" d="M 5.285655498504639 9.613908767700195 L 0.9823954105377197 5.635064125061035 C -0.3238036036491394 4.427295207977295 -0.3306940197944641 2.468093156814575 0.9823954105377197 1.253952264785767 L 2.241295099258423 0.08995640277862549 C 2.384282350540161 -0.04229017719626427 2.621845722198486 -0.02730839140713215 2.743739366531372 0.1229615285992622 L 4.886256217956543 2.764296293258667 C 4.985811233520508 2.887007236480713 4.972586154937744 3.058732271194458 4.855293750762939 3.167201519012451 L 3.574788808822632 4.351193428039551 C 3.42620062828064 4.488577842712402 3.425956249237061 4.711372852325439 3.574788808822632 4.848983764648438 L 6.13579797744751 7.216946601867676 C 6.284387111663818 7.354290962219238 6.525328159332275 7.354516506195068 6.674205303192139 7.216926574707031 L 7.954710006713867 6.032955169677734 C 8.072046279907227 5.924466133117676 8.257777214050293 5.912298679351807 8.390473365783691 6.004327774047852 L 11.24716854095459 7.985313892364502 C 11.40937995910645 8.097809791564941 11.42625045776367 8.317337989807129 11.28286647796631 8.449872016906738 L 10.02394390106201 9.613887786865234 C 8.715367317199707 10.82381629943848 6.595943450927734 10.82542037963867 5.285654544830322 9.613908767700195 Z">
		</path>
	</svg>
	<svg class="n_14_webmobsend_1_web_9_pc" viewBox="0 0 11.38 10.522">
		<path id="n_14_webmobsend_1_web_9_pc" d="M 11.3803596496582 0 L 0 5.918774127960205 L 3.636945486068726 7.164215564727783 L 9.246563911437988 2.301749229431152 L 4.979704856872559 7.624059200286865 L 4.98317289352417 7.625257015228271 L 4.978921890258789 7.624059200286865 L 4.978921890258789 10.52227306365967 L 7.018501281738281 8.32220458984375 L 9.602203369140625 9.206996917724609 L 11.3803596496582 0 Z">
		</path>
	</svg>
	<svg class="n_14_webmobIcon_metro-locatio_web_9_pc_n" viewBox="8.355 1.928 11.381 18.209">
		<path id="n_14_webmobIcon_metro-locatio_web_9_pc_n" d="M 14.04507637023926 1.928033471107483 C 10.90240859985352 1.928033471107483 8.354801177978516 4.475640773773193 8.354801177978516 7.618309020996094 C 8.354801177978516 13.30858421325684 14.04507637023926 20.13691520690918 14.04507637023926 20.13691520690918 C 14.04507637023926 20.13691520690918 19.7353515625 13.30858421325684 19.7353515625 7.618309020996094 C 19.7353515625 4.475640773773193 17.18770790100098 1.928033709526062 14.04507637023926 1.928033709526062 Z M 14.04507637023926 11.10360240936279 C 12.12019920349121 11.10360240936279 10.55978298187256 9.543186187744141 10.55978298187256 7.618309020996094 C 10.55978298187256 5.69343090057373 12.12019920349121 4.133015155792236 14.04507637023926 4.133015155792236 C 15.9699535369873 4.133015155792236 17.53037071228027 5.69343090057373 17.53037071228027 7.618309020996094 C 17.53037071228027 9.543186187744141 15.9699535369873 11.10360240936279 14.04507637023926 11.10360240936279 Z M 11.84009552001953 7.618309020996094 C 11.84009552001953 6.40053653717041 12.82730388641357 5.413327217102051 14.04507637023926 5.413327217102051 C 15.26284980773926 5.413327217102051 16.25005722045898 6.40053653717041 16.25005722045898 7.618309020996094 C 16.25005722045898 8.836081504821777 15.26284980773926 9.823290824890137 14.04507637023926 9.823290824890137 C 12.82730388641357 9.823290824890137 11.84009552001953 8.836081504821777 11.84009552001953 7.618309020996094 Z">
		</path>
	</svg>
	<div id="n_14_webmobaddress_lbl_web_9_pc">
		<span>{props.data.businessAddress}</span>
	</div>
	<div id="n_14_webmobGroup_14952">
    {props.data.linkedInUrl?
		<div title="LinkedIn" id="n_14_webmoblinked_in_grp_med_web_9_pc">
			<svg class="n_14_webmobEllipse_5_med_web_9_pc">
				<ellipse id="n_14_webmobEllipse_5_med_web_9_pc" rx="25" ry="25" cx="25" cy="25">
				</ellipse>
			</svg>
			<div id="n_14_webmoblinked_in_website_9_layout">
				<a href={props.data.linkedInUrl} target="_blank">
				<svg class="n_14_webmobn_website_2_layout_web_9_pc" viewBox="-15.255 0 16.092 15.182">
					<path id="n_14_webmobn_website_2_layout_web_9_pc" d="M -5.186071872711182 9.679508821136551e-07 C -7.904781818389893 9.679508821136551e-07 -9.60834789276123 1.472553014755249 -9.923189163208008 2.504196882247925 L -9.923189163208008 0.1445503830909729 L -15.2549991607666 0.1445503830909729 C -15.18569564819336 1.397309064865112 -15.2549991607666 15.18160820007324 -15.2549991607666 15.18160820007324 L -9.923189163208008 15.18160820007324 L -9.923189163208008 7.044621467590332 C -9.923189163208008 6.592491626739502 -9.944971084594727 6.140363693237305 -9.807681083679199 5.815624713897705 C -9.440696716308594 4.911367893218994 -8.653267860412598 3.972789287567139 -7.237480163574219 3.972789287567139 C -5.38672399520874 3.972789287567139 -4.544512748718262 5.363495349884033 -4.544512748718262 7.400383949279785 L -4.544512748718262 15.18160820007324 L 0.8368034362792969 15.18160820007324 L 0.8368034362792969 6.820206165313721 C 0.8368034362792969 2.162956237792969 -1.813262462615967 9.679508821136551e-07 -5.186071872711182 9.679508821136551e-07">
					</path>
				</svg>
			</a>
				<svg class="n_14_webmoblinked_in_i_down_web_9_pc" viewBox="0.931 -22.783 4.746 15.038">
					<path id="n_14_webmoblinked_in_i_down_web_9_pc" d="M 0.9310001134872437 -7.745282173156738 L 5.676695346832275 -7.745282173156738 L 5.676695346832275 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -7.745282173156738 Z">
					</path>
				</svg>
				<svg class="n_14_webmobtop_dot_i_website_9_layout" viewBox="-4.392 0 5.761 5.218">
					<path id="n_14_webmobtop_dot_i_website_9_layout" d="M -1.493098974227905 0 C -3.245508193969727 0 -4.392000198364258 1.125370144844055 -4.392000198364258 2.611123323440552 C -4.392000198364258 4.06783390045166 -3.278510093688965 5.217626571655273 -1.560423135757446 5.217626571655273 L -1.526101112365723 5.217626571655273 C 0.2579902410507202 5.217626571655273 1.368839383125305 4.063873767852783 1.368839383125305 2.606502532958984 C 1.334516644477844 1.122730016708374 0.2579902410507202 0 -1.493098974227905 0">
					</path>
				</svg>
			</div>
		</div>:""}
        {props.data.instagramUrl?	
		<div title="Instagram" id="n_14_webmobInsta_grp_med_web_9_pc">
			<svg class="n_14_webmobEllipse_4_med_web_9_pc">
				<ellipse id="n_14_webmobEllipse_4_med_web_9_pc" rx="25" ry="25" cx="25" cy="25">
				</ellipse>
			</svg>
			<a href={props.data.instagramUrl} target="_blank">
			<img id="n_14_webmobinstagram_website_9_layout" src="./Themes/FeatheredScape/instagram_website_9_layout.png" srcset="./Themes/FeatheredScape/instagram_website_9_layout.png 1x" />
				
			</a>
		</div>:""}
		{props.data.whatsAppNumber?
		<div title="WhatsApp" id="n_14_webmobwhatsapp_grp_med_web_9_pc">
			<svg class="n_14_webmobEllipse_3_med_web_9_pc">
				<ellipse id="n_14_webmobEllipse_3_med_web_9_pc" rx="25" ry="25" cx="25" cy="25">
				</ellipse>
			</svg>
			<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
			<img id="n_14_webmobwhatsapp_lbl_website9_layout" src="./Themes/FeatheredScape/whatsapp_lbl_website9_layout.png" srcset="./Themes/FeatheredScape/whatsapp_lbl_website9_layout.png 1x" />
				</a>
		</div>:""}
	</div>
	<div id="n_14_webmobn_Copyright_Operate_website_9_">
		<span>© Copyright Operate Live (OPC) Private Limited. <br/>All Rights Reserved</span>
	</div>
</div>
  )
}

export default FeatheredScapeMob
