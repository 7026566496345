import React from 'react';
import "../../../styles/KnowledgeSpaceMob.css";

function KnowledgeSpaceMob(props) {
  return (
    <div id="KnowledgeSpace_Mob">
	<img id="KS_20_mobRectangle_79" src="./Themes/KnowledgeSpace/Rectangle_79.png" srcset="./Themes/KnowledgeSpace/Rectangle_79.png 1x" />
		
	<div id="KS_20_mobGroup_99">
		<div id="KS_20_mobScroll_Group_69">
        {props.offeringsData.map((off,idx)=>(
            <div id="KS_20_mobGroup_49">
				<img id="KS_20_mobRectangle_115" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/KnowledgeSpace/Rectangle_115.png"} />
					
				<div id="KS_20_mobGroup_48">
					<svg class="KS_20_mobRectangle_116">
						<rect id="KS_20_mobRectangle_116" rx="0" ry="0" x="0" y="0" width="282" height="303.051">
						</rect>
					</svg>
					<svg class="KS_20_mobRectangle_117">
						<rect id="KS_20_mobRectangle_117" rx="0" ry="0" x="0" y="0" width="282" height="2.28">
						</rect>
					</svg>
					<svg class="KS_20_mobRectangle_118">
						<rect id="KS_20_mobRectangle_118" rx="0" ry="0" x="0" y="0" width="186.226" height="1.52">
						</rect>
					</svg>
					<div id="KS_20_mobDigital_Marketing">
						<span>{off.offeringTitle}</span>
					</div>
					<div id="KS_20_mobTime__10_am">
						<span>Duration : {parseInt(off.deliveryDuration/60)} Hours {off.deliveryDuration%60} Minutes</span>
					</div>
					<div id="KS_20_mobLorem_ipsum_dolor_sit_amet_con">
						<span>{off.description}</span>
					</div>
					<svg class="KS_20_mobRectangle_120">
						<rect id="KS_20_mobRectangle_120" rx="23.5" ry="23.5" x="0" y="0" width="247" height="47">
						</rect>
					</svg>
					<div id="KS_20_mobJoin_Now">
						<span>Join Now</span>
					</div>
				</div>
			</div>))}
		</div>
		<div id="KS_20_mobCourses">
			<span>Courses</span>
		</div>
	</div>
	<div id="KS_20_mobGroup_105">
		<div id="KS_20_mobContact">
			<span>Contact</span>
		</div>
		<div id="KS_20_mobGroup_90">
			<img id="KS_20_moblocation" src="./Themes/KnowledgeSpace/location.png" srcset="./Themes/KnowledgeSpace/location.png 1x" />
				
			<img id="KS_20_mobmessage" src="./Themes/KnowledgeSpace/message.png" srcset="./Themes/KnowledgeSpace/message.png 1x" />
				
			<div id="KS_20_mobScroll_Group_71">
				<div id="KS_20_mobLSE_Houghton___Street_London__">
					<span>{props.data.businessAddress}</span>
				</div>
			</div>
			<div id="KS_20_mobhellogmailcom">
				<span>{props.data.businessEmail}</span>
			</div>
			<img id="KS_20_mobphone" src="./Themes/KnowledgeSpace/phone.png" srcset="./Themes/KnowledgeSpace/phone.png 1x" />
				
			<div id="KS_20_mobn_4420_74057686">
				<span>{props.data.businessPhone}</span>
			</div>
			<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="KS_20_mobRectangle_740">
				<rect id="KS_20_mobRectangle_740" rx="0" ry="0" x="0" y="0" width="303" height="196">
				</rect>
			</iframe>
		</div>
		<div id="KS_20_mobn_Copyright_Live_OPC_Private_L">
			<span>© Copyright Operate Live (OPC) Private Limited. <br/>All Rights Reserved</span>
		</div>
	</div>
	<div id="KS_20_mobGroup_100">
		<svg class="KS_20_mobRectangle_121">
			<rect id="KS_20_mobRectangle_121" rx="0" ry="0" x="0" y="0" width="320" height="289">
			</rect>
		</svg>
		<div id="KS_20_mobOur_Teachers">
			<span>Our Teachers</span>
		</div>
		<div id="KS_20_mobScroll_Group_70">
			<div id="KS_20_mobGroup_96">
				<div id="KS_20_mobGroup_95">
					<img id="KS_20_mobEllipse_17" src="./Themes/KnowledgeSpace/Ellipse_17.png" srcset="./Themes/KnowledgeSpace/Ellipse_17.png 1x" />
						
					<div id="KS_20_mobBenton_Colley">
						<span>Benton Colley</span>
					</div>
					<div id="KS_20_mobPhotography_Head">
						<span>Photography Head</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div id="KS_20_mobGroup_107">
		<svg class="KS_20_mobRectangle_78">
			<rect id="KS_20_mobRectangle_78" rx="0" ry="0" x="0" y="0" width="320" height="500">
			</rect>
		</svg>
		<svg class="KS_20_mobRectangle_80">
			<rect id="KS_20_mobRectangle_80" rx="23.5" ry="23.5" x="0" y="0" width="134" height="47">
			</rect>
		</svg>
		<div id="KS_20_mobKnowledge___is_power">
			<span>Knowledge <br/>is power</span>
		</div>
		<div id="KS_20_mobAny_succesfull_career_starts__">
			<span>Any succesfull career starts <br/><br/>with good education</span>
		</div>
		<div id="KS_20_mobLearn_more">
			<span>Learn more</span>
		</div>
		<img id="KS_20_moblogo" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/KnowledgeSpace/logo.png"} />
			
	</div>
	<div id="KS_20_mobGroup_109">
		<img id="KS_20_mobtamanna-rumee-vaTsR-ghLog-unsp" src="./Themes/KnowledgeSpace/tamanna-rumee-vaTsR-ghLog-unsp.png" srcset="./Themes/KnowledgeSpace/tamanna-rumee-vaTsR-ghLog-unsp.png 1x" />
			
		<div id="KS_20_mobGroup_3">
			<div id="KS_20_mobMission">
				<span>Mission</span>
			</div>
			<div id="KS_20_mobScroll_Group_65">
				<div id="KS_20_mobLorem_ipsum_dolor_sit_amet_con_eh">
					<span>{props.data.mission}</span>
				</div>
			</div>
		</div>
	</div>
	<div id="KS_20_mobGroup_15057">
		<img id="KS_20_mobtim-mossholder-WE_Kv_ZB1l0-uns" src="./Themes/KnowledgeSpace/tim-mossholder-WE_Kv_ZB1l0-uns.png" srcset="./Themes/KnowledgeSpace/tim-mossholder-WE_Kv_ZB1l0-uns.png 1x" />
			
		<div id="KS_20_mobGroup_3_ek">
			<div id="KS_20_mobVision">
				<span>Vision</span>
			</div>
			<div id="KS_20_mobScroll_Group_65_em">
				<div id="KS_20_mobLorem_ipsum_dolor_sit_amet_con_en">
					<span>{props.data.vision}</span>
				</div>
			</div>
		</div>
	</div>
	<div id="KS_20_mobGroup_15058">
		<img id="KS_20_mobnguyen-khanh-ly-liiusuaISP0-un" src="./Themes/KnowledgeSpace/nguyen-khanh-ly-liiusuaISP0-un.png" srcset="./Themes/KnowledgeSpace/nguyen-khanh-ly-liiusuaISP0-un.png 1x" />
			
		<div id="KS_20_mobGroup_3_eq">
			<div id="KS_20_mobPrinciples">
				<span>Principles</span>
			</div>
			<div id="KS_20_mobScroll_Group_65_es">
				<div id="KS_20_mobLorem_ipsum_dolor_sit_amet_con_et">
					<span>{props.data.principles}</span>
				</div>
			</div>
		</div>
	</div>
</div>
  )
}

export default KnowledgeSpaceMob