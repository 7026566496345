import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/CommunitiesConnectedMembersNewUI.css';
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import AddCommunitiesConMemPopUP from '../AddCommunitiesConMemPopUp';
import FooterPcUI from './FooterPcUI';
import MyCommunitiesNewUI from './MyCommunitiesNewUI';


const CommunitiesConnectedMembersNewUI = (props) => {
  var navigate = useNavigate();
  const { user, setUser} = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [requirements,setRequirements]=useState([]);
  const [ offerings,setOfferings] = useState([]);
  const [allStatus,setAllStatus] = useState([]);
  const [checked, setChecked] = React.useState([true, false]);
  const [openGridModel,setOpenGridModel] = useState(false);
  const [openGridOfferingModel,setOpenGridOfferingModel] = useState(false);
  const [openGridConnectionModel,setOpenGridConnectionModel] = useState(false);
  const [oddRequirements,setOddRequirements] = useState([]);
  const [oddOfferings,setOddOfferings] = useState([]);
  const [oddConnections,setOddConnections] = useState([]);
  const [communityId,setCommunityId] = useState("")
  const [communityName,setCommunityName] = useState("")

// //UseEffect

//UseEffects
useEffect(()=>{
    if(user){
		if(props.communityId){
			setCommunityId(props.communityId);
			
			getStatus()
		}
		if(props.communityName){
			setCommunityName(props.communityName);
		}	
    }
    },[props.communityId])




	//Get Connection List
	const getStatus = ()=>{
		
		let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatusbycommunity/" + user._id;
		let apiParams = {
		  method: "GET",
		  headers:{
			communityid:props.communityId,
		  }
		};
		fetch(apiURL, apiParams)
		  .then((res) => (res.status === 200 ? res.json() : res.text()))
		  .then((repos) => {
			if (repos.data) {
				
				setAllStatus(repos.data)
				
			} else alert(repos.message || repos);
		  })
		  .catch((err) => alert(err));
	  };


      //Converting Image
function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

  const openGridConnectionModal = ()=>{
    setOpenGridConnectionModel(true)
  }

  const RemoveConnection = (connectionId)=>{
	
    let apiURL=process.env.REACT_APP_API_URL + "/connection/removeConnectionsbycommunity/"+ connectionId._id ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:communityId,
            userid:user._id,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
	
			getStatus()

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  return (
    <div>
	<div id="community_mem_page">
	<div id="bdr_mem_oveerall_grp">
	<svg class="overl_all_design_com_mem_pc" viewBox="2950.899 1057.904 3144.019 1645">
			<path id="overl_all_design_com_mem_pc" d="M 2951.0009765625 1139.186645507812 L 2951.0009765625 2702.90380859375 L 6094.91796875 2702.90380859375 L 6094.91796875 1112.318237304688 L 3513.9384765625 1112.318237304688 C 3513.9384765625 1112.318237304688 3495.102294921875 1115.219482421875 3494.559814453125 1103.182495117188 C 3494.018310546875 1091.145385742188 3494.559814453125 1068.515747070312 3494.559814453125 1068.515747070312 C 3494.559814453125 1068.515747070312 3496.366455078125 1057.92333984375 3477.949951171875 1057.92333984375 C 3459.533203125 1057.92333984375 3339.51806640625 1057.92333984375 3339.51806640625 1057.92333984375 C 3339.51806640625 1057.92333984375 3321.60400390625 1056.960205078125 3321.062255859375 1068.515747070312 C 3320.520263671875 1080.071533203125 3321.062255859375 1103.182495117188 3321.062255859375 1103.182495117188 C 3321.062255859375 1103.182495117188 3319.255615234375 1112.310668945312 3309.98876953125 1112.318237304688 C 3294.82275390625 1112.33056640625 2968.646240234375 1112.318237304688 2968.646240234375 1112.318237304688 C 2968.646240234375 1112.318237304688 2951.22998046875 1110.885864257812 2951.0009765625 1120.9970703125 C 2950.771240234375 1131.108154296875 2951.0009765625 1139.186645507812 2951.0009765625 1139.186645507812 Z">
			</path>
		</svg>
	</div>
	<div id="title_grp_all_com_mem_pc">
		<div id="Offerings_lbl_com_mem_pc" onClick={()=>props.selectedTab('offerings')}>
			<span>Offerings</span>
		</div>
		<div id="Requirement_lbl_com_mem_pc" onClick={()=>props.selectedTab('requirements')}>
			<span>Requirement</span>
		</div>
		<div id="Members_lbl_com_mem_pc">
			<span>Members</span>
		</div>
	</div>
	<div id="Scroll_Group_conn_mem">
	{allStatus.map((com)=>(
		<div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
	<div id="mem_card_1_com_mem_pc" className='fadeInLeft'>
		<svg class="card_bdr_com_mem_pc_" onClick={()=>navigate("../"+(com.user1Name===user.name ? com.user2Image.nickName:com.user1Image.nickName))}>
			<linearGradient id="card_bdr_com_mem_pc_" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
				<stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#cfffd7" stop-opacity="1"></stop>
			</linearGradient>
			<rect id="card_bdr_com_mem_pc_" rx="10" ry="10" x="0" y="0" width="199" height="192.457">
			</rect>
		</svg>
		<svg class="card_top_deisgn_com_mem_pc_" viewBox="768 1996.457 199 47.484">
			<linearGradient id="card_top_deisgn_com_mem_pc_" spreadMethod="pad" x1="0" x2="1" y1="0" y2="0.639">
				<stop offset="0" stop-color="#cfffd7" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#80ff95" stop-opacity="1"></stop>
			</linearGradient>
			<path id="card_top_deisgn_com_mem_pc_" d="M 768 1996.45654296875 L 967 1996.45654296875 L 967 2026.984619140625 L 933.4641723632812 2043.941040039062 L 809.4276123046875 2043.941040039062 L 768 2026.984619140625 L 768 1996.45654296875 Z">
			</path>
		</svg>
		<svg class="bg_name_deisgn_com_mem_pc">
			<rect id="bg_name_deisgn_com_mem_pc" rx="0" ry="0" x="0" y="0" width="169.518" height="20.793">
			</rect>
		</svg>
		<img id="ai-chip-_img_com_mem_pc" src={com.user1Name===user.name ?  (com.user2Image.imageUrl && com.user2Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(com.user2Image.imageUrl.split('/')[5]):"user_pfp_profile_page.png":(com.user1Image.imageUrl && com.user1Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(com.user1Image.imageUrl.split('/')[5]):"user_pfp_profile_page.png"} />
			
		<div id="Mohammed_Zaid_com_mem_pc_1">
			<span>{com.user1Name===user.name ? com.user2Name:com.user1Name}</span>
		</div>
		<img title="Delivery Location" id="com_mem_pc_location_img" src="com_mem_pc_location_img.png" srcset="com_mem_pc_location_img.png 1x" />
			
		<img title="Delivery Time" id="com_mem_pc_duration_img" src="com_mem_pc_duration_img.png" srcset="com_mem_pc_duration_img.png 1x" />
			
		<img id="minus_com_"  src="minus.png" srcset="minus.png 1x" onClick={()=>RemoveConnection(com)}/>
			
		<div id="date_com_mem_pc">
			<span>{com.updatedAt && (com.updatedAt).substring(0,10)}</span>
		</div>
		<div id="Software_development_com_mem_p">
			<span>{com.user1Name===user.name ? com.user2Image? (com.user2Image.profession):com.user1Image ?com.user1Image.profession:"":""}</span>
		</div>
	</div>
	</div>
	))}
	</div>
	{props.communityId && props.communityId.length > 0 ?
	<button title="Add Members" id="member_add_btn_grp_com_mem_pc" onClick={()=>openGridConnectionModal()}>
		<svg class="bdr_member_btn_com_off_pcZ">
			<ellipse id="bdr_member_btn_com_off_pcZ" rx="16" ry="16" cx="16" cy="16">
			</ellipse>
		</svg>
		<img id="member_img_btn_com_off_pcZ" src="member_img_btn_com_off_pcZ.png" srcset="member_img_btn_com_off_pcZ.png 1x" />
			
	</button>:""}

	
</div>
  	

  <AddCommunitiesConMemPopUP communityId={props.communityId} communityName={props.communityName} openComMem ={openGridConnectionModel} closeComMem ={()=>{setOpenGridConnectionModel();getStatus()}}/>

 </div>
  )
}

export default CommunitiesConnectedMembersNewUI
