import React, { useEffect, useState, useContext} from 'react'
import "../../styles/CalanderDashboard.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import CalanderEntryPopUpUI from './CalanderEntryPopUpUI';
import CalenderProfileUI from './CalenderProfileUI';
import CalenderPageUI from './CalenderPageUI';
import CalenderAppointmentCard from './CalenderAppointmentCard';
import HourCard from './HourCard';

const CalanderDashboard = () => {
	const navigate = useNavigate()
    const [openCalanderEntryPop,setOpenCalanderEntryPop] = useState(false);
	const [slotType,setSlotType] = useState("event");
	const [slotTime,setSlotTime] = useState(new Date().toISOString().substring(0,16));
	const [slotDate,setSlotDate] = useState(new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString().padStart(2, '0') + "-" + new Date().getDate().toString().padStart(2, '0'));

	const [events,setEvents] = useState([]);
	const [alarms,setAlarms] = useState([]);
	const [tasks,setTasks] = useState([]);
	const [meetings,setMeetings] = useState([]);
	const [refresh,setRefresh] = useState(false);
	const [ appointments,setAppointments] = useState([[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);//{title:"",type:"",startTime:"",endTime:""}
	const [searchParams] = useSearchParams();
    const { user, setUser } = useContext(UserContext);


// useEffect(()=>{
// if(user){
// 	getCalenderDetails()
	
// }

// },[user])



useEffect(()=>{
if(user){

	getCalenderDetailsByDate()
	
}

},[user,slotDate])


useEffect(()=>{
	
},[appointments])




 // //Get offerings by id
 const getCalenderDetailsByDate = ()=>{
      
	let apiURL=process.env.REACT_APP_API_URL + "/calenders/getallcalenderdatabydate/" +user._id ;
	let apiParams = {
		method:"GET",
		headers:{
			selecteddate:slotDate,
		}
		
	};
	fetch(apiURL, apiParams)
	.then((res)=> (res.status===200 ? res.json() : res.text()))
	.then(async(repos)=> {
		if (repos.data) {
		setAppointments(null)
	    // console.log(repos.data)
		setAppointments([[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]])
		
		setEvents(repos.data.events.map( eve=> {
					var item = {title:eve.title,type:"event",startTime:new Date(eve.startsOn)+(330*60000),endTime:new Date(eve.endsOn)+(330*60000)}
					var currApts = appointments;
				
					currApts[new Date(new Date(eve.startsOn)+(330*60000)).getHours()] = [...(currApts[new Date(eve.startsOn).getHours()]),item]
					
					 setAppointments(currApts)
					return eve;
				}))



		setAlarms(repos.data.alarms.map(alm=>{
			
			var item = {title:alm.title,type:"alarm",startTime:new Date(alm.startOn)+(330*60000),endTime:new Date(alm.startOn)+(330*60000)}
			var currApts = appointments;
		
			currApts[new Date(new Date(alm.startOn)+(330*60000)).getHours()] = [...currApts[new Date(alm.startOn).getHours()],item]
			
			setAppointments(currApts)
			return alm;
		}))


		 setTasks(repos.data.tasks.map( tsk=> {
				var item = {title:tsk.title,type:"task",startTime:new Date(tsk.startsAt)+(330*60000),endTime:new Date(tsk.endsAt)+(330*60000)}
				var currApts = appointments;
			
				currApts[new Date(new Date(tsk.startsAt)+(330*60000)).getHours()] = [...currApts[new Date(tsk.startsAt).getHours()],item]
			
				setAppointments(currApts)
				return tsk;
			}))

		 setMeetings(repos.data.meetings.map( mtg=> {
				var item = {title:mtg.meetingTitle,type:"meeting",startTime:new Date(mtg.meetingStartDateTime)+(330*60000),endTime:new Date(mtg.meetingEndDateTime)+(330*60000)}
				var currApts = appointments;
				// setAppointments(null)
				currApts[new Date(new Date(mtg.meetingStartDateTime)+(330*60000)).getHours()]= [...currApts[new Date(mtg.meetingStartDateTime).getHours()],item]
				
				setAppointments(currApts)
				return mtg;
			}))

		}else alert(repos.message || repos);  
	}) 
	.catch((err) => alert(err));
  }



  	
  return (
    <div>
      <div id="calender_page">
	<div id="com_tata_next_grp">
		<div id="completed_grp_calender_pc">
			<div id="Completed_lbbl_calender_pc">
				<span>Completed</span>
			</div>
			<svg class="count_bdr">
				<rect id="count_bdr" rx="10.5" ry="10.5" x="0" y="0" width="40" height="21">
				</rect>
			</svg>
			<div id="n_0_count_calender_pc">
				<span>30</span>
			</div>
			<svg class="completed_staus_calender_pc">
				<ellipse id="completed_staus_calender_pc" rx="3.5" ry="3.5" cx="3.5" cy="3.5">
				</ellipse>
			</svg>
		</div>
		<div id="next_up_calender_pc_grp">
			<div id="Next_Up_lbl_calender_pc">
				<span>Next Up</span>
			</div>
			<svg class="n_0_bdr_next_up_calender_pc">
				<rect id="n_0_bdr_next_up_calender_pc" rx="10.5" ry="10.5" x="0" y="0" width="40" height="21">
				</rect>
			</svg>
			<div id="n_0_calender_pc">
				<span>10</span>
			</div>
			<svg class="arrow_next_up" viewBox="0 0 8 8">
				<path id="arrow_next_up" d="M 3.999999523162842 0 L 7.999999523162842 8 L 0 8 Z">
				</path>
			</svg>
			<svg class="next_up_arrow_line" viewBox="0 0 1 8">
				<path id="next_up_arrow_line" d="M 0 0 L 0 8">
				</path>
			</svg>
		</div>
		<div id="todo_grp_calender_pc">
			<div id="To-Do_lbl_calender_pc">
				<span>To-Do</span>
			</div>
			<svg class="n_0_bdr_calender_pc">
				<rect id="n_0_bdr_calender_pc" rx="10.5" ry="10.5" x="0" y="0" width="40" height="21">
				</rect>
			</svg>
			<div id="n_0_calender_pc_ce">
				<span>10</span>
			</div>
		</div>
	</div>
	<div id="centre_card_grp_calender_pc">
		<div id="meeeetings_card" onClick={()=>{setOpenCalanderEntryPop(true);setSlotType("meeting");setSlotTime(slotDate+"T"+new Date().getHours()+":"+new Date().getMinutes());}}>
			<svg class="meeting_card_overall_bg_calend_ci">
				<linearGradient id="meeting_card_overall_bg_calend_ci" spreadMethod="pad" x1="-0.796" x2="0.98" y1="-0.897" y2="0.981">
					<stop offset="0" stop-color="#33e8ff" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="meeting_card_overall_bg_calend_ci" rx="11" ry="11" x="0" y="0" width="280" height="280">
				</rect>
			</svg>
			<div id="Meetings_lbl_headingcalender_p">
				<span>Meetings</span>
			</div>
			<div id="Scroll_meeting">
{meetings.map((meet)=>(
				<div id="meeting_grp_calender_page">
					<svg class="line_calender_pc" viewBox="0 0 249.76 1">
						<path id="line_calender_pc" d="M 0 0 L 249.7599945068359 0">
						</path>
					</svg>
					<div id="Arcane_Series_lbl_calender_pc">
						<span>{meet.meetingTitle}</span>
					</div>
					<div id="n_025pm_-_1200am_arcane">
						<span>{meet.meetingStartDateTime && new Date(meet.meetingStartDateTime).getHours().toString().padStart(2,"0")+":"+new Date(meet.meetingStartDateTime).getMinutes().toString().padStart(2,"0")} - {meet.meetingEndDateTime && new Date(meet.meetingEndDateTime).getHours().toString().padStart(2,"0")+":"+new Date(meet.meetingEndDateTime).getMinutes().toString().padStart(2,"0")} IST</span>
					</div>
					<svg class="rcane_mode_meetincalender_pc">
						<rect id="rcane_mode_meetincalender_pc" rx="0" ry="0" x="0" y="0" width="3.36" height="16.8">
						</rect>
					</svg>
					<svg class="inacvtice_status_calender_pc">
						<ellipse id="inacvtice_status_calender_pc" rx="5.60000467300415" ry="5.60000467300415" cx="5.60000467300415" cy="5.60000467300415">
						</ellipse>
					</svg>
				</div>
				))
}
			</div>
			<img id="meeting_img_calender_pc" src="./CalanderDashboard/meeting_img_calender_pc.png" srcset="./CalanderDashboard/meeting_img_calender_pc.png 1x" />
				
		</div>
		<div id="events_card" onClick={()=>{setOpenCalanderEntryPop(true);setSlotType("event");setSlotTime(slotDate+"T"+new Date().getHours()+":"+new Date().getMinutes());}}>
			<svg class="events_verall_pg_calender_pc_cu">
				<linearGradient id="events_verall_pg_calender_pc_cu" spreadMethod="pad" x1="-0.644" x2="0.985" y1="-0.675" y2="0.984">
					<stop offset="0" stop-color="#f5737f" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="events_verall_pg_calender_pc_cu" rx="11" ry="11" x="0" y="0" width="280" height="280">
				</rect>
			</svg>
			<div id="Events_lbl_calender_pc">
				<span>Events</span>
			</div>
			<div id="Scroll_events">
			{

				events.map((eve)=>(
				<div id="events_page_calender">
					<div id="Dragon_Ball_Super_lbl">
						<span>{eve.title}</span>
					</div>
					<div id="KAMEHAMEHA">
						<span>{eve.startsOn && new Date(eve.startsOn).getHours().toString().padStart(2,"0")+":"+new Date(eve.startsOn).getMinutes().toString().padStart(2,"0")} - {eve.endsOn && new Date(eve.endsOn).getHours().toString().padStart(2,"0")+":"+new Date(eve.endsOn).getMinutes().toString().padStart(2,"0")} IST</span>
					</div>
					<svg class="dragon_ball_super_calender_pc" viewBox="0 0 249.76 1">
						<path id="dragon_ball_super_calender_pc" d="M 0 0 L 249.7599945068359 0">
						</path>
					</svg>
					<svg class="dragon_ball_super_mode_calende">
						<rect id="dragon_ball_super_mode_calende" rx="0" ry="0" x="0" y="0" width="3.36" height="16.8">
						</rect>
					</svg>
					<svg class="active_events_calender_pc">
						<ellipse id="active_events_calender_pc" rx="5.599999904632568" ry="5.599999904632568" cx="5.599999904632568" cy="5.599999904632568">
						</ellipse>
					</svg>
				</div>
				))
			}
			</div>
			<img id="calendar_img_calender_pc" src="./CalanderDashboard/calendar_img_calender_pc.png" srcset="./CalanderDashboard/calendar_img_calender_pc.png 1x" />
				
		</div>
		<div id="tasks_card" onClick={()=>{setOpenCalanderEntryPop(true); setSlotType("task");setSlotTime(slotDate+"T"+new Date().getHours()+":"+new Date().getMinutes());}}>
			<svg class="tak_bdr_calender_pc_c">
				<linearGradient id="tak_bdr_calender_pc_c" spreadMethod="pad" x1="-0.734" x2="1" y1="-0.483" y2="1">
					<stop offset="0" stop-color="#f9cc80" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="tak_bdr_calender_pc_c" rx="11" ry="11" x="0" y="0" width="280" height="280">
				</rect>
			</svg>
			<div id="Tasks_lbl_">
				<span>Tasks</span>
			</div>
			<img id="notes_img_taskes_calender_pc" src="./CalanderDashboard/notes_img_taskes_calender_pc.png" srcset="./CalanderDashboard/notes_img_taskes_calender_pc.png 1x" />
				
			<div id="Scroll_tasks">
			{tasks.map((tsk)=>(
				<div id="Group_task_pc_new">
					<div id="calender_page_pc">
						<span>{tsk.title}</span>
					</div>
					<div id="calender_time_">
						<span>{tsk.startsAt && new Date(tsk.startsAt).getHours().toString().padStart(2,"0")+":"+new Date(tsk.startsAt).getMinutes().toString().padStart(2,"0")} - {tsk.endsAt && new Date(tsk.endsAt).getHours().toString().padStart(2,"0")+":"+new Date(tsk.endsAt).getMinutes().toString().padStart(2,"0")} IST</span>
					</div>
					<svg class="arcane_top_line_calender_pc" viewBox="0 0 249.76 1">
						<path id="arcane_top_line_calender_pc" d="M 0 0 L 249.7599945068359 0">
						</path>
					</svg>
					<svg class="caleneder_mode_calender_pc">
						<rect id="caleneder_mode_calender_pc" rx="0" ry="0" x="0" y="0" width="3.36" height="16.8">
						</rect>
					</svg>
					<svg class="active_calender_page_pc_calend">
						<ellipse id="active_calender_page_pc_calend" rx="5.599999904632568" ry="5.599999904632568" cx="5.599999904632568" cy="5.599999904632568">
						</ellipse>
					</svg>
					<img title="Urgent" id="immediate_1_calender_pc" src="./CalanderDashboard/immediate_1_calender_pc.png" srcset="./CalanderDashboard/immediate_1_calender_pc.png 1x" />
						
				</div>
				))}
			</div>
		</div>
		<div id="arlarm_card" onClick={()=>{setOpenCalanderEntryPop(true);setSlotType("alarm");setSlotTime(slotDate+"T"+new Date().getHours()+":"+new Date().getMinutes());}}>
			<svg class="alarm_bdr_overall_pc_dj">
				<linearGradient id="alarm_bdr_overall_pc_dj" spreadMethod="pad" x1="-0.061" x2="1" y1="-0.073" y2="1">
					<stop offset="0" stop-color="#e9edf5" stop-opacity="1"></stop>
					<stop offset="0.9791" stop-color="#fff" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="alarm_bdr_overall_pc_dj" rx="11" ry="11" x="0" y="0" width="280" height="280">
				</rect>
			</svg>
			<div id="Alarms_lbl_calender">
				<span>Alarms</span>
			</div>
			<img id="clock_alarm_for_snooze" src="./CalanderDashboard/clock_alarm_for_snooze.png" srcset="./CalanderDashboard/clock_alarm_for_snooze.png 1x" />
				
			<div id="Scroll_alarm">
			{alarms.map((alrm)=>(
				<div id="waking_up_all_grp_alarm">
					<div id="Waking_Up_Early_lbl_calender">
						<span>{alrm.title}</span>
					</div>
					<div id="n_00am_time_for_wake_up">
						<span>{alrm.startOn && new Date(alrm.startOn).getHours().toString().padStart(2,"0")+":"+new Date(alrm.startOn).getMinutes().toString().padStart(2,"0")}</span>
					</div>
					<svg class="leaving_office_line" viewBox="0 0 249.76 1">
						<path id="leaving_office_line" d="M 0 0 L 249.7599945068359 0">
						</path>
					</svg>
					<svg class="waking_up_early_line">
						<rect id="waking_up_early_line" rx="0" ry="0" x="0" y="0" width="3.36" height="16.8">
						</rect>
					</svg>
					<div id="n_1135am_time_for_snooze">
						<span> 11:35am</span>
					</div>
					<img id="snooze_img_calender" src="./CalanderDashboard/snooze_img_calender.png" srcset="./CalanderDashboard/snooze_img_calender.png 1x" />
						
					<svg class="green_ball">
						<ellipse id="green_ball" rx="5.599999904632568" ry="5.599999904632568" cx="5.599999904632568" cy="5.599999904632568">
						</ellipse>
					</svg>
				</div>
				))}	
			</div>
		</div>
	</div>
	<img id="back_btn_for_calender" src="./CalanderDashboard/back_btn_for_calender.png" srcset="./CalanderDashboard/back_btn_for_calender.png 1x" onClick={()=>navigate('/home')}/>
		
	<div id="right_side_grp_calender_pc">
		<svg class="calender_bdr_calender_pc_ei">
			<linearGradient id="calender_bdr_calender_pc_ei" spreadMethod="pad" x1="1" x2="-0.338" y1="0.487" y2="0.462">
				<stop offset="0" stop-color="#f3f3f3" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
			</linearGradient>
			<rect id="calender_bdr_calender_pc_ei" rx="15" ry="15" x="0" y="0" width="390" height="768">
			</rect>
		</svg>

		<div id="Scroll_date_and_event_oof">
			<div id="so_much_to_grp">
				{(appointments||[]).map((data,idx)=>
				<HourCard data={data||[]} hour={idx} openCalanderEntryPopUp={()=>{setOpenCalanderEntryPop(true);setSlotType("alarm");setSlotTime(slotDate+"T"+idx.toString().padStart(2,'0')+":00");}}/>
				)}
				
			</div>
		</div>
		
		<div id="august_calender_pc_side">
			<CalenderPageUI slotDate={slotDate}  date={(e)=>{setAppointments([[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);setSlotDate(e);getCalenderDetailsByDate()}}/>
		</div>
   </div>
</div>
<CalanderEntryPopUpUI OpenCalanderEntryPop={openCalanderEntryPop} type={slotType} time={slotTime} closePop={()=>{setOpenCalanderEntryPop(false);getCalenderDetailsByDate()}}/>
    </div>
  )
}

export default CalanderDashboard
