import React from 'react';
import "../../../styles/BakersStop.css";

function BakersStop(props) {
  return (
    <div id="BakersStop">
	<svg class="BCS_21_PCLine_20" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_20" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_21" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_21" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_22" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_22" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_23" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_23" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_24" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_24" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_25" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_25" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_20_p" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_20_p" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_21_q" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_21_q" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_22_r" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_22_r" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_23_s" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_23_s" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_24_t" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_24_t" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<svg class="BCS_21_PCLine_25_u" viewBox="0 0 6.337 3">
		<path id="BCS_21_PCLine_25_u" d="M 0 0 L 6.336598873138428 0">
		</path>
	</svg>
	<div id="BCS_21_PCCategory_Section">
		<svg class="BCS_21_PCRectangle_662">
			<rect id="BCS_21_PCRectangle_662" rx="0" ry="0" x="0" y="0" width="1316" height="5">
			</rect>
		</svg>
		<div id="BCS_21_PCScroll_Group_77">
        {props.offeringsData.map((off,idx)=>(<div id="BCS_21_PCn_">
				<img id="BCS_21_PCMask_Group_22" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/BakersStop/Mask_Group_22.png"} />
					
				<svg class="BCS_21_PCRectangle_664">
					<rect id="BCS_21_PCRectangle_664" rx="0" ry="0" x="0" y="0" width="170" height="70">
					</rect>
				</svg>
				<div id="BCS_21_PCHyundai_Azera_2020">
					<span>{off.offeringTitle}</span>
				</div>
				<div id="BCS_21_PCGroup_10773">
					<svg class="BCS_21_PCEllipse_38">
						<ellipse id="BCS_21_PCEllipse_38" rx="2.5" ry="2.5" cx="2.5" cy="2.5">
						</ellipse>
					</svg>
					<svg class="BCS_21_PCEllipse_39">
						<ellipse id="BCS_21_PCEllipse_39" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
					<svg class="BCS_21_PCEllipse_40">
						<ellipse id="BCS_21_PCEllipse_40" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
					<svg class="BCS_21_PCEllipse_41">
						<ellipse id="BCS_21_PCEllipse_41" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
					<svg class="BCS_21_PCEllipse_42">
						<ellipse id="BCS_21_PCEllipse_42" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
				</div>
			</div>))}
		</div>
	</div>
	<div id="BCS_21_PCn_rd_Section">
		<div id="BCS_21_PCScroll_Group_76">
			<div id="BCS_21_PCBinQasem_provides_a_platform_o">
				<span>{props.data.principles}<br/></span><br/>
			</div>
		</div>
		<div id="BCS_21_PCPrinciple">
			<span>Principles</span>
		</div>
		<img id="BCS_21_PCMask_Group_23" src="./Themes/BakersStop/Mask_Group_23.png" srcset="./Themes/BakersStop/Mask_Group_23.png 1x" />
			
	</div>
	<div id="BCS_21_PCn_rd_Section_bd">
		<div id="BCS_21_PCScroll_Group_75">
			<div id="BCS_21_PCBinQasem_provides_a_platform_o_bf">
				<span>{props.data.mission}<br/></span><br/>
			</div>
		</div>
		<div id="BCS_21_PCMission">
			<span>Mission</span>
		</div>
		<img id="BCS_21_PCMask_Group_23_bh" src="./Themes/BakersStop/Mask_Group_23_bh.png" srcset="./Themes/BakersStop/Mask_Group_23_bh.png 1x" />
			
	</div>
	<div id="BCS_21_PCn_rd_Section_bi">
		<div id="BCS_21_PCScroll_Group_74">
			<div id="BCS_21_PCBinQasem_provides_a_platform_o_bk">
				<span>{props.data.vision}<br/></span><br/>
			</div>
		</div>
		<div id="BCS_21_PCVISION">
			<span>VISION</span>
		</div>
		<img id="BCS_21_PCMask_Group_23_bm" src="./Themes/BakersStop/Mask_Group_23_bm.png" srcset="./Themes/BakersStop/Mask_Group_23_bm.png 1x" />
			
	</div>
	<div id="BCS_21_PCour_services">
		<span>our services</span>
	</div>
	<div id="BCS_21_PCGroup_15060">
		<div id="BCS_21_PCGroup_14807">
			<img id="BCS_21_PCMask_Group_25" src="./Themes/BakersStop/Mask_Group_25.png" srcset="./Themes/BakersStop/Mask_Group_25.png 1x" />
				
			<svg class="BCS_21_PCRectangle_678">
				<rect id="BCS_21_PCRectangle_678" rx="0" ry="0" x="0" y="0" width="1366" height="411">
				</rect>
			</svg>
			<div id="BCS_21_PCGroup_14850">
				<img id="BCS_21_PCn_2321397_luyk_98k2_201103" src="./Themes/BakersStop/n_2321397_luyk_98k2_201103.png" srcset="./Themes/BakersStop/n_2321397_luyk_98k2_201103.png 1x" />
					
				<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="BCS_21_PCRectangle_680">
					<rect id="BCS_21_PCRectangle_680" rx="0" ry="0" x="0" y="0" width="522" height="235">
					</rect>
				</iframe>
			</div>
			<svg class="BCS_21_PCRectangle_741">
				<rect id="BCS_21_PCRectangle_741" rx="7" ry="7" x="0" y="0" width="173" height="51">
				</rect>
			</svg>
			<div id="BCS_21_PCBAKERY">
				<span>BAKERY</span>
			</div>
		</div>
		<div id="BCS_21_PCGroup_15059">
			<div id="BCS_21_PCFor_Queries_infoandroomedanet">
				<span>For Queries: {props.data.businessEmail}</span>
			</div>
			<div id="BCS_21_PCFor_call__920012888">
				<span>For call : {props.data.businessPhone}</span>
			</div>
			<svg class="BCS_21_PCtelephone" viewBox="0 0 11.38 10.522">
				<path id="BCS_21_PCtelephone" d="M 5.285655498504639 9.613908767700195 L 0.9823954105377197 5.635064125061035 C -0.3238036036491394 4.427295207977295 -0.3306940197944641 2.468093156814575 0.9823954105377197 1.253952264785767 L 2.241295099258423 0.08995640277862549 C 2.384282350540161 -0.04229017719626427 2.621845722198486 -0.02730839140713215 2.743739366531372 0.1229615285992622 L 4.886256217956543 2.764296293258667 C 4.985811233520508 2.887007236480713 4.972586154937744 3.058732271194458 4.855293750762939 3.167201519012451 L 3.574788808822632 4.351193428039551 C 3.42620062828064 4.488577842712402 3.425956249237061 4.711372852325439 3.574788808822632 4.848983764648438 L 6.13579797744751 7.216946601867676 C 6.284387111663818 7.354290962219238 6.525328159332275 7.354516506195068 6.674205303192139 7.216926574707031 L 7.954710006713867 6.032955169677734 C 8.072046279907227 5.924466133117676 8.257777214050293 5.912298679351807 8.390473365783691 6.004327774047852 L 11.24716854095459 7.985313892364502 C 11.40937995910645 8.097809791564941 11.42625045776367 8.317337989807129 11.28286647796631 8.449872016906738 L 10.02394390106201 9.613887786865234 C 8.715367317199707 10.82381629943848 6.595943450927734 10.82542037963867 5.285654544830322 9.613908767700195 Z">
				</path>
			</svg>
			<svg class="BCS_21_PCsend_1" viewBox="0 0 11.38 10.522">
				<path id="BCS_21_PCsend_1" d="M 11.3803596496582 0 L 0 5.918774127960205 L 3.636945486068726 7.164215564727783 L 9.246563911437988 2.301749229431152 L 4.979704856872559 7.624059200286865 L 4.98317289352417 7.625257015228271 L 4.978921890258789 7.624059200286865 L 4.978921890258789 10.52227306365967 L 7.018501281738281 8.32220458984375 L 9.602203369140625 9.206996917724609 L 11.3803596496582 0 Z">
				</path>
			</svg>
			<svg class="BCS_21_PCLine_26" viewBox="0 0 1365.56 2">
				<path id="BCS_21_PCLine_26" d="M 0 0 L 1365.559692382812 0">
				</path>
			</svg>
			<div id="BCS_21_PCsocials_grp_med_web_4_mob">
		{props.data.linkedInUrl?
				<div title="LinkedIn" id="BCS_21_PClinked_in_grp_med_web_4_pc">
					<svg class="BCS_21_PCEllipse_5_med_web_4_pc">
						<ellipse id="BCS_21_PCEllipse_5_med_web_4_pc" rx="25" ry="25" cx="25" cy="25">
						</ellipse>
					</svg>
					<div id="BCS_21_PClinked_in_website_4_layout">
				<a href={props.data.linkedInUrl} target="_blank">
						<svg class="BCS_21_PCn_website_2_layout" viewBox="-15.255 0 16.092 15.182">
							<path id="BCS_21_PCn_website_2_layout" d="M -5.186071872711182 9.679508821136551e-07 C -7.904781818389893 9.679508821136551e-07 -9.60834789276123 1.472553014755249 -9.923189163208008 2.504196882247925 L -9.923189163208008 0.1445503830909729 L -15.2549991607666 0.1445503830909729 C -15.18569564819336 1.397309064865112 -15.2549991607666 15.18160820007324 -15.2549991607666 15.18160820007324 L -9.923189163208008 15.18160820007324 L -9.923189163208008 7.044621467590332 C -9.923189163208008 6.592491626739502 -9.944971084594727 6.140363693237305 -9.807681083679199 5.815624713897705 C -9.440696716308594 4.911367893218994 -8.653267860412598 3.972789287567139 -7.237480163574219 3.972789287567139 C -5.38672399520874 3.972789287567139 -4.544512748718262 5.363495349884033 -4.544512748718262 7.400383949279785 L -4.544512748718262 15.18160820007324 L 0.8368034362792969 15.18160820007324 L 0.8368034362792969 6.820206165313721 C 0.8368034362792969 2.162956237792969 -1.813262462615967 9.679508821136551e-07 -5.186071872711182 9.679508821136551e-07">
							</path>
						</svg>
					</a>
						<svg class="BCS_21_PClinked_in_i_down" viewBox="0.931 -22.783 4.746 15.038">
							<path id="BCS_21_PClinked_in_i_down" d="M 0.9310001134872437 -7.745282173156738 L 5.676695346832275 -7.745282173156738 L 5.676695346832275 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -7.745282173156738 Z">
							</path>
						</svg>
						<svg class="BCS_21_PCtop_dot_i_website_2_layout" viewBox="-4.392 0 5.761 5.218">
							<path id="BCS_21_PCtop_dot_i_website_2_layout" d="M -1.493098974227905 0 C -3.245508193969727 0 -4.392000198364258 1.125370144844055 -4.392000198364258 2.611123323440552 C -4.392000198364258 4.06783390045166 -3.278510093688965 5.217626571655273 -1.560423135757446 5.217626571655273 L -1.526101112365723 5.217626571655273 C 0.2579902410507202 5.217626571655273 1.368839383125305 4.063873767852783 1.368839383125305 2.606502532958984 C 1.334516644477844 1.122730016708374 0.2579902410507202 0 -1.493098974227905 0">
							</path>
						</svg>
					</div>
				</div>:""}
			{props.data.instagramUrl?	
				<div title="Instagram" id="BCS_21_PCInsta_grp_med_web_4_pc">
					<svg class="BCS_21_PCEllipse_4_med_web_4_pc">
						<ellipse id="BCS_21_PCEllipse_4_med_web_4_pc" rx="25" ry="25" cx="25" cy="25">
						</ellipse>
					</svg>
				<a href={props.data.instagramUrl} target="_blank">
					<img id="BCS_21_PCinstagram_website_4_layout" src="./Themes/BakersStop/instagram_website_4_layout.png" srcset="./Themes/BakersStop/instagram_website_4_layout.png 1x" />
						
					</a>
				</div>:""}
			{props.data.whatsAppNumber?
				<div title="WhatsApp" id="BCS_21_PCwhatsapp_grp_med_web_4_pc">
					<svg class="BCS_21_PCEllipse_3_med_web_4_pc">
						<ellipse id="BCS_21_PCEllipse_3_med_web_4_pc" rx="25" ry="25" cx="25" cy="25">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="BCS_21_PCwhatsapp_lbl_website4_layout" src="./Themes/BakersStop/whatsapp_lbl_website4_layout.png" srcset="./Themes/BakersStop/whatsapp_lbl_website4_layout.png 1x" />
						</a>
				</div>:""}
			</div>
			<svg class="BCS_21_PCIcon_metro-location" viewBox="8.355 1.928 11.381 18.209">
				<path id="BCS_21_PCIcon_metro-location" d="M 14.04507637023926 1.928033471107483 C 10.90240859985352 1.928033471107483 8.354801177978516 4.475640773773193 8.354801177978516 7.618309020996094 C 8.354801177978516 13.30858421325684 14.04507637023926 20.13691520690918 14.04507637023926 20.13691520690918 C 14.04507637023926 20.13691520690918 19.7353515625 13.30858421325684 19.7353515625 7.618309020996094 C 19.7353515625 4.475640773773193 17.18770790100098 1.928033709526062 14.04507637023926 1.928033709526062 Z M 14.04507637023926 11.10360240936279 C 12.12019920349121 11.10360240936279 10.55978298187256 9.543186187744141 10.55978298187256 7.618309020996094 C 10.55978298187256 5.69343090057373 12.12019920349121 4.133015155792236 14.04507637023926 4.133015155792236 C 15.9699535369873 4.133015155792236 17.53037071228027 5.69343090057373 17.53037071228027 7.618309020996094 C 17.53037071228027 9.543186187744141 15.9699535369873 11.10360240936279 14.04507637023926 11.10360240936279 Z M 11.84009552001953 7.618309020996094 C 11.84009552001953 6.40053653717041 12.82730388641357 5.413327217102051 14.04507637023926 5.413327217102051 C 15.26284980773926 5.413327217102051 16.25005722045898 6.40053653717041 16.25005722045898 7.618309020996094 C 16.25005722045898 8.836081504821777 15.26284980773926 9.823290824890137 14.04507637023926 9.823290824890137 C 12.82730388641357 9.823290824890137 11.84009552001953 8.836081504821777 11.84009552001953 7.618309020996094 Z">
				</path>
			</svg>
			<div id="BCS_21_PCaddress">
				<span>{props.data.businessAddress}</span>
			</div>
		</div>
	</div>
	<div id="BCS_21_PCGroup_14851">
		<svg class="BCS_21_PCRectangle_655">
			<rect id="BCS_21_PCRectangle_655" rx="0" ry="0" x="0" y="0" width="764" height="595">
			</rect>
		</svg>
		<div id="BCS_21_PCAlways_Find_The_Best_Deals">
			<span>Always Find The Best Deals!</span>
		</div>
		<div id="BCS_21_PCSocial">
			<div id="BCS_21_PCFacebook">
				<span>Facebook</span>
			</div>
			<div id="BCS_21_PCInstagram">
				<span>Instagram</span>
			</div>
			<div id="BCS_21_PCTwitter">
				<span>Twitter</span>
			</div>
			<div id="BCS_21_PCSnapchat">
				<span>Snapchat</span>
			</div>
		</div>
		<div id="BCS_21_PCBEST_IN__CLASS_">
			<span>BEST IN <br/>CLASS </span>
		</div>
		<img id="BCS_21_PCMask_Group_20" src="./Themes/BakersStop/Mask_Group_20.png" srcset="./Themes/BakersStop/Mask_Group_20.png 1x" />
			
		<img id="BCS_21_PCMask_Group_21" src="./Themes/BakersStop/Mask_Group_21.png" srcset="./Themes/BakersStop/Mask_Group_21.png 1x" />
			
		<div id="BCS_21_PCSell_btn">
			<svg class="BCS_21_PCRectangle_659">
				<rect id="BCS_21_PCRectangle_659" rx="0" ry="0" x="0" y="0" width="145" height="38">
				</rect>
			</svg>
			<div id="BCS_21_PCGet_it_now">
				<span>Get it now!!</span>
			</div>
		</div>
		<div id="BCS_21_PCNAME">
			<span>{props.data.businessName}</span>
		</div>
	</div>
	<div id="BCS_21_PCContact_us_">
		<span>Contact us !</span>
	</div>
</div>
  )
}

export default BakersStop