import React, { useEffect, useState, useContext} from 'react'
import PeoplesSuggestionsUI from '../PC/PeoplesSuggestionsUI';
import PeoplesRequestsUI from '../PC/PeoplesRequestsUI';
import PeoplesConnectionsUI from '../PC/PeoplesConnectionsUI';
import PeoplesConnectionsMobUI from './PeoplesConnectionsMobUI';
import PeoplesRequestsMobUI from './PeoplesRequestsMobUI';
import PeoplesSuggestionsMobUI from './PeoplesSuggestionsMobUI';
//import '../../styles/PeoplesUI.css'

const PeoplesMobUI = () => {

    const [selectedTab,setSelectedTab] = useState("Connections");

  return (
    <div>

    {selectedTab==="Connections" ?  <PeoplesConnectionsMobUI selectedTab={(val)=>setSelectedTab(val)}/> :""}
      {selectedTab==="Requests" ? <PeoplesRequestsMobUI selectedTab={(val)=>setSelectedTab(val)}/>  :""}
     {selectedTab==="Suggestions" ?  <PeoplesSuggestionsMobUI selectedTab={(val)=>setSelectedTab(val)}/>:""}

   </div>
  )
}

export default PeoplesMobUI
