import React, { useEffect, useState, useContext} from 'react'
import '../../styles/ViewOfferingMobUI.css';
import { UserContext } from '../UserContext';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Currencies from "../Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BargainPopUpPC from '../BargainPopUpPC';
import OfferingPopUpPC from '../OfferingPopUpPC';
import TrophiesAndCountMob from './TrophiesAndCountMob';
import BargainTimeLineMob from './BargainTimeLineMob';

const ViewOfferingMobUI = (props) => {

    const {user} = useContext(UserContext);
    const [offerings,setOfferings] = useState({})
	const [biddings,setBiddings] = useState([])
	const [openGridModel,setOpenGridModel] = useState(false);
	const [meetingTitle,setMeetingTitle] = useState("");
    const [agenda,setAgenda] = useState("");
    const [meetingRemarks,setMeetingRemarks] = useState("");
	const [customerId,setCustomerId] = useState("");
    const [customerName,setCustomerName] = useState("");
	const [openMeetingModel,setOpenMeetingModel] = useState(false);
	const [meetingDateTime,setMeetingDateTime] = useState(new Date().toISOString().substring(0,16));
    const [previousRemarks,setPreviousRemarks] = useState("");
	const [selectedBargain,setSelectedBargain]=useState("");
    const [offeringId,setOfferingId]=useState("");

    const [title,setTitle]= useState("");
    const [createdById,setCreatedById]=useState("");
    const [createdByName,setCreatedByName]=useState("");
    const [openBargainModel,setOpenBargainModel] = useState(false);
    const [bargainedCurrency,setBargainedCurrency] = useState("")
    const [bargainedTime,setBargainedTime] = useState("");
    const [bargainedAmount,setBargainedAmount] = useState("");
    const [remarks,setRemarks] = useState("");
    const [needToBeDelivered,setNeedToBeDelivered]= useState(false);
	const [offeringPopup,setOfferingPopup] = useState(false)
    const [bargainedId,setBargainedId] = useState();
	const [bargainsHistory,setBargainsHistory] = useState([]);

    useEffect(()=>{
    if(user){

	if(props.selectedOffering){
		setOfferings(props.selectedOffering)
		
	}else if(props.selectedOfferingsId)   {
		getOfferingbyId(props.selectedOfferingsId)
		getBargainsidbyOffering(props.selectedOfferingsId)
	}
   
    }

    },[props.selectedOfferingsId])

const getOfferingbyId = (id)=>{
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getallofferingsbyoffid/" +id ;
    let apiParams = {
        method:"GET",
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
    console.log(repos.data)
         setOfferings(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));

}

//get Bargains of comunity
const getBargainsidbyOffering = (id)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/bargain/getbargainid/"+user._id; 
    let apiParams = {
      headers:{
        offeringsid:id,
    }}
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then(async(repos)=> {
        if (repos.data) {

			var bargainHistoryPromises = await Promise.all(repos.data.map(async (rec) => {
				const result = await getbargainhistorybyId(rec._id); // Add await here
				//console.log(result);
				return result;
			  }));
	  
			  setBargainsHistory(bargainHistoryPromises)
			  // Now you can work with the bargainHistoryPromises array
			   //console.log(bargainHistoryPromises.length);
			
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


const getbargainhistorybyId = async (id)=>{
if(id){
	let apiURL=process.env.REACT_APP_API_URL + "/bargain/getbargainhistoryid/" +id ;
    let apiParams = {
        method:"GET",
		// headers:{
		// 	userid:user._id,
		// }
    };
  return await fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
    //console.log(repos.data)
         //setBargainsHistory(repos.data)
        return repos.data;
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));

}
    
}

const closeGridModel = () => setOpenGridModel(false);

const showGrid = (id)=>{
    
	getAllBiddings(id);
	setOpenGridModel(true)
  } 


  const getAllBiddings = (offeringId)=>{

	let apiURL=process.env.REACT_APP_API_URL + "/bargain/getallbargains/"+offeringId; 
  
	fetch(apiURL)
	.then((res)=> (res.status===200 ? res.json() : res.text()))
	.then((repos)=> {
		if (repos.data) {
			console.log(repos.data)
		  setBiddings(repos.data)
		 
		}else alert(repos.message || repos);  
	}) 
	.catch((err) => alert(err));
  }

  var cols = [
	{field:'id', headerName: 'id', hide: true },
	{field:'offeringsId', headerName: 'reqId',hide: true  },
	{field:'accept', headerName: 'Accept',width: 60, renderCell: (params) =>{
	  return[
	   <div>
	 {params.row.status==="Rejected" ? "": <CheckIcon style={{color:params.row && params.row.status==="Accepted"?"green":""}} onClick={()=>{updateStatus(params.row,"Accepted")}}/>}
	  </div>
	  ];},},  
	  {field:'reject', headerName: 'Reject',width: 60, renderCell: (params) =>{
		return[
		 <div>
	   {params.row.status==="Accepted" ? "":  <ClearIcon  style={{color:params.row && params.row.status==="Rejected"?"red":""}} onClick={()=>{updateStatus(params.row,"Rejected")}}/>}
		</div>
		];},}, 
		{field:'meeting', headerName: 'Meeting',width: 70, renderCell: (params) =>{
		  return[
		   <div>
		  <CalendarMonthIcon onClick={()=>{openScheduleModel(params.row)}}/>
		  </div>
		  ];},},
	{field:'offeringsTitle', headerName: 'Title',width: 200,  },
	{field:'bargainedAmount', headerName: 'Amount' ,width: 100, },      
	{field:'bargainedHours', headerName: 'Hours' ,width: 100, },      
	{field:'needToBeDelivered', headerName: 'Need To Be Delivered' ,width: 150,},      
	{field:'customerName', headerName: 'Customer Name' ,width: 150, },      
	{field:'remarks', headerName: 'Remarks' ,width: 400, },      
	{field:'customerid', headerName: 'Customer ID',hide: true,width: 100,},      
   
];


const openScheduleModel = (row) =>{
  
    //  setOfferingsId(row.offeringsId);
     setMeetingTitle(row.offeringsTitle);
     setCustomerId(row.customerid);
     setCustomerName(row.customerName)
   
    setOpenMeetingModel(true);
  }


  const closeMeetingModel = () => setOpenMeetingModel(false);


  const handleClose = ()=>{
	setOpenMeetingModel(false);
	//props.closePopUp()
  }

  const ScheduleMeeting = () =>{
 
	if(meetingTitle ===""||undefined)
	{
	  return alert("Please Enter Meeting Title !!")
	}else if(agenda===""||undefined){
	  return alert("Please Enter Agenda Of Meeting !!")
	}
	setOpenMeetingModel(false);
	let apiURL=process.env.REACT_APP_API_URL + "/meetings/schedulemeetings";
	  let apiParams = {
		  method:"POST",
		  headers:{
			// requirementtitle:requirementTitle,
			// requirementid:requirementId,
			requesterid:user._id,
			requestername:user.name,
			respondentid:customerId,
			respondentname:customerName,
			meetingtitle:meetingTitle,
			meetingdatetime:meetingDateTime,
			agenda:encodeURIComponent(agenda),
			remarks:encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+meetingRemarks),
			status:"Requested",
  
  
  
		  },
		 
	  };
	  fetch(apiURL, apiParams)
	  .then((res)=> (res.status===200 ? res.json() : res.text()))
	  .then((repos)=> {
		  if (repos.data) {
		  
			alert("Meeting Scheduled Successfully");
			
		//    setTimeout(() => {
		// 	getOfferings()
		//   }, 1500);
		  
		  }else alert(repos.message || repos);
	  }) 
	  .catch((err) => alert(err));
  
   }

  const updateStatus = (row,status)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/bargain/updatebargains/"+row.id;
    let apiParams = {
        method:"POST",
        headers:{
		offeringid:row.offeringsId,
		userid:user._id,
		username:user.name,
        status:status,
  
        },
       
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
         
          alert("Offering Status Updated Successfully!!");
          
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
    }

	const updateIsActive = (id) =>{

		let confirmation=window.confirm("Are you sure ?")
		if(!confirmation){
		  return
		}
		let apiURL=process.env.REACT_APP_API_URL + "/offerings/updateofferingstatus/" +id;
		let apiParams = {
		  method:"POST",
		  headers:{
			active:false
		  },
	   };
	  
	   fetch(apiURL, apiParams)
	   .then((res)=> (res.status===200 ? res.json() : res.text()))
	   .then((repos)=> {
		   if (repos.data) {
			 alert("Offering Removed Successfully");
			// props.showAlert("Job Removed Successfully","success")
			// setTimeout(() => {
			//   getOfferings()
			// }, 2000);
		   }else alert(repos.message || repos);
	   }) 
	   .catch((err) => alert(err));
	  
	  }

	  const addBid=(id,title,vendorId,vendorName)=>{
		setSelectedBargain({id,title,vendorId,vendorName})
		setOfferingId(id);
		setTitle(title);
		setCreatedById(vendorId);
		setCreatedByName(vendorName);
		getBargains(id)
		setOpenBargainModel(true);
	
		}
	
  
  //get Bargains of comunity
  const getBargains = (id)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/bargain/getbargain/"+user._id; 
    let apiParams = {
      headers:{
        offeringsid:id,
    }}
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
            console.log(repos.data)
           setBargainedId(repos.data[0] && repos.data[0]._id)
           setBargainedAmount(repos.data[0] && repos.data[0].bargainedAmount);
           setBargainedCurrency(repos.data[0] && repos.data[0].bargainedCurrency ? JSON.parse(repos.data[0].bargainedCurrency).cc:"INR");
           setBargainedTime(repos.data[0] && repos.data[0].bargainedHours);
           setNeedToBeDelivered(repos.data[0] && repos.data[0].needToBeDelivered);
           setRemarks(repos.data[0] && repos.data[0].remarks);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  const handleLike = (offId) =>{
	
	let apiURL = process.env.REACT_APP_API_URL + "/offerings/updatetrophy/"+offId;
	let apiParams = {
	  method: "POST",
	  headers: {
			  id:user._id,
			  name: user.name,
			  nickname:user.nickName,	
			  imageid:user.imageId,
			 
	  },
	};
	fetch(apiURL, apiParams)
	  .then((res) => (res.status === 200 ? res.json() : res.text()))
	  .then((repos) => {
		if (repos.data) {
		
		 // alert("Trophy Updated Successfully!!")
		 getOfferingbyId(offId)
		}
	  })
	  .catch((err) => alert(err));
	  return false
  };


  // const rowData = [];      

const rowData = (biddings||[]).map((bid) => {
       
	return{
   
	id: bid._id,
	offeringsId:bid.offeringsId,
	offeringsTitle:bid.offeringsTitle,
	bargainedAmount:bid.bargainedAmount,
	bargainedHours:bid.bargainedHours,
	needToBeDelivered:bid.needToBeDelivered,
	customerName:bid.customerName,
	customerid:bid.customerId,
	remarks:bid.remarks,
	status:bid.status,
	};
  });
  

  return (
	<>
   <div id="view_page_off_mob">
	<img id="previous_back_btn_back_page_mob" src="./MyPortfolio/Offerings/previous_back_btn_back_page_vi.png" onClick={()=>props.backOff()}/>
		
	<div id="all_grp_with_btn_off_mob">
		<div id="Scroll_cars_preview__mob_off">
		{
            offerings.offeringsImagesUrl && JSON.parse(offerings.offeringsImagesUrl).map((rec,idx)=>( 

			<div id="preview_1_view_page_mob_off" style={{left:idx*30+"px"}}>
				<svg class="img_preview_1_view_page_mob_of">
					<rect id="img_preview_1_view_page_mob_of" rx="7" ry="7" x="0" y="0" width="135" height="85">
					</rect>
				</svg>
				<img id="Image_preview_1_view_page_mob_" src={rec?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec).split('/')[5] :"./MyPortfolio/Offerings/Image_preview_1_view_page_mob_.png"}  />
					
			</div>

		))}	

		</div>
		<div id="Nike_Air_Force_1_off_view_page">
			<span>{offerings.offeringTitle}</span>
		</div>
		{/* <img title="Like" id="trophy_view_page_mob_off" src={offerings.trophies && (JSON.parse(offerings.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"} onClick={()=>handleLike(offerings._id)} />
			
		<div id="n_234_count_view_page_mob_off">
			<span>{offerings.trophies && JSON.parse(offerings.trophies).length}</span>
		</div> */}
		<div id="trophy_view_page_mob_off">
		<TrophiesAndCountMob  portfolioType={"offerings"} portfolioId={offerings._id}/>
        </div>
		<svg class="line_before_budget_view_page_m" viewBox="0 0 346 1">
			<path id="line_before_budget_view_page_m" d="M 0 1 L 346 0">
			</path>
		</svg>
		<div id="Budget_Delivery_Deadline_Dview">
			<span>Budget<br/>Delivery Deadline<br/>Delivery Location</span>
		</div>
		<div id="Afs_10000_AFN_details_info_off">
			<span>{offerings.offeredcurrency && JSON.parse(offerings.offeredcurrency).symbol} {offerings.budget} ({offerings.offeredcurrency && JSON.parse(offerings.offeredcurrency).cc})<br/>{( offerings.deliveryDuration ? offerings.deliveryDuration:"")} Hours<br/>{offerings.deliveryLocation}</span>
		</div>
		<div id="big_display_share_and_edit_mob">
			<svg class="img_2_big_view_page_mob_bdr">
				<rect id="img_2_big_view_page_mob_bdr" rx="10" ry="10" x="0" y="0" width="325" height="353">
				</rect>
			</svg>
			<img id="Image_2_big_view_page_mob" src={offerings.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(offerings.defaultOfferingsImageUrl.split('/')[5]):"./MyPortfolio/Offerings/Image_2_big_view_page_mob.png"} />
				
			<img id="share_btn_view_page_mob_off" src="./MyPortfolio/Offerings/share_btn_view_page_mob_off.png" srcset="./MyPortfolio/Offerings/share_btn_view_page_mob_off.png 1x" />
				
			{props.isOffering===false?<img title="Edit" id="edit_req_btn_view_page_mob_off" src="./MyPortfolio/Offerings/edit_req_btn_view_page_mob_off.png" onClick={()=>setOfferingPopup(true)} />:""}
				
		</div>
		<div id="Scroll_description_mob_off">
			<div id="description_of_title_view_page_mob_off">
				<span>{offerings.description}</span>
			</div>
		</div>
		<svg class="line_below_location_view_page_" viewBox="0 0 346 1">
			<path id="line_below_location_view_page_" d="M 0 0 L 346 0">
			</path>
		</svg>
		{props.isOffering===false?	
		<button id="Button_bargain_view_page_mob_o" onClick={()=>showGrid(offerings._id)}>
			<svg class="bargain_bdr_view_page_mob_off">
				<rect id="bargain_bdr_view_page_mob_off" rx="5" ry="5" x="0" y="0" width="140" height="45">
				</rect>
			</svg>
			<div id="bargain_view_page__mob_off">
				<span>Bargains</span>
			</div>
		</button>:
		<button id="Button_bargain_view_page_mob_o" onClick={()=>addBid(offerings._id,offerings.offeringTitle,offerings.vendorId,offerings.vendorName)}>
			<svg class="bargain_bdr_view_page_mob_off">
				<rect id="bargain_bdr_view_page_mob_off" rx="5" ry="5" x="0" y="0" width="140" height="45">
				</rect>
			</svg>
			<div id="bargain_view_page__mob_off">
				<span>Bargain</span>
			</div>
		</button>}
		{props.isOffering===false?
		<button id="Button_disable_view_page_mob_o" onClick={() => updateIsActive(offerings._id)}>
			<svg class="disable_bdr_view_page_moboff">
				<rect id="disable_bdr_view_page_moboff" rx="5" ry="5" x="0" y="0" width="140" height="45">
				</rect>
			</svg>
			<div id="disable_view_page_mob_off">
				<span>Disable</span>
			</div>
		</button>:""}
	</div>

	{(bargainsHistory.length > 0 ) ?
	<div style={{position:"absolute",top: "973px",height:"220px",width:"100%",overflowX:"hidden",overflowY:"auto"}}>
{bargainsHistory.map((rec)=>(
	<BargainTimeLineMob bargainsHistory={rec}/>
	))
}
</div>:""
}

	<div id="trophie_detail_view_page__mob_" style={{top:bargainsHistory.length > 0?"1210px":"1050px"}}>
	{offerings.trophies && JSON.parse(offerings.trophies).map((rec)=>( 
		<div id="card_1_trohpie_view_page_mob_p">
			<img id="img_who_liked_view_page_mob_pc" src={rec.imageId?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.imageId):"./MyPortfolio/Offerings/img_who_liked_view_page_mob_pc.png"}  />
				
			<div id="Bargained_view_page_mob_off">
				<span>{rec.name}</span>
			</div>
		</div>
		))}
		<div id="Trophies_Awarded_By_view_page_">
			<span>Trophies Awarded By</span>
		</div>
	</div>
</div>

{/* Offering Pop Up */}
<OfferingPopUpPC openOffpopup={offeringPopup} data={offerings} closeOffpopup={(id)=>{setOfferingPopup(false);if(id)getOfferingbyId(id)}}/>

     {/* Bids data in data grid */}
<Modal
        open={openGridModel}
        onClose={closeGridModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
         
         <Typography id="modal-modal-description">
         <Container style={{padding:"0px"}}>
         <div className = "BargainModalDesign">
       <div style={{alignItems:"center"}}>
         <h2 className="titleBargains"><b>Bargains Recieved</b></h2>
         <Button title="Close" variant="Standard" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,color:"red" }} onClick={()=>closeGridModel()} ><CloseIcon /></Button>   
         </div>
   
       <div style={{height:750,width: '100%',margin:"0px"}}>
       <DataGrid  rows={rowData} columns={cols} components={{
          Toolbar: GridToolbar,
        }}
          />
          
           </div>
            </div>
      </Container> 
          </Typography>
        
      </Modal>


	  <BargainPopUpPC  openBarPopUp={openBargainModel} selectedBargain={selectedBargain} closeBarPopUp={()=>{setOpenBargainModel(false);}}/>

	   {/* Pop up for schedule meeting */}
 <Modal
         open={openMeetingModel}
        onClose={closeMeetingModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
      <div id="pop_up_meeting_room">
	<img id="meetingRoom_popbg" src="meetingroompopupbg.png" srcset="meetingroompopupbg.png 1x, meetingroompopupbg@2x.png 2x" />
		
	<div id="Group_border_meetingPop">
		<svg class="border_meetpop_">
			<rect id="border_meetpop_" rx="45.78704833984375" ry="45.78704833984375" x="0" y="0" width="91.574" height="371" >
			</rect>
		</svg>
		<div id="Meeting_Request_lbl_mp">
			<span>Meeting Request</span>
		</div>
	</div>
	<input placeholder="Agenda" multiline class="txtprevious_remarks_val"   rows={3}  sx={{ m: 1, width: '35ch' }} size="small" label="Agenda" variant="outlined"  value={agenda} onChange={(e)=> {
       setAgenda(e.target.value);}} />
	
	<input placeholder="Meeting Title" class="txtMeeting_Room_pop_val"  value={meetingTitle} onChange={(e)=> {
       setMeetingTitle(e.target.value); }}  />
	
	<input type="datetime-local" id="MeetingTime" format="dd-mm-yyyy" class="txtDate_pop_val"  value={meetingDateTime} onChange={(e)=>setMeetingDateTime(e.target.value)}/>
		
	<div id="Meeting_On_lbl_mp">
		<span>Meeting On :</span>
	</div>

	<textarea placeholder="Previous Remarks" class="txtagenda_pop_val"   value={previousRemarks}/>
		
	<input placeholder="Remark" class="txtremarks_val_mp"  value={meetingRemarks} onChange={(e)=> {
          setMeetingRemarks(e.target.value);  }}/>
		
    

 <button id="Group_btn_propose_pr" style={{left: "140px"}} onClick={()=>ScheduleMeeting()}>
		<svg class="border_pro_btn_pr">
			<rect id="border_pro_btn_pr" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Propose_btn_pro__lbl">
			<span>Send</span>
		</div>
	</button>

  
	<img id="closeiconmeetingpop" src="closeiconmeetingpop.png" srcset="closeiconmeetingpop.png 1x, closeiconmeetingpop@2x.png 2x" onClick={()=>closeMeetingModel()}/>
</div>
           </Modal>  
</>
  )
}

export default ViewOfferingMobUI
