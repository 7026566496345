import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
 import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/MyReqandComReqNewUI.css';
import Switch from '@mui/material/Switch';

import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Currencies from "../Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import OfferingsUI from '../OfferingsUI';
import CommunityOfferingsUI from '../CommunityOfferingsUI';
import MyRequirementsUI from '../MyRequirementsUI';
import CommunityRequirementsUI from '../CommunityRequirementsUI';
import CommunityRequirementsMobUI from '../MobUI/CommunityRequirementsMobUI';
import MyRequirementsNewUI from './MyRequirementsNewUI';
import CommunityRequirementsNewUI from './CommunityRequirementsNewUI';
import ViewRequirementsUI from './ViewRequirementsUI';


const MyReqandComReqNewUI = (props) => {   

//UseState
    const [toggle,setToggle] = useState(false);
	const [selectedRequirementsId,setSelectedRequirementsId] = useState("");
    const { user, setUser} = useContext(UserContext);


//UseEffect

  return (
    <div id="requrements_page_pc_new">
  	<div id="heading_grp_offreqevent_req_PC">
		<div id="req_offreqevent_req_PC">
			<span>Requirements</span>
		</div>
		<div id="selected_grp_offreqevent_req_P">
			<div id="requirements_offreqevent_req_P" onClick={()=>props.selectedTab("offerings")}>
				<span>Offerings</span>
			</div>
		</div>
		<div id="A_offreqevent_req_PC" onClick={()=>props.selectedTab("events")}>
			<span>Events</span>
		</div>
		<svg class="underline_offreqeventreq_PC">
			<rect id="underline_offreqeventreq_PC" rx="0" ry="0" x="0" y="0" width="109" height="3">
			</rect>
		</svg>
	</div>

	<div id="filters_area_new_events_page">
		<svg class="bdr_new_events_page_pc">
			<rect id="bdr_new_events_page_pc" rx="5" ry="5" x="0" y="0" width="130" height="30">
			</rect>
		</svg>
		<div id="Find_Event_new_events_page_pc">
			<span>Filter</span>
		</div>
		<div id="category_grp_pc">
			<select class="category_input_new_events_page">
				<option>Product</option><option>Music</option><option>Workshop</option><option>UpSkill and Training</option><option>Others</option>
			</select>
			<div id="Category_new_events_page_pc">
				<span>Category</span>
			</div>
		</div>
		<div id="time_cate_gory_pc">
			<select class="time_bdr_filter_new_events_pag">
				<option>Recent</option><option>Today</option><option>This Week</option><option>This Month</option><option>This Year</option> 
			</select>
			<div id="Time_new_events_page">
				<span>Time</span>
			</div>
		</div>
	</div>

	<div id="swtitch_grp_event_req_PC">
		<div id="My_requirements_req_pc">
			<span>My Requirements</span>
		</div>
		<div id="Community_requirements_req_PC">
			<span>Community Requirements</span>
		</div>
		<div class="switch_eent_req_PC">
		<Switch id='switch_eent_req_PC' onChange={(e)=>{setToggle(e.target.checked);setSelectedRequirementsId("")}} checked={toggle} color="default" />
		</div>
	</div>
{selectedRequirementsId===""? toggle===false ? <MyRequirementsNewUI requirementsSelected={(val)=>setSelectedRequirementsId(val)}/> : <CommunityRequirementsNewUI requirementsSelected={(val)=>setSelectedRequirementsId(val)}/>:<ViewRequirementsUI selectedRequirementsId={selectedRequirementsId} isRequirement={toggle} backReq={()=>setSelectedRequirementsId("")} />}
    </div>
  )
}

export default MyReqandComReqNewUI
