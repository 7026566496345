import React, { useContext, useState, useEffect } from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import {SocketContext} from '../../SocketContext';
import { io } from "socket.io-client";
import { useSearchParams } from 'react-router-dom';
import '../../styles/MeetingRoomScheduleSlide.css';
import { UserContext } from "../UserContext";
import MeetingPopUpUI from '../MeetingPopUpUI';


const MeetingRoomScheduleSlide = (props) => {

const [scheduleSlide,setScheduleSlide] = useState(false);
const { user, setUser } = useContext(UserContext);
const [searchParams] = useSearchParams();
const [ availableMeetings,setAvailableMeetings] = useState([]);

useEffect(()=>{
if(user){
	if(props.openScheduleSlide)
	{
		setScheduleSlide(props.openScheduleSlide)
		getMeetings()
	}
}

},[props.openScheduleSlide])


const handleClose=()=>{
	
	props.closescheduleSlide()
}

///To get all meetings available for the logged in user
const getMeetings = ()=>{
 
	let apiURL = process.env.REACT_APP_API_URL + "/meetings/getallmeetings/" + user._id;
	let apiParams = {
	  method: "GET",
	};
	fetch(apiURL, apiParams)
	  .then((res) => (res.status === 200 ? res.json() : res.text()))
	  .then((repos) => {
		if (repos.data) {
			console.log(repos.data)
			setAvailableMeetings(repos.data)
		} else alert(repos.message || repos);
	  })
	  .catch((err) => alert(err));
  };


  
  const openScheduler = (data)=>{
	//  setMeetingData(data)
	//  setOpenMeetingModel(true)
	props.closescheduleSlide(data,true)
   }


  return (
    <div>
     <div id="upcomemeeting_pop" className="slideInDown">
	<div id="overall_upcome_meet_pop">
		<img id="bg_design_meetup" src="bg_design_meetup.png" srcset="bg_design_meetup.png 1x, bg_design_meetup@2x.png 2x" onClick={()=>handleClose()}/>
			
		<div id="meeting_scrrol_mob_">
		{availableMeetings.map((off) => (
			<div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
			<div id="card_1_upcome" onClick={()=>openScheduler(off)}>
				<svg class="back_desgin_meet_up_p">
					<linearGradient id="back_desgin_meet_up_p" spreadMethod="pad" x1="0" x2="0.943" y1="0.77" y2="0.933">
						<stop offset="0" stop-color="#ffe16b" stop-opacity="1"></stop>
						<stop offset="1" stop-color="#b1eabe" stop-opacity="1"></stop>
					</linearGradient>
					<rect id="back_desgin_meet_up_p" rx="17" ry="17" x="0" y="0" width="148" height="107">
					</rect>
				</svg>
				<div id="page1_upcome">
					<svg class="front_design_meet_up">
						<rect id="front_design_meet_up" rx="16" ry="16" x="0" y="0" width="148" height="102">
						</rect>
					</svg>
					<div id="Web_Development_meetup">
						<span><marquee>{off.meetingTitle?off.meetingTitle:""}</marquee></span>
					</div>
					<div id="Cancelled_meetup">
						<span>{off.status}</span>
					</div>
					<div id="date_lbl_meetup">
						<span>{off.meetingDateTime?off.meetingDateTime.split("T")[0]:""}</span>
					</div>
					<div id="tine_lbl_meetup">
						<span>{off.meetingDateTime?off.meetingDateTime.split("T")[1].slice(0,5):0}</span>
					</div>
					<img id="date_img_pop_meet" src="date_img_pop_meet.png" srcset="date_img_pop_meet.png 1x, date_img_pop_meet@2x.png 2x" />
						
					<img id="status_img_pop_meet" src="status_img_pop_meet.png" srcset="status_img_pop_meet.png 1x, status_img_pop_meet@2x.png 2x" />
						
					<img id="time_img_pop_meet" src="time_img_pop_meet.png" srcset="time_img_pop_meet.png 1x, time_img_pop_meet@2x.png 2x" />
						
				</div>
			</div>
			</div>
))}
		</div>
	</div>
	<img id="arrow-right_open_close" src="arrow-right_open_close.png" srcset="arrow-right_open_close.png 1x, arrow-right_open_close@2x.png 2x" onClick={()=>handleClose()}/>
		
</div>
    </div>
  )
}

export default MeetingRoomScheduleSlide
