import React, { useState, useEffect, useContext } from "react";
import { Button, Divider } from "@mui/material";
import "../../styles/AddAttendiesPopUp.css";
import Modal from "@mui/material/Modal";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";

const AddAttendiesPopUp = (props) => {
    const {user} = useContext(UserContext)
const [openPop,setOpenPop] = useState(false);
const [allStatus,setAllStatus] = useState([]);

    useEffect(()=>{
        if(props.openPeoplePop){
            setOpenPop(props.openPeoplePop)
            getStatus()
        }
    },[props.openPeoplePop])


    const handleClose = ()=>{
        setOpenPop(false)
        props.closePop()
    }


    const getStatus = ()=>{
 
        let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
        let apiParams = {
          method: "GET",
        };
        fetch(apiURL, apiParams)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
                //  console.log(repos.data)
                setAllStatus(repos.data)
                
            } else alert(repos.message || repos);
          })
          .catch((err) => alert(err));
      };

      const selectData = (data)=>{
        setOpenPop(false)
        props.closePop(data)
      }


  return (
    <Modal
    open={openPop}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{ overflow: "scroll", textAlign: "center" }}
  >
    <div id="add_attendies_pc">
	<svg class="bdr_of_attendies_people">
		<rect id="bdr_of_attendies_people" rx="10" ry="10" x="0" y="0" width="232" height="200">
		</rect>
	</svg>
	<div id="Scroll_add_attendies_people">
    {allStatus.map((rec)=>(
		<div id="card_1_grp_attendies_people" onClick={()=>selectData(rec)}>
			<img id="attendies_people_imreanmeeting" src={rec.user1Name===user.name ?  (rec.user2Image.imageUrl && rec.user2Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.user2Image.imageUrl.split('/')[5]):"./CalanderDashboard/attendies_people_imreanmeeting.png":(rec.user1Image.imageUrl && rec.user1Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.user1Image.imageUrl.split('/')[5]):"./CalanderDashboard/attendies_people_imreanmeeting.png"}  />
				
			<div id="attendies_people_imran_fool_me">
				<span>{rec.user1Name===user.name ? rec.user2Name:rec.user1Name}</span>
			</div>
			<div id="OP_FSD_Lbl_">
				<span>{rec.user1Name===user.name ? rec.user2Image? (rec.user2Image.businessName):rec.user1Image ?rec.user1Image.businessName:"":""} - {rec.user1Name===user.name ? rec.user2Image? (rec.user2Image.profession):rec.user1Image ?rec.user1Image.profession:"":""}</span>
			</div>
		</div>
        ))
    }
	</div>
	<img id="close_pop_people" src="./CalanderDashboard/close_meeting_pop.png" srcset="./CalanderDashboard/close_meeting_pop.png 1x" onClick={()=>handleClose()}/>
		
	<input placeholder="Search Names" class="Rectangle_699" />
		
</div>
</Modal>
  )
}

export default AddAttendiesPopUp
