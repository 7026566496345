import React from 'react';
import "../../../styles/KnowledgeSpace.css";

function KnowledgeSpace(props) {
  return (
    <div id="KnowledgeSpace">
	<div id="KS_20_PCGroup_117">
		<img id="KS_20_PClocation" src="./Themes/KnowledgeSpace/location.png" srcset="./Themes/KnowledgeSpace/location.png 1x" />
			
		<div id="KS_20_PCn_Copyright_Live_OPC_Private_L">
			<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
		</div>
		<img id="KS_20_PCmessage" src="./Themes/KnowledgeSpace/message.png" srcset="./Themes/KnowledgeSpace/message.png 1x" />
			
		<img id="KS_20_PCphone" src="./Themes/KnowledgeSpace/phone.png" srcset="./Themes/KnowledgeSpace/phone.png 1x" />
			
		<div id="KS_20_PCScroll_Group_66">
			<div id="KS_20_PCLSE_Houghton_Street_London_WC2">
				<span>{props.data.businessAddress}</span>
			</div>
		</div>
		<div id="KS_20_PChellogmailcom">
			<span>{props.data.businessEmail}</span>
		</div>
		<div id="KS_20_PCn_4057686">
			<span>{props.data.businessPhone}</span>
		</div>
		<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="KS_20_PCRectangle_740">
			<rect id="KS_20_PCRectangle_740" rx="0" ry="0" x="0" y="0" width="476" height="314">
			</rect>
		</iframe>
	</div>
	<div id="KS_20_PCGroup_111">
		<div id="KS_20_PCCourses">
			<span>Courses</span>
		</div>
		<div id="KS_20_PCScroll_Group_67">
			
	{props.offeringsData.map((off,idx)=>(<div id="KS_20_PCGroup_15053">
				<img id="KS_20_PCRectangle_71" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/KnowledgeSpace/Rectangle_71.png"} />
					
				<div id="KS_20_PCGroup_25">
					<svg class="KS_20_PCRectangle_72">
						<rect id="KS_20_PCRectangle_72" rx="0" ry="0" x="0" y="0" width="371" height="399">
						</rect>
					</svg>
					<svg class="KS_20_PCRectangle_73">
						<rect id="KS_20_PCRectangle_73" rx="0" ry="0" x="0" y="0" width="371" height="3">
						</rect>
					</svg>
					<svg class="KS_20_PCRectangle_74">
						<rect id="KS_20_PCRectangle_74" rx="0" ry="0" x="0" y="0" width="245" height="2">
						</rect>
					</svg>
					<div id="KS_20_PCWeb_Design">
						<span>{off.offeringTitle}</span>
					</div>
					<div id="KS_20_PCTime__3_pm">
						<span>Duration : {parseInt(off.deliveryDuration/60)} Hours {off.deliveryDuration%60} Minutes</span>
					</div>
					<div id="KS_20_PCScroll_Group_68">
						<div id="KS_20_PCLorem_ipsum_dolor_sit_amet_con">
							<span>{off.description}</span>
						</div>
					</div>
					<svg class="KS_20_PCRectangle_76">
						<rect id="KS_20_PCRectangle_76" rx="30" ry="30" x="0" y="0" width="325" height="60">
						</rect>
					</svg>
					<div id="KS_20_PCJoin_Now">
						<span>Join Now</span>
					</div>
				</div>
			</div>))}
		</div>
	</div>
	<div id="KS_20_PCGroup_112">
		<svg class="KS_20_PCPath_2" viewBox="0 0 1440 664">
			<path id="KS_20_PCPath_2" d="M 0 0 L 1440 0 L 1440 664 L 0 664 L 0 0 Z">
			</path>
		</svg>
		<div id="KS_20_PCOur_Teachers">
			<span>Our Teachers</span>
		</div>
		<div id="KS_20_PCGroup_27">
			<img id="KS_20_PCEllipse_6" src="./Themes/KnowledgeSpace/Ellipse_6.png" srcset="./Themes/KnowledgeSpace/Ellipse_6.png 1x" />
				
			<div id="KS_20_PCGroup_26">
				<div id="KS_20_PCVelvet_Vachon">
					<span>Velvet Vachon</span>
				</div>
				<div id="KS_20_PCDesign_Head">
					<span>Design Head</span>
				</div>
			</div>
		</div>
		<div id="KS_20_PCGroup_27_d">
			<img id="KS_20_PCEllipse_6_d" src="./Themes/KnowledgeSpace/Ellipse_6_d.png" srcset="./Themes/KnowledgeSpace/Ellipse_6_d.png 1x" />
				
			<div id="KS_20_PCGroup_26_d">
				<div id="KS_20_PCArlene_Anello">
					<span>Arlene Anello</span>
				</div>
				<div id="KS_20_PCSEO_Head">
					<span>SEO Head</span>
				</div>
			</div>
		</div>
		<div id="KS_20_PCGroup_27_ea">
			<img id="KS_20_PCEllipse_6_ea" src="./Themes/KnowledgeSpace/Ellipse_6_ea.png" srcset="./Themes/KnowledgeSpace/Ellipse_6_ea.png 1x" />
				
			<div id="KS_20_PCGroup_26_ea">
				<div id="KS_20_PCBenton_Colley">
					<span>Benton Colley</span>
				</div>
				<div id="KS_20_PCPhotography_Head">
					<span>Photography Head</span>
				</div>
			</div>
		</div>
		<div id="KS_20_PCGroup_27_ec">
			<img id="KS_20_PCEllipse_6_ed" src="./Themes/KnowledgeSpace/Ellipse_6_ed.png" srcset="./Themes/KnowledgeSpace/Ellipse_6_ed.png 1x" />
				
			<div id="KS_20_PCGroup_26_ee">
				<div id="KS_20_PCFloyd_Fukuda">
					<span>Floyd Fukuda</span>
				</div>
				<div id="KS_20_PCMarketing_HOD">
					<span>Marketing HOD</span>
				</div>
			</div>
		</div>
		<div id="KS_20_PCGroup_27_eh">
			<img id="KS_20_PCEllipse_6_ei" src="./Themes/KnowledgeSpace/Ellipse_6_ei.png" srcset="./Themes/KnowledgeSpace/Ellipse_6_ei.png 1x" />
				
			<div id="KS_20_PCGroup_26_ej">
				<div id="KS_20_PCElena_Cully">
					<span>Elena Cully</span>
				</div>
				<div id="KS_20_PCDesign_Head_el">
					<span>Design Head</span>
				</div>
			</div>
		</div>
		<div id="KS_20_PCGroup_27_em">
			<img id="KS_20_PCEllipse_6_en" src="./Themes/KnowledgeSpace/Ellipse_6_en.png" srcset="./Themes/KnowledgeSpace/Ellipse_6_en.png 1x" />
				
			<div id="KS_20_PCGroup_26_eo">
				<div id="KS_20_PCBurton_Brooke">
					<span>Burton Brooke</span>
				</div>
				<div id="KS_20_PCWeb_Technologist">
					<span>Web Technologist</span>
				</div>
			</div>
		</div>
		<div id="KS_20_PCGroup_27_er">
			<img id="KS_20_PCEllipse_6_es" src="./Themes/KnowledgeSpace/Ellipse_6_es.png" srcset="./Themes/KnowledgeSpace/Ellipse_6_es.png 1x" />
				
			<div id="KS_20_PCGroup_26_et">
				<div id="KS_20_PCRessie_Rottman">
					<span>Ressie Rottman</span>
				</div>
				<div id="KS_20_PCDesign_Head_ev">
					<span>Design Head</span>
				</div>
			</div>
		</div>
		<div id="KS_20_PCGroup_27_ew">
			<img id="KS_20_PCEllipse_6_ex" src="./Themes/KnowledgeSpace/Ellipse_6_ex.png" srcset="./Themes/KnowledgeSpace/Ellipse_6_ex.png 1x" />
				
			<div id="KS_20_PCGroup_26_ey">
				<div id="KS_20_PCReed_Recio">
					<span>Reed Recio</span>
				</div>
				<div id="KS_20_PCPhotography_Head_e">
					<span>Photography Head</span>
				</div>
			</div>
		</div>
		<div id="KS_20_PCGroup_27_e">
			<img id="KS_20_PCEllipse_6_e" src="./Themes/KnowledgeSpace/Ellipse_6_e.png" srcset="./Themes/KnowledgeSpace/Ellipse_6_e.png 1x" />
				
			<div id="KS_20_PCGroup_26_e">
				<div id="KS_20_PCNancee_Bluford">
					<span>Nancee Bluford</span>
				</div>
				<div id="KS_20_PCSEO_Head_e">
					<span>SEO Head</span>
				</div>
			</div>
		</div>
	</div>
	<div id="KS_20_PCGroup_108">
		<svg class="KS_20_PCRectangle_1">
			<rect id="KS_20_PCRectangle_1" rx="0" ry="0" x="0" y="0" width="1440" height="700">
			</rect>
		</svg>
		<img id="KS_20_PCPath_1" src="./Themes/KnowledgeSpace/Path_1.png" srcset="./Themes/KnowledgeSpace/Path_1.png 1x" />
			
		<div id="KS_20_PCKnowledge_is_power">
			<span>Knowledge is power</span>
		</div>
		<div id="KS_20_PCAny_succesfull_career_starts_w">
			<span>Any succesfull career starts with good education</span>
		</div>
		<img id="KS_20_PClogo" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/KnowledgeSpace/logo.png"} />
			
		<svg class="KS_20_PCRectangle_148">
			<rect id="KS_20_PCRectangle_148" rx="28" ry="28" x="0" y="0" width="195" height="56">
			</rect>
		</svg>
		<div id="KS_20_PCLearn_more">
			<span>Learn more</span>
		</div>
	</div>
	<div id="KS_20_PCName">
		<span>{props.data.businessName}</span>
	</div>
	<div id="KS_20_PCGroup_15056">
		<div id="KS_20_PCGroup_109">
			<img id="KS_20_PCtamanna-rumee-vaTsR-ghLog-unsp" src="./Themes/KnowledgeSpace/tamanna-rumee-vaTsR-ghLog-unsp.png" srcset="./Themes/KnowledgeSpace/tamanna-rumee-vaTsR-ghLog-unsp.png 1x" />
				
			<div id="KS_20_PCGroup_3">
				<div id="KS_20_PCMission">
					<span>Mission</span>
				</div>
				<div id="KS_20_PCScroll_Group_65">
					<div id="KS_20_PCLorem_ipsum_dolor_sit_amet_con_fl">
						<span>{props.data.mission}</span>
					</div>
				</div>
			</div>
		</div>
		<div id="KS_20_PCGroup_15051">
			<img id="KS_20_PCtim-mossholder-WE_Kv_ZB1l0-uns" src="./Themes/KnowledgeSpace/tim-mossholder-WE_Kv_ZB1l0-uns.png" srcset="./Themes/KnowledgeSpace/tim-mossholder-WE_Kv_ZB1l0-uns.png 1x" />
				
			<div id="KS_20_PCGroup_3_fo">
				<div id="KS_20_PCVision">
					<span>Vision</span>
				</div>
				<div id="KS_20_PCScroll_Group_64">
					<div id="KS_20_PCLorem_ipsum_dolor_sit_amet_con_fr">
						<span>{props.data.vision}</span>
					</div>
				</div>
			</div>
		</div>
		<div id="KS_20_PCGroup_15052">
			<img id="KS_20_PCnguyen-khanh-ly-liiusuaISP0-un" src="./Themes/KnowledgeSpace/nguyen-khanh-ly-liiusuaISP0-un.png" srcset="./Themes/KnowledgeSpace/nguyen-khanh-ly-liiusuaISP0-un.png 1x" />
				
			<div id="KS_20_PCGroup_3_fu">
				<div id="KS_20_PCPrinciples">
					<span>Principles</span>
				</div>
				<div id="KS_20_PCScroll_Group_63">
					<div id="KS_20_PCLorem_ipsum_dolor_sit_amet_con_fx">
						<span>{props.data.principles}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div id="KS_20_PCContact">
		<span>Contact</span>
	</div>
</div>
  )
}

export default KnowledgeSpace