import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioBigReqCardMob.css';
import { UserContext } from '../UserContext';

const MyPortfolioBigReqCardMob = (props) => {
	
	const {user} = useContext(UserContext);
	 
	const handleLike = (reqId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/requirement/updatetrophy/"+reqId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
		  };
  return (

	<div id="REQM_big_1_trending_off">
	<svg class="REQM_bdr_all_trending_off">
		<rect id="REQM_bdr_all_trending_off" rx="10" ry="10" x="0" y="0" width="339" height="427">
		</rect>
	</svg>
	<svg class="REQM_bdr_amount_trending_off">
		<rect id="REQM_bdr_amount_trending_off" rx="10.5" ry="10.5" x="0" y="0" width="43" height="21">
		</rect>
	</svg>
	<div id="REQM_n_9_trending_off">
		<span>{props.data.currency && JSON.parse(props.data.currency).symbol} {props.data.budget}</span>
	</div>
	<img id="REQM_Image_trending__off" src={props.data.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultRequirementsImageUrl.split('/')[5]):"./MyPortfolio/Requirements/Image_trending__off_PC.png"}  />
		
	<div id="REQM_Nike_Air_Force_1_Sha_trending_">
		<span>{props.data.requirementTitle}</span>
	</div>
	<div id="REQM_Single_Tour_trending_off">
		<span>{( props.data.deliveryDeadline ? (props.data.deliveryDeadline).substring(0,10):"")} | {props.data.deliveryLocation}</span>
	</div>
	<div id="REQM_Group_125">
		<svg class="REQM_bdr_1_trending_off_PC">
			<ellipse id="REQM_bdr_1_trending_off_PC" rx="12" ry="11.5" cx="12" cy="11.5">
			</ellipse>
		</svg>
		<img id="REQM_trophy_trending_off_PC" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(props.data._id)} />
			
	</div>
	<div id="REQM_Group_124">
		<svg class="REQM_likes_trending_off_PC">
			<rect id="REQM_likes_trending_off_PC" rx="0" ry="0" x="0" y="0" width="24" height="13">
			</rect>
		</svg>
		<div id="REQM_n_234_like_trending_off_PC">
			<span>{(JSON.parse(props.data.trophies)).length}</span>
		</div>
	</div>
	<div id="REQM_Group_133">
					<svg class="REQM_share_bdr_small_4_trending_com">
						<ellipse id="REQM_share_bdr_small_4_trending_com" rx="12.5" ry="12.5" cx="12.5" cy="12.5">
						</ellipse>
					</svg>
					<img id="REQM_share_2_com_of" src="./MyPortfolio/Requirements/share_1_reqoffevent_off_PC_dd.png" srcset="./MyPortfolio/Requirements/share_1_reqoffevent_off_PC_dd.png 1x" />
						
				</div>
</div>
  )
}

export default MyPortfolioBigReqCardMob
