import React, { useState, useEffect, useContext } from "react";
import "../../styles/EditGeneralTabMob.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";

const EditGeneralTabMob = (props) => {
//useState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [mobile, setMobile] = useState("");
const [dob, setDob] = useState(new Date());
const [gender, setGender] = useState("");
const [profession,setProfession] = useState("");
const [principles,setPrinciples] = useState("");
const [mission,setMission] = useState("");
const [vision,setVision] = useState("");
const [introduction,setIntroduction] = useState("");

const [address,setAddress]= useState("");
const [country,setCountry]= useState("");
const [state,setState]= useState("");
const [city,setCity]= useState("");
const [pincode,setPincode]= useState("");
const [whatsAppNumber,setWhatsAppNumber] = useState("");
    const [linkedInUrl,setLinkedInUrl] = useState("");
    const [instagramUrl,setInstagramUrl] = useState("");
    const [mapLatitude,setMapLatitude] = useState("");
    const [mapLongitude,setMapLongitude] = useState("");

    useEffect(()=>{
        if(user){
            if(props.userData && props.userData.name){
         //console.log(props.userData)
         setDob(props.userData.dob && props.userData.dob.substring(0,10));
         setEmail(props.userData.email);
         setName(props.userData.name);
         setGender(props.userData.gender);
         setMobile(props.userData.phone);
         setProfession(props.userData.profession);
         setIntroduction(props.userData.introduction);
         setVision(props.userData.vision);
         setMission(props.userData.mission);
         setPrinciples(props.userData.principles);


         setAddress(props.userData.address);
         setCountry(props.userData.country);
         setState(props.userData.state);
         setCity(props.userData.city);
         setPincode(props.userData.pincode);
         setWhatsAppNumber(props.userData.whatsAppNumber);
         setLinkedInUrl(props.userData.linkedInUrl);
         setInstagramUrl(props.userData.instagramUrl);
         setMapLatitude(props.userData.mapLatitude);
         setMapLongitude(props.userData.mapLongitude);

            }
        }
    },[props.userData])


    const handleLocationUpdateData = () =>{
      if(name === undefined || name.length === ""){
        return alert("Please Enter the Name !!")
      }
      if(mobile === undefined||mobile.length === ""){
        return alert("Please Enter the Mobile No !!")
      }
      if(email === undefined||email.length === ""){
        return alert("Please Enter the Email Address !!")
      }
      if(dob === undefined|| dob.length === ""){
        return alert("Please Enter DOB !!")
      }
        props.updateData({
            name:name,
            email:email,
            mobile:mobile,
            dob:dob,
            gender:gender,
            profession:profession,
            principles:principles,
            mission:mission,
            vision:vision,
            introduction:introduction,
        })
        props.selectedTab("location")
    }
    
    const handleBillingUpdateData = () =>{
      if(name === undefined || name.length === (""||0)){
        return alert("Please Enter the Name !!")
      }
      if(mobile === undefined||mobile.length === (""||0)){
        return alert("Please Enter the Mobile No !!")
      }
      if(email === undefined||email.length === (""||0)){
        return alert("Please Enter the Email Address !!")
      }
      if(dob === undefined|| dob.length === (""||0)){
        return alert("Please Enter DOB !!")
      }
        props.updateData({
            name:name,
            email:email,
            mobile:mobile,
            dob:dob,
            gender:gender,
            profession:profession,
            principles:principles,
            mission:mission,
            vision:vision,
            introduction:introduction,

            address:address,
            country:country,
            state:state,
            city:city,
            pincode:pincode,
            whatsAppNumber:whatsAppNumber,
            linkedInUrl:linkedInUrl,
            instagramUrl:instagramUrl,
            mapLatitude:mapLatitude,
            mapLongitude:mapLongitude,

        })
        props.selectedTab("billing")
    }

  return (
    <div>
   <div id="general_tab">
	<img id="gen_down_holder_edit_page" src="gen_down_holder_edit_page.png" srcset="gen_down_holder_edit_page.png 1x" />
		
	<img id="gen_holder_mob_eit_page" src="gen_holder_mob_eit_page.png" srcset="gen_holder_mob_eit_page.png 1x" />
		
	<div id="top_grp_heading_general">
		<div id="Generallbl_profile_mob" >
			<span>General</span>
		</div>
		<img id="dossier_lbl_profile_mob" src="dossier_lbl_profile_mob.png" srcset="dossier_lbl_profile_mob.png 1x" />
			
		<div id="Location_lbl_profile_mob" onClick={()=>handleLocationUpdateData()}>
			<span>Location</span>
		</div>
		<img id="placeholder_lbl_profile_mob" src="placeholder_lbl_profile_mob.png" srcset="placeholder_lbl_profile_mob.png 1x" onClick={()=>props.selectedTab("location")}/>
			
		<div id="Payment_Details_lbl_profile_mo" onClick={()=>handleBillingUpdateData()}>
			<span>Business</span>
		</div>
		<img id="card_lbl_profile_mob" src="card_lbl_profile_mob.png" srcset="card_lbl_profile_mob.png 1x" onClick={()=>props.selectedTab("billing")}/>
			
	</div>
	<div id="general_page_scroll_mob">
		<input class="name_input_lbl_profile_mob"  value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}/>
			
		<div id="Name_lbl_profile_mob">
			<span>Name</span>
		</div>
		<input class="number_lbl_profile_mobinput" type="number" value={mobile}
                              onChange={(e) => {
                                setMobile(e.target.value);
                              }} />
			
		<div id="Mobile_Number_lbl_profile_mob">
			<span>Mobile Number</span>
		</div>
		<input class="email_input_lbl_profile_mob"  value={email || ""}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}/>
			
		<div id="Email_lbl_profile_mob">
			<span>Email</span>
		</div>
		<input type="Date" class="DDOB_lbl_profile_mob"  value={dob}
                                // max={new Date().getFullYear()+"-"+(new Date().getMonth()+1).toString().padStart(2, '0')+"-"+new Date().getDay().toString().padStart(2, '0')}
                                onChange={(value) => {
                                  setDob(value.target.value);
                                }}/>
			
		<div id="DOB_lbl_profile_mob">
			<span>DOB</span>
		</div>
		<input class="profession_input_lbl_profile_m"  value={profession}
                              onChange={(e) => {
                                setProfession(e.target.value);
                              }}/>
			
		<div id="Profession_lbl_profile_mob">
			<span>Profession</span>
		</div>
		<textarea class="introduction_input_lbl_profile" value={introduction || ""}
                              onChange={(e) => {
                                setIntroduction(e.target.value);
                              }}/>
			
		<div id="Introduction_lbl_profile_mob">
			<span>Introduction</span>
		</div>
		<textarea class="Vision_bdr_bdr_input_mob" value={vision}
                              onChange={(e) => {
                                setVision(e.target.value);
                              }} />
			
		<div id="Vision_lbl_profile_mob">
			<span>Vision</span>
		</div>
		<textarea class="mission_bdr_input_lbl_profile_" value={mission}
                              onChange={(e) => {
                                setMission(e.target.value);
                              }}/>
			
		<div id="Mission_lbl_profile_mob">
			<span>Mission</span>
		</div>
		<textarea class="principle_bdr_lbl_profile_mob"  value={principles}
                              onChange={(e) => {
                                setPrinciples(e.target.value);
                              }}/>
			
		<div id="Principles_lbl_profile_mob">
			<span>Principles</span>
		</div>
		<div id="Gender_lbl_profile_mob">
			<span>Gender</span>
		</div>
		<div id="Group_gender_mob">
			<div title="Male" id="men_btn_grp" value={gender}
                                onClick={() => {
                                  setGender("male");
                                }}>
				<svg class="btn_bdr">
					<ellipse id="btn_bdr" rx="20" ry="20" cx="20" cy="20" style={{fill:gender === "male" ?"#a3e9c8 ":"white"}}>
					</ellipse>
				</svg>
				<img id="man_btn_img" src="man_btn_img.png" srcset="man_btn_img.png 1x" />
					
			</div>
			<div title="Female" id="women_btn_grp" value={gender}
                                onClick={() => {
                                  setGender("female");
                                }}>
				<svg class="btn_bdr_">
					<ellipse id="btn_bdr_" rx="20" ry="20" cx="20" cy="20" style={{fill:gender === "female" ?"#a3e9c8 ":"white"}}>
					</ellipse>
				</svg>
				<img id="woman_btn_" src="woman_btn_.png" srcset="woman_btn_.png 1x" />
					
			</div>
			<div title="Others" id="other_btn_gender" value={gender}
                                onClick={() => {
                                  setGender("others");
                                }}>
				<svg class="bdr_other_btn">
					<ellipse id="bdr_other_btn" rx="20" ry="20" cx="20" cy="20" style={{fill:gender === "others" ?"#a3e9c8 ":"white"}}>
					</ellipse>
				</svg>
				<img id="gender-neutral_btn_img" src="gender-neutral_btn_img.png" srcset="gender-neutral_btn_img.png 1x" />
					
			</div>
		</div>
	</div>
	<div id="add_btn_tax_authority_mob" onClick={()=>handleLocationUpdateData()}>
		<svg class="add_bg_Add_Tax_Authority">
			<rect id="add_bg_Add_Tax_Authority" rx="8" ry="8" x="0" y="0" width="109" height="36">
			</rect>
		</svg>
		<div id="Active_lbl_Add_Tax_Authority">
			<span>Next</span>
		</div>
	</div>
</div>
    </div>
  )
}

export default EditGeneralTabMob
