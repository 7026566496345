import React, { useEffect,useState } from 'react'
import CalenderAppointmentCard from './CalenderAppointmentCard';
import '../../styles/CalanderDashboard.css';

const HourCard = (props) => {
  const [imagePosition,setImagePosition] = useState(0);

useEffect(()=>{

//console.log(props.scrollPosition)

},[props.hour,props.data])


// const handleScroll =()=>{

// 	const scroller = document.querySelector("#"+"add_btn_"+parseInt(props.hour>12?props.hour-12:props.hour).toString()+"_"+(props.hour>11?"pm":"am"));

	
// 	scroller&& scroller.addEventListener("scroll", (event) => {
// 	 setImagePosition(scroller.scrollTop)
// 	 console.log(scroller)
// 	});

// 	}


  return (
    <div id="n_2am_number_calender_pc">
    <div id="n_2_am_lbl">
        <span>{props.hour>12?props.hour-12:props.hour} {props.hour>11?"pm":"am"}</span>
    </div>
    <svg class="Line_12am" viewBox="0 0 280 1">
        <path id="Line_12am" d="M 0 0 L 280 0">
        </path>
    </svg>
    <div >
    
        <CalenderAppointmentCard data={(props.data||[])}  />
    </div>

    <img id={"add_btn_"+parseInt(props.hour>12?props.hour-12:props.hour).toString()+"_"+(props.hour>11?"pm":"am")}  src="./CalanderDashboard/add_btn_12_am.png" srcset="./CalanderDashboard/add_btn_12_am.png 1x" onClick={()=>{props.openCalanderEntryPopUp();}} />
</div>
  )
}

export default HourCard
