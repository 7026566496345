import React, { useState, useEffect, useContext } from "react";
// import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider } from "@mui/material";
import "../styles/signUpMobUI.css";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
// import EventIcon from '@mui/icons-material/Event';
// import CheckIcon from '@mui/icons-material/Check';
// import ScheduleInterview from './ScheduleInterview';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// // import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
// import DatePicker from '@mui/lab/DatePicker';
// import TimePicker from '@mui/lab/TimePicker';
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
// import { id } from 'date-fns/locale';
import { UserContext } from "./UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { autocompleteClasses } from "@material-ui/core";
import countries from "./Countries.json";
import Currencies from "./Currencies.json";

const SignUpMobUI = (props) => {
  const style6 = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 400,
    maxWidth: 550,
    margin: "auto",
    scrollY: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
  };

  const label = { inputProps: { "aria-label": "Schedule Interview" } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule] = React.useState();
  const [searchParams] = useSearchParams();
  var navigate = useNavigate();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState(new Date().toISOString().substring(0,10));
  const [whatsNumber, setWhatsNumber] = useState("");
  const [gender, setGender] = useState("male");
  const [open, setOpen] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportingToId, setReportingToId] = useState("");
  const [reportingToName, setReportingToName] = useState("");
  const [accessType, setAccessType] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [hourlyRate,setHourlyRate] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [active, setActive] = useState(true);
  const [confirmPass, setConfirmPass] = useState("");
  const [password, setPassword] = useState("");
  const [profession,setProfession] = useState("");
  const [pincode, setPincode] = useState("");
  const [img, setImg] = useState("");
  const [image,setImage] = useState("");
  const [introduction,setIntroduction] = useState("");
  const { user } = useContext(UserContext);
  const [nickName,setNickName] = useState("")

  // const [filterModel, setFilterModel] = React.useState({
  //   items: [
  //     {columnField: '', operatorValue: 'contains', value:user.name}
  //   ],
  // });

  const cols = [
    { field: "id", headerName: "id", hide: true },
    { field: "name", headerName: "Name", width: 190 },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      renderCell: (params) => {
        return [
          params.row.gender
            ? params.row.gender.substring(0, 1).toUpperCase() +
              params.row.gender.substring(1).toLowerCase()
            : "",
        ];
      },
    },
    { field: "reportingToName", headerName: "Reporting To", width: 190 },
    {
      field: "accesstype",
      headerName: "AccessType",
      width: 100,
      renderCell: (params) => {
        switch (params.row.accesstype) {
          case "0":
            return ["Guest"];

          case "1":
            return ["Employee"];

          case "2":
            return ["Supervisor"];

          case "3":
            return ["Manager"];

          case "4":
            return ["Director"];

          case "5":
            return ["Admin"];

          default:
            return [""];
        }
      },
    },
    { field: "mobile", headerName: "Mobile No", width: 140 },

    { field: "email", headerName: "Email", width: 200 },
    {
      field: "active",
      headerName: "Active",
      width: 100,
      renderCell: (params) => {
        return [<input type="checkbox" checked={params.row.active} />];
      },
    },
  ];

  useEffect(() => {
    addEmployee();
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getEmployees = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/getallusers";
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          console.log(repos.data);
          setEmployees(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const UpdateEmployeesDetails = (id) => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/updateEmployees/" + id;
    let apiParams = {
      method: "POST",
      headers: {
        name: name,
        gender: gender,
        reportingtoid: reportingToId,
        reportingtoname: reportingToName,
        accesstype: accessType,
        mobile: mobile,
        email: email,
        password: encodeURIComponent(password),
        active: active,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);

          props.showAlert("User Updated Successfully", "success");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch((err) => alert(err));
  };

  const addEmployeedetails = () => {
  
    if(name===undefined||name===""){
      alert("Please Enter Name!!");
    return;
    }
    if(nickName===undefined||nickName===""){
      alert("Please Enter Nick Name!!");
    return;
    }
    if(email===undefined||email===""){
      alert("Please Enter Email Address!!");
    return;
    }
    if(mobile===undefined||mobile===""){
      alert("Please Enter Mobile Number!!");
    return;
    }

    if(password===undefined||password===""){
      alert("Password cannot be empty!!");
    return;
    }

    if(confirmPass===undefined||confirmPass===""){
      alert("Confirm Password cannot be empty!!");
    return;
    }

    if (password !== confirmPass) {
      alert("Password and Confirm Password don't match!!");
      return;
    }
   
    
   
    let apiURL = process.env.REACT_APP_API_URL + "/users/addEmployee";
    let apiParams = {
      method: "POST",
      headers: {
        name: name,
        nickname:nickName,
        // gender: gender,
        // dob: dob,
        mobile: mobile,
        email: email,
        // country: country,
        // state: state,
        // city: city,
        // address: encodeURIComponent(address),
        // pincode: pincode,
        // profession:profession,
        // introduction:encodeURIComponent(introduction),
        // currency:currency,
        // hourlyrate:hourlyRate,
        password: encodeURIComponent(password),
        // active: active,
      },
      //body:image,
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);
          alert("User Added Successfully!!");
          image && UploadImageToDrive(repos.data._id,image)
          setTimeout(() => {
               navigate("/login")
              }, 2000);
        }
      })
      .catch((err) => alert(err));
  };

  const addEmployee = () => {
    setOpen(true);
  };

  // const editEmployees = (row) =>{
  //   console.log(row)
  //   setId(row.id);
  //   setName(row.name)
  //   setGender(row.gender)
  //   setReportingToId(row.reportingToId)
  //   setReportingToName(row.reportingToName)
  //   setAccessType(row.accesstype)
  //   setMobile(row.mobile)
  //   setEmail(row.email)
  //   setActive(row.active)
  //   setOpen(true);
  // }

  const handleClose = () => {
    setOpen(false);
    navigate("/login");
  };

  const resetApplicant = () => {
    setId("");
    setName("");
  //  setLastName("");
    setEmail("");
    setMobile("");
    setGender("");
    setReportingToId("");
    setReportingToName("");
    setAccessType("");
    // setActive(false)
  };

  // const rowData = (employees)?.map((employee) => {

  // return{
  //   id:employee?._id,
  //   name:employee?.name,
  //   reportingToId:employee?.reportingToId,
  //   reportingToName:employee?.reportingToName,
  //   email:employee?.email,
  //   mobile:employee?.phone,
  //   gender:employee?.gender,
  //   accesstype:employee?.accesstype,
  //   active:employee?.active,
  // };
  // });

  const handleImageUpload = async (e) => {
    // if (e.target.files[0] && e.target.files[0].size > 71680) {
    //   alert("Please choose a file with size below 70kb!");
    //   return;
    // }

    if(e.target.files[0]){
      handleFileSelect(e.target.files[0]);

      // let formdata = new FormData();
      // formdata.append("image1", e.target.files[0], e.target.value);
      setImage(e.target.files[0]);
    }
  };

  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

   //Upload Image
   const UploadImageToDrive = async (id,document) =>{
     
    var file = document //the file
    var reader = new FileReader() //this for convert to Base64 
    reader.readAsDataURL(document) //start conversion...
    reader.onload = function (e) { //.. once finished..
      var rawLog = reader.result.split(',')[1]; //extract only thee file data part
      var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
     fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
        { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
        .then(res => res.json()).then((data) => {
          //See response
        
           SaveProfilePic(data,id)
          // alert("Image Uploaded Successfully","success")
  
        }).catch(err => console.log(err)) // Or Error in console
    }
  }


  const SaveProfilePic = (data,id)=>{
      
    const apiurl = process.env.REACT_APP_API_URL + "/users/updateprofilepicnew/"+id;
            let apiParams = {
                method:"POST",
                headers:{
                  imageurl:data.url,
                
                }
          }
          fetch(apiurl, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
               
                alert("Image Uploaded Successfully");
              }
            })
            .catch(e => console.log(e)) 
          }

  return (
   <div>
<div id="sign_up_mob">
	<img id="over_all_bg_design" src="over_all_bg_design.png" srcset="over_all_bg_design.png 1x" />
		
	<svg class="design_circle_op_sign_up_mon">
		<ellipse id="design_circle_op_sign_up_mon" rx="236" ry="103.62948608398438" cx="236" cy="103.62948608398438">
		</ellipse>
	</svg>
	<svg class="op_bg_design_op_sign_up_mon">
		<rect id="op_bg_design_op_sign_up_mon" rx="65" ry="65" x="0" y="0" width="349" height="316">
		</rect>
	</svg>
	<svg class="Rectangle_2_op_sign_up_mon">
		<rect id="Rectangle_2_op_sign_up_mon" rx="36" ry="36" x="0" y="0" width="349" height="643">
		</rect>
	</svg>
	<img id="image_op_sign_up_mon" src="image_op_sign_up_mon.png" srcset="image_op_sign_up_mon.png 1x" />
		
	<div id="OPERATE_LIVE_op_sign_up_mon">
		<span>OPERATE </span><span style={{color:"rgba(229,14,16,1)"}}>LIVE</span>
	</div>
	<input placeholder="Name" class="txtFirst_Name_mob" value={name || ""} onChange={(e) => {setName(e.target.value)}}/>
		
  <input placeholder="Trade/Nick Name" class="txtNickName_mob" value={nickName || ""} onChange={(e) => {setNickName(e.target.value.replace(" ","").replace(".","").toLowerCase())}}/>

  <label class="nickName_lbl_mob">{nickName.length > 0 ? "Your Website URL will be ":""}<font size="4" color="red" >{nickName.length > 0 ? nickName+".operate.live":""}</font></label>

	<input placeholder="Email" class="txtEmail_mob"   value={email || ""} onChange={(e) => {setEmail(e.target.value)}}/>
		
	<input placeholder="Mobile No" type="number" class="txtMobile_No_mob" value={mobile}  onChange={(e)=>setMobile(e.target.value)}/>
		
	<input placeholder="Set Password" class="txtEnter_Password_mob" type="password" value={password} onChange={(e) => {setPassword(e.target.value) }}/>
		
	<input placeholder="Confirm Password" type="password" class="txtConfirm_Password_mob" value={confirmPass} onChange={(e) => {setConfirmPass(e.target.value)}}/>
		
  <label for="imageSignupMob">
    <input id="imageSignupMob" type="file" name="imageSignupMob"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
    <img id="profile_op_sign_up_mon" width="100" height="100" src={img?img:"user_pfp_profile_page.png"} style={{borderRadius:"75px"}}/>
    </label>
	{/* <img id="profile_op_sign_up_mon" src="profile_op_sign_up_mon.png" srcset="profile_op_sign_up_mon.png 1x" /> */}
		
	<img onClick={()=>navigate('/')} id="back_btn_sign_up_mob" src="back_btn_sign_up.png" srcset="back_btn_sign_up.png 1x" />
		
    <div onClick={()=>addEmployeedetails()}>
	<svg class="sign_up_bdt">
		<rect id="sign_up_bdt" rx="20.98382568359375" ry="20.98382568359375" x="0" y="0" width="134.879" height="41.968">
		</rect>
	</svg>
	<div id="Sign_Up_btn_lbl">
		<span>Sign Up</span>
	</div>
	</div>
</div>
   </div>
  );
};

export default SignUpMobUI;
