import React from 'react';
import "../../../styles/GamersHub.css";

function GamersHub(props) {
  return (
    <div id="GamersHub">
	<div id="GamersHub_16_pcGroup_14979">
		<div id="GamersHub_16_pcGroup_14978">
			<div id="GamersHub_16_pcGroup_14972">
				<div id="GamersHub_16_pcScroll_Group_51">
					<div id="GamersHub_16_pcElevate_Your_Space_with_Timele">
						<span>{props.data.vision}</span>
					</div>
				</div>
				<div id="GamersHub_16_pcVision">
					<span>Vision</span>
				</div>
				<svg class="GamersHub_16_pcPath_6955" viewBox="3.06 146.411 358.421 124.099">
					<path id="GamersHub_16_pcPath_6955" d="M 3.1728515625 259.423095703125 L 33.2294921875 153.624755859375 C 33.2294921875 153.624755859375 37.43701171875 146.4112548828125 45.85302734375 146.4112548828125 C 54.26904296875 146.4112548828125 319.3662109375 146.4112548828125 319.3662109375 146.4112548828125 C 319.3662109375 146.4112548828125 333.19189453125 149.4168701171875 333.19189453125 153.624755859375 C 333.19189453125 157.8326416015625 361.44482421875 259.423095703125 361.44482421875 259.423095703125 C 361.44482421875 259.423095703125 362.64697265625 269.642333984375 351.2255859375 270.243408203125 C 339.80419921875 270.844482421875 12.18994140625 270.243408203125 12.18994140625 270.243408203125 C 12.18994140625 270.243408203125 1.87646484375 266.661865234375 3.1728515625 260.559326171875 C 4.46923828125 254.456787109375 3.1728515625 259.423095703125 3.1728515625 259.423095703125 Z">
					</path>
				</svg>
				<img id="GamersHub_16_pckisspng-twisted-metal-black-pl" src="./Themes/GamersHub/kisspng-twisted-metal-black-pl.png" srcset="./Themes/GamersHub/kisspng-twisted-metal-black-pl.png 1x" />
					
			</div>
		</div>
		<div id="GamersHub_16_pcGroup_14974">
			<div id="GamersHub_16_pcGroup_14973">
				<div id="GamersHub_16_pcScroll_Group_51_c">
					<div id="GamersHub_16_pcElevate_Your_Space_with_Timele_c">
						<span>{props.data.mission}</span>
					</div>
				</div>
				<div id="GamersHub_16_pcMission">
					<span>Mission</span>
				</div>
			</div>
			<svg class="GamersHub_16_pcPath_6957" viewBox="3.06 146.411 358.421 124.099">
				<path id="GamersHub_16_pcPath_6957" d="M 3.1728515625 259.423095703125 L 33.2294921875 153.624755859375 C 33.2294921875 153.624755859375 37.43701171875 146.4112548828125 45.85302734375 146.4112548828125 C 54.26904296875 146.4112548828125 319.3662109375 146.4112548828125 319.3662109375 146.4112548828125 C 319.3662109375 146.4112548828125 333.19189453125 149.4168701171875 333.19189453125 153.624755859375 C 333.19189453125 157.8326416015625 361.44482421875 259.423095703125 361.44482421875 259.423095703125 C 361.44482421875 259.423095703125 362.64697265625 269.642333984375 351.2255859375 270.243408203125 C 339.80419921875 270.844482421875 12.18994140625 270.243408203125 12.18994140625 270.243408203125 C 12.18994140625 270.243408203125 1.87646484375 266.661865234375 3.1728515625 260.559326171875 C 4.46923828125 254.456787109375 3.1728515625 259.423095703125 3.1728515625 259.423095703125 Z">
				</path>
			</svg>
			<img id="GamersHub_16_pckisspng-computer-keyboard-cors" src="./Themes/GamersHub/kisspng-computer-keyboard-cors.png" srcset="./Themes/GamersHub/kisspng-computer-keyboard-cors.png 1x" />
				
		</div>
		<div id="GamersHub_16_pcGroup_14975">
			<div id="GamersHub_16_pcScroll_Group_51_dd">
				<div id="GamersHub_16_pcElevate_Your_Space_with_Timele_de">
					<span>{props.data.principles}</span>
				</div>
			</div>
			<div id="GamersHub_16_pcPrinciples">
				<span>Principles</span>
			</div>
			<svg class="GamersHub_16_pcPath_6956" viewBox="3.06 146.411 358.421 124.099">
				<path id="GamersHub_16_pcPath_6956" d="M 3.1728515625 259.423095703125 L 33.2294921875 153.624755859375 C 33.2294921875 153.624755859375 37.43701171875 146.4112548828125 45.85302734375 146.4112548828125 C 54.26904296875 146.4112548828125 319.3662109375 146.4112548828125 319.3662109375 146.4112548828125 C 319.3662109375 146.4112548828125 333.19189453125 149.4168701171875 333.19189453125 153.624755859375 C 333.19189453125 157.8326416015625 361.44482421875 259.423095703125 361.44482421875 259.423095703125 C 361.44482421875 259.423095703125 362.64697265625 269.642333984375 351.2255859375 270.243408203125 C 339.80419921875 270.844482421875 12.18994140625 270.243408203125 12.18994140625 270.243408203125 C 12.18994140625 270.243408203125 1.87646484375 266.661865234375 3.1728515625 260.559326171875 C 4.46923828125 254.456787109375 3.1728515625 259.423095703125 3.1728515625 259.423095703125 Z">
				</path>
			</svg>
			<img id="GamersHub_16_pcMy_project" src="./Themes/GamersHub/My_project.png" srcset="./Themes/GamersHub/My_project.png 1x" />
				
			<svg class="GamersHub_16_pcEllipse_63">
				<ellipse id="GamersHub_16_pcEllipse_63" rx="147.5" ry="6" cx="147.5" cy="6">
				</ellipse>
			</svg>
		</div>
	</div>
	<div id="GamersHub_16_pcGroup_14984">
		<svg class="GamersHub_16_pcprinci_design_img_website_7_pc" viewBox="0 -0.553 175.359 76.1">
			<path id="GamersHub_16_pcprinci_design_img_website_7_pc" d="M 52.06050109863281 75.54600524902344 L 99.03150177001953 0 L 120.1688995361328 0 L 73.19699859619141 75.54600524902344 L 52.06050109863281 75.54600524902344 Z M 0 75.54600524902344 L 46.97190093994141 0 L 91.59480285644531 0 L 44.62290191650391 75.54600524902344 L 0 75.54600524902344 Z M 83.76390075683594 74.99250030517578 L 130.7358093261719 -0.5534999966621399 L 175.3587036132812 -0.5534999966621399 L 128.3868103027344 74.99250030517578 L 83.76390075683594 74.99250030517578 Z">
			</path>
		</svg>
		<div id="GamersHub_16_pcOur_Services_lbl_website_6_pc">
			<span>Our</span><span style={{color:"rgba(45,255,45,0.89)"}}> </span><span style={{color:"rgba(0,0,0,0.89)"}}>Clans</span>
		</div>
	</div>
	<div id="GamersHub_16_pcGroup_14985">
		<svg class="GamersHub_16_pcLine_28" viewBox="0 0 1269 2">
			<path id="GamersHub_16_pcLine_28" d="M 0 0 L 1269 0">
			</path>
		</svg>
		<div id="GamersHub_16_pcsocials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
			<div title="LinkedIn" id="GamersHub_16_pclinked_in_grp_shadow_space_web">
				<svg class="GamersHub_16_pcmed_shadow_space_website_7">
					<ellipse id="GamersHub_16_pcmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<div id="GamersHub_16_pclinked_in_shadow_space_website">
				<a href={props.data.linkedInUrl} target="_blank">
					<svg class="GamersHub_16_pcn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
						<path id="GamersHub_16_pcn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
						</path>
					</svg>
				</a>
					<svg class="GamersHub_16_pclinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
						<path id="GamersHub_16_pclinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
						</path>
					</svg>
					<svg class="GamersHub_16_pctop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
						<path id="GamersHub_16_pctop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
						</path>
					</svg>
				</div>
			</div>:""}
			{props.data.instagramUrl?	
			<div title="Instagram" id="GamersHub_16_pcInsta_grp_shadow_space_website">
				<svg class="GamersHub_16_pcshadow_space_website_7">
					<ellipse id="GamersHub_16_pcshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<a href={props.data.instagramUrl} target="_blank">
				<img id="GamersHub_16_pcinstagramshadow_space_website_" src="./Themes/GamersHub/instagramshadow_space_website_.png" srcset="./Themes/GamersHub/instagramshadow_space_website_.png 1x" />
					
				</a>
			</div>:""}
			{props.data.whatsAppNumber?
			<div title="WhatsApp" id="GamersHub_16_pcwhatsapp_grp_shadow_space_webs">
				<svg class="GamersHub_16_pcmed_shadow_space_website_7_d">
					<ellipse id="GamersHub_16_pcmed_shadow_space_website_7_d" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="GamersHub_16_pcwhatsapp_lbl_shadow_space_webs" src="./Themes/GamersHub/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/GamersHub/whatsapp_lbl_shadow_space_webs.png 1x" />
				</a>	
			</div>:""}
		</div>
		<div id="GamersHub_16_pcn_234567890_mobile_number_shad">
			<span>{props.data.businessPhone}</span>
		</div>
		<div id="GamersHub_16_pcaffanghanioperatelive_email_sh">
			<span>{props.data.businessEmail}</span>
		</div>
		<div id="GamersHub_16_pcaddress_med_shadow_space_websi">
			<span>{props.data.businessAddress}</span>
		</div>
		<img id="GamersHub_16_pclocation_img_shadow_space_webs" src="./Themes/GamersHub/location_img_shadow_space_webs.png" srcset="./Themes/GamersHub/location_img_shadow_space_webs.png 1x" />
			
		<img id="GamersHub_16_pcemail_address_shadow_space_web" src="./Themes/GamersHub/email_address_shadow_space_web.png" srcset="./Themes/GamersHub/email_address_shadow_space_web.png 1x" />
			
		<img id="GamersHub_16_pcphone_number_shadow_space_webs" src="./Themes/GamersHub/phone_number_shadow_space_webs.png" srcset="./Themes/GamersHub/phone_number_shadow_space_webs.png 1x" />
			
		<div id="GamersHub_16_pcn_Copyright_Operate_Live_OPC_P">
			<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
		</div>
		<div id="GamersHub_16_pcGroup_14976">
			<img id="GamersHub_16_pcMy_project_ec" src="./Themes/GamersHub/My_project_ec.png" srcset="./Themes/GamersHub/My_project_ec.png 1x" />
				
			<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="GamersHub_16_pcRectangle_717">
				<rect id="GamersHub_16_pcRectangle_717" rx="5" ry="5" x="0" y="0" width="357.517" height="199.755">
				</rect>
			</iframe>
		</div>
	</div>
	<div id="GamersHub_16_pcScroll_Group_55">
	{props.offeringsData.map((off,idx)=>(
		<div id="GamersHub_16_pcGroup_14977">
			<svg class="GamersHub_16_pcPath_448" viewBox="4578.628 19892.477 323.11 195.998">
				<path id="GamersHub_16_pcPath_448" d="M 4578.6279296875 19893.13671875 L 4901.73779296875 19892.4765625 L 4900.9365234375 20080.05078125 L 4762.78173828125 20080.28125 L 4762.78173828125 20081.431640625 L 4760.4091796875 20081.431640625 L 4760.4091796875 20083.31640625 L 4814.71875 20083.31640625 L 4816.318359375 20088.474609375 L 4662.70361328125 20088.474609375 L 4664.38623046875 20083.31640625 L 4719.93701171875 20083.31640625 L 4719.93701171875 20081.431640625 L 4716.2373046875 20081.431640625 L 4716.2373046875 20080.28125 L 4578.6279296875 20080.05078125 L 4578.6279296875 19893.13671875 Z">
				</path>
			</svg>
			<img id="GamersHub_16_pcn_71177" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/GamersHub/n_71177.png"} />
				
			<div id="GamersHub_16_pcMinecraft">
				<span>{off.offeringTitle}</span>
			</div>
		</div>  ))}
	</div>
	<div id="GamersHub_16_pcGroup_14983">
		<img id="GamersHub_16_pcMask_Group_1" src="./Themes/GamersHub/Mask_Group_1.png" srcset="./Themes/GamersHub/Mask_Group_1.png 1x" />
        
		<div id="GamersHub_16_pcPLAY_GAME_EVERYDAY_ALWAYS_BE_W">
			<span>P</span><span style={{textTransform:"lowercase"}}>LAY </span><span>G</span><span style={{textTransform:"lowercase"}}>AME</span><span> E</span><span style={{textTransform:"lowercase"}}>VERYDAY</span><br/><span>A</span><span style={{textTransform:"lowercase"}}>LWAYS</span><span> B</span><span style={{textTransform:"lowercase"}}>E </span><span>W</span><span style={{textTransform:"lowercase"}}>INNER</span><span>.</span>
		</div>
		<div id="GamersHub_16_pcGroup_14969">
			<svg class="GamersHub_16_pcRectangle_718">
				<rect id="GamersHub_16_pcRectangle_718" rx="0" ry="0" x="0" y="0" width="197" height="244">
				</rect>
			</svg>
			<svg class="GamersHub_16_pcRectangle_713">
				<rect id="GamersHub_16_pcRectangle_713" rx="5" ry="5" x="0" y="0" width="35" height="35">
				</rect>
			</svg>
			<div id="GamersHub_16_pcConsole">
				<span>Console</span>
			</div>
			<svg class="GamersHub_16_pcLine_30" viewBox="0 0 2 351">
				<path id="GamersHub_16_pcLine_30" d="M 0 0 L 0 351">
				</path>
			</svg>
			<svg class="GamersHub_16_pcLine_31" viewBox="0 0 2 237">
				<path id="GamersHub_16_pcLine_31" d="M 2 0 L 0 237">
				</path>
			</svg>
			<img id="GamersHub_16_pcpexels-anthony--139038" src="./Themes/GamersHub/pexels-anthony--139038.png" srcset="./Themes/GamersHub/pexels-anthony--139038.png 1x" />
				
		</div>
		<div id="GamersHub_16_pcGroup_14968">
			<svg class="GamersHub_16_pcRectangle_719">
				<rect id="GamersHub_16_pcRectangle_719" rx="0" ry="0" x="0" y="0" width="200" height="273">
				</rect>
			</svg>
			<div id="GamersHub_16_pcSwitch">
				<span>Switch</span>
			</div>
			<svg class="GamersHub_16_pcRectangle_714">
				<rect id="GamersHub_16_pcRectangle_714" rx="5" ry="5" x="0" y="0" width="35" height="35">
				</rect>
			</svg>
			<svg class="GamersHub_16_pcLine_32" viewBox="0 0 2 351">
				<path id="GamersHub_16_pcLine_32" d="M 0 0 L 0 351">
				</path>
			</svg>
			<svg class="GamersHub_16_pcLine_33" viewBox="0 0 2 237">
				<path id="GamersHub_16_pcLine_33" d="M 2 0 L 0 237">
				</path>
			</svg>
			<img id="GamersHub_16_pcpexels-pixabay-371924" src="./Themes/GamersHub/pexels-pixabay-371924.png" srcset="./Themes/GamersHub/pexels-pixabay-371924.png 1x" />
				
		</div>
		<img id="GamersHub_16_pcefeb0ad6-be8c-4667-ab83-307500" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/GamersHub/efeb0ad6-be8c-4667-ab83-307500.png"} />
			
		<div id="GamersHub_16_pcCompany_Name">
			<span>{props.data.businessName}</span>
		</div>
		<div id="GamersHub_16_pcCreate_your_own_game_platform_">
			<span>Create your own game platform with us</span>
		</div>
		<svg class="GamersHub_16_pcLine_38" viewBox="0 0 2 86">
			<path id="GamersHub_16_pcLine_38" d="M 0 0 L 0 86">
			</path>
		</svg>
		<svg class="GamersHub_16_pcLine_39" viewBox="0 0 2 141">
			<path id="GamersHub_16_pcLine_39" d="M 0 0 L 0 141">
			</path>
		</svg>
		<img id="GamersHub_16_pcflorian-olivo-Mf23RF8xArY-unsp" src="./Themes/GamersHub/florian-olivo-Mf23RF8xArY-unsp.png" srcset="./Themes/GamersHub/florian-olivo-Mf23RF8xArY-unsp.png 1x" />
			
		<svg class="GamersHub_16_pcIcon_ionic-ios-arrow-back" viewBox="11.251 6.194 9.379 16.405">
			<path id="GamersHub_16_pcIcon_ionic-ios-arrow-back" d="M 17.8018913269043 14.39378547668457 L 11.59486961364746 8.191643714904785 C 11.13581275939941 7.732587814331055 11.13581275939941 6.990283966064453 11.59486961364746 6.536111831665039 C 12.05392646789551 6.077055931091309 12.79622840881348 6.081939220428467 13.25528526306152 6.536111831665039 L 20.28763198852539 13.56357669830322 C 20.73203659057617 14.00798225402832 20.74180603027344 14.72098541259766 20.32181930541992 15.18003940582275 L 13.26016807556152 22.2563419342041 C 13.0306396484375 22.48587036132812 12.72785758972168 22.59819221496582 12.42995929718018 22.59819221496582 C 12.1320629119873 22.59819221496582 11.82928085327148 22.48587036132812 11.59975242614746 22.2563419342041 C 11.14069557189941 21.79728507995605 11.14069557189941 21.05498313903809 11.59975242614746 20.60080909729004 L 17.8018913269043 14.39378547668457 Z">
			</path>
		</svg>
		<svg class="GamersHub_16_pcIcon_ionic-ios-arrow-back_e" viewBox="11.251 6.194 9.379 16.405">
			<path id="GamersHub_16_pcIcon_ionic-ios-arrow-back_e" d="M 17.8018913269043 14.39378547668457 L 11.59486961364746 8.191643714904785 C 11.13581275939941 7.732587814331055 11.13581275939941 6.990283966064453 11.59486961364746 6.536111831665039 C 12.05392646789551 6.077055931091309 12.79622840881348 6.081939220428467 13.25528526306152 6.536111831665039 L 20.28763198852539 13.56357669830322 C 20.73203659057617 14.00798225402832 20.74180603027344 14.72098541259766 20.32181930541992 15.18003940582275 L 13.26016807556152 22.2563419342041 C 13.0306396484375 22.48587036132812 12.72785758972168 22.59819221496582 12.42995929718018 22.59819221496582 C 12.1320629119873 22.59819221496582 11.82928085327148 22.48587036132812 11.59975242614746 22.2563419342041 C 11.14069557189941 21.79728507995605 11.14069557189941 21.05498313903809 11.59975242614746 20.60080909729004 L 17.8018913269043 14.39378547668457 Z">
			</path>
		</svg>
		<div id="GamersHub_16_pcGroup_14981">
			<div id="GamersHub_16_pcGroup_14970">
				<svg class="GamersHub_16_pcLine_34" viewBox="0 0 2 351">
					<path id="GamersHub_16_pcLine_34" d="M 0 0 L 0 351">
					</path>
				</svg>
				<svg class="GamersHub_16_pcLine_35" viewBox="0 0 2 237">
					<path id="GamersHub_16_pcLine_35" d="M 2 0 L 0 237">
					</path>
				</svg>
			</div>
			<svg class="GamersHub_16_pcRectangle_720">
				<rect id="GamersHub_16_pcRectangle_720" rx="0" ry="0" x="0" y="0" width="201" height="268">
				</rect>
			</svg>
			<img id="GamersHub_16_pcpexels-harsch-shivam-2007647" src="./Themes/GamersHub/pexels-harsch-shivam-2007647.png" srcset="./Themes/GamersHub/pexels-harsch-shivam-2007647.png 1x" />
				
			<div id="GamersHub_16_pcVR">
				<span>VR</span>
			</div>
			<svg class="GamersHub_16_pcRectangle_715">
				<rect id="GamersHub_16_pcRectangle_715" rx="5" ry="5" x="0" y="0" width="35" height="35">
				</rect>
			</svg>
			<svg class="GamersHub_16_pcIcon_ionic-ios-arrow-back_fg" viewBox="11.251 6.194 9.379 16.405">
				<path id="GamersHub_16_pcIcon_ionic-ios-arrow-back_fg" d="M 17.8018913269043 14.39378547668457 L 11.59486961364746 8.191643714904785 C 11.13581275939941 7.732587814331055 11.13581275939941 6.990283966064453 11.59486961364746 6.536111831665039 C 12.05392646789551 6.077055931091309 12.79622840881348 6.081939220428467 13.25528526306152 6.536111831665039 L 20.28763198852539 13.56357669830322 C 20.73203659057617 14.00798225402832 20.74180603027344 14.72098541259766 20.32181930541992 15.18003940582275 L 13.26016807556152 22.2563419342041 C 13.0306396484375 22.48587036132812 12.72785758972168 22.59819221496582 12.42995929718018 22.59819221496582 C 12.1320629119873 22.59819221496582 11.82928085327148 22.48587036132812 11.59975242614746 22.2563419342041 C 11.14069557189941 21.79728507995605 11.14069557189941 21.05498313903809 11.59975242614746 20.60080909729004 L 17.8018913269043 14.39378547668457 Z">
				</path>
			</svg>
		</div>
		<div id="GamersHub_16_pcGroup_14982">
			<div id="GamersHub_16_pcGroup_14971">
				<svg class="GamersHub_16_pcLine_36" viewBox="0 0 2 351">
					<path id="GamersHub_16_pcLine_36" d="M 0 0 L 0 351">
					</path>
				</svg>
				<svg class="GamersHub_16_pcLine_37" viewBox="0 0 2 237">
					<path id="GamersHub_16_pcLine_37" d="M 2 0 L 0 237">
					</path>
				</svg>
			</div>
			<svg class="GamersHub_16_pcRectangle_721">
				<rect id="GamersHub_16_pcRectangle_721" rx="0" ry="0" x="0" y="0" width="200" height="268">
				</rect>
			</svg>
			<img id="GamersHub_16_pcpexels-karol-d-841228" src="./Themes/GamersHub/pexels-karol-d-841228.png" srcset="./Themes/GamersHub/pexels-karol-d-841228.png 1x" />
				
			<div id="GamersHub_16_pcKeybaord">
				<span>Keybaord</span>
			</div>
			<svg class="GamersHub_16_pcRectangle_716">
				<rect id="GamersHub_16_pcRectangle_716" rx="5" ry="5" x="0" y="0" width="35" height="35">
				</rect>
			</svg>
			<svg class="GamersHub_16_pcIcon_ionic-ios-arrow-back_fp" viewBox="11.251 6.194 9.379 16.405">
				<path id="GamersHub_16_pcIcon_ionic-ios-arrow-back_fp" d="M 17.8018913269043 14.39378547668457 L 11.59486961364746 8.191643714904785 C 11.13581275939941 7.732587814331055 11.13581275939941 6.990283966064453 11.59486961364746 6.536111831665039 C 12.05392646789551 6.077055931091309 12.79622840881348 6.081939220428467 13.25528526306152 6.536111831665039 L 20.28763198852539 13.56357669830322 C 20.73203659057617 14.00798225402832 20.74180603027344 14.72098541259766 20.32181930541992 15.18003940582275 L 13.26016807556152 22.2563419342041 C 13.0306396484375 22.48587036132812 12.72785758972168 22.59819221496582 12.42995929718018 22.59819221496582 C 12.1320629119873 22.59819221496582 11.82928085327148 22.48587036132812 11.59975242614746 22.2563419342041 C 11.14069557189941 21.79728507995605 11.14069557189941 21.05498313903809 11.59975242614746 20.60080909729004 L 17.8018913269043 14.39378547668457 Z">
				</path>
			</svg>
		</div>
		<svg class="GamersHub_16_pcPath_6953" viewBox="0 0 2 86">
			<path id="GamersHub_16_pcPath_6953" d="M 0 0 L 0 34.265625 L 0 86">
			</path>
		</svg>
		<svg class="GamersHub_16_pcLine_41" viewBox="0 0 2 141">
			<path id="GamersHub_16_pcLine_41" d="M 0 0 L 0 141">
			</path>
		</svg>
		<div id="GamersHub_16_pcGroup_14980">
			<svg class="GamersHub_16_pcEllipse_2">
				<ellipse id="GamersHub_16_pcEllipse_2" rx="27" ry="27" cx="27" cy="27">
				</ellipse>
			</svg>
			<svg class="GamersHub_16_pcPolygon_1" viewBox="0 0 12.801 10.972">
				<path id="GamersHub_16_pcPolygon_1" d="M 6.400603771209717 0 L 12.80120849609375 10.97246360778809 L 0 10.97246360778809 Z">
				</path>
			</svg>
		</div>
	</div>
</div>
  )
}

export default GamersHub
