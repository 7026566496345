import React from 'react';
import "../../../styles/FurniGem.css";

function FurniGem(props) {
  return (
    <div id="FurniGem">
	<div id="thm15FG_pcGroup_14956">
		<svg class="thm15FG_pcRectangle_705">
			<rect id="thm15FG_pcRectangle_705" rx="29.5" ry="29.5" x="0" y="0" width="208" height="59">
			</rect>
		</svg>
		<div id="thm15FG_pcGroup_14954">
			<div id="thm15FG_pcLearn_More">
				<span>Learn More</span>
			</div>
			<svg class="thm15FG_pcIcon_awesome-arrow-right" viewBox="0 2.647 31.504 30.705">
				<path id="thm15FG_pcIcon_awesome-arrow-right" d="M 13.39453125 4.703906059265137 L 14.95546913146973 3.142968654632568 C 15.61640644073486 2.482031106948853 16.68515586853027 2.482031106948853 17.33906364440918 3.142968654632568 L 31.0078125 16.8046875 C 31.66875076293945 17.46562576293945 31.66875076293945 18.53437423706055 31.0078125 19.18828201293945 L 17.33906173706055 32.85703277587891 C 16.67812347412109 33.51797103881836 15.60937404632568 33.51797103881836 14.95546817779541 32.85703277587891 L 13.39453029632568 31.29609489440918 C 12.72656154632568 30.62812614440918 12.74062442779541 29.53828239440918 13.42265510559082 28.88437652587891 L 21.89531326293945 20.8125 L 1.6875 20.8125 C 0.7523437738418579 20.8125 0 20.06015586853027 0 19.125 L 0 16.875 C 0 15.93984413146973 0.7523437738418579 15.1875 1.6875 15.1875 L 21.89531326293945 15.1875 L 13.42265605926514 7.115624904632568 C 12.73359394073486 6.461718559265137 12.71953105926514 5.371874809265137 13.39453125 4.703906059265137 Z">
				</path>
			</svg>
		</div>
	</div>
	<div id="thm15FG_pcGroup_14966">
		<svg class="thm15FG_pcRectangle_704">
			<rect id="thm15FG_pcRectangle_704" rx="0" ry="0" x="0" y="0" width="406" height="480">
			</rect>
		</svg>
		<img id="thm15FG_pcdi_an_h-g_8MrEZAvyE-unsplash" src="./Themes/FurniGem/di_an_h-g_8MrEZAvyE-unsplash.png" srcset="./Themes/FurniGem/di_an_h-g_8MrEZAvyE-unsplash.png 1x" />
			
		<img id="thm15FG_pcAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/FurniGem/Anime_Edit.png"}/>
			
		<div id="thm15FG_pcTransform__Your_Space">
			<span>Transform <br/>Your Space</span>
		</div>
		<div id="thm15FG_pcTransform_Your_Space_With_Our">
			<span>Transform Your Space With Our</span>
		</div>
		<div id="thm15FG_pcGroup_14955">
			<svg class="thm15FG_pcRectangle_705_c">
				<rect id="thm15FG_pcRectangle_705_c" rx="23" ry="23" x="0" y="0" width="160" height="46">
				</rect>
			</svg>
			<div id="thm15FG_pcGroup_14954_c">
				<div id="thm15FG_pcLearn_More_c">
					<span>Learn More</span>
				</div>
				<svg class="thm15FG_pcIcon_awesome-arrow-right_c" viewBox="0 2.647 24.307 23.691">
					<path id="thm15FG_pcIcon_awesome-arrow-right_c" d="M 10.33461380004883 4.234076023101807 L 11.53896236419678 3.029727458953857 C 12.04891204833984 2.51977801322937 12.87351036071777 2.51977801322937 13.37803649902344 3.029727458953857 L 23.92422294616699 13.57049083709717 C 24.43417358398438 14.08044147491455 24.43417358398438 14.90503883361816 23.92422294616699 15.40956497192383 L 13.3780345916748 25.95575332641602 C 12.86808490753174 26.4657039642334 12.04348564147949 26.4657039642334 11.53896236419678 25.95575332641602 L 10.33461284637451 24.75140571594238 C 9.819238662719727 24.23603057861328 9.83008861541748 23.39515686035156 10.35631275177002 22.89063262939453 L 16.8934326171875 16.6627368927002 L 1.301998615264893 16.6627368927002 C 0.5804743766784668 16.6627368927002 0 16.08226203918457 0 15.36073875427246 L 0 13.62474155426025 C 0 12.90321731567383 0.5804743766784668 12.3227424621582 1.301998615264893 12.3227424621582 L 16.8934326171875 12.3227424621582 L 10.35631370544434 6.094849109649658 C 9.824664115905762 5.590324878692627 9.813814163208008 4.74945068359375 10.33461380004883 4.234076023101807 Z">
					</path>
				</svg>
			</div>
		</div>
		<div id="thm15FG_pcScroll_Group_49">
			<div id="thm15FG_pcIntroduction">
				<span>{props.data.introduction}</span>
			</div>
		</div>
		<div id="thm15FG_pcGroup_14958">
			<svg class="thm15FG_pcRectangle_706">
				<rect id="thm15FG_pcRectangle_706" rx="22" ry="22" x="0" y="0" width="44" height="77">
				</rect>
			</svg>
			<svg class="thm15FG_pcIcon_awesome-arrow-down" viewBox="0.397 2.25 30.705 31.504">
				<path id="thm15FG_pcIcon_awesome-arrow-down" d="M 29.04609298706055 15.64453125 L 30.60703086853027 17.20546913146973 C 31.26796913146973 17.86640739440918 31.26796913146973 18.93515586853027 30.60703086853027 19.58906364440918 L 16.9453125 33.2578125 C 16.28437423706055 33.91875076293945 15.21562480926514 33.91875076293945 14.56171894073486 33.2578125 L 0.8929687738418579 19.58906173706055 C 0.2320312857627869 18.92812347412109 0.2320312857627869 17.859375 0.8929687738418579 17.20546722412109 L 2.453906297683716 15.64452934265137 C 3.121875047683716 14.97656059265137 4.211718559265137 14.99062347412109 4.865625381469727 15.6726541519165 L 12.9375 24.14531326293945 L 12.9375 3.9375 C 12.9375 3.002343654632568 13.68984413146973 2.25 14.625 2.25 L 16.875 2.25 C 17.81015586853027 2.25 18.5625 3.002343654632568 18.5625 3.9375 L 18.5625 24.14531326293945 L 26.63437461853027 15.67265701293945 C 27.28828048706055 14.98359489440918 28.37812423706055 14.96953201293945 29.04609298706055 15.64453220367432 Z">
				</path>
			</svg>
		</div>
		<div id="thm15FG_pcGroup_14963">
			<img id="thm15FG_pcguzman-barquin-FkKClUPUURU-uns" src="./Themes/FurniGem/guzman-barquin-FkKClUPUURU-uns.png" srcset="./Themes/FurniGem/guzman-barquin-FkKClUPUURU-uns.png 1x" />
				
			<img id="thm15FG_pcspacejoy-YqFz7UMm8qE-unsplash" src="./Themes/FurniGem/spacejoy-YqFz7UMm8qE-unsplash.png" srcset="./Themes/FurniGem/spacejoy-YqFz7UMm8qE-unsplash.png 1x" />
				
			<img id="thm15FG_pcmyxa69-nZnVdtlJvbw-unsplash" src="./Themes/FurniGem/myxa69-nZnVdtlJvbw-unsplash.png" srcset="./Themes/FurniGem/myxa69-nZnVdtlJvbw-unsplash.png 1x" />
				
		</div>
		<div id="thm15FG_pcCompany_Name">
			<span>{props.data.businessName}</span>
		</div>
		<div id="thm15FG_pcStunning_Furniture_Collection">
			<span>Stunning Furniture Collection</span>
		</div>
	</div>
	<div id="thm15FG_pcProduct">
		<span>Products</span>
	</div>
	<div id="thm15FG_pcScroll_Group_50">
	{props.offeringsData.map((off,idx)=>(
		<div id="thm15FG_pcGroup_14957">
			<svg class="thm15FG_pcRectangle_708">
				<rect id="thm15FG_pcRectangle_708" rx="0" ry="0" x="0" y="0" width="283" height="362">
				</rect>
			</svg>
			<img id="thm15FG_pcavery-klein-JaXs8Tk5Iww-unspla" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/FurniGem/avery-klein-JaXs8Tk5Iww-unspla.png"} />
				
			<div id="thm15FG_pcChair">
				<span>{off.offeringTitle}</span>
			</div>
		</div>))}
	</div>
	<div id="thm15FG_pcGroup_14961">
		<svg class="thm15FG_pcRectangle_707">
			<rect id="thm15FG_pcRectangle_707" rx="0" ry="0" x="0" y="0" width="876" height="473">
			</rect>
		</svg>
		<img id="thm15FG_pckisspng-furniture-poster-couch" src="./Themes/FurniGem/kisspng-furniture-poster-couch.png" srcset="./Themes/FurniGem/kisspng-furniture-poster-couch.png 1x" />
			
		<div id="thm15FG_pcScroll_Group_51">
			<div id="thm15FG_pcElevate_Your_Space_with_Timele">
				<span>{props.data.vision}</span>
			</div>
		</div>
		<div id="thm15FG_pcVision">
			<span>Vision</span>
		</div>
	</div>
	<div id="thm15FG_pcGroup_14960">
		<svg class="thm15FG_pcRectangle_709">
			<rect id="thm15FG_pcRectangle_709" rx="0" ry="0" x="0" y="0" width="876" height="473">
			</rect>
		</svg>
		<img id="thm15FG_pckisspng-window-living-room-cur" src="./Themes/FurniGem/kisspng-window-living-room-cur.png" srcset="./Themes/FurniGem/kisspng-window-living-room-cur.png 1x" />
			
		<div id="thm15FG_pcScroll_Group_52">
			<div id="thm15FG_pcElevate_Your_Space_with_Timele_d">
				<span>{props.data.mission}</span>
			</div>
		</div>
		<div id="thm15FG_pcMission">
			<span>Mission</span>
		</div>
	</div>
	<div id="thm15FG_pcGroup_14959">
		<svg class="thm15FG_pcRectangle_710">
			<rect id="thm15FG_pcRectangle_710" rx="0" ry="0" x="0" y="0" width="876" height="473">
			</rect>
		</svg>
		<img id="thm15FG_pcsuchit-poojari-ljRiZl00n18-uns" src="./Themes/FurniGem/suchit-poojari-ljRiZl00n18-uns.png" srcset="./Themes/FurniGem/suchit-poojari-ljRiZl00n18-uns.png 1x" />
			
		<div id="thm15FG_pcScroll_Group_53">
			<div id="thm15FG_pcElevate_Your_Space_with_Timele_ea">
				<span>{props.data.principles}</span>
			</div>
		</div>
		<div id="thm15FG_pcPrinciples">
			<span>Principles</span>
		</div>
	</div>
	<div id="thm15FG_pcGroup_14962">
		<svg class="thm15FG_pcRectangle_711">
			<rect id="thm15FG_pcRectangle_711" rx="0" ry="0" x="0" y="0" width="1366" height="442">
			</rect>
		</svg>
		<svg class="thm15FG_pcLine_29" viewBox="0 0 1290 2">
			<path id="thm15FG_pcLine_29" d="M 0 0 L 1290 0">
			</path>
		</svg>
		<div id="thm15FG_pcsocials_grp_shadow_space_websi">
			<div title="LinkedIn" id="thm15FG_pclinked_in_grp_shadow_space_web">
				<svg class="thm15FG_pcEllipse_5shadow_space_website_">
					<ellipse id="thm15FG_pcEllipse_5shadow_space_website_" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<div id="thm15FG_pclinked_in_shadow_space_website">
					<a href="https://www.linkedin.com/in/mohammed-affan-3b0533229/" target="_blank">
					<svg class="thm15FG_pcn_website_2_layout" viewBox="-15.255 0 16.092 15.182">
						<path id="thm15FG_pcn_website_2_layout" d="M -5.186071872711182 9.679508821136551e-07 C -7.904781818389893 9.679508821136551e-07 -9.60834789276123 1.472553014755249 -9.923189163208008 2.504196882247925 L -9.923189163208008 0.1445503830909729 L -15.2549991607666 0.1445503830909729 C -15.18569564819336 1.397309064865112 -15.2549991607666 15.18160820007324 -15.2549991607666 15.18160820007324 L -9.923189163208008 15.18160820007324 L -9.923189163208008 7.044621467590332 C -9.923189163208008 6.592491626739502 -9.944971084594727 6.140363693237305 -9.807681083679199 5.815624713897705 C -9.440696716308594 4.911367893218994 -8.653267860412598 3.972789287567139 -7.237480163574219 3.972789287567139 C -5.38672399520874 3.972789287567139 -4.544512748718262 5.363495349884033 -4.544512748718262 7.400383949279785 L -4.544512748718262 15.18160820007324 L 0.8368034362792969 15.18160820007324 L 0.8368034362792969 6.820206165313721 C 0.8368034362792969 2.162956237792969 -1.813262462615967 9.679508821136551e-07 -5.186071872711182 9.679508821136551e-07">
						</path>
					</svg>
				</a>
					<svg class="thm15FG_pclinked_in_i_down" viewBox="0.931 -22.783 4.746 15.038">
						<path id="thm15FG_pclinked_in_i_down" d="M 0.9310001134872437 -7.745282173156738 L 5.676695346832275 -7.745282173156738 L 5.676695346832275 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -7.745282173156738 Z">
						</path>
					</svg>
					<svg class="thm15FG_pctop_dot_i_website_2_layout" viewBox="-4.392 0 5.761 5.218">
						<path id="thm15FG_pctop_dot_i_website_2_layout" d="M -1.493098974227905 0 C -3.245508193969727 0 -4.392000198364258 1.125370144844055 -4.392000198364258 2.611123323440552 C -4.392000198364258 4.06783390045166 -3.278510093688965 5.217626571655273 -1.560423135757446 5.217626571655273 L -1.526101112365723 5.217626571655273 C 0.2579902410507202 5.217626571655273 1.368839383125305 4.063873767852783 1.368839383125305 2.606502532958984 C 1.334516644477844 1.122730016708374 0.2579902410507202 0 -1.493098974227905 0">
						</path>
					</svg>
				</div>
			</div>
			<div title="Instagram" id="thm15FG_pcInsta_grp_med_shadow_space_web">
				<svg class="thm15FG_pcEllipseshadow_space_website_5_">
					<ellipse id="thm15FG_pcEllipseshadow_space_website_5_" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<a href="https://www.instagram.com/mohammed_affan_78/?next=%2F" target="_blank">
				<img id="thm15FG_pcinstagram_shadow_space_website" src="./Themes/FurniGem/instagram_shadow_space_website.png" srcset="./Themes/FurniGem/instagram_shadow_space_website.png 1x" />
					
				</a>
			</div>
			<div title="WhatsApp" id="thm15FG_pcwhatsapp_grpshadow_space_websi">
				<svg class="thm15FG_pcEllipse_3_shadow_space_website">
					<ellipse id="thm15FG_pcEllipse_3_shadow_space_website" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<img id="thm15FG_pcwhatsapp_lbl_shadow_space_webs" src="./Themes/FurniGem/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/FurniGem/whatsapp_lbl_shadow_space_webs.png 1x" />
					
			</div>
		</div>
		<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="thm15FG_pcRectangle_712">
			<rect id="thm15FG_pcRectangle_712" rx="0" ry="0" x="0" y="0" width="547" height="244">
			</rect>
		</iframe>
		<div id="thm15FG_pcn_234567890_mobile_number_shad">
			<span>{props.data.businessPhone}</span>
		</div>
		<div id="thm15FG_pcaffanghanioperatelive_email_sh">
			<span>{props.data.businessEmail}</span>
		</div>
		<div id="thm15FG_pcaddress_med_shadow_space_websi">
			<span>{props.data.businessAddress}</span>
		</div>
		<img id="thm15FG_pclocation_img_shadow_space_webs" src="./Themes/FurniGem/location_img_shadow_space_webs.png" srcset="./Themes/FurniGem/location_img_shadow_space_webs.png 1x" />
			
		<img id="thm15FG_pcemail_address_shadow_space_web" src="./Themes/FurniGem/email_address_shadow_space_web.png" srcset="./Themes/FurniGem/email_address_shadow_space_web.png 1x" />
			
		<img id="thm15FG_pcphone_number_shadow_space_webs" src="./Themes/FurniGem/phone_number_shadow_space_webs.png" srcset="./Themes/FurniGem/phone_number_shadow_space_webs.png 1x" />
			
		<div id="thm15FG_pcVisit_Us">
			<span>Visit Us!</span>
		</div>
	</div>
</div>
  )
}

export default FurniGem
