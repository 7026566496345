import React, { useState, useEffect, useContext } from "react";
import { UserContext } from './UserContext';
import '../styles/SideNavBarIconUI.css'
import {useNavigate} from "react-router-dom";

const SideNavBarICONUI = (props) => {

//useState
var navigate = useNavigate();
const { user, setUser } = useContext(UserContext);

const meetingRoom = ()=>{
   
    props.goto(false);
    navigate('/meeting')
}

const dashboardRoom = ()=>{

    props.goto(false)
    navigate('/home')
}

const myPortfolio = ()=>{
    props.goto(false)
    navigate('/portfolio')
}

// const requirementRoom = ()=>{
//     props.goto(false)
//     navigate('/requirement')
// }

// const offeringRoom = ()=>{
//     props.goto(false)
//     navigate('/offerings')
// }
const myconnectionRoom = ()=>{
    props.goto(false)
    navigate('/mypeople')
}

const suggestionsRoom = ()=>{
    props.goto(false)
    navigate('/suggestions')
}

const communities = ()=>{
    props.goto(false)
    navigate('/communities')
}

const marketRoom = ()=>{
    props.goto(false)
    navigate('/marketpage')
}
const eventsRoom = ()=>{
    props.goto(false)
    navigate('/events')
}

const handlelogout = () =>{
    localStorage.clear();
    setUser(null);
    window.location.href = "/login";
  }


  return (
<div id="side_bar_icon">
	<svg class="side_nav_icon_border" onClick={()=>{props.goto(true)}}>
		<rect id="side_nav_icon_border" rx="0" ry="0" x="0" y="0" width="112" height="100%">
		</rect>
	</svg>
	<img id="side_logo_icon" src="side_logo_icon.png" srcset="side_logo_icon.png 1x" onClick={()=>{props.goto(true)}}/>
		
	<div title="Profile" id="dash_bdr_grp"  onClick={()=>dashboardRoom()}>
		<svg class="dash_bdr">
			<rect id="dash_bdr" rx="5" ry="5" x="0" y="0" width="43" height="43">
			</rect>
		</svg>
		<img id="dashboard_Icon_I" src="dashboard_Icon_I.png" srcset="dashboard_Icon_I.png 1x" />
			
	</div>
	<div title="Meeting Room" id="metting_room_grp" onClick={()=>meetingRoom()}>
		<svg class="meet_bdr">
			<rect id="meet_bdr" rx="5" ry="5" x="0" y="0" width="43" height="43">
			</rect>
		</svg>
		<img id="meeting_room_icon_I" src="meeting_room_icon_I.png" srcset="meeting_room_icon_I.png 1x" />
			
	</div>
	<div title="Portfolio" id="portfolio_grp" onClick={()=>{myPortfolio()}}>
		<svg class="portfolio_bdr">
			<rect id="portfolio_bdr" rx="5" ry="5" x="0" y="0" width="43" height="43">
			</rect>
		</svg>
		<img id="portfolio_icon_I" src="./SideNav/portfolio_icon_mob.png" srcset="./SideNav/portfolio_icon_mob.png 1x" />
			
	</div>
	<div title="People" id="connection_grp" onClick={()=>{myconnectionRoom()}}>
		<svg class="con_bdr">
			<rect id="con_bdr" rx="5" ry="5" x="0" y="0" width="43" height="43">
			</rect>
		</svg>
		<img id="my_connections_icon_I" src="my_connections_icon_I.png" srcset="my_connections_icon_I.png 1x" />
			
	</div>
	<div title="Market" id="market_grpp" onClick={()=>marketRoom()}>
		<svg class="market_bdr">
			<rect id="market_bdr" rx="5" ry="5" x="0" y="0" width="43" height="43">
			</rect>
		</svg>
		<img id="market_icon_I" src="market_icon_I.png" srcset="market_icon_I.png 1x" />
			
	</div>
	<div title="Events" id="event_grp" onClick={()=>eventsRoom()}>
		<svg class="event_bdr">
			<rect id="event_bdr" rx="5" ry="5" x="0" y="0" width="43" height="43">
			</rect>
		</svg>
		<img id="events_icon_I" src="broadcast_Nav_bar.png" srcset="broadcast_Nav_bar.png 1x" />
			
	</div>
	<div title="LogOut" id="logout_grp" onClick={()=>{handlelogout()}}>
		<svg class="log_bdr">
			<rect id="log_bdr" rx="5" ry="5" x="0" y="0" width="43" height="43">
			</rect>
		</svg>
		<img id="logout_icon_I" src="logout_icon_I.png" srcset="logout_icon_I.png 1x" />
			
	</div>
	<div title="Communities" id="com_grp" onClick={()=>{communities()}}>
		<svg class="com_bdr">
			<rect id="com_bdr" rx="5" ry="5" x="0" y="0" width="43" height="43">
			</rect>
		</svg>
		<img id="community_icon_I" src="./SideNav/community_icon_I_1_mob.png" srcset="./SideNav/community_icon_I_1_mob.png 1x" />
			
	</div>
</div>
  )
}

export default SideNavBarICONUI
