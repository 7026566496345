import React, { useState, useEffect, useContext } from "react";
import "../../../styles/ShadowSpaceMob.css";
import { UserContext } from '../../UserContext';
import { useNavigate,useLocation, Navigate } from "react-router-dom";

const ShadowSpaceMob = (props) => {
  return (
    <div id="web_5_mob_ShadowScape">
   
	<nav role="navigation">
	<div id="menuToggle">
	
	  <input type="checkbox" />
	
	  <span></span>
	  <span></span>
	  <span></span>
	
	  <ul id="menu">
	
	    <a href="#shadow_profileshadow_space_website_5_" target=""><li>Home</li></a>
		<a href="#shadow_oofering_lbl_shadow_space_webs" target=""><li>Offerings</li></a>
		<a href="#shadow_oofering_lbl_shadow_space_webs_cv" target=""><li>Events</li></a>
		<a href="#shadow_fotter_line_shadow_space_websi" target=""><li>Contact</li></a>
		{/* <a href="#bdr_contact_page_website_2mob" target=""><li>Contact</li></a>  */}
	  </ul>
	</div>
  </nav>
    {/* <svg class="shadow_n_th_shadow_space_website_5_mo" viewBox="4236.69 20366.375 1585.334 1103.135">
      <path id="shadow_n_th_shadow_space_website_5_mo" d="M 4236.68994140625 21310.33203125 L 5719.4287109375 20366.375 L 5822.0244140625 20478.296875 L 4236.68994140625 21469.509765625 L 4236.68994140625 21310.33203125 Z">
      </path>
    </svg> */}
    <svg class="shadow_n_rd_shadow_space_website_5_mo" viewBox="4350.95 20281.902 1377.635 915.344">
      <path id="shadow_n_rd_shadow_space_website_5_mo" d="M 4350.9501953125 21061.53515625 L 5641.1142578125 20281.90234375 L 5728.5849609375 20377.32421875 L 4350.9501953125 21197.24609375 L 4350.9501953125 21061.53515625 Z">
      </path>
    </svg>
    <svg class="shadow_n_nd_shadow_space_website_5_mo" viewBox="4350.95 20491.113 820.058 661.238">
      <path id="shadow_n_nd_shadow_space_website_5_mo" d="M 4350.9501953125 21030.587890625 L 5095.5234375 20491.11328125 L 5171.0078125 20576.73046875 L 4350.9501953125 21152.3515625 L 4350.9501953125 21030.587890625 Z">
      </path>
    </svg>
    <svg class="shadow_top_design_shadow_space_websit" viewBox="4690 20491.113 535.086 434.371">
      <path id="shadow_top_design_shadow_space_websit" d="M 4690 20815.658203125 L 5154.30029296875 20491.11328125 L 5225.08642578125 20568.3359375 L 4690 20925.484375 L 4690 20815.658203125 Z">
      </path>
    </svg>
    <div id="shadow_top_deafult_pic_img">
      <svg class="shadow_top_gold_shadow_space_website_">
        <rect id="shadow_top_gold_shadow_space_website_" rx="0" ry="0" x="0" y="0" width="164" height="184">
        </rect>
      </svg>
      <svg class="shadow_down_white_shadow_space_websit">
        <rect id="shadow_down_white_shadow_space_websit" rx="0" ry="0" x="0" y="0" width="165" height="185">
        </rect>
      </svg>
      <img id="shadow_damon-shadow_space_website_5_m" src="./Themes/ShadowSpace/damon-shadow_space_website_5_mob.png" srcset="./Themes/ShadowSpace/damon-shadow_space_website_5_mob.png 1x" />
        
    </div>
    <img id="shadow_profileshadow_space_website_5_" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/ShadowSpace/user_pfp_profile_page.png"} />
      
    <div id="shadow_Heading_shadow_space_website_5">
      <span>{props.data.businessName && props.data.businessName.length > 0?props.data.businessName:props.data.name}</span>
    </div>
    <div id="shadow_Scroll_shadow_space_website_5_">
      <div id="shadow_intro_lbl_website_3">
        <span>{props.data.introduction}</span>
      </div>
    </div>
    <div id="shadow_what_weshadow_space_website_5_">
      <span>What We Stand For</span>
    </div>
    <div id="shadow_Scroll_mission_shadow_space_we">
      <div id="shadow_intro_2_website_3">
        <span>{props.data.mission}</span>
      </div>
    </div>
    <div id="shadow_mission_lbl_shadow_space_websi">
      <span>Mission</span>
    </div>
    <div id="shadow_Icon_2_grp_shadow_space_websit">
      <svg class="shadow_bg_blue_design">
        <rect id="shadow_bg_blue_design" rx="35" ry="35" x="0" y="0" width="70" height="70">
        </rect>
      </svg>
    </div>
    <img id="shadow_leadership_2_shadow_space_webs" src="./Themes/ShadowSpace/leadership_2_shadow_space_webs_mob.png" srcset="./Themes/ShadowSpace/leadership_2_shadow_space_webs_mob.png 1x" />
      
    <div id="shadow_Scroll_vision_shadow_space_web">
      <div id="shadow_intro_website_3">
        <span>{props.data.vision}</span>
      </div>
    </div>
    <div id="shadow_Vision_shadow_space_website_5_">
      <span>Vision</span>
    </div>
    <div id="shadow_Icon_grp_shadow_space_website_">
      <svg class="shadow_Fill_11_website_3">
        <rect id="shadow_Fill_11_website_3" rx="35" ry="35" x="0" y="0" width="70" height="70">
        </rect>
      </svg>
    </div>
    <img id="shadow_target_1_shadow_space_website_" src="./Themes/ShadowSpace/target_1_shadow_space_website_mob.png" srcset="./Themes/ShadowSpace/target_1_shadow_space_website_mob.png 1x" />
      
    <div id="shadow_Scroll_princi_shadow_space_web">
      <div id="shadow_intro_lbl_website_3_cj">
        <span>{props.data.principles}</span>
      </div>
    </div>
    <div id="shadow_principles_lbl_shadow_space_we">
      <span>Principles</span>
    </div>
    <svg class="shadow_Fill_shadow_space_website_5_mo">
      <rect id="shadow_Fill_shadow_space_website_5_mo" rx="35" ry="35" x="0" y="0" width="70" height="70">
      </rect>
    </svg>
    <img id="shadow_principle_2_shadow_space_websi" src="./Themes/ShadowSpace/principle_2_shadow_space_websi_mob.png" srcset="./Themes/ShadowSpace/principle_2_shadow_space_websi_mob.png 1x" />
      
    <div id="shadow_oofering_lbl_shadow_space_webs">
      <span>My Offerings</span>
    </div>
    <div id="shadow_Scroll_offering_space_mob_5">
    {props.offeringsData.map((rec,idx)=>(
      <div id="shadow_space_grp_web_mob_5">
        <svg class="shadow_bdr_offering_mob">
          <rect id="shadow_bdr_offering_mob" rx="10" ry="10" x="0" y="0" width="190.07" height="268.577">
          </rect>
        </svg>
        <svg class="shadow_half_sun_spave_mob" viewBox="16.614 0 15.194 28.924">
          <path id="shadow_half_sun_spave_mob" d="M 17.34625053405762 0 C 25.33330154418945 0 31.80808639526367 6.474784374237061 31.80808639526367 14.46183490753174 C 31.80808639526367 22.44888496398926 25.33330154418945 28.92366981506348 17.34625053405762 28.92366981506348 C 16.45251846313477 28.92366981506348 16.29167747497559 0 17.34625053405762 0 Z">
          </path>
        </svg>
        <svg class="shadow_dot_design_carrd_offering">
          <ellipse id="shadow_dot_design_carrd_offering" rx="2.892366886138916" ry="2.892366886138916" cx="2.892366886138916" cy="2.892366886138916">
          </ellipse>
        </svg>
        <div id="shadow_oofering_lbl_med_web_4_pc">
          <span>{rec.offeringTitle}</span>
        </div>
        <img id="shadow_markus-img_offerings" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"./Themes/ShadowSpace/profilebackgroundEm.png" } />
          
      </div>
      ))}
    </div>
    <div id="shadow_oofering_lbl_shadow_space_webs_cv">
      <span>My Events</span>
    </div>

    <div id="shadow_Scroll_event_vgrp_mob_web_5">
    {props.eventsData && props.eventsData.map((eve)=>(
		<div id="shadow_event_grp_shadow_space_website">
			<svg class="shadow_event_bg_card_1_shadow_space_w">
				<rect id="shadow_event_bg_card_1_shadow_space_w" rx="15" ry="15" x="0" y="0" width="442" height="286">
				</rect>
			</svg>
			<img id="shadow_Anime_Edit_shadow_space_websit" src={eve.eventImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(eve.eventImageUrl.split('/')[5]):"./Themes/ShadowSpace/Anime_Edit_shadow_space_websit.png"}/>
				
			<div id="shadow_oofering_lblshadow_space_websi">
				<span>{eve.title}</span>
			</div>
			<div id="shadow_Scrolldesc_shadow_space_websit">
				<div id="shadow_oofering_lbl_shadow_space_webs_dh">
					<span>{eve.description}</span>
				</div>
			</div>
			<div id="shadow_date_grp_mob_web_5">
				<div id="shadow_dateshadow_space_website_5_mob">
					<span>{eve.startsOn.slice(0,10)}</span>
				</div>
				<img id="shadow_calendar_2_shadow_space_websit" src="./Themes/ShadowSpace/calendar_2_shadow_space_websit.png" srcset="./Themes/ShadowSpace/calendar_2_shadow_space_websit.png 1x" />
					
			</div>
			<div id="shadow_time_grp_mob_web_5">
				<div id="shadow_date_shadow_space_website_5_mo">
					<span>{eve.startsOn.slice(11,16)} IST</span>
				</div>
				<img id="shadow_clock_1_shadow_space_website_5" src="./Themes/ShadowSpace/clock_1_shadow_space_website_5.png" srcset="./Themes/ShadowSpace/clock_1_shadow_space_website_5.png 1x" />
					
			</div>
			<div id="shadow_name_grp_mob_web_5">
				<img id="shadow_man_2_shadow_space_website_5_m" src="./Themes/ShadowSpace/man_2_shadow_space_website_5_m.png" srcset="./Themes/ShadowSpace/man_2_shadow_space_website_5_m.png 1x" />
					
				<div id="shadow_date_shadow_space_website_5_mo_dq">
					<span>{eve.organiserName}</span>
				</div>
			</div>
			<div id="shadow_seats_grp_mob_web_5">
				<img id="shadow_seats_shadow_space_website_5_m" src="./Themes/ShadowSpace/seats_shadow_space_website_5_m.png" srcset="./Themes/ShadowSpace/seats_shadow_space_website_5_m.png 1x" />
					
				<div id="shadow_date_shadow_space_website_5_mo_dt">
					<span>{eve.seats}</span>
				</div>
			</div>
			<div id="shadow_price_grp_mob_web_5">
				<img id="shadow_price-tag_1_shadow_space_websi" src="./Themes/ShadowSpace/price-tag_1_shadow_space_websi.png" srcset="./Themes/ShadowSpace/price-tag_1_shadow_space_websi.png 1x" />
					
				<div id="shadow_date_shadow_space_website_5_mo_dw">
					<span>₹ {eve.cost}</span>
				</div>
			</div>
		</div>
    ))}
	</div>

    
    <svg class="shadow_fotter_line_shadow_space_websi" viewBox="0 0 323 3">
      <path id="shadow_fotter_line_shadow_space_websi" d="M 0 0 L 323 0">
      </path>
    </svg>
    <div id="shadow_n_234567890_shadow_space_websi">
      <span>{props.data.businessPhone}</span>
    </div>
    <div id="shadow_affanghanioperatelive_shadow_s">
      <span>{props.data.businessEmail}</span>
    </div>
    <div id="shadow_address_shadow_space_website_5">
      <span>{props.data.businessAddress}</span>
    </div>
    <img id="shadow_location_img_shadow_space_webs" src="./Themes/ShadowSpace/location_img_shadow_space_webs_mob.png" srcset="./Themes/ShadowSpace/location_img_shadow_space_webs_mob.png 1x" />
      
    <img id="shadow_email_address_shadow_space_web" src="./Themes/ShadowSpace/email_address_shadow_space_web_mob.png" srcset="./Themes/ShadowSpace/email_address_shadow_space_web_mob.png 1x" />
      
    <img id="shadow_phone_number_shadow_space_webs" src="./Themes/ShadowSpace/phone_number_shadow_space_webs_mob.png" srcset="./Themes/ShadowSpace/phone_number_shadow_space_webs_mob.png 1x" />
      
    <div id="shadow_Connect_with_us_and_unlock_lim">
      <span>Discover a world of limitless possibilities by connecting with us today!</span>
    </div>


    <div id="shadow_socials_grp_shadow_space_websi">

    {props.data.linkedInUrl?
      <div title="LinkedIn" id="shadow_linked_in_grp_shadow_space_web">
        <svg class="shadow_Ellipse_5shadow_space_website_">
          <ellipse id="shadow_Ellipse_5shadow_space_website_" rx="25" ry="25" cx="25" cy="25">
          </ellipse>
        </svg>
        <div id="shadow_linked_in_shadow_space_website">
          <a href={props.data.linkedInUrl} target="_blank">
          <svg class="shadow_n_website_2_layout" viewBox="-15.255 0 16.092 15.182">
            <path id="shadow_n_website_2_layout" d="M -5.186071872711182 9.679508821136551e-07 C -7.904781818389893 9.679508821136551e-07 -9.60834789276123 1.472553014755249 -9.923189163208008 2.504196882247925 L -9.923189163208008 0.1445503830909729 L -15.2549991607666 0.1445503830909729 C -15.18569564819336 1.397309064865112 -15.2549991607666 15.18160820007324 -15.2549991607666 15.18160820007324 L -9.923189163208008 15.18160820007324 L -9.923189163208008 7.044621467590332 C -9.923189163208008 6.592491626739502 -9.944971084594727 6.140363693237305 -9.807681083679199 5.815624713897705 C -9.440696716308594 4.911367893218994 -8.653267860412598 3.972789287567139 -7.237480163574219 3.972789287567139 C -5.38672399520874 3.972789287567139 -4.544512748718262 5.363495349884033 -4.544512748718262 7.400383949279785 L -4.544512748718262 15.18160820007324 L 0.8368034362792969 15.18160820007324 L 0.8368034362792969 6.820206165313721 C 0.8368034362792969 2.162956237792969 -1.813262462615967 9.679508821136551e-07 -5.186071872711182 9.679508821136551e-07">
            </path>
          </svg>
        </a>
          <svg class="shadow_linked_in_i_down" viewBox="0.931 -22.783 4.746 15.038">
            <path id="shadow_linked_in_i_down" d="M 0.9310001134872437 -7.745282173156738 L 5.676695346832275 -7.745282173156738 L 5.676695346832275 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -7.745282173156738 Z">
            </path>
          </svg>
          <svg class="shadow_top_dot_i_website_2_layout" viewBox="-4.392 0 5.761 5.218">
            <path id="shadow_top_dot_i_website_2_layout" d="M -1.493098974227905 0 C -3.245508193969727 0 -4.392000198364258 1.125370144844055 -4.392000198364258 2.611123323440552 C -4.392000198364258 4.06783390045166 -3.278510093688965 5.217626571655273 -1.560423135757446 5.217626571655273 L -1.526101112365723 5.217626571655273 C 0.2579902410507202 5.217626571655273 1.368839383125305 4.063873767852783 1.368839383125305 2.606502532958984 C 1.334516644477844 1.122730016708374 0.2579902410507202 0 -1.493098974227905 0">
            </path>
          </svg>
        </div>
      </div>:""}
 {props.data.instagramUrl?	
      <div title="Instagram" id="shadow_Insta_grp_med_shadow_space_web">
        <svg class="shadow_Ellipseshadow_space_website_5_">
          <ellipse id="shadow_Ellipseshadow_space_website_5_" rx="25" ry="25" cx="25" cy="25">
          </ellipse>
        </svg>
        <a href={props.data.instagramUrl} target="_blank">
        <img id="shadow_instagram_shadow_space_website" src="./Themes/ShadowSpace/instagram_shadow_space_website_mob.png" srcset="./Themes/ShadowSpace/instagram_shadow_space_website_mob.png 1x" />
          
        </a>
      </div>:""}
      {props.data.whatsAppNumber?
      <div title="WhatsApp" id="shadow_whatsapp_grpshadow_space_websi">
        <svg class="shadow_Ellipse_3_shadow_space_website">
          <ellipse id="shadow_Ellipse_3_shadow_space_website" rx="25" ry="25" cx="25" cy="25">
          </ellipse>
        </svg>
        <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
        <img id="shadow_whatsapp_lbl_shadow_space_webs" src="./Themes/ShadowSpace/whatsapp_lbl_shadow_space_webs_mob.png" srcset="./Themes/ShadowSpace/whatsapp_lbl_shadow_space_webs_mob.png 1x" />
        </a>
          
      </div>
      :""}
    </div>
    <img id="shadow_quote_left_shadow_space_websit" src="./Themes/ShadowSpace/quote_left_shadow_space_websit_mob.png" srcset="./Themes/ShadowSpace/quote_left_shadow_space_websit_mob.png 1x" />
      
    <a href="https://operate.live" target="_blank">
    <div id="shadow_n_Copyright_Operate_Live_shado">
      <span>© Copyright Operate Live (OPC) Private Limited.<br/>All Rights Reserved</span>
    </div>
    </a>
    <img id="shadow_quote_right_med_shadow_space_w_mob" src="./Themes/ShadowSpace/quote_right_med_shadow_space_mob.png" srcset="./Themes/ShadowSpace/quote_right_med_shadow_space_mob.png 1x" />
      
    <div id="shadow_Partners_grp_shadow_space_webs">
      <div id="shadow_text_our_partnersshadow_space_">
        <span>Our Amazing Partners</span>
      </div>
      <div id="shadow_Scroll_partner_shadow_space_we_mob">
      {props.partnersData && props.partnersData.map(partner=>
        <div onClick={()=>window.location.href="https://"+partner.nickName+".operate.live"}>
        <div id="shadow_partner_lbls_shadow_space_webs_mob">
          <div id="shadow_google_lbl_partner_shadow_spac_mob">
            <span>{partner.businessName && partner.businessName.length>0?partner.businessName:partner.name}</span>
          </div>
          <img id="shadow_google_1_shadow_space_website_mob" src={partner.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(partner.imageUrl.split('/')[5]):"./Themes/office.png"} />
            
        </div>
        </div>
            )}
      </div>
    </div>
  </div>

  )
}

export default ShadowSpaceMob
