import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import { UserContext } from "./Components/UserContext";
import { AllOut } from "@material-ui/icons";

const SocketContext = createContext();

const socket = io(process.env.REACT_APP_API_URL, {
  reconnect: true,
  autoConnect: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
});

const ContextProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  // const [stream,setStream] = useState(new MediaStream());
  const [call, setCall] = useState({});
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(true);
  const [callRejected, setCallRejected] = useState(false);
  const [name, setName] = useState("");
  const [mute, setMute] = useState(false);
  const [videoOff, setVideoOff] = useState(true);
  const [response, setResponse] = useState("");
  const ringSound = useRef(false);

  const me = useRef();
  const myVideo = useRef(new MediaStream());
  const userVideo = useRef(new MediaStream());
  const checkOnlineStatusTimer = useRef();
  const connectionRef = useRef();
  // const outdatedStreamRef = useRef();
  const activeStreamRef = useRef();
  const remoteStreamRef = useRef();
  const beepsound = new Audio("callringing.mp3");
  const isInCall = useRef(false);

  useEffect(async() => {
    if (user) {
      await onMyVideo();
      offMyVideo();
      // navigator.mediaDevices
      // .getUserMedia({ video: true, audio: true })
      // .then((currentStream) => {
      //   currentStream
      //     .getVideoTracks()
      //     .forEach((track) => track.enabled = !videoOff);
      //   currentStream
      //     .getAudioTracks()
      //     .forEach((track) => track.enabled = !mute);
      //   myVideo.current.srcObject = currentStream;
      //   activeStreamRef.current = currentStream;
      // });
      socket.on("me", (id) => {
        //    console.clear()
        console.log("me = " + id);
        me.current = id;
        //  if(user){
        //     updateOnlineStatus();
        //  }
        clearInterval(checkOnlineStatusTimer.current);
        checkOnlineStatusTimer.current = setInterval(() => {
          if (user) updateOnlineStatus();
        }, 5000);
      });
      socket.on("calluser", ({ signal, from, name }) => {
        console.log("call coming", name);
        if (isInCall.current === false) {
          isInCall.current = true;
          ringSound.current = true;

          setCall({ isRecievedCall: true, signal, from, name });
        }
      });
      //socket.emit("getsocketid");

      // clearInterval();
      // setInterval(()=>{
      //     if(user)
      //     updateOnlineStatus()
      // },5000)
      return () => {
        // var closedStream = new MediaStream(outdatedStreamRef.current)
        // closedStream.getTracks().forEach((track) => {
        //     track.stop();})
        clearInterval(checkOnlineStatusTimer.current);
        var activeStream = new MediaStream(activeStreamRef.current);
        activeStream.getTracks().forEach((track) => {
          track.stop();
        });
        //    stream && stream.getTracks().forEach((track) => {
        //     track.stop();track.enabled=false;})
      };
    }
  }, [user]);

  useEffect(() => {
    if (ringSound.current === true) playRingTone();
  }, [ringSound.current]);


  // useEffect ( ()=>{
  //     //alert("Changed")
  //     // if(outdatedStreamRef.current)
  //     // {
  //     //     var closedStream = new MediaStream(outdatedStreamRef.current)
  //     // outdatedStreamRef.current && outdatedStreamRef.current.getTracks().forEach((track) => {
  //     // track.stop()})
  //     if(videoOff === true)
  //     {
  //         activeStreamRef.current && activeStreamRef.current.getTracks().forEach((track) => {
  //             track.stop()})
  //     }
  //     // outdatedStreamRef.current = activeStreamRef.current;
  //     //  }

  // },[activeStreamRef.current]);

  useEffect(() => {
    console.log("Socket Changed");
    //vedioChanged();

    // socket.on('me',(id)=> {setMe(id);console.log(id);});
    // socket.on('calluser',({signal,from,name})=>{
    //     console.log('call coming',signal)
    //     ringSound.current=true;
    //     // setCallAccepted(false)
    //     // setCallEnded(true)
    //     setCall({isRecievedCall:true,signal,from,name})
    // });
    // socket.on('msguser',({msg,from,name})=>{
    //     console.log({msg,from,name})
    //     // setCallAccepted(false)
    //     // setCallEnded(true)
    //     setResponse(response+'\n'+name+": "+msg)

    // });
    // socket.on('isonline',(mobile)=>{
    //     socket.emit('online',mobile === user.mobile)
    // });
    // clearInterval();
    // setInterval(()=>updateOnlineStatus(),5000)
  }, [me.current]);

  // useEffect ( ()=>{

  //     // connectionRef.current && connectionRef.current.streams.forEach(stm => stm.getVideoTracks().forEach(track => track.enabled = !videoOff));

  //     // connectionRef.current && connectionRef.current.streams.forEach(stm => stm.getAudioTracks().forEach(track => track.enabled = !mute));

  //  const getUserMedia = () => {

  //      navigator.mediaDevices.getUserMedia({video:true,audio:true})
  //     .then((currentStream)=>{
  //         currentStream.getVideoTracks().forEach(track => track.enabled = !videoOff);
  //         currentStream.getAudioTracks().forEach(track => track.enabled = !mute);
  //         setStream(currentStream);
  //         myVideo.current.srcObject = currentStream;
  //         outdatedStreamRef.current = currentStream;
  //     });
  // }
  //  getUserMedia();

  // },[videoOff,mute]);
  useEffect(() => {
    //vedioChanged();

    videoOff===true?offMyVideo():onMyVideo();
    if (activeStreamRef.current) {
      var activeStream = new MediaStream(activeStreamRef.current);
      if (videoOff === true) {
        activeStream
        .getVideoTracks()
        .forEach((track) => (track.stop()));
      } else {
        activeStream
          .getVideoTracks()
          .forEach((track) => (track.enabled = !videoOff));
      }
      activeStream.getAudioTracks().forEach(track => track.enabled = !mute);
      // activeStreamRef.current.getVedioTracks().forEach((track) => (track.enabled = !videoOff));
      // activeStreamRef.current.getAudioTracks().forEach((track) => (track.enabled = !mute));
      // activeStreamRef.current = activeStream;
      myVideo.current.srcObject = activeStream;
      
      connectionRef.current && connectionRef.current.streams.forEach(stm => stm.getVideoTracks().forEach(track => track.enabled = !videoOff));

      connectionRef.current && connectionRef.current.streams.forEach(stm => stm.getAudioTracks().forEach(track => track.enabled = !mute));
  
    }
  }, [videoOff, mute]);

  // const vedioChanged = () => {
  //   // connectionRef.current && connectionRef.current.streams.forEach(stm => stm.getVideoTracks().forEach(track => track.enabled = !videoOff));

  //   // connectionRef.current && connectionRef.current.streams.forEach(stm => stm.getAudioTracks().forEach(track => track.enabled = !mute));

  //   // outdatedStreamRef.current = activeStreamRef.current;
  //   activeStreamRef.current && activeStreamRef.current.getTracks().forEach((track) => {
  //       track.stop()})
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then((currentStream) => {
  //       currentStream
  //         .getVideoTracks()
  //         .forEach((track) => (videoOff === true ? track.stop() : track.enabled = true));
  //       currentStream
  //         .getAudioTracks()
  //         .forEach((track) => (mute === true ? track.stop() : track.enabled = true));
  //       myVideo.current.srcObject = currentStream;
  //       activeStreamRef.current = currentStream;
  //       console.log(connectionRef.current)
  //     });
  // };

  const onMyVideo = async () => {
    console.log("on");
   await  navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((currentStream) => {
        currentStream
          .getVideoTracks()
          .forEach((track) => (track.enabled = !videoOff));
        currentStream
          .getAudioTracks()
          .forEach((track) => (track.enabled = !mute));
        myVideo.current.srcObject = currentStream;
        activeStreamRef.current = currentStream;
        //console.log(connectionRef.current)

      });
  };
  const offMyVideo = () => {
    console.log("off");
    if (activeStreamRef.current) {
      var activeStream = new MediaStream(activeStreamRef.current);
      activeStream.getTracks().forEach((track) => {
        track.stop();
      });
      // activeStreamRef.current = null;
    }
  };
  const playRingTone = () => {
    beepsound.play();
    if (ringSound.current === true) {
      setTimeout(() => {
        playRingTone();
      }, 3000);
    }
    // 	setTimeout(()=>{
    // 	if (call.isRecievedCall === true && !(callAccepted === false || callRejected === false))
    // 	{playRingTone()}
    // 	else {stopRingTone()}
    // },2000)
  };

  const updateOnlineStatus = () => {
    console.log("updateOnlineStatus", "me = " + me.current);
    if (me.current === "") {
      return socket.emit("getsocketid");
    }
    if (user) {
      let apiURL =
        process.env.REACT_APP_API_URL +
        "/status/onlinestatus/" +
        user.mobile.substring(user.mobile.length - 10);
      let apiParams = {
        method: "POST",
        headers: {
          id: user._id,
          name: user.name,
          socketid: me.current,
        },
      };
      fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const rejectCall = () => {
    ringSound.current = false;
    setCallAccepted(false);
    setCallRejected(true);

    isInCall.current = false;
    socket.emit("rejectcall", { to: call.from });

    setCallEnded(true);
    setTimeout(() => {
      ringSound.current = false;
      setCall({ isRecievedCall: false });
      setCallRejected(false);
    }, 1500);
  };

  const answerCall = () => {
    ringSound.current = false;
    setCallAccepted(true);
    setCallEnded(false);
    var peer = new Peer({
      initiator: false,
      trickle: false,
      stream: activeStreamRef.current,
    });

    peer.on("signal", (data) => {
      console.log("Signal Received!");
      setName(call.name);
      isInCall.current = true;
      ringSound.current = false;
      socket.emit("answercall", { signal: data, to: call.from });
    });

    peer.on("stream", (currentStream) => {
      console.log("Stream Recieved");
      isInCall.current = true;
      ringSound.current = false;
      // if(callAccepted==true &&callEnded==false)
      //userVideo.current = new MediaStream();
      remoteStreamRef.current = new MediaStream(currentStream);
      userVideo.current.srcObject = remoteStreamRef.current;
    });

    peer.on("userleft", () => {
      console.log("Caller Left Call!");
      isInCall.current = false;
      ringSound.current = false;
      setCallAccepted(false);
      setCallRejected(true);
      setCallEnded(true);
      setTimeout(() => {
        ringSound.current = false;
        setCall({ isRecievedCall: false });
        setCallRejected(false);
      }, 1500);
    });
    // peer.on("message",(msg)=>{

    //     setResponse(msg)
    // })
    peer.signal(call.signal);

    connectionRef.current = peer;
  };

  const msgUser = (id, msg, otherName) => {
    socket.emit("msguser", {
      userToMsg: id,
      msg: msg,
      from: me.current,
      name: otherName,
    });
    // socket.on("msguser",({msg,from,name})=>{
    //     setResponse(name +":"+msg)
    // })
  };

  const callUser = (id, callName) => {
    setName(callName);
    var peer = new Peer({
      initiator: true,
      trickle: false,
      stream: activeStreamRef.current,
    });
    peer.on("signal", (data) => {
      console.log("Caller Signal!", data);
      if (isInCall.current === false) {
        ringSound.current = false;
        socket.emit("calluser", {
          userToCall: id,
          signalData: data,
          from: me.current,
          name: user.name,
        });
        setCallAccepted(true);
        setCallEnded(false);
        isInCall.current = true;
      }
    });

    peer.on("stream", (currentStream) => {
      console.log("Caller Stream!");
      isInCall.current = true;
      ringSound.current = false;
      // remoteStreamRef.current = currentStream;
      remoteStreamRef.current = new MediaStream(currentStream);
      userVideo.current.srcObject = remoteStreamRef.current;
    });

    socket.on("callaccepted", (signal) => {
      console.log("Caller Accepted Call!");
      ringSound.current = false;
      isInCall.current = true;
      setCallAccepted(true);
      setCallEnded(false);

      if (peer.destroyed) {
        console.log(id, "peer destroyed");
        return callUser(id, callName);
      }
      console.log(signal);
      peer.signal(signal);
      ringSound.current = false;
      setCall({ isRecievedCall: false });
    });

    socket.on("callrejected", () => {
      console.log("Caller Rejected Call!");
      isInCall.current = false;
      ringSound.current = false;
      setCallAccepted(false);
      setCallRejected(true);
      setCallEnded(true);
      setTimeout(() => {
        ringSound.current = false;
        setCall({ isRecievedCall: false });
        setCallRejected(false);
      }, 1500);
    });

    socket.on("userleft", () => {
      console.log("User Left Call!");
      isInCall.current = false;
      ringSound.current = false;
      setCallAccepted(false);
      setCallRejected(true);
      setCallEnded(true);
      setTimeout(() => {
        ringSound.current = false;
        setCall({ isRecievedCall: false });
        setCallRejected(false);
      }, 1500);
    });
    peer.on("userleft", () => {
      console.log("Reciever Left Call!");
      isInCall.current = false;
      ringSound.current = false;
      setCallAccepted(false);
      setCallRejected(true);
      setCallEnded(true);
      setTimeout(() => {
        ringSound.current = false;
        setCall({ isRecievedCall: false });
        setCallRejected(false);
      }, 1500);
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {
    console.log("Left Call");
    connectionRef.current.emit("userleft", { to: call.from });
    setCallEnded(true);
    isInCall.current = false;
    setCallAccepted(false);
    connectionRef.current.destroy();
    connectionRef.current = null;
    ringSound.current = false;
    setCall({ isRecievedCall: false });
  };

  return (
    <SocketContext.Provider
      value={{
        call,
        callAccepted,
        callRejected,
        myVideo,
        userVideo,
        stream: activeStreamRef.current,
        name,
        setName,
        mute,
        setMute,
        videoOff,
        setVideoOff,
        callEnded,
        me: me.current,
        callUser,
        leaveCall,
        answerCall,
        rejectCall,
        response,
        setResponse,
        msgUser,
        onMyVideo,
        offMyVideo,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
