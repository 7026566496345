import React, { useState, useEffect, useContext } from "react";
import "../../styles/EditLocationTabMob.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import countries from "../Countries.json";


const EditLocationTabMob = (props) => {

    //useState
    var navigate = useNavigate();
    const { user, setUser} = useContext(UserContext);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState(""); 
    const [pincode, setPincode] = useState("");
    const [whatsAppNumber,setWhatsAppNumber] = useState("");
    const [linkedInUrl,setLinkedInUrl] = useState("");
    const [instagramUrl,setInstagramUrl] = useState("");
    const [mapLatitude,setMapLatitude] = useState("");
    const [mapLongitude,setMapLongitude] = useState("");
    const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [mobile, setMobile] = useState("");
const [dob, setDob] = useState(new Date());
const [gender, setGender] = useState("");
const [profession,setProfession] = useState("");
const [principles,setPrinciples] = useState("");
const [mission,setMission] = useState("");
const [vision,setVision] = useState("");
const [introduction,setIntroduction] = useState("");
  
  useEffect(()=>{
    if(user){
        if(props.userData){
     //console.log(props.userData)
     setAddress(props.userData.address);
     setCountry(props.userData.country);
     setState(props.userData.state);
     setCity(props.userData.city);
     setPincode(props.userData.pincode);
     setWhatsAppNumber(props.userData.whatsAppNumber);
     setLinkedInUrl(props.userData.linkedInUrl);
     setInstagramUrl(props.userData.instagramUrl);
     setMapLatitude(props.userData.mapLatitude);
     setMapLongitude(props.userData.mapLongitude);

         setDob(props.userData.dob && props.userData.dob.substring(0,10));
         setEmail(props.userData.email);
         setName(props.userData.name);
         setGender(props.userData.gender);
         setMobile(props.userData.phone);
         setProfession(props.userData.profession);
         setIntroduction(props.userData.introduction);
         setVision(props.userData.vision);
         setMission(props.userData.mission);
         setPrinciples(props.userData.principles);
        }
    }
},[props.userData])

const handleUpdateData = () =>{
 
  if(country === undefined||country.length === (""||0) ){
    return alert("Please Select your Country !!")
  }
  if(state === undefined ||state.length === (""||0)){
    return alert("Please Enter State !!")
  }
  if(city === undefined|| city.length === (""||0)){
    return alert("Please Enter City !!")
  }
    props.updateData({
        country:country,
        state:state,
        city:city,
        address:address,
        pincode:pincode,
        whatsAppNumber:whatsAppNumber,
        linkedInUrl:linkedInUrl,
        instagramUrl:instagramUrl,
        mapLatitude:mapLatitude,
        mapLongitude:mapLongitude,
    })
    props.selectedTab("billing");
}

  return (
    <div>
     <div id="location_tab">
	<img id="holder_mob_eit_page_location" src="holder_mob_eit_page_location.png" srcset="holder_mob_eit_page_location.png 1x" />
		
	<img id="holder_down_locatiom_mob_eit_p" src="holder_down_locatiom_mob_eit_p.png" srcset="holder_down_locatiom_mob_eit_p.png 1x" />
		
	<div id="top_grp_heading_location" >
		<div id="Generallbl_profile_mob_locatio" onClick={()=>props.selectedTab("general")}>
			<span>General</span>
		</div>
		<img id="dossier_lbl_profile_mob_locati" src="dossier_lbl_profile_mob_locati.png" srcset="dossier_lbl_profile_mob_locati.png 1x" onClick={()=>props.selectedTab("general")}/>
			
		<div id="Location_lbl_profile_mob_locat">
			<span>Location</span>
		</div>
		<img id="placeholder_lbl_profile_mob_lo" src="placeholder_lbl_profile_mob_lo.png" srcset="placeholder_lbl_profile_mob_lo.png 1x" />
			
		<div id="Payment_Details_lbl_profile_mo" onClick={()=>handleUpdateData()}>
			<span>Business</span>
		</div>
		<img id="card_lbl_profile_mob_location" src="card_lbl_profile_mob_location.png" srcset="card_lbl_profile_mob_location.png 1x"  onClick={()=>handleUpdateData()}/>
			
	</div>

	<div id="next_btn_mob_location" onClick={()=>handleUpdateData()}>
		<svg class="add_bg_Add_Tax_Authority">
			<rect id="add_bg_Add_Tax_Authority" rx="8" ry="8" x="0" y="0" width="109" height="36">
			</rect>
		</svg>
		<div id="Active_lbl_Add_Tax_Authority">
			<span>Next</span>
		</div>
	</div>
	<div id="Scrolllocation_tab">
		<div id="address_grp_mob_location">
			<textarea class="address_input_lbl_profile_mob_" value={address || ""}
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}/>
			
			<div id="Address_lbl_profile_mob_locati">
				<span>Address</span>
			</div>
		</div>

		<div id="pincode_grp_mob_location">
			<input class="pincode_bdr_input_mob_location" value={pincode || ""}
                              onChange={(e) => {
                                setPincode(e.target.value);
                              }}/>
			
			<div id="Pincode_lbl_lbl_profile_pmob_l" >
				<span>Pincode</span>
			</div>
		</div>
		<div id="city_grp_mob_location">
			<input class="city_input_profile_mob_locatio"  value={city}
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}/>
			
			<div id="City_lbl_profile_mob_location">
				<span>City</span>
			</div>
		</div>
		<div id="state_grp_mob_location">
			<input class="state_drop_lbl_profile_mob_loc" value={state}
                              onChange={(e) => {
                                setState(e.target.value);
                              }}/>
				
			
			<div id="State_lbl_profile_mob_location">
				<span>State</span>
			</div>
		</div>
		<div id="country_mob_location">
			<select class="country_drop_lbl_profile_mob_l" value={country} onChange={(e) => {setCountry(e.target.value); }}>
      {countries &&
                                  countries.map((country) => (
                                    <option value={country.name}>
                                      {country.name}
                                    </option>
                                  ))}
			</select>
			<div id="Country_lbl_profile_mob_locati">
				<span>Country</span>
			</div>
		</div>
    <input class="whatsapp_input_edit_profile_mo" value={whatsAppNumber}
                              onChange={(e) => {
                                setWhatsAppNumber(e.target.value);
                              }}/>
		
		<div id="whats_app_number_lbl_edit_mob">
			<span>WhatsApp Number</span>
		</div>
		<input class="linked_in_edit_profile_mob" value={linkedInUrl}
                              onChange={(e) => {
                                setLinkedInUrl(e.target.value);
                              }}/>
		
		<div id="linked_in_lbl_edit_profile_mob">
			<span>LinkedIn URL</span>
		</div>
		<input class="insta_url_box_edit_profile_mob" value={instagramUrl}
                              onChange={(e) => {
                                setInstagramUrl(e.target.value);
                              }}/>
			
		<div id="insta_url_lbl_edit_profile_mob">
			<span>Instagram URL</span>
		</div>
		<div id="socials_lbl_edit_profile_mob_e">
			<span>Social </span><span style={{fontStyle:"normal",fontWeight:"normal"}}>(Optional)</span>
		</div>
		<img id="social_img_edit_profile_mob" src="social_img_edit_profile_mob.png" srcset="social_img_edit_profile_mob.png 1x" />
			
		<div id="longitude_grp_mob_location">
			<input class="longi_bdr_input_mob_location" value={mapLongitude || ""}
                              onChange={(e) => {
                                setMapLongitude(e.target.value);
                              }} />
			
			<div id="longitudelbl_lbl_profile_pmob_">
				<span>Longitude</span>
			</div>
		</div>
		<div id="latitude_grp_mob_location">
			<input class="latitude_input_profile_mob_loc" value={mapLatitude || ""}
                              onChange={(e) => {
                                setMapLatitude(e.target.value);
                              }} />
			
			<div id="latitude_lbl_profile_mob_locat">
				<span>Latitude</span>
			</div>
		</div>
    </div>
</div>
    </div>
  )
}

export default EditLocationTabMob
