import React, { useContext, useState, useEffect } from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { SocketContext } from "../SocketContext";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicOffIcon from "@mui/icons-material/MicOff";
import "../styles/VideoPlayer.css"
import { Assignment, ChatSharp, Phone, PhoneDisabled } from "@material-ui/icons";
import MicIcon from "@mui/icons-material/Mic";
import { io } from "socket.io-client";
import { useSearchParams } from 'react-router-dom';
import Peer from "simple-peer";
import { UserContext } from "./UserContext";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DataGrid, GridToolbar} from '@mui/x-data-grid'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Link,ListItem,ListItemButton,List,IconButton} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { ListItemIcon } from '@material-ui/core'

const style5 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};




const useStyles = makeStyles((theme) => ({
  video: {
    width: "350px",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        width: "250px",
      },
    },
  },
  gridContainer: {
    justifyContent: "center",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        flexDirection: "column",
      },
    },
  },
  paper: {
    padding: "10px",
    border: "2px solid black",
    margin: "0px",
  },
}));

const socket = io(process.env.REACT_APP_API_URL
  , {
  reconnect:true,
  autoConnect:true,
  reconnectionDelay: 1000,
  reconnectionDelayMax : 5000,
  reconnectionAttempts: Infinity      
}
);

const VideoPlayer = () => {
  const {
    me,
    name,
    callAccepted,
    myVideo,
    userVideo,
    callEnded,
    stream,
    call,
    mute,
    setMute,
    videoOff,
    setVideoOff,
    callUser,
    leaveCall,
    response,
    answerCall,
    setResponse,
    msgUser
  } = useContext(SocketContext);
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [idToCall, setIdToCall] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState([]);
  const [status,setStatus] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [ availableMeetings,setAvailableMeetings] = useState([]);
  const [individualMeetings,setIndividualMeetings] = useState([]);
  const [openMeetingModel,setOpenMeetingModel] = useState(false);
  const [meetingTitle,setMeetingTitle] = useState("");
  const [agenda,setAgenda] = useState("");
  const [meetingRemarks,setMeetingRemarks] = useState("");
  const [meetingDateTime,setMeetingDateTime] = useState(new Date().toISOString().substring(0,16));
  const [previousRemarks,setPreviousRemarks] = useState("");
  const [requesterId,setRequesterId]= useState("");
  const [meetingId,setMeetingId] = useState("");
  const [callerName,setCallerName] = useState("");
  const [msg,setMsg] = useState("");
  const [prevResponse,setPrevResponse] = useState("");
  const [otherUserId,setOtherUserId] = useState("");
  const [otherUserName,setOtherUserName] = useState("");
  const [openDrawer,setOpenDrawer] = useState(false);
  const [openDrawer2,setOpenDrawer2] = useState(true);

  useEffect(() => {
    if(user){
    
      findOnlineUser();
      getMeetings()
    }
    
    setInterval(() => findOnlineUser(), 15000);
  }, [user]);

  
  useEffect(() => {
   setPrevResponse(prevResponse+"\n"+response)
  }, [response]);

  useEffect(() => {
    
    if(searchParams.get('meetingid')){
      getParticularMeetings(searchParams.get('meetingid'))
    }

    return ()=>{
      stream.getTracks().forEach(track => track.stop())
    }
   }, []);

//Getting Perticular meeting by meeting ID
const getParticularMeetings = (id)=>{

  let apiURL = process.env.REACT_APP_API_URL + "/meetings/getmeetings/" + id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          console.log(repos.data)
          openScheduler(repos.data)
          //setIndividualMeetings(repos.data)
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};

 



///To get all meetings available for the logged in user
const getMeetings = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/meetings/getallmeetings/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          console.log(repos.data)
          setAvailableMeetings(repos.data)
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};

  const findOnlineUser = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/status/onlinestatus/"+user._id;

    fetch(apiURL)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
       
          setOnlineUsers(repos.data.filter(rec=>(rec.mobile!=user.mobile.substring(user.mobile.length-10))));
        } else alert(repos.message || repos);
      })
      .catch((err) => console.log(err));
  };


  const isUserOnline = (id,name) => {
    setOpenMeetingModel(false)
    let apiURL = process.env.REACT_APP_API_URL + "/status/isonline/"+id;

    fetch(apiURL)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          
          callUser(repos.data[0].socketId,name)
         
        } else alert( name + " is not Online, please try after sometime!!");
      })
      .catch(() => alert(name + " is not Online, please try after sometime!!"));
  };

  const start = () => {
    recordScreen();

    // let node = document.createElement("p");
    // node.textContent = "Started recording";
    // document.body.appendChild(node);
  };

  const stop = () => {
    mediaRecorder && mediaRecorder.stop();

    // let node = document.createElement("p");
    //  node.textContent = "Stopped recording";
    // document.body.appendChild(node);
  };

  const mergeAudioStreams = (localStream, remoteStreams = false) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasVoice = false;
    let hasRemote = false;

    if (localStream && localStream.getAudioTracks().length > 0) {
      const source1 = context.createMediaStreamSource(localStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source1.connect(voiceGain).connect(destination);
      hasVoice = true;
    }
    
    remoteStreams.forEach((stream, peerId) => {
      if (stream && stream.getAudioTracks().length > 0) {
        const source2 = context.createMediaStreamSource(stream);
        const voiceGain = context.createGain();
        voiceGain.gain.value = 0.7;
        source2.connect(voiceGain).connect(destination);
        hasRemote = true;
      }
    });
    // console.log(hasVoice,hasRemote)
    return hasVoice || hasRemote ? destination.stream.getAudioTracks() : [];
  };

  function recordScreen() {
    let audioTrack, videoTrack, stream;

    [audioTrack] = mergeAudioStreams(myVideo.current.srcObject, [
      userVideo.current.srcObject,
    ]);

    navigator.mediaDevices
      .getDisplayMedia({ audio: true, video: true, preferCurrentTab: true })
      .then((displayStream) => {
        [videoTrack] = displayStream.getVideoTracks();
        stream = new MediaStream([videoTrack, audioTrack]);
        setMediaRecorder(createRecorder(stream));
      })
      .catch((e) => console.log(e));
  }


  function createRecorder(stream) {
    // the stream data is stored in this array
    let recordedChunks = [];

    const mediaRecorderNew = new MediaRecorder(stream);

    mediaRecorderNew.ondataavailable = function(e) {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    };

    mediaRecorderNew.onstop = function() {
      saveFile(recordedChunks);
      recordedChunks = [];
    };
    mediaRecorderNew.start(); // For every 200ms the stream data will be stored in a separate chunk.
    return mediaRecorderNew;
  }

  const closeMeetingModel = () => setOpenMeetingModel(false);

  const setCloseMeetingModel = ()=>{
    setOpenMeetingModel(false);
  }
  

  const ScheduleMeeting = (meetingId,status) =>{
    setOpenMeetingModel(false);
    let apiURL=process.env.REACT_APP_API_URL + "/meetings/updatemeetings/"+ meetingId;
      let apiParams = {
          method:"POST",
          headers:{
            meetingtitle:meetingTitle,
            meetingdatetime:meetingDateTime,
            agenda:encodeURIComponent(agenda),
            remarks:encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+meetingRemarks+"\n"+(previousRemarks?previousRemarks:"")+"\n"),
            status:status,
          },
         
      };
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
          
            alert("Meeting Updated Successfully");
            getMeetings()
          
          
          }else alert(repos.message || repos);
      }) 
      .catch((err) => alert(err));
  
   }

  const openScheduler = (data)=>{
   
    if(data.requesterId!==user._id){
      setIdToCall(data.requesterId)
      setCallerName(data.requesterName)
      
    }else if(data.requesterId===user._id && data.respondentId!==user._id){
      setIdToCall(data.respondentId)
      setCallerName(data.respondentName)
      
    }
   
    setStatus(data.status)
    setMeetingId(data._id)
    setMeetingTitle(data.meetingTitle);
    setRequesterId(data.requesterId);
    setMeetingDateTime(data.meetingDateTime.substring(0,16));
    setAgenda(data.agenda);
    setPreviousRemarks(data.remarks);
    setMeetingRemarks("");
    setOpenMeetingModel(true)
  }



  const Chats = ()=>{
    msgUser(otherUserId,msg,otherUserName)
  }





  // const RetrieveResume = (data)=>{

  //   let apiURL=process.env.REACT_APP_API_URL + "/applicants/saveResumeUrl/" + applicantId;
  //   let apiParams = {
  //       method:"POST",
  //       headers:{
  //         resumeurl:data.url,
  //       }
  // }
  // fetch(apiURL, apiParams)
  // .then((res)=> (res.status===200 ? res.json() : res.text()))
  // .then((repos)=> {
  //     if (repos.data) {
  //     setResumeUrl(data.url)
  //     }
  //   })
  // // }
  // function blobToFile(theBlob, fileName){
  //   //A Blob() is almost a File() - it's just missing the two properties below which we will add
  //   theBlob.lastModifiedDate = new Date();
  //   theBlob.name = fileName;
  //   return theBlob;
  // }

  function saveFile(recordedChunks) {
    const blob = new Blob(recordedChunks, {
      type: "video/mp4",
    });

    // let confirm = window.confirm(
    //   "Do you want to save in google Drive else it will be saved in your local system ??"
    // );

    // if (!confirm) {
      var file = window.prompt("Enter File Name") //the file
      let type = blob.type;
const buffer =  blob.arrayBuffer();
let bytes = new Int8Array(buffer);
let newLink = saveByteArray(file,blob );
          newLink.link.click();
// }else {
//       let filename = window.prompt("Enter File Name", ".mp4");
//       var reader = new FileReader(); //this for convert to Base64
//       reader.readAsDataURL(blob); //start conversion...
//       reader.onload = function() {
//         //.. once finished..
//         var rawLog = reader.result.split(",")[1]; //extract only thee file data part
//         var dataSend = {
//           dataReq: { data: rawLog, name: filename, type: blob.type },
//           fname: "uploadFilesToGoogleDrive",
//         }; //preapre info to send to API
//         fetch(
//           process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
//           { method: "POST", body: JSON.stringify(dataSend) }
//         ) //send to Api
//           .then((res) => res.json())
//           .then((data) => {
//             console.log(data);
//             if (data) {
//               alert("Video Saved to google drive Successfully!!!");
//             }
//           })
//           .catch((e) => console.log(e)); // Or Error in console
//       };
//     }
  }

  function saveByteArray(reportName, byte) {
    let blob = byte;
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
  
    let fileName = reportName;
    link.download = fileName;
  
    let newLink = {
      name: fileName,
      link: link,
    };
    return newLink;
  }



  return (
    <div style={{textAlign:"center"}}>
     {/* <div style={{float:"left",position:"absolute",left:"50px"}}> */}
      <Button className="rotate" variant="contained" sx={{ m: 1, width:'20ch',backgroundColor :"#14c871",float:"left" }} onClick={()=>setOpenDrawer(true)}>Meetings</Button>
      <Button className="rotate1" variant="contained" sx={{ m: 1, width:'20ch',backgroundColor :"#14c871",float:"right" }} onClick={()=>setOpenDrawer2(true)}>Online Status</Button>
      {/* </div> */}
      <Grid container className={classes.gridContainer}>
     
        {/* Our video */}
        {stream && (
          <Paper className={classes.paper}>
            <Grid item xs={12} md={12}>
              <Typography style={{width:"100%"}} variant="h5" gutterBottom>
                {user.name || "Name"}
              </Typography>

              <video
                id="my-video"
                playsInline
                muted 
                ref={myVideo}
                autoPlay
                className={classes.video}
              />
              {/* <div><Button variant="contained" color="secondary" startIcon={<MicOffIcon fontSize="large"/>} style={{width:"150px",height:"50px"}} value={mute} onClick={()=>setMute(!mute)} >UnMute Call</Button> 
            </div> */}
            </Grid>
          </Paper>
        )}

        {/* Users Video */}
        {callAccepted && !callEnded && (
          <Paper className={classes.paper}>
            <Grid item xs={12} md={12}>
              <Typography variant="h5" gutterBottom>
                {name || "Name"}
              </Typography>
              <video
                id="user-video"
                playsInline
                ref={userVideo}
                autoPlay
                className={classes.video}
              />
              {/* {console.log(userVideo)} */}
            </Grid>
          </Paper>
        )}

        <br/>
        <br/>
        {/* <video width ="700" height="400" controls></video> */}
      </Grid>
      <div style={{ textAlign: "center", flexDirection: "column" }}>
        {callAccepted && !callEnded ? (
          <Button
            style={{
              width: "50px",
              borderRadius: "25px",
              margin: "5px",
              alignSelf: "center",
              color: "white",
              backgroundColor: "red",
            }}
            variant="contained"
            color="secondary"
            startIcon={<PhoneDisabled fontSize="large" />}
            fullWidth
            onClick={leaveCall}
            title="Hang Up"
            className={classes.margin}
          ></Button>
        ) : ""
        /* (
          <Button
            style={{
              width: "50px",
              borderRadius: "25px",
              margin: "5px",
              alignSelf: "center",
              color: "white",
              backgroundColor: "green",
            }}
            variant="contained"
            color="primary"
            startIcon={<Phone fontSize="large" />}
            fullWidth
            onClick={() => callUser(idToCall)}
            title="Call"
            className={classes.margin}
          ></Button>
        ) */
        }
        {/* <CopyToClipboard text={me} className={classes.margin1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            title="Copy ID"
            startIcon={<Assignment fontSize="large" />}
            style={{
              width: "50px",
              borderRadius: "25px",
              margin: "5px",
              alignSelf: "center",
              color: "white",
              backgroundColor: "green",
            }}
          ></Button>
        </CopyToClipboard> */}
        {mute ? (
          <Button
            style={{
              width: "50px",
              borderRadius: "25px",
              margin: "5px",
              alignSelf: "center",
              color: "white",
              backgroundColor: "red",
            }}
            variant="contained"
            color="secondary"
            startIcon={<MicOffIcon fontSize="large" />}
            fullWidth
            value={mute}
            title="Mic Off"
            onClick={() => setMute(false)}
            className={classes.margin}
          ></Button>
        ) : (
          <Button
            style={{
              width: "50px",
              borderRadius: "25px",
              margin: "5px",
              alignSelf: "center",
              color: "red",
              backgroundColor: "white",
            }}
            variant="contained"
            color="secondary"
            startIcon={<MicIcon fontSize="large" />}
            fullWidth
            value={mute}
            title="Mic On"
            onClick={() => setMute(true)}
            className={classes.margin}
          ></Button>
        )}
        {videoOff ? (
          <Button
            style={{
              width: "50px",
              borderRadius: "25px",
              margin: "5px",
              alignSelf: "center",
              color: "white",
              backgroundColor: "red",
            }}
            variant="contained"
            color="primary"
            startIcon={<VideocamOffIcon fontSize="large" />}
            fullWidth
            value={videoOff}
            title="Video Off"
            onClick={() => setVideoOff(!videoOff)}
            className={classes.margin}
          ></Button>
        ) : (
          <Button
            style={{
              width: "50px",
              borderRadius: "25px",
              margin: "5px",
              alignSelf: "center",
              color: "red",
              backgroundColor: "white",
            }}
            variant="contained"
            color="primary"
            startIcon={<VideocamIcon fontSize="large" />}
            title="Video On"
            fullWidth
            value={videoOff}
            onClick={() => setVideoOff(!videoOff)}
            className={classes.margin}
          ></Button>
        )}
         {callAccepted && !callEnded ? (
           <Button
          style={{
            width: "50px",
            borderRadius: "25px",
            margin: "5px",
            alignSelf: "center",
            color: "red",
            backgroundColor: "white",
          }}
          variant="contained"
          title="Start Recording"
          fullWidth
          onClick={() => start()}
        >
          <RadioButtonCheckedIcon />
        </Button>):""}
        {callAccepted && !callEnded ? (  
          <Button
          style={{
            width: "50px",
            borderRadius: "25px",
            margin: "5px",
            alignSelf: "center",
            color: "black",
            backgroundColor: "white",
          }}
          variant="contained"
          title="Stop Recording"
          fullWidth
          onClick={() => stop()}
        >
          <StopCircleIcon />
        </Button>):""}
      </div>
      
      {callAccepted && !callEnded ? ( <div style={{textAlign:"center",height:"100px",width:"100%",margin:"5px",border:"2px solid white",color:"black",overflowY:"scroll",position:"relative",backgroundColor:"white"}}>
      {prevResponse.split('\n').map(mes=>mes.length>0?<div>{mes.toString()}<br/></div>:"")}
      </div>):""}
      {callAccepted && !callEnded ? ( <div style={{ textAlign: "center", flexDirection: "column",height:"100%" }}>
      <TextField  id="msg" multiline rows={1}  sx={{ m: 1, width: '40ch', backgroundColor:"white" }} size="small" label="Enter your text" variant="outlined"  value={msg} onChange={(e)=> {
       setMsg(e.target.value);   
         }}/>
         <Button  variant="contained" sx={{ m: 1, width:'25ch',backgroundColor :"#14c871" }} onClick={()=>Chats()}>Send</Button>
      </div>):""}
   
      <div>
      </div>
      {/* <div
        style={{
          height: "80%",
          width: "250px",
          position: "absolute",
          top: "100px",
          right: "10px",
          backgroundColor: "white",
        }}
      >
      <table style={{width:"100%",padding:"10px"}}>
      <tr>
        <td colSpan={2}>
        <h3 style={{backgroundColor:"#14c871",width:"100%",color:"white",textAlign:"center"}}>Online Users</h3>{" "}
        </td>
      </tr>
        {onlineUsers.map((ou) => (
        
          <tr>
              <td style={{width:"100px"}}>
              <Button
              style={{
                width: "220px",
                margin: "5px",
                color: "white",
                backgroundColor: "green",
              }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() =>{ setOtherUserId(ou.socketId);setOtherUserName(ou.name);callUser(ou.socketId,ou.name)}}
              title="Call"
              className={classes.margin}
            >
              {user.name!=ou.name?ou.name:""}
            </Button>
              </td>
             </tr>
           
           ))}
        
        </table>
     
      </div> */}
      
      {/* <div
        style={{
          height: "80%",
          width: "300px",
          position: "absolute",
          top: "100px",
          left: "10px",
          backgroundColor: "white",
        }}
      >
      <h3 style={{backgroundColor:"#14c871",width:"100%",color:"white",textAlign:"center"}}>Meetings</h3>{" "}
        <div style={{height: "95%",overflowY:"scroll"}}>
         <table style={{width:"100%",padding:"10px"}}>

        {availableMeetings.map((off) => (
          <tr>
              <td style={{display:"block",width:"100%",border:"1px solid black",margin:"5px",color:"black",textAlign:"center"}} title={off.agenda} onClick={()=>openScheduler(off)}>
              <div>{off.meetingTitle?off.meetingTitle:""} &nbsp;&nbsp;
                
              </div>
              <div> Status: {off.status}</div>
              <div>
             Date : {off.meetingDateTime?off.meetingDateTime.split("T")[0]+"  "+"Time:"+off.meetingDateTime.split("T")[1].slice(0,5):0}
              </div>
              </td>
      </tr>
           ))}
        
        </table>
        </div>
      </div> */}


  {/* Drawer For Meetings */}
  <React.Fragment>
      <Drawer open={openDrawer} anchor='left'
      onClose={()=> setOpenDrawer(false)}
      >
        <List style={{width:"250px"}} onClick={()=> setOpenDrawer(false)}>
        <ListItemIcon>
      <div>
      <h3 style={{backgroundColor:"#14c871",width:"100%",color:"white",textAlign:"center"}}>Meetings</h3>{" "}
        <div style={{height: "95%",overflowY:"scroll"}}>
         <table style={{width:"100%",padding:"10px"}}>

        {availableMeetings.map((off) => (
          <tr>
              <td style={{display:"block",width:"100%",border:"1px solid black",margin:"5px",color:"black",textAlign:"center"}} title={off.agenda} onClick={()=>openScheduler(off)}>
              <div>{off.meetingTitle?off.meetingTitle:""} &nbsp;&nbsp;
                
              </div>
              <div> Status: {off.status}</div>
              <div>
             Date : {off.meetingDateTime?off.meetingDateTime.split("T")[0]+"  "+"Time:"+off.meetingDateTime.split("T")[1].slice(0,5):0}
              </div>
              </td>
      </tr>
           ))}
        
        </table>
        </div>
        </div>
            </ListItemIcon> 
        </List>
      </Drawer>
      {/* <IconButton sx={{color:"#058044",marginLeft:"auto"}} onClick={()=> setOpenDrawer(!openDrawer)}>
      <MenuIcon/>
      </IconButton> */}
    </React.Fragment>


  {/* Drawer For Online Status */}
  <React.Fragment>
      <Drawer open={openDrawer2} anchor='right'
      onClose={()=> setOpenDrawer2(false)}
      >
        <List style={{width:"230px"}} onClick={()=> setOpenDrawer2(false)}>
        <ListItemIcon>
      <div>
      <h3 style={{backgroundColor:"#14c871",width:"250px",color:"white",textAlign:"center"}}>Online Users</h3>{" "}
      <table style={{width:"100%"}}>
        {(onlineUsers||[]).map((ou) => (
          <tr>
              <td style={{width:"100px"}}>
              <Button
              style={{
                width: "220px",
                margin: "5px",
                color: "white",
                backgroundColor: "green",
              }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() =>{ setOtherUserId(ou.socketId);setOtherUserName(ou.name);callUser(ou.socketId,ou.name)}}
              title="Call"
              className={classes.margin}
            >
              {user.name!=ou.name?ou.name:""}
            </Button>
              </td>
             </tr>
           
           ))}
        
        </table>
        </div>
            </ListItemIcon> 
        </List>
      </Drawer>
    </React.Fragment>


    {/* Pop up for schedule meeting */}
    <Modal
         open={openMeetingModel}
        onClose={closeMeetingModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
          <Box sx={style5}>
         
         <Typography id="modal-modal-description">
         <Container style={{padding:"0px"}}>
         <div className = "content6">
       <div style={{alignItems:"center"}}>
         <h2 className="fnt2"><b>Meeting Request</b></h2>
         <Button title="Close" variant="Standard" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,color:"white" }} onClick={()=>setCloseMeetingModel()} ><CloseIcon /></Button>   
         </div>
       <TextField  id="title" multiline rows={1}  sx={{ m: 1, width: '35ch' }} size="small" label="Meeting Title" variant="outlined" disabled={requesterId!==user._id?true:false}  value={meetingTitle} onChange={(e)=> {
       setMeetingTitle(e.target.value);   
         }}/>

         <label for="MeetingTime">Meeting On:</label>
        <input type="datetime-local" id="MeetingTime" format="dd-mm-yyyy" disabled={requesterId!==user._id?true:false} value={meetingDateTime} onChange={(e)=>setMeetingDateTime(e.target.value)}/>

          <TextField  id="Agenda" disabled={requesterId!==user._id?true:false} multiline rows={3}  sx={{ m: 1, width: '35ch' }} size="small" label="Agenda" variant="outlined"  value={agenda} onChange={(e)=> {
       setAgenda(e.target.value);   
         }}/>
         <TextField  id="PreviousRemarks" multiline rows={3}  sx={{ m: 1, width: '35ch' }} size="small" label="PreviousRemarks" variant="outlined" disabled={requesterId!==user._id?true:false}  value={previousRemarks}/>
        <TextField  id="remarks" multiline rows={2}  sx={{ m: 1, width: '35ch' }} size="small" label="Enter Remarks" variant="outlined"  value={meetingRemarks} onChange={(e)=> {
          setMeetingRemarks(e.target.value);  
         }}/>
         <br/>
           
     {((requesterId!==user._id) && status ==="Requested") ?<Button  variant="contained" sx={{ m: 1, width:requesterId!==user._id?'10ch':'35ch',backgroundColor :"#14c871" }} onClick={()=>ScheduleMeeting(meetingId,"Accepted")}>Accept</Button>:""}          
     {((requesterId!==user._id) && status ==="Requested")?<Button  variant="contained" sx={{ m: 1, width:requesterId!==user._id?'10ch':'35ch',backgroundColor :"#14c871" }} onClick={()=>ScheduleMeeting(meetingId,"Rejected")}>Reject</Button>:""}      
     {status ==="Accepted"?<Button  variant="contained" sx={{ m: 1, width:requesterId!==user._id?'15ch':'15ch',backgroundColor :"#14c871" }} onClick={()=>isUserOnline(idToCall,callerName)}>Call</Button>:""}      
    { status ==="Requested" ? <Button  variant="contained" sx={{ m: 1, width: requesterId===user._id?'15ch':'10ch',backgroundColor :"#14c871" }} onClick={()=>ScheduleMeeting(meetingId,"Requested")}>{requesterId===user._id?"Update":"Propose"}</Button>:""}   
     {(((requesterId===user._id) && status ==="Requested")||status ==="Accepted")?<Button  variant="contained" sx={{ m: 1, width: requesterId==user._id?'15ch':'15ch',backgroundColor :"#14c871" }} onClick={()=>ScheduleMeeting(meetingId,"Cancelled")}>Cancel</Button>:""}   
      </div>
      </Container> 
          </Typography>
        </Box>
      </Modal>

    </div>
  );
};

export default VideoPlayer;
