import React, { useState, useEffect, useContext } from "react";
// import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider } from "@mui/material";
import "../../styles/UserInfoMobUI.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
import { UserContext } from "../UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { autocompleteClasses } from "@material-ui/core";
import countries from "../Countries.json";
import Currencies from "../Currencies.json";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ThemesSetUpMob from "./ThemesSetUpMob";

const UserInfoUI = (props) => {
 
  const label = { inputProps: { "aria-label": "Schedule Interview" } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule] = React.useState();
  const [searchParams] = useSearchParams();
  
  const { user, setUser } = useContext(UserContext);
  var navigate = useNavigate();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState(new Date());
  const [whatsNumber, setWhatsNumber] = useState("");
  const [img,setImg]= useState("");
  const [gender, setGender] = useState("");
  const [open, setOpen] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportingToId, setReportingToId] = useState("");
  const [reportingToName, setReportingToName] = useState("");
  const [accessType, setAccessType] = useState("");
  const [country, setCountry] = useState("");
  const [hourlyRate,setHourlyRate] = useState("");
  const [currency, setCurrency] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [profession,setProfession] = useState("");
  const [active, setActive] = useState(true);
  const [confirmPass, setConfirmPass] = useState("");
  const [password, setPassword] = useState("");
  const [pincode, setPincode] = useState("");
  const [introduction,setIntroduction] = useState("");
  const [openChgPwd, setOpenChgPwd] = React.useState(false);
  const [previousPass,setPreviousPass]= useState("");
  const [nickName,setNickName] = useState("");
  const [connections, setConnections] = useState(0);
  const [followers, setFollowers] = useState(0);
  const [bannerImage,setBannerImage] = useState("");
  const [bannerPic,setBannerPic] = useState("");
  const [openThemesSelection,setOpenThemesSelection] = useState(false)
  const [selectedTheme,setSelectedTheme] = useState("")

  // const [filterModel, setFilterModel] = React.useState({
  //   items: [
  //     {columnField: '', operatorValue: 'contains', value:user.name}
  //   ],
  // });

  const cols = [
    { field: "id", headerName: "id", hide: true },
    { field: "name", headerName: "Name", width: 190 },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      renderCell: (params) => {
        return [
          params.row.gender
            ? params.row.gender.substring(0, 1).toUpperCase() +
              params.row.gender.substring(1).toLowerCase()
            : "",
        ];
      },
    },
    { field: "reportingToName", headerName: "Reporting To", width: 190 },
    {
      field: "accesstype",
      headerName: "AccessType",
      width: 100,
      renderCell: (params) => {
        switch (params.row.accesstype) {
          case "0":
            return ["Guest"];

          case "1":
            return ["Employee"];

          case "2":
            return ["Supervisor"];

          case "3":
            return ["Manager"];

          case "4":
            return ["Director"];

          case "5":
            return ["Admin"];

          default:
            return [""];
        }
      },
    },
    { field: "mobile", headerName: "Mobile No", width: 140 },

    { field: "email", headerName: "Email", width: 200 },
    {
      field: "active",
      headerName: "Active",
      width: 100,
      renderCell: (params) => {
        return [<input type="checkbox" checked={params.row.active} />];
      },
    },
  ];

  useEffect(() => {
    //addEmployee();
    if(props.profile)
    {
      console.log(props.profile)
      props.profile._id && getUserProfile();
    }
    else if(user._id)
    {
      getUserProfile()
    }
    
  }, [user,props.profile]);

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }


  // function handleFileSelect(f) {
  //   var reader = new FileReader();
  //   // Closure to capture the file information.
  //   reader.onload = (function (theFile) {
  //     return function (e) {
  //       var binaryData = e.target.result;
  //       //Converting Binary Data to base 64
  //       var base64String = window.btoa(binaryData);
  //       //showing file converted to base64
  //       setImg("data:image/jpeg;base64," + base64String);
  //     };
  //   })(f);
  //   // Read in the image file as a data URL.
  //   reader.readAsBinaryString(f);
  // }

  // const handleImageUpload = async (e) => {
  //   if (e.target.files[0].size > 71680) {
  //     alert("Please choose a file with size below 70kb!");
  //     return;
  //   }
  //   handleFileSelect(e.target.files[0]);
  //   let formdata = new FormData();
  //   formdata.append("image", e.target.files[0], e.target.value);
  //   updateProfilePic(formdata);
  // };
  // const updateProfilePic = (formdata) => {
  //   const apiurl = process.env.REACT_APP_API_URL + "/users/updateprofilepic/"+user._id;
  //   var apiparam = {
  //     method: "POST",
  //     // headers: {
  //     //   id: user._id,
  //     // },
  //     body:formdata,
  //   };

  //   fetch(apiurl, apiparam)
  //     .then((res) => (res.status === 200 ? res.json() : res.text()))
  //     .then((repos) => {
  //       if (repos.data) {
  //         alert("Image Uploaded Successfully!!");
  //       } else alert(repos.message || repos);
  //     })
  //     .catch((err) => alert(err));
  // };
  
  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setBannerPic("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }
  const handleImageUpload = async (e) => {
    // if (e.target.files[0].size > 71680) {
    //   alert("Please choose a file with size below 70kb!");
    //   return;
    // }
    handleFileSelect(e.target.files[0]);
    await UploadImageToDrive(e.target.files[0])
    // let formdata = new FormData();
    // formdata.append("image", e.target.files[0], e.target.value);
    // updateProfilePic(formdata);
  };


  //Upload Image
  const UploadImageToDrive = async (document) =>{
     
    var file = document //the file
    var reader = new FileReader() //this for convert to Base64 
    reader.readAsDataURL(document) //start conversion...
    reader.onload = function (e) { //.. once finished..
      var rawLog = reader.result.split(',')[1]; //extract only thee file data part
      var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
     fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
        { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
        .then(res => res.json()).then((data) => {
          //See response
        
           SaveProfilePic(data)
          //console.log(data.url)
          //  var loggedIn = JSON.parse(localStorage.getItem("loggedInUser"));
          //  loggedIn.imageId = (data.url).split('/')[5]
          //   localStorage.setItem("loggedInUser",JSON.stringify(loggedIn));
          //   setUser(loggedIn)
          // alert("Image Uploaded Successfully","success")
  
        }).catch(err => console.log(err)) // Or Error in console
    }
  }

  const SaveProfilePic = (data)=>{
    
    const apiurl = process.env.REACT_APP_API_URL + "/users/updatebannerpicnew/"+user._id;
            let apiParams = {
                method:"POST",
                headers:{
                  bannerimageurl:data.url,
                
                }
          }
          fetch(apiurl, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
               
                alert("Image Uploaded Successfully");
              }
            })
            .catch(e => console.log(e)) 
          }


  const getUserProfile = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/getUserDetails/"+(props.profile?props.profile._id:user._id);
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          // console.log(repos.data);
          setEmployees(repos.data);
          setAddress(repos.data.address);
          setDob(repos.data.dob ?(repos.data.dob.substring(0,10)):new Date());
          setEmail(repos.data.email?repos.data.email:"");
          setName(repos.data.name);
          setGender(repos.data.gender);
          setMobile(repos.data.phone);
          setCountry(repos.data.country?repos.data.country:"");
          setState(repos.data.state?repos.data.state:"");
          setCity(repos.data.city?repos.data.city:"");
          setPincode(repos.data.pincode);
          setProfession(repos.data.profession);
          setIntroduction(repos.data.introduction);
          setSelectedTheme(repos.data.theme);
          // setImg(arrayBufferToBase64(repos.data.image.data.data));
          setImg(repos.data.imageUrl);
          setBannerImage(repos.data.bannerImageUrl);
          setCurrency(repos.data.currency);
          setHourlyRate(repos.data.hourlyRate);
          setNickName(repos.data.nickName);
          setConnections(repos.connections);
          setFollowers(repos.followers);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const UpdateEmployeesDetails = (id) => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/updateEmployees/" + user._id;
    let apiParams = {
      method: "POST",
      headers: {
              name: name,
              gender: gender,
              dob: dob,
              mobile: mobile,
              email: email,
              country: country,
              state: state,
              city: city,
              address: encodeURIComponent(address),
              pincode: pincode,
              profession:profession,
              introduction:encodeURIComponent(introduction),
              currency:currency,
              hourlyrate:hourlyRate,
        // password: encodeURIComponent(password),
        // active: active,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);
          alert("User Updated Successfully")
          // props.showAlert("User Updated Successfully", "success");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch((err) => alert(err));
  };

const PasswordChange = ()=>{
  if (password !== confirmPass) {
    alert("Password and Confirm Password don't match!!");
    return;
  }
  setOpenChgPwd(false);
  let apiURL = process.env.REACT_APP_API_URL + "/users/updatePassword/" + user._id;
  let apiParams = {
    method: "POST",
    headers: {
           previouspass:encodeURIComponent(previousPass),
           newpassword:encodeURIComponent(password),
           
    },
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
        setIsLoading(false);
        alert("Password Changed Successfully!!!")
        handlelogout()
      } else alert(repos);
    })
    .catch((err) => alert(err));

}

const handlelogout = () =>{
  localStorage.clear();
  setUser(null);
  window.location.href = "/login";
 
}

  const addEmployee = () => {
    setOpen(true);
  };


  const handleClosePwd = () => {
    setOpenChgPwd(false);
   
  };
  

  

  return (
    <div>

<div id="top_profile">
	<div id="over_all_bg_top_profile">
		<div id="top_bnner_mob_top_profile">
    {bannerPic.length === 0 ?<img id="banner_img_mob_top_profile" src={bannerImage?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(bannerImage.split('/')[5]):"banner_img.png"} />: 
			<img id="banner_img_mob_top_profile" src={bannerPic?bannerPic:"banner_img.png"}  />}
		</div>
		<div id="mid_circle_grp_profile_mob_top">
			<svg class="profil_info_bdr_mob_top_profil">
				<rect id="profil_info_bdr_mob_top_profil" rx="15" ry="15" x="0" y="0" width="100%" height="471">
				</rect>
			</svg>
			<img id="pfp_pic_mob_top_profile" src={img && img.length > 10 ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+img.split('/')[5]:"user_pfp_profile_page.png"} />
				
			<div id="Mohammed_Affan_mob_top_profile">
				<span>{name} ({nickName})</span>
			</div>
			<div id="location_grp_mob_top_profile">
				<div id="India_Karnataka_mob_top_profil">
					<span>{country+" , "+state +" , "+city}</span>
				</div>
				<img id="india_img_mob_top_profile" src="worldwide_logo_address.png" srcset="worldwide_logo_address.png 1x" />
					
			</div>
			<div id="skills_grp__mob_top_profile">
				<img id="skillmob_top_profile_" src="skillmob_top_profile_.png" srcset="skillmob_top_profile_.png 1x" />
					
				<div id="mob_top_profile_designer_skill">
					<span>{profession}</span>
				</div>
			</div>
			<div id="connections_grp_mob_top_profil">
				<svg class="all_con_mob_top_profile">
					<rect id="all_con_mob_top_profile" rx="15" ry="15" x="0" y="0" width="128" height="31">
					</rect>
				</svg>
				<svg class="mob_top_profile_circle_">
					<ellipse id="mob_top_profile_circle_" rx="12.5" ry="12.5" cx="12.5" cy="12.5">
					</ellipse>
				</svg>
				<div id="n_23454mob_top_profile">
					<span>{connections}</span>
				</div>
				<div id="Connections_lbl_mob_top_profil">
					<span>Connections</span>
				</div>
			</div>
			{/* <div title="Whatsapp" id="QR_btn_grp_mob_top_profile">
				<svg class="QR_circle_bdr_mob_top_profile">
					<ellipse id="QR_circle_bdr_mob_top_profile" rx="18" ry="18" cx="18" cy="18">
					</ellipse>
				</svg>
				<img id="QR_img_mob_top_profile" src="QR_img_mob_top_profile.png" srcset="QR_img_mob_top_profile.png 1x" />
					
			</div> */}

	  { props.profile && props.profile._id?"": 		<div id="profile_link_grp__mob_top_prof">
				<svg class="offer_circle_mob_top_profile">
					<ellipse id="offer_circle_mob_top_profile" rx="18" ry="18" cx="18" cy="18">
					</ellipse>
				</svg>
      
				<img id="url_img_mob_top_profile" src="web-design.png" srcset="web-design.png 1x" onClick={()=>setOpenThemesSelection(true)}/>
    
			</div>  
        }
			<div id="About_lbl_lorem_mob_top_profil">
				<span>About</span>
			</div>
			<div id="Scroll_Group_9_mob_top_profile">
				<div id="Lorem_ipsum_dolor_sit_amet_ui_mob">
					<span>{introduction}</span>
				</div>
			</div>
			<div id="followers_grp_lbl_mob_top_prof">
				<svg class="overall_mob_top_profile">
					<rect id="overall_mob_top_profile" rx="15" ry="15" x="0" y="0" width="113" height="31">
					</rect>
				</svg>
				<svg class="mob_top_profile_circle">
					<ellipse id="mob_top_profile_circle" rx="12.5" ry="12.5" cx="12.5" cy="12.5">
					</ellipse>
				</svg>
				<div id="n_23mob_top_profile">
					<span>{followers}</span>
				</div>
				<div id="Followers_lbl_mob_top_profile">
					<span>Followers</span>
				</div>
			</div>
			<div id="skills_grp_mob_top_profile">
				<img id="skill_img__mob_top_profile" src="internet_website.png" srcset="internet_website.png 1x" />
					
				<div id="UIUX_designer_skill_lbl_mob_to">
				<a href={"https://"+nickName+".operate.live"} target="_blank" ><span>https://{nickName.toLowerCase()}.operate.live</span></a>
				</div>
			</div>
		</div>
	</div>
  {/* { props.profile && props.profile._id?"":     <label for="image">
                        <input id="image" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
	<img id="pen_edit_ban_img" src="pen_edit_banner_img.png" srcset="pen_edit_banner_img.png 1x"/>
                      </label>
  } */}
  { props.profile && props.profile._id?"": 
    <label for="image">
                        <input id="image" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
                    
                      <img id="pen_edit_ban_img" width="100" height="100" src="pen_edit_banner_img_bw.png" />
                      </label>	}
			{/* <img id="pen_edit_banner_img_bw" src="pen_edit_banner_img_bw.png" srcset="pen_edit_banner_img_bw.png 1x" onClick={()=>alert("hi")}/> */}
	
  { props.profile && props.profile._id?"": 
	<img id="pen_edit_banner_img" src="pen_edit_banner_img.png" srcset="pen_edit_banner_img.png 1x"  onClick={()=>navigate("/editprofile")}/>
  }
</div>
     
<ThemesSetUpMob  openThemesSelection={openThemesSelection} theme={selectedTheme} closeThemesSelection={()=>{setOpenThemesSelection(false);getUserProfile()}}/>
    </div>
  );
};

export default UserInfoUI;
