import React from 'react';
import "../../../styles/GamersHubMob.css";

function GamersHubMob(props) {
  return (
    <div id="GamersHubMob">
	<div id="GamersHub_16_mobGroup_14998">
		<img id="GamersHub_16_mobMask_Group_1" src="./Themes/GamersHub/Mask_Group_1.png" srcset="./Themes/GamersHub/Mask_Group_1.png 1x" />
			
		<img id="GamersHub_16_mobefeb0ad6-be8c-4667-ab83-307500" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/GamersHub/efeb0ad6-be8c-4667-ab83-307500.png"} />
			
		<div id="GamersHub_16_mobCompany_Name">
			<span>{props.data.businessName}</span>
		</div>
		<div id="GamersHub_16_mobGroup_14986">
			<div id="GamersHub_16_mobPLAY_GAME_EVERYDAY_ALWAYS_BE_W">
				<span>P</span><span style={{textTransform:"lowercase"}}>LAY </span><span>G</span><span style={{textTransform:"lowercase"}}>AME</span><span> E</span><span style={{textTransform:"lowercase"}}>VERYDAY</span><br/><span>A</span><span style={{textTransform:"lowercase"}}>LWAYS</span><span> B</span><span style={{textTransform:"lowercase"}}>E </span><span>W</span><span style={{textTransform:"lowercase"}}>INNER</span><span>.</span>
			</div>
			<div id="GamersHub_16_mobCreate_your_own_game_platform_">
				<span>Create your own game platform with us</span>
			</div>
			<svg class="GamersHub_16_mobLine_38" viewBox="0 0 2 43.809">
				<path id="GamersHub_16_mobLine_38" d="M 0 0 L 0 43.80910873413086">
				</path>
			</svg>
			<svg class="GamersHub_16_mobLine_39" viewBox="0 0 2 71.827">
				<path id="GamersHub_16_mobLine_39" d="M 0 0 L 0 71.82656097412109">
				</path>
			</svg>
			<img id="GamersHub_16_mobflorian-olivo-Mf23RF8xArY-unsp" src="./Themes/GamersHub/florian-olivo-Mf23RF8xArY-unsp.png" srcset="./Themes/GamersHub/florian-olivo-Mf23RF8xArY-unsp.png 1x" />
				
			<svg class="GamersHub_16_mobPath_6953" viewBox="0 0 0 43.809">
				<path id="GamersHub_16_mobPath_6953" d="M 0 0 L -2.111782184020604e-31 17.45519065856934 L -5.300159206953673e-31 43.80910873413086">
				</path>
			</svg>
			<svg class="GamersHub_16_mobLine_41" viewBox="0 0 2 71.827">
				<path id="GamersHub_16_mobLine_41" d="M 0 0 L 0 71.82656097412109">
				</path>
			</svg>
			<div id="GamersHub_16_mobGroup_14980">
				<svg class="GamersHub_16_mobEllipse_2">
					<ellipse id="GamersHub_16_mobEllipse_2" rx="13.754022598266602" ry="13.754022598266602" cx="13.754022598266602" cy="13.754022598266602">
					</ellipse>
				</svg>
				<svg class="GamersHub_16_mobPolygon_1" viewBox="0 0 6.521 5.589">
					<path id="GamersHub_16_mobPolygon_1" d="M 3.260520458221436 0 L 6.521041393280029 5.589463710784912 L 0 5.589463710784912 Z">
					</path>
				</svg>
			</div>
		</div>
		<div id="GamersHub_16_mobGroup_14987">
			<div id="GamersHub_16_mobGroup_14969">
				<svg class="GamersHub_16_mobRectangle_718">
					<rect id="GamersHub_16_mobRectangle_718" rx="0" ry="0" x="0" y="0" width="140" height="173">
					</rect>
				</svg>
				<svg class="GamersHub_16_mobRectangle_713">
					<rect id="GamersHub_16_mobRectangle_713" rx="5" ry="5" x="0" y="0" width="25" height="25">
					</rect>
				</svg>
				<div id="GamersHub_16_mobConsole">
					<span>Console</span>
				</div>
				<svg class="GamersHub_16_mobLine_30" viewBox="0 0 2 250.164">
					<path id="GamersHub_16_mobLine_30" d="M 0 0 L 0 250.1637573242188">
					</path>
				</svg>
				<svg class="GamersHub_16_mobLine_31" viewBox="0 0 1.425 168.914">
					<path id="GamersHub_16_mobLine_31" d="M 1.425434470176697 0 L 0 168.9139862060547">
					</path>
				</svg>
				<img id="GamersHub_16_mobpexels-anthony--139038" src="./Themes/GamersHub/pexels-anthony--139038.png" srcset="./Themes/GamersHub/pexels-anthony--139038.png 1x" />
					
			</div>
			<div id="GamersHub_16_mobGroup_14968">
				<svg class="GamersHub_16_mobRectangle_719">
					<rect id="GamersHub_16_mobRectangle_719" rx="0" ry="0" x="0" y="0" width="143" height="194">
					</rect>
				</svg>
				<div id="GamersHub_16_mobSwitch">
					<span>Switch</span>
				</div>
				<svg class="GamersHub_16_mobRectangle_714">
					<rect id="GamersHub_16_mobRectangle_714" rx="5" ry="5" x="0" y="0" width="25" height="25">
					</rect>
				</svg>
				<svg class="GamersHub_16_mobLine_32" viewBox="0 0 2 250.164">
					<path id="GamersHub_16_mobLine_32" d="M 0 0 L 0 250.1637573242188">
					</path>
				</svg>
				<svg class="GamersHub_16_mobLine_33" viewBox="0 0 1.425 168.914">
					<path id="GamersHub_16_mobLine_33" d="M 1.425434470176697 0 L 0 168.9139862060547">
					</path>
				</svg>
				<img id="GamersHub_16_mobpexels-pixabay-371924" src="./Themes/GamersHub/pexels-pixabay-371924.png" srcset="./Themes/GamersHub/pexels-pixabay-371924.png 1x" />
					
			</div>
			<div id="GamersHub_16_mobGroup_14981">
				<div id="GamersHub_16_mobGroup_14970">
					<svg class="GamersHub_16_mobLine_34" viewBox="0 0 2 250.164">
						<path id="GamersHub_16_mobLine_34" d="M 0 0 L 0 250.1637573242188">
						</path>
					</svg>
					<svg class="GamersHub_16_mobLine_35" viewBox="0 0 1.425 168.914">
						<path id="GamersHub_16_mobLine_35" d="M 1.425434470176697 0 L 0 168.9139862060547">
						</path>
					</svg>
				</div>
				<svg class="GamersHub_16_mobRectangle_720">
					<rect id="GamersHub_16_mobRectangle_720" rx="0" ry="0" x="0" y="0" width="143" height="191">
					</rect>
				</svg>
				<img id="GamersHub_16_mobpexels-harsch-shivam-2007647" src="./Themes/GamersHub/pexels-harsch-shivam-2007647.png" srcset="./Themes/GamersHub/pexels-harsch-shivam-2007647.png 1x" />
					
				<div id="GamersHub_16_mobVR">
					<span>VR</span>
				</div>
				<svg class="GamersHub_16_mobRectangle_715">
					<rect id="GamersHub_16_mobRectangle_715" rx="5" ry="5" x="0" y="0" width="25" height="25">
					</rect>
				</svg>
				<svg class="GamersHub_16_mobIcon_ionic-ios-arrow-back" viewBox="11.251 6.194 6.684 11.692">
					<path id="GamersHub_16_mobIcon_ionic-ios-arrow-back" d="M 15.9198112487793 12.0380277633667 L 11.4959602355957 7.617654323577881 C 11.16878223419189 7.290477275848389 11.16878223419189 6.761424541473389 11.4959602355957 6.437728404998779 C 11.82313823699951 6.110551357269287 12.35218906402588 6.114031791687012 12.67936706542969 6.437728404998779 L 17.69144058227539 11.44632339477539 C 18.00817680358887 11.76305866241455 18.01514053344727 12.2712287902832 17.7158088684082 12.59840393066406 L 12.68284702301025 17.64180755615234 C 12.51925849914551 17.80539703369141 12.30346012115479 17.88545036315918 12.09114265441895 17.88545036315918 C 11.87882709503174 17.88545036315918 11.66302871704102 17.80539703369141 11.49944019317627 17.64180755615234 C 11.17226219177246 17.31463050842285 11.17226219177246 16.78557777404785 11.49944019317627 16.46187973022461 L 15.9198112487793 12.0380277633667 Z">
					</path>
				</svg>
			</div>
			<div id="GamersHub_16_mobGroup_14982">
				<div id="GamersHub_16_mobGroup_14971">
					<svg class="GamersHub_16_mobLine_36" viewBox="0 0 2 250.164">
						<path id="GamersHub_16_mobLine_36" d="M 0 0 L 0 250.1637573242188">
						</path>
					</svg>
					<svg class="GamersHub_16_mobLine_37" viewBox="0 0 1.425 168.914">
						<path id="GamersHub_16_mobLine_37" d="M 1.425434470176697 0 L 0 168.9139862060547">
						</path>
					</svg>
				</div>
				<svg class="GamersHub_16_mobRectangle_721">
					<rect id="GamersHub_16_mobRectangle_721" rx="0" ry="0" x="0" y="0" width="143" height="191">
					</rect>
				</svg>
				<img id="GamersHub_16_mobpexels-karol-d-841228" src="./Themes/GamersHub/pexels-karol-d-841228.png" srcset="./Themes/GamersHub/pexels-karol-d-841228.png 1x" />
					
				<div id="GamersHub_16_mobKeyboard">
					<span>Keyboard</span>
				</div>
				<svg class="GamersHub_16_mobRectangle_716">
					<rect id="GamersHub_16_mobRectangle_716" rx="5" ry="5" x="0" y="0" width="25" height="25">
					</rect>
				</svg>
				<svg class="GamersHub_16_mobIcon_ionic-ios-arrow-back_d" viewBox="11.251 6.194 6.684 11.692">
					<path id="GamersHub_16_mobIcon_ionic-ios-arrow-back_d" d="M 15.9198112487793 12.0380277633667 L 11.4959602355957 7.617654323577881 C 11.16878223419189 7.290477275848389 11.16878223419189 6.761424541473389 11.4959602355957 6.437728404998779 C 11.82313823699951 6.110551357269287 12.35218906402588 6.114031791687012 12.67936706542969 6.437728404998779 L 17.69144058227539 11.44632339477539 C 18.00817680358887 11.76305866241455 18.01514053344727 12.2712287902832 17.7158088684082 12.59840393066406 L 12.68284702301025 17.64180755615234 C 12.51925849914551 17.80539703369141 12.30346012115479 17.88545036315918 12.09114265441895 17.88545036315918 C 11.87882709503174 17.88545036315918 11.66302871704102 17.80539703369141 11.49944019317627 17.64180755615234 C 11.17226219177246 17.31463050842285 11.17226219177246 16.78557777404785 11.49944019317627 16.46187973022461 L 15.9198112487793 12.0380277633667 Z">
					</path>
				</svg>
			</div>
		</div>
	</div>
	<div id="GamersHub_16_mobGroup_14996">
		<div id="GamersHub_16_mobGroup_14990">
			<div id="GamersHub_16_mobGroup_14989">
				<svg class="GamersHub_16_mobPath_6955" viewBox="3.06 146.411 295.791 102.414">
					<path id="GamersHub_16_mobPath_6955" d="M 3.153154611587524 239.6756744384766 L 27.95777130126953 152.3642883300781 C 27.95777130126953 152.3642883300781 31.43007850646973 146.4112548828125 38.37550354003906 146.4112548828125 C 45.3209228515625 146.4112548828125 264.0956726074219 146.4112548828125 264.0956726074219 146.4112548828125 C 264.0956726074219 146.4112548828125 275.5054931640625 148.8916778564453 275.5054931640625 152.3642883300781 C 275.5054931640625 155.8368835449219 298.8215637207031 239.6756744384766 298.8215637207031 239.6756744384766 C 298.8215637207031 239.6756744384766 299.8136596679688 248.1092224121094 290.3880004882812 248.6052703857422 C 280.9623718261719 249.101318359375 10.59461975097656 248.6052703857422 10.59461975097656 248.6052703857422 C 10.59461975097656 248.6052703857422 2.083295345306396 245.6495666503906 3.153154611587524 240.6133575439453 C 4.223013877868652 235.5771636962891 3.153154611587524 239.6756744384766 3.153154611587524 239.6756744384766 Z">
					</path>
				</svg>
				<img id="GamersHub_16_mobkisspng-twisted-metal-black-pl" src="./Themes/GamersHub/kisspng-twisted-metal-black-pl.png" srcset="./Themes/GamersHub/kisspng-twisted-metal-black-pl.png 1x" />
					
			</div>
			<div id="GamersHub_16_mobGroup_14988">
				<div id="GamersHub_16_mobScroll_Group_51">
					<div id="GamersHub_16_mobElevate_Your_Space_with_Timele">
						<span>{props.data.vision}</span>
					</div>
				</div>
				<div id="GamersHub_16_mobVision">
					<span>Vision</span>
				</div>
			</div>
		</div>
		<div id="GamersHub_16_mobGroup_14992">
			<div id="GamersHub_16_mobGroup_14991">
				<svg class="GamersHub_16_mobPath_6957" viewBox="3.06 146.411 295.79 124.099">
					<path id="GamersHub_16_mobPath_6957" d="M 3.153154373168945 259.423095703125 L 27.95764923095703 153.624755859375 C 27.95764923095703 153.624755859375 31.42994117736816 146.4112548828125 38.37533187866211 146.4112548828125 C 45.32071685791016 146.4112548828125 264.0943908691406 146.4112548828125 264.0943908691406 146.4112548828125 C 264.0943908691406 146.4112548828125 275.504150390625 149.4168701171875 275.504150390625 153.624755859375 C 275.504150390625 157.8326416015625 298.8201293945312 259.423095703125 298.8201293945312 259.423095703125 C 298.8201293945312 259.423095703125 299.8121948242188 269.642333984375 290.3865966796875 270.243408203125 C 280.9609985351562 270.844482421875 10.59458351135254 270.243408203125 10.59458351135254 270.243408203125 C 10.59458351135254 270.243408203125 2.083300113677979 266.661865234375 3.153154373168945 260.559326171875 C 4.223008155822754 254.456787109375 3.153154373168945 259.423095703125 3.153154373168945 259.423095703125 Z">
					</path>
				</svg>
				<img id="GamersHub_16_mobkisspng-computer-keyboard-cors" src="./Themes/GamersHub/kisspng-computer-keyboard-cors.png" srcset="./Themes/GamersHub/kisspng-computer-keyboard-cors.png 1x" />
					
			</div>
			<div id="GamersHub_16_mobGroup_14973">
				<div id="GamersHub_16_mobScroll_Group_51_en">
					<div id="GamersHub_16_mobElevate_Your_Space_with_Timele_eo">
						<span>{props.data.mission}</span>
					</div>
				</div>
				<div id="GamersHub_16_mobMission">
					<span>Mission</span>
				</div>
			</div>
		</div>
		<div id="GamersHub_16_mobGroup_14995">
			<div id="GamersHub_16_mobGroup_14993">
				<svg class="GamersHub_16_mobPath_6956" viewBox="3.06 146.411 336.389 116.471">
					<path id="GamersHub_16_mobPath_6956" d="M 3.165922880172729 252.476318359375 L 31.37499809265137 153.1813659667969 C 31.37499809265137 153.1813659667969 35.32388687133789 146.4112701416016 43.22257614135742 146.4112701416016 C 51.12126159667969 146.4112701416016 299.9230346679688 146.4112701416016 299.9230346679688 146.4112701416016 C 299.9230346679688 146.4112701416016 312.8988647460938 149.2321166992188 312.8988647460938 153.1813659667969 C 312.8988647460938 157.1305999755859 339.4151000976562 252.476318359375 339.4151000976562 252.476318359375 C 339.4151000976562 252.476318359375 340.5433654785156 262.0673828125 329.8240051269531 262.6315307617188 C 319.1047058105469 263.1956787109375 11.62873649597168 262.6315307617188 11.62873649597168 262.6315307617188 C 11.62873649597168 262.6315307617188 1.949224233627319 259.2701416015625 3.165922880172729 253.542724609375 C 4.382621288299561 247.8153076171875 3.165922880172729 252.476318359375 3.165922880172729 252.476318359375 Z">
					</path>
				</svg>
				<img id="GamersHub_16_mobMy_project" src="./Themes/GamersHub/My_project.png" srcset="./Themes/GamersHub/My_project.png 1x" />
					
				<svg class="GamersHub_16_mobEllipse_63">
					<ellipse id="GamersHub_16_mobEllipse_63" rx="138.43324279785156" ry="5.631182670593262" cx="138.43324279785156" cy="5.631182670593262">
					</ellipse>
				</svg>
			</div>
			<div id="GamersHub_16_mobGroup_14994">
				<div id="GamersHub_16_mobScroll_Group_51_ew">
					<div id="GamersHub_16_mobElevate_Your_Space_with_Timele_ex">
						<span>{props.data.principles}</span>
					</div>
				</div>
				<div id="GamersHub_16_mobPrinciples">
					<span>Principles</span>
				</div>
			</div>
		</div>
	</div>
	<div id="GamersHub_16_mobGroup_14984">
		<svg class="GamersHub_16_mobprinci_design_img_website_7_pc" viewBox="0 -0.553 175.359 76.1">
			<path id="GamersHub_16_mobprinci_design_img_website_7_pc" d="M 52.06050109863281 75.54600524902344 L 99.03150177001953 0 L 120.1688995361328 0 L 73.19699859619141 75.54600524902344 L 52.06050109863281 75.54600524902344 Z M 0 75.54600524902344 L 46.97190093994141 0 L 91.59480285644531 0 L 44.62290191650391 75.54600524902344 L 0 75.54600524902344 Z M 83.76390075683594 74.99250030517578 L 130.7358093261719 -0.5534999966621399 L 175.3587036132812 -0.5534999966621399 L 128.3868103027344 74.99250030517578 L 83.76390075683594 74.99250030517578 Z">
			</path>
		</svg>
		<div id="GamersHub_16_mobOur_Services_lbl_website_6_pc">
			<span>Our</span><span style={{color:"rgba(45,255,45,0.89)"}}> </span><span style={{color:"rgba(0,0,0,0.89)"}}>Clans</span>
		</div>
	</div>
	<div id="GamersHub_16_mobScroll_Group_55">
	{props.offeringsData.map((off,idx)=>(
		<div id="GamersHub_16_mobGroup_14977">
			<svg class="GamersHub_16_mobPath_448" viewBox="4578.628 19892.477 240.468 145.867">
				<path id="GamersHub_16_mobPath_448" d="M 4578.6279296875 19892.96875 L 4819.095703125 19892.4765625 L 4818.4990234375 20032.07421875 L 4715.6806640625 20032.24609375 L 4715.6806640625 20033.1015625 L 4713.91455078125 20033.1015625 L 4713.91455078125 20034.505859375 L 4754.33349609375 20034.505859375 L 4755.52392578125 20038.34375 L 4641.19970703125 20038.34375 L 4642.45166015625 20034.505859375 L 4683.79443359375 20034.505859375 L 4683.79443359375 20033.1015625 L 4681.041015625 20033.1015625 L 4681.041015625 20032.24609375 L 4578.6279296875 20032.07421875 L 4578.6279296875 19892.96875 Z">
				</path>
			</svg>
			<img id="GamersHub_16_mobn_71177" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/GamersHub/n_71177.png"} />
				
			<div id="GamersHub_16_mobMinecraft">
				<span>{off.offeringTitle}</span>
			</div>
		</div>))}
	</div>
	<div id="GamersHub_16_mobGroup_14999">
		<div id="GamersHub_16_mobGroup_14976">
			<img id="GamersHub_16_mobMy_project_e" src="./Themes/GamersHub/My_project_e.png" srcset="./Themes/GamersHub/My_project_e.png 1x" />
				
			<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="GamersHub_16_mobRectangle_717">
				<rect id="GamersHub_16_mobRectangle_717" rx="5" ry="5" x="0" y="0" width="205.276" height="114.694">
				</rect>
			</iframe>
		</div>
		<div id="GamersHub_16_mobGroup_14997">
			<div id="GamersHub_16_mobn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="GamersHub_16_mobaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="GamersHub_16_mobaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="GamersHub_16_moblocation_img_shadow_space_webs" src="./Themes/GamersHub/location_img_shadow_space_webs.png" srcset="./Themes/GamersHub/location_img_shadow_space_webs.png 1x" />
				
			<img id="GamersHub_16_mobemail_address_shadow_space_web" src="./Themes/GamersHub/email_address_shadow_space_web.png" srcset="./Themes/GamersHub/email_address_shadow_space_web.png 1x" />
				
			<img id="GamersHub_16_mobphone_number_shadow_space_webs" src="./Themes/GamersHub/phone_number_shadow_space_webs.png" srcset="./Themes/GamersHub/phone_number_shadow_space_webs.png 1x" />
				
		</div>
		<div id="GamersHub_16_mobsocials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
			<div title="LinkedIn" id="GamersHub_16_moblinked_in_grp_shadow_space_web">
				<svg class="GamersHub_16_mobmed_shadow_space_website_7">
					<ellipse id="GamersHub_16_mobmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<div id="GamersHub_16_moblinked_in_shadow_space_website">
				<a href={props.data.linkedInUrl} target="_blank">
					<svg class="GamersHub_16_mobn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
						<path id="GamersHub_16_mobn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
						</path>
					</svg>
				</a>
					<svg class="GamersHub_16_moblinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
						<path id="GamersHub_16_moblinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
						</path>
					</svg>
					<svg class="GamersHub_16_mobtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
						<path id="GamersHub_16_mobtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
						</path>
					</svg>
				</div>
			</div>:""}
			{props.data.instagramUrl?	
			<div title="Instagram" id="GamersHub_16_mobInsta_grp_shadow_space_website">
				<svg class="GamersHub_16_mobshadow_space_website_7">
					<ellipse id="GamersHub_16_mobshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<a href={props.data.instagramUrl} target="_blank">
				<img id="GamersHub_16_mobinstagramshadow_space_website_" src="./Themes/GamersHub/instagramshadow_space_website_.png" srcset="./Themes/GamersHub/instagramshadow_space_website_.png 1x" />
					
				</a>
			</div>:""}
			{props.data.whatsAppNumber?
			<div title="WhatsApp" id="GamersHub_16_mobwhatsapp_grp_shadow_space_webs">
				<svg class="GamersHub_16_mobmed_shadow_space_website_7_fv">
					<ellipse id="GamersHub_16_mobmed_shadow_space_website_7_fv" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="GamersHub_16_mobwhatsapp_lbl_shadow_space_webs" src="./Themes/GamersHub/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/GamersHub/whatsapp_lbl_shadow_space_webs.png 1x" />
					</a>
			</div>:""}
		</div>
		<div id="GamersHub_16_mobn_Copyright_Operate_Live_OPC_P">
			<span>© Copyright Operate Live (OPC) Private Limited.<br/> All Rights Reserved</span>
		</div>
		<svg class="GamersHub_16_mobLine_28" viewBox="0 0 370 3">
			<path id="GamersHub_16_mobLine_28" d="M 0 0 L 370 3">
			</path>
		</svg>
	</div>
</div>
  )
}

export default GamersHubMob
