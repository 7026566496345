import React from 'react';
import "../../../styles/CycleHaven.css";

function CycleHaven(props) {
  return (
    <div id="CycleHaven">
	<svg class="CHEllipse_45">
		<ellipse id="CHEllipse_45" rx="284" ry="9" cx="284" cy="9">
		</ellipse>
	</svg>
	<img id="CHAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/CycleHaven/Anime_Edit.png"} />
		
	<div id="CHGroup_15101">
		<div id="CHGroup_15041">
			<div id="CHGroup_15040">
				<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="CHRectangle_737">
					<rect id="CHRectangle_737" rx="0" ry="0" x="0" y="0" width="538" height="302">
					</rect>
				</iframe>
			</div>
			<div id="CHsocials_grp_shadow_space_websi">
{props.data.linkedInUrl?
				<div title="LinkedIn" id="CHlinked_in_grp_shadow_space_web">
					<svg class="CHmed_shadow_space_website_7">
						<ellipse id="CHmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="CHlinked_in_shadow_space_website">
						<a href={props.data.linkedInUrl} target="_blank">
						<svg class="CHn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="CHn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="CHlinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="CHlinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="CHtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="CHtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>
:""}
{props.data.instagramUrl?	
				<div title="Instagram" id="CHInsta_grp_shadow_space_website">
					<svg class="CHshadow_space_website_7">
						<ellipse id="CHshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={props.data.instagramUrl} target="_blank">
					<img id="CHinstagramshadow_space_website_" src="./Themes/CycleHaven/instagramshadow_space_website_.png" srcset="./Themes/CycleHaven/instagramshadow_space_website_.png 1x" />
						
					</a>
				</div>:""}
{props.data.whatsAppNumber?
				<div title="WhatsApp" id="CHwhatsapp_grp_shadow_space_webs">
					<svg class="CHmed_shadow_space_website_7_bk">
						<ellipse id="CHmed_shadow_space_website_7_bk" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
<img id="CHwhatsapp_lbl_shadow_space_webs" src="./Themes/CycleHaven/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/CycleHaven/whatsapp_lbl_shadow_space_webs.png 1x" />
</a>
				</div>:""}
			</div>
			<div id="CHn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="CHaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="CHaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="CHlocation_img_shadow_space_webs" src="./Themes/CycleHaven/location_img_shadow_space_webs.png" srcset="./Themes/CycleHaven/location_img_shadow_space_webs.png 1x" />
				
			<img id="CHemail_address_shadow_space_web" src="./Themes/CycleHaven/email_address_shadow_space_web.png" srcset="./Themes/CycleHaven/email_address_shadow_space_web.png 1x" />
				
			<img id="CHphone_number_shadow_space_webs" src="./Themes/CycleHaven/phone_number_shadow_space_webs.png" srcset="./Themes/CycleHaven/phone_number_shadow_space_webs.png 1x" />
				
			<div id="CHn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
			</div>
		</div>
		<div id="CHContact_Us">
			<span>Contact Us!</span>
		</div>
	</div>
	<div id="CHCTA">
		<div id="CHScroll_Group_92">
			<div id="CHLorem_ipsum_dolor_sit_amet_con">
				<span>{props.data.introduction}</span>
			</div>
		</div>
		<div id="CHCompany_Name">
			<span>{props.data.businessName}</span>
		</div>
	</div>
	<div id="CHGroup_15154">
		<svg class="CHPath_7783" viewBox="-2212.423 -14603.414 118.831 18.743">
			<path id="CHPath_7783" d="M -2212.422607421875 -14584.6708984375 L -2114.584228515625 -14584.6708984375 C -2114.584228515625 -14584.6708984375 -2098.465087890625 -14585.0458984375 -2093.592041015625 -14603.4140625">
			</path>
		</svg>
		<svg class="CHPath_7784" viewBox="-2212.423 -14603.415 118.831 18.743">
			<path id="CHPath_7784" d="M -2093.592041015625 -14603.4150390625 L -2191.430419921875 -14603.4150390625 C -2191.430419921875 -14603.4150390625 -2207.549560546875 -14603.0400390625 -2212.422607421875 -14584.671875">
			</path>
		</svg>
		<div id="CHBuy_Now">
			<span>Buy Now</span>
		</div>
	</div>
	<img id="CHwhite-bicycle-standing-park" src="./Themes/CycleHaven/white-bicycle-standing-park.png" srcset="./Themes/CycleHaven/white-bicycle-standing-park.png 1x" />
		
	<div id="CHGroup_15039">
		<div id="CHGroup_15033">
			<img id="CHalvan-nee-EHILKZNmTuA-unsplash" src="./Themes/CycleHaven/alvan-nee-EHILKZNmTuA-unsplash.png" srcset="./Themes/CycleHaven/alvan-nee-EHILKZNmTuA-unsplash.png 1x" />
				
			<div id="CHGroup_15038">
				<div id="CHScroll_Group_28">
					<div id="CHIt_is_a_long_established_fact_">
						<span>{props.data.vision}</span>
					</div>
				</div>
				<div id="CHVISION">
					<span>VISION</span>
				</div>
			</div>
		</div>
		<div id="CHGroup_15034">
			<img id="CHkaur-kristjan-miUC0b1IVYU-unsp" src="./Themes/CycleHaven/kaur-kristjan-miUC0b1IVYU-unsp.png" srcset="./Themes/CycleHaven/kaur-kristjan-miUC0b1IVYU-unsp.png 1x" />
				
			<div id="CHGroup_15037">
				<div id="CHScroll_Group_28_cd">
					<div id="CHIt_is_a_long_established_fact__ce">
						<span>{props.data.mission}</span>
					</div>
				</div>
				<div id="CHMISSION">
					<span>MISSION</span>
				</div>
			</div>
		</div>
		<div id="CHGroup_15035">
			<img id="CHsaurav-kundu-H8QttyFgroY-unspl" src="./Themes/CycleHaven/saurav-kundu-H8QttyFgroY-unspl.png" srcset="./Themes/CycleHaven/saurav-kundu-H8QttyFgroY-unspl.png 1x" />
				
			<div id="CHScroll_Group_28_ci">
				<div id="CHIt_is_a_long_established_fact__cj">
					<span>{props.data.principles}</span>
				</div>
			</div>
			<div id="CHPRINCIPLES">
				<span>PRINCIPLES</span>
			</div>
		</div>
	</div>
	<div id="CHGroup_15032">
		<div id="CHScroll_Group_60">
			<div id="CHGroup_15031">
				<svg class="CHRectangle_112">
					<rect id="CHRectangle_112" rx="15" ry="15" x="0" y="0" width="485" height="385">
					</rect>
				</svg>
				<img id="CHMask_Group_4" src="./Themes/CycleHaven/Mask_Group_4.png" srcset="./Themes/CycleHaven/Mask_Group_4.png 1x" />
					
				<div id="CHCycle">
					<span>Cycle</span>
				</div>
				<div id="CHGroup_9694">
					<div id="CHBangalore">
						<span>Bangalore</span>
					</div>
					<div id="CHgeo-alt">
						<svg class="CHPath_2927" viewBox="4.5 0 17 22.667">
							<path id="CHPath_2927" d="M 13 22.66666793823242 C 13 22.66666793823242 21.5 14.61181640625 21.5 8.500000953674316 C 21.5 3.805579662322998 17.69441795349121 0 12.99999904632568 0 C 8.305577278137207 0 4.499998092651367 3.805579900741577 4.499998092651367 8.500000953674316 C 4.499999046325684 14.61181640625 13 22.66666793823242 13 22.66666793823242 Z M 13 12.75000095367432 C 15.34721088409424 12.75000095367432 17.25 10.84721088409424 17.25 8.500000953674316 C 17.25 6.152790069580078 15.34721088409424 4.250000476837158 13 4.250000476837158 C 10.65278911590576 4.250000476837158 8.75 6.152790069580078 8.75 8.500000953674316 C 8.75 10.84721088409424 10.65278911590576 12.75000095367432 13 12.75000095367432 Z">
							</path>
						</svg>
					</div>
				</div>
			</div>
		</div>
		<div id="CHOur_Collection">
			<span>Our Collection</span>
		</div>
	</div>
</div>
  )
}

export default CycleHaven