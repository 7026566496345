import React, { useState, useEffect, useContext } from "react";
import "../../../styles/ShadowSpace.css";
import { UserContext } from '../../UserContext';
import { useNavigate,useLocation, Navigate } from "react-router-dom";

const ShadowSpace = (props) => {
  return (
      <div id="Shadow_Space_PC_websie_5_ShadowScape">
	<svg class="Shadow_Space_PC_n_th_shaodwow_spave_web_5" viewBox="4350.95 20281.902 1615.849 1073.621">
		<path id="Shadow_Space_PC_n_th_shaodwow_spave_web_5" d="M 4350.9501953125 21196.345703125 L 5864.20361328125 20281.90234375 L 5966.79931640625 20393.82421875 L 4350.9501953125 21355.5234375 L 4350.9501953125 21196.345703125 Z">
		</path>
	</svg>
	{/* <svg class="Shadow_Space_PC_n_rd_shaodwow_spave_web_5" viewBox="4236.69 20366.375 1585.334 1103.135">
		<path id="Shadow_Space_PC_n_rd_shaodwow_spave_web_5" d="M 4236.68994140625 21310.33203125 L 5719.4287109375 20366.375 L 5822.0244140625 20478.296875 L 4236.68994140625 21469.509765625 L 4236.68994140625 21310.33203125 Z">
		</path>
	</svg> */}
	<svg class="Shadow_Space_PC_n_nshaodwow_spave_web_5" viewBox="4350.95 20491.115 1188.57 958.381">
		<path id="Shadow_Space_PC_n_nshaodwow_spave_web_5" d="M 4350.9501953125 21273.013671875 L 5430.115234375 20491.115234375 L 5539.5205078125 20615.205078125 L 4350.9501953125 21449.49609375 L 4350.9501953125 21273.013671875 Z">
		</path>
	</svg>
	<svg class="Shadow_Space_PC_first_shaodwow_spave_web_5" viewBox="4690 20491.115 775.541 629.563">
		<path id="Shadow_Space_PC_first_shaodwow_spave_web_5" d="M 4690 20961.5 L 5362.9453125 20491.115234375 L 5465.541015625 20603.037109375 L 4690 21120.677734375 L 4690 20961.5 Z">
		</path>
	</svg>
	<div id="Shadow_Space_PC_Scroll_comp_intro_shaodwow_spa">
		<div id="Shadow_Space_PC_intro_lbl_shaodwow_spave_web_5">
			<span>{props.data.introduction}</span>
		</div>
	</div>
	<div id="Shadow_Space_PC_Heading_shaodwow_spave_web_5">
		<span>{props.data.businessName && props.data.businessName.length > 0?props.data.businessName:props.data.name}</span>
	</div>
	<img id="Shadow_Space_PC_profile_pic_logo_shadow_space_" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/ShadowSpace/user_pfp_profile_page.png"}/>
		
	<div id="Shadow_Space_PC_Partners_grp_shadow_space_webs">
		<div id="Shadow_Space_PC_text_our_partnersshadow_space_">
			<span>Our Amazing Partners</span>
		</div>
		<div id="Shadow_Space_PC_Scroll_partner_shadow_space_we">
       
        {props.partnersData && props.partnersData.map(partner=>
        <div onClick={()=>window.location.href="https://"+partner.nickName+".operate.live"}>
			<div id="Shadow_Space_PC_partner_lbls_shadow_space_webs">
				<div id="Shadow_Space_PC_google_lbl_partner_shadow_spac">
					<span>{partner.businessName && partner.businessName.length>0?partner.businessName:partner.name}</span>
				</div>
				<img id="Shadow_Space_PC_google_1_shadow_space_website_" src={partner.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(partner.imageUrl.split('/')[5]):"./Themes/office.png"} />
					
			</div>
            </div>
            )}
        
		</div>
	</div>
	<div id="Shadow_Space_PC_top_right_imgs_shadow_space_we">
		<svg class="Shadow_Space_PC_right_gold_shadow_space_websit">
			<rect id="Shadow_Space_PC_right_gold_shadow_space_websit" rx="0" ry="0" x="0" y="0" width="392" height="441">
			</rect>
		</svg>
		<svg class="Shadow_Space_PC_down_white_shadow_space_websit">
			<rect id="Shadow_Space_PC_down_white_shadow_space_websit" rx="0" ry="0" x="0" y="0" width="392" height="441">
			</rect>
		</svg>
		<img id="Shadow_Space_PC_damon-shadow_space_website_5" src="./Themes/ShadowSpace/damon-shadow_space_website_5.png" srcset="./Themes/ShadowSpace/damon-shadow_space_website_5.png 1x" />
			
	</div>
	<div id="Shadow_Space_PC_what_we_stand_for_shadow_space">
		<span>What We Stand For</span>
	</div>
	<div id="Shadow_Space_PC_Scrollprinciple_shadow_space_w">
		<div id="Shadow_Space_PC_intro_lbl_website_3_ss_pc">
			<span>{props.data.principles}</span>
		</div>
	</div>
	<div id="Shadow_Space_PC_principles_lbl_shadow_space_we">
		<span>Principles</span>
	</div>
	<svg class="Shadow_Space_PC_Fill_pink_shadow_space_website">
		<rect id="Shadow_Space_PC_Fill_pink_shadow_space_website" rx="35" ry="35" x="0" y="0" width="70" height="70">
		</rect>
	</svg>
	<img id="Shadow_Space_PC_principle_2_shadow_space_websi_pc" src="./Themes/ShadowSpace/principle_2_shadow_space_websi.png" srcset="./Themes/ShadowSpace/principle_2_shadow_space_websi.png 1x" />
		
	<div id="Shadow_Space_PC_Scroll_mission_shadow_space_we">
		<div id="Shadow_Space_PC_intro_2_shadow_space_website_5">
			<span>{props.data.mission}</span>
		</div>
	</div>
	<div id="Shadow_Space_PC_mission_lbl_shadow_space_websi">
		<span>Mission</span>
	</div>
	<div id="Shadow_Space_PC_Icon_2_grpshadow_space_website">
		<svg class="Shadow_Space_PC_bg_blueshadow_space_website_5">
			<rect id="Shadow_Space_PC_bg_blueshadow_space_website_5" rx="35" ry="35" x="0" y="0" width="70" height="70">
			</rect>
		</svg>
	</div>
	<img id="Shadow_Space_PC_leadership_2_shadow_space_webs_pc" src="./Themes/ShadowSpace/leadership_2_shadow_space_webs.png" srcset="./Themes/ShadowSpace/leadership_2_shadow_space_webs.png 1x" />
		
	<div id="Shadow_Space_PC_Scroll_vision_shadow_space_web">
		<div id="Shadow_Space_PC_introshadow_space_website_5">
			<span>{props.data.vision}</span>
		</div>
	</div>
	<div id="Shadow_Space_PC_Visionshadow_space_website_5">
		<span>Vision</span>
	</div>
	<div id="Shadow_Space_PC_Icon_grp_shadow_space_website_">
		<svg class="Shadow_Space_PC_Fill_11shadow_space_website_5">
			<rect id="Shadow_Space_PC_Fill_11shadow_space_website_5" rx="35" ry="35" x="0" y="0" width="70" height="70">
			</rect>
		</svg>
	</div>
	<img id="Shadow_Space_PC_target_1_shadow_space_website_" src="./Themes/ShadowSpace/target_1_shadow_space_website_.png" srcset="./Themes/ShadowSpace/target_1_shadow_space_website_.png 1x" />
		
	<div id="Shadow_Space_PC_offering_lbl_shadow_space_webs">
		<span>My Offerings</span>
	</div>
	<div id="Shadow_Space_PC_Scroll_offering_space_shaow_we">
    {props.offeringsData.map((rec,idx)=>(
		<div id="Shadow_Space_PC_offering_card_1_shadow_space_w">
			<svg class="Shadow_Space_PC_card_bg_offeringshadow_space_w">
				<rect id="Shadow_Space_PC_card_bg_offeringshadow_space_w" rx="10" ry="10" x="0" y="0" width="230" height="325">
				</rect>
			</svg>
			<svg class="Shadow_Space_PC_half_circle_shadow_space_websi" viewBox="16.614 0 18.386 35">
				<path id="Shadow_Space_PC_half_circle_shadow_space_websi" d="M 17.5 0 C 27.16498184204102 0 35 7.835017204284668 35 17.5 C 35 27.16498184204102 27.16498184204102 35 17.5 35 C 16.41851043701172 35 16.22388076782227 0 17.5 0 Z">
				</path>
			</svg>
			<svg class="Shadow_Space_PC_circle_deign_shadow_space_webs">
				<ellipse id="Shadow_Space_PC_circle_deign_shadow_space_webs" rx="3.5" ry="3.5" cx="3.5" cy="3.5">
				</ellipse>
			</svg>
			<div id="Shadow_Space_PC_oofering_lbl_med_shadow_space_pc">
				<span>{rec.offeringTitle}</span>
			</div>
			
			<img id="Shadow_Space_PC_markus-shadow_space_website_5" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"./Themes/ShadowSpace/profilebackgroundEm.png" } />
				
		</div>
        ))}
	</div>
	<div id="Shadow_Space_PC_n_234567890_mobile_number_shad">
		<span>{props.data.businessPhone}</span>
	</div>
	<div id="Shadow_Space_PC_affanghanioperatelive_email_sh">
		<span>{props.data.businessEmail}</span>
	</div>
	<div id="Shadow_Space_PC_address_med_shadow_space_websi">
		<span>{props.data.businessAddress}</span>
	</div>
	<img id="Shadow_Space_PC_location_img_shadow_space_webs" src="./Themes/ShadowSpace/location_img_shadow_space_webs.png" srcset="./Themes/ShadowSpace/location_img_shadow_space_webs.png 1x" />
		
	<img id="Shadow_Space_PC_email_address_shadow_space_web" src="./Themes/ShadowSpace/email_address_shadow_space_web.png" srcset="./Themes/ShadowSpace/email_address_shadow_space_web.png 1x" />
		
	<img id="Shadow_Space_PC_phone_number_shadow_space_webs" src="./Themes/ShadowSpace/phone_number_shadow_space_webs.png" srcset="./Themes/ShadowSpace/phone_number_shadow_space_webs.png 1x" />
		
	<div id="Shadow_Space_PC_quote_grp_shadow_space_website">
		<div id="Shadow_Space_PC_Connect_with_us_and_unlock_lim_ss_pc">
			<span>Embrace the unknown and ignite your potential as you step into a world where possibilities know no bounds.</span>
		</div>
		<img id="Shadow_Space_PC_quote_left_med_shadow_space_we" src="./Themes/ShadowSpace/quote_left_med_shadow_space_we.png" srcset="./Themes/ShadowSpace/quote_left_med_shadow_space_we.png 1x" />
			
		<img id="Shadow_Space_PC_quote_right_med_shadow_space_w" src="./Themes/ShadowSpace/quote_right_med_shadow_space_w.png" srcset="./Themes/ShadowSpace/quote_right_med_shadow_space_w.png 1x" />
			
	</div>
	<a href="https://operate.live" target="_blank">
	<div id="Shadow_Space_PC_n_Copyright_Operate_Live_med_s">
		<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
	</div>
	</a>
	<div id="Shadow_Space_PC_socials_grp_shadow_space_websi">
    {props.data.linkedInUrl?
		<div title="LinkedIn" id="Shadow_Space_PC_linked_in_grp_shadow_space_web">
			<svg class="Shadow_Space_PC_Ellipse_5_med_shadow_space_web">
				<ellipse id="Shadow_Space_PC_Ellipse_5_med_shadow_space_web" rx="32.5" ry="32.5" cx="32.5" cy="32.5">
				</ellipse>
			</svg>
			<div id="Shadow_Space_PC_linked_in_shadow_space_website">
				<a href={props.data.linkedInUrl} target="_blank">
				<svg class="Shadow_Space_PC_n_website_5_layout_" viewBox="-15.255 0 20.919 19.736">
					<path id="Shadow_Space_PC_n_website_5_layout_" d="M -2.165393829345703 3.814697265625e-06 C -5.699716567993164 3.814697265625e-06 -7.914352416992188 1.91431999206543 -8.323646545410156 3.255456924438477 L -8.323646545410156 0.1879177093505859 L -15.2549991607666 0.1879177093505859 C -15.16490459442139 1.816503524780273 -15.2549991607666 19.73609161376953 -15.2549991607666 19.73609161376953 L -8.323646545410156 19.73609161376953 L -8.323646545410156 9.15800952911377 C -8.323646545410156 8.57024097442627 -8.351963043212891 7.982475280761719 -8.17348575592041 7.560314178466797 C -7.696406841278076 6.384779930114746 -6.672748565673828 5.164627075195312 -4.83222484588623 5.164627075195312 C -2.426241397857666 5.164627075195312 -1.331366658210754 6.972545623779297 -1.331366658210754 9.620500564575195 L -1.331366658210754 19.73609161376953 L 5.66434383392334 19.73609161376953 L 5.66434383392334 8.866270065307617 C 5.66434383392334 2.811843872070312 2.219258308410645 3.814697265625e-06 -2.165393829345703 3.814697265625e-06">
					</path>
				</svg>
			</a>
				<svg class="Shadow_Space_PC_linked_in_i_shadow_space_websi" viewBox="0.931 -22.783 6.169 19.549">
					<path id="Shadow_Space_PC_linked_in_i_shadow_space_websi" d="M 0.9310001134872437 -3.233964920043945 L 7.100403785705566 -3.233964920043945 L 7.100403785705566 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -3.233964920043945 Z">
					</path>
				</svg>
				<svg class="Shadow_Space_PC_top_dot_i_shadow_space_website" viewBox="-4.392 0 7.489 6.783">
					<path id="Shadow_Space_PC_top_dot_i_shadow_space_website" d="M -0.6234287023544312 0 C -2.90156078338623 0 -4.392000198364258 1.462981224060059 -4.392000198364258 3.394460201263428 C -4.392000198364258 5.288183689117432 -2.944462776184082 6.782914161682129 -0.7109499573707581 6.782914161682129 L -0.6663313508033752 6.782914161682129 C 1.652987360954285 6.782914161682129 3.097091197967529 5.283035755157471 3.097091197967529 3.388453245162964 C 3.05247163772583 1.459548950195312 1.652987360954285 0 -0.6234287023544312 0">
					</path>
				</svg>
			</div>
		</div>:""}
        {props.data.instagramUrl?	
		<div title="Instagram" id="Shadow_Space_PC_Insta_grp_shadow_space_website">
			<svg class="Shadow_Space_PC_Ellipse_4_shadow_space_website">
				<ellipse id="Shadow_Space_PC_Ellipse_4_shadow_space_website" rx="32.5" ry="32.5" cx="32.5" cy="32.5">
				</ellipse>
			</svg>
			<a href={props.data.instagramUrl} target="_blank">
			<img id="Shadow_Space_PC_instagramshadow_space_website_" src="./Themes/ShadowSpace/instagramshadow_space_website_.png" srcset="./Themes/ShadowSpace/instagramshadow_space_website_.png 1x" />
				
			</a>
		</div>:""}
        {props.data.whatsAppNumber?
		<div title="WhatsApp" id="Shadow_Space_PC_whatsapp_grp_shadow_space_webs">
			<svg class="Shadow_Space_PC_Ellipse_3_med_shadow_space_web">
				<ellipse id="Shadow_Space_PC_Ellipse_3_med_shadow_space_web" rx="32.5" ry="32.5" cx="32.5" cy="32.5">
				</ellipse>
			</svg>
            <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
			<img id="Shadow_Space_PC_whatsapp_lbl_shadow_space_webs" src="./Themes/ShadowSpace/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/ShadowSpace/whatsapp_lbl_shadow_space_webs.png 1x" />
			</a>	
		</div>
        :""}
	</div>
	<div id="Shadow_Space_PC_oofering_lbl_med_shadow_space__du">
		<span>My Events</span>
	</div>
	<div id="Shadow_Space_PC_Scroll_event_web_5_pc">
	{props.eventsData && props.eventsData.map((eve)=>(
		<div id="Shadow_Space_PC_my_event_sectionshadow_space_w">
			<svg class="Shadow_Space_PC_comic_con_bg_shadow_space_webs">
				<rect id="Shadow_Space_PC_comic_con_bg_shadow_space_webs" rx="15" ry="15" x="0" y="0" width="514" height="286">
				</rect>
			</svg>
			<div id="Shadow_Space_PC_Group_banner_event_img_pc">
				<img id="Shadow_Space_PC_Anime_Edit_shadow_space_websit" src={eve.eventImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(eve.eventImageUrl.split('/')[5]):"./Themes/ShadowSpace/Anime_Edit_shadow_space_websit.png"}  />
					
			</div>
			<div id="Shadow_Space_PC_oofering_lbl_med_shadow_space__ee">
				<span>{eve.title}</span>
			</div>
			<div id="Shadow_Space_PC_Scroll_description_event_web_5">
				<div id="Shadow_Space_PC_offering_lbl_med_web_5_pc">
					<span>{eve.description}</span>
				</div>
			</div>
			<div id="Shadow_Space_PC_date_web_5_pc_event">
				<div id="Shadow_Space_PC_date_shadow_space_website_5">
					<span>{eve.startsOn.slice(0,10)}</span>
				</div>
				<img id="Shadow_Space_PC_calendar_2_shadow_space_websit" src="./Themes/ShadowSpace/calendar_2_shadow_space_websit.png" srcset="./Themes/ShadowSpace/calendar_2_shadow_space_websit.png 1x" />
					
			</div>
			<div id="Shadow_Space_PC_time_web_5_pc_event">
				<div id="Shadow_Space_PC_date_shadow_space_website_5_el">
					<span>{eve.startsOn.slice(11,16)} IST</span>
				</div>
				<img id="Shadow_Space_PC_clock_1_shadow_space_website_5" src="./Themes/ShadowSpace/clock_1_shadow_space_website_5.png" srcset="./Themes/ShadowSpace/clock_1_shadow_space_website_5.png 1x" />
					
			</div>
			<div id="Shadow_Space_PC_person_web_5_pc_event">
				<img id="Shadow_Space_PC_man_2_shadow_space_website_5" src="./Themes/ShadowSpace/man_2_shadow_space_website_5.png" srcset="./Themes/ShadowSpace/man_2_shadow_space_website_5.png 1x" />
					
				<div id="Shadow_Space_PC_date_shadow_space_website_5_ep">
					<span>{eve.organiserName}</span>
				</div>
			</div>
			<div id="Shadow_Space_PC_seats_web_5_pc_event">
				<img id="Shadow_Space_PC_seats_shadow_space_website_5" src="./Themes/ShadowSpace/seats_shadow_space_website_5.png" srcset="./Themes/ShadowSpace/seats_shadow_space_website_5.png 1x" />
					
				<div id="Shadow_Space_PC_dateshadow_space_website_5">
					<span>{eve.seats}</span>
				</div>
			</div>
			<div id="Shadow_Space_PC_price_web_5_pc_event">
				<img id="Shadow_Space_PC_price-tag_shadow_space_website" src="./Themes/ShadowSpace/price-tag_shadow_space_website.png" srcset="./Themes/ShadowSpace/price-tag_shadow_space_website.png 1x" />
					
				<div id="Shadow_Space_PC_date_shadow_space_website_5_ev">
					<span>₹ {eve.cost}</span>
				</div>
			</div>
		</div>
		))}
	</div>
	<svg class="Shadow_Space_PC_Line_shadow_space_fotter_line_" viewBox="0 0 1280 3">
		<path id="Shadow_Space_PC_Line_shadow_space_fotter_line_" d="M 0 0 L 1280 0">
		</path>
	</svg>
    <a href="#profile_pic_logo_shadow_space_">
	<div id="Shadow_Space_PC_home_shadow_space_website_5">
		<div id="Shadow_Space_PC_Home_shadow_space_website_5_eg">
			<span>Home</span>
		</div>
		<img id="Shadow_Space_PC_home-icon-shadow_space_website" src="./Themes/ShadowSpace/home-icon-shadow_space_website.png" srcset="./Themes/ShadowSpace/home-icon-shadow_space_website.png 1x" />
			
	</div>
    </a>
    <a href="#offering_lbl_shadow_space_webs">
	<div id="Shadow_Space_PC_offering_shadow_space_website_">
		<div id="Shadow_Space_PC_Offerings_shadow_space_website">
			<span>Offerings</span>
		</div>
		<img id="Shadow_Space_PC_discount_shadow_space_website_" src="./Themes/ShadowSpace/discount_shadow_space_website_.png" srcset="./Themes/ShadowSpace/discount_shadow_space_website_.png 1x" />
			
	</div>
    </a>
    <a href="#oofering_lbl_med_shadow_space__du">
	<div id="Shadow_Space_PC_event_grp_shadow_space_website_pc">
		<div id="Shadow_Space_PC_Events_shadow_space_website_5">
			<span>Events</span>
		</div>
		<img id="Shadow_Space_PC_election-event-on-a-calendar-s" src="./Themes/ShadowSpace/election-event-on-a-calendar-s.png" srcset="./Themes/ShadowSpace/election-event-on-a-calendar-s.png 1x" />
			
	</div>
    </a>

    <a href="#Line_shadow_space_fotter_line_">
	<div id="Shadow_Space_PC_contact_grpshadow_space_websit">
		<div id="Shadow_Space_PC_Contact_lbl_shadow_space_websi">
			<span>Contact</span>
		</div>
		<img id="Shadow_Space_PC_contact_img_shadow_space_websi" src="./Themes/ShadowSpace/contact_img_shadow_space_websi.png" srcset="./Themes/ShadowSpace/contact_img_shadow_space_websi.png 1x" />
			
	</div>
    </a>
</div>

  )
}

export default ShadowSpace
