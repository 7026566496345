import React, { useEffect, useState, useContext} from 'react'
// import '../../styles/ViewOfferingMobUI.css';
import { UserContext } from '../UserContext';


const TrophiesAndCountMob = (props) => {
const {user} = useContext(UserContext);
const [count, setCount] = useState(0);
const [trophy,setTrophy] = useState(false);


useEffect(()=>{
    if(props.portfolioId && props.portfolioType){
        handleOnLoad()
    }
   
},[props.portfolioId])



    const handleLike = () =>{
        var apiURL ="";
	switch(props.portfolioType){

        case "offerings" :  apiURL = process.env.REACT_APP_API_URL + "/offerings/updatetrophy/"+props.portfolioId;
            break;
        case "requirements" : apiURL = process.env.REACT_APP_API_URL + "/requirement/updatetrophy/"+props.portfolioId;
            break;
        case "events" : apiURL = process.env.REACT_APP_API_URL + "/events/updatetrophy/"+props.portfolioId;
            break;
    }
        let apiParams = {
          method: "POST",
          headers: {
                  id:user._id,
                  name: user.name,
                  nickname:user.nickName,	
                  imageid:user.imageId,
                 
          },
        };
        fetch(apiURL, apiParams)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
                // props.refreshed()
             // alert("Trophy Updated Successfully!!")
             handleOnLoad()
            }
          })
          .catch((err) => alert(err));
          return false
      };

      const handleOnLoad = ()=>{
        var apiURL = ""
        switch(props.portfolioType){

            case "offerings" :  apiURL = process.env.REACT_APP_API_URL + "/offerings/getallofferingsbyoffid/" + props.portfolioId;
                break;
            case "requirements" : apiURL=process.env.REACT_APP_API_URL + "/requirement/getallrequirementsbyreqid/" +props.portfolioId;
                break;
            case "events" : apiURL = process.env.REACT_APP_API_URL + "/events/getalleventsbyeveid/"+props.portfolioId;
                break;
        }
      
        let apiParams = {
            method:"GET",
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
        
        // console.log(JSON.parse(repos.data))
             setCount(repos.data.trophies ? JSON.parse(repos.data.trophies).length:0)
            setTrophy(repos.data.trophies && JSON.parse(repos.data.trophies).filter((trp)=>trp.id===user._id).length>0)
            }else alert(repos.message || repos);  
        }) 
        .catch((err) => alert(err));
    
    }

  return (
    <div style={{maxWidth:"125px",display:"block",width:"auto"}}>
      <img title="Like"  src={trophy ?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"} onClick={()=>{handleLike();}} style={{position:"relative",width:"23px",height:"23px",cursor:"pointer",display:"inline-block",overflow:"hidden"}}/>
			
            <div style={{position:"relative",top:"0px",margin:"5px",maxWidth:"90px",height:"23px",verticalAlign:"middle",textAlign:"left",display:"inline-block"}}>
                <span>{count}</span>
            </div>
    </div>
  )
}

export default TrophiesAndCountMob
