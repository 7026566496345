import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioOfferingMobUI.css';
import { UserContext } from '../UserContext';
import MyOffandComOffMob from './MyOffandComOffMob';

const MyPortfolioOfferingMobUI = (props) => {
  return (
   
      <div id="portfolio_off">
	<div id="heading_grp_offreqevent_off_mo">
		<div id="offreqevent_off_mob_req" onClick={()=>props.selectedTab("requirements")}>
			<span>Requirements</span>
		</div>
		<div id="selected_grp_offreqevent_off_m">
			<div id="offering_offreqevent_off_mob">
				<span>Offerings</span>
			</div>
		</div>
		<div id="events_offreqevent_off_mob" onClick={()=>props.selectedTab("events")}>
			<span>Events</span>
		</div>
		<svg class="underline_offreqevent_off_mob">
			<rect id="underline_offreqevent_off_mob" rx="0" ry="0" x="0" y="0" width="61" height="3">
			</rect>
		</svg>
	</div>
	<img id="settings-sliders_offreqevent_o" src="./MyPortfolio/settings-sliders_offreqevent_o.png" srcset="./MyPortfolio/settings-sliders_offreqevent_o.png 1x" />

		<MyOffandComOffMob />
</div>

  )
}

export default MyPortfolioOfferingMobUI
