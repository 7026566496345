import React from 'react';
import "../../../styles/FurniGemMob.css";

function FurniGemMob(props) {
  return (
    <div id="FurniGem_mob">
        <div id="thm15FG_mobGroup_14967">
            <div id="thm15FG_mobTransform__Your_Space">
                <span>Transform <br/>Your Space</span>
            </div>
            <img id="thm15FG_mobAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"Anime_Edit.png"} />
                
            <div id="thm15FG_mobTransform_Your_Space_With_Our_">
                <span>Transform Your Space With Our <br/>                Stunning Furniture Collection</span>
            </div>
            <img id="thm15FG_mobdi_an_h-g_8MrEZAvyE-unsplash" src="./Themes/FurniGem/di_an_h-g_8MrEZAvyE-unsplash.png" srcset="./Themes/FurniGem/di_an_h-g_8MrEZAvyE-unsplash.png 1x" />
                
            <div id="thm15FG_mobScroll_Group_49">
                <div id="thm15FG_mobIntroduction">
                    <span>{props.data.introduction}</span>
                </div>
            </div>
            <div id="thm15FG_mobScroll_Group_54">
                <div id="thm15FG_mobGroup_14963">
                    <img id="thm15FG_mobguzman-barquin-FkKClUPUURU-uns" src="./Themes/FurniGem/guzman-barquin-FkKClUPUURU-uns.png" srcset="./Themes/FurniGem/guzman-barquin-FkKClUPUURU-uns.png 1x" />
                        
                    <img id="thm15FG_mobspacejoy-YqFz7UMm8qE-unsplash" src="./Themes/FurniGem/spacejoy-YqFz7UMm8qE-unsplash.png" srcset="./Themes/FurniGem/spacejoy-YqFz7UMm8qE-unsplash.png 1x" />
                        
                    <img id="thm15FG_mobmyxa69-nZnVdtlJvbw-unsplash" src="./Themes/FurniGem/myxa69-nZnVdtlJvbw-unsplash.png" srcset="./Themes/FurniGem/myxa69-nZnVdtlJvbw-unsplash.png 1x" />
                        
                </div>
            </div>
            <div id="thm15FG_mobCompany_Name">
                <span>{props.data.businessName}</span>
            </div>
        </div>
        <div id="thm15FG_mobGroup_14961">
            <svg class="thm15FG_mobRectangle_707">
                <rect id="thm15FG_mobRectangle_707" rx="0" ry="0" x="0" y="0" width="336" height="330">
                </rect>
            </svg>
            <img id="thm15FG_mobkisspng-furniture-poster-couch" src="./Themes/FurniGem/kisspng-furniture-poster-couch.png" srcset="./Themes/FurniGem/kisspng-furniture-poster-couch.png 1x" />
                
            <div id="thm15FG_mobScroll_Group_51">
                <div id="thm15FG_mobElevate_Your_Space_with_Timele">
                    <span>"Elevate Your Space with Timeless <br/>Elegance and Uncompromising Size."</span>
                </div>
            </div>
            <div id="thm15FG_mobVision">
                <span>Vision</span>
            </div>
        </div>
        <div id="thm15FG_mobGroup_14964">
            <svg class="thm15FG_mobRectangle_707_de">
                <rect id="thm15FG_mobRectangle_707_de" rx="0" ry="0" x="0" y="0" width="336" height="330">
                </rect>
            </svg>
            <img id="thm15FG_mobkisspng-window-living-room-cur" src="./Themes/FurniGem/kisspng-window-living-room-cur.png" srcset="./Themes/FurniGem/kisspng-window-living-room-cur.png 1x" />
                
            <div id="thm15FG_mobScroll_Group_51_dg">
                <div id="thm15FG_mobElevate_Your_Space_with_Timele_dh">
                    <span>"Elevate Your Space with Timeless <br/>Elegance and Uncompromising Size."</span>
                </div>
            </div>
            <div id="thm15FG_mobMission">
                <span>Mission</span>
            </div>
        </div>
        <div id="thm15FG_mobGroup_14965">
            <svg class="thm15FG_mobRectangle_707_dk">
                <rect id="thm15FG_mobRectangle_707_dk" rx="0" ry="0" x="0" y="0" width="336" height="330">
                </rect>
            </svg>
            <img id="thm15FG_mobsuchit-poojari-ljRiZl00n18-uns" src="./Themes/FurniGem/suchit-poojari-ljRiZl00n18-uns.png" srcset="./Themes/FurniGem/suchit-poojari-ljRiZl00n18-uns.png 1x" />
                
            <div id="thm15FG_mobScroll_Group_51_dm">
                <div id="thm15FG_mobElevate_Your_Space_with_Timele_dn">
                    <span>"Elevate Your Space with Timeless <br/>Elegance and Uncompromising Size."</span>
                </div>
            </div>
            <div id="thm15FG_mobPrinciples">
                <span>Principles</span>
            </div>
        </div>
        <div id="thm15FG_mobProduct">
            <span>Product</span>
        </div>
        <div id="thm15FG_mobScroll_Group_50">
	{props.offeringsData.map((off,idx)=>(
            <div id="thm15FG_mobGroup_14957">
                <svg class="thm15FG_mobRectangle_708">
                    <rect id="thm15FG_mobRectangle_708" rx="0" ry="0" x="0" y="0" width="248" height="318">
                    </rect>
                </svg>
                    <img id="thm15FG_mobavery-klein-JaXs8Tk5Iww-unspla" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/FurniGem/avery-klein-JaXs8Tk5Iww-unspla.png"} />
                    
                <div id="thm15FG_mobChair">
                    <span>{off.offeringTitle}</span>
                </div>
            </div>))}
        </div>
        <svg class="thm15FG_mobRectangle_711">
            <rect id="thm15FG_mobRectangle_711" rx="0" ry="0" x="0" y="0" width="390" height="695">
            </rect>
        </svg>
        <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="thm15FG_mobRectangle_712">
            <rect id="thm15FG_mobRectangle_712" rx="0" ry="0" x="0" y="0" width="353" height="209">
            </rect>
        </iframe>
        <div id="thm15FG_mobVisit_Us">
            <span>Visit Us!</span>
        </div>
        <div id="thm15FG_mobn_234567890_mobile_number_shad">
            <span>{props.data.businessPhone}</span>
        </div>
        <div id="thm15FG_mobaffanghanioperatelive_email_sh">
            <span>{props.data.businessEmail}</span>
        </div>
        <div id="thm15FG_mobaddress_med_shadow_space_websi">
            <span>{props.data.businessAddress}</span>
        </div>
        <img id="thm15FG_moblocation_img_shadow_space_webs" src="./Themes/FurniGem/location_img_shadow_space_webs.png" srcset="./Themes/FurniGem/location_img_shadow_space_webs.png 1x" />
            
        <img id="thm15FG_mobemail_address_shadow_space_web" src="./Themes/FurniGem/email_address_shadow_space_web.png" srcset="./Themes/FurniGem/email_address_shadow_space_web.png 1x" />
            
        <img id="thm15FG_mobphone_number_shadow_space_webs" src="./Themes/FurniGem/phone_number_shadow_space_webs.png" srcset="./Themes/FurniGem/phone_number_shadow_space_webs.png 1x" />
            
        <svg class="thm15FG_mobLine_29" viewBox="0 0 390 2">
            <path id="thm15FG_mobLine_29" d="M 0 0 L 390 0">
            </path>
        </svg>
        <div id="thm15FG_mobsocials_grp_shadow_space_websi">
            <div title="LinkedIn" id="thm15FG_moblinked_in_grp_shadow_space_web">
                <svg class="thm15FG_mobEllipse_5shadow_space_website_">
                    <ellipse id="thm15FG_mobEllipse_5shadow_space_website_" rx="25" ry="25" cx="25" cy="25">
                    </ellipse>
                </svg>
                <div id="thm15FG_moblinked_in_shadow_space_website">
                    <a href="https://www.linkedin.com/in/mohammed-affan-3b0533229/" target="_blank">
                    <svg class="thm15FG_mobn_website_2_layout" viewBox="-15.255 0 16.092 15.182">
                        <path id="thm15FG_mobn_website_2_layout" d="M -5.186071872711182 9.679508821136551e-07 C -7.904781818389893 9.679508821136551e-07 -9.60834789276123 1.472553014755249 -9.923189163208008 2.504196882247925 L -9.923189163208008 0.1445503830909729 L -15.2549991607666 0.1445503830909729 C -15.18569564819336 1.397309064865112 -15.2549991607666 15.18160820007324 -15.2549991607666 15.18160820007324 L -9.923189163208008 15.18160820007324 L -9.923189163208008 7.044621467590332 C -9.923189163208008 6.592491626739502 -9.944971084594727 6.140363693237305 -9.807681083679199 5.815624713897705 C -9.440696716308594 4.911367893218994 -8.653267860412598 3.972789287567139 -7.237480163574219 3.972789287567139 C -5.38672399520874 3.972789287567139 -4.544512748718262 5.363495349884033 -4.544512748718262 7.400383949279785 L -4.544512748718262 15.18160820007324 L 0.8368034362792969 15.18160820007324 L 0.8368034362792969 6.820206165313721 C 0.8368034362792969 2.162956237792969 -1.813262462615967 9.679508821136551e-07 -5.186071872711182 9.679508821136551e-07">
                        </path>
                    </svg>
                </a>
                    <svg class="thm15FG_moblinked_in_i_down" viewBox="0.931 -22.783 4.746 15.038">
                        <path id="thm15FG_moblinked_in_i_down" d="M 0.9310001134872437 -7.745282173156738 L 5.676695346832275 -7.745282173156738 L 5.676695346832275 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -7.745282173156738 Z">
                        </path>
                    </svg>
                    <svg class="thm15FG_mobtop_dot_i_website_2_layout" viewBox="-4.392 0 5.761 5.218">
                        <path id="thm15FG_mobtop_dot_i_website_2_layout" d="M -1.493098974227905 0 C -3.245508193969727 0 -4.392000198364258 1.125370144844055 -4.392000198364258 2.611123323440552 C -4.392000198364258 4.06783390045166 -3.278510093688965 5.217626571655273 -1.560423135757446 5.217626571655273 L -1.526101112365723 5.217626571655273 C 0.2579902410507202 5.217626571655273 1.368839383125305 4.063873767852783 1.368839383125305 2.606502532958984 C 1.334516644477844 1.122730016708374 0.2579902410507202 0 -1.493098974227905 0">
                        </path>
                    </svg>
                </div>
            </div>
            <div title="Instagram" id="thm15FG_mobInsta_grp_med_shadow_space_web">
                <svg class="thm15FG_mobEllipseshadow_space_website_5_">
                    <ellipse id="thm15FG_mobEllipseshadow_space_website_5_" rx="25" ry="25" cx="25" cy="25">
                    </ellipse>
                </svg>
                <a href="https://www.instagram.com/mohammed_affan_78/?next=%2F" target="_blank">
                <img id="thm15FG_mobinstagram_shadow_space_website" src="./Themes/FurniGem/instagram_shadow_space_website.png" srcset="./Themes/FurniGem/instagram_shadow_space_website.png 1x" />
                    
                </a>
            </div>
            <div title="WhatsApp" id="thm15FG_mobwhatsapp_grpshadow_space_websi">
                <svg class="thm15FG_mobEllipse_3_shadow_space_website">
                    <ellipse id="thm15FG_mobEllipse_3_shadow_space_website" rx="25" ry="25" cx="25" cy="25">
                    </ellipse>
                </svg>
                <img id="thm15FG_mobwhatsapp_lbl_shadow_space_webs" src="./Themes/FurniGem/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/FurniGem/whatsapp_lbl_shadow_space_webs.png 1x" />
                    
            </div>
        </div>
    </div>
  )
}

export default FurniGemMob
