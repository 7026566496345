import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioOffCardsMob.css';
import { UserContext } from '../UserContext';

const MyPortfolioOffCardsMob = (props) => {

	const {user} = useContext(UserContext);
	 


	const handleLike = (offId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/offerings/updatetrophy/"+offId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
			  return false
		  };
	

  return (
	<div id="OFFM_card_1_reqoffevent_off">
	<svg class="OFFM_Base_reqoffevent_off">
		<rect id="OFFM_Base_reqoffevent_off" rx="10" ry="10" x="0" y="0" width="170" height="170">
		</rect>
	</svg>
	<svg class="OFFM_bdr_1_reqoffevent_off">
		<rect id="OFFM_bdr_1_reqoffevent_off" rx="11" ry="11" x="0" y="0" width="40" height="22">
		</rect>
	</svg>
	<div id="OFFM_n_90_reqoffevent_of">
		<span>{props.data.offeredcurrency && JSON.parse(props.data.offeredcurrency).symbol} {props.data.budget}</span>
	</div>
	<div id="OFFM_Single_Tour_reqoffevent_of">
		<span>{props.data.offeringTitle}</span>
	</div>
	<div id="OFFM_date_reqoffevent">
		<span>{( props.data.deliveryDuration ? props.data.deliveryDuration:"")} Hours | {props.data.deliveryLocation}</span>
	</div>
	<img id="OFFM_online-shopping_reqoffeven" src={props.data.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultOfferingsImageUrl.split('/')[5]):"./MyPortfolio/Offerings/online-shopping_reqoffevent_of.png"} />
		
	<div id="OFFM_Group_131">
		<svg class="OFFM_Ellipse_1">
			<ellipse id="OFFM_Ellipse_1" rx="13.5" ry="13.5" cx="13.5" cy="13.5">
			</ellipse>
		</svg>
		<img id="OFFM_trophy_reqoffevent_off" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"} onClick={()=>handleLike(props.data._id)}/>
			
	</div>
	<div id="OFFM_Group_130">
		<svg class="OFFM_Rectangle_31">
			<rect id="OFFM_Rectangle_31" rx="0" ry="0" x="0" y="0" width="24" height="15">
			</rect>
		</svg>
		<div id="OFFM_n_234_reqoffevent_off">
			<span>{(JSON.parse(props.data.trophies)).length}</span>
		</div>
	</div>
	<div id="OFFM_Group_132">
		<svg class="OFFM_Ellipse_2">
			<ellipse id="OFFM_Ellipse_2" rx="13.5" ry="13.5" cx="13.5" cy="13.5">
			</ellipse>
		</svg>
		<img id="OFFM_share_1_reqoffevent_off" src="./MyPortfolio/Offerings/share_1_reqoffevent_off_PC.png" srcset="./MyPortfolio/Offerings/share_1_reqoffevent_off_PC.png 1x" />
			
	</div>
</div>

  
  )
}

export default MyPortfolioOffCardsMob
