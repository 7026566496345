import React from 'react';
import "../../../styles/RadiantLocks.css";

function RadiantLocks(props) {
  return (
    <div id="RadiantLocks">
	<div id="RLGroup_15101">
		<div id="RLGroup_15041">
			<div id="RLGroup_15040">
				<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="RLRectangle_737">
					<rect id="RLRectangle_737" rx="0" ry="0" x="0" y="0" width="538" height="302">
					</rect>
				</iframe>
			</div>
			<div id="RLsocials_grp_shadow_space_websi">
{props.data.linkedInUrl?
				<div title="LinkedIn" id="RLlinked_in_grp_shadow_space_web">
					<svg class="RLmed_shadow_space_website_7">
						<ellipse id="RLmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="RLlinked_in_shadow_space_website">
						<a href={props.data.linkedInUrl} target="_blank">
						<svg class="RLn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="RLn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="RLlinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="RLlinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="RLtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="RLtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>
:""}
{props.data.instagramUrl?	
				<div title="Instagram" id="RLInsta_grp_shadow_space_website">
					<svg class="RLshadow_space_website_7">
						<ellipse id="RLshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={props.data.instagramUrl} target="_blank">
					<img id="RLinstagramshadow_space_website_" src="./Themes/RadiantLocks/instagramshadow_space_website_.png" srcset="./Themes/RadiantLocks/instagramshadow_space_website_.png 1x" />
						
					</a>
				</div>
:""}
{props.data.whatsAppNumber?
				<div title="WhatsApp" id="RLwhatsapp_grp_shadow_space_webs">
					<svg class="RLmed_shadow_space_website_7_bn">
						<ellipse id="RLmed_shadow_space_website_7_bn" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
<img id="RLwhatsapp_lbl_shadow_space_webs" src="./Themes/RadiantLocks/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/RadiantLocks/whatsapp_lbl_shadow_space_webs.png 1x" />
</a>		
				</div>
:""}
			</div>
			<div id="RLn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="RLaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="RLaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="RLlocation_img_shadow_space_webs" src="./Themes/RadiantLocks/location_img_shadow_space_webs.png" srcset="./Themes/RadiantLocks/location_img_shadow_space_webs.png 1x" />
				
			<img id="RLemail_address_shadow_space_web" src="./Themes/RadiantLocks/email_address_shadow_space_web.png" srcset="./Themes/RadiantLocks/email_address_shadow_space_web.png 1x" />
				
			<img id="RLphone_number_shadow_space_webs" src="./Themes/RadiantLocks/phone_number_shadow_space_webs.png" srcset="./Themes/RadiantLocks/phone_number_shadow_space_webs.png 1x" />
				
			<div id="RLn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
			</div>
		</div>
		<div id="RLContact_Us">
			<span>Contact Us!</span>
		</div>
	</div>
	<div id="RLGroup_15151">
		<div id="RLGroup_15141">
			<svg class="RLRectangle_752">
				<rect id="RLRectangle_752" rx="0" ry="0" x="0" y="0" width="915" height="376">
				</rect>
			</svg>
			<img id="RLjamie-street-aMuq-Xz7R_M-unspl" src="./Themes/RadiantLocks/jamie-street-aMuq-Xz7R_M-unspl.png" srcset="./Themes/RadiantLocks/jamie-street-aMuq-Xz7R_M-unspl.png 1x" />
				
			<div id="RLScroll_Group_28">
				<div id="RLIt_is_a_long_established_fact_">
					<span>{props.data.vision}</span>
				</div>
			</div>
			<div id="RLVISION">
				<span>VISION</span>
			</div>
		</div>
		<div id="RLGroup_15142">
			<svg class="RLRectangle_752_b">
				<rect id="RLRectangle_752_b" rx="0" ry="0" x="0" y="0" width="915" height="376">
				</rect>
			</svg>
			<img id="RLshari-sirotnak-ajzbkcRy-wU-uns" src="./Themes/RadiantLocks/shari-sirotnak-ajzbkcRy-wU-uns.png" srcset="./Themes/RadiantLocks/shari-sirotnak-ajzbkcRy-wU-uns.png 1x" />
				
			<div id="RLScroll_Group_28_b">
				<div id="RLIt_is_a_long_established_fact__b">
					<span>{props.data.mission}</span>
				</div>
			</div>
			<div id="RLMISSION">
				<span>MISSION</span>
			</div>
		</div>
		<div id="RLGroup_15143">
			<svg class="RLRectangle_752_cb">
				<rect id="RLRectangle_752_cb" rx="0" ry="0" x="0" y="0" width="915" height="376">
				</rect>
			</svg>
			<img id="RLjamie-street-aMuq-Xz7R_M-unspl_cc" src="./Themes/RadiantLocks/jamie-street-aMuq-Xz7R_M-unspl_cc.png" srcset="./Themes/RadiantLocks/jamie-street-aMuq-Xz7R_M-unspl_cc.png 1x" />
				
			<div id="RLScroll_Group_28_cd">
				<div id="RLIt_is_a_long_established_fact__ce">
					<span>{props.data.principles}</span>
				</div>
			</div>
			<div id="RLPRINCIPLES">
				<span>PRINCIPLES</span>
			</div>
		</div>
	</div>
	<div id="RLGroup_15150">
		<div id="RLOfferings">
			<span>Offerings</span>
		</div>
		<div id="RLGroup_15145">
			<svg class="RLRectangle_753">
				<rect id="RLRectangle_753" rx="3" ry="3" x="0" y="0" width="185" height="61">
				</rect>
			</svg>
			<div id="RLView_More">
				<span>View More</span>
			</div>
		</div>
		<div id="RLScroll_Group_91">
			<div id="RLGroup_15144">
				<svg class="RLRectangle_754">
					<rect id="RLRectangle_754" rx="10" ry="10" x="0" y="0" width="243" height="331">
					</rect>
				</svg>
				<img id="RLbaylee-gramling-a3xr2mVjT5M-un" src="./Themes/RadiantLocks/baylee-gramling-a3xr2mVjT5M-un.png" srcset="./Themes/RadiantLocks/baylee-gramling-a3xr2mVjT5M-un.png 1x" />
					
				<div id="RLTitle">
					<span>Title</span>
				</div>
				<div id="RLn_10000">
					<span>₹ 10,000</span>
				</div>
			</div>
		</div>
	</div>
	<div id="RLGroup_15139">
		<svg class="RLRectangle_752_cs">
			<rect id="RLRectangle_752_cs" rx="0" ry="0" x="0" y="0" width="1366" height="157">
			</rect>
		</svg>
		<div id="RLLorem_Ipsum_is_simply_dummy_te">
			<span>{props.data.introduction}</span>
		</div>
	</div>
	<div id="RLGroup_15086" class="fadeIn">
		<img id="RLMask_Group_1" src="./Themes/RadiantLocks/Mask_Group_1.png" srcset="./Themes/RadiantLocks/Mask_Group_1.png 1x" />
			
		<svg class="RLRectangle_3">
			<rect id="RLRectangle_3" rx="24.5" ry="24.5" x="0" y="0" width="177" height="49">
			</rect>
		</svg>
		<div id="RLOrder_Now">
			<span>Order Now</span>
		</div>
		<div id="RLWhere_Beauty_Meets__Confidence">
			<span>"Where Beauty Meets <br/>Confidence, Every <br/>Appointment."</span>
		</div>
		<div id="RLChoose_one_of_our_plans_enter_">
			<span>Choose one of our plans, enter delivery time<br/>and enjoy delicious food without leaving your home!</span>
		</div>
		<div id="RLCompany_Name">
			<span>{props.data.businessName}</span>
		</div>
	</div>
</div>
  )
}

export default RadiantLocks
