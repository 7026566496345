import React, { useState, useEffect, useContext } from "react";
import { Button, Divider } from "@mui/material";
import "../../styles/CalanderEntryPopUpUI.css";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import Modal from "@mui/material/Modal";
import AddEventsToCalander from "./AddEventsToCalander";
import AddAlarmToCalander from "./AddAlarmToCalander";
import AddTaskToCalander from "./AddTaskToCalander";
import AddMeetingToCalander from "./AddMeetingToCalander";

const CalanderEntryPopUpUI = (props) => {

    const [openPop,setOpenPop] = useState(false);
    const [slotType,setSlotType] = useState("event");
    const [searchParams] = useSearchParams();
    const { user, setUser } = useContext(UserContext);
    var navigate = useNavigate();
    const [eventData,setEventData] = useState({});
  const [slotime,setSlotime] = useState(new Date().toISOString().substring(0,16))

    useEffect(()=>{
        if(props.OpenCalanderEntryPop){
            setOpenPop(props.OpenCalanderEntryPop)
            setSlotType(props.type)
        }
    },[props.OpenCalanderEntryPop])

    useEffect(()=>{
    
      if(props.time){
        setSlotime(props.time)
      }
    },[props.time])

    const handleClose = ()=>{
        setOpenPop(false)
        props.closePop()
    }





  return (
    <Modal
          open={openPop}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflow: "scroll", textAlign: "center" }}
        >
      <div id="add_calander_pop">

	<img id="over_all_bg_of_add_calender_ev" src="./CalanderDashboard/over_all_bg_of_add_calender_ev.png" srcset="./CalanderDashboard/over_all_bg_of_add_calender_ev.png 1x" />
		
	<img id="close_btn_add_calender_event" src="./CalanderDashboard/close_btn_add_calender_event.png" srcset="./CalanderDashboard/close_btn_add_calender_event.png 1x" onClick={()=>handleClose()}/>
		
	<div id="add_cal_lbl_add_calender_event">
		<span>Add To Calander</span>
	</div>

    <div id="add_calender_event_grp_add_eve">
			<select class="event_name_bdr_add_calender_ev" value={slotType} onChange={(e)=>setSlotType(e.target.value)}>
			<option value="alarm">Alarm</option>
			<option value="task">Task</option>
			<option value="meeting">Meeting</option>
			<option value="event">Event</option> 	
			</select>
			<div id="Event_Name_lbl_add_calender_ev">
				<span>Slot Type</span>
			</div>
		</div>

    {slotType==="event" ?<AddEventsToCalander dateAndTime={slotime} close={()=>handleClose()}/>:""}
    {slotType==="alarm" ?<AddAlarmToCalander dateAndTime={slotime} close={()=>handleClose()}/>:""}
    {slotType==="task" ?<AddTaskToCalander dateAndTime={slotime} close={()=>handleClose()}/>:""}
    {slotType==="meeting" ?<AddMeetingToCalander dateAndTime={slotime} close={()=>handleClose()}/>:""}
      </div>
    </Modal>
  )
}

export default CalanderEntryPopUpUI
