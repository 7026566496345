import React from 'react';
import "../../../styles/TrueVision.css";

function TrueVision(props) {
  return (
    <div id="True_Vision">
	<svg class="TVRectangle_760">
		<rect id="TVRectangle_760" rx="0" ry="0" x="0" y="0" width="1366" height="40">
		</rect>
	</svg>
	<svg class="TVRectangle_761">
		<rect id="TVRectangle_761" rx="0" ry="0" x="0" y="0" width="1366" height="40">
		</rect>
	</svg>
	<svg class="TVRectangle_756">
		<rect id="TVRectangle_756" rx="0" ry="0" x="0" y="0" width="1366" height="40">
		</rect>
	</svg>
	<svg class="TVRectangle_759">
		<rect id="TVRectangle_759" rx="0" ry="0" x="0" y="0" width="1366" height="40">
		</rect>
	</svg>
	<svg class="TVRectangle_757">
		<rect id="TVRectangle_757" rx="0" ry="0" x="0" y="0" width="1366" height="40">
		</rect>
	</svg>
	<svg class="TVRectangle_758">
		<rect id="TVRectangle_758" rx="0" ry="0" x="0" y="0" width="1366" height="40">
		</rect>
	</svg>
	<div id="TVGroup_15167">
		<img id="TVvolodymyr-hryshchenko-NQQ_WKbj" src="./Themes/TrueVision/volodymyr-hryshchenko-NQQ_WKbj.png" srcset="./Themes/TrueVision/volodymyr-hryshchenko-NQQ_WKbj.png 1x" />
			
		<img id="TVAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/TrueVision/Anime_Edit.png"} />
			
		<div id="TVYour_Business_Name">
			<span>{props.data.businessName}</span>
		</div>
		<div id="TVSee_the_world_through_a_cleare">
			<span>"See the world through a clearer lens, <br/>and let your vision become a work of art."</span>
		</div>
	</div>
	<div id="TVGroup_15118">
		<div id="TVGroup_15114">
			<img id="TVjoshua-newton-g4_IymCiD-k-unsp" src="./Themes/TrueVision/joshua-newton-g4_IymCiD-k-unsp.png" srcset="./Themes/TrueVision/joshua-newton-g4_IymCiD-k-unsp.png 1x" />
				
			<div id="TVScroll_Group_28">
				<div id="TVIt_is_a_long_established_fact_">
					<span>{props.data.vision}</span>
				</div>
			</div>
			<div id="TVVISION">
				<span>VISION</span>
			</div>
		</div>
		<div id="TVGroup_15115">
			<img id="TVfelipepelaquim-0q5Y7OFmMhM-uns" src="./Themes/TrueVision/felipepelaquim-0q5Y7OFmMhM-uns.png" srcset="./Themes/TrueVision/felipepelaquim-0q5Y7OFmMhM-uns.png 1x" />
				
			<div id="TVScroll_Group_28_">
				<div id="TVIt_is_a_long_established_fact__">
					<span>{props.data.mission}</span>
				</div>
			</div>
			<div id="TVMISSION">
				<span>MISSION</span>
			</div>
		</div>
		<div id="TVGroup_15116">
			<img id="TVdmitry-ratushny-wpi3sDUrSEk-un" src="./Themes/TrueVision/dmitry-ratushny-wpi3sDUrSEk-un.png" srcset="./Themes/TrueVision/dmitry-ratushny-wpi3sDUrSEk-un.png 1x" />
				
			<div id="TVScroll_Group_28_bb">
				<div id="TVIt_is_a_long_established_fact__bc">
					<span>{props.data.principles}</span>
				</div>
			</div>
			<div id="TVPRINCIPLES">
				<span>PRINCIPLES</span>
			</div>
		</div>
	</div>
	<div id="TVGroup_15119">
		<div id="TVScroll_Group_77">
			<div id="TVn_">
				<img id="TVMask_Group_22" src="./Themes/TrueVision/Mask_Group_22.png" srcset="./Themes/TrueVision/Mask_Group_22.png 1x" />
					
				<svg class="TVRectangle_664">
					<rect id="TVRectangle_664" rx="0" ry="0" x="0" y="0" width="307" height="46">
					</rect>
				</svg>
				<div id="TVCOSMOS">
					<span>COSMOS</span>
				</div>
				<div id="TVGroup_10773">
					<svg class="TVEllipse_38">
						<ellipse id="TVEllipse_38" rx="2.5" ry="2.5" cx="2.5" cy="2.5">
						</ellipse>
					</svg>
					<svg class="TVEllipse_39">
						<ellipse id="TVEllipse_39" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
					<svg class="TVEllipse_40">
						<ellipse id="TVEllipse_40" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
					<svg class="TVEllipse_41">
						<ellipse id="TVEllipse_41" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
					<svg class="TVEllipse_42">
						<ellipse id="TVEllipse_42" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
				</div>
			</div>
		</div>
		<div id="TVWIDE_RANGE_OF_DESIGN">
			<span>WIDE RANGE OF DESIGN</span>
		</div>
	</div>
	<div id="TVGroup_15101">
		<div id="TVGroup_15041">
			<div id="TVGroup_15040">
				<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="TVRectangle_737">
					<rect id="TVRectangle_737" rx="0" ry="0" x="0" y="0" width="538" height="302">
					</rect>
				</iframe>
			</div>
			<div id="TVsocials_grp_shadow_space_websi">
{props.data.linkedInUrl?
				<div title="LinkedIn" id="TVlinked_in_grp_shadow_space_web">
					<svg class="TVmed_shadow_space_website_7">
						<ellipse id="TVmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="TVlinked_in_shadow_space_website">
						<a href={props.data.linkedInUrl} target="_blank">
						<svg class="TVn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="TVn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="TVlinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="TVlinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="TVtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="TVtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>
:""}
{props.data.instagramUrl?	

				<div title="Instagram" id="TVInsta_grp_shadow_space_website">
					<svg class="TVshadow_space_website_7">
						<ellipse id="TVshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={props.data.instagramUrl} target="_blank">
					<img id="TVinstagramshadow_space_website_" src="./Themes/TrueVision/instagramshadow_space_website_.png" srcset="./Themes/TrueVision/instagramshadow_space_website_.png 1x" />
						
					</a>
				</div>			
:""}
{props.data.whatsAppNumber?
				<div title="WhatsApp" id="TVwhatsapp_grp_shadow_space_webs">
					<svg class="TVmed_shadow_space_website_7_b">
						<ellipse id="TVmed_shadow_space_website_7_b" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
<img id="TVwhatsapp_lbl_shadow_space_webs" src="./Themes/TrueVision/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/TrueVision/whatsapp_lbl_shadow_space_webs.png 1x" />
</a>						
				</div>
:""}
			</div>
			<div id="TVn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="TVaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="TVaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="TVlocation_img_shadow_space_webs" src="./Themes/TrueVision/location_img_shadow_space_webs.png" srcset="./Themes/TrueVision/location_img_shadow_space_webs.png 1x" />
				
			<img id="TVemail_address_shadow_space_web" src="./Themes/TrueVision/email_address_shadow_space_web.png" srcset="./Themes/TrueVision/email_address_shadow_space_web.png 1x" />
				
			<img id="TVphone_number_shadow_space_webs" src="./Themes/TrueVision/phone_number_shadow_space_webs.png" srcset="./Themes/TrueVision/phone_number_shadow_space_webs.png 1x" />
				
			<div id="TVn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
			</div>
		</div>
		<div id="TVContact_Us">
			<span>Contact Us!</span>
		</div>
	</div>
</div>
  )
}

export default TrueVision
