import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioReqCards.css';
import { UserContext } from '../UserContext';

const MyPortfolioReqCards = (props) => {


	const {user} = useContext(UserContext);
	 


	const handleLike = (reqId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/requirement/updatetrophy/"+reqId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
		  };
	

  return (
    <div>
      <div id="card_1_reqoffevent_req_PC">
				<svg class="Base_reqoffevent_req_PC">
					<rect id="Base_reqoffevent_req_PC" rx="10" ry="10" x="0" y="0" width="270" height="270">
					</rect>
				</svg>
				<svg class="bdr_1_reqoffevent_req_PC">
					<rect id="bdr_1_reqoffevent_req_PC" rx="15.5" ry="15.5" x="0" y="0" width="80" height="31">
					</rect>
				</svg>
				<svg class="trohpie_bdr_small_4_trendingrereq_PC">
					<ellipse id="trohpie_bdr_small_4_trendingrereq_PC" rx="20" ry="18.5" cx="20" cy="18.5">
					</ellipse>
				</svg>
				<svg class="share_bdr_small_4_trending_reqreq_PC">
					<ellipse id="share_bdr_small_4_trending_reqreq_PC" rx="20.5" ry="19" cx="20.5" cy="19">
					</ellipse>
				</svg>
				<div id="n_90_reqoffevent_req_PC">
					<span>{props.data.currency && JSON.parse(props.data.currency).symbol} {props.data.budget}</span>
				</div>
				<div id="Single_Tour_reqoffevent_req_PC">
					<span>{props.data.requirementTitle}</span>
				</div>
				<div id="date_reqoffevent_req_PC">
					<span> {( props.data.deliveryDeadline ? (props.data.deliveryDeadline).substring(0,10):"")} | {props.data.deliveryLocation}</span>
				</div>
				<img id="online-shopping_reqoffevent_req_PC" src={props.data.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultRequirementsImageUrl.split('/')[5]):"./MyPortfolio/Requirements/online-shopping_reqoffevent_of.png"}  />
					
				<img id="trophy_reqoffevent_req_PC" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(props.data._id)} />
					
				<div id="n_234_reqoffevent_req_PC">
					<span>{(JSON.parse(props.data.trophies)).length}</span>
				</div>
				<img id="share_1_reqoffevent_req_PC" src="./MyPortfolio/Requirements/share_1_reqoffevent_off_PC.png" srcset="./MyPortfolio/Requirements/share_1_reqoffevent_off_PC.png 1x" />
					
			</div>
    </div>
  )
}

export default MyPortfolioReqCards
