import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from './UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import '../styles/RequirementsViewMorePopUp.css';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RequirementsViewMorePopUp = (props) => {

    var navigate = useNavigate();
    const communityId = props.communityId;
    const communityName = props.communityName;
    const { user, setUser} = useContext(UserContext);
    const [openGridViewModel,setOpenGridViewModel] = useState(false);
    const [reqDescription,setReqDescription] =useState("");
    const [reqTitle,setReqTitle] = useState("");
    const [ communities,setCommunities] = useState([]);
    const [selectedCommunity,setSelectedCommunity] = useState([]);
    const [selectedCommunities,setSelectedCommunities] = useState([]);

useEffect(()=>{
    if(user){
        if(props.openViewMore){
          
            setOpenGridViewModel(props.openViewMore)
            getCommunities()
            if(props.viewMoreData){
                console.log(props.viewMoreData)
                setReqDescription(props.viewMoreData.description?props.viewMoreData.description:"")
                setReqTitle(props.viewMoreData.requirementTitle?props.viewMoreData.requirementTitle:"")
                 setSelectedCommunities(props.viewMoreData.selectedCommunity?JSON.parse(props.viewMoreData.selectedCommunity):[])
            }
        }
    }

},[props.openViewMore])


const closeGridModel = ()=>{
    setOpenGridViewModel(false)
    props.closeViewMore()
}

 //Get my Communities
 const getCommunities = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/community/getmycommunity/"+user._id; 
  
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        setCommunities(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


  return (
    <div>
    <Modal
        open={openGridViewModel}
        onClose={closeGridModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
      <div id="view_more_pop_up_req">
	<div id="group_popup_req_view">
		<img id="bg_view_req_pop" src="n_6170.png" />
			
		
		<div id="Description_viewPop_req">
			<span>Description</span>
		</div>
		<svg class="website_title_req_view">
			<rect id="website_title_req_view" rx="15" ry="15" x="0" y="0" width="172" height="30">
			</rect>
		</svg>
		<div id="Website_Development_title_req_vm">
			<span>{reqTitle||""}</span>
		</div>
		<div id="Scroll_description_title_pop" style={{height:props.type==="CR"?"150px":""}}>
			<div id="Create_a_website_for__online_req" style={{height:props.type==="CR"?"150px":""}}>
				<span>{reqDescription||""}</span>
			</div>
		</div>
        {/* {props.type==="R" ?	<div id="My_Community_req_pop">
			<span>My Community</span>
		</div>:""} */}
       

       {/* {props.type==="R" ?<Autocomplete
      multiple
      limitTags={1}
      id="checkboxes-tags-demo"
      options={communities}
      size="small"
      disableCloseOnSelect
      getOptionLabel={(option) => option.communityName}
      isOptionEqualToValue={(o, v) => o._id === v._id}
      defaultValue={selectedCommunities?selectedCommunities:[]}
      renderOption={(props, option, { selected }) => (
        
        <li {...props}>
       
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            //style={{ marginRight: 8 }}
            checked={selected}
          />
        
          {option.communityName}
         
        </li>
        
      )}
      onChange={(event, newValue) => {
    
      setSelectedCommunities(newValue);
    }}
      sx={{width: '25ch',maxHeight:"10ch"}}
      renderInput={(params) => (
        <TextField {...params} className="Description_req_view_pop" label="My Communities" placeholder="Communities" />
      )}
    />:""} */}

	</div>
	<img id="coding_req_pop_view" src="coding.png" srcset="coding.png 1x, coding@2x.png 2x" />
    
    <img title="Close" id="close_Req_viewmore_pc"  onClick={()=>{closeGridModel()}} src="close2.png" srcset="close2.png 1x, close2@2x.png 2x" />
      </div>
    </Modal>
    </div>
  )
}

export default RequirementsViewMorePopUp
