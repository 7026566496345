import React from 'react';
import "../../../styles/InkwellHaven.css";

function InkwellHaven(props) {
  return (
    <div id="IWH_PC_33InkwellHaven">
        <div id="IWH_PC_33Group_15156">
            <img id="IWH_PC_33n_1994588_2111w039n0037Bp17" src="./Themes/InkwellHaven/n_1994588_2111w039n0037Bp17.png" srcset="./Themes/InkwellHaven/n_1994588_2111w039n0037Bp17.png 1x" />
                
            <div id="IWH_PC_33Scroll_Group_28">
                <div id="IWH_PC_33It_is_a_long_established_fact_">
                    <span>{props.data.vision}</span>
                </div>
            </div>
            <div id="IWH_PC_33VISION">
                <span>VISION</span>
            </div>
            <div id="IWH_PC_33Scroll_Group_28_">
                <div id="IWH_PC_33It_is_a_long_established_fact__">
                    <span>{props.data.mission}</span>
                </div>
            </div>
            <div id="IWH_PC_33MISSION">
                <span>MISSION</span>
            </div>
            <div id="IWH_PC_33Scroll_Group_28_ba">
                <div id="IWH_PC_33It_is_a_long_established_fact__bb">
                    <span>{props.data.principles}</span>
                </div>
            </div>
            <div id="IWH_PC_33PRINCIPLES">
                <span>PRINCIPLES</span>
            </div>
            <div id="IWH_PC_33Group_15160">
                <svg class="IWH_PC_33Path_7783" viewBox="-6131.819 -2830.473 288.01 66.065">
                    <path id="IWH_PC_33Path_7783" d="M -6131.81884765625 -2764.407470703125 L -6112.63330078125 -2830.472900390625 L -5861.78271484375 -2830.472900390625 L -5843.80908203125 -2764.407470703125 L -6131.81884765625 -2764.407470703125 Z">
                    </path>
                </svg>
                <img id="IWH_PC_33kisspng-learning-education-bla" src="./Themes/InkwellHaven/kisspng-learning-education-bla.png" srcset="./Themes/InkwellHaven/kisspng-learning-education-bla.png 1x" />
                    
            </div>
            <div id="IWH_PC_33Group_15161">
                <svg class="IWH_PC_33Path_7784" viewBox="-6131.819 -2830.473 288.01 66.065">
                    <path id="IWH_PC_33Path_7784" d="M -6131.81884765625 -2764.407470703125 L -6112.63330078125 -2830.472900390625 L -5861.78271484375 -2830.472900390625 L -5843.80908203125 -2764.407470703125 L -6131.81884765625 -2764.407470703125 Z">
                    </path>
                </svg>
                <img id="IWH_PC_33kisspng-school-royalty-free-dr" src="./Themes/InkwellHaven/kisspng-school-royalty-free-dr.png" srcset="./Themes/InkwellHaven/kisspng-school-royalty-free-dr.png 1x" />
                    
            </div>
            <div id="IWH_PC_33Group_15162">
                <svg class="IWH_PC_33Path_7785" viewBox="-6131.819 -2830.473 288.01 66.065">
                    <path id="IWH_PC_33Path_7785" d="M -6131.81884765625 -2764.407470703125 L -6112.63330078125 -2830.472900390625 L -5861.78271484375 -2830.472900390625 L -5843.80908203125 -2764.407470703125 L -6131.81884765625 -2764.407470703125 Z">
                    </path>
                </svg>
                <img id="IWH_PC_33n_359207_19882" src="./Themes/InkwellHaven/n_359207_19882.png" srcset="./Themes/InkwellHaven/n_359207_19882.png 1x" />
                    
            </div>
        </div>
        <div id="IWH_PC_33Group_15158">
            <div id="IWH_PC_33socials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
                <div title="LinkedIn" id="IWH_PC_33linked_in_grp_shadow_space_web">
                    <svg class="IWH_PC_33med_shadow_space_website_7">
                        <ellipse id="IWH_PC_33med_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                        </ellipse>
                    </svg>
                    <div id="IWH_PC_33linked_in_shadow_space_website">
					<a href={props.data.linkedInUrl} target="_blank">
                        <svg class="IWH_PC_33n_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
                            <path id="IWH_PC_33n_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
                            </path>
                        </svg>
                    </a>
                        <svg class="IWH_PC_33linked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
                            <path id="IWH_PC_33linked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
                            </path>
                        </svg>
                        <svg class="IWH_PC_33top_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
                            <path id="IWH_PC_33top_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
                            </path>
                        </svg>
                    </div>
                </div>:""}
			{props.data.instagramUrl?	
                <div title="Instagram" id="IWH_PC_33Insta_grp_shadow_space_website">
                    <svg class="IWH_PC_33shadow_space_website_7">
                        <ellipse id="IWH_PC_33shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                        </ellipse>
                    </svg>
				<a href={props.data.instagramUrl} target="_blank">
                    <img id="IWH_PC_33instagramshadow_space_website_" src="./Themes/InkwellHaven/instagramshadow_space_website_.png" srcset="./Themes/InkwellHaven/instagramshadow_space_website_.png 1x" />
                        
                    </a>
                </div>:""}
                
                {props.data.whatsAppNumber?
                <div title="WhatsApp" id="IWH_PC_33whatsapp_grp_shadow_space_webs">
                    <svg class="IWH_PC_33med_shadow_space_website_7_by">
                        <ellipse id="IWH_PC_33med_shadow_space_website_7_by" rx="24" ry="24" cx="24" cy="24">
                        </ellipse>
                    </svg>
                    <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="IWH_PC_33whatsapp_lbl_shadow_space_webs" src="./Themes/InkwellHaven/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/InkwellHaven/whatsapp_lbl_shadow_space_webs.png 1x" />
                     </a>   
                </div>:""}
            </div>
            <div id="IWH_PC_33n_234567890_mobile_number_shad">
                <span>{props.data.businessPhone}</span>
            </div>
            <div id="IWH_PC_33affanghanioperatelive_email_sh">
                <span>{props.data.businessEmail}</span>
            </div>
            <div id="IWH_PC_33address_med_shadow_space_websi">
                <span>{props.data.businessAddress}</span>
            </div>
            <img id="IWH_PC_33location_img_shadow_space_webs" src="./Themes/InkwellHaven/location_img_shadow_space_webs.png" srcset="./Themes/InkwellHaven/location_img_shadow_space_webs.png 1x" />
                
            <img id="IWH_PC_33email_address_shadow_space_web" src="./Themes/InkwellHaven/email_address_shadow_space_web.png" srcset="./Themes/InkwellHaven/email_address_shadow_space_web.png 1x" />
                
            <img id="IWH_PC_33phone_number_shadow_space_webs" src="./Themes/InkwellHaven/phone_number_shadow_space_webs.png" srcset="./Themes/InkwellHaven/phone_number_shadow_space_webs.png 1x" />
                
            <div id="IWH_PC_33n_Copyright_Operate_Live_OPC_P">
                <span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
            </div>
            <div id="IWH_PC_33Contact_Us">
                <span>Contact Us!</span>
            </div>
            <img id="IWH_PC_33kisspng-paper-area-rectangle-s" src="./Themes/InkwellHaven/kisspng-paper-area-rectangle-s.png" srcset="./Themes/InkwellHaven/kisspng-paper-area-rectangle-s.png 1x" />
                
            <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="IWH_PC_33Rectangle_756">
                <rect id="IWH_PC_33Rectangle_756" rx="0" ry="0" x="0" y="0" width="405" height="266">
                </rect>
            </iframe>
        </div>
        <div id="IWH_PC_33Group_15159">
            <img id="IWH_PC_33deepigoyal--TCygdpnO3g-unsplas" src="./Themes/InkwellHaven/deepigoyal--TCygdpnO3g-unsplas.png" srcset="./Themes/InkwellHaven/deepigoyal--TCygdpnO3g-unsplas.png 1x" />
                
            <div id="IWH_PC_33Essential_That_Inspires_You_to">
                <span>Essential That Inspires<br/>You to Write</span>
            </div>
            <div id="IWH_PC_33Crafting_Words_One_Page_at_a_T">
                <span>"Crafting Words, One Page at a Time: <br/>Unleash Elegance with Our Leather Stationery"</span>
            </div>
            <div id="IWH_PC_33Your_Name">
                <span>{props.data.businessName}</span>
            </div>
            <img id="IWH_PC_33Anime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/InkwellHaven/Anime_Edit.png"} />
                
        </div>
        <div id="IWH_PC_33Group_15157">
            <img id="IWH_PC_33school-supplies-art-classes" src="./Themes/InkwellHaven/school-supplies-art-classes.png" srcset="./Themes/InkwellHaven/school-supplies-art-classes.png 1x" />
                
            <svg class="IWH_PC_33Rectangle_755">
                <rect id="IWH_PC_33Rectangle_755" rx="0" ry="0" x="0" y="0" width="1366" height="395">
                </rect>
            </svg>
            <div id="IWH_PC_33Available_Stationery">
                <span>Available Stationery</span>
            </div>
            <div id="IWH_PC_33Scroll_offering_med_web_4_pc">
	{props.offeringsData.map((off,idx)=>(
                <div id="IWH_PC_33offering_crd_1_grp_med_web_4_p">
                    <svg class="IWH_PC_33title_bdr_med_web_4_pc">
                        <rect id="IWH_PC_33title_bdr_med_web_4_pc" rx="7" ry="7" x="0" y="0" width="172" height="32">
                        </rect>
                    </svg>
                    <div id="IWH_PC_33BiziBees_PVT_LTD_med_web_4_pc">
                        <span>{off.offeringTitle}</span>
                    </div>
                    <img id="IWH_PC_33img_border_med_web_4_pc_of" src="./Themes/InkwellHaven/img_border_med_web_4_pc_of.png" srcset="./Themes/InkwellHaven/img_border_med_web_4_pc_of.png 1x" />
                        
                    <img id="IWH_PC_33chuttersna_med_web_4_pc_img" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/InkwellHaven/chuttersna_med_web_4_pc_img.png"} />
                        
                </div>))}
            </div>
        </div>
    </div>
  )
}

export default InkwellHaven