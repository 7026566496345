import React, { useEffect, useState, useContext} from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';



const CalenderPageMobUI = (props) => {

    const [date, setDate] = useState(new Date().toISOString().substring(0,16));

    useEffect(()=>{
      if(props.slotDate){
        setDate(props.slotDate)
      }
      
    },[props.slotDate])

   const CalenderDate = (e)=>{
    if(new Date(new Date(e).setHours(5, 30, 0, 0)).toISOString().substring(0,10)!==date.substring(0,10)){

  setDate(new Date(new Date(e).setHours(5, 30, 0, 0)).toISOString().substring(0,16))

  var newDate =  new Date(new Date(e).setHours(5, 30, 0, 0)).toISOString().substring(0,10)

  props.date(newDate)
  
    }
   }

  return (
    <div>
       <Calendar value={date}  onClickDay={(e)=>{
        
          CalenderDate(e)
       }}
      />
    </div>
  )
}

export default CalenderPageMobUI
