import React,{useState,useMemo, useContext,useEffect} from 'react'
import { Typography,AppBar,Button } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import {ContextProvider,SocketContext} from '../../SocketContext';
import {useNavigate} from "react-router-dom";
import MeetingRoomMobUI from '../MobUI/MeetingRoomMobUI';
import NotificationsMobUI from '../../Components/MobUI/NotificationsMobUI';

const useStyles = makeStyles((theme) => ({
    appBar: {
      borderRadius: 15,
      margin: '30px 100px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '600px',
      border: '2px solid black',
      "@media (max-width: 1440px)": {
        mobileMenuStyle: {
            width: '90%',
         },
        }
    },
    // image: {
    //   marginLeft: '15px',
    // },
    wrapper: {
       display: 'flex',
      // flexDirection: 'column',
      alignSelf: 'center',
      width: '50%',
      // height:"auto",
      marginTop: "15px"
    },
  }));


const MeetingHomeMobUI = (props) => {
  
  const classes = useStyles();
  var navigate = useNavigate();
//   const [user, setUser] = useState(null);
//   const value = useMemo(
//     () => ({
//       user,
//       setUser,
//     }),
//     [
//       user,
//       setUser,
//     ]
//     );



  return (
    
<>

<ContextProvider>
    <MeetingRoomMobUI />
    <NotificationsMobUI />
</ContextProvider>
    </>
  )
}

export default MeetingHomeMobUI