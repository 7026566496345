import React, { useState, useEffect, useContext } from "react";
import { UserContext } from './UserContext';
import '../styles/TopNavBarMobUI.css';
import {useNavigate} from "react-router-dom";



const TopNavBarMobUI = (props) => {

//Usestate 
// const [sideNavBar,setSideNavBar] = useState(false);
const { user, setUser } = useContext(UserContext);
var navigate = useNavigate();



const handlelogout = () =>{
    localStorage.clear();
    setUser(null);
    window.location.href = "/login";
  }


//UseEffect
// useEffect(()=>{


// },[])


  return (
    <div>
      {/* <svg data-type="Rectangle" data-name="Rectangle 1" class="top_bar_Border">
		<rect id="top_bar_Border" rx="0" ry="0" x="0" y="0" width="67" height="375">
		</rect>
	  </svg>
	<img data-type="Rectangle" data-name="menu" onClick={()=>{props.goto(true);}} id="menu_top_bar" src="menu.png" srcset="menu.png 1x, menu@2x.png 2x" />
		
	<img data-type="Rectangle" data-name="image_cd" onClick={()=>handlelogout()} id="logo_topBorder" src="image_cd.png" srcset="image_cd.png 1x, image_cd@2x.png 2x" />
	 */}


   <nav>
	<svg class="top_bar_Border">
		<rect id="top_bar_Border" rx="0" ry="0" x="0" y="0" width="401" height="70">
		</rect>
	</svg>
</nav>
	<img id="menu_top_bar" src="menu.png" onClick={()=>{props.goto(true)}} srcset="menu.png 1x, menu@2x.png 2x" />
		
	<img id="logo_topBorder" src="image_cd.png" srcset="image_cd.png 1x, image_cd@2x.png 2x" />
  </div>
  )
}

export default TopNavBarMobUI
