import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioEventMobUI.css';
import { UserContext } from '../UserContext';
import MyOffandComOffMob from './MyOffandComOffMob';
import { Switch } from '@mui/material';
import MyEveandComEveMobUI from './MyEveandComEveMobUI';

const MyPortfolioEventMobUI = (props) => {
	const [toggle,setToggle] = useState(false);

  return (
   
	<div id="portfolio_event">
	<div id="heading_grp_offreqevent_event_">
		<div id="req_off_porfolio_reqevent_req_PC" onClick={()=>props.selectedTab("requirements")}>
			<span>Requirements</span>
		</div>
		<div id="selected_grp_offreqevent_req_P">
			<div id="requirements_offreqevent_req_P" onClick={()=>props.selectedTab("offerings")}>
				<span>Offerings</span>
			</div>
		</div>
		<div id="A_off_porfolio_reqevent_req_PC">
			<span>Events</span>
		</div>
		<svg class="underline_off_porfolio_reqeventreq_PC">
			<rect id="underline_off_porfolio_reqeventreq_PC" rx="0" ry="0" x="0" y="0" width="43" height="3">
			</rect>
		</svg>
	</div>
	<img id="settings-sliders_offreqevent_e" src="./MyPortfolio/settings-sliders_offreqevent_o.png" srcset="./MyPortfolio/settings-sliders_offreqevent_o.png 1x" />
		
	<MyEveandComEveMobUI />
</div>

  )
}

export default MyPortfolioEventMobUI
