import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../styles/MyConnectionsUI.css';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MyConnectionsUI = () => {

//UseState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [users,setUsers] = useState("");
const [ allStatus,setAllStatus] = useState("");
const [requests,setRequests]= useState([]);
const [selectedCommunity,setSelectedCommunity] = useState([])
const [communities,setCommunities] = useState([]);


 //UseEffect
 useEffect(()=>{
   
 
   if(user){
   
    getStatus()
    // getRequests()
    // getCommunities()
   }
},[user])
  
const ConnectionReq = (id,status)=>{  

if(selectedCommunity==="undefined"){
  setSelectedCommunity(JSON.parse([]))
}
    let apiURL=process.env.REACT_APP_API_URL + "/connection/updateconnection/"+id;
    let apiParams = {
        method:"POST",
        headers:{
          status:status,
          user2selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        },
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        alert("Action Updated Successfully!!");
         
      //props.showAlert("Job Added Successfully","success")
         setTimeout(() => {
          getStatus()
        //   getRequests()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
  
}


const getStatus = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
           console.log(repos.data)
          setAllStatus(repos.data)
          
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};

//Converting Image
function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  var base64Flag = "data:image/jpeg;base64,";
  return base64Flag + window.btoa(binary);
}




//Get my Communities
const getCommunities = ()=>{

  let apiURL=process.env.REACT_APP_API_URL + "/community/getmycommunity/"+user._id; 

  fetch(apiURL)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
          console.log(repos.data)
        setCommunities(repos.data)
      
      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}




  return (
    <>
   

    {(allStatus||[]).map((us,idx)=>(
      <div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
	<div id="box_cr_"  className='slideInRight'>
		<svg class="border_cr_" onClick={()=>navigate("../"+(us.user1Name===user.name ? us.user2Image.nickName:us.user1Image.nickName))}>
			<rect id="border_cr_" rx="0" ry="0" x="0" y="0" width="359" height="153">
			</rect>
		</svg>
		<img id="profile_img_cr" src={us.user1Name===user.name ?  (us.user2Image.imageUrl && us.user2Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(us.user2Image.imageUrl.split('/')[5]):"default.jpg":(us.user1Image.imageUrl && us.user1Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(us.user1Image.imageUrl.split('/')[5]):"default.jpg"}   />
			
		<svg class="name_lbl_border_cr">
			<rect id="name_lbl_border_cr" rx="0" ry="0" x="0" y="0" width="220" height="30">
			</rect>
		</svg>
		<div id="name_lbl_value_cr">
			<span>{us.user1Name===user.name ? us.user2Name:us.user1Name}</span>
		</div>
		<div id="Profession_lbl_value_cr">
			<span>Profession :</span>
		</div>
		<div id="Connected_On_lbl_value_cr">
			<span>Connected On :</span>
		</div>
		<div id="Software_Developer_lbl_value_cr">
			<span>{us.user1Name===user.name ? us.user2Image.profession:us.user1Image.profession}</span>
		</div>
		<div id="date_new_value_cr">
			<span>{us.updatedAt && (us.updatedAt).substring(0,10)}</span>
		</div>

   
   
    { user.name !==us.name ?  <div>
		<button id="border_box_cr" onClick={(e) =>{ConnectionReq(us._id,"Blocked")}}>
			<svg class="block_border_cr">
				<rect id="block_border_cr" rx="9" ry="9" x="0" y="0" width="70" height="26">
				</rect>
			</svg>
			<div id="Block_value_cr">
				<span>Block</span>
			</div>
		</button>
		<button id="border_ignore_cr" onClick={(e) =>{ConnectionReq(us._id,"Ignored")}}>
			<svg class="block_border_ignore_cr">
				<rect id="block_border_ignore_cr" rx="9" ry="9" x="0" y="0" width="70" height="26">
				</rect>
			</svg>
			<div id="Ignore_value_ig">
				<span>Ignore</span>
			</div>
		</button>
		<button id="border_disconnect_cr" onClick={(e) =>{ConnectionReq(us._id,"Disconnected")}}>
			<svg class="discon_border_cr">
				<rect id="discon_border_cr" rx="8" ry="8" x="0" y="0" width="98" height="26">
				</rect>
			</svg>
			<div id="Disconnected_bd_value_cr">
				<span>Disconnected</span>
			</div>
		</button>
    </div>:""}

	</div>
   </div>
    ))}
  
    </>
  )
}

export default MyConnectionsUI
