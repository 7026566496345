import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/MyConnectionsAndRequestsMobUI.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import MyConnectionsUI from '../MyConnectionsUI';
import Switch from '@mui/material/Switch';
import MyRequestsUI from '../MyRequestsUI';
import MyConnectionsMobUI from './MyConnectionsMobUI';
import MyRequestsMobUI from './MyRequestsMobUI';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MyConnectionsAndRequestsMobUI = () => {

//UseState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [toggle,setToggle] = useState(false);
const [ allStatus,setAllStatus] = useState("");
const [requests,setRequests]= useState([]);
const [selectedCommunity,setSelectedCommunity] = useState([])
const [communities,setCommunities] = useState([]);


 //UseEffect
 useEffect(()=>{
   
 
   if(user){
   
    
   }
},[user])



  return (
    <>
    <div>
   
	<div id="Connection_page">
	<img id="con_bg_img_mob" src="req_bg_img_mob.png" srcset="req_bg_img_mob.png 1x" />
		
	<div id="connect_mob_lbl">
		<span>{toggle=== false ?"My Connections":"My Request"}</span>
	</div>

	<div class="connect_switch">
	<Switch id='connect_switch' onChange={(e)=>setToggle(e.target.checked)} checked={toggle} color="default" />
	</div>
</div>

{toggle===false ? <MyConnectionsMobUI />:<MyRequestsMobUI /> }

    </div>
    </>
  )
}

export default MyConnectionsAndRequestsMobUI
