import React from 'react'
import '../../styles/NotificationProfileUI.css'

const NotificationProfileUI = () => {
  return (
    <div>
      <div id="notification_Overall_pc">
	<div id="notification_grp">
		<svg class="notification_bdr">
			<rect id="notification_bdr" rx="15" ry="15" x="0" y="0" width="326" height="415">
			</rect>
		</svg>
		<div id="Notifications_lbl">
			<span>Notifications</span>
		</div>
		<div id="noti_scroll_">
			<div id="card_1_noti">
				<svg class="down_shadow">
					<rect id="down_shadow" rx="25.5" ry="25.5" x="0" y="0" width="227" height="51">
					</rect>
				</svg>
				<svg class="bg_pfp_shadow">
					<rect id="bg_pfp_shadow" rx="25.5" ry="25.5" x="0" y="0" width="251" height="51">
					</rect>
				</svg>
				{/* <div id="Mark_Damon_lbl">
					<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sodales lectus id metus lacinia, id pharetra eros scelerisque. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Quisque at mauris justo. Maecenas accumsan magna non tortor consequat, eu efficitur nulla efficitur.</span>
				</div>
				<div id="Mark_Damon_lbl_">
					<span>26 Jun 2023 12:12 Am</span>
				</div> */}
			</div>
		</div>
	</div>
</div>

    </div>
  )
}

export default NotificationProfileUI
