import React, { useContext, useState, useEffect } from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import '../../styles/ComReqSlide.css';
import { UserContext } from "../UserContext";
import CreateCommunityPopUpUI from "../CreateCommunityPopUpUI";
import OfferingPopUpPC from "../OfferingPopUpPC";
import RequirementPopUpPC from "../RequirementPopUpPC";

const ComOffSlide = (props) => {
  
    const { user, setUser } = useContext(UserContext);
    const [openCommunityModal,setOpenCommunityModal] = useState(false);
    const [searchParams] = useSearchParams();
    const [communityDescription,setCommunityDescription] = useState("");
    const [requirements,setRequirements]=useState([]);
    const [requirementPopup,setRequirementPopup] = useState(false);
    
    
useEffect(()=>{
    if(user){
        if(props.openReqSlide)
        {
          getRequirements()
        }
    }
    
    },[props.openReqSlide])


   
  
const handleClose = ()=>{
    props.closeReqSlide()
}

  
const openPopUp = ()=>{
  setRequirementPopup(true)
}

   //Converting Image
   function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

// //Get requirement by id
const getRequirements = async()=>{
     
  let apiURL=process.env.REACT_APP_API_URL + "/requirement/getallrequirementsbyuser/"+ user._id ;
  let apiParams = {
      method:"GET",
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        // setRequirementStatus(repos.d)
    
      setRequirements(repos.data)

      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}


const showMonth = (month)=>{
  
  switch (month){
  case 0:
  return "Jan"
    break;
  case 1:
    return "Feb"
    break;
    case 2:
      return "Mar"
      break;
      case 3:
    return "Apr"
    break;
    case 4:
    return "May"
    break;
    case 5:
    return "Jun"
    break;
    case 6:
    return "Jul"
    break;
    case 7:
    return "Aug"
    break;
    case 8:
    return "Sep"
    break;
    case 9:
    return "Oct"
    break;
    case 10:
    return "Nov"
    break;
    case 11:
    return "Dec"
    break;
}
}


//Disable Job
const updateIsActive = (id) =>{
  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
  let apiURL=process.env.REACT_APP_API_URL + "/requirement/updaterequirementsstatus/" +id;
  let apiParams = {
    method:"POST",
    headers:{
      active:false
    },
 };

 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
       alert("Requirement Removed Successfully");
      // props.showAlert("Job Removed Successfully","success")
      setTimeout(() => {
        getRequirements()
      }, 2000);
     }else alert(repos.message || repos);
 }) 
 .catch((err) => alert(err));
 
}

  return (
    <div>
<div id="req_down">
	<svg class="background_green_down_pop_down_y">
		<linearGradient id="background_green_down_pop_down_y" spreadMethod="pad" x1="1" x2="0" y1="1" y2="0.395">
			<stop offset="0" stop-color="#65ff7f" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="background_green_down_pop_down_y" rx="0" ry="0" x="0" y="0" width="100%" height="100%">
		</rect>
	</svg>
	<div id="req_pop_up_bottom_scroll_down_">
  <div style={{height:"100%",display:'block'}}>
  {requirements.map((req)=>(
    <div style={{position:"relative",display:"inline-block",padding:"5px",textAlign:"center"}}>
		<div id="req_card_req">
			<svg class="bdr_com_req__">
				<linearGradient id="bdr_com_req__" spreadMethod="pad" x1="0.13" x2="0.89" y1="0.039" y2="1">
					<stop offset="0" stop-color="#65ff7f" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="bdr_com_req__" rx="24.5" ry="24.5" x="0" y="0" width="180" height="49">
				</rect>
			</svg>
			<img id="SD_pfp_pic_com_req_mob" src="SD_pfp_pic_com_req_mob.png" srcset="SD_pfp_pic_com_req_mob.png 1x" />
				
			<marquee id="SD_lbl_com_req_">
				<span>{req.requirementTitle}</span>
			</marquee>
			<div id="inr_and_com_req">
				<span> {req.currency && JSON.parse(req.currency).symbol} {req.budget}</span><span> | </span><span>{req.deliveryDeadline && new Date(req.deliveryDeadline).getDate() +" "+ showMonth(new Date(req.deliveryDeadline).getMonth())} </span>
			</div>
			<img id="minuclose_com_req_mob" src="minuclose_com_req_mob.png" srcset="minuclose_com_req_mob.png 1x" onClick={()=>updateIsActive(req._id)}/>
				
		</div>
    </div>
    ))}
</div>
		<button title="Add Requirements" id="requirment_com_mob_pop" onClick={()=>openPopUp()}>
			<svg class="req_btncom_req">
				<rect id="req_btncom_req" rx="20" ry="20" x="0" y="0" width="139" height="40">
				</rect>
			</svg>
			<div id="My_Requirs_lbl_btn_req">
				<span>My Requirements</span>
			</div>
			<img id="plus_img_com_req" src="plus_img_com_req.png" srcset="plus_img_com_req.png 1x" />
				
		</button>
	</div>
	<img id="down-arrow_for_top_req" src="down-arrow_for_top_req.png" srcset="down-arrow_for_top_req.png 1x" onClick={()=>handleClose()}/>
		
</div>
<RequirementPopUpPC  openReqPopUp={requirementPopup} closeReqPopUp={()=>{setRequirementPopup(false);getRequirements()}} />
    </div>
  )
}

export default ComOffSlide
