import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import '../../styles/CommunitiesRequirementsPageNewMobUI.css';
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddCommmunitiesReqPopUp from '../AddCommunitiesReqPopUp'
import FooterPcUI from '../PC/FooterPcUI';
import MyCommunitiesNewUI from '../PC/MyCommunitiesNewUI';
import OfferingsSideUI from '../PC/OfferingsSideUI';
import QuotationPopUpPC from '../QuotationPopUpPC';
import RequirementsViewMorePopUp from '../RequirementsViewMorePopUp';
import ImageSet from '../PC/ImageSet';
import OfferingsBottomMobUI from './OfferingsBottomMobUI';
import ComOffSlide from './ComOffSlide';


const CommunitiesRequirementsPageNewUI = (props) => {

//useStates
    
    var navigate = useNavigate();
    const { user, setUser} = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const communityId = props.communityId;
    const communityName = props.communityName;
    const [requirements,setRequirements]=useState([]);
    const [ offerings,setOfferings] = useState([]);
    const [allStatus,setAllStatus] = useState([]);
    const [checked, setChecked] = React.useState([true, false]);
   
    const [openGridOfferingModel,setOpenGridOfferingModel] = useState(false);
    const [openGridConnectionModel,setOpenGridConnectionModel] = useState(false);
    const [oddRequirements,setOddRequirements] = useState([]);
    const [oddOfferings,setOddOfferings] = useState([]);
    const [oddConnections,setOddConnections] = useState([]);
    const [selectedCommunity,setSelectedCommunity] = useState([]);
    const [selectedCommunities,setSelectedCommunities] = useState([]);
    const [selectedQuotation,setSelectedQuotation] = useState({});
    const [viewMorePopup,setViewMorePopup] = useState(false);
    const [viewMoreData,setViewMoreData] = useState("");
    const [quotationOpenModel,setQuotationOpenModel] = useState(false);
    const [openGridModel,setOpenGridModel] = useState(false);

//UseEffects
useEffect(()=>{
    if(user){
      
        if(props.communityId){
        
            getRequirements()
            // getOfferings()
            // getStatus()
        }
        
       
       

    }
    },[props.communityId])

    //Converting Image
function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

     //Get requirement by communityId
  const getRequirements = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getrequirementsbycommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:props.communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        
        setRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


   //Get requirement not in community
   const getRequirementswithoutcommunity = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getrequirementswithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   //Get offering not in community
   const getOfferingsWithoutCommunity = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getofferingwithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddOfferings(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
   //Get connection not in community
   const getConnectionWithoutCommunity = async()=>{
    let apiURL=process.env.REACT_APP_API_URL + "/connection/getconnectionwithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddConnections(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   
// //Get offerings by id
const getOfferings = ()=>{
       
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getofferingsbycommunity/" +user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        }
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
    
        setOfferings(repos.data)
         
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
 

  //Get Connection List
  const getStatus = ()=>{
 
    let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatusbycommunity/" + user._id;
    let apiParams = {
      method: "GET",
      headers:{
        communityid:communityId,
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
            
            setAllStatus(repos.data)
            
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

//
  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };


  const RemoveRequirement = (requirment)=>{
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/removerequirementsbycommunity/"+ requirment._id ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
          getRequirements()

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
  

  const RemoveOffering = (offeringId)=>{
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/removeofferingsbycommunity/"+ offeringId._id ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
          getOfferings()

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
  
  // const RemoveConnection = (connectionId)=>{
  //   let apiURL=process.env.REACT_APP_API_URL + "/connection/removeConnectionsbycommunity/"+ connectionId._id ;
  //   let apiParams = {
  //       method:"POST",
  //       headers:{
  //           communityid:communityId,
  //           userid:user._id,
  //       },
  //   };
  //   fetch(apiURL, apiParams)
  //   .then((res)=> (res.status===200 ? res.json() : res.text()))
  //   .then((repos)=> {
  //       if (repos.data) {
  //         // setRequirementStatus(repos.d)
  //         getStatus()

  //       }else alert(repos.message || repos);  
  //   }) 
  //   .catch((err) => alert(err));
  // }

  const closeGridModel = () => setOpenGridModel(false);
  const closeGridOfferingModel = () => setOpenGridOfferingModel(false);
  const closeGridConnectionModel = () => setOpenGridConnectionModel(false);
  
//adding community to requirements 
  // const updateStatus = (requirementId)=>{
   
  //   let apiURL=process.env.REACT_APP_API_URL + "/requirement/addcommunitytorequirement/"+ requirementId ;
  //   let apiParams = {
  //       method:"POST",
  //       headers:{
  //           communityid:communityId,
  //           communityname:communityName,
  //       },
  //   };
  //   fetch(apiURL, apiParams)
  //   .then((res)=> (res.status===200 ? res.json() : res.text()))
  //   .then((repos)=> {
  //       if (repos.data) {
  //       console.log(repos.data)
  //         getRequirementswithoutcommunity()
  //         getRequirements()
  //       }else alert(repos.message || repos);  
  //   }) 
  //   .catch((err) => alert(err));
  // }

// //adding Community to Offerings
//   const updateOfferingStatus = (offeringId)=>{
   
//     let apiURL=process.env.REACT_APP_API_URL + "/offerings/addcommunitytooffering/"+ offeringId ;
//     let apiParams = {
//         method:"POST",
//         headers:{
//             communityid:communityId,
//             communityname:communityName,
//         },
//     };
//     fetch(apiURL, apiParams)
//     .then((res)=> (res.status===200 ? res.json() : res.text()))
//     .then((repos)=> {
//         if (repos.data) {
//         console.log(repos.data)
//           getOfferingsWithoutCommunity()
//           getOfferings()
//         }else alert(repos.message || repos);  
//     }) 
//     .catch((err) => alert(err));
//   }


//adding Community to Offerings
  // const updateConnectionStatus = (connectionId)=>{
   
  //   let apiURL=process.env.REACT_APP_API_URL + "/connection/addcommunitytoconnection/"+ connectionId ;
  //   let apiParams = {
  //       method:"POST",
  //       headers:{
  //           communityid:communityId,
  //           communityname:communityName,
  //           userid:user._id,
  //       },
  //   };
  //   fetch(apiURL, apiParams)
  //   .then((res)=> (res.status===200 ? res.json() : res.text()))
  //   .then((repos)=> {
  //       if (repos.data) {
  //       console.log(repos.data)
  //         getConnectionWithoutCommunity()
  //         getStatus()
  //       }else alert(repos.message || repos);  
  //   }) 
  //   .catch((err) => alert(err));
  // }

  // var cols = [

  //   {field:'id', headerName: 'id', hide: true },
  //   {field:'requirementId', headerName: 'reqId',hide: true  },
  //   {field:'requirementTitle', headerName: 'Title',width: 260,  },
  //   {field:'add', headerName: 'Add',width: 60, renderCell: (params) =>{
  //     return[
  //      <div>
  //     <AddIcon style={{color:"green"}} onClick={()=>{updateStatus(params.row.id);}}/>
  //     </div>
  //     ];},},  

  // ];

  // var colsOffering = [

  //   {field:'id', headerName: 'id', hide: true },
  //   {field:'offeringId', headerName: 'offId',hide: true  },
  //   {field:'offeringTitle', headerName: 'Title',width: 260,  },
  //   {field:'add', headerName: 'Add',width: 60, renderCell: (params) =>{
  //     return[
  //      <div>
  //     <AddIcon style={{color:"green"}} onClick={()=>{updateOfferingStatus(params.row.id);}}/>
  //     </div>
  //     ];},},  

  // ];

  // var colsConnection = [

  //   {field:'id', headerName: 'id', hide: true },
  //   {field:'connectionId', headerName: 'conId',hide: true  },
  //   {field:'name', headerName: 'Name',width: 260,  },
  //   {field:'add', headerName: 'Add',width: 60, renderCell: (params) =>{
  //     return[
  //      <div>
  //     <AddIcon style={{color:"green"}} onClick={()=>{updateConnectionStatus(params.row.id);}}/>
  //     </div>
  //     ];},},  

  // ];

//   const rowData = oddRequirements.filter(req=>req.customerId === user._id).map((re) => {
       
//     return{
   
//     id: re._id,
//     requirementId:re._id,
//     requirementTitle:re.requirementTitle,
  
//     };
//  });

 
//  const rowDataOffering = oddOfferings.filter(req=>req.vendorId === user._id).map((off) => {
       
//   return{
 
//   id: off._id,
//   offeringId:off._id,
//   offeringTitle:off.offeringTitle,

//   };
// });

//  const rowDataConnection = oddConnections.map((con) => {
       
//   return{
 
//   id:con._id,
//   connectionId:con._id,
//   name:con.user1Id===user._id?con.user2Name:con.user1Name,

//   };
// });



  const openGridModal = ()=>{

    setOpenGridModel(true)
    // getRequirementswithoutcommunity()
  }

  const openGridOfferingModal = ()=>{
    setOpenGridOfferingModel(true)
    getOfferingsWithoutCommunity()

  }

  const openGridConnectionModal = ()=>{
    setOpenGridConnectionModel(true)
    getConnectionWithoutCommunity()

  }

  const addBid=(id,title,createdById,createdByName)=>{
    setSelectedQuotation({id,title,createdById,createdByName});
    // getBids(id)
    setQuotationOpenModel(true);

    }

    const openViewMorePopUp = (rec)=>{
      setViewMoreData(rec)
      setViewMorePopup(true)
    }

  const showMonth = (month)=>{
  
    switch (month){
    case 0:
    return "Jan"
      break;
    case 1:
      return "Feb"
      break;
      case 2:
        return "Mar"
        break;
      case 3:
      return "Apr"
      break;
      case 4:
      return "May"
      break;
      case 5:
      return "Jun"
      break;
      case 6:
      return "Jul"
      break;
      case 7:
      return "Aug"
      break;
      case 8:
      return "Sep"
      break;
      case 9:
      return "Oct"
      break;
      case 10:
      return "Nov"
      break;
      case 11:
      return "Dec"
      break;
  }
  }
  

  return (


<div id="community_req_Mob1">
<div id="community_req_side_menu">
<img id="com_req_bg_img_com_req_mob" src="com_req_bg_img_com_req_mob.png" srcset="com_req_bg_img_com_req_mob.png 1x" />
	<div id="req_overall_com_req_mob">
		<img id="com_req_mob_side_bar" src="com_req_mob_side_bar.png" srcset="com_req_mob_side_bar.png 1x" />
   
		<div id="req_off_mem_grp_1">
			<div id="com_off_req_txt_com_req_mob">
				<span>Requirements</span>
			</div>
			<div id="com_off_off_txt_com_req_mob" onClick={()=>props.selectedTab('offerings')}>
				<span>Offerings</span>
			</div>
			<div id="com_off_mem_txt_com_req_mob" onClick={()=>props.selectedTab('members')}>
				<span>Members</span>
			</div>
		</div>
	</div>
</div>
	<div id="mid_scroll_card">
  {requirements.map((req)=>(
  <div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
		<div id="card_grp_over_all_mob">
			<svg class="com_req_mob_card_1">
				<rect id="com_req_mob_card_1" rx="13" ry="13" x="0" y="0" width="90%" height="89">
				</rect>
			</svg>
			<img id="com_req_mob_mob_pfp" src={req.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(req.defaultRequirementsImageUrl.split('/')[5]):"profilebackground.png"} />
				
			<div id="com_req_mob_md_zaid">
				<span>{req.requirementTitle}</span>
			</div>
			<div id="com_req_mob_design_grp">
				<svg class="com_req_mob_page_card_design" viewBox="17.761 54.55 117.404 28.566">
					<path id="com_req_mob_page_card_design" d="M 17.93937301635742 72.22697448730469 L 54.18844604492188 83.11607360839844 C 54.18844604492188 83.11607360839844 55.80867004394531 78.82169342041016 59.8450927734375 74.31443023681641 C 62.31302261352539 71.55460357666016 65.82218933105469 67.43408966064453 74.36148071289062 66.66352081298828 C 77.51936340332031 66.37856292724609 81.06272125244141 66.79751586914062 83.42662048339844 67.50247192382812 C 84.86332702636719 67.92860412597656 87.85828399658203 69.11642456054688 89.34918975830078 69.92697143554688 C 98.34956359863281 74.81540679931641 100.6986465454102 83.11607360839844 100.6986465454102 83.11607360839844 L 111.5691680908203 79.64503479003906 L 134.8008728027344 72.22697448730469 C 134.8008728027344 72.22697448730469 138.4102020263672 53.33117294311523 122.8901214599609 54.61224365234375 C 107.3700561523438 55.89331817626953 27.96507453918457 54.61224365234375 27.96507453918457 54.61224365234375 C 27.96507453918457 54.61224365234375 21.55619812011719 54.73288345336914 18.70760536193848 60.51025772094727 C 17.75597381591797 62.43505477905273 17.56718254089355 65.52426147460938 17.93937301635742 69.37132263183594 C 18.33065032958984 73.41566467285156 17.89220428466797 71.28224182128906 17.93937301635742 72.22697448730469 C 18.02732086181641 73.85850524902344 17.93937301635742 72.22697448730469 17.93937301635742 72.22697448730469 Z">
					</path>
				</svg>
			</div>

			{/* <img id="com_req_mob_minus" src="com_req_mob_minus.png" srcset="com_req_mob_minus.png 1x" /> */}
				
			<img title="Profession" id="com_req_mob_budget_png" src="com_req_mob_budget_png.png" srcset="com_req_mob_budget_png.png 1x" />
				
			<img title="Joined" id="com_req_mob_delivery_png" src="com_req_mob_delivery_png.png" srcset="com_req_mob_delivery_png.png 1x" />
				
			<div id="com_req_mob_budget_txt">
				<span>{req.budget}</span>
			</div>
			<div id="com_req_mob_dep_txt">
				<span>{req.deliveryLocation}</span>
			</div>
			<img title="Joined" id="com_req_mob_location_png" src="com_req_mob_location_png.png" srcset="com_req_mob_location_png.png 1x" />
				
			<div id="com_req_mob__timg_txt">
				<span>{req.deliveryDeadline && new Date(req.deliveryDeadline).getDate() +" "+ showMonth(new Date(req.deliveryDeadline).getMonth())}</span>
			</div>
		</div>
    </div>
  ))}
	</div>

<OfferingsBottomMobUI />





<QuotationPopUpPC  openQuoPopUp={quotationOpenModel}  selectedQuotation={selectedQuotation} closeQuoPopUp={()=>{setQuotationOpenModel(false);getRequirements()}}/>


{/* View More PopUp */}

<RequirementsViewMorePopUp type="CR" openViewMore={viewMorePopup} viewMoreData={viewMoreData} closeViewMore={()=>setViewMorePopup(false)}/> 

{/* Add Communities Pop up */}
 <AddCommmunitiesReqPopUp communityId={props.communityId} communityName={props.communityName} openComReq={openGridModel} closeComReq={()=>{setOpenGridModel(false);getRequirements()}}/>

  </div>
  )
}

export default CommunitiesRequirementsPageNewUI
