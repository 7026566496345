import React, { useState, useEffect, useContext } from "react";
import { UserContext } from './UserContext';
import '../styles/SideNavBarMobUI.css'
import {useNavigate} from "react-router-dom";

const SideNavBarMobUI = (props) => {

//useState
var navigate = useNavigate();
const { user, setUser } = useContext(UserContext);

const meetingRoom = ()=>{
    props.goto(false);
    navigate('/meeting')
}

const dashboardRoom = ()=>{
    props.goto(false)
    navigate('/home')
}

const myPortfolio = ()=>{
    props.goto(false)
    navigate('/portfolio')
}

const myPeoplesRoom = ()=>{
    props.goto(false)
    navigate('/mypeople')
}

const communities = ()=>{
    props.goto(false)
    navigate('/communities')
}

const marketRoom = ()=>{
    props.goto(false)
    navigate('/market')
}
const eventsRoom = ()=>{
    props.goto(false)
    navigate('/market')
}

const handlelogout = () =>{
    localStorage.clear();
    setUser(null);
    window.location.href = "/login";
  }

  return (
	<div id="side_nav_bar_mob" className="fadeInRight">
	<svg class="side_nav_bg__be" onClick={()=>props.goto(false)}>
		<linearGradient id="side_nav_bg__be" spreadMethod="pad" x1="0.072" x2="1.658" y1="0.963" y2="-0.591">
			<stop offset="0" stop-color="#dbf2e7" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="side_nav_bg__be" rx="0" ry="0" x="0" y="0" width="100%" height="100%">
		</rect>
	</svg>
	<div id="left_comp_mob_menu" onClick={()=>props.goto(false)}>
		<svg class="side_nav_border_3">
			<rect id="side_nav_border_3" rx="20" ry="20" x="0" y="0" width="81" height="565">
			</rect>
		</svg>
		<svg class="side_nav_border_2">
			<rect id="side_nav_border_2" rx="20" ry="20" x="0" y="0" width="81" height="674">
			</rect>
		</svg>
		<svg class="side_nav_border_1" viewBox="-0.056 0 67.056 376.655">
			<path id="side_nav_border_1" d="M 21 0 L 46 0 C 57.59798049926758 0 67 9.402019500732422 67 21 L 67 354 C 67 365.5979919433594 57.59798049926758 375 46 375 L 21 375 C 15.44456672668457 375 7.102727890014648 378.7247009277344 3.13427734375 375 C -0.7381570339202881 371.3709106445312 0 360.0425415039062 0 354 L 0 21 C 0 9.402019500732422 9.402019500732422 0 21 0 Z">
			</path>
		</svg>
	</div>

	<img id="logged_user_mob_pic" src={user.imageId && user.imageId.length > 10?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+user.imageId:"./SideNav/logged_user_mob_pic.png"} />
		
	<div title="Profile" id="dash_bdr_grp_mob" onClick={()=>dashboardRoom()}>
		<svg class="dash_mob">
			<rect id="dash_mob" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Dashboard_side_lbl_mob">
			<span>Profile</span>
		</div>
		<img id="dashboard_Icon_mob" src="./SideNav/dashboard_Icon_mob.png" srcset="./SideNav/dashboard_Icon_mob.png 1x" />
	</div>
	<div title="Meeting Room" id="metting_room_grp_mob" onClick={()=>meetingRoom()}>
		<svg class="meet_mob">
			<rect id="meet_mob" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Meeting_Room_side_lbl_mob">
			<span>Meeting Room</span>
		</div>
		<img id="meeting_room_icon_mob" src="./SideNav/meeting_room_icon_mob.png" srcset="./SideNav/meeting_room_icon_mob.png 1x" />
			
	</div>
	<div title="Portfolio" id="portfolio_grp_mob" onClick={()=>myPortfolio()}>
		<svg class="portfolio_mob">
			<rect id="portfolio_mob" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="portfolio_side_lbl_mob">
			<span>Portfolio</span>
		</div>
		<img id="portfolio_icon_mob" src="./SideNav/portfolio_icon_mob.png" srcset="./SideNav/portfolio_icon_mob.png 1x" />
			
	</div>
	<div title="People" id="connection_grp_mob" onClick={()=>myPeoplesRoom()}>
		<svg class="con_mob">
			<rect id="con_mob" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="My_Connection_side_lbl_mob">
			<span>People</span>
		</div>
		<img id="my_connections_icon_mob" src="./SideNav/my_connections_icon_mob.png" srcset="./SideNav/my_connections_icon_mob.png 1x" />
			
	</div>
	<div title="Market" id="market_grp_mob" onClick={()=>marketRoom()}>
		<svg class="market_mob">
			<rect id="market_mob" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Market_side_lbl_mob">
			<span>Market</span>
		</div>
		<img id="market_icon_mob" src="./SideNav/market_icon_mob.png" srcset="./SideNav/market_icon_mob.png 1x" />
			
	</div>
	<div title="Events" id="event_grp_mob" onClick={()=>eventsRoom()}>
		<svg class="event_mob">
			<rect id="event_mob" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Events_side_lbl_mob">
			<span>Broadcast</span>
		</div>
		<img id="events_icon_" src="./SideNav/events_icon_.png" srcset="./SideNav/events_icon_.png 1x" />
			
	</div>
	<div title="LogOut" id="logout_grp_mob" onClick={()=>{handlelogout()}}>
		<svg class="log_mob">
			<rect id="log_mob" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Logout_side_lbl_mob">
			<span>Logout</span>
		</div>
		<img id="logout_icon_I_mob" src="./SideNav/logout_icon_I_mob.png" srcset="./SideNav/logout_icon_I_mob.png 1x" />
			
	</div>
	<div title="Communities" id="com_grp_mob" onClick={()=>{communities()}}>
		<svg class="com_mob">
			<rect id="com_mob" rx="5" ry="5" x="0" y="0" width="195" height="43">
			</rect>
		</svg>
		<div id="Communities_side_lbl_mob">
			<span>Communities</span>
		</div>
		<img id="community_icon_I_1_mob" src="./SideNav/community_icon_I_1_mob.png" srcset="./SideNav/community_icon_I_1_mob.png 1x" />
			
	</div>
	<div id="Dashboard_side_lbl_mob_cg">
		<span>{user.name}</span>
	</div>
</div>
  )
}

export default SideNavBarMobUI
