import React from 'react';
import "../../../styles/MobileMarvels.css";

function MobileMarvels(props) {
  return (
    <div id="MobileStock">
	<div id="theme11_stock_pcstyle">
		<svg class="theme11_stock_pcPath_28" viewBox="0 -72.597 504.926 508.613">
			<path id="theme11_stock_pcPath_28" d="M 0 -72.5966796875 L 504.9259948730469 68.34429168701172 L 504.9259948730469 342.0555725097656 L 0 436.01611328125 L 0 -72.5966796875 Z">
			</path>
		</svg>
		<svg class="theme11_stock_pcEllipse_6">
			<ellipse id="theme11_stock_pcEllipse_6" rx="211.58026123046875" ry="211.58026123046875" cx="211.58026123046875" cy="211.58026123046875">
			</ellipse>
		</svg>
		<svg class="theme11_stock_pcEllipse_5">
			<ellipse id="theme11_stock_pcEllipse_5" rx="120.4931640625" ry="120.4931640625" cx="120.4931640625" cy="120.4931640625">
			</ellipse>
		</svg>
	</div>
	<div id="theme11_stock_pcGroup_14880">
		<div id="theme11_stock_pcstyle_ci">
			<svg class="theme11_stock_pcPath_28_cj" viewBox="0 -72.597 504.926 508.613">
				<path id="theme11_stock_pcPath_28_cj" d="M 0 -72.5966796875 L 504.9259948730469 68.34429168701172 L 504.9259948730469 342.0555725097656 L 0 436.01611328125 L 0 -72.5966796875 Z">
				</path>
			</svg>
			<svg class="theme11_stock_pcEllipse_6_ck">
				<ellipse id="theme11_stock_pcEllipse_6_ck" rx="211.58026123046875" ry="211.58026123046875" cx="211.58026123046875" cy="211.58026123046875">
				</ellipse>
			</svg>
			<svg class="theme11_stock_pcEllipse_5_cl">
				<ellipse id="theme11_stock_pcEllipse_5_cl" rx="120.4931640625" ry="120.4931640625" cx="120.4931640625" cy="120.4931640625">
				</ellipse>
			</svg>
		</div>
		<img id="theme11_stock_pcjoel-rohland-MCL2xxjZJvs-unspl" src="./Themes/MobileMarvels/joel-rohland-MCL2xxjZJvs-unspl.png" srcset="./Themes/MobileMarvels/joel-rohland-MCL2xxjZJvs-unspl.png 1x" />
			
		<div id="theme11_stock_pcScroll_Group_26">
			<div id="theme11_stock_pcIt_is_a_long_established_fact_">
				<span>{props.data.principles}</span>
			</div>
		</div>
		<div id="theme11_stock_pcPRINCIPLES">
			<span>PRINCIPLES</span>
		</div>
	</div>
	<div id="theme11_stock_pcButtom">
		<svg class="theme11_stock_pcRectangle_53">
			<rect id="theme11_stock_pcRectangle_53" rx="0" ry="0" x="0" y="0" width="1377" height="400">
			</rect>
		</svg>
	</div>
	<div id="theme11_stock_pcour_Services">
		<span>our Services</span>
	</div>
	<div id="theme11_stock_pcBanner_Slider">
		<svg class="theme11_stock_pcPath_2" viewBox="0 0 1377.065 718.641">
			<path id="theme11_stock_pcPath_2" d="M 0 0 L 1377.064697265625 0 L 1377.064697265625 436.6969299316406 C 1377.064697265625 436.6969299316406 1310.87158203125 718.6405639648438 966.6053466796875 718.6405639648438 C 771.6206665039062 718.6405639648438 388.6665649414062 606.0856323242188 208.9726257324219 525.4080810546875 C 152.2424926757812 499.9378967285156 108.4569396972656 483.2593078613281 108.4569396972656 483.2593078613281 C 108.4569396972656 483.2593078613281 79.6505126953125 468.752685546875 67.32158660888672 462.4668884277344 C 26.39108276367188 441.5990600585938 0 432.7062683105469 0 432.7062683105469 L 0 0 Z">
			</path>
		</svg>
		<img id="theme11_stock_pcdugba-cauley-hushie-6MNmDi1hc_" src="./Themes/MobileMarvels/dugba-cauley-hushie-6MNmDi1hc_.png" srcset="./Themes/MobileMarvels/dugba-cauley-hushie-6MNmDi1hc_.png 1x" />
			
		<svg class="theme11_stock_pcPath_1527" viewBox="0 0 1377.065 672.276">
			<path id="theme11_stock_pcPath_1527" d="M 0 0 L 1377.064697265625 0 L 1377.064697265625 467.7721862792969 C 1377.064697265625 467.7721862792969 1315.077270507812 672.275634765625 970.8110961914062 672.275634765625 C 626.544921875 672.275634765625 0 467.7721862792969 0 467.7721862792969 L 0 0 Z">
			</path>
		</svg>
		<div id="theme11_stock_pcYour_Mobile_Paradise__Where_Dr">
			<span>"Your Mobile Paradise: <br/>Where Dreams Take Shape!"</span>
		</div>
		<div id="theme11_stock_pcBtg">
			<svg class="theme11_stock_pcRectangle_77">
				<rect id="theme11_stock_pcRectangle_77" rx="16" ry="16" x="0" y="0" width="179" height="63">
				</rect>
			</svg>
			<div id="theme11_stock_pcExplore_More">
				<span>Explore More</span>
			</div>
		</div>
	</div>
	<div id="theme11_stock_pcGroup_14881">
		<div id="theme11_stock_pcstyle_c">
			<svg class="theme11_stock_pcPath_28_c" viewBox="0 -72.597 504.926 508.613">
				<path id="theme11_stock_pcPath_28_c" d="M 504.9259948730469 -72.5966796875 L 0 68.34429168701172 L 0 342.0555725097656 L 504.9259948730469 436.01611328125 L 504.9259948730469 -72.5966796875 Z">
				</path>
			</svg>
			<svg class="theme11_stock_pcEllipse_6_c">
				<ellipse id="theme11_stock_pcEllipse_6_c" rx="211.58026123046875" ry="211.58026123046875" cx="211.58026123046875" cy="211.58026123046875">
				</ellipse>
			</svg>
			<svg class="theme11_stock_pcEllipse_5_c">
				<ellipse id="theme11_stock_pcEllipse_5_c" rx="120.4931640625" ry="120.4931640625" cx="120.4931640625" cy="120.4931640625">
				</ellipse>
			</svg>
		</div>
		<img id="theme11_stock_pcneil-soni-6wdRuK7bVTE-unsplash" src="./Themes/MobileMarvels/neil-soni-6wdRuK7bVTE-unsplash.png" srcset="./Themes/MobileMarvels/neil-soni-6wdRuK7bVTE-unsplash.png 1x" />
			
		<div id="theme11_stock_pcScroll_Group_27">
			<div id="theme11_stock_pcIt_is_a_long_established_fact__c">
				<span>{props.data.mission}</span>
			</div>
		</div>
		<div id="theme11_stock_pcMISSION">
			<span>MISSION</span>
		</div>
	</div>
	<div id="theme11_stock_pcGroup_14882">
		<div id="theme11_stock_pcstyle_db">
			<svg class="theme11_stock_pcPath_28_dc" viewBox="0 -72.597 504.926 508.613">
				<path id="theme11_stock_pcPath_28_dc" d="M 0 -72.5966796875 L 504.9259948730469 68.34429168701172 L 504.9259948730469 342.0555725097656 L 0 436.01611328125 L 0 -72.5966796875 Z">
				</path>
			</svg>
			<svg class="theme11_stock_pcEllipse_6_dd">
				<ellipse id="theme11_stock_pcEllipse_6_dd" rx="211.58026123046875" ry="211.58026123046875" cx="211.58026123046875" cy="211.58026123046875">
				</ellipse>
			</svg>
			<svg class="theme11_stock_pcEllipse_5_de">
				<ellipse id="theme11_stock_pcEllipse_5_de" rx="120.4931640625" ry="120.4931640625" cx="120.4931640625" cy="120.4931640625">
				</ellipse>
			</svg>
		</div>
		<img id="theme11_stock_pcdaniel-korpai-H0vKZjt2k1E-unsp" src="./Themes/MobileMarvels/daniel-korpai-H0vKZjt2k1E-unsp.png" srcset="./Themes/MobileMarvels/daniel-korpai-H0vKZjt2k1E-unsp.png 1x" />
			
		<div id="theme11_stock_pcScroll_Group_28">
			<div id="theme11_stock_pcIt_is_a_long_established_fact__dh">
				<span>{props.data.vision}</span>
			</div>
		</div>
		<div id="theme11_stock_pcVISION">
			<span>VISION</span>
		</div>
	</div>
	<div id="theme11_stock_pcsocials_grp_shadow_space_websi">
    {props.data.linkedInUrl?	
        <div title="LinkedIn" id="theme11_stock_pclinked_in_grp_shadow_space_web">
			<svg class="theme11_stock_pcmed_shadow_space_website_7">
				<ellipse id="theme11_stock_pcmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
				</ellipse>
			</svg>
			<div id="theme11_stock_pclinked_in_shadow_space_website">
				<a href={props.data.linkedInUrl} target="_blank">
				<svg class="theme11_stock_pcn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
					<path id="theme11_stock_pcn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
					</path>
				</svg>
			</a>
				<svg class="theme11_stock_pclinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
					<path id="theme11_stock_pclinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
					</path>
				</svg>
				<svg class="theme11_stock_pctop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
					<path id="theme11_stock_pctop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
					</path>
				</svg>
			</div>
		</div>:""}
        {props.data.instagramUrl?	
		<div title="Instagram" id="theme11_stock_pcInsta_grp_shadow_space_website">
			<svg class="theme11_stock_pcshadow_space_website_7">
				<ellipse id="theme11_stock_pcshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
				</ellipse>
			</svg>
			<a href={props.data.instagramUrl} target="_blank">
			<img id="theme11_stock_pcinstagramshadow_space_website_" src="./Themes/MobileMarvels/instagramshadow_space_website_.png" srcset="./Themes/MobileMarvels/instagramshadow_space_website_.png 1x" />
				
			</a>
		</div>:""}
		{props.data.whatsAppNumber?<div title="WhatsApp" id="theme11_stock_pcwhatsapp_grp_shadow_space_webs">
			<svg class="theme11_stock_pcmed_shadow_space_website_7_dw">
				<ellipse id="theme11_stock_pcmed_shadow_space_website_7_dw" rx="24" ry="24" cx="24" cy="24">
				</ellipse>
			</svg>
            <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
			<img id="theme11_stock_pcwhatsapp_lbl_shadow_space_webs" src="./Themes/MobileMarvels/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/MobileMarvels/whatsapp_lbl_shadow_space_webs.png 1x" />
				</a>
		</div>:""}
	</div>
	<div id="theme11_stock_pcn_234567890_mobile_number_shad">
		<span>{props.data.businessPhone}</span>
	</div>
	<div id="theme11_stock_pcaffanghanioperatelive_email_sh">
		<span>{props.data.businessEmail}</span>
	</div>
	<div id="theme11_stock_pcaddress_med_shadow_space_websi">
		<span>{props.data.businessAddress}</span>
	</div>
	<img id="theme11_stock_pclocation_img_shadow_space_webs" src="./Themes/MobileMarvels/location_img_shadow_space_webs.png" srcset="./Themes/MobileMarvels/location_img_shadow_space_webs.png 1x" />
		
	<img id="theme11_stock_pcemail_address_shadow_space_web" src="./Themes/MobileMarvels/email_address_shadow_space_web.png" srcset="./Themes/MobileMarvels/email_address_shadow_space_web.png 1x" />
		
	<img id="theme11_stock_pcphone_number_shadow_space_webs" src="./Themes/MobileMarvels/phone_number_shadow_space_webs.png" srcset="./Themes/MobileMarvels/phone_number_shadow_space_webs.png 1x" />
		
	<div id="theme11_stock_pcn_Copyright_Operate_Live_OPC_P">
		<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
	</div>
	<div id="theme11_stock_pcTitle">
		<span>Title</span>
	</div>
	<div id="theme11_stock_pcScroll_Group_29">
		<div id="theme11_stock_pcDescription">
			<span>Description</span>
		</div>
	</div>
	<img id="theme11_stock_pckisspng-laptop-macbook-pro-mac" src="./Themes/MobileMarvels/kisspng-laptop-macbook-pro-mac.png" srcset="./Themes/MobileMarvels/kisspng-laptop-macbook-pro-mac.png 1x" />
		
	<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="theme11_stock_pcRectangle_690">
		<rect id="theme11_stock_pcRectangle_690" rx="7" ry="7" x="0" y="0" width="349" height="225">
		</rect>
	</iframe>
	<svg class="theme11_stock_pcEllipse_62">
		<ellipse id="theme11_stock_pcEllipse_62" rx="17.5" ry="39" cx="17.5" cy="39">
		</ellipse>
	</svg>
	<img id="theme11_stock_pcjakob-rosen-FsBbavP9YA4-unspla" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/MobileMarvels/jakob-rosen-FsBbavP9YA4-unspla.png"} />
		
	<div id="theme11_stock_pcGroup_14894">
		<div id="theme11_stock_pcGroup_14893">
        {props.offeringsData.map((rec,idx)=>(<>
			<div id="theme11_stock_pcGroup_14885">
				<img id="theme11_stock_pciPhone_X_-_Light2x" src="./Themes/MobileMarvels/iPhone_X_-_Light2x.png" srcset="./Themes/MobileMarvels/iPhone_X_-_Light2x.png 1x" />
					
				<img id="theme11_stock_pcdariusz-sankowski-3OiYMgDKJ6k-" src="./Themes/MobileMarvels/dariusz-sankowski-3OiYMgDKJ6k-.png" srcset="./Themes/MobileMarvels/dariusz-sankowski-3OiYMgDKJ6k-.png 1x" />
					
			</div>
			<div id="theme11_stock_pcGroup_14884">
				<img id="theme11_stock_pciPhone_X_-_Light2x_ej" src="./Themes/MobileMarvels/iPhone_X_-_Light2x_ej.png" srcset="./Themes/MobileMarvels/iPhone_X_-_Light2x_ej.png 1x" />
					
				<img id="theme11_stock_pcdariusz-sankowski-3OiYMgDKJ6k-_ek" src="./Themes/MobileMarvels/dariusz-sankowski-3OiYMgDKJ6k-.png" srcset="./Themes/MobileMarvels/dariusz-sankowski-3OiYMgDKJ6k-.png 1x" />
					
			</div>
			<div id="theme11_stock_pcGroup_14883">
				<img id="theme11_stock_pciPhone_X_-_Light2x_em" src="./Themes/MobileMarvels/iPhone_X_-_Light2x_em.png" srcset="./Themes/MobileMarvels/iPhone_X_-_Light2x_em.png 1x" />
					
				<img id="theme11_stock_pcdariusz-sankowski-3OiYMgDKJ6k-_en" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"./Themes/MobileMarvels/dariusz-sankowski-3OiYMgDKJ6k-.png"} />
					
			</div></>   ))}
		</div>
		<svg class="theme11_stock_pcPolygon_1" viewBox="0 0 40 37.6">
			<path id="theme11_stock_pcPolygon_1" d="M 19.99999809265137 0 L 40 37.59999847412109 L 0 37.59999847412109 Z">
			</path>
		</svg>
		<svg class="theme11_stock_pcPolygon_2" viewBox="0 0 40 37.6">
			<path id="theme11_stock_pcPolygon_2" d="M 19.99999809265137 0 L 40 37.59999847412109 L 0 37.59999847412109 Z">
			</path>
		</svg>
	</div>
</div>
  )
}

export default MobileMarvels
