import React from 'react';
import "../../../styles/ShoeStride.css";

function ShoeStride(props) {
  return (
    <div id="ShoeStride">
        <div id="SS_31_PCGroup_15101">
            <div id="SS_31_PCGroup_15041">
                <div id="SS_31_PCGroup_15040">
                    <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="SS_31_PCRectangle_737">
                        <rect id="SS_31_PCRectangle_737" rx="0" ry="0" x="0" y="0" width="538" height="302">
                        </rect>
                    </iframe>
                </div>
                <div id="SS_31_PCsocials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
                    <div title="LinkedIn" id="SS_31_PClinked_in_grp_shadow_space_web">
                        <svg class="SS_31_PCmed_shadow_space_website_7">
                            <ellipse id="SS_31_PCmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
                        <div id="SS_31_PClinked_in_shadow_space_website">
					<a href={props.data.linkedInUrl} target="_blank">
                            <svg class="SS_31_PCn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
                                <path id="SS_31_PCn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
                                </path>
                            </svg>
                        </a>
                            <svg class="SS_31_PClinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
                                <path id="SS_31_PClinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
                                </path>
                            </svg>
                            <svg class="SS_31_PCtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
                                <path id="SS_31_PCtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
                                </path>
                            </svg>
                        </div>
                    </div>:""}
			{props.data.instagramUrl?	
                    <div title="Instagram" id="SS_31_PCInsta_grp_shadow_space_website">
                        <svg class="SS_31_PCshadow_space_website_7">
                            <ellipse id="SS_31_PCshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
				<a href={props.data.instagramUrl} target="_blank">
                        <img id="SS_31_PCinstagramshadow_space_website_" src="./Themes/ShoeStride/instagramshadow_space_website_.png" srcset="./Themes/ShoeStride/instagramshadow_space_website_.png 1x" />
                            
                        </a>
                    </div>:""}
			{props.data.whatsAppNumber?
                    <div title="WhatsApp" id="SS_31_PCwhatsapp_grp_shadow_space_webs">
                        <svg class="SS_31_PCmed_shadow_space_website_7_bf">
                            <ellipse id="SS_31_PCmed_shadow_space_website_7_bf" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
                        <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				 <img id="SS_31_PCwhatsapp_lbl_shadow_space_webs" src="./Themes/ShoeStride/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/ShoeStride/whatsapp_lbl_shadow_space_webs.png 1x" />
                          </a>  
                    </div>:""}
                </div>
                <div id="SS_31_PCn_234567890_mobile_number_shad">
                    <span>{props.data.businessPhone}</span>
                </div>
                <div id="SS_31_PCaffanghanioperatelive_email_sh">
                    <span>{props.data.businessEmail}</span>
                </div>
                <div id="SS_31_PCaddress_med_shadow_space_websi">
                    <span>{props.data.businessAddress}</span>
                </div>
                <img id="SS_31_PClocation_img_shadow_space_webs" src="./Themes/ShoeStride/location_img_shadow_space_webs.png" srcset="./Themes/ShoeStride/location_img_shadow_space_webs.png 1x" />
                    
                <img id="SS_31_PCemail_address_shadow_space_web" src="./Themes/ShoeStride/email_address_shadow_space_web.png" srcset="./Themes/ShoeStride/email_address_shadow_space_web.png 1x" />
                    
                <img id="SS_31_PCphone_number_shadow_space_webs" src="./Themes/ShoeStride/phone_number_shadow_space_webs.png" srcset="./Themes/ShoeStride/phone_number_shadow_space_webs.png 1x" />
                    
                <div id="SS_31_PCn_Copyright_Operate_Live_OPC_P">
                    <span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
                </div>
            </div>
            <div id="SS_31_PCContact_Us">
                <span>Contact Us!</span>
            </div>
        </div>
        <div id="SS_31_PCGroup_15140">
            <svg class="SS_31_PCEllipse_44">
                <ellipse id="SS_31_PCEllipse_44" rx="32" ry="0.5" cx="32" cy="0.5">
                </ellipse>
            </svg>
            <div id="SS_31_PCYour_Name">
                <span>{props.data.businessName}</span>
            </div>
            <div id="SS_31_PCDISCOVER_LIMITED_SHOES__WITHOU">
                <span>DISCOVER LIMITED SHOES <br/>WITHOUT LIMITATIONS</span>
            </div>
            <img id="SS_31_PCthe-dk-photography-b9e8ffVw5XY" src="./Themes/ShoeStride/the-dk-photography-b9e8ffVw5XY.png" srcset="./Themes/ShoeStride/the-dk-photography-b9e8ffVw5XY.png 1x" />
                
            <img id="SS_31_PCthibaut-burckel-n2V5MLDPE-k-un" src="./Themes/ShoeStride/thibaut-burckel-n2V5MLDPE-k-un.png" srcset="./Themes/ShoeStride/thibaut-burckel-n2V5MLDPE-k-un.png 1x" />
                
            <img id="SS_31_PCthe-dk-photography-A8HaTLON5Gg" src="./Themes/ShoeStride/the-dk-photography-A8HaTLON5Gg.png" srcset="./Themes/ShoeStride/the-dk-photography-A8HaTLON5Gg.png 1x" />
                
            <img id="SS_31_PCkyle-austin-vHIv0AreyDk-unspla" src="./Themes/ShoeStride/kyle-austin-vHIv0AreyDk-unspla.png" srcset="./Themes/ShoeStride/kyle-austin-vHIv0AreyDk-unspla.png 1x" />
                
        </div>
        <div id="SS_31_PCGroup_15139">
            <svg class="SS_31_PCRectangle_752">
                <rect id="SS_31_PCRectangle_752" rx="0" ry="0" x="0" y="0" width="1366" height="157">
                </rect>
            </svg>
            <div id="SS_31_PCLorem_Ipsum_is_simply_dummy_te">
                <span>{props.data.introduction}</span>
            </div>
        </div>
        <div id="SS_31_PCGroup_15151">
            <div id="SS_31_PCGroup_15141">
                <svg class="SS_31_PCRectangle_752_b">
                    <rect id="SS_31_PCRectangle_752_b" rx="0" ry="0" x="0" y="0" width="915" height="376">
                    </rect>
                </svg>
                <img id="SS_31_PCmarten-bjork-_ccRYJWcNwk-unspl" src="./Themes/ShoeStride/marten-bjork-_ccRYJWcNwk-unspl.png" srcset="./Themes/ShoeStride/marten-bjork-_ccRYJWcNwk-unspl.png 1x" />
                    
                <div id="SS_31_PCScroll_Group_28">
                    <div id="SS_31_PCIt_is_a_long_established_fact_">
                        <span>{props.data.vision}</span>
                    </div>
                </div>
                <div id="SS_31_PCVISION">
                    <span>VISION</span>
                </div>
            </div>
            <div id="SS_31_PCGroup_15142">
                <svg class="SS_31_PCRectangle_752_ca">
                    <rect id="SS_31_PCRectangle_752_ca" rx="0" ry="0" x="0" y="0" width="915" height="376">
                    </rect>
                </svg>
                <img id="SS_31_PCxavier-teo-SxAXphIPWeg-unsplas" src="./Themes/ShoeStride/xavier-teo-SxAXphIPWeg-unsplas.png" srcset="./Themes/ShoeStride/xavier-teo-SxAXphIPWeg-unsplas.png 1x" />
                    
                <div id="SS_31_PCScroll_Group_28_ca">
                    <div id="SS_31_PCIt_is_a_long_established_fact__cb">
                        <span>{props.data.mission}</span>
                    </div>
                </div>
                <div id="SS_31_PCMISSION">
                    <span>MISSION</span>
                </div>
            </div>
            <div id="SS_31_PCGroup_15143">
                <svg class="SS_31_PCRectangle_752_ce">
                    <rect id="SS_31_PCRectangle_752_ce" rx="0" ry="0" x="0" y="0" width="915" height="376">
                    </rect>
                </svg>
                <img id="SS_31_PChanne-van-oeckel-4R2tRB7R5uQ-u" src="./Themes/ShoeStride/hanne-van-oeckel-4R2tRB7R5uQ-u.png" srcset="./Themes/ShoeStride/hanne-van-oeckel-4R2tRB7R5uQ-u.png 1x" />
                    
                <div id="SS_31_PCScroll_Group_28_cg">
                    <div id="SS_31_PCIt_is_a_long_established_fact__ch">
                        <span>{props.data.principles}</span>
                    </div>
                </div>
                <div id="SS_31_PCPRINCIPLES">
                    <span>PRINCIPLES</span>
                </div>
            </div>
        </div>
        <div id="SS_31_PCGroup_15150">
            <div id="SS_31_PCNEW_EDITION">
                <span>NEW EDITION</span>
            </div>
            <div id="SS_31_PCGroup_15145">
                <svg class="SS_31_PCRectangle_753">
                    <rect id="SS_31_PCRectangle_753" rx="0" ry="0" x="0" y="0" width="185" height="61">
                    </rect>
                </svg>
                <div id="SS_31_PCView_More">
                    <span>View More</span>
                </div>
            </div>
            <div id="SS_31_PCScroll_Group_91">
	{props.offeringsData.map((off,idx)=>(
                <div id="SS_31_PCGroup_15144">
                    <svg class="SS_31_PCRectangle_754">
                        <rect id="SS_31_PCRectangle_754" rx="10" ry="10" x="0" y="0" width="243" height="331">
                        </rect>
                    </svg>
                    <img id="SS_31_PCthibaut-burckel-n2V5MLDPE-k-un_cr" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/ShoeStride/thibaut-burckel-n2V5MLDPE-k-un_cr.png"} />
                        
                    <div id="SS_31_PCTitle">
                        <span>{off.offeringTitle}</span>
                    </div>
                    <div id="SS_31_PCn_10000">
                        <span>₹ {off.budget}</span>
                    </div>
                </div>))}
            </div>
        </div>
    </div>
  )
}

export default ShoeStride