import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
 import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/MyOffandComOffMob.css';
import Switch from '@mui/material/Switch';

import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";

import OfferingsMobUI from '../../Components/MobUI/OfferingsMobUI';
import CommunityOfferingsUI from '../CommunityOfferingsUI';
import CommunityOfferingsMobUI from './CommunityOfferingsMobUI';
import ViewOfferingMobUI from './ViewOfferingMobUI';


const MyOffandComOffMob = () => {   

//UseState
    const [toggle,setToggle] = useState(false);
    const [selectedOfferingsId,setSelectedOfferingsId] = useState("");
    const { user, setUser} = useContext(UserContext);


//UseEffect

  return (
    <div>
  <div id="Offerings_page_mob">
	{/* <img id="offering_bg_img" src="offering_bg_img.png" srcset="offering_bg_img.png 1x" /> */}
		
	<div id="My_Offerings_name_tag">
		<span>{toggle===false?"My Offerings":"Community Offerings"}</span>
	</div>
	<div class="offering_switch_new">
  <Switch id='offering_switch_new' onChange={(e)=>setToggle(e.target.checked)} checked={toggle} color="default" />
	</div>

  </div>

    {selectedOfferingsId===""? toggle===false  ?<OfferingsMobUI offeringSelected={(val)=>setSelectedOfferingsId(val)}/> : <CommunityOfferingsMobUI offeringSelected={(val)=>setSelectedOfferingsId(val)}/>:<ViewOfferingMobUI selectedOfferingsId={selectedOfferingsId} isOffering={toggle} backOff={()=>setSelectedOfferingsId("")}/>}
    
    </div>
  )
}

export default MyOffandComOffMob
