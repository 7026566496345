import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from './UserContext';
import { useNavigate } from "react-router-dom";
import "../styles/CreateCommunityPopUpUI.css";
import Modal from '@mui/material/Modal';


const CreateCommunityPopUpUI = (props) => {

    var navigate = useNavigate();
    const { user, setUser} = useContext(UserContext);
    const [openCommunityModal,setOpenCommunityModal] = useState(false);
    const [communityName,setCommunityName] = useState("");
    const [selectedCommunityId,setSelectedCommunityId] = useState("");
    const [selectedCommunityName,setSelectedCommunityName] = useState("");
    const [communityDescription,setCommunityDescription] = useState("");
    const [communities,setCommunities] = useState("");
    const [img,setImg] = useState("default.jpg");
    const [profile,setProfile]= useState("");



  useEffect (()=>{

    setOpenCommunityModal(props.openComModal);

    },[props.openComModal])

    const closeCommunity = ()=>{
        setOpenCommunityModal(false);
        props.closeComModal()
    }


     //To Upload Picture
     const handleImageUpload = async (e) => {
      if (e.target.files.length > 0) {
      // if (e.target.files[0].size > 71680) {
      //   alert("Please choose a file with size below 70kb!");
      //   return;
      // }
      handleFileSelect(e.target.files[0]);
      let formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.value);
      setProfile(formdata);
      }
    };

    //Select file to upload
function handleFileSelect(f) {
  var reader = new FileReader();
  // Closure to capture the file information.
  reader.onload = (function (theFile) {
    return function (e) {
      var binaryData = e.target.result;
      //Converting Binary Data to base 64
      var base64String = window.btoa(binaryData);
      //showing file converted to base64
      setImg("data:image/jpeg;base64," + base64String);
    };
  })(f);
  // Read in the image file as a data URL.
  reader.readAsBinaryString(f);
}

const createCommunity = () => {

  if(communityName.length === 0 || communityName.length === "" ){

    return alert("Please Enter Community Name!!")
  }
  if(profile.length === 0){
    return alert("Please select Profile Image !!")
  }


  setOpenCommunityModal(false)
  const apiurl = process.env.REACT_APP_API_URL + "/community/createcommunity";
  var apiparam = {
    method: "POST",
    headers: {
      communityname:communityName,
      communitydiscription:encodeURIComponent(communityDescription),
      createdbyid:user._id,
      createdbyname:user.name,
      
    },
    body:profile,
  };

  fetch(apiurl, apiparam)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
        alert("Community Created Successfully!!");
        // getCommunity()
        props.closeComModal()
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};



  return (
    <div>
         <Modal
        open={openCommunityModal}
        onClose={closeCommunity}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
         <div id="pop_up_create">
	<div id="Group_popup_cr_com">
		<svg class="Path_3_com" viewBox="0 0 118 265">
			<path id="Path_3_com" d="M 48 0 L 70 0 C 96.50967407226562 0 118 21.49032974243164 118 48 L 118 217 C 118 243.5096740722656 96.50967407226562 265 70 265 L 48 265 C 34.21497344970703 265 21.78717422485352 259.1890258789062 12.85527420043945 249.6935272216797 C 4.951372623443604 241.2928314208984 0 229.7246398925781 0 217 L 0 48 C 0 21.49032974243164 21.49032974243164 0 48 0 Z">
			</path>
		</svg>
		<div id="Create_a_Community_lbl">
			<span>Create a Community</span>
		</div>
		<img id="profilebackgroundPC" src="profilebackground.png" />
			
    <label for="image" >
            <input id="image" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
            <img id='profileimgupload' width="100" height="100" src={img?img:"profile.png"}  style={{position:"absolute",right:"130px",top:"160px"}}/>
            </label>
		{/* <img id="profile" src="profile.png" srcset="profile.png 1x, profile@2x.png 2x" /> */}
			
		<input placeholder="Community Name" class="communityName_pop" value={communityName} onChange={(e)=> {
         setCommunityName(e.target.value);}}/>
			{/* <rect id="Rectangle_27" rx="0" ry="0" x="0" y="0" width="211" height="32">
			</rect>
		</input> */}
		<textarea placeholder="Community Description" class="communityDescription_pop"  value={communityDescription} onChange={(e)=> {
       setCommunityDescription(e.target.value);}}/>
			{/* <rect id="Rectangle_28" rx="0" ry="0" x="0" y="0" width="211" height="49">
			</rect>
		</textarea> */}
		<button id="Group_77" onClick={()=>createCommunity()}>
			<svg class="Rectangle_29">
				<rect id="Rectangle_29" rx="6" ry="6" x="0" y="0" width="81" height="24">
				</rect>
			</svg>
			<div id="Submit">
				<span>Submit</span>
			</div>
		</button>
	</div>
	<img id="cancelicon_pop" src="closeicon.png"  onClick={()=>closeCommunity()}/>
		
</div>
      </Modal>
    </div>
  )
}

export default CreateCommunityPopUpUI
