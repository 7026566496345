import React, { useContext, useState, useEffect } from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import '../../styles/ComMyComSlide.css';
import { UserContext } from ".././UserContext";
import CreateCommunityPopUpUI from "../CreateCommunityPopUpUI";

const ComMyComSlide = (props) => {
  
    const { user, setUser } = useContext(UserContext);
    const [openCommunityModal,setOpenCommunityModal] = useState(false);
    const [searchParams] = useSearchParams();
    const [selectedCommunityId,setSelectedCommunityId] = useState("");
    const [selectedCommunityName,setSelectedCommunityName] = useState("");
    const [communityDescription,setCommunityDescription] = useState("");
    const [communities,setCommunities] = useState("");
    const [mode,setMode] = useState("create");

    
useEffect(()=>{
    if(user){
        if(props.openMyComSlide)
        {
            getCommunity() 
        }
    }
    
    },[props.openMyComSlide])

const handleClose = ()=>{
    props.closeMyComSlide()
}

const openCreatePopUp = ()=>{
    setMode("create")
    setOpenCommunityModal(true)
  }

  const updateCards = (id,name)=>{
    props.closeMyComSlide(id,name)
  }
  

   //Converting Image
   function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

//get comunity
const getCommunity = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/community/getcommunity/"+user._id; 

    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          repos.data[0] && setSelectedCommunityId(repos.data[0]._id)
          repos.data[0] && setSelectedCommunityName(repos.data[0].communityName)

          setCommunities(repos.data);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


  return (
    <div>
   <div id="my_com_slide_">
	<svg class="top_bg_green_req_mob_y">
		{/* <linearGradient id="top_bg_green_req_mob_y" spreadMethod="pad" x1="1" x2="0" y1="1" y2="0.395">
			<stop offset="0" stop-color="rgba(219,242,231,1)" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
		</linearGradient> */}
		<rect id="top_bg_green_req_mob_y" rx="0" ry="0" x="0" y="0" width="100%" height="100%">
		</rect>
	</svg>
	<div id="mycom_pop_up_bottom_scroll">
    <div style={{width:"100%",display:"block"}}>
    {(communities||[]).map((com,idx)=>(
        <div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
		<div id="com_card_com_req_mob" onClick={()=>{setSelectedCommunityId(com._id);setSelectedCommunityName(com.communityName);updateCards(com._id,com.communityName)}}>
			<svg class="bdr_com_req_mob_">
            <linearGradient id="bdr_com_req_mob_" spreadMethod="pad" x1="0.13" x2="0.89" y1="0.039" y2="1">
					<stop offset="0" stop-color="#65ff7f" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient> 
				<rect id="bdr_com_req_mob_" rx="24.5" ry="24.5" x="0" y="0" width="180" height="49">
				</rect>
			</svg>
			<img id="pfp_pic_com_req_mob" src={com.profilePic  ?arrayBufferToBase64(com.profilePic.data.data):"coding.png"} />
				
			<marquee id="title_lbl_com_req_mob">
				<span>{com.communityName}</span>
			</marquee>
			{/* <img id="minus_close_com_mycom_mob" src="minus_2_close_com_req_mob.png" srcset="minus_2_close_com_req_mob.png 1x" /> */}
				
		</div>
        </div>
        ))}
		</div>
		<button title="Add Requirements" id="my_combtn_com_mob_12" onClick={()=>openCreatePopUp()}>
			<svg class="n_2_btn_com_mynew_mob">
				<rect id="n_2_btn_com_mynew_mob" rx="20" ry="20" x="0" y="0" width="139" height="40">
				</rect>
			</svg>
			<div id="My_com__lbl_btn_com_req_mob">
				<span>Community</span>
			</div>
			<img id="plus_img_btn_comoff_mob_12" src="plus_img_btn_comoff_mob_12.png" srcset="plus_img_btn_comoff_mob_12.png 1x" />
				
		</button>
	</div>
	<img id="down-arrow_for_top_mom" src="down-arrow_for_top_mom.png" srcset="down-arrow_for_top_mom.png 1x" onClick={()=>handleClose()}/>
		
</div>
<CreateCommunityPopUpUI  openComModal={openCommunityModal} mode={mode} closeComModal={()=>{setOpenCommunityModal(false);getCommunity()}}/>
    </div>
  )
}

export default ComMyComSlide
