import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from './UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import '../styles/AddCommunitesConMemPopUp.css';
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const AddCommunitiesConMemPopUp = (props) => {

    //useState
    var navigate = useNavigate();
    // const communityId = props.communityId;
    // const communityName = props.communityName;
    const { user, setUser} = useContext(UserContext);
    const [openGridModel,setOpenGridModel] = useState(false);
    const [oddConnections,setOddConnections] = useState([]);


useEffect(()=>{
if(user){
    if(props.openComMem)
    {
        setOpenGridModel(props.openComMem)
if(props.communityId){
    getConnectionWithoutCommunity()
}
       
        
    }
}    


},[props.openComMem])

const closeGridModel = ()=>{
    setOpenGridModel(false)
    props.closeComMem()
}


  //Get connection not in community
  const getConnectionWithoutCommunity = async()=>{
    let apiURL=process.env.REACT_APP_API_URL + "/connection/getconnectionwithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:props.communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddConnections(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


//adding Community to Offerings
const updateConnectionStatus = (connectionId)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/connection/addcommunitytoconnection/"+ connectionId ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:props.communityId,
            communityname:props.communityName,
            userid:user._id,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          console.log(repos.data)
          getConnectionWithoutCommunity()
       
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }



  return (
    <div>
           
<Modal
        open={openGridModel}
        onClose={closeGridModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
<div id="req_pop_up">
	<img id="bg_pop_req_design" src="coonectioon_pop_up_bg_deisgn.png" srcset="coonectioon_pop_up_bg_deisgn.png 1x" />
		
	<svg class="dsfndsfd_s">
		<linearGradient id="dsfndsfd_s" spreadMethod="pad" x1="0" x2="1" y1="0" y2="1">
			<stop offset="0" stop-color="#dbf2e7" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="dsfndsfd_s" rx="0" ry="0" x="0" y="0" width="265" height="30">
		</rect>
	</svg>
	<div id="Requirements_pop_na">
		<span>Members</span>
	</div>
	{/* <div id="grp_useless_req">
		<div id="req_add">
			<span>Title</span>
		</div>
		<div id="Add_title_req">
			<span>Add</span>
		</div>
	</div> */}
	<div id="req_pop_scroll">
   { oddConnections.map((con,idx) =>(
    <div style={{top:(idx*40).toString()+"px",position:"relative",left:"0px"}}>
		<div id="Group_79">
			<svg class="Rectangle_22">
				<rect id="Rectangle_22" rx="6" ry="6" x="0" y="0" width="253" height="37">
				</rect>
			</svg>
			<div id="Testing">
				<span>{con.user1Id===user._id?con.user2Name:con.user1Name}</span>
			</div>
			<img id="plus1" src="add-button_con.png" srcset="add-button_con.png 1x" onClick={()=>{updateConnectionStatus(con._id);}}/>
		</div>
        </div>  
   ))}
	</div>
    <img title="Close" id="close_comconmem_pc"  onClick={()=>{closeGridModel()}} src="close2.png" srcset="close2.png 1x, close2@2x.png 2x" />
</div>
       </Modal>
    </div>
  )
}

export default AddCommunitiesConMemPopUp
