import React, { useEffect, useState, useContext} from 'react'
import "../../styles/CalanderDashboardMob.css";
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import CalanderEntryPopUpUI from '../PC/CalanderEntryPopUpUI';
import CalenderProfileUI from '../PC/CalenderProfileUI';
import CalenderPageUI from '../PC/CalenderPageUI';
import CalenderAppointmentCard from '../PC/CalenderAppointmentCard';
import HourCard from '../PC/HourCard';
import CalanderAlarmsMob from './CalanderAlarmsMob';
import CalanderTasksMob from './CalanderTasksMob';
import CalanderMeetingsMob from './CalanderMeetingsMob';
import CalanderEventsMob from './CalanderEventsMob';
import CalanderSlideMob from './CalanderSlideMob';

const CalanderDashboardMob = () => {
	const navigate = useNavigate()
    const [openCalanderEntryPop,setOpenCalanderEntryPop] = useState(false);
	const [slotType,setSlotType] = useState("event");
	const [slotTime,setSlotTime] = useState(new Date().toISOString().substring(0,16));
	const [slotDate,setSlotDate] = useState(new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString().padStart(2, '0') + "-" + new Date().getDate().toString().padStart(2, '0'));

	const [events,setEvents] = useState([]);
	const [alarms,setAlarms] = useState([]);
	const [tasks,setTasks] = useState([]);
	const [meetings,setMeetings] = useState([]);
	const [refresh,setRefresh] = useState(false);
	const [ appointments,setAppointments] = useState([[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);//{title:"",type:"",startTime:"",endTime:""}
	const [searchParams] = useSearchParams();
    const { user, setUser } = useContext(UserContext);
	const [selectedTab,setSelectedTab] = useState("alarms");
	const [openCalander,setOpenCalander] = useState(false);

// useEffect(()=>{
// if(user){
// 	getCalenderDetails()
	
// }

// },[user])



useEffect(()=>{
if(user){
	getCalenderDetailsByDate()
	
}

},[user,slotDate])


useEffect(()=>{
	
},[appointments])



 // //Get offerings by id
 const getCalenderDetailsByDate = ()=>{
      
	let apiURL=process.env.REACT_APP_API_URL + "/calenders/getallcalenderdatabydate/" +user._id ;
	let apiParams = {
		method:"GET",
		headers:{
			selecteddate:slotDate,
		}
		
	};
	fetch(apiURL, apiParams)
	.then((res)=> (res.status===200 ? res.json() : res.text()))
	.then(async(repos)=> {
		if (repos.data) {

	    // console.log(repos.data)
		setAppointments([[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]])
		setEvents(repos.data.events.map( eve=> {
					var item = {title:eve.title,type:"event",startTime:new Date(eve.startsOn)+(330*60000),endTime:new Date(eve.endsOn)+(330*60000)}
					var currApts = appointments;
				
					currApts[new Date(new Date(eve.startsOn)+(330*60000)).getHours()] = [...(currApts[new Date(eve.startsOn).getHours()]),item]
					
					 setAppointments(currApts)
					return eve;
				}))



		setAlarms(repos.data.alarms.map(alm=>{
			
			var item = {title:alm.title,type:"alarm",startTime:new Date(alm.startOn)+(330*60000),endTime:new Date(alm.startOn)+(330*60000)}
			var currApts = appointments;
		
			currApts[new Date(new Date(alm.startOn)+(330*60000)).getHours()] = [...currApts[new Date(alm.startOn).getHours()],item]
			
			setAppointments(currApts)
			return alm;
		}))


		 setTasks(repos.data.tasks.map( tsk=> {
				var item = {title:tsk.title,type:"task",startTime:new Date(tsk.startsAt)+(330*60000),endTime:new Date(tsk.endsAt)+(330*60000)}
				var currApts = appointments;
			
				currApts[new Date(new Date(tsk.startsAt)+(330*60000)).getHours()] = [...currApts[new Date(tsk.startsAt).getHours()],item]
			
				setAppointments(currApts)
				return tsk;
			}))

		 setMeetings(repos.data.meetings.map( mtg=> {
				var item = {title:mtg.meetingTitle,type:"meeting",startTime:new Date(mtg.meetingStartDateTime)+(330*60000),endTime:new Date(mtg.meetingEndDateTime)+(330*60000)}
				var currApts = appointments;
				// setAppointments(null)
				currApts[new Date(new Date(mtg.meetingStartDateTime)+(330*60000)).getHours()]= [...currApts[new Date(mtg.meetingStartDateTime).getHours()],item]
				
				setAppointments(currApts)
				return mtg;
			}))

		}else alert(repos.message || repos);  
	}) 
	.catch((err) => alert(err));
  }

  

  	
  return (
    <div>
    <div id="mb_top_part_calender">
	<div id="mb_com_tata_next_grp_mob">
		<div id="mb_completed_grp_calender_mob">
			<div id="mb_Completed_lbbl_calender_mob">
				<span>Completed</span>
			</div>
			<svg class="mb_count_bdr_mob">
				<rect id="mb_count_bdr_mob" rx="10.5" ry="10.5" x="0" y="0" width="40" height="21">
				</rect>
			</svg>
			<div id="mb_n_0_count_calender_mob">
				<span>30</span>
			</div>
			<svg class="mb_completed_staus_calender_mob">
				<ellipse id="mb_completed_staus_calender_mob" rx="3.5" ry="3.5" cx="3.5" cy="3.5">
				</ellipse>
			</svg>
		</div>
		<div id="mb_next_up_calender_mobgrp">
			<div id="mb_Next_Up_lbl_calender_mob">
				<span>Next Up</span>
			</div>
			<svg class="mb_n_0_bdr_next_up_calender_mob">
				<rect id="mb_n_0_bdr_next_up_calender_mob" rx="10.5" ry="10.5" x="0" y="0" width="69" height="21">
				</rect>
			</svg>
			<svg class="mb_arrow_next_up_mob" viewBox="0 0 8 8">
				<path id="mb_arrow_next_up_mob" d="M 3.999999523162842 0 L 7.999999523162842 8 L 0 8 Z">
				</path>
			</svg>
			<svg class="mb_next_up_arrow_line_mob" viewBox="0 0 1 8">
				<path id="mb_next_up_arrow_line_mob" d="M 0 0 L 0 8">
				</path>
			</svg>
			<div id="mb_Next_Up_lbl_calender_mob_c">
				<span>230 : 00</span>
			</div>
			<marquee id="mb_Next_Up_lbl_calender_mob_da">
				<span>Title Next in</span>
			</marquee>
		</div>
		<div id="mb_todo_grp_calender_mob">
			<div id="mb_To-Do_lbl_calender_mob">
				<span>Upcoming</span>
			</div>
			<svg class="mb_n_0_bdr_calendemob">
				<rect id="mb_n_0_bdr_calendemob" rx="10.5" ry="10.5" x="0" y="0" width="40" height="21">
				</rect>
			</svg>
			<div id="mb_n_0_calender_mob">
				<span>10</span>
			</div>
		</div>
	</div>
	<div id="mb_Icon_feather-menu_mob" onClick={()=>setOpenCalander(true)}>
		<svg class="mb_Path_6953_mobcal" viewBox="4.5 18 27 3">
			<path id="mb_Path_6953_mobcal" d="M 4.5 18 L 31.5 18">
			</path>
		</svg>
		<svg class="mb_Path_6954_mobcal" viewBox="4.5 9 27 3">
			<path id="mb_Path_6954_mobcal" d="M 4.5 9 L 31.5 9">
			</path>
		</svg>
		<svg class="mb_Path_6955_mobcal" viewBox="4.5 27 27 3">
			<path id="mb_Path_6955_mobcal" d="M 4.5 27 L 31.5 27">
			</path>
		</svg>
	</div>
{selectedTab==="alarms"?<CalanderAlarmsMob data={alarms} selectedTab={(e)=>setSelectedTab(e)}/>:""}
{selectedTab==="tasks"?<CalanderTasksMob data={tasks} selectedTab={(e)=>setSelectedTab(e)}/>:""}
{selectedTab==="meetings"?<CalanderMeetingsMob data={meetings} selectedTab={(e)=>setSelectedTab(e)}/>:""}
{selectedTab==="events"?<CalanderEventsMob  data={events} selectedTab={(e)=>setSelectedTab(e)}/>:""}

</div>

{openCalander === true ?<CalanderSlideMob appointments={appointments} slotDate={slotDate} selectedDate={(e)=>{setSlotDate(e);setAppointments([[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);getCalenderDetailsByDate()}} refresh={()=>getCalenderDetailsByDate()} closeCalender={(e)=>{setOpenCalander(false);}}/>:""}
    </div>
  )
}

export default CalanderDashboardMob
