import React, { useContext, useState, useEffect,useRef } from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import {SocketContext} from '../../SocketContext';
import { io } from "socket.io-client";
import { useSearchParams } from 'react-router-dom';
import '../../styles/MeetingRoomOnlineUsersSlide.css';
import { UserContext } from ".././UserContext";
import MeetingPopUpUI from '../MeetingPopUpUI';


const MeetingRoomOnlineUsersSlide = (props) => {

const [onlineUsersSlide,setOnlineUsersSlide] = useState(false);
const { user, setUser } = useContext(UserContext);
const [searchParams] = useSearchParams();
const [onlineUsers, setOnlineUsers] = useState([]);
const [otherUserId,setOtherUserId] = useState("");
const [otherUserName,setOtherUserName] = useState("");
const checkOnlineStatusTimer = useRef();

// useEffect(()=>{
// if(user){
// 	findOnlineUser()
// 	clearInterval(checkOnlineStatusTimer.current)
//     checkOnlineStatusTimer.current = setInterval(() => findOnlineUser(), 5000);

// }
// },[user])

useEffect(()=>{
	if(user){
		
			setOnlineUsersSlide(props.openSlide)
			//findOnlineUser();
		
	}
	},[props.openSlide])
	
// useEffect(()=>{
// 	if(user){
		
// 			setOnlineUsers(props.onlineUsers)
		
// 	}
// 	},[props.onlineUsers])

// const findOnlineUser = () => {
//     if(user){

//     let apiURL = process.env.REACT_APP_API_URL + "/status/onlinestatus/"+user._id;

//     fetch(apiURL)
//       .then((res) => (res.status === 200 ? res.json() : res.text()))
//       .then((repos) => {
//         if (repos.data) {
        
//           setOnlineUsers(repos.data.filter(rec=>(rec.mobile!=user.mobile.substring(user.mobile.length-10))));
//         } else alert(repos.message || repos);
//       })
//       .catch((err) => console.log(err));
//   }
      
// };

const handleClose=()=>{
	setOnlineUsersSlide(false);
	props.closeSlide()
}


  return (
    <div>
      <div id="onlineuser_pop" className="slideInRight">
	<div id="over_all_onlineuser_pop_">
		<div id="inner_overall_">
			<img id="bg_design_online_user_rec" src="bg_design_online_user_rec.png" srcset="bg_design_online_user_rec.png 1x, bg_design_online_user_rec@2x.png 2x" onClick={()=>handleClose()}/>
				
			<div id="username_lbl_and_rec">
				<svg class="Online_user_bdr_mob">
					<rect id="Online_user_bdr_mob" rx="16.5" ry="16.5" x="30" y="0" width="120" height="33">
					</rect>
				</svg>
				<div id="online_user_lbl_mob">
					<span>Online Users</span>
				</div>
			</div>
		</div>
		<div id="online_user_scrooll">
		{(props.onlineUsers||[]).map((ou) => (
			<div id="card1_online_user_" onClick={() =>{ setOtherUserId(ou.socketId);setOtherUserName(ou.name);props.closeSlide({id:ou.socketId,name:ou.name})}}>
				<svg class="card_1_bdr_mob">
					<rect id="card_1_bdr_mob" rx="9" ry="9" x="0" y="0" width="193" height="60">
					</rect>
				</svg>
				<img id="user_1_img_tag_mob" src="user_pfp_profile_page.png" />
					
				<div id="online_user_lbl_mob_u">
					<span><marquee scrollamount="4">{user.name!=ou.name?ou.name:""}</marquee></span>
				</div>
				<div id="call_btn_over_all_mob">
					<svg class="Online_user_bdr_mob_w">
						<rect id="Online_user_bdr_mob_w" rx="11" ry="11" x="0" y="0" width="45" height="22">
						</rect>
					</svg>
					<div id="online_user_lbl_mob_x">
						<span>Call</span>
					</div>
				</div>
			</div>
			))}
		</div>
		<img id="arrow_right_open_close" src="arrow-right_open_close.png" srcset="arrow-right_open_close.png 1x, arrow-right_open_close@2x.png 2x" onClick={()=>handleClose()}/>
			
	</div>
</div>
    </div>
  )
}

export default MeetingRoomOnlineUsersSlide
