import React,{useState,useEffect,useContext} from 'react'
import { UserContext } from '../UserContext';
import '../../styles/OfferingProfileFeedMobUI.css';

const OfferingProfileFeedMobUI = (props) => {
const { user, setUser} = useContext(UserContext);
const [offerings,setOfferings] = useState([]);

useEffect(()=>{
	if(user)
	getOfferings()
},[user])

	//Get applicant by id
	const getOfferings = ()=>{
		
	 let apiURL=process.env.REACT_APP_API_URL + "/offerings/getallofferings";
	 let apiParams = {
		 method:"GET",
	 };
	 fetch(apiURL, apiParams)
	 .then((res)=> (res.status===200 ? res.json() : res.text()))
	 .then((repos)=> {
		 if (repos.data) {
	 
		 setOfferings(repos.data)
		  
		 
		 }else alert(repos.message || repos);  
	 }) 
	 .catch((err) => alert(err));
   }
 

  return (
    <div>
    <div id="off_feed_mob">
	<div id="offering_ffeed_grp_offy_feed_m">
		<svg class="off_feed_bdr_offy_feed_mob">
			<rect id="off_feed_bdr_offy_feed_mob" rx="15" ry="15" x="0" y="0" width="100%" height="608">
			</rect>
		</svg>
		<div id="Offerings_Feedoffy_feed_mob">
			<span>Offerings Feed</span>
		</div>
		<div id="off_feed_scroll_offy_feed_mob">
			
			{offerings.map((rec,idx)=>(
			<div id="card_1_offy_feed_mob" onClick={()=>props.data(rec)}>
				<img id="card_1_of_feed_1_offy_feed_mob" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"profilebackground.png"} />
					
				<div id="Offering_lbl_offy_feed_mob">
					<span>{rec.offeringTitle}</span>
				</div>
				<div id="Mohammed_Affan_offy_feed_mob">
					<span>{rec.vendorName}</span>
				</div>
			</div>
			))
			}
		</div>
	</div>
</div>
    </div>
  )
}

export default OfferingProfileFeedMobUI
