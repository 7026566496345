import React from 'react'

const BargainTimeLinePC = (props) => {
  return (
    <div id="Scroll_timeline_view_page_pc_off">
{props.bargainsHistory.map((rec)=>(
	<div id="timeline_grp_view_page_pc_off">
			<svg class="first_green_bdr__view_page_pc_">
				<rect id="first_green_bdr__view_page_pc_" rx="6.5" ry="6.5" x="0" y="0" width="193" height="13">
				</rect>
			</svg>
			<svg class="firsty_bdr_genn__view_page_pc_">
				<ellipse id="firsty_bdr_genn__view_page_pc_" rx="15" ry="15" cx="15" cy="15">
				</ellipse>
			</svg>
			<svg class="first_circle__view_page_pc_off">
				<ellipse id="first_circle__view_page_pc_off" rx="9" ry="9" cx="9" cy="9">
				</ellipse>
			</svg>
			<svg class="first_green_line_view_page_pc_">
				<rect id="first_green_line_view_page_pc_" rx="3" ry="3" x="0" y="0" width="182" height="6">
				</rect>
			</svg>
			<div id="Bargained__view_page_pc_offeri">
				<span>{rec.status}</span><br/>
				<span>{rec.userName}</span>
			</div>
			<div id="n_4__12__view_page_pc_offering">
				<span>{rec.createdAt.toLocaleString().substring(0,10)}</span>
			</div>
		</div>
		))
}
	</div>
  )
}

export default BargainTimeLinePC
