import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyEveandComEveMobUI.css';
import { UserContext } from '../UserContext';
import { Switch } from '@mui/material';
import MyEventsMobUI from './MyEventsMobUI';
import CommunityEventsMobUI from './CommunityEventsMobUI';

const MyEveandComEveMobUI = () => {
	const [toggle,setToggle] = useState(false);

  return (
   
      <div id="with_edit_option_mob">
		<div id="event_nane_offreqevent_req_PC">
			<span>{toggle === false ? "Events":"Community Events"}</span>
		</div>
		{/* <svg class="swicj_mob">
			<rect id="swicj_mob" rx="0" ry="0" x="0" y="0" width="30" height="12">
			</rect>
		</svg> */}
		<div class="swicj_mob">
      <Switch id='swicj_mob' onChange={(e)=>setToggle(e.target.checked)} checked={toggle} color="default" />
	  </div>
	
			
		{toggle === false ? <MyEventsMobUI/> :<CommunityEventsMobUI />}	
		
	</div>
   
  )
}

export default MyEveandComEveMobUI
