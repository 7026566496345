import React,{useState,useEffect,useContext} from 'react'
import { UserContext } from '../UserContext';
import '../../styles/EventsProfileMobUI.css'

const EventsProfileMobUI = () => {

	
	const { user, setUser} = useContext(UserContext);
    const [events,setEvents] = useState([]);

useEffect(()=>{
	if(user)
	getEvents()
},[user])

	//get event
const getEvents = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/events/getallevents"; 

    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
			console.log(repos.data)
          setEvents(repos.data);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


  return (
    <div>
    <div id="event_feed_mob">
	<div id="Events_Feed_lbl_profile_page_m">
		<span>Events Feed</span>
	</div>
	<div id="event_feed_scroll_mob">
	{events.map((eve)=>(
		<div id="event_feed_grp_feed_mob_eventz">
			<svg class="bdr_card_1_feed_mob_eventz">
				<rect id="bdr_card_1_feed_mob_eventz" rx="10" ry="10" x="0" y="0" width="100%" height="100">
				</rect>
			</svg>
			<img id="img_card_feed_mob_eventz" src={eve.eventImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+eve.eventImageUrl.split('/')[5]:"img_card_feed_mob_eventz.png"}  />
				
			<div id="feed_mob_eventz_name_lbl">
				<span>{eve.title}</span>
			</div>
			<div id="Eventsfeed_mob_eventz_profile_">
				<span>Starts </span><span style={{fontStyle:"normal",fontWeight:"normal"}}>{eve.startsOn.slice(0,10)} - {eve.startsOn.slice(11,16)} IST</span><br/><span>Ends </span><span style={{fontStyle:"normal",fontWeight:"normal"}}>{eve.endsOn.slice(0,10)} - {eve.endsOn.slice(11,16)} IST</span>
			</div>
			<div id="Category_-_Education_lbl_feed_">
				<span>Category - {eve.category}</span>
			</div>
			<div id="n_50000_lbl_feed_mob_eventz">
				<span>₹ {eve.cost}</span>
			</div>
		</div>
	))}
	</div>
</div>

    </div>
  )
}

export default EventsProfileMobUI
