import React,{useState,useEffect,useContext} from 'react';
import { UserContext } from '../UserContext';
import '../../styles/RequirementProfileFeed.css'

const ProfileRequirementsPC = (props) => {

  const { user, setUser} = useContext(UserContext);
  const [requirements,setRequirements] = useState([]);

useEffect(()=>{
if(user){
  //getRequirements()
  props.requirements && setRequirements(props.requirements)
}
},[user,props.requirements])

  const getRequirements = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getallrequirements";
    let apiParams = {
        method:"GET",
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        
        setRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
  return (
    <div>
      <div id="req_feeed">
	<div id="req_feeed_grp">
		<svg class="req_feed_bdr">
			<rect id="req_feed_bdr" rx="15" ry="15" x="0" y="0" width="100%" height="251">
			</rect>
		</svg>
		<div id="Requirements_Feed_lbl">
			<span>Requirements</span>
		</div>
		<div id="req_feed_scroll">

		{requirements.map((rec,idx)=>(
			<div id="card_1_grp_profile_req"  style={{left:idx*90+"px"}}>
				<img id="req_feed_1_card" src={rec.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultRequirementsImageUrl.split('/')[5]):"profilebackground.png"} />
					
				<div id="Offering_1_lbl">
					<span>{rec.requirementTitle}</span>
				</div>
				<div id="Mohammed_Affan_lbl">
					<span>{rec.customerName}</span>
				</div>
			</div>
      ))
    }
		</div>
	</div>
</div>

    </div>
  )
}

export default ProfileRequirementsPC
