import React from 'react'
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import LoginUI from './Components/LoginUI';
import SignUpUI from './Components/SignUpUI';
import ForgotPasswordUI from './Components/ForgotPasswordUI';
import FlamingoFiesta from './Components/PC/Themes/FlamingoFiesta';
import ComingSoonPageUI from './Components/PC/ComingSoonPageUI';
import OceanicBliss from './Components/PC/Themes/OceanicBliss';
import EnchantingEmerald from './Components/PC/Themes/EnchantingEmerald';
import HarmonyHaven from './Components/PC/Themes/HarmonyHaven';
import ShadowSpace from './Components/PC/Themes/ShadowSpace';
import KnowledgeKorner from './Components/PC/Themes/KnowledgeKorner';
import DriveVelocity from './Components/PC/Themes/DriveVelocity';
import RideRevolution from './Components/PC/Themes/RideRevolution';
import FashionFinds from './Components/PC/Themes/FashionFinds';
import JourneyJunkies from './Components/PC/Themes/JourneyJunkies';
import MobileMarvels from './Components/PC/Themes/MobileMarvels';
import PawPalace from './Components/PC/Themes/PawPalace';
import ChefsKitchen from './Components/PC/Themes/ChefsKitchen';
import FeatheredScape from './Components/PC/Themes/FeatheredScape';
import FurniGem from './Components/PC/Themes/FurniGem';
import GamersHub from './Components/PC/Themes/GamersHub';
import TeaCorner from './Components/PC/Themes/TeaCorner';
import SharpStyles from './Components/PC/Themes/SharpStyles';
import RealtyScape from './Components/PC/Themes/RealtyScape';
import KnowledgeSpace from './Components/PC/Themes/KnowledgeSpace';
import BakersStop from './Components/PC/Themes/BakersStop';
import HealthHub from './Components/PC/Themes/HealthHub';
import ChaatsParadice from './Components/PC/Themes/ChaatsParadice';
import FlowMasters from './Components/PC/Themes/FlowMasters';
import CaptureSphere from './Components/PC/Themes/CaptureSphere';
import PlantPlates from './Components/PC/Themes/PlantPlates';
import ElectroTech from './Components/PC/Themes/ElectroTech';
import VerdantHarvest from './Components/PC/Themes/VerdantHarvest';
import FruitFiesta from './Components/PC/Themes/FruitFiesta';
import FreshSip from './Components/PC/Themes/FreshSip';
import ShoeStride from './Components/PC/Themes/ShoeStride';
import SoleCrafted from './Components/PC/Themes/SoleCrafted';
import InkwellHaven from './Components/PC/Themes/InkwellHaven';
import TextureFusion from './Components/PC/Themes/TextureFusion';
import ColorFusion from './Components/PC/Themes/ColorFusion';
import CleanFabrica from './Components/PC/Themes/CleanFabrica';
import PerfumePulse from './Components/PC/Themes/PerfumePulse';
import PunctureWizards from './Components/PC/Themes/PunctureWizards';
import CycleHaven from './Components/PC/Themes/CycleHaven';
import SweetTreat from './Components/PC/Themes/SweetTreat';
import RadiantLocks from './Components/PC/Themes/RadiantLocks';
import TrueVision from './Components/PC/Themes/TrueVision';
import TechTrekker from './Components/PC/Themes/TechTrekker';

function PCThemeRoutes(props) {
 
const selectTheme =()=>{

  switch (props.webData.theme){
    case "Enchanting Emerald": 
   return <EnchantingEmerald data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Flamingo Fiesta" :
   return <FlamingoFiesta data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Oceanic Bliss" :
   return <OceanicBliss data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Harmony Haven" :
   return <HarmonyHaven data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Shadow Space" :
   return <ShadowSpace data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Knowledge Korner" :
   return <KnowledgeKorner data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Drive Velocity" :
   return <DriveVelocity data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Ride Revolution" :
   return <RideRevolution data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Fashion Finds" :
   return <FashionFinds data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Journey Junkies" :
   return <JourneyJunkies data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Mobile Marvels" :
   return <MobileMarvels data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Paw Palace" :
   return <PawPalace data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Chef's Kitchen" :
   return <ChefsKitchen data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Feathered Scape" :
   return <FeatheredScape data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Furni Gem" :
   return <FurniGem data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
   break;
   case "Gamers Hub" :
  return <GamersHub data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
   case "Tea Corner" :
  return <TeaCorner data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
   case "Sharp Styles" :
  return <SharpStyles data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
   case "Realty Scape" :
  return <RealtyScape data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
   case "Knowledge Space" :
  return <KnowledgeSpace data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
   case "Bakers Stop" :
  return <BakersStop data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
    break;
    case "Health Hub" :
   return <HealthHub data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
     break;
    case "Chaats Paradice" :
   return <ChaatsParadice data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
     break;
     case "Flow Masters" :
    return <FlowMasters data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
      break;
      case "Capture Sphere" :
     return <CaptureSphere data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
       break;
       case "Plant Plates" :
      return <PlantPlates data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
        break;
        case "Electro Tech" :
       return <ElectroTech data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
         break;

         case "Verdant Harvest" :
          return <VerdantHarvest data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
            break;
            case "Fruit Fiesta" :
           return <FruitFiesta data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
             break;
             case "Fresh Sip" :
            return <FreshSip data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
              break;
              case "Shoe Stride" :
             return <ShoeStride data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
               break;
               case "Sole Crafted" :
              return <SoleCrafted data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                break;
                case "Inkwell Haven" :
               return <InkwellHaven data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                 break;
                 case "Texture Fusion" :
                return <TextureFusion data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                  break;
                  case "Color Fusion" :
                 return <ColorFusion data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                   break;
                   case "Clean Fabrica" :
                  return <CleanFabrica data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                    break;
                    case "Perfume Pulse" :
                   return <PerfumePulse data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                     break;
                     case "Puncture Wizards" :
                return <PunctureWizards data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                  break;
                  case "Cycle Haven" :
                 return <CycleHaven data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                   break;
                   case "Sweet Treat" :
                  return <SweetTreat data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                    break;
                    case "Radiant Locks" :
                   return <RadiantLocks data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                     break;
                     case "True Vision" :
                    return <TrueVision data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                      break;
                      case "Tech Trekker" :
                     return <TechTrekker data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/>
                       break;
    
    default :return <ShadowSpace data={props.webData} offeringsData={props.offeringsData} eventsData={props.eventsData} partnersData={props.partnersData}/> 
  }

} 
  return (
    <Routes>
    
    <Route
         path="/"
         element={selectTheme()}
       />
       <Route exact path="/login" element={<LoginUI/>}/>
       
    </Routes>
  )
}

export default PCThemeRoutes
