import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
 import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/MyOffandComOffNewUI.css';
import Switch from '@mui/material/Switch';

import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Currencies from "../Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import OfferingsUI from '../OfferingsUI';
import CommunityOfferingsUI from '../CommunityOfferingsUI';
import OfferingsNewUI from './OfferingsNewUI';
import CommunityOfferingsNewUI from './CommunityOfferingsNewUI';
import ViewOfferingUI from './ViewOfferingUI';


const MyOffandComOffNewUI = (props) => {   

//UseState
    const [toggle,setToggle] = useState(false);
	const [selectedOfferingsId,setSelectedOfferingsId] = useState("");
    const { user, setUser} = useContext(UserContext);


//UseEffect

  return (
    <div id="offering_page_pc_new">
   <div id="heading_grp_offreqevent_off_PC">
		<div id="req_offreqevent_off_PC" onClick={()=>props.selectedTab("requirements")}>
			<span>Requirements</span>
		</div>
		<div id="selected_grp_offreqevent_off_P">
			<div id="offeringoffreqevent_off_PC">
				<span>Offerings</span>
			</div>
			<svg class="underline_offreqevent_off_PC">
				<rect id="underline_offreqevent_off_PC" rx="0" ry="0" x="0" y="0" width="77" height="3">
				</rect>
			</svg>
		</div>
		<div id="A_transformative_offreqevent_o" onClick={()=>props.selectedTab("events")}>
			<span>Events</span>
		</div>
	</div>
	
	<div id="filters_area_new_events_page_gz">
		<svg class="bdr_new_events_page_pc_g">
			<rect id="bdr_new_events_page_pc_g" rx="5" ry="5" x="0" y="0" width="130" height="30">
			</rect>
		</svg>
		<div id="Find_Event_new_events_page_pc_g">
			<span>Filter</span>
		</div>
		<div id="category_grp_pc_g">
			<select class="category_input_new_events_page_g">
				<option>Product</option><option>Music</option><option>Workshop</option><option>UpSkill and Training</option><option>Others</option>
			</select>
			<div id="Category_new_events_page_pc_g">
				<span>Category</span>
			</div>
		</div>
		<div id="time_cate_gory_pc_g">
			<select class="time_bdr_filter_new_events_pag_g">
				<option>Recent</option><option>Today</option><option>This Week</option><option>This Month</option><option>This Year</option> 
			</select>
			<div id="Time_new_events_page_g">
				<span>Time</span>
			</div>
		</div>
	</div>

	<div id="swtitch_grp_event_g">
		<div id="Myevents_PC_g">
			<span>My Offerings</span>
		</div>
		<div id="Community_eventsPC_ha">
			<span>Community Offerings</span>
		</div>
		<div class="switch_eent_off_PC_hb">
		<Switch id='switch_eent_off_PC_hb' onChange={(e)=>{setToggle(e.target.checked);setSelectedOfferingsId("")}} checked={toggle} color="default" />
		</div>
	</div>
{selectedOfferingsId===""? toggle===false  ?<OfferingsNewUI  offeringSelected={(val)=>setSelectedOfferingsId(val)}/> : <CommunityOfferingsNewUI offeringSelected={(val)=>setSelectedOfferingsId(val)}/>:<ViewOfferingUI selectedOfferingsId={selectedOfferingsId} isOffering={toggle} backOff={()=>setSelectedOfferingsId("")}/>}

    </div>
  )
}

export default MyOffandComOffNewUI
