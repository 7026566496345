import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
 import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/MyReqandComReqMob.css';
import Switch from '@mui/material/Switch';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import CommunityOfferingsUI from '../CommunityOfferingsUI';
import MyRequirementsUI from '../MyRequirementsUI';
import CommunityRequirementsUI from '../CommunityRequirementsUI';
import MyRequirementsMobUI from './MyRequirementsMobUI';
import CommunityRequirementsMobUI from './CommunityRequirementsMobUI';
import ViewRequirementsMobUI from './ViewRequirementsMobUI';


const MyReqandComReqMob = () => {   

//UseState
    const [toggle,setToggle] = useState(false);
    const [selectedRequirementsId,setSelectedRequirementsId] = useState("");
    const { user, setUser} = useContext(UserContext);


//UseEffect

  return (
    <div>
 <div id="my_requirement_page_mob">
 
	{/* <img id="req__bg_img_mob" src="req_bg_img_mob.png" srcset="req_bg_img_mob.png 1x" /> */}
		
	<div id="My_req_name_tag">
		<span>{toggle=== false ? "My Requirements":"Community Requirements"}</span>
	</div>

    <div class="requirement_mob_switch">
	<Switch id='requirement_mob_switch' onChange={(e)=>setToggle(e.target.checked)} checked={toggle} color="default" />
	</div>


</div>
   {selectedRequirementsId===""? toggle===false ? <MyRequirementsMobUI requirementsSelected={(val)=>setSelectedRequirementsId(val)}/> : <CommunityRequirementsMobUI requirementsSelected={(val)=>setSelectedRequirementsId(val)}/>:<ViewRequirementsMobUI selectedRequirementsId={selectedRequirementsId} isRequirement={toggle} backReq={()=>setSelectedRequirementsId("")} />}
    </div>
  )
}

export default MyReqandComReqMob
