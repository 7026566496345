import React, { useEffect, useState, useContext} from 'react'
import '../../styles/ViewRequirementsMobUI.css';
import { UserContext } from '../UserContext';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button, CardActionArea, CardActions } from '@mui/material';
import QuotationPopUpPC from '../QuotationPopUpPC';
import RequirementPopUpPC from '../RequirementPopUpPC';
import BidTimeLineMob from './BidTimeLineMob';

const ViewRequirementsMobUI = (props) => {

    const {user} = useContext(UserContext);
    const [requirements,setRequirements] = useState({})
	const [openGridModel,setOpenGridModel] = useState(false);
	const [biddings,setBiddings] = useState("");
	const [meetingTitle,setMeetingTitle] = useState("");
    const [agenda,setAgenda] = useState("");
    const [meetingRemarks,setMeetingRemarks] = useState("");
    const [ requirementTitle,setRequirementTitle] = useState("");
    const [ vendorName,setVendorName] = useState("");
    const [ vendorId,setVendorId] = useState("");
	const [openMeetingModel,setOpenMeetingModel] = useState(false);
	const [meetingDateTime,setMeetingDateTime] = useState(new Date().toISOString().substring(0,16));
    const [previousRemarks,setPreviousRemarks] = useState("");
	const [requirementId,setRequirementId]=useState("");
    const [title,setTitle]= useState("");
    const [createdById,setCreatedById]=useState("");
    const [createdByName,setCreatedByName]=useState("");
    const [currency,setCurrency] = useState("")
    const [estimatedCurrency,setEstimatedCurrency] = useState("");
	const [selectedQuotation,setSelectedQuotation] = useState({});
	const [quotationOpenModel,setQuotationOpenModel] = useState(false);
	const [requirementPopup,setRequirementPopup] = useState(false);
	const [bidsHistory,setBidsHistory] = useState([]);

    useEffect(()=>{
    if(user){
		if(props.selectedRequirement){
			setRequirements(props.selectedRequirement)
		}else if(props.selectedRequirementsId)   
       {
		//alert(props.selectedRequirementsId)
		getRequirementsbyId(props.selectedRequirementsId)
		getBidsbyRequirementId(props.selectedRequirementsId)
	   }
    }

    },[props.selectedRequirementsId])



const getRequirementsbyId = (id)=>{
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getallrequirementsbyreqid/" +id ;
    let apiParams = {
        method:"GET",
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
    //console.log(repos.data)
         setRequirements(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));

}

  //get Bargains of comunity
  const getBidsbyRequirementId = (id)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/quotation/getquotationbyid/"+user._id; 
    let apiParams = {
      headers:{
		requirementid:id,  
    }}
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then(async(repos)=> {
        if (repos.data) {

			var bidHistoryPromises = await Promise.all(repos.data.map(async (rec) => {
				const result = await getbidshistorybyId(rec._id); // Add await here
				console.log(result);
				return result;
			  }));
	  
			  setBidsHistory(bidHistoryPromises)
			  // Now you can work with the bargainHistoryPromises array
			 // console.log(bidHistoryPromises);
			
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


const getbidshistorybyId = async (id)=>{
if(id){
	let apiURL=process.env.REACT_APP_API_URL + "/quotation/getbidshistoryid/" +id ;
    let apiParams = {
        method:"GET",
		// headers:{
		// 	userid:user._id,
		// }
    };
  return await fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
    //console.log(repos.data)
         //setBargainsHistory(repos.data)
        return repos.data;
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));

}
    
}


const updateStatus = (row,status)=>{

	let apiURL=process.env.REACT_APP_API_URL + "/quotation/updatequotation/"+row.id;
	let apiParams = {
		method:"POST",
		headers:{
			requirementid:row.requirementId,  
			userid:user._id,
			username:user.name,
			status:status,
		},
	   
	};
	fetch(apiURL, apiParams)
	.then((res)=> (res.status===200 ? res.json() : res.text()))
	.then((repos)=> {
		if (repos.data) {
		 
		  alert("Quatation Status Updated Successfully!!");
		  
		}else alert(repos.message || repos);
	}) 
	.catch((err) => alert(err));
	}
  
	const ScheduleMeeting = () =>{
  
		if(meetingTitle ===""||undefined)
		{
		  return alert("Please Enter Meeting Title !!")
		}else if(agenda===""||undefined){
		  return alert("Please Enter Agenda Of Meeting !!")
		}
		setOpenMeetingModel(false)
		let apiURL=process.env.REACT_APP_API_URL + "/meetings/schedulemeetings";
		  let apiParams = {
			  method:"POST",
			  headers:{
				// requirementtitle:requirementTitle,
				// requirementid:requirementId,
				requesterid:user._id,
				requestername:user.name,
				respondentid:vendorId,
				respondentname:vendorName,
				meetingtitle:meetingTitle,
				meetingdatetime:meetingDateTime,
				agenda:encodeURIComponent(agenda),
				remarks:encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+meetingRemarks),
				status:"Requested",
	  
			  },
			 
		  };
		  fetch(apiURL, apiParams)
		  .then((res)=> (res.status===200 ? res.json() : res.text()))
		  .then((repos)=> {
			  if (repos.data) {
			  
				alert("Meeting Scheduled Successfully");
				
			//    setTimeout(() => {
			// 	getRequirements()
			//   }, 1500);
			  
			  }else alert(repos.message || repos);
		  }) 
		  .catch((err) => alert(err));
	  
	   }

const getAllBiddings = (requirementId)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/quotation/getallquotations/"+requirementId; 
  
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
            console.log(repos.data)
          setBiddings(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
  var cols = [
	{field:'id', headerName: 'id', hide: true },
	{field:'requirementId', headerName: 'reqId',hide: true  },
	{field:'accept', headerName: 'Accept',width: 60, renderCell: (params) =>{
	 return[
	   <div>
	 {params.row.status==="Rejected" ? "": <CheckIcon style={{color:params.row && params.row.status==="Accepted"?"green":""}} onClick={()=>{updateStatus(params.row,"Accepted")}}/>}
	  </div>
	  ];},},  
	  {field:'reject', headerName: 'Reject',width: 60, renderCell: (params) =>{
		return[
		 <div>
	   {params.row.status==="Accepted" ? "": <ClearIcon style={{color:params.row && params.row.status==="Rejected"?"red":""}} onClick={()=>{updateStatus(params.row,"Rejected")}}/>}
		</div>
		];},}, 
		{field:'meeting', headerName: 'Meeting',width: 70, renderCell: (params) =>{
		  return[
		   <div>
		  <CalendarMonthIcon  onClick={()=>{openScheduleModel(params.row)}}/>
		  </div>
		  ];},},
	{field:'requirementTitle', headerName: 'Title',width: 200,  },
	{field:'estimatedAmount', headerName: 'Amount' ,width: 100, },      
	{field:'estimatedHours', headerName: 'Hours' ,width: 100, },      
	{field:'canBeDelivered', headerName: 'Can Be Delivered' ,width: 150,},      
	{field:'vendorName', headerName: 'Vendor Name' ,width: 150, }, 
	{field:'remarks', headerName: 'Remarks' ,width: 400, },   
	{field:'vendorId', headerName: 'Vendor ID',hide: true,width: 100,},      
   
];

const openScheduleModel = (row) =>{
	console.log(row)
	// setRequirementId(row.requirementId);
	// setRequirementTitle(row.requirementTitle);
	setMeetingTitle(row.requirementTitle)
	setVendorId(row.vendorid);
	setVendorName(row.vendorName)
	setOpenMeetingModel(true);
  }	

// const rowData = [];      

const rowData = (biddings||[]).map((bid) => {
       
	return{
   
	id: bid._id,
	requirementId:bid.requirementId,
	requirementTitle:bid.requirementTitle,
	estimatedAmount:bid.estimatedAmount,
	estimatedHours:bid.estimatedHours,
	canBeDelivered:bid.canBeDelivered,
	vendorName:bid.vendorName,
	vendorid:bid.vendorId,
	remarks:bid.remarks,
	status:bid.status,
	};
  });
  
const closeMeetingModel = () => setOpenMeetingModel(false);

const showGrid = (id)=>{
    
    getAllBiddings(id);
    setOpenGridModel(true)
 } 

 //Disable Job
 const updateIsActive = (id) =>{
	let confirmation=window.confirm("Are you sure ?")
	if(!confirmation){
	  return
	}
	let apiURL=process.env.REACT_APP_API_URL + "/requirement/updaterequirementsstatus/" +id;
	let apiParams = {
	  method:"POST",
	  headers:{
		active:false
	  },
   };
  
   fetch(apiURL, apiParams)
   .then((res)=> (res.status===200 ? res.json() : res.text()))
   .then((repos)=> {
	   if (repos.data) {
		 alert("Requirement Removed Successfully");
		// props.showAlert("Job Removed Successfully","success")
		// setTimeout(() => {
		//   getRequirements()
		// }, 2000);
	   }else alert(repos.message || repos);
   }) 
   .catch((err) => alert(err));
   
  }

  const addBid=(id,title,createdById,createdByName)=>{
    setSelectedQuotation({id,title,createdById,createdByName});
    setRequirementId(id);
    setTitle(title);
    setCreatedById(createdById);
    setCreatedByName(createdByName);
    // getBids(id)
    setQuotationOpenModel(true);

    }

	const handleLike = (reqId) =>{
	
		let apiURL = process.env.REACT_APP_API_URL + "/requirement/updatetrophy/"+reqId;
		let apiParams = {
		  method: "POST",
		  headers: {
				  id:user._id,
				  name: user.name,
				  nickname:user.nickName,	
				  imageid:user.imageId,
				 
		  },
		};
		fetch(apiURL, apiParams)
		  .then((res) => (res.status === 200 ? res.json() : res.text()))
		  .then((repos) => {
			if (repos.data) {
			
			 // alert("Trophy Updated Successfully!!")
			 getRequirementsbyId(reqId)
			}
		  })
		  .catch((err) => alert(err));
	  };

	  

 const closeGridModel = () => setOpenGridModel(false);

  return (
	<>
	<div id="view_page_req_mob">
	<img id="previous_back_btn_back_page_vi" src="./MyPortfolio/Requirements/previous_back_btn_back_page_vi.png" srcset="./MyPortfolio/Requirements/previous_back_btn_back_page_vi.png 1x" onClick={()=>props.backReq()}/>
		
	<div id="overall_grp_with_detail_and_bt">
		<div id="Scroll_cars_preview__mob">
		{
		requirements.requirementsImagesUrl && JSON.parse(requirements.requirementsImagesUrl).map((rec,idx)=>( 

			<div id="preview_1_view_page_mob" style={{left:idx*30+"px"}}>
				<svg class="img_preview_1_view_page__mob">
					<rect id="img_preview_1_view_page__mob" rx="7" ry="7" x="0" y="0" width="135" height="85">
					</rect>
				</svg>
				<img id="Image_preview_1_view_page_mob" src={rec?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec).split('/')[5] :"./MyPortfolio/Requirements/Image_preview_1_view_page_mob.png"} />
					
			</div>
			))}
		</div>
		<div id="Nike_Air_Force_req_mob_view_page">
			<span>{requirements.requirementTitle}</span>
		</div>
		<img title="Like" id="trophy_view_page_mob" src={requirements.trophies && (JSON.parse(requirements.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(requirements._id)}/>
			
		<div id="n_234_count_view_page_mob">
			<span>{requirements.trophies && (JSON.parse(requirements.trophies)).length}</span>
		</div>
		<svg class="line_before_budget_view_page_m" viewBox="0 0 346 1">
			<path id="line_before_budget_view_page_m" d="M 0 1 L 346 0">
			</path>
		</svg>
		<div id="Budget_Delivery_Deadline_Deliv_mob">
			<span>Budget<br/>Delivery Deadline<br/>Delivery Location</span>
		</div>
		<div id="Afs_10000_mob_details_info_view">
			<span>{requirements.currency && JSON.parse(requirements.currency).symbol} {requirements.budget} ({requirements.currency && JSON.parse(requirements.currency).cc})<br/>{( requirements.deliveryDeadline ? (requirements.deliveryDeadline).substring(0,10):"")}<br/>{requirements.deliveryLocation}</span>
		</div>
		<div id="display_img_mob_with_edit_shar">
			<svg class="img_2_big_view_page_mob_bdr">
				<rect id="img_2_big_view_page_mob_bdr" rx="10" ry="10" x="0" y="0" width="325" height="353">
				</rect>
			</svg>
			<img id="Image_2_big_view_page_mob" src={requirements.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(requirements.defaultRequirementsImageUrl.split('/')[5]):"./MyPortfolio/Requirements/Image_2_big_view_page_mob.png"}  />
				
			<img id="share_btn_view_page_mob" src="./MyPortfolio/Requirements/share_btn_view_page_mob.png" srcset="./MyPortfolio/Requirements/share_btn_view_page_mob.png 1x" />
				
			{props.isRequirement === false ?<img title="Edit" id="edit_req_btn_view_page_mob" src="./MyPortfolio/Requirements/edit_req_btn_view_page_mob.png" srcset="./MyPortfolio/Requirements/edit_req_btn_view_page_mob.png 1x" onClick={()=>setRequirementPopup(true)}/>:""}
				
		</div>
		<div id="Scroll_description__mob">
			<div id="description_of_title_view_page">
				<span>{requirements.description}</span>
			</div>
		</div>
		<svg class="line_below_location_view_page_" viewBox="0 0 346 1">
			<path id="line_below_location_view_page_" d="M 0 0 L 346 0">
			</path>
		</svg>
		{props.isRequirement === false ?
		<button id="Button_bargain_view_page_mob" onClick={() =>{showGrid(requirements._id)}}>
			<svg class="bargain_bdr_view_page_mob">
				<rect id="bargain_bdr_view_page_mob" rx="5" ry="5" x="0" y="0" width="140" height="45">
				</rect>
			</svg>
			<div id="bargain_view_page__mob">
				<span>Bids</span>
			</div>
		</button>:
		<button id="Button_bargain_view_page_mob" onClick={()=>addBid(requirements._id,requirements.requirementTitle,requirements.customerId,requirements.customerName)}>
			<svg class="bargain_bdr_view_page_mob">
				<rect id="bargain_bdr_view_page_mob" rx="5" ry="5" x="0" y="0" width="140" height="45">
				</rect>
			</svg>
			<div id="bargain_view_page__mob">
				<span>Bid</span>
			</div>
		</button>}
		{props.isRequirement === false ?	
		<button id="Button_disable_view_page_mob" onClick={() => updateIsActive(requirements._id)}>
			<svg class="disable_bdr_view_page_mob">
				<rect id="disable_bdr_view_page_mob" rx="5" ry="5" x="0" y="0" width="140" height="45">
				</rect>
			</svg>
			<div id="disable_view_page_mob">
				<span>Disable</span>
			</div>
		</button>:""}
	</div>

	{bidsHistory.length > 0 ?
	<div style={{position:"absolute",top:"973px",height:"220px",width:"100%",overflowX:"hidden",overflowY:"auto"}}>
{bidsHistory.map((rec)=>(
	<BidTimeLineMob bidsHistory={rec}/>
	))
}
</div>:""
}
	

	<div id="trophie_detail_view_page__mob" style={{top:bidsHistory.length > 0?"1210px":"1050px"}}>
	{requirements.trophies && JSON.parse(requirements.trophies).map((rec)=>(
		<div id="card_1_trohpie_view_page_mob_r">
			<img id="img_who_liked_view_page_mob_re" src={rec.imageId?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.imageId):"./MyPortfolio/Requirements/img_who_liked_view_page_mob_re.png"}/>
				
			<div id="Bargained_view_page_mob_req">
				<span>{rec.name}</span>
			</div>
		</div>
		))
	}
		<div id="Trophies_Awarded_By_view_page_">
			<span>Trophies Awarded By</span>
		</div>
	</div>
</div>

<RequirementPopUpPC openReqPopUp={requirementPopup} data={requirements} closeReqPopUp={(id)=>{setRequirementPopup(false);if(id)getRequirementsbyId(id)}}/>

  {/* Pop up for schedule meeting */}
  <Modal
         open={openMeetingModel}
        onClose={closeMeetingModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
      <div id="pop_up_meeting_room">
	<img id="meetingRoom_popbg" src="meetingroompopupbg.png" srcset="meetingroompopupbg.png 1x, meetingroompopupbg@2x.png 2x" />
		
	<div id="Group_border_meetingPop">
		<svg class="border_meetpop_">
			<rect id="border_meetpop_" rx="45.78704833984375" ry="45.78704833984375" x="0" y="0" width="91.574" height="371" >
			</rect>
		</svg>
		<div id="Meeting_Request_lbl_mp">
			<span>Meeting Request</span>
		</div>
	</div>
	<input placeholder="Agenda" multiline class="txtprevious_remarks_val"   rows={3}  sx={{ m: 1, width: '35ch' }} size="small" label="Agenda" variant="outlined"  value={agenda} onChange={(e)=> {
       setAgenda(e.target.value);}} />
	
	<input placeholder="Meeting Title" class="txtMeeting_Room_pop_val"  value={meetingTitle} onChange={(e)=> {
       setMeetingTitle(e.target.value); }}  />
	
	<input type="datetime-local" id="MeetingTime" format="dd-mm-yyyy" class="txtDate_pop_val"  value={meetingDateTime} onChange={(e)=>setMeetingDateTime(e.target.value)}/>
		
	<div id="Meeting_On_lbl_mp">
		<span>Meeting On :</span>
	</div>

	<textarea placeholder="Previous Remarks" class="txtagenda_pop_val"   value={previousRemarks}/>
		
	<input placeholder="Remark" class="txtremarks_val_mp"  value={meetingRemarks} onChange={(e)=> {
          setMeetingRemarks(e.target.value);  }}/>
		
    

 <button id="Group_btn_propose_pr" style={{left: "140px"}} onClick={()=>ScheduleMeeting()}>
		<svg class="border_pro_btn_pr">
			<rect id="border_pro_btn_pr" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Propose_btn_pro__lbl">
			<span>Send</span>
		</div>
	</button>

  
	<img id="closeiconmeetingpop" src="closeiconmeetingpop.png" srcset="closeiconmeetingpop.png 1x, closeiconmeetingpop@2x.png 2x" onClick={()=>closeMeetingModel()}/>
</div>
           </Modal>  

		   <QuotationPopUpPC  openQuoPopUp={quotationOpenModel}  selectedQuotation={selectedQuotation} closeQuoPopUp={()=>{setQuotationOpenModel(false);}}/>

  {/* Bids data in data grid for requirement which was added */}
<Modal
        open={openGridModel}
        onClose={closeGridModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
       
         <Typography id="modal-modal-description">
         <Container style={{padding:"0px"}}>
         <div className = "BidsModalDesign">
       <div style={{alignItems:"center"}}>
         <h2 className="titleBids"><b>Quotations Recieved</b></h2>
         <Button title="Close" variant="Standard" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,color:"red" }} onClick={()=>closeGridModel()} ><CloseIcon /></Button>   
         </div>
   
       <div style={{height:750,width: '100%',margin:"0px"}}>
       <DataGrid  rows={rowData} columns={cols} components={{
          Toolbar: GridToolbar,
        }}
          />
           </div>
     
       </div>
      </Container> 
          </Typography>
         
      </Modal>

	  </>
  )
}

export default ViewRequirementsMobUI
