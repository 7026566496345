import React, { useEffect, useState, useContext} from 'react'
import "../../styles/MyCommunitiesNewUI.css";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import CreateCommunityPopUpUI from '../CreateCommunityPopUpUI';
import CommunitiesConnectedMembersNewUI from './CommunitiesConnectedMembersNewUI';
import CommunitiesOfferingPageNewUI from './CommunitiesOfferingsPageNewUI';
import CommunitiesRequirementsPageNewUI from './CommunitiesRequirementsPageNewUI';
import FooterPcUI from './FooterPcUI';


const MyCommunitiesNewUI = () => {

 //useState
    var navigate = useNavigate();
    const { user, setUser} = useContext(UserContext);
    const [openCommunityModal,setOpenCommunityModal] = useState(false);
    const [communityName,setCommunityName] = useState("");
    const [selectedCommunityId,setSelectedCommunityId] = useState("");
    const [selectedCommunityName,setSelectedCommunityName] = useState("");
    const [communityDescription,setCommunityDescription] = useState("");
    const [communities,setCommunities] = useState("");
    const [img,setImg] = useState("user_pfp_profile_page.png");
    const [profile,setProfile]= useState("");
    const [mode,setMode] = useState("create");
    const [selectedTab,setSelectedTab] = useState("requirements");



    //UseEffect
    useEffect(()=>{
        if(user)
        getCommunity()
    },[user])

    // useEffect(()=>{
        
    // },[])
    //To Upload Picture
    const handleImageUpload = async (e) => {
        // if (e.target.files[0].size > 71680) {
        //   alert("Please choose a file with size below 70kb!");
        //   return;
        // }
        handleFileSelect(e.target.files[0]);
        let formdata = new FormData();
        formdata.append("image", e.target.files[0], e.target.value);
        setProfile(formdata);
      };

      //Select file to upload
  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const createCommunity = () => {
    setCloseButton()
    const apiurl = process.env.REACT_APP_API_URL + "/community/createcommunity";
    var apiparam = {
      method: "POST",
      headers: {
        communityname:communityName,
        communitydiscription:encodeURIComponent(communityDescription),
        createdbyid:user._id,
        createdbyname:user.name,
        
      },
      body:profile,
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Community Created Successfully!!");
          getCommunity()
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };


const closeCommunity =()=>{
    setOpenCommunityModal(false)
}
const loadCommunity= (communityId)=>{

}


const setCloseButton = ()=>{
    setOpenCommunityModal(false)
    setCommunityDescription("");
    setCommunityName("");
    setProfile("");
    setImg("");
}

//get comunity
const getCommunity = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/community/getcommunity/"+user._id; 

    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          repos.data[0] && setSelectedCommunityId(repos.data[0]._id)
          repos.data[0] && setSelectedCommunityName(repos.data[0].communityName)

          setCommunities(repos.data);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   //Converting Image
function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  var base64Flag = "data:image/jpeg;base64,";
  return base64Flag + window.btoa(binary);
}


const openCreatePopUp = ()=>{
  setMode("create")
  setOpenCommunityModal(true)
}



  return (
  <div>
	<div id="scroll_and_plus_btn_req_com">
 
		<div id="Scroll_req_page" >
    <div style={{width:"100%",display:"flex"}}>
    {(communities||[]).map((com,idx)=>(
      <div style={{left:(idx*5).toString()+"px",position:"relative",display:"inline-flex"}}>
			<button title={"Description: "+com.communityDiscription} id="com_top_com_req" className={com._id===selectedCommunityId ?"com_top_com_req_glow backInLeft":"backInLeft"}  onClick={()=>{setSelectedCommunityId(com._id);setSelectedCommunityName(com.communityName)}}>
				<svg class="bdr_community_req_pge_" >
					{/* <linearGradient id="bdr_community_req_pge_" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1" >
						<stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
						<stop offset="1" stop-color="#65ff7f"  stop-opacity="1"></stop>
					</linearGradient> */}
					<rect id="bdr_community_req_pge_" rx="29" ry="29" x="0" y="0" width="231" height="58">
					</rect>
				</svg>
				<img id="photo_community_req_page" src={com.profilePic  ?arrayBufferToBase64(com.profilePic.data.data):"coding.png"}  />
      
				<div id="Mohammed_Zaid_lbl_req_page" style={{color:com._id===selectedCommunityId ?"white":""}}>
					<span>{com.communityName}</span>
				</div>
			</button>
      </div>
      ))}
		</div>
    </div>
		<div title="Add Community" id="com_btn_top_right_req" onClick={()=>openCreatePopUp()}>
			<svg class="plus_btn_bdr">
				<rect id="plus_btn_bdr" rx="2" ry="2" x="0" y="0" width="26" height="58">
				</rect>
			</svg>
			<img id="plus_img_btn" src="plus_img_btn.png" srcset="plus_img_btn.png 1x"  />
				
		</div>
	</div>
 <div>
 {
   selectedTab=== "members" ?<CommunitiesConnectedMembersNewUI communityId={selectedCommunityId} communityName={selectedCommunityName} selectedTab={(st)=>setSelectedTab(st)}/> : 
    selectedTab === "offerings" ?<> <CommunitiesOfferingPageNewUI communityId={selectedCommunityId} communityName={selectedCommunityName} selectedTab={(st)=>setSelectedTab(st)}/></> :
   selectedTab === "requirements" ?<> <CommunitiesRequirementsPageNewUI communityId={selectedCommunityId}  communityName={selectedCommunityName} selectedTab={(st)=>setSelectedTab(st)}/></> :  
  "" 
  } 
  </div>
  <FooterPcUI />
  <CreateCommunityPopUpUI  openComModal={openCommunityModal} mode={mode} closeComModal={()=>{setOpenCommunityModal(false);getCommunity()}}/>
</div>
 
  )
}

export default MyCommunitiesNewUI

