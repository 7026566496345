import React,{useState,useEffect,useContext} from 'react';
import { UserContext } from '../UserContext';
import '../../styles/RequirementProfileFeedMobUI.css'

const ProfileRequirementsMobUI = (props) => {

  const { user, setUser} = useContext(UserContext);
  const [requirements,setRequirements] = useState([]);

useEffect(()=>{
if(user){
  //getRequirements()
  props.requirements && setRequirements(props.requirements)
}
},[user,props.requirements])

  const getRequirements = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getallrequirements";
    let apiParams = {
        method:"GET",
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        
        setRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
  return (
    <div>
  <div id="req_feed_mob">
	<div id="req_feed_mob_ffeed_grp">
		<svg class="req_feed_mobfeed_bdr">
			<rect id="req_feed_mobfeed_bdr" rx="15" ry="15" x="0" y="0" width="100%" height="608">
			</rect>
		</svg>
		<div id="req_feed_mob_Feed_lbl">
			<span>Requirements</span>
		</div>
		<div id="req_feed_mob_feed_scroll">
    {requirements.map((rec,idx)=>(
			<div id="card_1_req_feed_mob">
				<img id="card_1_req_feed_mob_bf" src={rec.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultRequirementsImageUrl.split('/')[5]):"profilebackground.png"} />
					
				<div id="req_lbl__req_feed_mob">
					<span>{rec.requirementTitle}</span>
				</div>
				<div id="Mohammed_Affan_lbl_req_feed_mo">
					<span>{rec.customerName}</span>
				</div>
			</div>
      ))
    }
		</div>
	</div>
</div>

    </div>
  )
}

export default ProfileRequirementsMobUI
