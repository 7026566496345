import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../styles/RequirementsUI.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { clear } from '@testing-library/user-event/dist/clear';
import Currencies from "./Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import  RequirementPopUpPC from './RequirementPopUpPC';
import RequirementsViewMorePopUp from './RequirementsViewMorePopUp';

const style4 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 383,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const style5 = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 325,
    maxWidth: 385,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
  
  };

  const style7 = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 325,
    maxWidth: 385,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
  
  };

  const BidsModalStyle = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 600,
    maxWidth: 1200,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
  
  }; 
const MyRequirementsUI = (props) => {

  //usestate
  var navigate = useNavigate();
    const [name, setName] = useState("");
    const [open, setOpen] = React.useState(false);
    const [openApplicant, setOpenApplicant] = React.useState(false);
    const [openModel,setOpenModel] = useState(false);
    const [openGridModel,setOpenGridModel] = useState(false);

    const [jobId, setJobId] = React.useState();
    const [jobTitle, setJobTitle] = React.useState("");
    const [biddings,setBiddings] = useState("");
    const [deliveryDeadline, setDeliveryDeadline] = useState(new Date().toISOString());
    const [deliveryLocation, setDeliveryLocation] = useState();
    const [description, setDescription] = useState("");
    const [services, setServices] = useState("");
    const [budget,setBudget] = useState(0);
    const [remarks,setRemarks] = useState("");
    const [canBeDelivered,setCanBeDelivered]= useState(false);
    const [companyName, setCompanyName] = useState("");
    const [hours, setHours]= useState("hours");
    const [estimatedTime,setEstimatedTime] = useState("");
    const [estimatedAmount,setEstimatedAmount] = useState("");
    const [searchParams] = useSearchParams();
    const [requirements,setRequirements]=useState([]);
    const [requirementId,setRequirementId]=useState("");
    const [title,setTitle]= useState("");
    const [createdById,setCreatedById]=useState("");
    const [createdByName,setCreatedByName]=useState("");
    const [currency,setCurrency] = useState("")
    const [estimatedCurrency,setEstimatedCurrency] = useState("");
    const { user, setUser} = useContext(UserContext);
    const [status,setStatus] = useState([]);
    const [openMeetingModel,setOpenMeetingModel] = useState(false);
    const [meetingTitle,setMeetingTitle] = useState("");
    const [agenda,setAgenda] = useState("");
    const [meetingRemarks,setMeetingRemarks] = useState("");
    const [ requirementTitle,setRequirementTitle] = useState("");
    const [ vendorName,setVendorName] = useState("");
    const [ vendorId,setVendorId] = useState("");
    const [meetingDateTime,setMeetingDateTime] = useState(new Date().toISOString().substring(0,16));
    const [previousRemarks,setPreviousRemarks] = useState("");
    const [bids,setBids]= useState([]);
    const [quotationId,setQuotationId] = useState("");
    const [requirementStatus,setRequirementStatus] = useState("");
    const [ communities,setCommunities] = useState([]);
    const [selectedCommunity,setSelectedCommunity] = useState([]);
    const [selectedCommunities,setSelectedCommunities] = useState([]);
    const [requirementPopup,setRequirementPopup] = useState(false)
    const [viewMorePopup,setViewMorePopup] = useState(false);
    const [viewMoreData,setViewMoreData] = useState("");

    //UseEffect
      useEffect(()=>{
        cleartext()
    },[openApplicant])
      
    useEffect(()=>{
        clearBox()
    },[openModel])

    useEffect(()=>{
      if(user)
          getStatus()
      },[user])
  
      useEffect(()=>{
        if(user){
            getRequirements()
           
        }
        },[user])
    

      
    var cols = [
        {field:'id', headerName: 'id', hide: true },
        {field:'requirementId', headerName: 'reqId',hide: true  },
        {field:'accept', headerName: 'Accept',width: 60, renderCell: (params) =>{
         return[
           <div>
         {params.row.status==="Rejected" ? "": <CheckIcon style={{color:params.row && params.row.status==="Accepted"?"green":""}} onClick={()=>{updateStatus(params.row,"Accepted")}}/>}
          </div>
          ];},},  
          {field:'reject', headerName: 'Reject',width: 60, renderCell: (params) =>{
            return[
             <div>
           {params.row.status==="Accepted" ? "": <ClearIcon style={{color:params.row && params.row.status==="Rejected"?"red":""}} onClick={()=>{updateStatus(params.row,"Rejected")}}/>}
            </div>
            ];},}, 
            {field:'meeting', headerName: 'Meeting',width: 70, renderCell: (params) =>{
              return[
               <div>
              <CalendarMonthIcon  onClick={()=>{openScheduleModel(params.row)}}/>
              </div>
              ];},},
        {field:'requirementTitle', headerName: 'Title',width: 200,  },
        {field:'estimatedAmount', headerName: 'Amount' ,width: 100, },      
        {field:'estimatedHours', headerName: 'Hours' ,width: 100, },      
        {field:'canBeDelivered', headerName: 'Can Be Delivered' ,width: 150,},      
        {field:'vendorName', headerName: 'Vendor Name' ,width: 150, }, 
        {field:'remarks', headerName: 'Remarks' ,width: 400, },   
        {field:'vendorId', headerName: 'Vendor ID',hide: true,width: 100,},      
       
    ];
    

  const addBid=(id,title,createdById,createdByName)=>{
    setRequirementId(id);
    setTitle(title);
    setCreatedById(createdById);
    setCreatedByName(createdByName);
    getBids(id)
    setOpenModel(true);

    }



 const showGrid = (id)=>{
    
    getAllBiddings(id);
    setOpenGridModel(true)
 }   
   
 const handleClose = () => setOpenApplicant(false)

 const closeModel = () => setOpenModel(false);

 const closeMeetingModel = () => setOpenMeetingModel(false);

 const closeGridModel = () => setOpenGridModel(false);


const openScheduleModel = (row) =>{
  console.log(row)
  // setRequirementId(row.requirementId);
  // setRequirementTitle(row.requirementTitle);
  setMeetingTitle(row.requirementTitle)
  setVendorId(row.vendorid);
  setVendorName(row.vendorName)
  setOpenMeetingModel(true);
}

// //Get requirement by id
  const getRequirements = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getallrequirementsbyid/"+ user._id ;
    let apiParams = {
        method:"GET",
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
          console.log(repos.data)
        setRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  const updateStatus = (row,status)=>{

  let apiURL=process.env.REACT_APP_API_URL + "/quotation/updatequotation/"+row.id;
  let apiParams = {
      method:"POST",
      headers:{
      requirementid:row.requirementId,  
      status:status,

      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
       
        alert("Quatation Status Updated Successfully!!");
        
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
  }


const getStatus = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
         
          setStatus(repos.data)
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};
 
  const cleartext = ()=>{
    setServices("");
    setDescription("");
    setDeliveryLocation("");
    setDeliveryDeadline("");
    setBudget("");
    setCurrency("");
  }

  const clearBox = ()=>{
  
    setEstimatedAmount("")
    setEstimatedTime("")
    setHours("hours")
    setCanBeDelivered(false)
    setRemarks("")
  }

//Add Jobs
const addRequirement = () =>{
  setOpenApplicant(true)
  getCommunities()
}

const addRequirements = ()=>{
    setOpenApplicant(false)
  let apiURL=process.env.REACT_APP_API_URL + "/requirement/addrequirements";
  let apiParams = {
      method:"POST",
      headers:{
        requirementtitle:services,
        budget:budget,
        currency:encodeURIComponent(JSON.stringify(currency)),
        deliverylocation:encodeURIComponent(deliveryLocation),
        description:encodeURIComponent(description),
        deliverydeadline:deliveryDeadline,
        selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        customerid:user._id,
        customername:user.name,
        status:"Created"

      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        cleartext()
        alert("Requirement Added Successfully");
        
    //    props.showAlert("Job Added Successfully","success")
       setTimeout(() => {
        getRequirements()
      }, 1500);
      
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
}

const addQuotation = ()=>{
   
    setOpenModel(false)
    let EstimatedTime=0;
    if(hours==="hours"){
        EstimatedTime = estimatedTime;
    }else{
        EstimatedTime = estimatedTime*8;
    }
    let apiURL=process.env.REACT_APP_API_URL + "/quotation/addquotation";
    //console.log(JSON.stringify(Currencies.filter(curr=>curr.cc===estimatedCurrency)[0] ))
    let apiParams = {
        method:"POST",
        headers:{
          requirementtitle:title,
          requirementid:requirementId,
          customerid:createdById,
          customername:createdByName,
          estimatedamount:estimatedAmount,
          estimatedcurrency:encodeURIComponent(JSON.stringify(Currencies.filter(curr=>curr.cc===estimatedCurrency)[0] )),
          estimatedhours:EstimatedTime,
          canbedelivered:canBeDelivered,
          remarks:encodeURIComponent(remarks),
          vendorid:user._id,
          vendorname:user.name,
          status:"Submitted"
        },
       
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          cleartext()
          alert("Quatations Added Successfully");
          
         setTimeout(() => {
          getRequirements()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
}

const updateQuotation = (id)=>{
  setOpenModel(false)
  let EstimatedTime=0;
  if(hours==="hours"){
      EstimatedTime = estimatedTime;
  }else{
      EstimatedTime = estimatedTime*8;
  }
  let apiURL=process.env.REACT_APP_API_URL + "/quotation/updatequotationdata/"+ id;
  let apiParams = {
      method:"POST",
      headers:{
        estimatedamount:estimatedAmount,
        estimatedcurrency:encodeURIComponent(JSON.stringify(Currencies.filter(curr=>curr.cc===estimatedCurrency)[0])),
        estimatedhours:EstimatedTime,
        canbedelivered:canBeDelivered,
        remarks:encodeURIComponent(remarks),
        status:"Submitted"
      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
      
        alert("Quatations Updated Successfully");
        
      //  setTimeout(() => {
      //   getRequirements()
      // }, 1500);
      
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
}

const cancelQuotation = (id,status) =>{
 
  let apiURL=process.env.REACT_APP_API_URL + "/quotation/updatequotation/"+id;
  let apiParams = {
      method:"POST",
      headers:{
      status:status,
      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
       
        alert("Quatation Status Updated Successfully!!");
        
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
  
}

//Disable Job
 const updateIsActive = (id) =>{
  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
  let apiURL=process.env.REACT_APP_API_URL + "/requirement/updaterequirementsstatus/" +id;
  let apiParams = {
    method:"POST",
    headers:{
      active:false
    },
 };

 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
       alert("Requirement Removed Successfully");
      // props.showAlert("Job Removed Successfully","success")
      setTimeout(() => {
        getRequirements()
      }, 2000);
     }else alert(repos.message || repos);
 }) 
 .catch((err) => alert(err));
 
}
    
  const setClose = ()=>{
    setOpenApplicant(false);
}

const setCloseModel = ()=>{
    setOpenModel(false);
}

const setCloseMeetingModel = ()=>{
  setOpenMeetingModel(false);
}


const setCloseGridModel = ()=>{
    setOpenGridModel(false);
}

const getAllBiddings = (requirementId)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/quotation/getallquotations/"+requirementId; 
  
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
            console.log(repos.data)
          setBiddings(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  //Get my Communities
  const getCommunities = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/community/getmycommunity/"+user._id; 
  
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        setCommunities(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


//get bid of comunity
  const getBids = (id)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/quotation/getbiddings/"+user._id; 
    let apiParams = {
      headers:{
        requirementid:id,
    }}
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
            console.log(repos.data)
           setQuotationId(repos.data[0] && repos.data[0]._id)
          setEstimatedAmount(repos.data[0] && repos.data[0].estimatedAmount);
         setEstimatedCurrency(repos.data[0] && repos.data[0].estimatedCurrency ? JSON.parse(repos.data[0].estimatedCurrency).cc:"INR");
         setEstimatedTime(repos.data[0] && repos.data[0].estimatedHours);
         setCanBeDelivered(repos.data[0] && repos.data[0].canBeDelivered);
         setRemarks(repos.data[0] && repos.data[0].remarks);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }



 const openPopUp = ()=>{
  setRequirementPopup(true)
}


const openViewMorePopUp = (rec)=>{
  setViewMoreData(rec)
  setViewMorePopup(true)
}

const ScheduleMeeting = () =>{
  
  if(meetingTitle ===""||undefined)
  {
    return alert("Please Enter Meeting Title !!")
  }else if(agenda===""||undefined){
    return alert("Please Enter Agenda Of Meeting !!")
  }
  setOpenMeetingModel(false)
  let apiURL=process.env.REACT_APP_API_URL + "/meetings/schedulemeetings";
    let apiParams = {
        method:"POST",
        headers:{
          // requirementtitle:requirementTitle,
          // requirementid:requirementId,
          requesterid:user._id,
          requestername:user.name,
          respondentid:vendorId,
          respondentname:vendorName,
          meetingtitle:meetingTitle,
          meetingdatetime:meetingDateTime,
          agenda:encodeURIComponent(agenda),
          remarks:encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+meetingRemarks),
          status:"Requested",

        },
       
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        
          alert("Meeting Scheduled Successfully");
          
         setTimeout(() => {
          getRequirements()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));

 }


// const rowData = [];      

const rowData = (biddings||[]).map((bid) => {
       
  return{
 
  id: bid._id,
  requirementId:bid.requirementId,
  requirementTitle:bid.requirementTitle,
  estimatedAmount:bid.estimatedAmount,
  estimatedHours:bid.estimatedHours,
  canBeDelivered:bid.canBeDelivered,
  vendorName:bid.vendorName,
  vendorid:bid.vendorId,
  remarks:bid.remarks,
  status:bid.status,
  };
});



  return (
   <div style={{height:"100%",overflowY:"scroll"}}>
    {requirements.filter(req=>req.customerId === user._id ).map((req)=>(
      <div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>

  <div id='My_Requirements_pc_r' className='fadeInLeft'>
	<svg class="card_border_requirementPC">
		<rect id="card_border_requirementPC" rx="0" ry="0" x="0" y="0" width="391" height="231">
		</rect>
	</svg>
  
	<svg class="name_border_requirementPC">
		<rect id="name_border_requirementPC" rx="0" ry="0" x="0" y="0" width="280" height="30">
		</rect>
	</svg>

	<div id="Website_Development_lbl_reqPC">
		<span>{req.requirementTitle}</span>
	</div>
	<div id="Budget_req_pc">
		<span>Budget</span>
	</div>
	<div id="Delivery_Duration_lbl_reqPc">
		<span>Delivery Deadline</span>
	</div>
	<div id="Delivery_Location_lbl_reqPc">
		<span>Delivery Location</span>
	</div>
	<div id="budget_amount_req">
		<span> {req.currency && JSON.parse(req.currency).symbol} {req.budget} ({req.currency && JSON.parse(req.currency).cc})</span>
	</div>
	<div id="duration_req_pc">
		<span> {( req.deliveryDeadline ? (req.deliveryDeadline).substring(0,10):"")}</span>
	</div>
	<div id="Online_Deployment_value_req_pc">
		<span>{req.deliveryLocation}</span>
	</div>
	<div id="Group_10_btn_reqpc" onClick={() =>{openViewMorePopUp(req)}}>
		<svg class="border_btn_reqpc">
			<rect id="border_btn_reqpc" rx="14.5" ry="14.5" x="0" y="0" width="90" height="29">
			</rect>
		</svg>
		<div id="View_more_pc_req_lbl">
			<span>View more</span>
		</div>
	</div>
	<div id="Group_8_btn_reqpc" onClick={() =>{showGrid(req._id)}}>
		<svg class="border_btn_reqpc">
			<rect id="border_btn_reqpc" rx="14.5" ry="14.5" x="0" y="0" width="90" height="29">
			</rect>
		</svg>
		<div id="Bids_lbl_req_pc">
			<span>Bids</span>
		</div>
	</div>
	<img id="coding_img_reqprofile" src={req.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(req.defaultRequirementsImageUrl.split('/')[5]):"profilebackground.png"} />
		
	<div id="Group_41_btn_reqpc" onClick={() => updateIsActive(req._id)}>
		<svg class="border_btn_reqpc">
			<rect id="border_btn_reqpc" rx="14.5" ry="14.5" x="0" y="0" width="90" height="29">
			</rect>
		</svg>
		<div id="Disable_lbl_req_pc">
			<span>Disable</span>
		</div>
	</div>
</div>
</div>
  ))}
  <div id="Group_44_border_addReq" onClick={()=>openPopUp()}>
		<div id="Add_Requirements_lbl_btn_req">
			<span>Add Requirements</span>
		</div>
		<img id="add_req_lbl_btn_" src="add.png" srcset="add.png 1x, add@2x.png 2x" />
	</div>

  <RequirementPopUpPC  openReqPopUp={requirementPopup} closeReqPopUp={()=>{setRequirementPopup(false);getRequirements()}} />

  
{/* Bids data in data grid for requirement which was added */}
<Modal
        open={openGridModel}
        onClose={closeGridModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
          <Box sx={BidsModalStyle}>
         <Typography id="modal-modal-description">
         <Container style={{padding:"0px"}}>
         <div className = "BidsModalDesign">
       <div style={{alignItems:"center"}}>
         <h2 className="titleBids"><b>Quotations Recieved</b></h2>
         <Button title="Close" variant="Standard" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,color:"red" }} onClick={()=>setCloseGridModel()} ><CloseIcon /></Button>   
         </div>
   
       <div style={{height:750,width: '100%',margin:"0px"}}>
       <DataGrid  rows={rowData} columns={cols} components={{
          Toolbar: GridToolbar,
        }}
          />
           </div>
     
       </div>
      </Container> 
          </Typography>
          </Box>
      </Modal>

   <RequirementsViewMorePopUp type="R" openViewMore={viewMorePopup} viewMoreData={viewMoreData} closeViewMore={()=>setViewMorePopup(false)}/>   

   {/* Pop up for schedule meeting */}
 <Modal
         open={openMeetingModel}
        onClose={closeMeetingModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
      <div id="pop_up_meeting_room">
	<img id="meetingRoom_popbg" src="meetingroompopupbg.png" srcset="meetingroompopupbg.png 1x, meetingroompopupbg@2x.png 2x" />
		
	<div id="Group_border_meetingPop">
		<svg class="border_meetpop_">
			<rect id="border_meetpop_" rx="45.78704833984375" ry="45.78704833984375" x="0" y="0" width="91.574" height="371" >
			</rect>
		</svg>
		<div id="Meeting_Request_lbl_mp">
			<span>Meeting Request</span>
		</div>
	</div>
	<input placeholder="Agenda" multiline class="txtprevious_remarks_val"   rows={3}  sx={{ m: 1, width: '35ch' }} size="small" label="Agenda" variant="outlined"  value={agenda} onChange={(e)=> {
       setAgenda(e.target.value);}} />
	
	<input placeholder="Meeting Title" class="txtMeeting_Room_pop_val"  value={meetingTitle} onChange={(e)=> {
       setMeetingTitle(e.target.value); }}  />
	
	<input type="datetime-local" id="MeetingTime" format="dd-mm-yyyy" class="txtDate_pop_val"  value={meetingDateTime} onChange={(e)=>setMeetingDateTime(e.target.value)}/>
		
	<div id="Meeting_On_lbl_mp">
		<span>Meeting On :</span>
	</div>

	<textarea placeholder="Previous Remarks" class="txtagenda_pop_val"   value={previousRemarks}/>
		
	<input placeholder="Remark" class="txtremarks_val_mp"  value={meetingRemarks} onChange={(e)=> {
          setMeetingRemarks(e.target.value);  }}/>
		
    

 <button id="Group_btn_propose_pr" style={{left: "140px"}} onClick={()=>ScheduleMeeting()}>
		<svg class="border_pro_btn_pr">
			<rect id="border_pro_btn_pr" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Propose_btn_pro__lbl">
			<span>Send</span>
		</div>
	</button>

  
	<img id="closeiconmeetingpop" src="closeiconmeetingpop.png" srcset="closeiconmeetingpop.png 1x, closeiconmeetingpop@2x.png 2x" onClick={()=>closeMeetingModel()}/>
</div>
           </Modal>  

   </div>
  );
}

export default MyRequirementsUI
