import React, { useState, useEffect, useContext } from "react";
import "../../styles/BroadcastsUI.css";
import Modal from "@mui/material/Modal";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";

const BroadcastsUI = () => {
    const [searchParams] = useSearchParams();
    const { user, setUser } = useContext(UserContext);
    var navigate = useNavigate();

  return (
    <div>
      
      <div id="broadcast_pc">
	<div id="add_bdr_event_page_pc">
		<svg class="bdr_ad_area_events_event_page_" viewBox="0 0 1137 116">
			<path id="bdr_ad_area_events_event_page_" d="M 15 0 L 1122 0 C 1130.284301757812 0 1137 4.451568603515625 1137 9.942856788635254 L 1137 106.0571441650391 C 1137 111.5484390258789 1130.284301757812 116 1122 116 L 15 116 C 6.715728759765625 116 0 111.5484390258789 0 106.0571441650391 L 0 9.942856788635254 C 0 4.451568603515625 6.715728759765625 0 15 0 Z">
			</path>
		</svg>
		<div id="AD_AREA_event_page_pc">
			<span>AD AREA</span>
		</div>
	</div>
	<div id="find_the_ulti_lblevent_page_pc">
		<span>Find the ultimate event experience here!</span>
	</div>
	<div id="recommeneded_events_lbl_event_">
		<span>Recommended Events</span>
	</div>
	<svg class="card_1_recomm_lbl_bdr_event_pa">
		<rect id="card_1_recomm_lbl_bdr_event_pa" rx="7" ry="7" x="0" y="0" width="172" height="53">
		</rect>
	</svg>
	<div id="card_1_recommened_lbl_event_pa">
		<span>BiziBees PVT LTD</span>
	</div>
	<img id="card_1_recommened_bdr_event_pa" src="./Broadcasts/card_1_recommened_bdr_event_pa.png" srcset="./Broadcasts/card_1_recommened_bdr_event_pa.png 1x" />
		
	<img id="recommended_events_card_1_img_" src="./Broadcasts/recommended_events_card_1_img_.png" srcset="./Broadcasts/recommended_events_card_1_img_.png 1x" />
		
	<div id="top_event_category_lbl_categor">
		<span>Top Event Categories</span>
	</div>
	<svg class="product_zone_bdr_category_even_bd">
		<linearGradient id="product_zone_bdr_category_even_bd" spreadMethod="pad" x1="0.5" x2="1" y1="0" y2="1.09">
			<stop offset="0" stop-color="#ff7171" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#7e3980" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="product_zone_bdr_category_even_bd" rx="15" ry="15" x="0" y="0" width="200" height="245">
		</rect>
	</svg>
	<svg class="music_zone_bdr_category_event_">
		<rect id="music_zone_bdr_category_event_" rx="15" ry="15" x="0" y="0" width="200" height="245">
		</rect>
	</svg>
	<svg class="workshop_bdr_category_event_pa_bg">
		<linearGradient id="workshop_bdr_category_event_pa_bg" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
			<stop offset="0" stop-color="#38d5d0" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#b3d8d6" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#1c6b68" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="workshop_bdr_category_event_pa_bg" rx="15" ry="15" x="0" y="0" width="200" height="245">
		</rect>
	</svg>
	<svg class="upskill_bdr_category_event_pag_bi">
		<linearGradient id="upskill_bdr_category_event_pag_bi" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
			<stop offset="0" stop-color="#b3e140" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#e8ffac" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="upskill_bdr_category_event_pag_bi" rx="15" ry="15" x="0" y="0" width="200" height="245">
		</rect>
	</svg>
	<svg class="other_bdr_category_event_page_">
		<rect id="other_bdr_category_event_page_" rx="15" ry="15" x="0" y="0" width="200" height="245">
		</rect>
	</svg>
	<div id="product_zone_lbl_category_even">
		<span>Entertainment<br/>Zone</span>
	</div>
	<div id="n_0events_lbl_category_event_p">
		<span>10+ events</span>
	</div>
	<div id="music_zone_lbl_category_event_">
		<span>Food<br/>Zone</span>
	</div>
	<div id="n_0events_lbl_category_event_p_bn">
		<span>10+ events</span>
	</div>
	<div id="workshop_zone_lbl_category_eve">
		<span>Education<br/>Zone</span>
	</div>
	<div id="n_0events_lbl_category_event_p_bp">
		<span>10+ events</span>
	</div>
	<div id="upskill_lbl_category_event_pag">
		<span>Competitions<br/>Zone</span>
	</div>
	<div id="n_0_events_upskill_category_ev">
		<span>10+ events</span>
	</div>
	<div id="other_lbl_category_event_page_">
		<span>Others</span>
	</div>
	<div id="n_0_event_lbl_other_category_e">
		<span>10+ events</span>
	</div>
	<img id="product_img_category_event_pag" src="./Broadcasts/product_img_category_event_pag.png" srcset="./Broadcasts/product_img_category_event_pag.png 1x" />
		
	<img id="workshop_img_category_event_pa" src="./Broadcasts/workshop_img_category_event_pa.png" srcset="./Broadcasts/workshop_img_category_event_pa.png 1x" />
		
	<img id="upskill_img_category_event_pag" src="./Broadcasts/upskill_img_category_event_pag.png" srcset="./Broadcasts/upskill_img_category_event_pag.png 1x" />
		
	<img id="other_img_category_event_page_" src="./Broadcasts/other_img_category_event_page_.png" srcset="./Broadcasts/other_img_category_event_page_.png 1x" />
		
	<img id="music_zone_img_category_event_" src="./Broadcasts/music_zone_img_category_event_.png" srcset="./Broadcasts/music_zone_img_category_event_.png 1x" />
		
	<svg class="streming_live_bdr_event_page_p">
		<rect id="streming_live_bdr_event_page_p" rx="0" ry="0" x="0" y="0" width="1282" height="491">
		</rect>
	</svg>
	<div id="streaming_Live_now_lbl_event_p">
		<span>Streaming Soon....</span>
	</div>
	<svg class="enroll_now_bdr_event_page_pc">
		<rect id="enroll_now_bdr_event_page_pc" rx="7" ry="7" x="0" y="0" width="172" height="60">
		</rect>
	</svg>
	<div id="BiziBees_PVT_LTD_steraming_now">
		<span>BiziBees PVT LTD</span>
	</div>
	<div id="Enroll_Now__lbl_streaming_live">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<img id="streaming_live_now_bdr_event_p" src="./Broadcasts/streaming_live_now_bdr_event_p.png" srcset="./Broadcasts/streaming_live_now_bdr_event_p.png 1x" />
		
	<img id="streaming_live_now_img_card_1e" src="./Broadcasts/streaming_live_now_img_card_1e.png" srcset="./Broadcasts/streaming_live_now_img_card_1e.png 1x" />
		
	<svg class="free_event_name_event_page_pc">
		<rect id="free_event_name_event_page_pc" rx="7" ry="7" x="0" y="0" width="172" height="53">
		</rect>
	</svg>
	<div id="BiziBees_PVT_LTD_free_events_e">
		<span>BiziBees PVT LTD</span>
	</div>
	<img id="free_events_card_1_bdr_event_p" src="./Broadcasts/free_events_card_1_bdr_event_p.png" srcset="./Broadcasts/free_events_card_1_bdr_event_p.png 1x" />
		
	<img id="free_events_card_1_event_page_" src="./Broadcasts/free_events_card_1_event_page_.png" srcset="./Broadcasts/free_events_card_1_event_page_.png 1x" />
		
	<div id="Colonel_gravity_get_thought_fa">
		<span>Discover the ultimate free event extravaganza here!</span>
	</div>
	<div id="Free_Events_lbl_event_page_pc">
		<span>Free Events</span>
	</div>
	<div id="event_top_banner_event_page_pc">
		<svg class="Path_3" viewBox="0 0 316.087 175">
			<path id="Path_3" d="M 144.9999542236328 1.52587890625e-05 L 316.0868530273438 148.7235870361328 L 285.0377807617188 151.3047027587891 L 151.8523101806641 39.5488166809082 L 42.31500244140625 170.0902862548828 L -9.094947017729282e-13 174.9999847412109 L 144.9999542236328 1.52587890625e-05 Z">
			</path>
		</svg>
		<svg class="Path_2" viewBox="0 0 316.087 175">
			<path id="Path_2" d="M 144.9999847412109 0 L 316.0869140625 148.7235870361328 L 0 175.0000152587891 L 144.9999847412109 0 Z">
			</path>
		</svg>
		<img id="img_banner_1_event_pagge_pc" src="./Broadcasts/img_banner_1_event_pagge_pc.png" srcset="./Broadcasts/img_banner_1_event_pagge_pc.png 1x" />
			
		<img id="img_banner_3_event_page_pc" src="./Broadcasts/img_banner_3_event_page_pc.png" srcset="./Broadcasts/img_banner_3_event_page_pc.png 1x" />
			
		<img id="img_banner_3_event_page_pc_ch" src="./Broadcasts/img_banner_3_event_page_pc_ch.png" srcset="./Broadcasts/img_banner_3_event_page_pc_ch.png 1x" />
			
		<img id="all_img_banner_2_event_page_pc" src="./Broadcasts/all_img_banner_2_event_page_pc.png" srcset="./Broadcasts/all_img_banner_2_event_page_pc.png 1x" />
			
		<div id="Join_us_today_event_page_pc">
			<span>Join us today</span>
		</div>
		<div id="Today_at_600pm_event_page_pc">
			<span>Today at 6:00pm</span>
		</div>
		<div id="Sat_6pm_event_4_event_page_pc">
			<span>Sat 6pm</span>
		</div>
		<svg class="bell_4_bdr_event_page_pc">
			<rect id="bell_4_bdr_event_page_pc" rx="3" ry="3" x="0" y="0" width="24" height="24">
			</rect>
		</svg>
		<img id="bell_4_img_event_page_pc" src="./Broadcasts/bell_4_img_event_page_pc.png" srcset="./Broadcasts/bell_4_img_event_page_pc.png 1x" />
			
		<svg class="cal_bdr_4_event_page_pc">
			<rect id="cal_bdr_4_event_page_pc" rx="3" ry="3" x="0" y="0" width="24" height="24">
			</rect>
		</svg>
		<img id="calendar_4_img_event_page_pc" src="./Broadcasts/calendar_4_img_event_page_pc.png" srcset="./Broadcasts/calendar_4_img_event_page_pc.png 1x" />
			
		<div id="Sat_6pm_time_3_event_page_pc">
			<span>Sat 6pm</span>
		</div>
		<svg class="bell_bdr_3_event_page_pc">
			<rect id="bell_bdr_3_event_page_pc" rx="3" ry="3" x="0" y="0" width="24" height="24">
			</rect>
		</svg>
		<img id="bell_img_3_event_page_pc" src="./Broadcasts/bell_img_3_event_page_pc.png" srcset="./Broadcasts/bell_img_3_event_page_pc.png 1x" />
			
		<svg class="calender_bdr_3_event_page_pc">
			<rect id="calender_bdr_3_event_page_pc" rx="3" ry="3" x="0" y="0" width="24" height="24">
			</rect>
		</svg>
		<img id="calendar_img_3_event_page_pc" src="./Broadcasts/calendar_img_3_event_page_pc.png" srcset="./Broadcasts/calendar_img_3_event_page_pc.png 1x" />
			
		<div id="Sat_6pm_time_2_event_page_pc">
			<span>Sat 6pm</span>
		</div>
		<svg class="bdr_bell_2_event_page_pc">
			<rect id="bdr_bell_2_event_page_pc" rx="3" ry="3" x="0" y="0" width="24" height="24">
			</rect>
		</svg>
		<img id="bell_2_img_event_page_pc" src="./Broadcasts/bell_2_img_event_page_pc.png" srcset="./Broadcasts/bell_2_img_event_page_pc.png 1x" />
			
		<svg class="calender_bdr_2_event_page_pc">
			<rect id="calender_bdr_2_event_page_pc" rx="3" ry="3" x="0" y="0" width="24" height="24">
			</rect>
		</svg>
		<img id="calendar_img_2_event_page_pc" src="./Broadcasts/calendar_img_2_event_page_pc.png" srcset="./Broadcasts/calendar_img_2_event_page_pc.png 1x" />
			
		<div id="BiziBees_PVT_LTD_1_event_page_">
			<span>BiziBees PVT LTD</span>
		</div>
		<div id="BiziBees_PVT_LTD_4_event_page_">
			<span>BiziBees PVT LTD</span>
		</div>
		<div id="BiziBees_PVT_LTD_3_event_page_">
			<span>BiziBees PVT LTD</span>
		</div>
		<div id="BiziBees_PVT_LTD_2_event_page_">
			<span>BiziBees PVT LTD</span>
		</div>
		<svg class="bell_bdr_1_event_page_pc">
			<rect id="bell_bdr_1_event_page_pc" rx="3" ry="3" x="0" y="0" width="24" height="24">
			</rect>
		</svg>
		<img id="bell_img_1_event_page_pc" src="./Broadcasts/bell_img_1_event_page_pc.png" srcset="./Broadcasts/bell_img_1_event_page_pc.png 1x" />
			
		<svg class="calender_bdr_1_event_page_pc">
			<rect id="calender_bdr_1_event_page_pc" rx="3" ry="3" x="0" y="0" width="24" height="24">
			</rect>
		</svg>
		<img id="calendar_img_1_event_page_pc" src="./Broadcasts/calendar_img_1_event_page_pc.png" srcset="./Broadcasts/calendar_img_1_event_page_pc.png 1x" />
			
		<svg class="enroll_1_bdr_event_page_pc_c">
			<linearGradient id="enroll_1_bdr_event_page_pc_c" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
				<stop offset="0" stop-color="#ff8181" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#f00" stop-opacity="1"></stop>
			</linearGradient>
			<rect id="enroll_1_bdr_event_page_pc_c" rx="3" ry="3" x="0" y="0" width="79" height="24">
			</rect>
		</svg>
		<div id="Enroll_lbl_1_event_page_pc">
			<span>Enroll</span>
		</div>
	</div>
	<svg class="enroll_now_bdr_event_page_pc_db">
		<rect id="enroll_now_bdr_event_page_pc_db" rx="7" ry="7" x="0" y="0" width="172" height="60">
		</rect>
	</svg>
	<div id="BiziBees_PVT_LTD_steraming_now_dc">
		<span>BiziBees PVT LTD</span>
	</div>
	<div id="Enroll_Now__lbl_streaming_live_dd">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<img id="streaming_live_now_bdr_event_p_de" src="./Broadcasts/streaming_live_now_bdr_event_p_de.png" srcset="./Broadcasts/streaming_live_now_bdr_event_p_de.png 1x" />
		
	<img id="streaming_live_now_img_card_1e_df" src="./Broadcasts/streaming_live_now_img_card_1e_df.png" srcset="./Broadcasts/streaming_live_now_img_card_1e_df.png 1x" />
		
	<svg class="enroll_now_bdr_event_page_pc_dg">
		<rect id="enroll_now_bdr_event_page_pc_dg" rx="7" ry="7" x="0" y="0" width="172" height="60">
		</rect>
	</svg>
	<div id="BiziBees_PVT_LTD_steraming_now_dh">
		<span>BiziBees PVT LTD</span>
	</div>
	<div id="Enroll_Now__lbl_streaming_live_di">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<img id="streaming_live_now_bdr_event_p_dj" src="./Broadcasts/streaming_live_now_bdr_event_p_dj.png" srcset="./Broadcasts/streaming_live_now_bdr_event_p_dj.png 1x" />
		
	<img id="streaming_live_now_img_card_1e_dk" src="./Broadcasts/streaming_live_now_img_card_1e_dk.png" srcset="./Broadcasts/streaming_live_now_img_card_1e_dk.png 1x" />
		
	<svg class="enroll_now_bdr_event_page_pc_dl">
		<rect id="enroll_now_bdr_event_page_pc_dl" rx="7" ry="7" x="0" y="0" width="172" height="60">
		</rect>
	</svg>
	<div id="BiziBees_PVT_LTD_steraming_now_dm">
		<span>BiziBees PVT LTD</span>
	</div>
	<div id="Enroll_Now__lbl_streaming_live_dn">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<img id="streaming_live_now_bdr_event_p_do" src="./Broadcasts/streaming_live_now_bdr_event_p_do.png" srcset="./Broadcasts/streaming_live_now_bdr_event_p_do.png 1x" />
		
	<img id="streaming_live_now_img_card_1e_dp" src="./Broadcasts/streaming_live_now_img_card_1e_dp.png" srcset="./Broadcasts/streaming_live_now_img_card_1e_dp.png 1x" />
		
	<svg class="enroll_now_bdr_event_page_pc_dq">
		<rect id="enroll_now_bdr_event_page_pc_dq" rx="7" ry="7" x="0" y="0" width="172" height="60">
		</rect>
	</svg>
	<div id="BiziBees_PVT_LTD_steraming_now_dr">
		<span>BiziBees PVT LTD</span>
	</div>
	<div id="Enroll_Now__lbl_streaming_live_ds">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<img id="streaming_live_now_bdr_event_p_dt" src="./Broadcasts/streaming_live_now_bdr_event_p_dt.png" srcset="./Broadcasts/streaming_live_now_bdr_event_p_dt.png 1x" />
		
	<img id="streaming_live_now_img_card_1e_du" src="./Broadcasts/streaming_live_now_img_card_1e_du.png" srcset="./Broadcasts/streaming_live_now_img_card_1e_du.png 1x" />
		
	<svg class="card_1_recomm_lbl_bdr_event_pa_dv">
		<rect id="card_1_recomm_lbl_bdr_event_pa_dv" rx="7" ry="7" x="0" y="0" width="172" height="53">
		</rect>
	</svg>
	<div id="card_1_recommened_lbl_event_pa_dw">
		<span>BiziBees PVT LTD</span>
	</div>
	<img id="card_1_recommened_bdr_event_pa_dx" src="./Broadcasts/card_1_recommened_bdr_event_pa_dx.png" srcset="./Broadcasts/card_1_recommened_bdr_event_pa_dx.png 1x" />
		
	<img id="recommended_events_card_1_img__dy" src="./Broadcasts/recommended_events_card_1_img__dy.png" srcset="./Broadcasts/recommended_events_card_1_img__dy.png 1x" />
		
	<svg class="card_1_recomm_lbl_bdr_event_pa_dz">
		<rect id="card_1_recomm_lbl_bdr_event_pa_dz" rx="7" ry="7" x="0" y="0" width="172" height="53">
		</rect>
	</svg>
	<div id="card_1_recommened_lbl_event_pa_d">
		<span>BiziBees PVT LTD</span>
	</div>
	<img id="card_1_recommened_bdr_event_pa_d" src="./Broadcasts/card_1_recommened_bdr_event_pa_d.png" srcset="./Broadcasts/card_1_recommened_bdr_event_pa_d.png 1x" />
		
	<img id="recommended_events_card_1_img__d" src="./Broadcasts/recommended_events_card_1_img__d.png" srcset="./Broadcasts/recommended_events_card_1_img__d.png 1x" />
		
	<svg class="card_1_recomm_lbl_bdr_event_pa_d">
		<rect id="card_1_recomm_lbl_bdr_event_pa_d" rx="7" ry="7" x="0" y="0" width="172" height="53">
		</rect>
	</svg>
	<div id="card_1_recommened_lbl_event_pa_ea">
		<span>BiziBees PVT LTD</span>
	</div>
	<img id="card_1_recommened_bdr_event_pa_ea" src="./Broadcasts/card_1_recommened_bdr_event_pa_ea.png" srcset="./Broadcasts/card_1_recommened_bdr_event_pa_ea.png 1x" />
		
	<img id="recommended_events_card_1_img__ea" src="./Broadcasts/recommended_events_card_1_img__ea.png" srcset="./Broadcasts/recommended_events_card_1_img__ea.png 1x" />
		
	<svg class="card_1_recomm_lbl_bdr_event_pa_ea">
		<rect id="card_1_recomm_lbl_bdr_event_pa_ea" rx="7" ry="7" x="0" y="0" width="172" height="53">
		</rect>
	</svg>
	<div id="card_1_recommened_lbl_event_pa_eb">
		<span>BiziBees PVT LTD</span>
	</div>
	<img id="card_1_recommened_bdr_event_pa_eb" src="./Broadcasts/card_1_recommened_bdr_event_pa_eb.png" srcset="./Broadcasts/card_1_recommened_bdr_event_pa_eb.png 1x" />
		
	<img id="recommended_events_card_1_img__eb" src="./Broadcasts/recommended_events_card_1_img__eb.png" srcset="./Broadcasts/recommended_events_card_1_img__eb.png 1x" />
		
	<svg class="free_event_name_event_page_pc_eb">
		<rect id="free_event_name_event_page_pc_eb" rx="7" ry="7" x="0" y="0" width="172" height="53">
		</rect>
	</svg>
	<div id="BiziBees_PVT_LTD_free_events_e_ec">
		<span>BiziBees PVT LTD</span>
	</div>
	<img id="free_events_card_1_bdr_event_p_ed" src="./Broadcasts/free_events_card_1_bdr_event_p_ed.png" srcset="./Broadcasts/free_events_card_1_bdr_event_p_ed.png 1x" />
		
	<img id="free_events_card_1_event_page__ee" src="./Broadcasts/free_events_card_1_event_page__ee.png" srcset="./Broadcasts/free_events_card_1_event_page__ee.png 1x" />
		
	<svg class="free_event_name_event_page_pc_ef">
		<rect id="free_event_name_event_page_pc_ef" rx="7" ry="7" x="0" y="0" width="172" height="53">
		</rect>
	</svg>
	<div id="BiziBees_PVT_LTD_free_events_e_eg">
		<span>BiziBees PVT LTD</span>
	</div>
	<img id="free_events_card_1_bdr_event_p_eh" src="./Broadcasts/free_events_card_1_bdr_event_p_eh.png" srcset="./Broadcasts/free_events_card_1_bdr_event_p_eh.png 1x" />
		
	<img id="free_events_card_1_event_page__ei" src="./Broadcasts/free_events_card_1_event_page__ei.png" srcset="./Broadcasts/free_events_card_1_event_page__ei.png 1x" />
		
	<svg class="free_event_name_event_page_pc_ej">
		<rect id="free_event_name_event_page_pc_ej" rx="7" ry="7" x="0" y="0" width="172" height="53">
		</rect>
	</svg>
	<div id="BiziBees_PVT_LTD_free_events_e_ek">
		<span>BiziBees PVT LTD</span>
	</div>
	<img id="free_events_card_1_bdr_event_p_el" src="./Broadcasts/free_events_card_1_bdr_event_p_el.png" srcset="./Broadcasts/free_events_card_1_bdr_event_p_el.png 1x" />
		
	<img id="free_events_card_1_event_page__em" src="./Broadcasts/free_events_card_1_event_page__em.png" srcset="./Broadcasts/free_events_card_1_event_page__em.png 1x" />
		
	<div id="Group_119">
		<svg class="free_event_name_event_page_pc_eo">
			<rect id="free_event_name_event_page_pc_eo" rx="7" ry="7" x="0" y="0" width="172" height="53">
			</rect>
		</svg>
		<div id="BiziBees_PVT_LTD_free_events_e_ep">
			<span>BiziBees PVT LTD</span>
		</div>
		<img id="free_events_card_1_bdr_event_p_eq" src="./Broadcasts/free_events_card_1_bdr_event_p_eq.png" srcset="./Broadcasts/free_events_card_1_bdr_event_p_eq.png 1x" />
			
		<img id="free_events_card_1_event_page__er" src="./Broadcasts/free_events_card_1_event_page__er.png" srcset="./Broadcasts/free_events_card_1_event_page__er.png 1x" />
			
	</div>
	<div id="card_1_recommened_lbl_event_pa_es">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<div id="card_1_recommened_lbl_event_pa_et">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<div id="card_1_recommened_lbl_event_pa_eu">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<div id="card_1_recommened_lbl_event_pa_ev">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<div id="card_1_recommened_lbl_event_pa_ew">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<div id="card_1_recommened_lbl_event_pa_ex">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<div id="card_1_recommened_lbl_event_pa_ey">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<div id="card_1_recommened_lbl_event_pa_ez">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<div id="card_1_recommened_lbl_event_pa_e">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<div id="card_1_recommened_lbl_event_pa_fa">
		<span>IST 11:30 - 12:20 | 12 July 23</span>
	</div>
	<button class="button">
		View All Events
		</button>
	<button class="button1">
		View All Events
		</button>
</div>
    </div>
  )
}

export default BroadcastsUI
