import React, { useContext, useState, useEffect } from "react";
import { Grid, Typography, Paper, Button } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { SocketContext } from "../SocketContext";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicOffIcon from "@mui/icons-material/MicOff";
import "../styles/MeetingPopUpUI.css"
import { Assignment, ChatSharp, Phone, PhoneDisabled } from "@material-ui/icons";
import MicIcon from "@mui/icons-material/Mic";
import { io } from "socket.io-client";
import { useSearchParams } from 'react-router-dom';
import Peer from "simple-peer";
import { UserContext } from "./UserContext";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DataGrid, GridToolbar} from '@mui/x-data-grid'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Link,ListItem,ListItemButton,List,IconButton} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { ListItemIcon } from '@material-ui/core'


const MeetingPopUpUI = (props) => {

  const {
    me,
    name,
    callAccepted,
    myVideo,
    userVideo,
    callEnded,
    stream,
    call,
    mute,
    setMute,
    videoOff,
    setVideoOff,
    callUser,
    leaveCall,
    response,
    answerCall,
    setResponse,
    msgUser
  } = useContext(SocketContext);

  const { user, setUser } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [idToCall, setIdToCall] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState([]);
  const [status,setStatus] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [ availableMeetings,setAvailableMeetings] = useState([]);
  const [individualMeetings,setIndividualMeetings] = useState([]);
  const [openMeetingModel,setOpenMeetingModel] = useState(false);
  const [meetingTitle,setMeetingTitle] = useState("");
  const [agenda,setAgenda] = useState("");
  const [meetingRemarks,setMeetingRemarks] = useState("");
   
    const [meetingDateTime,setMeetingDateTime] = useState(new Date().toISOString().substring(0,16));
    const [previousRemarks,setPreviousRemarks] = useState("");
    const [requesterId,setRequesterId]= useState("");
    const [meetingId,setMeetingId] = useState("");
    const [callerName,setCallerName] = useState("");
    const [msg,setMsg] = useState("");
    const [prevResponse,setPrevResponse] = useState("");
    const [otherUserId,setOtherUserId] = useState("");
    const [otherUserName,setOtherUserName] = useState("");
    // const [openDrawer,setOpenDrawer] = useState(false);
    // const [openDrawer2,setOpenDrawer2] = useState(true);
  
//UseEffect

useEffect(()=>{
if(user){
   if(props.openMeeetingPopUp){
    if(props.meetingData.requesterId!==user._id){
      setIdToCall(props.meetingData.requesterId)
      setCallerName(props.meetingData.requesterName)
      
    }else if(props.meetingData.requesterId===user._id && props.meetingData.respondentId!==user._id){
      setIdToCall(props.meetingData.respondentId)
      setCallerName(props.meetingData.respondentName)
      
    }
   
    setStatus(props.meetingData.status)
    setMeetingId(props.meetingData._id)
    setMeetingTitle(props.meetingData.meetingTitle);
    setRequesterId(props.meetingData.requesterId);
    setMeetingDateTime(props.meetingData && props.meetingData.meetingDateTime.substring(0,16));
    setAgenda(props.meetingData.agenda);
    setPreviousRemarks(props.meetingData.remarks);
    setMeetingRemarks("");
    setOpenMeetingModel(true)
  
}

   }
    
},[props.openMeeetingPopUp])


useEffect(()=>{

},[openMeetingModel])




    const closeMeetingModel = () =>{
         setOpenMeetingModel(false);
         props.closeMeetingPopup()
    }



      const ScheduleMeeting = (meetingId,status) =>{
        setOpenMeetingModel(false);
        let apiURL=process.env.REACT_APP_API_URL + "/meetings/updatemeetings/"+ meetingId;
          let apiParams = {
              method:"POST",
              headers:{
                meetingtitle:meetingTitle,
                meetingdatetime:meetingDateTime,
                agenda:encodeURIComponent(agenda),
                remarks:encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+meetingRemarks+"\n"+(previousRemarks?previousRemarks:"")+"\n"),
                status:status,
              },
             
          };
          fetch(apiURL, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
              
                alert("Meeting Updated Successfully");
                props.closeMeetingPopup()
              
              
              }else alert(repos.message || repos);
          }) 
          .catch((err) => alert(err));
      
       }


       //user Online
       const isUserOnline = (id,name) => {
        setOpenMeetingModel(false)
        let apiURL = process.env.REACT_APP_API_URL + "/status/isonline/"+id;
    
        fetch(apiURL)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
              
              callUser(repos.data[0].socketId,name)
             
            } else alert( name + " is not Online, please try after sometime!!");
          })

          .catch(() => alert(name + " is not Online, please try after sometime!!"));

      };
    


    

  return (
    <div>
      {/* Pop up for schedule meeting */}
      <Modal
        open={openMeetingModel}
        onClose={closeMeetingModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
      <div id="pop_up_meeting_room">
	<img id="meetingRoom_popbg" src="meetingroompopupbg.png" srcset="meetingroompopupbg.png 1x, meetingroompopupbg@2x.png 2x" />
		
	<div id="Group_border_meetingPop">
		<svg class="border_meetpop_">
			<rect id="border_meetpop_" rx="45.78704833984375" ry="45.78704833984375" x="0" y="0" width="91.574" height="371" >
			</rect>
		</svg>
		<div id="Meeting_Request_lbl_mp">
			<span>Meeting Request</span>
		</div>
	</div>
	<input placeholder="Agenda" multiline class="txtprevious_remarks_val" disabled={requesterId!==(user && user._id)?true:false}  rows={3}  sx={{ m: 1, width: '35ch' }} size="small" label="Agenda" variant="outlined"  value={agenda} onChange={(e)=> {
       setAgenda(e.target.value);}} />
	
	<input placeholder="Meeting Title" class="txtMeeting_Room_pop_val" disabled={requesterId!==(user && user._id)?true:false}  value={meetingTitle} onChange={(e)=> {
       setMeetingTitle(e.target.value); }}  />
	
	<input type="datetime-local" id="MeetingTime" format="dd-mm-yyyy" class="txtDate_pop_val" disabled={requesterId!==(user && user._id)?true:false} value={meetingDateTime} onChange={(e)=>setMeetingDateTime(e.target.value)}/>
		
	<div id="Meeting_On_lbl_mp">
		<span>Meeting starts On :</span>
	</div>
	<input type="datetime-local" id="MeetingTime" format="dd-mm-yyyy" class="txtDate_pop_val" disabled={requesterId!==(user && user._id)?true:false} value={meetingDateTime} onChange={(e)=>setMeetingDateTime(e.target.value)}/>
		
	<div id="Meeting_On_lbl_mp">
		<span>Meeting Ends On :</span>
	</div>

	<textarea placeholder="Previous Remarks" class="txtagenda_pop_val" disabled={requesterId!==(user && user._id)?true:false}  value={previousRemarks}/>
		
	<input placeholder="Remark" class="txtremarks_val_mp"  value={meetingRemarks} onChange={(e)=> {
          setMeetingRemarks(e.target.value);  }}/>
		
        {((requesterId!==(user && user._id)) && status ==="Requested") ?
        <button id="Group_btn_accept_" onClick={()=>ScheduleMeeting(meetingId,"Accepted")}>
		<svg class="border_grp_btn_acc">
			<rect id="border_grp_btn_acc" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Accept_btn_acc">
			<span>Accept</span>
		</div>
	</button>:""}
	{((requesterId!==(user && user._id)) && status ==="Requested")?
  <button id="Group_reject_btn_rej" onClick={()=>ScheduleMeeting(meetingId,"Rejected")}>
		<svg class="border_btn_rej_">
			<rect id="border_btn_rej_" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Reject_lbl_rej">
			<span>Reject</span>
		</div>
	</button>:""}

    { status ==="Requested" && (requesterId!==(user && user._id)) ?
    <button id="Group_btn_propose_pr" onClick={()=>ScheduleMeeting(meetingId,"Requested")}>
		<svg class="border_pro_btn_pr">
			<rect id="border_pro_btn_pr" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Propose_btn_pro__lbl">
			<span>Propose</span>
		</div>
	</button>:""}

     { status ==="Requested" && (requesterId===(user && user._id)) ?
     <button id="Group_btn_propose_pr" style={{left: "80px"}} onClick={()=>ScheduleMeeting(meetingId,"Requested")}>
		<svg class="border_pro_btn_pr">
			<rect id="border_pro_btn_pr" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Propose_btn_pro__lbl">
			<span>Update</span>
		</div>
	</button>:""}
  { status ==="Accepted"  ?
     <button id="Group_btn_propose_pr" style={{left: "80px",textAlign:"center"}} onClick={()=>isUserOnline(idToCall,callerName)}>
		<svg class="border_pro_btn_pr">
			<rect id="border_pro_btn_pr" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Propose_btn_pro__lbl">
			<span>Call</span>
		</div>
	</button>:""}

 

  {(((requesterId===(user && user._id)) && status ==="Requested")||status ==="Accepted")?
  <button id="Group_btn_propose_pr" style={{left: "200px"}} onClick={()=>ScheduleMeeting(meetingId,"Cancelled")}>
		<svg class="border_pro_btn_pr">
			<rect id="border_pro_btn_pr" rx="8" ry="8" x="0" y="0" width="97" height="32">
			</rect>
		</svg>
		<div id="Propose_btn_pro__lbl">
			<span>Cancel</span>
		</div>
	</button>:""}
	<img id="closeiconmeetingpop" src="closeiconmeetingpop.png" srcset="closeiconmeetingpop.png 1x, closeiconmeetingpop@2x.png 2x" onClick={()=>closeMeetingModel()}/>
</div>
           </Modal>  
    </div>
  )
}

export default MeetingPopUpUI
