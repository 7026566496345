import React from 'react';
import "../../../styles/TrueVisionMob.css";

function TrueVisionMob(props) {
  return (
    <div id="TVMTrue_Vision_Mob">
	<div id="TVMGroup_15132">
		<div id="TVMGroup_15040">
			<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="TVMRectangle_737">
				<rect id="TVMRectangle_737" rx="0" ry="0" x="0" y="0" width="281" height="158">
				</rect>
			</iframe>
		</div>
		<div id="TVMGroup_15050">
			<div id="TVMsocials_grp_shadow_space_websi">
{props.data.linkedInUrl?
				<div title="LinkedIn" id="TVMlinked_in_grp_shadow_space_web">
					<svg class="TVMmed_shadow_space_website_7">
						<ellipse id="TVMmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="TVMlinked_in_shadow_space_website">
						<a href={props.data.linkedInUrl} target="_blank">
						<svg class="TVMn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="TVMn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="TVMlinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="TVMlinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="TVMtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="TVMtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>
:""}
{props.data.instagramUrl?	
				<div title="Instagram" id="TVMInsta_grp_shadow_space_website">
					<svg class="TVMshadow_space_website_7">
						<ellipse id="TVMshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={props.data.instagramUrl} target="_blank">
					<img id="TVMinstagramshadow_space_website_" src="./Themes/TrueVision/instagramshadow_space_website_.png" srcset="./Themes/TrueVision/instagramshadow_space_website_.png 1x" />
						
					</a>
				</div>	
:""}
{props.data.whatsAppNumber?
				<div title="WhatsApp" id="TVMwhatsapp_grp_shadow_space_webs">
					<svg class="TVMmed_shadow_space_website_7_">
						<ellipse id="TVMmed_shadow_space_website_7_" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
<img id="TVMwhatsapp_lbl_shadow_space_webs" src="./Themes/TrueVision/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/TrueVision/whatsapp_lbl_shadow_space_webs.png 1x" />
</a>
				</div>:""}
			</div>
			<div id="TVMn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="TVMaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="TVMaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="TVMlocation_img_shadow_space_webs" src="./Themes/TrueVision/location_img_shadow_space_webs.png" srcset="./Themes/TrueVision/location_img_shadow_space_webs.png 1x" />
				
			<img id="TVMemail_address_shadow_space_web" src="./Themes/TrueVision/email_address_shadow_space_web.png" srcset="./Themes/TrueVision/email_address_shadow_space_web.png 1x" />
				
			<img id="TVMphone_number_shadow_space_webs" src="./Themes/TrueVision/phone_number_shadow_space_webs.png" srcset="./Themes/TrueVision/phone_number_shadow_space_webs.png 1x" />
				
			<div id="TVMn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited.<br/> All Rights Reserved</span>
			</div>
			<div id="TVMContact_Us">
				<span>Contact Us!</span>
			</div>
		</div>
	</div>
	<div id="TVMGroup_15168">
		<img id="TVMvolodymyr-hryshchenko-NQQ_WKbj" src="./Themes/TrueVision/volodymyr-hryshchenko-NQQ_WKbj.png" srcset="./Themes/TrueVision/volodymyr-hryshchenko-NQQ_WKbj.png 1x" />
			
		<div id="TVMSee_the_world_through_a__clear">
			<span>"See the world through a <br/>clearer lens, and let your <br/>vision become a work of art."</span>
		</div>
		<img id="TVMAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/TrueVision/Anime_Edit.png"} />
			
		<div id="TVMYour_Business_Name">
			<span>{props.data.businessName}</span>
		</div>
	</div>
	<div id="TVMGroup_15114">
		<img id="TVMjoshua-newton-g4_IymCiD-k-unsp" src="./Themes/TrueVision/joshua-newton-g4_IymCiD-k-unsp.png" srcset="./Themes/TrueVision/joshua-newton-g4_IymCiD-k-unsp.png 1x" />
			
		<div id="TVMScroll_Group_28">
			<div id="TVMIt_is_a_long_established_fact_">
				<span>{props.data.vision}</span>
			</div>
		</div>
		<div id="TVMVISION">
			<span>VISION</span>
		</div>
	</div>
	<div id="TVMGroup_15165">
		<img id="TVMfelipepelaquim-0q5Y7OFmMhM-uns" src="./Themes/TrueVision/felipepelaquim-0q5Y7OFmMhM-uns.png" srcset="./Themes/TrueVision/felipepelaquim-0q5Y7OFmMhM-uns.png 1x" />
			
		<div id="TVMScroll_Group_28_bo">
			<div id="TVMIt_is_a_long_established_fact__bp">
				<span>{props.data.mission}</span>
			</div>
		</div>
		<div id="TVMMISSION">
			<span>MISSION</span>
		</div>
	</div>
	<div id="TVMGroup_15166">
		<img id="TVMdmitry-ratushny-wpi3sDUrSEk-un" src="./Themes/TrueVision/dmitry-ratushny-wpi3sDUrSEk-un.png" srcset="./Themes/TrueVision/dmitry-ratushny-wpi3sDUrSEk-un.png 1x" />
			
		<div id="TVMScroll_Group_28_bt">
			<div id="TVMIt_is_a_long_established_fact__bu">
				<span>{props.data.principles}</span>
			</div>
		</div>
		<div id="TVMPRINCIPLE">
			<span>PRINCIPLE</span>
		</div>
	</div>
	<div id="TVMScroll_Group_94">
		<div id="TVMn_">
			<img id="TVMMask_Group_22" src="./Themes/TrueVision/Mask_Group_22.png" srcset="./Themes/TrueVision/Mask_Group_22.png 1x" />
				
			<svg class="TVMRectangle_664">
				<rect id="TVMRectangle_664" rx="0" ry="0" x="0" y="0" width="217" height="33">
				</rect>
			</svg>
			<div id="TVMCOSMOS">
				<span>COSMOS</span>
			</div>
			<div id="TVMGroup_10773">
				<svg class="TVMEllipse_38">
					<ellipse id="TVMEllipse_38" rx="2" ry="1.5" cx="2" cy="1.5">
					</ellipse>
				</svg>
				<svg class="TVMEllipse_39">
					<ellipse id="TVMEllipse_39" rx="2" ry="1.5" cx="2" cy="1.5">
					</ellipse>
				</svg>
				<svg class="TVMEllipse_40">
					<ellipse id="TVMEllipse_40" rx="2" ry="1.5" cx="2" cy="1.5">
					</ellipse>
				</svg>
				<svg class="TVMEllipse_41">
					<ellipse id="TVMEllipse_41" rx="2" ry="1.5" cx="2" cy="1.5">
					</ellipse>
				</svg>
				<svg class="TVMEllipse_42">
					<ellipse id="TVMEllipse_42" rx="2" ry="1.5" cx="2" cy="1.5">
					</ellipse>
				</svg>
			</div>
		</div>
	</div>
	<div id="TVMWIDE_RANGE_OF_DESIGN">
		<span>WIDE RANGE OF DESIGN</span>
	</div>
</div>
  )
}

export default TrueVisionMob