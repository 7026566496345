import React from 'react'
import '../../styles/ADProfileMobUI.css'
const ADProfileMobUI = () => {
  return (
    <div>
    <div id="ad_area">
	<svg class="ad_bdr_ad_mob">
		<rect id="ad_bdr_ad_mob" rx="10" ry="10" x="0" y="0" width="100%" height="90">
		</rect>
	</svg>
	<div id="AD_AREA_lbl_ad_mob">
		{/* <span>AD AREA</span> */}
	</div>
</div>
    </div>
  )
}

export default ADProfileMobUI
