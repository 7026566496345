import React from 'react';
import "../../../styles/PerfumePulse.css";

function PerfumePulse(props) {
  return (
    <div id="PerfumePulse">
	<div id="PPText">
		<div id="PPExperience_the_finest_in_premi">
			<span>Experience the finest in premium perfume </span>
		</div>
		<div id="PPCompany__Name">
			<span>{props.data.businessName}</span>
		</div>
	</div>
	<div id="PPBGs">
		<div id="PPSee_the_Collection">
			<svg class="PPBG">
				<rect id="PPBG" rx="0" ry="0" x="0" y="0" width="341" height="358">
				</rect>
			</svg>
			<div id="PPIcon_feather-arrow-right-circl">
				<svg class="PPPath_1" viewBox="3 3 29.271 29.271">
					<path id="PPPath_1" d="M 32.27142715454102 17.63574409484863 C 32.27142715454102 25.71884155273438 25.71879577636719 32.27148056030273 17.63571357727051 32.27148056030273 C 9.552632331848145 32.27148056030273 3 25.71884155273438 3 17.63574409484863 C 3 9.552644729614258 9.552632331848145 3 17.63571357727051 3 C 25.71879577636719 3 32.27142715454102 9.552644729614258 32.27142715454102 17.63574409484863 Z">
					</path>
				</svg>
				<svg class="PPPath_2" viewBox="18 12 5.854 11.709">
					<path id="PPPath_2" d="M 18 23.70859527587891 L 23.85428619384766 17.85429573059082 L 18 12">
					</path>
				</svg>
				<svg class="PPPath_3" viewBox="12 18 11.709 3">
					<path id="PPPath_3" d="M 12 18 L 23.70857238769531 18">
					</path>
				</svg>
			</div>
			<div id="PPSee_the_Collection_bb">
				<div id="PPSee_the">
					<span>See the</span>
				</div>
				<div id="PPcollection">
					<span>collection</span>
				</div>
			</div>
		</div>
		<img id="PPmiguel-andrade-potCPE_Cw8A-uns" src="./Themes/PerfumePulse/miguel-andrade-potCPE_Cw8A-uns.png" srcset="./Themes/PerfumePulse/miguel-andrade-potCPE_Cw8A-uns.png 1x, miguel-andrade-potCPE_Cw8A-uns@2x.png 2x" />
			
		<img id="PPjeroen-den-otter-2b0JeJTEclQ-u" src="./Themes/PerfumePulse/jeroen-den-otter-2b0JeJTEclQ-u.png" srcset="./Themes/PerfumePulse/jeroen-den-otter-2b0JeJTEclQ-u.png 1x, jeroen-den-otter-2b0JeJTEclQ-u@2x.png 2x" />
			
	</div>
	<div id="PPTop_Nav">
		<div id="PPMenu_Button">
			<svg class="PPLine_1" viewBox="0 0 23.417 3">
				<path id="PPLine_1" d="M 0 0 L 23.41714286804199 0">
				</path>
			</svg>
			<svg class="PPLine_2" viewBox="0 0 46.834 3">
				<path id="PPLine_2" d="M 0 0 L 46.83428573608398 0">
				</path>
			</svg>
			<svg class="PPLine_3" viewBox="0 0 23.417 3">
				<path id="PPLine_3" d="M 0 0 L 23.41714286804199 0">
				</path>
			</svg>
		</div>
	</div>
	<img id="PPAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/PerfumePulse/Anime_Edit.png"} />
		
	<div id="PPGroup_15039">
		<div id="PPGroup_15033">
			<img id="PPbundo-kim-ur2zmbseUIA-unsplash" src="./Themes/PerfumePulse/bundo-kim-ur2zmbseUIA-unsplash.png" srcset="./Themes/PerfumePulse/bundo-kim-ur2zmbseUIA-unsplash.png 1x, bundo-kim-ur2zmbseUIA-unsplash@2x.png 2x" />
				
			<div id="PPGroup_15038">
				<div id="PPScroll_Group_28">
					<div id="PPIt_is_a_long_established_fact_">
						<span>{props.data.vision}</span>
					</div>
				</div>
				<div id="PPVISION">
					<span>VISION</span>
				</div>
			</div>
		</div>
		<div id="PPGroup_15034">
			<img id="PPhanna-balan-d1GwyeOlZDw-unspla" src="./Themes/PerfumePulse/hanna-balan-d1GwyeOlZDw-unspla.png" srcset="./Themes/PerfumePulse/hanna-balan-d1GwyeOlZDw-unspla.png 1x, hanna-balan-d1GwyeOlZDw-unspla@2x.png 2x" />
				
			<div id="PPGroup_15037">
				<div id="PPScroll_Group_28_bw">
					<div id="PPIt_is_a_long_established_fact__bx">
						<span>{props.data.mission}</span>
					</div>
				</div>
				<div id="PPMISSION">
					<span>MISSION</span>
				</div>
			</div>
		</div>
		<div id="PPGroup_15035">
			<img id="PPemily-wang-a5917t2ea8I-unsplas" src="./Themes/PerfumePulse/emily-wang-a5917t2ea8I-unsplas.png" srcset="./Themes/PerfumePulse/emily-wang-a5917t2ea8I-unsplas.png 1x, emily-wang-a5917t2ea8I-unsplas@2x.png 2x" />
				
			<div id="PPScroll_Group_28_b">
				<div id="PPIt_is_a_long_established_fact__b">
					<span>{props.data.principles}</span>
				</div>
			</div>
			<div id="PPPRINCIPLES">
				<span>PRINCIPLES</span>
			</div>
		</div>
	</div>
	<div id="PPGroup_15119">
		<div id="PPScroll_Group_77">
            {props.offeringsData.map((rec,idx)=>( 
			<div id="PPn_">	 
				<img id="PPMask_Group_22" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"./Themes/PerfumePulse/Mask_Group_22.png"} />
				<svg class="PPRectangle_664">
					<rect id="PPRectangle_664" rx="0" ry="0" x="0" y="0" width="307" height="46">
					</rect>
				</svg>
				<div id="PPPERFUME">
					<span>{rec.offeringTitle}</span>
				</div>
				<div id="PPGroup_10773">
					<svg class="PPEllipse_38">
						<ellipse id="PPEllipse_38" rx="2.5" ry="2.5" cx="2.5" cy="2.5">
						</ellipse>
					</svg>
					<svg class="PPEllipse_39">
						<ellipse id="PPEllipse_39" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
					<svg class="PPEllipse_40">
						<ellipse id="PPEllipse_40" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
					<svg class="PPEllipse_41">
						<ellipse id="PPEllipse_41" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
					<svg class="PPEllipse_42">
						<ellipse id="PPEllipse_42" rx="3" ry="2.5" cx="3" cy="2.5">
						</ellipse>
					</svg>
				</div>
			</div>))}
		</div>
		<div id="PPIn_Stock">
			<span>In Stock</span>
		</div>
	</div>
	<div id="PPGroup_15101">
		<div id="PPGroup_15041">
			<div id="PPGroup_15040">
				<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="PPRectangle_737">
					<rect id="PPRectangle_737" rx="0" ry="0" x="0" y="0" width="538" height="302">
					</rect>
				</iframe>
			</div>
			<div id="PPsocials_grp_shadow_space_websi">
            {props.data.linkedInUrl?<div title="LinkedIn" id="PPlinked_in_grp_shadow_space_web">
					<svg class="PPmed_shadow_space_website_7">
						<ellipse id="PPmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="PPlinked_in_shadow_space_website">
						<a href={props.data.linkedInUrl} target="_blank">
						<svg class="PPn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="PPn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="PPlinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="PPlinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="PPtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="PPtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>:""}
			{props.data.instagramUrl?	
				<div title="Instagram" id="PPInsta_grp_shadow_space_website">
					<svg class="PPshadow_space_website_7">
						<ellipse id="PPshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={props.data.instagramUrl} target="_blank">
					<img id="PPinstagramshadow_space_website_" src="./Themes/PerfumePulse/instagramshadow_space_website_.png" srcset="./Themes/PerfumePulse/instagramshadow_space_website_.png 1x, instagramshadow_space_website_@2x.png 2x" />
						
					</a>
				</div>:""}
			{props.data.whatsAppNumber?
				<div title="WhatsApp" id="PPwhatsapp_grp_shadow_space_webs">
					<svg class="PPmed_shadow_space_website_7_cw">
						<ellipse id="PPmed_shadow_space_website_7_cw" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="PPwhatsapp_lbl_shadow_space_webs" src="./Themes/PerfumePulse/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/PerfumePulse/whatsapp_lbl_shadow_space_webs.png 1x, whatsapp_lbl_shadow_space_webs@2x.png 2x" />
						</a>
				</div>:""}
			</div>
			<div id="PPn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="PPaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="PPaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="PPlocation_img_shadow_space_webs" src="./Themes/PerfumePulse/location_img_shadow_space_webs.png" srcset="./Themes/PerfumePulse/location_img_shadow_space_webs.png 1x, location_img_shadow_space_webs@2x.png 2x" />
				
			<img id="PPemail_address_shadow_space_web" src="./Themes/PerfumePulse/email_address_shadow_space_web.png" srcset="./Themes/PerfumePulse/email_address_shadow_space_web.png 1x, email_address_shadow_space_web@2x.png 2x" />
				
			<img id="PPphone_number_shadow_space_webs" src="./Themes/PerfumePulse/phone_number_shadow_space_webs.png" srcset="./Themes/PerfumePulse/phone_number_shadow_space_webs.png 1x, phone_number_shadow_space_webs@2x.png 2x" />
				
			<div id="PPn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
			</div>
		</div>
		<div id="PPContact_Us">
			<span>Contact Us!</span>
		</div>
	</div>
</div>
  )
}

export default PerfumePulse