import React, { useState, useEffect, useContext } from "react";
// import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider } from "@mui/material";
import "../../styles/UserInfoUI.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
import { UserContext } from "../UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { autocompleteClasses } from "@material-ui/core";
import countries from "../Countries.json";
import Currencies from "../Currencies.json";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SuggestionPageIntroPopUp from "../SuggestionPageIntroPopUp";
import ThemesSetUpPc from "./ThemesSetUpPc";

const UserInfoUI = (props) => {
 
  const label = { inputProps: { "aria-label": "Schedule Interview" } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule] = React.useState();
  const [searchParams] = useSearchParams();
  
  const { user, setUser } = useContext(UserContext);
  var navigate = useNavigate();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState(new Date());
  const [whatsNumber, setWhatsNumber] = useState("");
  const [img,setImg]= useState("");
  const [gender, setGender] = useState("");
  const [open, setOpen] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportingToId, setReportingToId] = useState("");
  const [reportingToName, setReportingToName] = useState("");
  const [accessType, setAccessType] = useState("");
  const [country, setCountry] = useState("");
  const [hourlyRate,setHourlyRate] = useState("");
  const [currency, setCurrency] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [profession,setProfession] = useState("");
  const [active, setActive] = useState(true);
  const [confirmPass, setConfirmPass] = useState("");
  const [password, setPassword] = useState("");
  const [pincode, setPincode] = useState("");
  const [introduction,setIntroduction] = useState("");
  const [openChgPwd, setOpenChgPwd] = React.useState(false);
  const [previousPass,setPreviousPass]= useState("");
  const [nickName,setNickName] = useState("");
  const [mission,setMission] = useState("");
  const [vision,setVision] = useState("");
  const [principle,setPrinciple] = useState("");
  const [connections, setConnections] = useState(0);
  const [followers, setFollowers] = useState(0);
  const [openIntroModal,setOpenIntroModal] = useState(false);
  const [introData,setIntroData] = useState(false);
  const [selectedTab,setSelectedTab] = useState("about")
  const [bannerPic,setBannerPic] = useState("");
  const [bannerImage,setBannerImage] = useState("");
  const [openThemesSelection,setOpenThemesSelection] = useState(false)
  const [selectedTheme,setSelectedTheme] = useState("")
  const [connectionStatus,setConnectionStatus] = useState("New");
  // const [filterModel, setFilterModel] = React.useState({
  //   items: [
  //     {columnField: '', operatorValue: 'contains', value:user.name}
  //   ],
  // });

  const cols = [
    { field: "id", headerName: "id", hide: true },
    { field: "name", headerName: "Name", width: 190 },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      renderCell: (params) => {
        return [
          params.row.gender
            ? params.row.gender.substring(0, 1).toUpperCase() +
              params.row.gender.substring(1).toLowerCase()
            : "",
        ];
      },
    },
    { field: "reportingToName", headerName: "Reporting To", width: 190 },
    {
      field: "accesstype",
      headerName: "AccessType",
      width: 100,
      renderCell: (params) => {
        switch (params.row.accesstype) {
          case "0":
            return ["Guest"];

          case "1":
            return ["Employee"];

          case "2":
            return ["Supervisor"];

          case "3":
            return ["Manager"];

          case "4":
            return ["Director"];

          case "5":
            return ["Admin"];

          default:
            return [""];
        }
      },
    },
    { field: "mobile", headerName: "Mobile No", width: 140 },

    { field: "email", headerName: "Email", width: 200 },
    {
      field: "active",
      headerName: "Active",
      width: 100,
      renderCell: (params) => {
        return [<input type="checkbox" checked={params.row.active} />];
      },
    },
  ];

  useEffect(() => {
    //addEmployee();
    if(props.profile)
    {
      props.profile._id && getUserProfile();
      props.profile._id && getConnectionStatus()
    }
    else if(user._id)
    {
      getUserProfile()
    }
    
  }, [user,props.profile]);

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }


  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setBannerPic("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const handleBannnerUpload = async (e) => {
   
    // if (e.target.files[0].size > 71680) {
    //   alert("Please choose a file with size below 70kb!");
    //   return;
    // }
    handleFileSelect(e.target.files[0]);
    UploadImageToDrive(e.target.files[0])
    // let formdata = new FormData();
    // formdata.append("image", e.target.files[0], e.target.value);
    // updateProfilePic(formdata);
  };


  //Upload Image
  const UploadImageToDrive = async (document) =>{
     
    var file = document //the file
    var reader = new FileReader() //this for convert to Base64 
    reader.readAsDataURL(document) //start conversion...
    reader.onload = function (e) { //.. once finished..
      var rawLog = reader.result.split(',')[1]; //extract only thee file data part
      var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
     fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
        { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
        .then(res => res.json()).then((data) => {
          //See response
        
           SaveProfilePic(data)
          // alert("Image Uploaded Successfully","success")
  
        }).catch(err => console.log(err)) // Or Error in console
    }
  }

  const SaveProfilePic = (data)=>{
    
    const apiurl = process.env.REACT_APP_API_URL + "/users/updatebannerpicnew/"+user._id;
            let apiParams = {
                method:"POST",
                headers:{
                  bannerimageurl:data.url,
                
                }
          }
          fetch(apiurl, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
               
                alert("Image Uploaded Successfully");
              }
            })
            .catch(e => console.log(e)) 
          }


          const getConnectionStatus = ()=>{

            let apiURL=process.env.REACT_APP_API_URL + "/connection/getconnectionstatus/"+props.profile._id+"/"+user._id; 
        
            fetch(apiURL)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  console.log(repos.data)
                
                  setConnectionStatus(repos.data.status)
                  
                }else setConnectionStatus("New");  
            }) 
            .catch((err) => alert(err));
          }       

  const getUserProfile = () => {
    
    let apiURL = process.env.REACT_APP_API_URL + "/users/getUserDetails/"+ (props.profile? props.profile._id : user._id);
  
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          //console.log(repos.data);
          setEmployees(repos.data);
          setAddress(repos.data.address);
          setDob(repos.data.dob ?((repos.data.dob).substring(0,10)):new Date());
          setEmail(repos.data.email?repos.data.email:"");
          setName(repos.data.name);
          setGender(repos.data.gender);
          setMobile(repos.data.phone);
          setCountry(repos.data.country ?repos.data.country:"");
          setState(repos.data.state?repos.data.state:"");
          setCity(repos.data.city? repos.data.city:"");
          setPincode(repos.data.pincode);
          setProfession(repos.data.profession);
          setIntroduction(repos.data.introduction);
          setSelectedTheme(repos.data.theme);
          // setImg(repos.data.image.data ?arrayBufferToBase64(repos.data.image.data.data):"");
          setImg(repos.data.imageUrl);
          setBannerImage(repos.data.bannerImageUrl);
          setCurrency(repos.data.currency);
          setHourlyRate(repos.data.hourlyRate);
          setNickName(repos.data.nickName);
          setMission(repos.data.mission);
          setVision(repos.data.vision);
          setPrinciple(repos.data.principles);
          setConnections(repos.connections);
          setFollowers(repos.followers);
        } else console.log(repos);
      })
      .catch((err) => console.log(err));
  };

  const UpdateEmployeesDetails = (id) => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/updateEmployees/" + user._id;
    let apiParams = {
      method: "POST",
      headers: {
              name: name,
              gender: gender,
              dob: dob,
              mobile: mobile,
              email: email,
              country: country,
              state: state,
              city: city,
              address: encodeURIComponent(address),
              pincode: pincode,
              profession:profession,
              introduction:encodeURIComponent(introduction),
              currency:currency,
              hourlyrate:hourlyRate,
        // password: encodeURIComponent(password),
        // active: active,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);
          alert("User Updated Successfully")
          // props.showAlert("User Updated Successfully", "success");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch((err) => alert(err));
  };

const PasswordChange = ()=>{
  if (password !== confirmPass) {
    alert("Password and Confirm Password don't match!!");
    return;
  }
  setOpenChgPwd(false);
  let apiURL = process.env.REACT_APP_API_URL + "/users/updatePassword/" + user._id;
  let apiParams = {
    method: "POST",
    headers: {
           previouspass:encodeURIComponent(previousPass),
           newpassword:encodeURIComponent(password),
           
    },
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
        setIsLoading(false);
        alert("Password Changed Successfully!!!")
        handlelogout()
      } else alert(repos);
    })
    .catch((err) => alert(err));

}

const handlelogout = () =>{
  localStorage.clear();
  setUser(null);
  window.location.href = "/login";
 
}

  const addEmployee = () => {
    setOpen(true);
  };


  const handleClosePwd = () => {
    setOpenChgPwd(false);
   
  };
  
  const openConnectionModal = (id,name)=>{
 
    setIntroData({id,name})
     setOpenIntroModal(true)
    }
  
    const ConnectionReq = async (id,name,status)=>{
      let confirmation=window.confirm("Are you sure ?")
      if(!confirmation){
        return
      }
      let apiURL=process.env.REACT_APP_API_URL + "/connection/addconnections";
      let apiParams = {
          method:"POST",
          headers:{
            user1id:user._id,
            user1name:user.name,
            status:status,
             user2id:id,
             user2name:name,  
            //  intro:encodeURIComponent(intro),
            //  user1selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
          },
          
      };
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
        
            alert("Action Updated Successfully!!");
            
        //    props.showAlert("Job Added Successfully","success")
           setTimeout(() => {
            
          }, 1500);
          
          }else alert(repos.message || repos);
      }) 
      .catch((err) => alert(err)); 
  }

  return (
    <div>

<div id="top_info">
	<div id="mid_circle_grp_profile">
     {bannerPic.length === 0 ?<img id="banner_img" width="100" height="100" src={bannerImage?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(bannerImage.split('/')[5]):"banner_img.png"} />: 
		<img id="banner_img" src={bannerPic?bannerPic:"banner_img.png"} />}
			
		<svg class="profil_info_bdr">
			<rect id="profil_info_bdr" rx="15" ry="15" x="0" y="0" width="100%" height="251">
			</rect>
		</svg>
    <svg class="pfp_pic">
			<ellipse id="pfp_pic" rx="60" ry="60" cx="60" cy="60">
			</ellipse>
		</svg>
		<img id="pfp_pic_image" src={img && img.length > 10?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(img.split('/')[5]):"user_pfp_profile_page.png"} />
			
    <div id="user_details_grp_left">
			<div id="UserProfileName_Pc">
				<span>{name}</span><span style={{fontStyle:"normal",fontWeight:"normal",fontSize:"15px",margin:"5px"}}>({nickName})</span>
			</div>
			<div id="location_grp">
				<div id="India_Bangalore">
					<span>{country+" , "+city}</span>
				</div>
				<img id="india_img" src="world__img.png" srcset="world__img.png 1x" />
					
			</div>
			<div id="skills_grp_">
				<img id="skill_img_" src="skill_img_.png" srcset="skill_img_.png 1x" />
					
				<div id="UIUX_designer_skill">
					<span>{profession}</span>
				</div>
			</div>
			<div id="connections_grp">
				<svg class="all_con">
					<rect id="all_con" rx="15" ry="15" x="0" y="0" width="128" height="31">
					</rect>
				</svg>
				<svg class="Ellipse_11_circle_">
					<ellipse id="Ellipse_11_circle_" rx="12.5" ry="12.5" cx="12.5" cy="12.5">
					</ellipse>
				</svg>
				<div id="n_2345432">
					<span>{connections}</span>
				</div>
				<div id="Connections_lbl">
					<span>Connections</span>
				</div>
			</div>
			<div id="followers_grp_lbl">
				<svg class="overall_rec">
					<rect id="overall_rec" rx="15" ry="15" x="0" y="0" width="113" height="31">
					</rect>
				</svg>
				<svg class="Ellipse_1_circle">
					<ellipse id="Ellipse_1_circle" rx="12.5" ry="12.5" cx="12.5" cy="12.5">
					</ellipse>
				</svg>
				<div id="n_23_456789">
					<span>{followers}</span>
				</div>
				<div id="Followers_lbl">
					<span>Followers</span>
				</div>
			</div>
			
		</div>

		{/* <div title="Whatsapp" onclick="application.goToTargetView(event)" id="QR_btn_grp">
			<svg class="QR_circle_bdr">
				<ellipse id="QR_circle_bdr" rx="18" ry="18" cx="18" cy="18">
				</ellipse>
			</svg>
			<img id="QR_img" src="QR_img.png" srcset="QR_img.png 1x" />
				
		</div> */}

    { props.profile && props.profile._id? "":	<div id="profile_link_grp_">
			<svg class="offer_circle">
				<ellipse id="offer_circle" rx="18" ry="18" cx="18" cy="18">
				</ellipse>
			</svg>
			<img id="web-design_" title="Themes" src="web-design.png" srcset="web-design.png 1x" onClick={()=>setOpenThemesSelection(true)}/>
		</div>
    }

   { props.profile && props.profile._id? <div title="Connect" id="all_centre_btn_grp">
			<img id="left_half_btn_bg" src="left_half_btn_bg.png" srcset="left_half_btn_bg.png 1x" />
				
			<img id="right_half_btn_bg" src="right_half_btn_bg.png" srcset="right_half_btn_bg.png 1x" />
				
			<div id="Scroll_menu_options_extra">
				<div id="dot_menu_options">
        {((connectionStatus !== "New") && (connectionStatus !== "Disconnected") && (connectionStatus === "Connected")|| (connectionStatus === "Blocked"))? "":
					<div title="Connect" id="connect_btn_grp_menu_edit_page" onClick={()=>{openConnectionModal(props.profile._id,props.profile.name)}}>
						<svg class="bdr_connect_menu_edit_page_c">
							<rect id="bdr_connect_menu_edit_page_c" rx="16" ry="16" x="0" y="0" width="120" height="32">
							</rect>
						</svg>
						<img title="Connect" id="conncet_img_connect_c" src="conncet_img_connect_c.png" srcset="conncet_img_connect_c.png 1x" />
							
						<div id="Connect_lbl_menu_edit_page_c">
							<span>Connect</span>
						</div>
					</div>
        }
        {((connectionStatus === "Connected" )|| (connectionStatus === "Following" )|| (connectionStatus === "Blocked"))? "":
					<div title="Follow" id="follow_link_grp_menu_edit_page" onClick={()=>{ConnectionReq(props.profile._id,props.profile.name,"Following")}}>
						<svg class="follow_bdr_menu_edit_page_c">
							<rect id="follow_bdr_menu_edit_page_c" rx="16" ry="16" x="0" y="0" width="120" height="32">
							</rect>
						</svg>
						<div id="Follow_lbl_menu_edit_page_da">
							<span>Follow</span>
						</div>
						<img title="Follow" id="follow_img_follow_db" src="follow_img_follow_db.png" srcset="follow_img_follow_db.png 1x" />
							
					</div>
        }
        {((connectionStatus === "Connected") || (connectionStatus === "Ignored")|| (connectionStatus === "Blocked")) ? "":
          <div title="Ignore" id="ignore_btn_grp_menu_edit_page" onClick={(e) =>{ConnectionReq(props.profile._id,props.profile.name,"Ignored")}}>
						<svg class="ignore_bdr_btn_menu_edit_page_dh">
							<rect id="ignore_bdr_btn_menu_edit_page_dh" rx="16" ry="16" x="0" y="0" width="120" height="32">
							</rect>
						</svg>
						<div id="Ignore_lbl_menu_edit_page_di">
							<span>Ignore</span>
						</div>
						<img title="Ignore" id="ignore_img_unfollow_dj" src="ignore_img_unfollow_dj.png" srcset="ignore_img_unfollow_dj.png 1x" />
    
					</div>
        }
        {(connectionStatus === "Connected")? 
        <div title="Disconnect" id="ignore_btn_grp_menu_edit_page" onClick={(e) =>{ConnectionReq(props.profile._id,props.profile.name,"Disconnected")}}>
						<svg class="ignore_bdr_btn_menu_edit_page_dh">
							<rect id="ignore_bdr_btn_menu_edit_page_dh" rx="16" ry="16" x="0" y="0" width="120" height="32">
							</rect>
						</svg>
						<div id="Ignore_lbl_menu_edit_page_di">
							<span>Disconnect</span>
						</div>
						<img title="Ignore" id="ignore_img_unfollow_dj" src="./Peoples/no-connection_Copines.png" srcset="./Peoples/no-connection_Copines.png 1x" />
    
					</div>:""
        }
        {(connectionStatus === "Blocked")? "":
					<div title="Block" id="block_btn_menu_edit_page" onClick={(e) =>{ConnectionReq(props.profile._id,props.profile.name,"Blocked")}}>
						<svg class="block_dbr_menu_edit_page_dp">
							<rect id="block_dbr_menu_edit_page_dp" rx="16" ry="16" x="0" y="0" width="120" height="32">
							</rect>
						</svg>
						<div id="Block_lbl_menu_edit_page_dq">
							<span>Block</span>
						</div>
						<img title="Block" id="img_block_menu_edit_page_dr" src="img_block_menu_edit_page_dr.png" srcset="img_block_menu_edit_page_dr.png 1x" />
							
					</div>
        }
        {(connectionStatus === "Blocked")? 
					<div title="Unblock" id="block_btn_menu_edit_page" onClick={(e) =>{ConnectionReq(props.profile._id,props.profile.name,"Blocked")}}>
						<svg class="block_dbr_menu_edit_page_dp">
							<rect id="block_dbr_menu_edit_page_dp" rx="16" ry="16" x="0" y="0" width="120" height="32">
							</rect>
						</svg>
						<div id="Block_lbl_menu_edit_page_dq">
							<span>Unblock</span>
						</div>
						<img title="Block" id="img_block_menu_edit_page_dr" src="img_block_menu_edit_page_dr.png" srcset="img_block_menu_edit_page_dr.png 1x" />
							
					</div>:""
        }
				</div>
			</div>
		</div>:""}


		<div id="all_about_mission_vision_rpifb" style={{width:props.profile && props.profile._id?"calc(100% - 500px)":""}}>
			<svg class="over_all_secter_bg">
				<rect id="over_all_secter_bg" rx="24" ry="24" x="0" y="0" width="381" height="48">
				</rect>
			</svg>
			<svg class="mark_about_hanger" style={{left:selectedTab === "mission" ? "90px":selectedTab === "vision" ? "185px":selectedTab === "principles" ? "280px":"0px"}}>
				<rect id="mark_about_hanger" rx="24" ry="24" x="0" y="0" width="103" height="48" >
				</rect>
			</svg>
		{	selectedTab === "about" ? <div id="Lorem_about_data_pc">
				<span>{introduction}</span>
			</div>:""}
      {	selectedTab === "mission" ? 
			<div id="Lorem_about_data_pc">
				<span>{mission}</span>
			</div>:""}
      {	selectedTab === "vision" ? 
			<div id="Lorem_about_data_pc">
				<span>{vision}</span>
			</div>:""}
      {	selectedTab === "principles" ? 
			<div id="Lorem_about_data_pc">
				<span>{principle}</span>
			</div>:""}
			<div id="labal_name_aoutb_grp">
				<div id="About_lbl_lorem" onClick={()=>setSelectedTab("about")}>
					<span>About</span>
				</div>
				<div id="mission_lbl_selecter" onClick={()=>setSelectedTab("mission")}>
					<span>Mission</span>
				</div>
				<div id="vision_lbl_selecter" onClick={()=>setSelectedTab("vision")}>
					<span>Vision</span>
				</div>
				<div id="princi_lbl_selecter" onClick={()=>setSelectedTab("principles")}>
					<span>Principles</span>
				</div>
			</div>
		</div>

		<div id="followers_grp_lbl">
			<svg class="overall_rec">
				<rect id="overall_rec" rx="15" ry="15" x="0" y="0" width="113" height="31">
				</rect>
			</svg>
			<svg class="Ellipse_1_circle">
				<ellipse id="Ellipse_1_circle" rx="12.5" ry="12.5" cx="12.5" cy="12.5">
				</ellipse>
			</svg>
			<div id="n_23_456789">
				<span>{followers}</span>
			</div>
			<div id="Followers_lbl">
				<span>Followers</span>
			</div>
		</div>
		<div id="skills_grp__bp">
			<img id="skill_img__bq" src="internet_website.png" srcset="internet_website.png 1x" />
				
			<div id="UIUX_designer_skill_lbl">
			<a href={"https://"+nickName+".operate.live"} target="_blank" ><span>https://{nickName.toLowerCase()}.operate.live</span></a>	
			</div>
		</div>
		{/* <div id="pen_edit_banner_grp_pc">
			<img id="pen_edit_banner_img_pc" src="pen_edit_banner_img.png" srcset="pen_edit_banner_img.png 1x" />
				
		</div> */}
		{/* <div id="Mohammed_Affan_Ghani_lbl_nickn">
			<span>({nickName})</span>
		</div> */}
    { props.profile && props.profile._id?"": <div id="pen_edit_banner_grp_bv">
    <label for="banner">
                        <input id="banner" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleBannnerUpload(e)}/>
                    
                      <img id="pen_edit_banner_img_bw" width="100" height="100" src={"pen_edit_banner_img_bw.png"} />
                      </label>
			{/* <img id="pen_edit_banner_img_bw" src="pen_edit_banner_img_bw.png" srcset="pen_edit_banner_img_bw.png 1x" onClick={()=>alert("hi")}/> */}
		</div>}
	</div>
</div>
     
<SuggestionPageIntroPopUp  openSuggestionModal={openIntroModal}  introData={introData} closeSuggestionModal={()=>setOpenIntroModal(false)}/>
<ThemesSetUpPc  openThemesSelection={openThemesSelection} theme={selectedTheme} closeThemesSelection={()=>{setOpenThemesSelection(false);getUserProfile()}}/>
    </div>
  );
};

export default UserInfoUI;
