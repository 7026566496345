import React from 'react';
import "../../../styles/SweetTreat.css";

function SweetTreat(props) {
  return (
    <div id="SweetTreat">
	<div id="STCGroup_15101">
		<div id="STCGroup_15041">
			<div id="STCGroup_15040">
				<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="STCRectangle_737">
					<rect id="STCRectangle_737" rx="0" ry="0" x="0" y="0" width="538" height="302">
					</rect>
				</iframe>
			</div>
			<div id="STCsocials_grp_shadow_space_websi">
{props.data.linkedInUrl?
				<div title="LinkedIn" id="STClinked_in_grp_shadow_space_web">
					<svg class="STCmed_shadow_space_website_7">
						<ellipse id="STCmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<div id="STClinked_in_shadow_space_website">
						<a href={props.data.linkedInUrl} target="_blank">
						<svg class="STCn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
							<path id="STCn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
							</path>
						</svg>
					</a>
						<svg class="STClinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
							<path id="STClinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
							</path>
						</svg>
						<svg class="STCtop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
							<path id="STCtop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
							</path>
						</svg>
					</div>
				</div>
:""}
{props.data.instagramUrl?
				<div title="Instagram" id="STCInsta_grp_shadow_space_website">
					<svg class="STCshadow_space_website_7">
						<ellipse id="STCshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={props.data.instagramUrl} target="_blank">
					<img id="STCinstagramshadow_space_website_" src="./Themes/SweetTreat/instagramshadow_space_website_.png" srcset="./Themes/SweetTreat/instagramshadow_space_website_.png 1x" />
						
					</a>
				</div>:""}
{props.data.whatsAppNumber?

				<div title="WhatsApp" id="STCwhatsapp_grp_shadow_space_webs">
					<svg class="STCmed_shadow_space_website_7_bl">
						<ellipse id="STCmed_shadow_space_website_7_bl" rx="24" ry="24" cx="24" cy="24">
						</ellipse>
					</svg>
					<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
<img id="STCwhatsapp_lbl_shadow_space_webs" src="./Themes/SweetTreat/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/SweetTreat/whatsapp_lbl_shadow_space_webs.png 1x" />
</a>						
				</div>:""}
			</div>
			<div id="STCn_234567890_mobile_number_shad">
				<span>{props.data.businessPhone}</span>
			</div>
			<div id="STCaffanghanioperatelive_email_sh">
				<span>{props.data.businessEmail}</span>
			</div>
			<div id="STCaddress_med_shadow_space_websi">
				<span>{props.data.businessAddress}</span>
			</div>
			<img id="STClocation_img_shadow_space_webs" src="./Themes/SweetTreat/location_img_shadow_space_webs.png" srcset="./Themes/SweetTreat/location_img_shadow_space_webs.png 1x" />
				
			<img id="STCemail_address_shadow_space_web" src="./Themes/SweetTreat/email_address_shadow_space_web.png" srcset="./Themes/SweetTreat/email_address_shadow_space_web.png 1x" />
				
			<img id="STCphone_number_shadow_space_webs" src="./Themes/SweetTreat/phone_number_shadow_space_webs.png" srcset="./Themes/SweetTreat/phone_number_shadow_space_webs.png 1x" />
				
			<div id="STCn_Copyright_Operate_Live_OPC_P">
				<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
			</div>
		</div>
		<div id="STCContact_Us">
			<span>Contact Us!</span>
		</div>
	</div>
	<svg class="STCEllipse_44">
		<ellipse id="STCEllipse_44" rx="32" ry="0.5" cx="32" cy="0.5">
		</ellipse>
	</svg>
	<div id="STCGroup_15135">
		<div id="STCGroup_15126">
			<img id="STCshree-iyer-8vzLN4oHcGQ-unsplas" src="./Themes/SweetTreat/shree-iyer-8vzLN4oHcGQ-unsplas.png" srcset="./Themes/SweetTreat/shree-iyer-8vzLN4oHcGQ-unsplas.png 1x" />
				
			<div id="STCScroll_Group_28">
				<div id="STCIt_is_a_long_established_fact_">
					<span>{props.data.vision}</span>
				</div>
			</div>
			<div id="STCVISION">
				<span>VISION</span>
			</div>
		</div>
		<div id="STCGroup_15125">
			<img id="STCaditya-joshi-uWI48cpw7Vo-unspl" src="./Themes/SweetTreat/aditya-joshi-uWI48cpw7Vo-unspl.png" srcset="./Themes/SweetTreat/aditya-joshi-uWI48cpw7Vo-unspl.png 1x" />
				
			<div id="STCScroll_Group_88">
				<div id="STCIt_is_a_long_established_fact__b">
					<span>{props.data.mission}</span>
				</div>
			</div>
			<div id="STCMISSION">
				<span>MISSION</span>
			</div>
		</div>
		<div id="STCGroup_15127">
			<img id="STCsanju-m-gurung-W1qp7p_laj0-uns" src="./Themes/SweetTreat/sanju-m-gurung-W1qp7p_laj0-uns.png" srcset="./Themes/SweetTreat/sanju-m-gurung-W1qp7p_laj0-uns.png 1x" />
				
			<div id="STCScroll_Group_89">
				<div id="STCIt_is_a_long_established_fact__ca">
					<span>{props.data.principles}</span>
				</div>
			</div>
			<div id="STCPRINCIPLES">
				<span>PRINCIPLES</span>
			</div>
		</div>
	</div>
	<div id="STCGroup_15156">
		<img id="STCstefano-zocca-1p-Mx-SaLT0-unsp" src="./Themes/SweetTreat/stefano-zocca-1p-Mx-SaLT0-unsp.png" srcset="./Themes/SweetTreat/stefano-zocca-1p-Mx-SaLT0-unsp.png 1x" />
			
		<svg class="STCRectangle_755">
			<rect id="STCRectangle_755" rx="0" ry="0" x="0" y="0" width="1366" height="663">
			</rect>
		</svg>
		<div id="STCIndulge_in_a_world_of_sweetnes">
			<span>"Indulge in a world of sweetness, <br/>where every bite is a moment of <br/>pure delight."</span>
		</div>
		<img id="STCAnime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/SweetTreat/Anime_Edit.png"} />
			
		<div id="STCYour_Business_Name">
			<span>{props.data.businessName}</span>
		</div>
		<div id="STCGroup_15157">
			<svg class="STCPath_7785" viewBox="-1627.995 -10724.327 29.202 59.657">
				<path id="STCPath_7785" d="M -1627.9951171875 -10724.3271484375 L -1598.79345703125 -10724.3271484375 L -1598.79345703125 -10664.669921875">
				</path>
			</svg>
			<svg class="STCPath_7786" viewBox="-1627.995 -10739.621 29.202 57.011">
				<path id="STCPath_7786" d="M -1598.79345703125 -10682.6103515625 L -1627.9951171875 -10682.6103515625 L -1627.9951171875 -10739.62109375">
				</path>
			</svg>
			<svg class="STCIcon_awesome-arrow-down" viewBox="0.397 2.25 27.916 28.642">
				<path id="STCIcon_awesome-arrow-down" d="M 26.44350814819336 14.42771434783936 L 27.86264419555664 15.84684944152832 C 28.46354103088379 16.44774436950684 28.46354103088379 17.41940307617188 27.86264419555664 18.01390647888184 L 15.44201564788818 30.44092750549316 C 14.84111976623535 31.04182434082031 13.869460105896 31.04182434082031 13.27495861053467 30.44092750549316 L 0.8479369878768921 18.01390647888184 C 0.2470418959856033 17.41300964355469 0.2470418959856033 16.44135093688965 0.8479369878768921 15.846848487854 L 2.267072200775146 14.42771244049072 C 2.874359846115112 13.82042503356934 3.86519718170166 13.83320999145508 4.459700107574463 14.45328235626221 L 11.79829025268555 22.15624809265137 L 11.79829025268555 3.784200191497803 C 11.79829025268555 2.933997631072998 12.4822883605957 2.25 13.33249092102051 2.25 L 15.37809085845947 2.25 C 16.22829246520996 2.25 16.91229057312012 2.933997631072998 16.91229057312012 3.784200191497803 L 16.91229057312012 22.15624809265137 L 24.25088119506836 14.45328521728516 C 24.84538459777832 13.82682037353516 25.83622169494629 13.8140344619751 26.44350814819336 14.42771530151367 Z">
				</path>
			</svg>
		</div>
	</div>
	<div id="STCOur_Greatest_Drinks">
		<span>Our Greatest Sweets</span>
	</div>
	<div id="STCScroll_Group_90">
		<div id="STCGroup_15128">
			<svg class="STCRectangle_750_cq">
				<linearGradient id="STCRectangle_750_cq" spreadMethod="pad" x1="-0.871" x2="1" y1="-0.401" y2="1">
					<stop offset="0" stop-color="#eba163" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="STCRectangle_750_cq" rx="10" ry="10" x="0" y="0" width="253" height="317">
				</rect>
			</svg>
			<img id="STCprchi-palwe-wwgulilpmlg-unspla" src="./Themes/SweetTreat/prchi-palwe-wwgulilpmlg-unspla.png" srcset="./Themes/SweetTreat/prchi-palwe-wwgulilpmlg-unspla.png 1x" />
				
			<div id="STCJuice_1">
				<span>Laddu</span>
			</div>
		</div>
	</div>
</div>
  )
}

export default SweetTreat