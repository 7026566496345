import React, { useEffect, useState, useContext} from 'react'
import PeoplesSuggestionsUI from './PeoplesSuggestionsUI';
import PeoplesRequestsUI from './PeoplesRequestsUI';
import PeoplesConnectionsUI from './PeoplesConnectionsUI';
//import '../../styles/PeoplesUI.css'

const PeoplesUI = () => {

    const [selectedTab,setSelectedTab] = useState("Connections");

  return (
    <div>

    {selectedTab==="Connections" ?  <PeoplesConnectionsUI selectedTab={(val)=>setSelectedTab(val)}/> :""}
      {selectedTab==="Requests" ? <PeoplesRequestsUI selectedTab={(val)=>setSelectedTab(val)}/>  :""}
     {selectedTab==="Suggestions" ?  <PeoplesSuggestionsUI selectedTab={(val)=>setSelectedTab(val)}/>:""}

   </div>
  )
}

export default PeoplesUI
