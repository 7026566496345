import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/CommunitiesConnectedMembersNewMobUI.css';
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import AddCommunitiesConMemPopUP from '../AddCommunitiesConMemPopUp';
import FooterPcUI from '../PC/FooterPcUI';
import MyCommunitiesNewUI from '../PC/MyCommunitiesNewUI';


const CommunitiesConnectedMembersNewMobUI = (props) => {

  var navigate = useNavigate();
  const { user, setUser} = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [requirements,setRequirements]=useState([]);
  const [ offerings,setOfferings] = useState([]);
  const [allStatus,setAllStatus] = useState([]);
  const [checked, setChecked] = React.useState([true, false]);
  const [openGridModel,setOpenGridModel] = useState(false);
  const [openGridOfferingModel,setOpenGridOfferingModel] = useState(false);
  const [openGridConnectionModel,setOpenGridConnectionModel] = useState(false);
  const [oddRequirements,setOddRequirements] = useState([]);
  const [oddOfferings,setOddOfferings] = useState([]);
  const [oddConnections,setOddConnections] = useState([]);
  const [communityId,setCommunityId] = useState("")
  const [communityName,setCommunityName] = useState("")

// //UseEffect

//UseEffects
useEffect(()=>{
    if(user){
		if(props.communityId){
			setCommunityId(props.communityId);
			
			getStatus()
		}
		if(props.communityName){
			setCommunityName(props.communityName);
		}	
    }
    },[props.communityId])




	//Get Connection List
	const getStatus = ()=>{
		
		let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatusbycommunity/" + user._id;
		let apiParams = {
		  method: "GET",
		  headers:{
			communityid:props.communityId,
		  }
		};
		fetch(apiURL, apiParams)
		  .then((res) => (res.status === 200 ? res.json() : res.text()))
		  .then((repos) => {
			if (repos.data) {
				
				setAllStatus(repos.data)
				
			} else alert(repos.message || repos);
		  })
		  .catch((err) => alert(err));
	  };


      //Converting Image
function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

  const openGridConnectionModal = ()=>{
    setOpenGridConnectionModel(true)
  }

  const RemoveConnection = (connectionId)=>{
	
    let apiURL=process.env.REACT_APP_API_URL + "/connection/removeConnectionsbycommunity/"+ connectionId._id ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:communityId,
            userid:user._id,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
	
			getStatus()

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  return (
    <div>

<div id="community_mem_mob">
	<img id="com_mem_bg_img_mob" src="com_mem_bg_mid_new.png" srcset="com_mem_bg_mid_new.png 1x" />
		
	<div id="mid_scroll_com_mem_mob_new">
  {allStatus.map((com)=>(
		<div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
		<div id="card_grp_over_all_com_mem_new">
			<svg class="community_mem_page_card_new" onClick={()=>navigate("../"+(com.user1Name===user.name ? com.user2Image.nickName:com.user1Image.nickName))}>
				<rect id="community_mem_page_card_new" rx="13" ry="13" x="0" y="0" width="90%" height="89">
				</rect>
			</svg>
			<img id="community_mem_page_mob_new" src={com.user1Name===user.name ?  (com.user2Image.imageUrl && com.user2Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(com.user2Image.imageUrl.split('/')[5]):"user_pfp_profile_page.png":(com.user1Image.imageUrl && com.user1Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(com.user1Image.imageUrl.split('/')[5]):"user_pfp_profile_page.png"}   />
				
			<div id="community_mem_page_new">
				<span>{com.user1Name===user.name ? com.user2Name:com.user1Name}</span>
			</div>
			<svg class="community_mem_page_card_design_new" viewBox="17.761 54.55 117.404 28.566">
				<path id="community_mem_page_card_design_new" d="M 17.93937301635742 72.22697448730469 L 54.18844604492188 83.11607360839844 C 54.18844604492188 83.11607360839844 55.80867004394531 78.82169342041016 59.8450927734375 74.31443023681641 C 62.31302261352539 71.55460357666016 65.82218933105469 67.43408966064453 74.36148071289062 66.66352081298828 C 77.51936340332031 66.37856292724609 81.06272125244141 66.79751586914062 83.42662048339844 67.50247192382812 C 84.86332702636719 67.92860412597656 87.85828399658203 69.11642456054688 89.34918975830078 69.92697143554688 C 98.34956359863281 74.81540679931641 100.6986465454102 83.11607360839844 100.6986465454102 83.11607360839844 L 111.5691680908203 79.64503479003906 L 134.8008728027344 72.22697448730469 C 134.8008728027344 72.22697448730469 138.4102020263672 53.33117294311523 122.8901214599609 54.61224365234375 C 107.3700561523438 55.89331817626953 27.96507453918457 54.61224365234375 27.96507453918457 54.61224365234375 C 27.96507453918457 54.61224365234375 21.55619812011719 54.73288345336914 18.70760536193848 60.51025772094727 C 17.75597381591797 62.43505477905273 17.56718254089355 65.52426147460938 17.93937301635742 69.37132263183594 C 18.33065032958984 73.41566467285156 17.89220428466797 71.28224182128906 17.93937301635742 72.22697448730469 C 18.02732086181641 73.85850524902344 17.93937301635742 72.22697448730469 17.93937301635742 72.22697448730469 Z">
				</path>
			</svg>
			<img id="com_mem_minus_new" src="com_mem_minus_new_.png" srcset="com_mem_minus_new_.png 1x" onClick={()=>RemoveConnection(com)}/>
				
			<img title="Profession" id="com_mem_dget_png_new" src="com_mem_dget_new.png" srcset="com_mem_dget_new.png 1x" />
				
			<img title="Joined" id="com_mem_delivery_png_new" src="com_mem_delivery_new.png" srcset="com_mem_delivery_new.png 1x" />
				
			<div id="com_mem_budget_txt_new">
				<span>{com.updatedAt && (com.updatedAt).substring(0,10)}</span>
			</div>
			<div id="com_mem_dep_txt_new">
				<span>₹ 1233 (INR) / Hr</span>
			</div>
			<img title="Joined" id="com_mem_location_png_new" src="com_mem_location_new.png" srcset="com_mem_location_new.png 1x" />
				
			<div id="com_mem__timg_txt_new">
				<span>{com.user1Name===user.name ? com.user2Image? (com.user2Image.profession):com.user1Image ?com.user1Image.profession:"":""}</span>
			</div>
		</div>
    </div>
	))}
	</div>

	<div id="community_mem_side_grp">
	<img id="com_mem_bg_side_menu" src="com_mem_bg_side_menu.png" srcset="com_mem_bg_side_menu.png 1x" />
		
	<div id="mem_page_grp_right_new">
		<div id="com_mem_req_txt_new" onClick={()=>props.selectedTab('requirements')}>
			<span>Requirement</span>
		</div>
		<div id="com_mem_off_txt_new" onClick={()=>props.selectedTab('offerings')}>
			<span>Offerings</span>
		</div>
		<div id="com_mem_mem_txt_new">
			<span>Members</span>
		</div>
		<div id="add_btn_com_mem_mob_new" onClick={()=>openGridConnectionModal()}>
			<svg class="bdr_plus_btn_new">
				<ellipse id="bdr_plus_btn_new" rx="9.25140380859375" ry="9.25140380859375" cx="9.25140380859375" cy="9.25140380859375">
				</ellipse>
			</svg>
			<img id="plus_btn_mem_new" src="plus_btn_mem_new.png" srcset="plus_btn_mem_new.png 1x" />
				
		</div>
	</div>
</div>
	
</div>


  <AddCommunitiesConMemPopUP communityId={props.communityId} communityName={props.communityName} openComMem ={openGridConnectionModel} closeComMem ={()=>{setOpenGridConnectionModel();getStatus()}}/>

 </div>
  )
}

export default CommunitiesConnectedMembersNewMobUI
