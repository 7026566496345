import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioBigReqCard.css';
import { UserContext } from '../UserContext';

const MyPortfolioBigReqCard = (props) => {
	
	const {user} = useContext(UserContext);
	 
	const handleLike = (reqId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/requirement/updatetrophy/"+reqId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
		  };
  return (

	<div id="big_1_trending_req_Pc">
	<svg class="bdr_all_trending_req_Pc">
		<rect id="bdr_all_trending_req_Pc" rx="10" ry="10" x="0" y="0" width="540" height="680">
		</rect>
	</svg>
	<svg class="bdr_amount_trending_req_Pc">
		<rect id="bdr_amount_trending_req_Pc" rx="17" ry="17" x="0" y="0" width="90" height="34">
		</rect>
	</svg>
	<div id="n_9_trending_req_Pc">
		<span>{props.data.currency && JSON.parse(props.data.currency).symbol} {props.data.budget}</span>
	</div>
	<img id="Image_trending__req_Pc" src={props.data.defaultRequirementsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultRequirementsImageUrl.split('/')[5]):"./MyPortfolio/Requirements/Image_trending__off_PC.png"}  />
		
	<div id="Nike_Air_Force_1_Sha_trending_req_Pc">
		<span>{props.data.requirementTitle}</span>
	</div>
	<div id="Single_Tour_trending_req_Pc">
		<span>{( props.data.deliveryDeadline ? (props.data.deliveryDeadline).substring(0,10):"")} | {props.data.deliveryLocation}</span>
	</div>
	<svg class="trohpie_bdr_small_7_trending_req_Pc">
		<ellipse id="trohpie_bdr_small_7_trending_req_Pc" rx="19" ry="19" cx="19" cy="19">
		</ellipse>
	</svg>
	<img id="share_1_reqoffevent_req_Pc" src="./MyPortfolio/Requirements/share_1_reqoffevent_off_PC_dd.png" srcset="./MyPortfolio/Requirements/share_1_reqoffevent_off_PC_dd.png 1x" />
		
	<svg class="likes_trending_req_Pc">
		<rect id="likes_trending_req_Pc" rx="0" ry="0" x="0" y="0" width="39" height="21">
		</rect>
	</svg>
	<svg class="bdr_1_trending_req_Pc">
		<ellipse id="bdr_1_trending_req_Pc" rx="19" ry="18.5" cx="19" cy="18.5">
		</ellipse>
	</svg>
	<img id="trophy_trending_req_Pc" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(props.data._id)} />
		
	<div id="n_234_like_trending_req_Pc">
		<span>{(JSON.parse(props.data.trophies)).length}</span>
	</div>
</div>
  
  )
}

export default MyPortfolioBigReqCard
