import React from 'react';
import "../../../styles/SoleCrafted.css";

function SoleCrafted(props) {
  return (
    <div id="SoleCrafted">
        <div id="SC_32_PcGroup_15101">
            <div id="SC_32_PcGroup_15041">
                <div id="SC_32_PcGroup_15040">
                    <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="SC_32_PcRectangle_737">
                        <rect id="SC_32_PcRectangle_737" rx="0" ry="0" x="0" y="0" width="538" height="302">
                        </rect>
                    </iframe>
                </div>
                <div id="SC_32_Pcsocials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
                    <div title="LinkedIn" id="SC_32_Pclinked_in_grp_shadow_space_web">
                        <svg class="SC_32_Pcmed_shadow_space_website_7">
                            <ellipse id="SC_32_Pcmed_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
                        <div id="SC_32_Pclinked_in_shadow_space_website">
					<a href={props.data.linkedInUrl} target="_blank">
                            <svg class="SC_32_Pcn_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
                                <path id="SC_32_Pcn_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
                                </path>
                            </svg>
                        </a>
                            <svg class="SC_32_Pclinked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
                                <path id="SC_32_Pclinked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
                                </path>
                            </svg>
                            <svg class="SC_32_Pctop_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
                                <path id="SC_32_Pctop_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
                                </path>
                            </svg>
                        </div>
                    </div>:""}
			{props.data.instagramUrl?	
                    <div title="Instagram" id="SC_32_PcInsta_grp_shadow_space_website">
                        <svg class="SC_32_Pcshadow_space_website_7">
                            <ellipse id="SC_32_Pcshadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
				<a href={props.data.instagramUrl} target="_blank">
                        <img id="SC_32_Pcinstagramshadow_space_website_" src="./Themes/SoleCrafted/instagramshadow_space_website_.png" srcset="./Themes/SoleCrafted/instagramshadow_space_website_.png 1x" />
                            
                        </a>
                    </div>:""}
			{props.data.whatsAppNumber?
                    <div title="WhatsApp" id="SC_32_Pcwhatsapp_grp_shadow_space_webs">
                        <svg class="SC_32_Pcmed_shadow_space_website_7_bf">
                            <ellipse id="SC_32_Pcmed_shadow_space_website_7_bf" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
                        <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="SC_32_Pcwhatsapp_lbl_shadow_space_webs" src="./Themes/SoleCrafted/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/SoleCrafted/whatsapp_lbl_shadow_space_webs.png 1x" />
                        </a>    
                    </div>:""}
                </div>
                <div id="SC_32_Pcn_234567890_mobile_number_shad">
                    <span>{props.data.businessPhone}</span>
                </div>
                <div id="SC_32_Pcaffanghanioperatelive_email_sh">
                    <span>{props.data.businessEmail}</span>
                </div>
                <div id="SC_32_Pcaddress_med_shadow_space_websi">
                    <span>{props.data.businessAddress}</span>
                </div>
                <img id="SC_32_Pclocation_img_shadow_space_webs" src="./Themes/SoleCrafted/location_img_shadow_space_webs.png" srcset="./Themes/SoleCrafted/location_img_shadow_space_webs.png 1x" />
                    
                <img id="SC_32_Pcemail_address_shadow_space_web" src="./Themes/SoleCrafted/email_address_shadow_space_web.png" srcset="./Themes/SoleCrafted/email_address_shadow_space_web.png 1x" />
                    
                <img id="SC_32_Pcphone_number_shadow_space_webs" src="./Themes/SoleCrafted/phone_number_shadow_space_webs.png" srcset="./Themes/SoleCrafted/phone_number_shadow_space_webs.png 1x" />
                    
                <div id="SC_32_Pcn_Copyright_Operate_Live_OPC_P">
                    <span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
                </div>
            </div>
            <div id="SC_32_PcContact_Us">
                <span>Contact Us!</span>
            </div>
        </div>
        <div id="SC_32_PcGroup_15140">
            <svg class="SC_32_PcEllipse_44">
                <ellipse id="SC_32_PcEllipse_44" rx="32" ry="0.5" cx="32" cy="0.5">
                </ellipse>
            </svg>
            <div id="SC_32_PcYour_Name">
                <span>{props.data.businessName}</span>
            </div>
            <div id="SC_32_PcDiscover_Timeless__Craftsmansh">
                <span>"Discover Timeless <br/>Craftsmanship and <br/>Unparalleled Comfort: <br/>Your Exquisite Footwear <br/>Journey Starts Here, Where <br/>Leather Meets Luxury!"</span>
            </div>
            <img id="SC_32_Pcnoah-smith-R4xOiCIeOUs-unsplas" src="./Themes/SoleCrafted/noah-smith-R4xOiCIeOUs-unsplas.png" srcset="./Themes/SoleCrafted/noah-smith-R4xOiCIeOUs-unsplas.png 1x" />
                
            <img id="SC_32_Pcalexandr-sadkov-BnG4KWAzt9c-un" src="./Themes/SoleCrafted/alexandr-sadkov-BnG4KWAzt9c-un.png" srcset="./Themes/SoleCrafted/alexandr-sadkov-BnG4KWAzt9c-un.png 1x" />
                
            <img id="SC_32_Pclea-ochel-nsRBbE6-YLs-unsplash" src="./Themes/SoleCrafted/lea-ochel-nsRBbE6-YLs-unsplash.png" srcset="./Themes/SoleCrafted/lea-ochel-nsRBbE6-YLs-unsplash.png 1x" />
                
            <img id="SC_32_Pcrajesh-rajput-NqZ2vFqChaw-unsp" src="./Themes/SoleCrafted/rajesh-rajput-NqZ2vFqChaw-unsp.png" srcset="./Themes/SoleCrafted/rajesh-rajput-NqZ2vFqChaw-unsp.png 1x" />
                
        </div>
        <div id="SC_32_PcGroup_15141">
            <svg class="SC_32_PcRectangle_752">
                <rect id="SC_32_PcRectangle_752" rx="10" ry="10" x="0" y="0" width="915" height="376">
                </rect>
            </svg>
            <img id="SC_32_Pcty-feague-87V27nw0sS0-unsplash" src="./Themes/SoleCrafted/ty-feague-87V27nw0sS0-unsplash.png" srcset="./Themes/SoleCrafted/ty-feague-87V27nw0sS0-unsplash.png 1x" />
                
            <div id="SC_32_PcScroll_Group_28">
                <div id="SC_32_PcIt_is_a_long_established_fact_">
                    <span>{props.data.vision}</span>
                </div>
            </div>
            <div id="SC_32_PcVISION">
                <span>VISION</span>
            </div>
        </div>
        <div id="SC_32_PcGroup_15142">
            <svg class="SC_32_PcRectangle_752_b">
                <rect id="SC_32_PcRectangle_752_b" rx="10" ry="10" x="0" y="0" width="915" height="376">
                </rect>
            </svg>
            <img id="SC_32_Pcprateek-chawla-87luO7iL1mM-uns" src="./Themes/SoleCrafted/prateek-chawla-87luO7iL1mM-uns.png" srcset="./Themes/SoleCrafted/prateek-chawla-87luO7iL1mM-uns.png 1x" />
                
            <div id="SC_32_PcScroll_Group_28_b">
                <div id="SC_32_PcIt_is_a_long_established_fact__b">
                    <span>{props.data.mission}</span>
                </div>
            </div>
            <div id="SC_32_PcMISSION">
                <span>MISSION</span>
            </div>
        </div>
        <div id="SC_32_PcGroup_15143">
            <svg class="SC_32_PcRectangle_752_ca">
                <rect id="SC_32_PcRectangle_752_ca" rx="10" ry="10" x="0" y="0" width="915" height="376">
                </rect>
            </svg>
            <img id="SC_32_Pcnoah-smith-1z2hBTKHdWI-unsplas" src="./Themes/SoleCrafted/noah-smith-1z2hBTKHdWI-unsplas.png" srcset="./Themes/SoleCrafted/noah-smith-1z2hBTKHdWI-unsplas.png 1x" />
                
            <div id="SC_32_PcScroll_Group_28_cc">
                <div id="SC_32_PcIt_is_a_long_established_fact__cd">
                    <span>{props.data.principles}</span>
                </div>
            </div>
            <div id="SC_32_PcPRINCIPLES">
                <span>PRINCIPLES</span>
            </div>
        </div>
        <div id="SC_32_PcNEW_EDITION">
            <span>NEW EDITION</span>
        </div>
        <div id="SC_32_PcGroup_15145">
            <svg class="SC_32_PcRectangle_753">
                <rect id="SC_32_PcRectangle_753" rx="10" ry="10" x="0" y="0" width="185" height="61">
                </rect>
            </svg>
            <div id="SC_32_PcView_More">
                <span>View More</span>
            </div>
        </div>
        <div id="SC_32_PcScroll_Group_91">
	{props.offeringsData.map((off,idx)=>(
            <div id="SC_32_PcGroup_15144">
                <svg class="SC_32_PcRectangle_754">
                    <rect id="SC_32_PcRectangle_754" rx="10" ry="10" x="0" y="0" width="243" height="331">
                    </rect>
                </svg>
                <img id="SC_32_Pcmostafa-mahmoudi-3OZr-hLbsq0-u" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/SoleCrafted/mostafa-mahmoudi-3OZr-hLbsq0-u.png"} />
                    
                <div id="SC_32_PcTitle">
                    <span>{off.offeringTitle}</span>
                </div>
                <div id="SC_32_Pcn_10000">
                    <span>₹ {off.budget}</span>
                </div>
            </div>))}
        </div>
    </div>
  )
}

export default SoleCrafted