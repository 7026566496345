import React, { useState, useEffect, useContext } from "react";
import { UserContext } from './UserContext';
import '../styles/TopNavBarUI.css';
import {useNavigate} from "react-router-dom";
import EditProfilePopUpPc from "./PC/EditProfilePopUpPc";



const TopNavBarUI = (props) => {

//Usestate 
// const [sideNavBar,setSideNavBar] = useState(false);
const { user, setUser } = useContext(UserContext);
var navigate = useNavigate();
const [ img,setImg] = useState("");
const [editPopUp,setEditPopUp] = useState(false)


useEffect(()=>{
if(user){
	getUserProfile()
   }
},[user])

const handlelogout = () =>{
    localStorage.clear();
    setUser(null);
    window.location.href = "/login";
  }


  const getUserProfile = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/getUserProfile/"+user._id;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
        //console.log(repos.data)
        //   setImg(arrayBufferToBase64(repos.data.image && repos.data.image.data.data));
          setImg(repos.data.imageUrl);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

  const handleOpen =() =>{

	setEditPopUp(true)
  }

  return (
    <div>
   <div id="top_bar">
	<svg class="mid_op_design">
		<rect id="mid_op_design" rx="0" ry="0" x="0" y="0" width="100%" height="59">
		</rect>
	</svg>
	<div id="left_grp_op">
		<svg class="bg_path" viewBox="2037.855 2388 363.145 58.662">
			<path id="bg_path" d="M 2401 2388 L 2037.916748046875 2388 C 2037.916748046875 2388 2034.292236328125 2445.914306640625 2082.4443359375 2446.43212890625 C 2130.596435546875 2446.949951171875 2401 2446.43212890625 2401 2446.43212890625 L 2401 2388 Z">
			</path>
		</svg>
		<input placeholder="Seach..." class="seacch_input_op"  />
		
		<img id="search_img_" src="search.png" srcset="search.png 1x" />
			
		<svg class="bg_deisng_2_op" viewBox="2037.855 2388 363.145 58.662">
			<path id="bg_deisng_2_op" d="M 2401 2388 L 2037.916748046875 2388 C 2037.916748046875 2388 2034.292236328125 2445.914306640625 2082.4443359375 2446.43212890625 C 2130.596435546875 2446.949951171875 2401 2446.43212890625 2401 2446.43212890625 L 2401 2388 Z">
			</path>
		</svg>
	</div>
	<div id="right_grp_op">
		<svg class="bg_design_1_op" viewBox="2037.854 2388 363.145 58.662">
			<path id="bg_design_1_op" d="M 2037.8544921875 2388 L 2400.9375 2388 C 2400.9375 2388 2404.56201171875 2445.914306640625 2356.41015625 2446.43212890625 C 2308.258056640625 2446.949951171875 2037.8544921875 2446.43212890625 2037.8544921875 2446.43212890625 L 2037.8544921875 2388 Z">
			</path>
		</svg>
    <a href="tel:+919019050190">
		<img class="Icon_material-message" src="customer-support_top_nav.png"  />
    </a>
	    <img class="Icon_material-notifications-ac" src="notification_top_navbar.png" />
	
		<img class="Icon_awesome-user-friends" src="friends_top_navbar.png" />
	
		<img id="profile_op_pfp" src={user.imageId && user.imageId.length > 10?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+user.imageId:"user_pfp_profile_page.png"}  onClick={()=>handleOpen()} />
			
	</div>
</div>
{editPopUp=== true?
<EditProfilePopUpPc openPopup={editPopUp} closePopUp={()=>setEditPopUp(false)}/>
:""}
    </div>
  )
}

export default TopNavBarUI
