import React from 'react';
import "../../../styles/PlantPlatesMob.css";

function PlantPlatesMob(props) {
  return (
    <div id="PlantPlateDelights_Mob">
      <div id="PPD_MoB_26Group_15098">
        <img id="PPD_MoB_26Mask_Group_1" src="./Themes/PlantPlates/Mask_Group_1.png" srcset="./Themes/PlantPlates/Mask_Group_1.png 1x" />
          
        <div id="PPD_MoB_26Group_15092">
          <svg class="PPD_MoB_26Rectangle_3">
            <rect id="PPD_MoB_26Rectangle_3" rx="17.5" ry="17.5" x="0" y="0" width="127" height="35">
            </rect>
          </svg>
          <div id="PPD_MoB_26Order_Now">
            <span>Order Now</span>
          </div>
        </div>
        <div id="PPD_MoB_26Have_no_time_to_prepare_food">
          <span>Have no time<br/>to prepare</span><span style={{color:"rgba(248,162,47,1)"}}> </span><span style={{color:"rgba(103,123,200,1)"}}>food</span><span>?</span>
        </div>
        <div id="PPD_MoB_26Choose_one_of_our_plans_enter_">
          <span>Choose one of our plans, enter delivery time<br/>and enjoy delicious food without leaving your home!</span>
        </div>
        <div id="PPD_MoB_26FOODCOURT">
          <span>FOOD.</span><span style={{color:"rgba(103,123,200,1)"}}>COURT</span>
        </div>
      </div>
      <div id="PPD_MoB_26Group_15099">
        <img id="PPD_MoB_26Mask_Group_2" src="./Themes/PlantPlates/Mask_Group_2.png" srcset="./Themes/PlantPlates/Mask_Group_2.png 1x" />
          
        <div id="PPD_MoB_26Group_15093">
          <div id="PPD_MoB_26Group_4">
            <svg class="PPD_MoB_26Rectangle_6">
              <rect id="PPD_MoB_26Rectangle_6" rx="21" ry="21" x="0" y="0" width="151" height="42">
              </rect>
            </svg>
            <div id="PPD_MoB_26Order_Now_bi">
              <span>Order Now</span>
            </div>
          </div>
          <div id="PPD_MoB_26Group_12">
            <svg class="PPD_MoB_26Line_2" viewBox="0 0 164.372 7">
              <path id="PPD_MoB_26Line_2" d="M 0 0 L 164.3724365234375 0">
              </path>
            </svg>
            <div id="PPD_MoB_26OUR_FOOD">
              <span>OUR FOOD</span>
            </div>
          </div>
          <div id="PPD_MoB_26Choose_one_of_our_plans_enter__bm">
            <span>Choose one of our plans, enter delivery time<br/>and enjoy delicious food without leaving your home!</span>
          </div>
          <div id="PPD_MoB_26Choose_one_of_our_plans_enter__bn">
            <span>Choose one of our plans, enter delivery time.</span>
          </div>
          <div id="PPD_MoB_26Choose_one_of_our_plans_enter__bo">
            <span>Choose one of our plans, enter delivery time.</span>
          </div>
          <div id="PPD_MoB_26Group_9">
            <div id="PPD_MoB_26Appetizers">
              <span>Appetizers:</span>
            </div>
            <div id="PPD_MoB_26Group_6">
              <svg class="PPD_MoB_26Ellipse_2">
                <ellipse id="PPD_MoB_26Ellipse_2" rx="18.863571166992188" ry="18.863571166992188" cx="18.863571166992188" cy="18.863571166992188">
                </ellipse>
              </svg>
              <div id="PPD_MoB_26Group_5">
                <svg class="PPD_MoB_26Line_3" viewBox="0 0 14.103 4">
                  <path id="PPD_MoB_26Line_3" d="M 0 0 L 14.10341835021973 0">
                  </path>
                </svg>
                <svg class="PPD_MoB_26Line_4" viewBox="0 0 14.103 4">
                  <path id="PPD_MoB_26Line_4" d="M 0 0 L 14.10341835021973 0">
                  </path>
                </svg>
              </div>
            </div>
          </div>
          <div id="PPD_MoB_26Group_10">
            <div id="PPD_MoB_26Main_Courses">
              <span>Main Courses:</span>
            </div>
            <div id="PPD_MoB_26Group_7">
              <svg class="PPD_MoB_26Ellipse_2_bz">
                <ellipse id="PPD_MoB_26Ellipse_2_bz" rx="18.863571166992188" ry="18.863571166992188" cx="18.863571166992188" cy="18.863571166992188">
                </ellipse>
              </svg>
              <div id="PPD_MoB_26Group_5_b">
                <svg class="PPD_MoB_26Line_3_b" viewBox="0 0 14.103 4">
                  <path id="PPD_MoB_26Line_3_b" d="M 0 0 L 14.10341835021973 0">
                  </path>
                </svg>
                <svg class="PPD_MoB_26Line_4_b" viewBox="0 0 14.103 4">
                  <path id="PPD_MoB_26Line_4_b" d="M 0 0 L 14.10341835021973 0">
                  </path>
                </svg>
              </div>
            </div>
          </div>
          <div id="PPD_MoB_26Group_11">
            <div id="PPD_MoB_26Desserts">
              <span>Desserts:</span>
            </div>
            <div id="PPD_MoB_26Group_8">
              <svg class="PPD_MoB_26Ellipse_2_b">
                <ellipse id="PPD_MoB_26Ellipse_2_b" rx="18.863571166992188" ry="18.863571166992188" cx="18.863571166992188" cy="18.863571166992188">
                </ellipse>
              </svg>
              <div id="PPD_MoB_26Group_5_ca">
                <svg class="PPD_MoB_26Line_3_ca" viewBox="0 0 14.103 4">
                  <path id="PPD_MoB_26Line_3_ca" d="M 0 0 L 14.10341835021973 0">
                  </path>
                </svg>
                <svg class="PPD_MoB_26Line_4_ca" viewBox="0 0 14.103 4">
                  <path id="PPD_MoB_26Line_4_ca" d="M 0 0 L 14.10341835021973 0">
                  </path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="PPD_MoB_26Group_15100">
        <div id="PPD_MoB_26Group_15088">
          <div id="PPD_MoB_26Group_15094">
            <svg class="PPD_MoB_26Path_7778" viewBox="0 0 388.588 171.516">
              <path id="PPD_MoB_26Path_7778" d="M 0 171.5158843994141 C 0 171.5158843994141 98.79680633544922 137.0253753662109 195.9438934326172 137.0253753662109 C 293.0910339355469 137.0253753662109 388.5883483886719 171.5158843994141 388.5883483886719 171.5158843994141 L 388.5883483886719 0 L 0 0 L 0 171.5158843994141 Z">
              </path>
            </svg>
            <img id="PPD_MoB_26Mask_Group_7" src="./Themes/PlantPlates/Mask_Group_7.png" srcset="./Themes/PlantPlates/Mask_Group_7.png 1x" />
              
          </div>
          <div id="PPD_MoB_26Group_13">
            <svg class="PPD_MoB_26Line_2_cg" viewBox="0 0 93.939 7">
              <path id="PPD_MoB_26Line_2_cg" d="M 0 0 L 93.93899536132812 0">
              </path>
            </svg>
            <div id="PPD_MoB_26PLANS">
              <span>PLANS</span>
            </div>
          </div>
          <div id="PPD_MoB_26Scroll_Group_87">
            <div id="PPD_MoB_26Group_143">
              <div id="PPD_MoB_26Group_15095">
                <svg class="PPD_MoB_26Rectangle_8">
                  <rect id="PPD_MoB_26Rectangle_8" rx="50" ry="50" x="0" y="0" width="344.069" height="267.242">
                  </rect>
                </svg>
                <img id="PPD_MoB_26Mask_Group_3" src="./Themes/PlantPlates/Mask_Group_3.png" srcset="./Themes/PlantPlates/Mask_Group_3.png 1x" />
                  
                <svg class="PPD_MoB_26victoria-shes-4MEL9XS-3JQ-unsp">
                  <rect id="PPD_MoB_26victoria-shes-4MEL9XS-3JQ-unsp" rx="20" ry="20" x="0" y="0" width="344.069" height="267.242">
                  </rect>
                </svg>
                <div id="PPD_MoB_26Order_1">
                  <span>Order 1</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="PPD_MoB_26Group_15097">
        <div id="PPD_MoB_26VISIT_US_">
          <span>VISIT US !!</span>
        </div>
        <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="PPD_MoB_26Text_Background">
          <rect id="PPD_MoB_26Text_Background" rx="0" ry="0" x="0" y="0" width="390.252" height="247.958">
          </rect>
        </iframe>
        <div id="PPD_MoB_26Group_15096">
          <img id="PPD_MoB_26sonny-mauricio-yhc4pSbl01A-uns" src="./Themes/PlantPlates/sonny-mauricio-yhc4pSbl01A-uns.png" srcset="./Themes/PlantPlates/sonny-mauricio-yhc4pSbl01A-uns.png 1x" />
            
          <svg class="PPD_MoB_26sonny-mauricio-yhc4pSbl01A-uns_cu">
            <rect id="PPD_MoB_26sonny-mauricio-yhc4pSbl01A-uns_cu" rx="0" ry="0" x="0" y="0" width="390" height="629">
            </rect>
          </svg>
          <svg class="PPD_MoB_26Border" viewBox="0 -40 390.252 2.923">
            <path id="PPD_MoB_26Border" d="M 0 -39.99999237060547 L 390.25244140625 -39.99999237060547 L 390.25244140625 -37.07714080810547 L 0 -37.07714080810547 L 0 -39.99999237060547 Z">
            </path>
          </svg>
          <div id="PPD_MoB_26n_234567890_mobile_number_shad">
            <span>{props.data.businessPhone}</span>
          </div>
          <div id="PPD_MoB_26affanghanioperatelive_email_sh">
            <span>{props.data.businessEmail}</span>
          </div>
          <div id="PPD_MoB_26address_med_shadow_space_websi">
            <span>{props.data.businessAddress}</span>
          </div>
          <img id="PPD_MoB_26location_img_shadow_space_webs" src="./Themes/PlantPlates/location_img_shadow_space_webs.png" srcset="./Themes/PlantPlates/location_img_shadow_space_webs.png 1x" />
            
          <img id="PPD_MoB_26email_address_shadow_space_web" src="./Themes/PlantPlates/email_address_shadow_space_web.png" srcset="./Themes/PlantPlates/email_address_shadow_space_web.png 1x" />
            
          <img id="PPD_MoB_26phone_number_shadow_space_webs" src="./Themes/PlantPlates/phone_number_shadow_space_webs.png" srcset="./Themes/PlantPlates/phone_number_shadow_space_webs.png 1x" />
            
          <div id="PPD_MoB_26Follow_Us">
            <span>Follow Us</span>
          </div>
          <div id="PPD_MoB_26socials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
            <div title="LinkedIn" id="PPD_MoB_26linked_in_grp_shadow_space_web">
              <svg class="PPD_MoB_26med_shadow_space_website_7">
                <ellipse id="PPD_MoB_26med_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
              </svg>
              <div id="PPD_MoB_26linked_in_shadow_space_website">
                <a href={props.data.linkedInUrl} target="_blank">
                <svg class="PPD_MoB_26n_website_7_layout_" viewBox="-15.255 0 15.547 14.668">
                  <path id="PPD_MoB_26n_website_7_layout_" d="M -5.526878833770752 3.814697038251325e-06 C -8.153567314147949 3.814697038251325e-06 -9.799471855163574 1.422712445259094 -10.10365676879883 2.419437646865845 L -10.10365676879883 0.1396603286266327 L -15.25499820709229 0.1396603286266327 C -15.1880407333374 1.350015878677368 -15.25499820709229 14.66775035858154 -15.25499820709229 14.66775035858154 L -10.10365676879883 14.66775035858154 L -10.10365676879883 6.806180477142334 C -10.10365676879883 6.369354248046875 -10.12470149993896 5.932529926300049 -9.992057800292969 5.618782520294189 C -9.637495994567871 4.745131969451904 -8.876718521118164 3.838321924209595 -7.508852481842041 3.838321924209595 C -5.720739364624023 3.838321924209595 -4.907035350799561 5.18195629119873 -4.907035350799561 7.149900913238525 L -4.907035350799561 14.66775035858154 L 0.2921361923217773 14.66775035858154 L 0.2921361923217773 6.589361190795898 C 0.2921361923217773 2.089746952056885 -2.268231630325317 3.814697038251325e-06 -5.526878833770752 3.814697038251325e-06">
                  </path>
                </svg>
              </a>
                <svg class="PPD_MoB_26linked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.585 14.529">
                  <path id="PPD_MoB_26linked_in_i_shadow_space_websi" d="M 0.9309999942779541 -8.254270553588867 L 5.516065120697021 -8.254270553588867 L 5.516065120697021 -22.78300285339355 L 0.9309999942779541 -22.78300285339355 L 0.9309999942779541 -8.254270553588867 Z">
                  </path>
                </svg>
                <svg class="PPD_MoB_26top_dot_i_shadow_space_website" viewBox="-4.392 0 5.566 5.041">
                  <path id="PPD_MoB_26top_dot_i_shadow_space_website" d="M -1.591219305992126 0 C -3.284313917160034 0 -4.392000198364258 1.087279200553894 -4.392000198364258 2.522743225097656 C -4.392000198364258 3.930147409439087 -3.316198587417603 5.041022300720215 -1.656264781951904 5.041022300720215 L -1.623104333877563 5.041022300720215 C 0.1005998626351357 5.041022300720215 1.17384946346283 3.926321506500244 1.17384946346283 2.5182785987854 C 1.140688419342041 1.084728360176086 0.1005998626351357 0 -1.591219305992126 0">
                  </path>
                </svg>
              </div>
            </div>:""}
			{props.data.instagramUrl?	
            <div title="Instagram" id="PPD_MoB_26Insta_grp_shadow_space_website">
              <svg class="PPD_MoB_26shadow_space_website_7">
                <ellipse id="PPD_MoB_26shadow_space_website_7" rx="24.5" ry="24" cx="24.5" cy="24">
                </ellipse>
              </svg>
              <a href={props.data.instagramUrl} target="_blank">
              <img id="PPD_MoB_26instagramshadow_space_website_" src="./Themes/PlantPlates/instagramshadow_space_website_.png" srcset="./Themes/PlantPlates/instagramshadow_space_website_.png 1x" />
                
              </a>
            </div>:""}
			{props.data.whatsAppNumber?
            <div title="WhatsApp" id="PPD_MoB_26whatsapp_grp_shadow_space_webs">
              <svg class="PPD_MoB_26med_shadow_space_website_7_de">
                <ellipse id="PPD_MoB_26med_shadow_space_website_7_de" rx="24" ry="24" cx="24" cy="24">
                </ellipse>
              </svg>
              <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="PPD_MoB_26whatsapp_lbl_shadow_space_webs" src="./Themes/PlantPlates/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/PlantPlates/whatsapp_lbl_shadow_space_webs.png 1x" />
                </a>
            </div>:""}
          </div>
          <div id="PPD_MoB_26n_Copyright_Operate_Live_OPC_P">
            <span>© Copyright Operate Live (OPC) Private Limited. <br/>All Rights Reserved</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlantPlatesMob