import React, { useState, useEffect, useContext } from "react";
import '../styles/BottomNavBarMobUI.css'

const BottomNavBarMobUI = () => {
  return (
    <div>
      {/* <div id="bar"> */}
	<div id="BottomBar_group">
		<svg class="BottomBar_border">
			<rect id="BottomBar_border" rx="0" ry="0" x="0" y="0"  height="65">
			</rect>
		</svg>
		<a href="tel:+919019050190">
		<img id="customer-support_bottombar" src="customer-support_top_nav.png"  />
    </a>
	    <img id="bell_bottombar" src="notification_top_navbar.png" />
	
		<img id="add-friend_bottombar" src="friends_top_navbar.png" />
{/* 
		<img id="bell_bottombar" src="bell.png" srcset="bell.png 1x, bell@2x.png 2x" />
			
		<img id="add-friend_bottombar" src="add-friend.png" srcset="add-friend.png 1x, add-friend@2x.png 2x" />
			
		<img id="customer-support_bottombar" src="customer-support.png" srcset="customer-support.png 1x, customer-support@2x.png 2x" />
			 */}
	</div>
 {/* </div> */}
    </div>
  )
}

export default BottomNavBarMobUI
