import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import '../../styles/CalanderSlideMob.css';
import Modal from '@mui/material/Modal';
import CalenderPageMobUI from '../PC/CalenderPageMobUI';
import CalanderEntryPopUpUI from '../PC/CalanderEntryPopUpUI';
import HourCard from '../PC/HourCard';


const CalanderSlideMob = (props) => {
    var navigate = useNavigate();
   
	const [openCalanderEntryPop,setOpenCalanderEntryPop] = useState(false);
    const { user, setUser} = useContext(UserContext);
    const [slotType,setSlotType] = useState("event");
	const [slotTime,setSlotTime] = useState(new Date().toISOString().substring(0,16));
	//const [slotDate,setSlotDate] = useState(new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString().padStart(2, '0') + "-" + new Date().getDate().toString().padStart(2, '0'));
    const [ appointments,setAppointments] = useState([[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);//{title:"",type:"",startTime:"",endTime:""}
    const [scrollPosition,setScrollPosition] = useState(0)

useEffect(()=>{

    if(user){
      if(props.slotDate){
		setAppointments(props.appointments)
	  }
    }    
    
    
    },[props.slotDate,props.appointments])
    
    const closeGridModel = ()=>{
        props.closeCalender()
    }

	
	const handleScroll =()=>{

	const scroller = document.querySelector("#Scroll_date_and_event_oof_mob");

	
	scroller&& scroller.addEventListener("scroll", (event) => {
	 setScrollPosition(scroller.scrollTop)
	
	});

	}

	


  return (
 
<div id="right_component" className='fadeInRight'>
	<div id="right_side_grp_calender_mob">
		<svg class="calender_bdr_calendermob_cu">
			<linearGradient id="calender_bdr_calendermob_cu" spreadMethod="pad" x1="1" x2="-0.338" y1="0.487" y2="0.462">
				<stop offset="0" stop-color="#f3f3f3" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
			</linearGradient>
			<rect id="calender_bdr_calendermob_cu" rx="15" ry="15" x="0" y="0" width="296" height="767">
			</rect>
		</svg>
		<div id="Scroll_date_and_event_oof_mob" onScroll={()=>handleScroll()}>
			<div id="so_much_to_grpmob">

				
			{(appointments||[]).map((data,idx)=>
				<HourCard data={data||[]} hour={idx} scrollPosition={scrollPosition} openCalanderEntryPopUp={()=>{setOpenCalanderEntryPop(true);setSlotType("alarm");setSlotTime(props.slotDate+"T"+idx.toString().padStart(2,'0')+":00");}}/>
				)}

			
			</div>
		</div>

		<div id="august_calender_mobside">
			<CalenderPageMobUI slotDate={props.slotDate} date={(e)=>{setAppointments([[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);props.selectedDate(e)}} />
		</div>
        
		<svg class="Icon_ionic-ios-arrow-back3123" viewBox="11.251 6.194 13.503 23.619" onClick={()=>closeGridModel()}>
			<path id="Icon_ionic-ios-arrow-back3123" d="M 20.68299102783203 17.99990081787109 L 11.74628067016602 9.07028865814209 C 11.08534240722656 8.409357070922852 11.08534240722656 7.340615272521973 11.74628067016602 6.686714649200439 C 12.40721893310547 6.025783061981201 13.47596549987793 6.032814025878906 14.13690376281738 6.686714649200439 L 24.26189613342285 16.80459785461426 C 24.9017391204834 17.44443511962891 24.91580200195312 18.47099113464355 24.31111526489258 19.13192176818848 L 14.14393424987793 29.32011795043945 C 13.8134651184082 29.65058326721191 13.37752723693848 29.81230163574219 12.94862174987793 29.81230163574219 C 12.51971817016602 29.81230163574219 12.08378028869629 29.65058326721191 11.75331115722656 29.32011795043945 C 11.09237289428711 28.6591854095459 11.09237289428711 27.59044456481934 11.75331115722656 26.93654251098633 L 20.68299102783203 17.99990081787109 Z">
			</path>
		</svg>
	</div>
	
<CalanderEntryPopUpUI OpenCalanderEntryPop={openCalanderEntryPop} type={slotType} time={slotTime} closePop={()=>{setOpenCalanderEntryPop(false);props.refresh()}}/>
</div>
      
  )
}

export default CalanderSlideMob
