import React from 'react';
import "../../../styles/FashionFinds.css";

function FashionFinds(props) {
  return (
    <div id="Fashion_Finds">
	<div id="footer_grp_web_9_pc">
		<div id="socials_grp_med_web_9_pc">
			<svg class="footer_web9pc">
				<rect id="footer_web9pc" rx="0" ry="0" x="0" y="0" width="1366" height="411">
				</rect>
			</svg>
			<div id="n_Copyright_Operate_Live_websi9_web_pc">
				<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
			</div>
			{props.data.linkedInUrl?
			<div title="LinkedIn" id="linked_in_grp_med_web_9_pc">
				<svg class="Ellipse_5_med_web_9_pc">
					<ellipse id="Ellipse_5_med_web_9_pc" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<div id="linked_in_website_9_layout">
				<a href={props.data.linkedInUrl} target="_blank">
					<svg class="n_website_2_layout_web_9_pc" viewBox="-15.255 0 16.092 15.182">
						<path id="n_website_2_layout_web_9_pc" d="M -5.186071872711182 9.679508821136551e-07 C -7.904781818389893 9.679508821136551e-07 -9.60834789276123 1.472553014755249 -9.923189163208008 2.504196882247925 L -9.923189163208008 0.1445503830909729 L -15.2549991607666 0.1445503830909729 C -15.18569564819336 1.397309064865112 -15.2549991607666 15.18160820007324 -15.2549991607666 15.18160820007324 L -9.923189163208008 15.18160820007324 L -9.923189163208008 7.044621467590332 C -9.923189163208008 6.592491626739502 -9.944971084594727 6.140363693237305 -9.807681083679199 5.815624713897705 C -9.440696716308594 4.911367893218994 -8.653267860412598 3.972789287567139 -7.237480163574219 3.972789287567139 C -5.38672399520874 3.972789287567139 -4.544512748718262 5.363495349884033 -4.544512748718262 7.400383949279785 L -4.544512748718262 15.18160820007324 L 0.8368034362792969 15.18160820007324 L 0.8368034362792969 6.820206165313721 C 0.8368034362792969 2.162956237792969 -1.813262462615967 9.679508821136551e-07 -5.186071872711182 9.679508821136551e-07">
						</path>
					</svg>
				</a>
					<svg class="linked_in_i_down_web_9_pc" viewBox="0.931 -22.783 4.746 15.038">
						<path id="linked_in_i_down_web_9_pc" d="M 0.9310001134872437 -7.745282173156738 L 5.676695346832275 -7.745282173156738 L 5.676695346832275 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -7.745282173156738 Z">
						</path>
					</svg>
					<svg class="top_dot_i_website_9_layout" viewBox="-4.392 0 5.761 5.218">
						<path id="top_dot_i_website_9_layout" d="M -1.493098974227905 0 C -3.245508193969727 0 -4.392000198364258 1.125370144844055 -4.392000198364258 2.611123323440552 C -4.392000198364258 4.06783390045166 -3.278510093688965 5.217626571655273 -1.560423135757446 5.217626571655273 L -1.526101112365723 5.217626571655273 C 0.2579902410507202 5.217626571655273 1.368839383125305 4.063873767852783 1.368839383125305 2.606502532958984 C 1.334516644477844 1.122730016708374 0.2579902410507202 0 -1.493098974227905 0">
						</path>
					</svg>
				</div>
			</div>:""}
			{props.data.instagramUrl?
			<div title="Instagram" id="Insta_grp_med_web_9_pc">
				<svg class="Ellipse_4_med_web_9_pc">
					<ellipse id="Ellipse_4_med_web_9_pc" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<a href={props.data.instagramUrl} target="_blank">
				<img id="instagram_website_9_layout" src="./Themes/FashionFinds/instagram_website_9_layout.png" srcset="./Themes/FashionFinds/instagram_website_9_layout.png 1x" />
					
				</a>
			</div>:""}
			{props.data.whatsAppNumber?
			<div title="WhatsApp" id="whatsapp_grp_med_web_9_pc">
				<svg class="Ellipse_3_med_web_9_pc">
					<ellipse id="Ellipse_3_med_web_9_pc" rx="25" ry="25" cx="25" cy="25">
					</ellipse>
				</svg>
				<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="whatsapp_lbl_website9_layout" src="./Themes/FashionFinds/whatsapp_lbl_website9_layout.png" srcset="./Themes/FashionFinds/whatsapp_lbl_website9_layout.png 1x" />
				</a>	
			</div>:""}
		</div>
		<div id="For_Queries_infoandroom_web_9_">
			<span>For Queries: {props.data.businessEmail}</span>
		</div>
		<div id="For_call__920012888_web_9_pc">
			<span>For call : {props.data.businessPhone}</span>
		</div>
		<svg class="telephone_web_9_pc" viewBox="0 0 11.38 10.522">
			<path id="telephone_web_9_pc" d="M 5.285655498504639 9.613908767700195 L 0.9823954105377197 5.635064125061035 C -0.3238036036491394 4.427295207977295 -0.3306940197944641 2.468093156814575 0.9823954105377197 1.253952264785767 L 2.241295099258423 0.08995640277862549 C 2.384282350540161 -0.04229017719626427 2.621845722198486 -0.02730839140713215 2.743739366531372 0.1229615285992622 L 4.886256217956543 2.764296293258667 C 4.985811233520508 2.887007236480713 4.972586154937744 3.058732271194458 4.855293750762939 3.167201519012451 L 3.574788808822632 4.351193428039551 C 3.42620062828064 4.488577842712402 3.425956249237061 4.711372852325439 3.574788808822632 4.848983764648438 L 6.13579797744751 7.216946601867676 C 6.284387111663818 7.354290962219238 6.525328159332275 7.354516506195068 6.674205303192139 7.216926574707031 L 7.954710006713867 6.032955169677734 C 8.072046279907227 5.924466133117676 8.257777214050293 5.912298679351807 8.390473365783691 6.004327774047852 L 11.24716854095459 7.985313892364502 C 11.40937995910645 8.097809791564941 11.42625045776367 8.317337989807129 11.28286647796631 8.449872016906738 L 10.02394390106201 9.613887786865234 C 8.715367317199707 10.82381629943848 6.595943450927734 10.82542037963867 5.285654544830322 9.613908767700195 Z">
			</path>
		</svg>
		<svg class="send_1_web_9_pc" viewBox="0 0 11.38 10.522">
			<path id="send_1_web_9_pc" d="M 11.3803596496582 0 L 0 5.918774127960205 L 3.636945486068726 7.164215564727783 L 9.246563911437988 2.301749229431152 L 4.979704856872559 7.624059200286865 L 4.98317289352417 7.625257015228271 L 4.978921890258789 7.624059200286865 L 4.978921890258789 10.52227306365967 L 7.018501281738281 8.32220458984375 L 9.602203369140625 9.206996917724609 L 11.3803596496582 0 Z">
			</path>
		</svg>
		<svg class="Line_web_9_pc" viewBox="0 0 1365.56 2">
			<path id="Line_web_9_pc" d="M 0 0 L 1365.559692382812 0">
			</path>
		</svg>
		<svg class="Icon_metro-locatio_web_9_pc_n" viewBox="8.355 1.928 11.381 18.209">
			<path id="Icon_metro-locatio_web_9_pc_n" d="M 14.04507637023926 1.928033471107483 C 10.90240859985352 1.928033471107483 8.354801177978516 4.475640773773193 8.354801177978516 7.618309020996094 C 8.354801177978516 13.30858421325684 14.04507637023926 20.13691520690918 14.04507637023926 20.13691520690918 C 14.04507637023926 20.13691520690918 19.7353515625 13.30858421325684 19.7353515625 7.618309020996094 C 19.7353515625 4.475640773773193 17.18770790100098 1.928033709526062 14.04507637023926 1.928033709526062 Z M 14.04507637023926 11.10360240936279 C 12.12019920349121 11.10360240936279 10.55978298187256 9.543186187744141 10.55978298187256 7.618309020996094 C 10.55978298187256 5.69343090057373 12.12019920349121 4.133015155792236 14.04507637023926 4.133015155792236 C 15.9699535369873 4.133015155792236 17.53037071228027 5.69343090057373 17.53037071228027 7.618309020996094 C 17.53037071228027 9.543186187744141 15.9699535369873 11.10360240936279 14.04507637023926 11.10360240936279 Z M 11.84009552001953 7.618309020996094 C 11.84009552001953 6.40053653717041 12.82730388641357 5.413327217102051 14.04507637023926 5.413327217102051 C 15.26284980773926 5.413327217102051 16.25005722045898 6.40053653717041 16.25005722045898 7.618309020996094 C 16.25005722045898 8.836081504821777 15.26284980773926 9.823290824890137 14.04507637023926 9.823290824890137 C 12.82730388641357 9.823290824890137 11.84009552001953 8.836081504821777 11.84009552001953 7.618309020996094 Z">
			</path>
		</svg>
		<div id="address_lbl_web_9_pc">
			<span>{props.data.businessAddress}</span>
		</div>
		<img id="background_img_web_9_pc" src="./Themes/FashionFinds/background_img_web_9_pc.png" srcset="./Themes/FashionFinds/background_img_web_9_pc.png 1x" />
			
		{/* <iframe src="./Themes/FashionFinds/https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15547.185741508381!2d77.64215715!3d13.04862565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1688370086554!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="display_web_9_pc">
			<rect id="display_web_9_pc" rx="0" ry="0" x="0" y="0" width="522" height="235">
			</rect>
		</iframe> */}
		<iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="display_web_9_pc">
				<rect id="display_web_9_pc" rx="15" ry="15" x="0" y="0" width="385" height="211">
				</rect>
			</iframe>
	</div>
	<div id="Group_14856_web_9_pc">
		<img id="n_0230728085642_fpdlin_colorfu" src="./Themes/FashionFinds/n_0230728085642_fpdlin_colorfu.png" srcset="./Themes/FashionFinds/n_0230728085642_fpdlin_colorfu.png 1x" />
			
		<svg class="Rectangle_681">
			<rect id="Rectangle_681" rx="0" ry="0" x="0" y="0" width="1366" height="771">
			</rect>
		</svg>
		<div id="pfp_pic_web_9_pc">
			<svg class="bdr_pfp_web_9_pc">
				<ellipse id="bdr_pfp_web_9_pc" rx="35" ry="35" cx="35" cy="35">
				</ellipse>
			</svg>
			<img id="zane-lee-_web_9_pc" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/FashionFinds/zane-lee-_web_9_pc.png"} />
				
		</div>
		<div id="ACT_NOW__SEIZE_THE_DEAL_lbl_we">
			<span>ACT NOW &<br/>SEIZE THE DEAL</span>
		</div>
		<div id="Sell_btn_web_9_pc">
			<svg class="start_bidding_web_9_pc">
				<rect id="start_bidding_web_9_pc" rx="0" ry="0" x="0" y="0" width="145" height="38">
				</rect>
			</svg>
			<div id="START_BIDDING_web_9_pc_c">
				<span>START SHOPPING</span>
			</div>
		</div>
	</div>
	<div id="vision_grp_web_9_pc">
		<div id="Scroll_Groupvision_web_9_pc">
			<div id="BinQasem_provides_a_web_9_pc">
				<span>{props.data.vision}<br/></span><br/>
			</div>
		</div>
		<div id="VISIon_">
			<span>VISIon</span>
		</div>
		<img id="Mask_Group_web_9_pc" src="./Themes/FashionFinds/Mask_Group_web_9_pc.png" srcset="./Themes/FashionFinds/Mask_Group_web_9_pc.png 1x" />
		
	</div>
	<div id="mission_grp_web_9_pc">
		<div id="Scroll_Group_misiinweb_9_pc">
			<div id="BinQasem_provide_mission_web_9">
				<span>{props.data.mission}<br/></span><br/>
			</div>
		</div>
		<div id="Mission_lbl_web_9_pc">
			<span>Mission</span>
		</div>
		<img id="Mask_Group_web_9_pc_dj" src="./Themes/FashionFinds/Mask_Group_web_9_pc_dj.png" srcset="./Themes/FashionFinds/Mask_Group_web_9_pc_dj.png 1x" />
	</div>
	<div id="principle_group_web_9_pc">
		<div id="Scroll_Group_prini_web_9_pc">
			<div id="BinQasem_provi_web_9_pc">
				<span>{props.data.principles}<br/></span><br/>
			</div>
		</div>
		<div id="PRINCIPLE_web_9_pc">
			<span>PRINCIPLE</span>
		</div>
		<img id="Mask_Group_web_9_pc_do" src="./Themes/FashionFinds/Mask_Group_web_9_pc_do.png" srcset="./Themes/FashionFinds/Mask_Group_web_9_pc_do.png 1x" />
	</div>
	<div id="Category_Section_web_9_pc">
		<svg class="Line_27" viewBox="0 0 1348 2">
			<path id="Line_27" d="M 0 0 L 1348 2">
			</path>
		</svg>
		<svg class="Ellipse_44">
			<ellipse id="Ellipse_44" rx="13.5" ry="13.5" cx="13.5" cy="13.5">
			</ellipse>
		</svg>
		<div id="our_services">
			<span>our services</span>
		</div>
	</div>
	<div id="Scroll_our_services_web_9_pc">
	{props.offeringsData.map((off,idx)=>(
		<div id="hanger_cloth_grp_web_9_pc">
			<img id="hanger_our_services_web_9_pc" src="./Themes/FashionFinds/hanger_our_services_web_9_pc.png" srcset="./Themes/FashionFinds/hanger_our_services_web_9_pc.png 1x" />
				
			<img id="Mask_Groupoff_img_web_9_pc" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/FashionFinds/Mask_Groupoff_img_web_9_pc.png"} />
			<div id="Bunda_Clothes_lbl_web_9_pc">
				<span>{off.offeringTitle}</span>
			</div>
			<svg class="bdr_for_bid_web_9_pc">
				<rect id="bdr_for_bid_web_9_pc" rx="0" ry="0" x="0" y="0" width="68" height="70">
				</rect>
			</svg>
			<div id="law_6_bid_path_web_9_pc">
				<svg class="Path_6945_web_9_pc" viewBox="43.745 0 12.47 13.839">
					<path id="Path_6945_web_9_pc" d="M 43.98790740966797 10.4166841506958 L 48.6747932434082 13.73391056060791 C 48.93252944946289 13.91635227203369 49.29129409790039 13.85678863525391 49.47518920898438 13.59694480895996 L 50.80208969116211 11.72218418121338 C 50.98528671264648 11.46333408355713 50.92397308349609 11.10499572753906 50.66512298583984 10.92178726196289 L 50.40657424926758 10.73877048492432 C 51.79323577880859 9.643007278442383 53.13486480712891 7.723150730133057 53.71611785888672 6.046488761901855 L 53.98234558105469 6.234902858734131 C 54.24115753173828 6.418034076690674 54.59952926635742 6.356786251068115 54.78274154663086 6.097936630249023 L 56.10964202880859 4.223175525665283 C 56.2927360534668 3.964517831802368 56.23151779174805 3.60594916343689 55.97267532348633 3.422779083251953 L 51.28575134277344 0.1056286841630936 C 51.02694320678711 -0.07757922261953354 50.6685676574707 -0.01629300601780415 50.48535537719727 0.2425944060087204 L 49.1584587097168 2.117317676544189 C 48.97536087036133 2.37601375579834 49.03661346435547 2.734543561935425 49.29542541503906 2.917712926864624 L 49.56207656860352 3.106433629989624 C 48.14989471435547 4.231825828552246 46.79964447021484 6.145365715026855 46.23723983764648 7.787882804870605 L 45.97819519042969 7.604559898376465 C 45.71938705444336 7.421390533447266 45.36100769042969 7.482637882232666 45.17780303955078 7.741525650024414 L 43.85090637207031 9.616249084472656 C 43.66784286499023 9.874944686889648 43.72906112670898 10.23351192474365 43.98790740966797 10.41668319702148 Z">
					</path>
				</svg>
				<svg class="Path_6946_web_9_pc" viewBox="265.077 203.692 8.859 7.48">
					<path id="Path_6946_web_9_pc" d="M 273.2081604003906 208.042724609375 L 267.0609741210938 203.6919708251953 C 266.5446472167969 204.6836853027344 265.8451843261719 205.6806182861328 265.0769958496094 206.5085144042969 L 271.2178039550781 210.8547973632812 C 271.995361328125 211.4051513671875 273.069091796875 211.2209167480469 273.6190490722656 210.4439086914062 C 274.1690673828125 209.6667938232422 273.9853515625 208.5927429199219 273.2081604003906 208.042724609375 Z">
					</path>
				</svg>
				<svg class="Path_6947_web_9_pc" viewBox="15.495 392.001 12.632 4.594">
					<path id="Path_6947_web_9_pc" d="M 27.55318641662598 393.1493225097656 L 26.97898292541504 393.1493225097656 C 26.66237258911133 393.1493225097656 26.40478515625 392.8917541503906 26.40478515625 392.5751953125 C 26.40478515625 392.2580261230469 26.14769744873047 392.0009460449219 25.83058547973633 392.0009460449219 L 17.79179191589355 392.0009460449219 C 17.47467994689941 392.0009460449219 17.21759033203125 392.2580261230469 17.21759033203125 392.5751953125 C 17.21759033203125 392.8917541503906 16.96000671386719 393.1493225097656 16.64339065551758 393.1493225097656 L 16.06919097900391 393.1493225097656 C 15.75208377838135 393.1493225097656 15.49499416351318 393.4064636230469 15.49499416351318 393.7236022949219 L 15.49499416351318 396.0203247070312 C 15.49499416351318 396.3374633789062 15.75208377838135 396.5945434570312 16.06919097900391 396.5945434570312 L 27.55318641662598 396.5945434570312 C 27.87029266357422 396.5945434570312 28.12738037109375 396.3374633789062 28.12738037109375 396.0203247070312 L 28.12738037109375 393.7236022949219 C 28.12738037109375 393.4064636230469 27.87033271789551 393.1493225097656 27.55318641662598 393.1493225097656 Z">
					</path>
				</svg>
			</div>
		</div>))}
	</div>
</div>
  )
}

export default FashionFinds
