import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioBigOffCardMob.css';
import { UserContext } from '../UserContext';

const MyPortfolioBigOffCardMob = (props) => {
	const {user} = useContext(UserContext);
	 


	const handleLike = (offId) =>{
	
			let apiURL = process.env.REACT_APP_API_URL + "/offerings/updatetrophy/"+offId;
			let apiParams = {
			  method: "POST",
			  headers: {
					  id:user._id,
					  name: user.name,
					  nickname:user.nickName,	
					  imageid:user.imageId,
					 
			  },
			};
			fetch(apiURL, apiParams)
			  .then((res) => (res.status === 200 ? res.json() : res.text()))
			  .then((repos) => {
				if (repos.data) {
				
				 // alert("Trophy Updated Successfully!!")
				 props.reload()
				}
			  })
			  .catch((err) => alert(err));
		  };
	
  return (

	<div class="OFFM_big_1_trending_off_mob">
	<svg class="OFFM_bdr_all_trending_off">
		<rect id="OFFM_bdr_all_trending_off" rx="10" ry="10" x="0" y="0" width="339" height="427">
		</rect>
	</svg>
	<svg class="OFFM_bdr_amount_trending_off">
		<rect id="OFFM_bdr_amount_trending_off" rx="10.5" ry="10.5" x="0" y="0" width="65" height="21">
		</rect>
	</svg>
	<div id="OFFM_n_9_trending_off">
		<span>{props.data.offeredcurrency && JSON.parse(props.data.offeredcurrency).symbol} {props.data.budget}</span>
	</div>
	<img id="OFFM_Image_trending__off_" src={props.data.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.defaultOfferingsImageUrl.split('/')[5]):"./MyPortfolio/Offerings/Image_trending__off_PC.png"}/>
		
	<div id="OFFM_Nike_Air_Force_1_Sha_trending_">
		<span>{props.data.offeringTitle}</span>
	</div>
	<div id="OFFM_Single_Tour_trending_off">
		<span>{( props.data.deliveryDuration ? props.data.deliveryDuration:"")} Hours | {props.data.deliveryLocation}</span>
	</div>
	<div id="OFFM_Group_125">
		<svg class="OFFM_bdr_1_trending_off_">
			<ellipse id="OFFM_bdr_1_trending_off_" rx="12" ry="11.5" cx="12" cy="11.5">
			</ellipse>
		</svg>
		<img id="OFFM_trophy_trending_off_" src={(JSON.parse(props.data.trophies)).filter((rec)=>rec.id === user._id)[0]?"./MyPortfolio/Offerings/trophyLiked.png":"./MyPortfolio/Offerings/trophy_reqoffevent_off_PC.png"}   onClick={()=>handleLike(props.data._id)} />
			
	</div>
	<div id="OFFM_Group_124">
		<svg class="OFFM_likes_trending_off_">
			<rect id="OFFM_likes_trending_off_" rx="0" ry="0" x="0" y="0" width="24" height="13">
			</rect>
		</svg>
		<div id="OFFM_n_234_like_trending_off_">
			<span>{(JSON.parse(props.data.trophies)).length}</span>
		</div>	
	</div>
<div id="off_m_Group_134">
		<svg class="off_m_share_bdr_small_4_trending_com">
			<ellipse id="off_m_share_bdr_small_4_trending_com" rx="12.5" ry="12.5" cx="12.5" cy="12.5">
			</ellipse>
		</svg>
		<img id="off_m_share_2_com_of" src="./MyPortfolio/Offerings/share_1_reqoffevent_off_PC_da.png" srcset="./MyPortfolio/Offerings/share_1_reqoffevent_off_PC_da.png 1x" />
			
	</div>

</div>
  )
}

export default MyPortfolioBigOffCardMob
