import React, { useEffect, useState, useContext} from 'react'
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import '../../styles/CommunitiesRequirementsPageNewUI.css';
import Modal from '@mui/material/Modal';
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AddCommmunitiesReqPopUp from '../AddCommunitiesReqPopUp'
import FooterPcUI from './FooterPcUI';
import MyCommunitiesNewUI from './MyCommunitiesNewUI';
import OfferingsSideUI from './OfferingsSideUI';
import QuotationPopUpPC from '../QuotationPopUpPC';
import RequirementsViewMorePopUp from '../RequirementsViewMorePopUp';
import ImageSet from './ImageSet';


const CommunitiesRequirementsPageNewUI = (props) => {

//useStates
    
    var navigate = useNavigate();
    const { user, setUser} = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const communityId = props.communityId;
    const communityName = props.communityName;
    const [requirements,setRequirements]=useState([]);
    const [ offerings,setOfferings] = useState([]);
    const [allStatus,setAllStatus] = useState([]);
    const [checked, setChecked] = React.useState([true, false]);
   
    const [openGridOfferingModel,setOpenGridOfferingModel] = useState(false);
    const [openGridConnectionModel,setOpenGridConnectionModel] = useState(false);
    const [oddRequirements,setOddRequirements] = useState([]);
    const [oddOfferings,setOddOfferings] = useState([]);
    const [oddConnections,setOddConnections] = useState([]);
    const [selectedCommunity,setSelectedCommunity] = useState([]);
    const [selectedCommunities,setSelectedCommunities] = useState([]);
    const [selectedQuotation,setSelectedQuotation] = useState({});
    const [viewMorePopup,setViewMorePopup] = useState(false);
    const [viewMoreData,setViewMoreData] = useState("");
    const [quotationOpenModel,setQuotationOpenModel] = useState(false);
    const [openGridModel,setOpenGridModel] = useState(false);

//UseEffects
useEffect(()=>{
    if(user){
      
        if(props.communityId){
        
            getRequirements()
            // getOfferings()
            // getStatus()
        }
        
       
       

    }
    },[props.communityId])

    //Converting Image
function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

     //Get requirement by communityId
  const getRequirements = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getrequirementsbycommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:props.communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        
        setRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }


   //Get requirement not in community
   const getRequirementswithoutcommunity = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/getrequirementswithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddRequirements(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   //Get offering not in community
   const getOfferingsWithoutCommunity = async()=>{
     
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getofferingwithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddOfferings(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
   //Get connection not in community
   const getConnectionWithoutCommunity = async()=>{
    let apiURL=process.env.REACT_APP_API_URL + "/connection/getconnectionwithoutcommunity/"+ user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        console.log(repos.data)
        setOddConnections(repos.data)

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

   
// //Get offerings by id
const getOfferings = ()=>{
       
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getofferingsbycommunity/" +user._id ;
    let apiParams = {
        method:"GET",
        headers:{
            communityid:communityId,
        }
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
    
        setOfferings(repos.data)
         
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
 

  //Get Connection List
  const getStatus = ()=>{
 
    let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatusbycommunity/" + user._id;
    let apiParams = {
      method: "GET",
      headers:{
        communityid:communityId,
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
            
            setAllStatus(repos.data)
            
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

//
  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };


  const RemoveRequirement = (requirment)=>{
    let apiURL=process.env.REACT_APP_API_URL + "/requirement/removerequirementsbycommunity/"+ requirment._id ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
          getRequirements()

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
  

  const RemoveOffering = (offeringId)=>{
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/removeofferingsbycommunity/"+ offeringId._id ;
    let apiParams = {
        method:"POST",
        headers:{
            communityid:communityId,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // setRequirementStatus(repos.d)
          getOfferings()

        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
  
  // const RemoveConnection = (connectionId)=>{
  //   let apiURL=process.env.REACT_APP_API_URL + "/connection/removeConnectionsbycommunity/"+ connectionId._id ;
  //   let apiParams = {
  //       method:"POST",
  //       headers:{
  //           communityid:communityId,
  //           userid:user._id,
  //       },
  //   };
  //   fetch(apiURL, apiParams)
  //   .then((res)=> (res.status===200 ? res.json() : res.text()))
  //   .then((repos)=> {
  //       if (repos.data) {
  //         // setRequirementStatus(repos.d)
  //         getStatus()

  //       }else alert(repos.message || repos);  
  //   }) 
  //   .catch((err) => alert(err));
  // }

  const closeGridModel = () => setOpenGridModel(false);
  const closeGridOfferingModel = () => setOpenGridOfferingModel(false);
  const closeGridConnectionModel = () => setOpenGridConnectionModel(false);
  
//adding community to requirements 
  // const updateStatus = (requirementId)=>{
   
  //   let apiURL=process.env.REACT_APP_API_URL + "/requirement/addcommunitytorequirement/"+ requirementId ;
  //   let apiParams = {
  //       method:"POST",
  //       headers:{
  //           communityid:communityId,
  //           communityname:communityName,
  //       },
  //   };
  //   fetch(apiURL, apiParams)
  //   .then((res)=> (res.status===200 ? res.json() : res.text()))
  //   .then((repos)=> {
  //       if (repos.data) {
  //       console.log(repos.data)
  //         getRequirementswithoutcommunity()
  //         getRequirements()
  //       }else alert(repos.message || repos);  
  //   }) 
  //   .catch((err) => alert(err));
  // }

// //adding Community to Offerings
//   const updateOfferingStatus = (offeringId)=>{
   
//     let apiURL=process.env.REACT_APP_API_URL + "/offerings/addcommunitytooffering/"+ offeringId ;
//     let apiParams = {
//         method:"POST",
//         headers:{
//             communityid:communityId,
//             communityname:communityName,
//         },
//     };
//     fetch(apiURL, apiParams)
//     .then((res)=> (res.status===200 ? res.json() : res.text()))
//     .then((repos)=> {
//         if (repos.data) {
//         console.log(repos.data)
//           getOfferingsWithoutCommunity()
//           getOfferings()
//         }else alert(repos.message || repos);  
//     }) 
//     .catch((err) => alert(err));
//   }


//adding Community to Offerings
  // const updateConnectionStatus = (connectionId)=>{
   
  //   let apiURL=process.env.REACT_APP_API_URL + "/connection/addcommunitytoconnection/"+ connectionId ;
  //   let apiParams = {
  //       method:"POST",
  //       headers:{
  //           communityid:communityId,
  //           communityname:communityName,
  //           userid:user._id,
  //       },
  //   };
  //   fetch(apiURL, apiParams)
  //   .then((res)=> (res.status===200 ? res.json() : res.text()))
  //   .then((repos)=> {
  //       if (repos.data) {
  //       console.log(repos.data)
  //         getConnectionWithoutCommunity()
  //         getStatus()
  //       }else alert(repos.message || repos);  
  //   }) 
  //   .catch((err) => alert(err));
  // }

  // var cols = [

  //   {field:'id', headerName: 'id', hide: true },
  //   {field:'requirementId', headerName: 'reqId',hide: true  },
  //   {field:'requirementTitle', headerName: 'Title',width: 260,  },
  //   {field:'add', headerName: 'Add',width: 60, renderCell: (params) =>{
  //     return[
  //      <div>
  //     <AddIcon style={{color:"green"}} onClick={()=>{updateStatus(params.row.id);}}/>
  //     </div>
  //     ];},},  

  // ];

  // var colsOffering = [

  //   {field:'id', headerName: 'id', hide: true },
  //   {field:'offeringId', headerName: 'offId',hide: true  },
  //   {field:'offeringTitle', headerName: 'Title',width: 260,  },
  //   {field:'add', headerName: 'Add',width: 60, renderCell: (params) =>{
  //     return[
  //      <div>
  //     <AddIcon style={{color:"green"}} onClick={()=>{updateOfferingStatus(params.row.id);}}/>
  //     </div>
  //     ];},},  

  // ];

  // var colsConnection = [

  //   {field:'id', headerName: 'id', hide: true },
  //   {field:'connectionId', headerName: 'conId',hide: true  },
  //   {field:'name', headerName: 'Name',width: 260,  },
  //   {field:'add', headerName: 'Add',width: 60, renderCell: (params) =>{
  //     return[
  //      <div>
  //     <AddIcon style={{color:"green"}} onClick={()=>{updateConnectionStatus(params.row.id);}}/>
  //     </div>
  //     ];},},  

  // ];

//   const rowData = oddRequirements.filter(req=>req.customerId === user._id).map((re) => {
       
//     return{
   
//     id: re._id,
//     requirementId:re._id,
//     requirementTitle:re.requirementTitle,
  
//     };
//  });

 
//  const rowDataOffering = oddOfferings.filter(req=>req.vendorId === user._id).map((off) => {
       
//   return{
 
//   id: off._id,
//   offeringId:off._id,
//   offeringTitle:off.offeringTitle,

//   };
// });

//  const rowDataConnection = oddConnections.map((con) => {
       
//   return{
 
//   id:con._id,
//   connectionId:con._id,
//   name:con.user1Id===user._id?con.user2Name:con.user1Name,

//   };
// });



  const openGridModal = ()=>{

    setOpenGridModel(true)
    // getRequirementswithoutcommunity()
  }

  const openGridOfferingModal = ()=>{
    setOpenGridOfferingModel(true)
    getOfferingsWithoutCommunity()

  }

  const openGridConnectionModal = ()=>{
    setOpenGridConnectionModel(true)
    getConnectionWithoutCommunity()

  }

  const addBid=(id,title,createdById,createdByName)=>{
    setSelectedQuotation({id,title,createdById,createdByName});
    // getBids(id)
    setQuotationOpenModel(true);

    }

    const openViewMorePopUp = (rec)=>{
      setViewMoreData(rec)
      setViewMorePopup(true)
    }

  const showMonth = (month)=>{
  
    switch (month){
    case 0:
    return "Jan"
      break;
    case 1:
      return "Feb"
      break;
      case 2:
        return "Mar"
        break;
      case 3:
      return "Apr"
      break;
      case 4:
      return "May"
      break;
      case 5:
      return "Jun"
      break;
      case 6:
      return "Jul"
      break;
      case 7:
      return "Aug"
      break;
      case 8:
      return "Sep"
      break;
      case 9:
      return "Oct"
      break;
      case 10:
      return "Nov"
      break;
      case 11:
      return "Dec"
      break;
  }
  }
  

  return (
  <div>

<div id="community_Req__1">
	<div id="design_req">
  <svg class="cube_green_design_req" viewBox="2949.899 1057.904 2786.662 1897">
			<path id="cube_green_design_req" d="M 2950.0009765625 1139.474731445312 L 2951.015869140625 2954.90380859375 L 5736.56103515625 2954.90380859375 L 5736.56103515625 1112.510986328125 L 3159.4375 1112.510986328125 C 3159.4375 1112.510986328125 3140.576416015625 1115.422485351562 3140.03369140625 1103.343017578125 C 3139.4912109375 1091.263305664062 3140.03369140625 1068.553466796875 3140.03369140625 1068.553466796875 C 3140.03369140625 1068.553466796875 3141.84228515625 1057.923583984375 3123.40185546875 1057.923583984375 C 3104.961181640625 1057.923583984375 2984.79052734375 1057.923583984375 2984.79052734375 1057.923583984375 C 2984.79052734375 1057.923583984375 2966.853271484375 1056.95703125 2966.310791015625 1068.553466796875 C 2965.76806640625 1080.150146484375 2966.310791015625 1103.343017578125 2966.310791015625 1103.343017578125 C 2966.310791015625 1103.343017578125 2963.48681640625 1112.50341796875 2954.2080078125 1112.510986328125 C 2949.5458984375 1112.514892578125 2950.159912109375 1114.188720703125 2950.0009765625 1121.220458984375 C 2949.77099609375 1131.367309570312 2950.0009765625 1139.474731445312 2950.0009765625 1139.474731445312 Z">
			</path>
		</svg>
	</div>

	<div id="labels_grp_req">
		<div id="Offerings_req" onClick={()=>props.selectedTab('offerings')}>
			<span>Offerings</span>
		</div>

		<div id="Requirements_req">
			<span>Requirements</span>
		</div>

		<div id="Members_req" onClick={()=>props.selectedTab('members')}>
			<span>Members</span>
		</div>

	</div>
  <div id="Scroll_Group_req_">
  {requirements.map((req)=>(
  <div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
	<div id="overall_card_req_page" className='fadeInDown'>
		<svg class="full_card_back_bdr_req_">
			<linearGradient id="full_card_back_bdr_req_" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
				<stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#cfffd7" stop-opacity="1"></stop>
			</linearGradient>
			<rect id="full_card_back_bdr_req_" rx="10" ry="10" x="0" y="0" width="199" height="243">
			</rect>
		</svg>
		<svg class="card_title_bg_bdr">
			<rect id="card_title_bg_bdr" rx="0" ry="0" x="0" y="0" width="169.518" height="20.793">
			</rect>
		</svg>
		<div id="Website_Development_lbl_card_t">
			<span>{req.requirementTitle}</span>
		</div>
		<svg class="card_top_design_req_" viewBox="768 1996.457 199 47.484">
			<linearGradient id="card_top_design_req_" spreadMethod="pad" x1="0" x2="1" y1="0" y2="0.639">
				<stop offset="0" stop-color="#cfffd7" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#80ff95" stop-opacity="1"></stop>
			</linearGradient>
			<path id="card_top_design_req_" d="M 768 1996.45654296875 L 967 1996.45654296875 L 967 2026.984619140625 L 933.4641723632812 2043.941040039062 L 809.4276123046875 2043.941040039062 L 768 2026.984619140625 L 768 1996.45654296875 Z">
			</path>
		</svg>
		<div id="n_000_inr_lbl">
			<span>{req.budget}</span>
		</div>
		<img title="Budget" id="req_1_budget_img" src="req_1_budget_img.png" srcset="req_1_budget_img.png 1x" />
			
		<img title="Delivery Deadline" id="Delivery_Deadline_img_req" src="Delivery_Deadline_img_req.png" srcset="Delivery_Deadline_img_req.png 1x" />
			
		<img title="Delivery Location" id="delivery_location_img_req" src="delivery_location_img_req.png" srcset="delivery_location_img_req.png 1x" />
			
		<div id="n_4_HR_time_req">
			<span>{req.deliveryDeadline && new Date(req.deliveryDeadline).getDate() +" "+ showMonth(new Date(req.deliveryDeadline).getMonth())}</span>
		</div>
		<div id="Online_Deployment_lbl_req">
			<span>{req.deliveryLocation}</span>
		</div>
			
		<button title="Bid" id="bid_btn_grp_req" onClick={()=>addBid(req._id,req.requirementTitle,req.customerId,req.customerName)}>
			<svg class="bid_bdr_req">
				<rect id="bid_bdr_req" rx="13.5" ry="13.5" x="0" y="0" width="72" height="27">
				</rect>
			</svg>
			<div id="Bid_lbl_req">
				<span>Bid</span>
			</div>
			<img title="Bids" id="bid_btn_img" src="bid_btn_img.png" srcset="bid_btn_img.png 1x" />
		</button>
		<button title="Details" id="detail_btn_grp" onClick={() =>{openViewMorePopUp(req)}}>
			<svg class="detail_bdr_req">
				<rect id="detail_bdr_req" rx="13.5" ry="13.5" x="0" y="0" width="72" height="27">
				</rect>
			</svg>
			<div id="Details_lbl_req">
				<span>Details</span>
			</div>
			<img title="View More" id="detail_btn_req" src="detail_btn_req.png" srcset="detail_btn_req.png 1x" />
				
		</button>
		
    <ImageSet imageArray={req.requirementsImagesUrl}/>
	</div>
  </div>
  ))}
	</div>
<OfferingsSideUI />

</div>


<QuotationPopUpPC  openQuoPopUp={quotationOpenModel}  selectedQuotation={selectedQuotation} closeQuoPopUp={()=>{setQuotationOpenModel(false);getRequirements()}}/>


{/* View More PopUp */}

<RequirementsViewMorePopUp type="CR" openViewMore={viewMorePopup} viewMoreData={viewMoreData} closeViewMore={()=>setViewMorePopup(false)}/> 

{/* Add Communities Pop up */}
 <AddCommmunitiesReqPopUp communityId={props.communityId} communityName={props.communityName} openComReq={openGridModel} closeComReq={()=>{setOpenGridModel(false);getRequirements()}}/>

  </div>
  )
}

export default CommunitiesRequirementsPageNewUI
