import React, { useEffect, useState, useContext} from 'react'
import '../../styles/MyPortfolioSmallReqCard.css';
import { UserContext } from '../UserContext';
import MyOffandComOffNewUI from './MyOffandComOffNewUI';
import MyReqandComReqNewUI from './MyReqandComReqNewUI';
import MyEventsandComEventsUI from './MyEventsandComEventsUI';

const MyPortfolioUI = () => {

    const [selectedTab,setSelectedTab] = useState("offerings");


  return (
    <div>
    {selectedTab==="offerings" ? <MyOffandComOffNewUI selectedTab={(rec)=>setSelectedTab(rec)} />:""}
    {selectedTab==="requirements" ?  <MyReqandComReqNewUI selectedTab={(rec)=>setSelectedTab(rec)}/>:""}
    {selectedTab==="events" ?   <MyEventsandComEventsUI selectedTab={(rec)=>setSelectedTab(rec)}/>:""}
    </div>
  )
}

export default MyPortfolioUI
