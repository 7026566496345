import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/PeoplesConnectionsMobUI.css';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import MyConnectionsMobUI from './MyConnectionsMobUI';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PeoplesConnectionsMobUI = (props) => {

//UseState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [users,setUsers] = useState("");
const [ allStatus,setAllStatus] = useState("");
const [requests,setRequests]= useState([]);
const [selectedCommunity,setSelectedCommunity] = useState([])
const [communities,setCommunities] = useState([]);


  return (
   
<div id="people_con">
	<div id="heading_grp_conreqsug_mob_con">
		<div id="req_conreqsug_mob_con" onClick={()=>props.selectedTab("Requests")}>
			<span>Requests</span>
		</div>
		<div id="con_grp_conreqsug_mob_con">
			<div id="con_conreqsug_mob_con">
				<span>Connections</span>
			</div>
		</div>
		<div id="sugg_conreqsug_mob_con" onClick={()=>props.selectedTab("Suggestions")}>
			<span>Suggestions</span>
		</div>
		<svg class="underline_conreqsug_mob_con">
			<rect id="underline_conreqsug_mob_con" rx="0" ry="0" x="0" y="0" width="82" height="3">
			</rect>
		</svg>
	</div>
	<MyConnectionsMobUI />
</div>
  
  )
}

export default PeoplesConnectionsMobUI
