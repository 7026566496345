import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../styles/MyRequestsUI.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import MyConnectionsUI from './MyConnectionsUI';
import Switch from '@mui/material/Switch';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MyRequestsUI = () => {

//UseState
var navigate = useNavigate();
const { user, setUser} = useContext(UserContext);
const [toggle,setToggle] = useState(false);
const [ allStatus,setAllStatus] = useState("");
const [requests,setRequests]= useState([]);
const [selectedCommunity,setSelectedCommunity] = useState([])
const [communities,setCommunities] = useState([]);


 //UseEffect
 useEffect(()=>{
   
 
   if(user){
   
    // getStatus()
     getRequests()
    // getCommunities()
   }
},[user])
  
const ConnectionReq = (id,status)=>{  
if(selectedCommunity==="undefined"){
  setSelectedCommunity(JSON.parse([]))
}
    let apiURL=process.env.REACT_APP_API_URL + "/connection/updateconnection/"+id;
    let apiParams = {
        method:"POST",
        headers:{
          status:status,
          user2selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        },
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        alert("Action Updated Successfully!!");
          
      //props.showAlert("Job Added Successfully","success")
         setTimeout(() => {
          // getStatus()
           getRequests()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
  
}


const getStatus = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          console.log(repos.data)
          setAllStatus(repos.data)
          
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};

//Converting Image
function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  var base64Flag = "data:image/jpeg;base64,";
  return base64Flag + window.btoa(binary);
}


const getRequests = ()=>{
 
  let apiURL = process.env.REACT_APP_API_URL + "/connection/getrequests/" + user._id;
  let apiParams = {
    method: "GET",
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
          // console.log(repos.data)
          setRequests(repos.data)
          
      } else alert(repos.message || repos);
    })
    .catch((err) => alert(err));
};


//Get my Communities
const getCommunities = ()=>{

  let apiURL=process.env.REACT_APP_API_URL + "/community/getmycommunity/"+user._id; 

  fetch(apiURL)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
          console.log(repos.data)
        setCommunities(repos.data)
      
      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}




  return (
    <>
    <div style={{height: "100%",overflowY: "auto"}}>
    {(requests||[]).map((rs)=>(
        
        <div style={{display:"inline-block",padding:"5px",textAlign:"center"}}>
    <div id="Border_box_requests" className='slideInRight'>
		
		<svg class="border_req_outer" onClick={()=>navigate("../"+(rs.user1Name===user.name ? rs.user2Image.nickName:rs.user1Image.nickName))}>
			<rect id="border_req_outer" rx="0" ry="0" x="0" y="0" width="359" height="203">
			</rect>
		</svg>
    
		<img id="porfile_img_req_" src={rs.user1Name===user.name ?  (rs.user2Image.imageUrl && rs.user2Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rs.user2Image.imageUrl.split('/')[5]):"default.jpg":(rs.user1Image.imageUrl && rs.user1Image.imageUrl!=="") ?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rs.user1Image.imageUrl.split('/')[5]):"default.jpg"}  />
			
		<svg class="border_name_lbl_req">
			<rect id="border_name_lbl_req" rx="0" ry="0" x="0" y="0" width="220" height="30">
			</rect>
		</svg>
		<div id="Mohammed_Zaid_lbl_req">
			<span>{rs.user1Name===user.name ? rs.user2Name:rs.user1Name}</span>
		</div>
		<div id="Profession_lbl_req">
			<span>Profession :</span>
		</div>
		<div id="Connected_On_lbl_req">
			<span>Request Date:</span>
		</div>
		<div id="Software_Developer_lbl_req">
			<span>{rs.user1Name===user.name ?  rs.user2Image ?rs.user2Image.profession:"":rs.user1Image ? rs.user1Image.profession:""}</span>
		</div>
		<div id="date_req_lbl">
			<span>{rs.createdAt && (rs.createdAt).substring(0,10)}</span>
		</div>
    
    {user.name==rs.user1Name ?
    	<button id="border_cancel_req" onClick={(e) =>{ConnectionReq(rs._id,"Cancelled")}}>
			<svg class="border_req_cancel">
				<rect id="border_req_cancel" rx="9" ry="9" x="0" y="0" width="70" height="26">
				</rect>
			</svg>
			<div id="Cancel_lbl_req">
				<span>Cancel</span>
			</div>
		</button>:<button id="border_cancel_req" onClick={(e) =>{ConnectionReq(rs._id,"Connected")}}>
			<svg class="border_req_cancel">
				<rect id="border_req_cancel" rx="9" ry="9" x="0" y="0" width="70" height="26">
				</rect>
			</svg>
			<div id="Cancel_lbl_req">
				<span>Connect</span>
			</div>
		</button>}
    {user.name!=rs.user1Name ?	<button id="group_block_req" onClick={(e) =>{ConnectionReq(rs._id,"Blocked")}}>
			<svg class="border_block_req">
				<rect id="border_block_req" rx="9" ry="9" x="0" y="0" width="70" height="26">
				</rect>
			</svg>
			<div id="Block_req_lbl">
				<span>Block</span>
			</div>
		</button>:""}
    {user.name!=rs.user1Name ?	<button id="Group_ignore_req" onClick={(e) =>{ConnectionReq(rs._id,"Ignored")}}>
			<svg class="border_ignore_req">
				<rect id="border_ignore_req" rx="9" ry="9" x="0" y="0" width="70" height="26">
				</rect>
			</svg>
			<div id="Ignore_lbl_req">
				<span>Ignore</span>
			</div>
		</button>:""}
		<div id="Scroll_intro_req">
			<div id="intro_value_req">
				<span>{rs.intro}</span>
			</div>
		</div>
	</div> 
   </div>
   ))}
    </div>
    </>
  )
}

export default MyRequestsUI
