import React, { useState, useEffect, useContext } from "react";
import "../../../styles/HarmonyHavenMob.css";
import { UserContext } from '../../UserContext';
import { useNavigate,useLocation, Navigate } from "react-router-dom";

const HarmonyHavenMob = (props) => {
  return (
    <div>
    <div id="web_4_mob">
	<nav role="navigation">
	<div id="menuToggle">
	
	  <input type="checkbox" />
	
	  <span></span>
	  <span></span>
	  <span></span>
	
	  <ul id="menu">
	
	  <a href="#profile_pic_logo_web_4_mob" target=""><li>Home</li></a>
		<a href="#oofering_lbl_med_web_4_mob" target=""><li>Offerings</li></a>
		<a href="#footer_med_web_4_mob" target=""><li>Contact</li></a>
		{/* <a href="#bdr_contact_page_website_2mob" target=""><li>Contact</li></a>  */}
	  </ul>
	</div>
  </nav>
	<svg class="design_main_page_web_4_theme_m" viewBox="5100.037 16976.803 375 294.785">
		<path id="design_main_page_web_4_theme_m" d="M 5100.037109375 17271.587890625 L 5143.21826171875 17238.17578125 C 5143.21826171875 17238.17578125 5145.6435546875 17235.65625 5152.41259765625 17235.65625 C 5159.181640625 17235.65625 5323.54443359375 17235.607421875 5323.54443359375 17235.607421875 C 5323.54443359375 17235.607421875 5327.1435546875 17236.14453125 5327.0380859375 17232.8515625 C 5326.931640625 17229.55859375 5327.0380859375 17117.59375 5327.0380859375 17117.59375 C 5327.0380859375 17117.59375 5327.1416015625 17115.93359375 5328.59228515625 17114.62890625 C 5330.04296875 17113.322265625 5475.037109375 16976.802734375 5475.037109375 16976.802734375 L 5475.037109375 17271.587890625 L 5100.037109375 17271.587890625 Z">
		</path>
	</svg>
	<img id="markus-wink_img_centre_med_mob" src="./Themes/HarmonyHaven/markus-wink_img_centre_med_mob.png" srcset="./Themes/HarmonyHaven/markus-wink_img_centre_med_mob.png 1x" />
		
	<div id="follow_us_btn__grp_web_4mob">
		<svg class="bdr_med_web_4_mob">
			<rect id="bdr_med_web_4_mob" rx="4" ry="4" x="0" y="0" width="76" height="16">
			</rect>
		</svg>
		<div id="Follow_Us_med_web_4_mob">
			<span>Follow Us </span>
		</div>
		<svg class="arrow_follow_us_med_web_4_mob" viewBox="0 2.647 7.615 7.422">
			<path id="arrow_follow_us_med_web_4_mob" d="M 3.237651109695435 3.144386529922485 L 3.614951848983765 2.767085552215576 C 3.774710178375244 2.607326507568359 4.033042430877686 2.607326507568359 4.19110107421875 2.767085552215576 L 7.495034694671631 6.069319725036621 C 7.654793262481689 6.22907829284668 7.654793262481689 6.487408638000488 7.495034694671631 6.645467758178711 L 4.19110107421875 9.949402809143066 C 4.03134298324585 10.10915946960449 3.773010492324829 10.10915946960449 3.614951848983765 9.949402809143066 L 3.237650871276855 9.572101593017578 C 3.076193332672119 9.410642623901367 3.079592227935791 9.147212982177734 3.244448900222778 8.989153861999512 L 5.292412281036377 7.038065910339355 L 0.407893031835556 7.038065910339355 C 0.1818523108959198 7.038065910339355 0 6.85621166229248 0 6.630172729492188 L 0 6.08631420135498 C 0 5.860274314880371 0.1818523108959198 5.678421974182129 0.407893031835556 5.678421974182129 L 5.292412281036377 5.678421974182129 L 3.244449377059937 3.727333784103394 C 3.077892780303955 3.56927490234375 3.074493885040283 3.305843353271484 3.237651109695435 3.144386529922485 Z">
			</path>
		</svg>
	</div>
	<div id="Heading_left_med_web_4_mob">
		<span>{props.data.businessName && props.data.businessName.length > 0?props.data.businessName.split(" ")[0]:props.data.name}</span>
	</div>
	<div id="Heading_med_web_4mob">
		<span>{props.data.businessName && props.data.businessName.length > 0?props.data.businessName.split(" ")[1]:""}</span>
	</div>
	<img id="marijuana_img_bdr_med_web_4_mo" src="./Themes/HarmonyHaven/marijuana_img_bdr_med_web_4_mo.png" srcset="./Themes/HarmonyHaven/marijuana_img_bdr_med_web_4_mo.png 1x" />
		
	<div id="Scroll_intro_web_4_mob">
		<div id="Lorem_ipsum_dolor_sit_amet_hh_mob">
			<span>{props.data.introduction}</span>
		</div>
	</div>
	<div id="text_our_partner_bdr_med_web_4">
		<span>Our Amazing Partners</span>
	</div>
	<div id="partner_scroll_web_4">
	<marquee direction="up">
    {props.partnersData && props.partnersData.map(partner=>
        <div onClick={()=>window.location.href="https://"+partner.nickName+".operate.live"}>
		    <div id="g_and_google_grp_mob">
			<div id="google_lbl_partnermed_web_4_mo">
				<span>{partner.businessName && partner.businessName.length>0?partner.businessName:partner.name}</span>
			</div>
			<img id="google_1img_med_web_4_mob" src={partner.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(partner.imageUrl.split('/')[5]):"./Themes/office.png"} />
				
		    </div>
		</div>
            )}
    </marquee>
	</div>

	<div id="Scroll_ll_misvispri_mob">
		<div id="mission_all_med_web_4mob">
			<svg class="bdr_mission_med_web_4mob">
				<rect id="bdr_mission_med_web_4mob" rx="10" ry="10" x="0" y="0" width="189" height="299">
				</rect>
			</svg>
			<img id="box_1_website_med_web_4_mob" src="./Themes/HarmonyHaven/box_1_website_med_web_4_mob.png" srcset="./Themes/HarmonyHaven/box_1_website_med_web_4_mob.png 1x" />
				
			<div id="mission_lbl_med_web_4_mob">
				<span>Mission</span>
			</div>
			<div id="Scroll_mission_med_web_4_mob">
				<div id="Lorem_ipsum_dolor_sit_amet_con_mob">
					<span>{props.data.mission}</span>
				</div>
			</div>
		</div>
		<div id="vision_grp_all_med_web_4_mob">
			<svg class="bdr_vision_back_med_web_4mob">
				<rect id="bdr_vision_back_med_web_4mob" rx="10" ry="10" x="0" y="0" width="189" height="299">
				</rect>
			</svg>
			<img id="box_2_website_1_med_web_4_mob" src="./Themes/HarmonyHaven/box_2_website_1_med_web_4_mob.png" srcset="./Themes/HarmonyHaven/box_2_website_1_med_web_4_mob.png 1x" />
				
			<div id="vision_lbl_med_web_4_mob">
				<span>Vision</span>
			</div>
			<div id="Scroll_vision_med_web_4_mob">
				<div id="Lorem_ipsum_dolor_sit_amet_con_cl">
					<span>{props.data.vision}</span>
				</div>
			</div>
		</div>

		<div id="principle_grp_all_med_web_4_mo">
			<svg class="princi_bdr_akll_med_web_4_mob">
				<rect id="princi_bdr_akll_med_web_4_mob" rx="10" ry="10" x="0" y="0" width="189" height="299">
				</rect>
			</svg>
			<img id="box_3_off_med_web_4_mob_op_liv" src="./Themes/HarmonyHaven/box_3_off_med_web_4_mob_op_liv.png" srcset="./Themes/HarmonyHaven/box_3_off_med_web_4_mob_op_liv.png 1x" />
				
			<div id="princi_med_web_4_mob_lbl">
				<span>Principles</span>
			</div>
			<div id="Scroll_prrinci_details_med_mob">
				<div id="Lorem_ipsum_dolor_sit_amet_con_cr">
					<span>{props.data.principles}</span>
				</div>
			</div>
		</div>
	</div>
	<div id="oofering_lbl_med_web_4_mob">
		<span>My Offerings</span>
	</div>

	<div id="Scroll_offering_med_web_4_mob">
	{props.offeringsData.map((rec,idx)=>(
		<div id="offering_crd_1_grp_med_web_4_m">
			<svg class="title_bdr_med_web_4_mob">
				<rect id="title_bdr_med_web_4_mob" rx="7" ry="7" x="0" y="0" width="172" height="32">
				</rect>
			</svg>
			<div id="BiziBees_PVT_LTD_med_web_4_mob">
				<span>{rec.offeringTitle}</span>
			</div>
			<img id="img_border_med_web_4_mob_of" src="./Themes/HarmonyHaven/img_border_med_web_4_mob_of.png" srcset="./Themes/HarmonyHaven/img_border_med_web_4_mob_of.png 1x" />
				
			<img id="chuttersna_med_web_4_mob_img" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"./Themes/HarmonyHaven/profilebackgroundEm.png" } />
				
		</div>
		))}
	</div>

	<div id="map_grp_mob_web_4">
		
		<iframe class="map_area_med_web_4_mob" width="600" height="450"  frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"}>
	<rect id="map_area_med_web_4_mob" rx="0" ry="0" x="0" y="0" width="375" height="330">
		</rect>
	</iframe>
		<svg class="map_top_med_web_4_mob" viewBox="-30.902 0 374.902 23">
			<path id="map_top_med_web_4_mob" d="M -30.90185546875 0 L -30.90185546875 23 L 344 22.78886604309082 L -30.90185546875 0 Z">
			</path>
		</svg>
		<svg class="map_below_desion_med_web_4_mob" viewBox="0 0 374.902 23">
			<path id="map_below_desion_med_web_4_mob" d="M 374.90185546875 0 L 0 0 L 374.90185546875 23 L 374.90185546875 0 Z">
			</path>
		</svg>
	</div>
	<svg class="medincine_bdr_web_4_mob">
		<rect id="medincine_bdr_web_4_mob" rx="0" ry="0" x="0" y="0" width="375" height="55">
		</rect>
	</svg>
	<marquee loop='999' scrollamount='15' id="Medicine_is_not_only_a_science_mob">
		<span>"Medicine is not only a science; it is an art. It does not consist of compounding pills and plasters; it deals with the very processes of life, which must be understood before they may be guided."</span>
	</marquee>
	<svg class="footer_med_web_4_mob">
		<rect id="footer_med_web_4_mob" rx="0" ry="0" x="0" y="0" width="375" height="500">
		</rect>
	</svg>
	<div id="n_234567890_mobile_number_mob">
		<span>{props.data.businessPhone}</span>
	</div>
	<div id="affanghanioperatelive_email_mob">
		<span>{props.data.businessEmail}</span>
	</div>
	<div id="address_med_web_4_mob">
		<span>{props.data.businessAddress}</span>
	</div>
	<img id="location_img_med_web_4_mob" src="./Themes/HarmonyHaven/location_img_med_web_4_mob.png" srcset="./Themes/HarmonyHaven/location_img_med_web_4_mob.png 1x" />
		
	<img id="email_address_med_web_4_mob" src="./Themes/HarmonyHaven/email_address_med_web_4_mob.png" srcset="./Themes/HarmonyHaven/email_address_med_web_4_mob.png 1x" />
		
	<img id="phone_number_med_web_4_mob" src="./Themes/HarmonyHaven/phone_number_med_web_4_mob.png" srcset="./Themes/HarmonyHaven/phone_number_med_web_4_mob.png 1x" />
		
	<div id="Connect_with_us_and_unlock_mob">
		<span>Discover a world of limitless possibilities by connecting with us today!</span>
	</div>
	<div id="socials_grp_med_web_4_mob">

		{props.data.linkedInUrl?	
		<div title="LinkedIn" id="linked_in_grp_med_web_4_mob">
			<svg class="Ellipse_5_med_web_4_pc">
				<ellipse id="Ellipse_5_med_web_4_pc" rx="25" ry="25" cx="25" cy="25">
				</ellipse>
			</svg>
			<div id="linked_in_website_4_layout_mob">
				<a href={props.data.instagramUrl} target="_blank">
				<svg class="n_website_2_layout" viewBox="-15.255 0 16.092 15.182">
					<path id="n_website_2_layout" d="M -5.186071872711182 9.679508821136551e-07 C -7.904781818389893 9.679508821136551e-07 -9.60834789276123 1.472553014755249 -9.923189163208008 2.504196882247925 L -9.923189163208008 0.1445503830909729 L -15.2549991607666 0.1445503830909729 C -15.18569564819336 1.397309064865112 -15.2549991607666 15.18160820007324 -15.2549991607666 15.18160820007324 L -9.923189163208008 15.18160820007324 L -9.923189163208008 7.044621467590332 C -9.923189163208008 6.592491626739502 -9.944971084594727 6.140363693237305 -9.807681083679199 5.815624713897705 C -9.440696716308594 4.911367893218994 -8.653267860412598 3.972789287567139 -7.237480163574219 3.972789287567139 C -5.38672399520874 3.972789287567139 -4.544512748718262 5.363495349884033 -4.544512748718262 7.400383949279785 L -4.544512748718262 15.18160820007324 L 0.8368034362792969 15.18160820007324 L 0.8368034362792969 6.820206165313721 C 0.8368034362792969 2.162956237792969 -1.813262462615967 9.679508821136551e-07 -5.186071872711182 9.679508821136551e-07">
					</path>
				</svg>
			</a>
				<svg class="linked_in_i_down" viewBox="0.931 -22.783 4.746 15.038">
					<path id="linked_in_i_down" d="M 0.9310001134872437 -7.745282173156738 L 5.676695346832275 -7.745282173156738 L 5.676695346832275 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -7.745282173156738 Z">
					</path>
				</svg>
				<svg class="top_dot_i_website_2_layout" viewBox="-4.392 0 5.761 5.218">
					<path id="top_dot_i_website_2_layout" d="M -1.493098974227905 0 C -3.245508193969727 0 -4.392000198364258 1.125370144844055 -4.392000198364258 2.611123323440552 C -4.392000198364258 4.06783390045166 -3.278510093688965 5.217626571655273 -1.560423135757446 5.217626571655273 L -1.526101112365723 5.217626571655273 C 0.2579902410507202 5.217626571655273 1.368839383125305 4.063873767852783 1.368839383125305 2.606502532958984 C 1.334516644477844 1.122730016708374 0.2579902410507202 0 -1.493098974227905 0">
					</path>
				</svg>
			</div>
		</div>
		:""}

		{props.data.instagramUrl?	
		<div title="Instagram" id="Insta_grp_med_web_4_mob">
			<svg class="Ellipse_4_med_web_4_pc">
				<ellipse id="Ellipse_4_med_web_4_pc" rx="25" ry="25" cx="25" cy="25">
				</ellipse>
			</svg>
			<a href={props.data.instagramUrl} target="_blank">
			<img id="instagram_website_4_layout_mob" src="./Themes/HarmonyHaven/instagram_website_4_layout.png" srcset="./Themes/HarmonyHaven/instagram_website_4_layout.png 1x" />
				
			</a>
		</div>
		:""}

		{props.data.whatsAppNumber?
		<div title="WhatsApp" id="whatsapp_grp_med_web_4_mob">
			<svg class="Ellipse_3_med_web_4_pc">
				<ellipse id="Ellipse_3_med_web_4_pc" rx="25" ry="25" cx="25" cy="25">
				</ellipse>
			</svg>
			<a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
			<img id="whatsapp_lbl_website4_layout_mob" src="./Themes/HarmonyHaven/whatsapp_lbl_website4_layout.png" srcset="./Themes/HarmonyHaven/whatsapp_lbl_website4_layout.png 1x" />
			</a>
		</div>
		:""}
	</div>

	<img id="quote_left_med_web_4_mob" src="./Themes/HarmonyHaven/quote_left_med_web_4_mob.png" srcset="./Themes/HarmonyHaven/quote_left_med_web_4_mob.png 1x" />
		
	<img id="quote_right_med_web_4_mob" src="./Themes/HarmonyHaven/quote_right_med_web_4_mob.png" srcset="./Themes/HarmonyHaven/quote_right_med_web_4_mob.png 1x" />
		
	<a href="https://operate.live" target="_blank">
	<div id="n_Copyright_Operate_Live_med_mob">
		<span>© Copyright Operate Live (OPC) Private Limited.<br/>All Rights Reserved</span>
	</div>
	</a>
	<img id="profile_pic_logo_web_4_mob" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/HarmonyHaven/user_pfp_profile_page.png"} />
</div>
    </div>
  )
}

export default HarmonyHavenMob
