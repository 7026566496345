import React,{useState,useEffect,useContext} from 'react';
import { UserContext } from '../UserContext';
import '../../styles/SuggestionProfilePCUI.css'
import { useNavigate } from "react-router-dom";

const SuggestionProfilePCUI = () => {

    const { user, setUser} = useContext(UserContext);
    const [suggestion,setSuggestion] = useState([])
    var navigate = useNavigate();

    useEffect(()=>{
        if(user)
        getAllUsers()
    },[user])

    const getAllUsers = ()=>{
        let apiURL = process.env.REACT_APP_API_URL + "/users/getLatestUserDetails";
        let apiParams = {
          method: "GET",
        };
        fetch(apiURL, apiParams)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
                console.log(repos.data)
                setSuggestion(repos.data)
            } else alert(repos.message || repos);
          })
          .catch((err) => alert(err));
    };

    
//Converting Image
function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }
  
  return (
    <div>
      <div id="sugg">
	<div id="suggestions_grp_profile">
		<svg class="suggestions_bdr">
			<rect id="suggestions_bdr" rx="15" ry="15" x="0" y="0" width="326" height="359">
			</rect>
		</svg>
		<div id="Suggestions_lbl_profile">
			<span>Suggestions</span>
		</div>
		<div id="suggestions_scroll_profile_pag">
        {suggestion.filter((rec)=>rec._id!=user._id).map((sug)=>(
			<div id="card_1_suggestion" title={sug.introduction} onClick={()=>navigate("/"+sug.nickName)}>
				{/* <svg class="dow_shadow_sugg">
					<rect id="dow_shadow_sugg" rx="37" ry="37" x="0" y="0" width="227" height="77">
					</rect>
				</svg> */}
				<svg class="bg_shadow_sugg">
					<rect id="bg_shadow_sugg" rx="37" ry="37" x="0" y="0" width="251" height="94">
					</rect>
				</svg>
				<img id="img_bdr_suggestion" src={sug.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(sug.imageUrl.split('/')[5]):"user_pfp_profile_page.png"} />
					
				{/* <svg class="sugg_down_bdr_">
					<linearGradient id="sugg_down_bdr_" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
						<stop offset="0" stop-color="#252e48" stop-opacity="0"></stop>
						<stop offset="1" stop-color="#0d1f55" stop-opacity="1"></stop>
					</linearGradient>
					<rect id="sugg_down_bdr_" rx="29.5" ry="29.5" x="0" y="0" width="251" height="59">
					</rect>
				</svg> */}
				<div id="Mark_Damon_lbl_suggestions">
					<span><marquee>{sug.name} - {sug.profession}</marquee></span>
				</div>
			</div>
            ))
        }
		</div>
	</div>
</div>
    </div>
  )
}

export default SuggestionProfilePCUI
