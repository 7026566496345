import React, { useState, useEffect, useContext } from "react";
import "../../../styles/KnowledgeKorner.css";
import { UserContext } from '../../UserContext';
import { useNavigate,useLocation, Navigate } from "react-router-dom";

const KnowledgeKorner = (props) => {

  return (
    <div id="KnowledgeKorner">
	<svg class="grass_shadow_website_6_pc" viewBox="4476.914 17891.283 1373.327 59.191">
		<path id="grass_shadow_website_6_pc" d="M 4482 17891.283203125 L 5850.2412109375 17891.283203125 L 5850.2412109375 17941.55078125 C 5850.2412109375 17941.55078125 5412.19091796875 17955.9140625 5249.41845703125 17927.189453125 C 5086.64599609375 17898.46484375 4941.0576171875 17921.02734375 4941.0576171875 17921.02734375 C 4941.0576171875 17921.02734375 4811.40234375 17951.53515625 4744.9404296875 17950.4453125 C 4678.478515625 17949.35546875 4476.9140625 17921.02734375 4476.9140625 17921.02734375 L 4476.9140625 17891.283203125 L 4482 17891.283203125 Z">
		</path>
	</svg>
	<svg class="sky_img_website_6_pc">
		<rect id="sky_img_website_6_pc" rx="0" ry="0" x="0" y="0" width="1373" height="716">
		</rect>
	</svg>
	<svg class="grass_design_website_6_pc" viewBox="4476.914 17891.283 1373.327 59.191">
		<path id="grass_design_website_6_pc" d="M 4482 17950.474609375 L 5850.2412109375 17950.474609375 L 5850.2412109375 17900.20703125 C 5850.2412109375 17900.20703125 5412.19091796875 17885.84375 5249.41845703125 17914.568359375 C 5086.64599609375 17943.29296875 4941.0576171875 17920.73046875 4941.0576171875 17920.73046875 C 4941.0576171875 17920.73046875 4811.40234375 17890.22265625 4744.9404296875 17891.3125 C 4678.478515625 17892.40234375 4476.9140625 17920.73046875 4476.9140625 17920.73046875 L 4476.9140625 17950.474609375 L 4482 17950.474609375 Z">
		</path>
	</svg>
	
    <a href="#Anime_Edit_1_website_6_pc">
	<div id="home_shadow_space_website_6">
		<div id="Home_shadow_space_website_6_cd">
			<span>Home</span>
		</div>
		<img id="home-icon-shadow_space_website" src="./Themes/KnowledgeKorner/home-icon-shadow_space_website.png" srcset="./Themes/KnowledgeKorner/home-icon-shadow_space_website.png 1x" />
			
	</div>
    </a>
    <a href="#Our_Services_lbl_website_6_pc">
	<div id="offering_shadow_space_website_">
		<div id="Offerings_shadow_space_website">
			<span>Offerings</span>
		</div>
		<img id="discount_shadow_space_website_" src="./Themes/KnowledgeKorner/discount_shadow_space_website_.png" srcset="./Themes/KnowledgeKorner/discount_shadow_space_website_.png 1x" />
			
	</div>
    </a>
    <a href="#oofering_lbl_med_knowledge_pc">
	<div id="event_grp_shadow_space_website_kk_pc">
		<div id="Events_shadow_space_website_6">
			<span>Events</span>
		</div>
		<img id="election-event-on-a-calendar-s" src="./Themes/KnowledgeKorner/election-event-on-a-calendar-s.png" srcset="./Themes/KnowledgeKorner/election-event-on-a-calendar-s.png 1x" />
			
	</div>
    </a>
    <a href="#footer_grp_web_6_pc">
	<div id="contact_grpshadow_space_websit">
		<div id="Contact_lbl_shadow_space_websi">
			<span>Contact</span>
		</div>
		<img id="contact_img_shadow_space_websi" src="./Themes/KnowledgeKorner/contact_img_shadow_space_websi.png" srcset="./Themes/KnowledgeKorner/contact_img_shadow_space_websi.png 1x" />
			
	</div>
    </a>
	<img id="Anime_Edit_1_website_6_pc" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/KnowledgeKorner/Anime_Edit_1_website_6_pc.png"}  />
		
	<div id="LETS_LEARN_AND_GAIN__website_6">
		<span>LET'S LEARN AND GAIN NEW <br/>KNOWLEDGE AND ABILITIES</span>
	</div>
	<div id="Lets_discover_new_lbl_website_">
		<span>Lets discover new knowledge and new friends and have a <br/>teaming experience with teachers</span>
	</div>
	<div id="Trusted_by_our_partners_lbl_we">
		<span>Trusted by our partners</span>
	</div>
	<div id="Scroll_web_sug_pc_6">
    {props.partnersData && props.partnersData.map(partner=>
        <div onClick={()=>window.location.href="https://"+partner.nickName+".operate.live"}>
		<div id="partner_grp_website_6_pc">
			<img id="Anime_Edit_img_website_6_pc" src={partner.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(partner.imageUrl.split('/')[5]):"./Themes/office.png"} />
				
			<div id="Mohammed_Affan_lbl_website_6_p">
				<span>{partner.businessName && partner.businessName.length>0?partner.businessName:partner.name}</span>
			</div>
		</div>
        </div>
            )}
	</div>
	<div id="Our_Services_lbl_website_6_pc">
		<span>Our Services</span>
	</div>
	<div id="mission_card_grp_website_6_pc">
		<svg class="mission_overall_bdr_website_6_">
			<rect id="mission_overall_bdr_website_6_" rx="10" ry="10" x="0" y="0" width="233" height="325">
			</rect>
		</svg>
		<svg class="mission_img_bdr_website_6_pc">
			<rect id="mission_img_bdr_website_6_pc" rx="5" ry="5" x="0" y="0" width="50" height="50">
			</rect>
		</svg>
		<div id="Mission_lbl_website_6_pc">
			<span>Mission</span>
		</div>
		<div id="Scroll_mision_pc_web_6">
			<div id="Watashitachi_no_dry_no_zaido_o">
				<span>{props.data.mission}</span>
			</div>
		</div>
		<img id="principle_2_k_k_websi_pc" src="./Themes/KnowledgeKorner/principle_2_shadow_space_websi.png" srcset="./Themes/KnowledgeKorner/principle_2_shadow_space_websi.png 1x" />
			
	</div>
	<div id="principle_card_grp_website_6_p">
		<svg class="overall_princi_card_website_6_">
			<rect id="overall_princi_card_website_6_" rx="10" ry="10" x="0" y="0" width="233" height="325">
			</rect>
		</svg>
		<svg class="principle_img_bdr_website_6_pc">
			<rect id="principle_img_bdr_website_6_pc" rx="5" ry="5" x="0" y="0" width="50" height="50">
			</rect>
		</svg>
		<div id="Principle_lbl_website_6_pc">
			<span>Principle</span>
		</div>
		<div id="Scroll_princi_web_6_pc">
			<div id="Kono_sekai_wa_itami_o_shirudar">
				<span>{props.data.principles}</span>
			</div>
		</div>
		<img id="leadership_2_knowledge_webs_pc" src="./Themes/KnowledgeKorner/leadership_2_shadow_space_webs.png" srcset="./Themes/KnowledgeKorner/leadership_2_shadow_space_webs.png 1x" />
			
	</div>
	<div id="vision_card_website_6_pc_grp">
		<svg class="vision_card_overall_website_6_">
			<rect id="vision_card_overall_website_6_" rx="10" ry="10" x="0" y="0" width="233" height="325">
			</rect>
		</svg>
		<svg class="bdr_img_visionwebsite_6_pc">
			<rect id="bdr_img_visionwebsite_6_pc" rx="5" ry="5" x="0" y="0" width="50" height="50">
			</rect>
		</svg>
		<div id="Vision_lbl_website_6_pc">
			<span>Vision</span>
		</div>
		<div id="Scroll_vision_web_6_pc">
			<div id="Zaido_wa_gisei_ni_natte_korosa">
				<span>{props.data.vision}</span>
			</div>
		</div>
		<img id="target_1_shadow_space_website_" src="./Themes/KnowledgeKorner/target_1_shadow_space_website_.png" srcset="./Themes/KnowledgeKorner/target_1_shadow_space_website_.png 1x" />
			
	</div>
	<div id="Scroll_my_services_pc_6_wev">
    {props.offeringsData.map((off,idx)=>(
		<div id="our_servicces_card_1_website_6">
			<svg class="services_card_1_bdr_website_6_">
				<rect id="services_card_1_bdr_website_6_" rx="10" ry="10" x="0" y="0" width="233" height="325">
				</rect>
			</svg>
			<img id="img_of_services_website_6_pc" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/KnowledgeKorner/img_of_services_website_6_pc.png"}  />
				
			<svg class="circle_deign_shadow_space_webs">
				<ellipse id="circle_deign_shadow_space_webs" rx="5" ry="5" cx="5" cy="5">
				</ellipse>
			</svg>
			<div id="My_Offerings_website_6_pc">
				<span>{off.offeringTitle}</span>
			</div>
		</div>
        ))}
	</div>
	<svg class="circle_3_website_6_pc">
		<ellipse id="circle_3_website_6_pc" rx="25" ry="25" cx="25" cy="25">
		</ellipse>
	</svg>
	<svg class="circle_2_website_6_pc">
		<ellipse id="circle_2_website_6_pc" rx="20" ry="20" cx="20" cy="20">
		</ellipse>
	</svg>
	<div id="oofering_lbl_med_knowledge_pc">
		<span>My Events</span>
	</div>
    <div id="Scroll_event_web_6_pc">
    {props.eventsData && props.eventsData.map((eve)=>(
		<div id="my_event_sectionshadow_space_w">
			<svg class="comic_con_bg_shadow_space_webs_dq">
				<linearGradient id="comic_con_bg_shadow_space_webs_dq" spreadMethod="pad" x1="0.354" x2="0.985" y1="0" y2="1">
					<stop offset="0" stop-color="#a6d2ca" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="comic_con_bg_shadow_space_webs_dq" rx="15" ry="15" x="0" y="0" width="514" height="286">
				</rect>
			</svg>
			<img id="Anime_Edit_shadow_space_websit" src={eve.eventImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(eve.eventImageUrl.split('/')[5]):"./Themes/KnowledgeKorner/Anime_Edit_shadow_space_websit.png"}  />
				
			<div id="oofering_lbl_med_shadow_space__ds">
				<span>{eve.title}</span>
			</div>
			<div id="Scroll_description_event_web_6">
				<div id="offering_lbl_med_web_6_pc">
					<span>{eve.description}</span>
				</div>
			</div>
			<svg class="person_name_event_1_website_6_">
				<rect id="person_name_event_1_website_6_" rx="14" ry="14" x="0" y="0" width="230" height="28">
				</rect>
			</svg>
			<div id="grp_1_top_pc_web_6">
				<div id="date_shadow_space_website_6">
					<span>{eve.startsOn.slice(0,10)}</span>
				</div>
				<img id="calendar_2_shadow_space_websit" src="./Themes/KnowledgeKorner/calendar_2_shadow_space_websit.png" srcset="./Themes/KnowledgeKorner/calendar_2_shadow_space_websit.png 1x" />
					
				<div id="date_shadow_space_website_6_dz">
					<span>{eve.startsOn.slice(11,16)} IST</span>
				</div>
				<img id="clock_1_shadow_space_website_6" src="./Themes/KnowledgeKorner/clock_1_shadow_space_website_6.png" srcset="./Themes/KnowledgeKorner/clock_1_shadow_space_website_6.png 1x" />
					
			</div>
			<div id="grp_2_top_pc_web_6">
				<img id="seats_shadow_space_website_6" src="./Themes/KnowledgeKorner/seats_shadow_space_website_6.png" srcset="./Themes/KnowledgeKorner/seats_shadow_space_website_6.png 1x" />
					
				<div id="dateshadow_space_website_6">
					<span>{eve.seats}</span>
				</div>
				<img id="price-tag_shadow_space_website" src="./Themes/KnowledgeKorner/price-tag_shadow_space_website.png" srcset="./Themes/KnowledgeKorner/price-tag_shadow_space_website.png 1x" />
					
				<div id="date_shadow_space_website_6_d">
					<span>₹ {eve.cost}</span>
				</div>
			</div>
			<img id="man_2_shadow_space_website_6" src="./Themes/KnowledgeKorner/man_2_shadow_space_website_6.png" srcset="./Themes/KnowledgeKorner/man_2_shadow_space_website_6.png 1x" />
				
			<div id="date_shadow_space_website_6_ea">
				<span>{eve.organiserName}</span>
			</div>
		</div>
        ))}
	</div>
	
	<div id="footer_grp_web_6_pc">
		<img id="footer_bg_img_website_6_pc" src="./Themes/KnowledgeKorner/footer_bg_img_website_6_pc.png" srcset="./Themes/KnowledgeKorner/footer_bg_img_website_6_pc.png 1x" />
			
		<div id="n_234567890_mobile_number_shad_k_k_pc">
			<span>{props.data.businessPhone}</span>
		</div>
		<div id="affanghanioperatelive_email_sh_k_k_pc">
			<span>{props.data.businessEmail}</span>
		</div>
		<div id="address_med_shadow_space_websi_k_k_pc">
			<span>{props.data.businessAddress}</span>
		</div>
		<img id="location_img_shadow_space_webs_k_k_pc" src="./Themes/KnowledgeKorner/location_img_shadow_space_webs.png" srcset="./Themes/KnowledgeKorner/location_img_shadow_space_webs.png 1x" />
			
		<img id="email_address_shadow_space_web_k_k_pc" src="./Themes/KnowledgeKorner/email_address_shadow_space_web.png" srcset="./Themes/KnowledgeKorner/email_address_shadow_space_web.png 1x" />
			
		<img id="phone_number_shadow_space_webs_k_k_pc" src="./Themes/KnowledgeKorner/phone_number_shadow_space_webs.png" srcset="./Themes/KnowledgeKorner/phone_number_shadow_space_webs.png 1x" />
			
		<div id="Connect_with_us_and_unlock_lim_k_k_pc">
			<span>Embrace the unfamiliar, ignite your potential, and explore boundless possibilities.</span>
		</div>
		<img id="quote_left_med_shadow_space_we_k_k_pc" src="./Themes/KnowledgeKorner/quote_left_med_shadow_space_we.png" srcset="./Themes/KnowledgeKorner/quote_left_med_shadow_space_we.png 1x" />
			
		<img id="quote_right_med_shadow_space_w_k_k_pc" src="./Themes/KnowledgeKorner/quote_right_med_shadow_space_w.png" srcset="./Themes/KnowledgeKorner/quote_right_med_shadow_space_w.png 1x" />
			
		<a href="https://operate.live" target="_blank">
		<div id="n_Copyright_Operate_Live_med_s_k_k_pc">
			<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
		</div>
		</a>
		<div id="socials_grp_shadow_space_websi_k_k_pc">
        {props.data.linkedInUrl?
			<div title="LinkedIn" id="linked_in_grp_shadow_space_web_k_k_pc">
				<svg class="med_shadow_space_website_6_k_k_pc">
					<ellipse id="med_shadow_space_website_6_k_k_pc" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<div id="linked_in_shadow_space_website_k_k_pc">
					<a href={props.data.linkedInUrl} target="_blank">
					<svg class="n_website_6_layout__k_k_pc" viewBox="-15.255 0 15.439 14.566">
						<path id="n_website_6_layout__k_k_pc" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
						</path>
					</svg>
				</a>
					<svg class="linked_in_i_shadow_space_websi_k_k_pc" viewBox="0.931 -22.783 4.553 14.427">
						<path id="linked_in_i_shadow_space_websi_k_k_pc" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
						</path>
					</svg>
					<svg class="top_dot_i_shadow_space_website_k_k_pc" viewBox="-4.392 0 5.527 5.006">
						<path id="top_dot_i_shadow_space_website_k_k_pc" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
						</path>
					</svg>
				</div>
			</div>
            :""}

            {props.data.instagramUrl?	
			<div title="Instagram" id="Insta_grp_shadow_space_website_k_k_pc">
				<svg class="shadow_space_website_6_k_k_pc">
					<ellipse id="shadow_space_website_6_k_k_pc" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
				<a href={props.data.instagramUrl} target="_blank">
				<img id="instagramshadow_space_website__k_k_pc" src="./Themes/KnowledgeKorner/instagramshadow_space_website_.png" srcset="./Themes/KnowledgeKorner/instagramshadow_space_website_.png 1x" />
					
				</a>
			</div>:""}
            {props.data.whatsAppNumber?
			<div title="WhatsApp" id="whatsapp_grp_shadow_space_webs_k_k_pc">
				<svg class="med_shadow_space_website_6_ex_k_k_pc">
					<ellipse id="med_shadow_space_website_6_ex_k_k_pc" rx="24" ry="24" cx="24" cy="24">
					</ellipse>
				</svg>
                <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="whatsapp_lbl_shadow_space_webs_k_k_pc" src="./Themes/KnowledgeKorner/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/KnowledgeKorner/whatsapp_lbl_shadow_space_webs.png 1x" />
                </a>
			</div>
            :""}
		</div>
		<svg class="tv_part_21_website_6_pc" viewBox="4578.628 19892.477 512.559 310.918">
			<path id="tv_part_21_website_6_pc" d="M 4578.6279296875 19893.5234375 L 5091.1865234375 19892.4765625 L 5089.9150390625 20190.03125 L 4870.75634765625 20190.39453125 L 4870.75634765625 20192.220703125 L 4866.99267578125 20192.220703125 L 4866.99267578125 20195.208984375 L 4953.1455078125 20195.208984375 L 4955.68310546875 20203.39453125 L 4711.9990234375 20203.39453125 L 4714.66943359375 20195.208984375 L 4802.79052734375 20195.208984375 L 4802.79052734375 20192.220703125 L 4796.92138671875 20192.220703125 L 4796.92138671875 20190.39453125 L 4578.6279296875 20190.03125 L 4578.6279296875 19893.5234375 Z">
			</path>
		</svg>
		{/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15547.185741508381!2d77.64215715!3d13.04862565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1688370086554!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="tv_part_1_website_6_pc" viewBox="4586.469 19900.395 497.467 275.943">
			<path id="tv_part_1_website_6_pc" d="M 4588.9306640625 20176.337890625 L 4586.46875 19900.66796875 L 5083.93603515625 19900.39453125 L 5082.28857421875 20176.3046875 L 4588.9306640625 20176.337890625 Z">
			</path>
		</iframe> */}
        <iframe class="tv_part_1_website_6_pc" width="1366" height="330" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"}>
	<rect id="tv_part_1_website_6_pc" rx="0" ry="0" x="0" y="0" width="1366" height="330">
		</rect>
	</iframe>
	</div>
	
	
	<svg class="Icon_awesome-cloud_6_website_6" viewBox="0 2.25 80 56">
		<path id="Icon_awesome-cloud_6_website_6" d="M 67.19999694824219 26.57500076293945 C 67.71250152587891 25.23749542236328 68 23.77499580383301 68 22.24999809265137 C 68 15.62499713897705 62.62499618530273 10.24999809265137 56 10.24999809265137 C 53.53749847412109 10.24999809265137 51.23749923706055 11 49.33750152587891 12.27499771118164 C 45.87499618530273 6.274999618530273 39.41249847412109 2.249999761581421 31.99999809265137 2.249999761581421 C 20.95000267028809 2.249999761581421 12 11.19999885559082 12 22.24999809265137 C 12 22.58749961853027 12.01250076293945 22.92499732971191 12.02500057220459 23.26249694824219 C 5.024999618530273 25.72499656677246 0 32.39999771118164 0 40.25 C 0 50.18750381469727 8.0625 58.25 18 58.25 L 63.99999618530273 58.25 C 72.83749389648438 58.25 80 51.08750152587891 80 42.24999618530273 C 80 34.51249694824219 74.5 28.04999923706055 67.19999694824219 26.57500076293945 Z">
		</path>
	</svg>
	<svg class="Icon_awesome-cloud_5_website_6" viewBox="0 2.25 62 43.4">
		<path id="Icon_awesome-cloud_5_website_6" d="M 52.08000183105469 21.10187530517578 C 52.47718811035156 20.06530952453613 52.70000076293945 18.93187141418457 52.70000076293945 17.74999809265137 C 52.70000076293945 12.61562347412109 48.53437423706055 8.44999885559082 43.40000152587891 8.44999885559082 C 41.49156188964844 8.44999885559082 39.70906448364258 9.03125 38.23656463623047 10.01937389373779 C 35.55312347412109 5.369374752044678 30.54468727111816 2.249999761581421 24.79999923706055 2.249999761581421 C 16.23625183105469 2.249999761581421 9.300000190734863 9.186249732971191 9.300000190734863 17.74999809265137 C 9.300000190734863 18.01156234741211 9.309688568115234 18.27312278747559 9.319375991821289 18.5346851348877 C 3.894374847412109 20.44312286376953 0 25.61624908447266 0 31.69999885559082 C 0 39.40156555175781 6.248437404632568 45.65000152587891 13.94999980926514 45.65000152587891 L 49.59999847412109 45.65000152587891 C 56.44906234741211 45.65000152587891 62 40.09906387329102 62 33.24999618530273 C 62 27.2534351348877 57.73749923706055 22.24499893188477 52.08000183105469 21.10187530517578 Z">
		</path>
	</svg>
	<svg class="Icon_awesome-cloud_4_website_6" viewBox="0 2.25 80 56">
		<path id="Icon_awesome-cloud_4_website_6" d="M 67.19999694824219 26.57500076293945 C 67.71250152587891 25.23749542236328 68 23.77499580383301 68 22.24999809265137 C 68 15.62499713897705 62.62499618530273 10.24999809265137 56 10.24999809265137 C 53.53749847412109 10.24999809265137 51.23749923706055 11 49.33750152587891 12.27499771118164 C 45.87499618530273 6.274999618530273 39.41249847412109 2.249999761581421 31.99999809265137 2.249999761581421 C 20.95000267028809 2.249999761581421 12 11.19999885559082 12 22.24999809265137 C 12 22.58749961853027 12.01250076293945 22.92499732971191 12.02500057220459 23.26249694824219 C 5.024999618530273 25.72499656677246 0 32.39999771118164 0 40.25 C 0 50.18750381469727 8.0625 58.25 18 58.25 L 63.99999618530273 58.25 C 72.83749389648438 58.25 80 51.08750152587891 80 42.24999618530273 C 80 34.51249694824219 74.5 28.04999923706055 67.19999694824219 26.57500076293945 Z">
		</path>
	</svg>
	<svg class="Icon_awesome-cloud_3_website_6" viewBox="0 2.25 80 56">
		<path id="Icon_awesome-cloud_3_website_6" d="M 67.19999694824219 26.57500076293945 C 67.71250152587891 25.23749542236328 68 23.77499580383301 68 22.24999809265137 C 68 15.62499713897705 62.62499618530273 10.24999809265137 56 10.24999809265137 C 53.53749847412109 10.24999809265137 51.23749923706055 11 49.33750152587891 12.27499771118164 C 45.87499618530273 6.274999618530273 39.41249847412109 2.249999761581421 31.99999809265137 2.249999761581421 C 20.95000267028809 2.249999761581421 12 11.19999885559082 12 22.24999809265137 C 12 22.58749961853027 12.01250076293945 22.92499732971191 12.02500057220459 23.26249694824219 C 5.024999618530273 25.72499656677246 0 32.39999771118164 0 40.25 C 0 50.18750381469727 8.0625 58.25 18 58.25 L 63.99999618530273 58.25 C 72.83749389648438 58.25 80 51.08750152587891 80 42.24999618530273 C 80 34.51249694824219 74.5 28.04999923706055 67.19999694824219 26.57500076293945 Z">
		</path>
	</svg>
	<svg class="Icon_awesome-cloud_2_website_6" viewBox="0 2.25 80 56">
		<path id="Icon_awesome-cloud_2_website_6" d="M 67.19999694824219 26.57500076293945 C 67.71250152587891 25.23749542236328 68 23.77499580383301 68 22.24999809265137 C 68 15.62499713897705 62.62499618530273 10.24999809265137 56 10.24999809265137 C 53.53749847412109 10.24999809265137 51.23749923706055 11 49.33750152587891 12.27499771118164 C 45.87499618530273 6.274999618530273 39.41249847412109 2.249999761581421 31.99999809265137 2.249999761581421 C 20.95000267028809 2.249999761581421 12 11.19999885559082 12 22.24999809265137 C 12 22.58749961853027 12.01250076293945 22.92499732971191 12.02500057220459 23.26249694824219 C 5.024999618530273 25.72499656677246 0 32.39999771118164 0 40.25 C 0 50.18750381469727 8.0625 58.25 18 58.25 L 63.99999618530273 58.25 C 72.83749389648438 58.25 80 51.08750152587891 80 42.24999618530273 C 80 34.51249694824219 74.5 28.04999923706055 67.19999694824219 26.57500076293945 Z">
		</path>
	</svg>
	<svg class="Icon_awesome-cloud_1_website_6" viewBox="0 2.25 80 56">
		<path id="Icon_awesome-cloud_1_website_6" d="M 67.19999694824219 26.57500076293945 C 67.71250152587891 25.23749542236328 68 23.77499580383301 68 22.24999809265137 C 68 15.62499713897705 62.62499618530273 10.24999809265137 56 10.24999809265137 C 53.53749847412109 10.24999809265137 51.23749923706055 11 49.33750152587891 12.27499771118164 C 45.87499618530273 6.274999618530273 39.41249847412109 2.249999761581421 31.99999809265137 2.249999761581421 C 20.95000267028809 2.249999761581421 12 11.19999885559082 12 22.24999809265137 C 12 22.58749961853027 12.01250076293945 22.92499732971191 12.02500057220459 23.26249694824219 C 5.024999618530273 25.72499656677246 0 32.39999771118164 0 40.25 C 0 50.18750381469727 8.0625 58.25 18 58.25 L 63.99999618530273 58.25 C 72.83749389648438 58.25 80 51.08750152587891 80 42.24999618530273 C 80 34.51249694824219 74.5 28.04999923706055 67.19999694824219 26.57500076293945 Z">
		</path>
	</svg>
	<img id="sheep3_website_6_pc" src="./Themes/KnowledgeKorner/sheep3_website_6_pc.png" srcset="./Themes/KnowledgeKorner/sheep3_website_6_pc.png 1x" />
		
	<img id="sheep_2_website_6_pc" src="./Themes/KnowledgeKorner/sheep_2_website_6_pc.png" srcset="./Themes/KnowledgeKorner/sheep_2_website_6_pc.png 1x" />
		
	<img id="sheep_1_website_6_pc" src="./Themes/KnowledgeKorner/sheep_1_website_6_pc.png" srcset="./Themes/KnowledgeKorner/sheep_1_website_6_pc.png 1x" />
		
	<img id="dove_3_website_6_pc" src="./Themes/KnowledgeKorner/dove_3_website_6_pc.png" srcset="./Themes/KnowledgeKorner/dove_3_website_6_pc.png 1x" />
		
	<img id="dove_2_website_6_pc" src="./Themes/KnowledgeKorner/dove_2_website_6_pc.png" srcset="./Themes/KnowledgeKorner/dove_2_website_6_pc.png 1x" />
		
	<img id="dove_1_website_6_pc" src="./Themes/KnowledgeKorner/dove_1_website_6_pc.png" srcset="./Themes/KnowledgeKorner/dove_1_website_6_pc.png 1x" />
		
	<div id="n__imgs_grp_website_6_pc">
		<svg class="image_4_shadow_website_6_pc">
			<ellipse id="image_4_shadow_website_6_pc" rx="75" ry="4.5" cx="75" cy="4.5">
			</ellipse>
		</svg>
		<svg class="image_3_shadow_website_6_pc">
			<ellipse id="image_3_shadow_website_6_pc" rx="75" ry="4.5" cx="75" cy="4.5">
			</ellipse>
		</svg>
		<svg class="image_2_shadow_website_6_pc">
			<ellipse id="image_2_shadow_website_6_pc" rx="75" ry="4.5" cx="75" cy="4.5">
			</ellipse>
		</svg>
		<svg class="image_1_shadow_website_6_pc">
			<ellipse id="image_1_shadow_website_6_pc" rx="75" ry="4.5" cx="75" cy="4.5">
			</ellipse>
		</svg>
		<img id="img_4_website_6_pc" src="./Themes/KnowledgeKorner/img_4_website_6_pc.png" srcset="./Themes/KnowledgeKorner/img_4_website_6_pc.png 1x" />
			
		<img id="img_3_website_6_pc" src="./Themes/KnowledgeKorner/img_3_website_6_pc.png" srcset="./Themes/KnowledgeKorner/img_3_website_6_pc.png 1x" />
			
		<img id="img_2_website_6_pc" src="./Themes/KnowledgeKorner/img_2_website_6_pc.png" srcset="./Themes/KnowledgeKorner/img_2_website_6_pc.png 1x" />
			
		<img id="img_1_website_6_pc" src="./Themes/KnowledgeKorner/img_1_website_6_pc.png" srcset="./Themes/KnowledgeKorner/img_1_website_6_pc.png 1x" />
			
	</div>
	<svg class="circle_1_website_6_pc">
		<ellipse id="circle_1_website_6_pc" rx="25" ry="25" cx="25" cy="25">
		</ellipse>
	</svg>
</div>
  )
}

export default KnowledgeKorner
