import React, { useState, useEffect, useContext } from "react";
// import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider } from "@mui/material";
import "../styles/signUpUI.css";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
// import EventIcon from '@mui/icons-material/Event';
// import CheckIcon from '@mui/icons-material/Check';
// import ScheduleInterview from './ScheduleInterview';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// // import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
// import DatePicker from '@mui/lab/DatePicker';
// import TimePicker from '@mui/lab/TimePicker';
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
// import { id } from 'date-fns/locale';
import { UserContext } from "./UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { autocompleteClasses } from "@material-ui/core";
import countries from "./Countries.json";
import Currencies from "./Currencies.json";

const SignUpUI = (props) => {
  const style6 = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 400,
    maxWidth: 550,
    margin: "auto",
    scrollY: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
  };

  const label = { inputProps: { "aria-label": "Schedule Interview" } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule] = React.useState();
  const [searchParams] = useSearchParams();
  var navigate = useNavigate();
  // const [id, setId] = useState("");
  const [name, setName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState(new Date().toISOString().substring(0,10));
  const [whatsNumber, setWhatsNumber] = useState("");
  const [gender, setGender] = useState("male");
  const [open, setOpen] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportingToId, setReportingToId] = useState("");
  const [reportingToName, setReportingToName] = useState("");
  const [accessType, setAccessType] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [hourlyRate,setHourlyRate] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [active, setActive] = useState(true);
  const [confirmPass, setConfirmPass] = useState("");
  const [password, setPassword] = useState("");
  const [profession,setProfession] = useState("");
  const [pincode, setPincode] = useState("");
  const [img, setImg] = useState("");
  const [imageDemo,setImageDemo] = useState("");
  const [nickName,setNickName] = useState("")
  const [introduction,setIntroduction] = useState("");
  const { user } = useContext(UserContext);

  // const [filterModel, setFilterModel] = React.useState({
  //   items: [
  //     {columnField: '', operatorValue: 'contains', value:user.name}
  //   ],
  // });

  const cols = [
    { field: "id", headerName: "id", hide: true },
    { field: "name", headerName: "Name", width: 190 },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      renderCell: (params) => {
        return [
          params.row.gender
            ? params.row.gender.substring(0, 1).toUpperCase() +
              params.row.gender.substring(1).toLowerCase()
            : "",
        ];
      },
    },
    { field: "reportingToName", headerName: "Reporting To", width: 190 },
    {
      field: "accesstype",
      headerName: "AccessType",
      width: 100,
      renderCell: (params) => {
        switch (params.row.accesstype) {
          case "0":
            return ["Guest"];

          case "1":
            return ["Employee"];

          case "2":
            return ["Supervisor"];

          case "3":
            return ["Manager"];

          case "4":
            return ["Director"];

          case "5":
            return ["Admin"];

          default:
            return [""];
        }
      },
    },
    { field: "mobile", headerName: "Mobile No", width: 140 },

    { field: "email", headerName: "Email", width: 200 },
    {
      field: "active",
      headerName: "Active",
      width: 100,
      renderCell: (params) => {
        return [<input type="checkbox" checked={params.row.active} />];
      },
    },
  ];

  useEffect(() => {
    addEmployee();
  }, []);

  const getEmployees = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/getallusers";
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          console.log(repos.data);
          setEmployees(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const UpdateEmployeesDetails = (id) => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/updateEmployees/" + id;
    let apiParams = {
      method: "POST",
      headers: {
        name: name,
        gender: gender,
        reportingtoid: reportingToId,
        reportingtoname: reportingToName,
        accesstype: accessType,
        mobile: mobile,
        email: email,
        password: encodeURIComponent(password),
        active: active,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);

          props.showAlert("User Updated Successfully", "success");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch((err) => alert(err));
  };

  const addEmployeedetails = () => {
  
    if(name===undefined||name===""){
      alert("Please Enter Name!!");
    return;
    }
    if(nickName===undefined||nickName===""){
      alert("Please Enter Nick Name!!");
    return;
    }
    if(email===undefined||email===""){
      alert("Please Enter Email Address!!");
    return;
    }
    if(mobile===undefined||mobile===""){
      alert("Please Enter Mobile Number!!");
    return;
    }

    if(password===undefined||password===""){
      alert("Password cannot be empty!!");
    return;
    }

    if(confirmPass===undefined||confirmPass===""){
      alert("Confirm Password cannot be empty!!");
    return;
    }

    if (password !== confirmPass) {
      alert("Password and Confirm Password don't match!!");
      return;
    }
   
    
   
    let apiURL = process.env.REACT_APP_API_URL + "/users/addEmployee";
    let apiParams = {
      method: "POST",
      headers: {
        name: name,
        // gender: gender,
        // dob: dob,
        nickname:nickName,
        mobile: mobile,
        email: email,
        // country: country,
        // state: state,
        // city: city,
        // address: encodeURIComponent(address),
        // pincode: pincode,
        // profession:profession,
        // introduction:encodeURIComponent(introduction),
        // currency:currency,
        // hourlyrate:hourlyRate,
        password: encodeURIComponent(password),
        // active: active,
      },
      // body:image,
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);
          
          alert("User Added Successfully!!");
          img && UploadImageToDrive(repos.data._id,img)
          setTimeout(() => {
                navigate("/login")
              }, 2000);
        }
      })
      .catch((err) => alert(err));
  };

  const addEmployee = () => {
    setOpen(true);
  };

  // const editEmployees = (row) =>{
  //   console.log(row)
  //   setId(row.id);
  //   setName(row.name)
  //   setGender(row.gender)
  //   setReportingToId(row.reportingToId)
  //   setReportingToName(row.reportingToName)
  //   setAccessType(row.accesstype)
  //   setMobile(row.mobile)
  //   setEmail(row.email)
  //   setActive(row.active)
  //   setOpen(true);
  // }

  const handleClose = () => {
    setOpen(false);
    navigate("/login");
  };



  // const rowData = (employees)?.map((employee) => {

  // return{
  //   id:employee?._id,
  //   name:employee?.name,
  //   reportingToId:employee?.reportingToId,
  //   reportingToName:employee?.reportingToName,
  //   email:employee?.email,
  //   mobile:employee?.phone,
  //   gender:employee?.gender,
  //   accesstype:employee?.accesstype,
  //   active:employee?.active,
  // };
  // });

  const handleSignUpImageUpload = async (e) => {
    // if (e.target.files[0] && e.target.files[0].size > 71680) {
    //   alert("Please choose a file with size below 70kb!");
    //   return;
    // }
    if(e.target.files[0]){
      handleFileSelect(e.target.files[0]);
     
      // let formdata = new FormData();
      // formdata.append("image1", e.target.files[0], e.target.value);
      setImg(e.target.files[0]);
    }
    
  };

  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImageDemo("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

   //Upload Image
   const UploadImageToDrive = async (id,document) =>{
     
    var file = document //the file
    var reader = new FileReader() //this for convert to Base64 
    reader.readAsDataURL(document) //start conversion...
    reader.onload = function (e) { //.. once finished..
      var rawLog = reader.result.split(',')[1]; //extract only thee file data part
      var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
     fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
        { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
        .then(res => res.json()).then((data) => {
          //See response
        
           SaveProfilePic(data,id)
          // alert("Image Uploaded Successfully","success")
  
        }).catch(err => console.log(err)) // Or Error in console
    }
  }


  const SaveProfilePic = (data,id)=>{
      
    const apiurl = process.env.REACT_APP_API_URL + "/users/updateprofilepicnew/"+id;
            let apiParams = {
                method:"POST",
                headers:{
                  imageurl:data.url,
                
                }
          }
          fetch(apiurl, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
               
                alert("Image Uploaded Successfully");
              }
            })
            .catch(e => console.log(e)) 
          }

  return (
   <div>
<div id="sign_up">
	<svg class="Path_331" viewBox="0 0 1248.816 660.617">
		<path id="Path_331" d="M 27.4623966217041 0 L 1221.35400390625 0 C 1236.52099609375 0 1248.81640625 12.55776882171631 1248.81640625 28.04856109619141 L 1248.81640625 632.56884765625 C 1248.81640625 648.0596313476562 1236.52099609375 660.617431640625 1221.35400390625 660.617431640625 L 27.4623966217041 660.617431640625 C 12.29533195495605 660.617431640625 0 648.0596313476562 0 632.56884765625 L 0 28.04856109619141 C 0 12.55776882171631 12.29533195495605 0 27.4623966217041 0 Z">
		</path>
	</svg>
	<img onClick={()=>navigate('/')}  id="right-arrow" src="right-arrow.png" srcset="right-arrow.png 1x, right-arrow@2x.png 2x" />
		
	<div id="Sign_Up_q">
		<span>Sign Up</span>
	</div>
	<img id="contract" src="contract.png" srcset="contract.png 1x, contract@2x.png 2x" />
		
	<input placeholder="Mobile No" type="number" class="txtMobile_No"  value={mobile}  onChange={(e)=>setMobile(e.target.value)}/>
		{/* <rect id="txtMobile_No" rx="0" ry="0" x="0" y="0" width="159.018" height="30">
		</rect>
	</input> */}
	<input placeholder="Email" class="txtEmail_signUp" value={email || ""} onChange={(e) => {setEmail(e.target.value)}}/>
		{/* <rect id="txtEmail" rx="0" ry="0" x="0" y="0" width="159.018" height="30">
		</rect>
	</input> */}
	<input placeholder="Set Password" class="txtEnter_Password" type="password" value={password} onChange={(e) => {setPassword(e.target.value) }} />
		{/* <rect id="txtEnter_Password" rx="0" ry="0" x="0" y="0" width="159.018" height="30">
		</rect>
	</input> */}
	<input placeholder="Confirm Password" class="txtConfirm_Password" type="password" value={confirmPass} onChange={(e) => {setConfirmPass(e.target.value)}}/>
		{/* <rect id="txtConfirm_Password" rx="0" ry="0" x="0" y="0" width="159.018" height="30">
		</rect>
	</input> */}
	<input placeholder="Name" class="txtFirst_Name" value={name || ""} onChange={(e) => {setName(e.target.value)}} />
  <input placeholder="Trade/Nick Name" class="txtNick_Name" value={nickName || ""} onChange={(e) => {setNickName(e.target.value.replace(" ","").replace(".","").toLowerCase())}} />
  <label class="nickNamelbl">{nickName.length > 0 ? "Your Website URL will be ":""}<font size="5" color="red" >{nickName.length > 0 ? nickName+".operate.live":""}</font></label>

		{/* <rect id="txtFirst_Name" rx="0" ry="0" x="0" y="0" width="373.81" height="30">
		</rect>
	</input> */}

  <label for="signUpimage">
    <input id="signUpimage" type="file" name="signUpimage"  hidden accept="image/*" onChange={(e) => handleSignUpImageUpload(e)}/>
    <img id="profile_Pic_SignUp" width="100" height="100" src={imageDemo?imageDemo:"user_pfp_profile_page.png"} style={{borderRadius:"75px",border:"1px solid",padding:"5px"}}/>
    </label>
	{/* <img id="profile" src="profile.png" srcset="profile.png 1x, profile@2x.png 2x" /> */}
		
	<div onClick={()=>navigate('/')} id="Already_a_Member_Sign_in">
		<span>Already a Member?</span><span style={{color:"purple"}}> Sign in</span>
	</div>
	<button id="Login_button" onClick={() => addEmployeedetails()}>
		<svg class="Rectangle_2_">
			<rect id="Rectangle_2_" rx="17.690927505493164" ry="17.690927505493164" x="0" y="0" width="153.462" height="35.382">
			</rect>
		</svg>
		<div id="Sign_Up_ba">
			<span>Sign Up</span>
		</div>
	</button>
	<svg class="Path_328" viewBox="3222.578 93 562.609 85.903">
		<path id="Path_328" d="M 3222.57763671875 93 C 3222.57763671875 93 3222.577880859375 188.5773620605469 3329.438232421875 178.1008453369141 C 3436.298583984375 167.6243286132812 3785.186279296875 93 3785.186279296875 93 L 3222.57763671875 93 Z">
		</path>
	</svg>
	<svg class="Path_329" viewBox="3287.741 93.721 526.456 639.169">
		<path id="Path_329" d="M 3332.540283203125 155.3172760009766 L 3287.741455078125 732.890380859375 L 3813.383544921875 730.3936767578125 C 3813.376953125 730.32568359375 3813.54345703125 136.9828796386719 3813.54345703125 136.9828796386719 C 3813.54345703125 136.9828796386719 3821.791259765625 99.60132598876953 3779.683837890625 93.76044464111328 C 3776.86279296875 93.369140625 3769.645751953125 95.97640991210938 3760.20263671875 97.80039978027344 C 3741.38720703125 101.4343414306641 3717.541748046875 102.0059509277344 3717.541748046875 102.0059509277344 C 3717.541748046875 102.0059509277344 3644.046142578125 113.7676620483398 3602.455322265625 121.3918838500977 C 3564.080078125 128.4266204833984 3524.828369140625 131.6411743164062 3486.540771484375 136.5371398925781 C 3403.7568359375 147.1230316162109 3332.540283203125 155.3172760009766 3332.540283203125 155.3172760009766 Z">
		</path>
	</svg>
	<svg class="Path_330" viewBox="3257.505 803.527 608.803 184.473">
		<path id="Path_330" d="M 3290.976318359375 988 L 3257.504638671875 844.1663818359375 C 3257.504638671875 844.1663818359375 3264.11767578125 807.3438720703125 3290.978271484375 806.0847778320312 C 3317.839111328125 804.82568359375 3866.307373046875 803.5269165039062 3866.307373046875 803.5269165039062 L 3866.176025390625 946.75 C 3866.176025390625 946.75 3867.869384765625 961.4517211914062 3857.65869140625 973.3567504882812 C 3851.170654296875 980.926513671875 3842.01953125 987.233154296875 3813.2666015625 987.3843994140625 C 3741.564453125 987.7615356445312 3290.976318359375 988 3290.976318359375 988 Z">
		</path>
	</svg>
	<svg class="Path_332" viewBox="3235.263 416.487 613.107 389.203">
		<path id="Path_332" d="M 3290.880859375 805.689208984375 C 3290.880859375 805.689208984375 3165.740234375 680.7068481445312 3290.880859375 632.737548828125 C 3416.021728515625 584.7682495117188 3848.36962890625 416.4866027832031 3848.36962890625 416.4866027832031 L 3848.142822265625 803.4501342773438 L 3290.880859375 805.689208984375 Z">
		</path>
	</svg>
	<div id="Group_15">
		<svg class="Ellipse_4">
			<ellipse id="Ellipse_4" rx="80.5" ry="80.5" cx="80.5" cy="80.5">
			</ellipse>
		</svg>
    
		<img id="image_SignUp" src="image.png" srcset="image.png 1x, image@2x.png 2x" />
			
	</div>
	<div id="Group_14">
		<svg class="Rectangle_5">
			<rect id="Rectangle_5" rx="11" ry="11" x="0" y="0" width="341" height="50">
			</rect>
		</svg>
		<div id="OPERATE_LIVE_SignUp">
			<span>OPERATE </span><span style={{color:"red"}}>LIVE</span>
		</div>
	</div>
</div>
   </div>
  );
};

export default SignUpUI;
