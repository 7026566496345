import React, { useState, useEffect, useContext } from "react";
import "../../styles/ThemesSetUpMob.css";
import { UserContext } from "../UserContext";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";

const ThemesSetUpMob = (props) => {

  //useState
const [open,setOpen]= useState(false);
const [searchParams] = useSearchParams();
const { user, setUser } = useContext(UserContext);
var navigate = useNavigate();
const [selectedTheme,setSelectedTheme] = useState("Flamingo Fiesta")

useEffect(()=>{

 if(user){
  if(props.openThemesSelection){
    setOpen(props.openThemesSelection)
    setSelectedTheme(props.theme?props.theme:"Flamingo Fiesta")
  }
 }

},[props.openThemesSelection])

const handleClose = ()=>{
  setOpen(false)
  props.closeThemesSelection()
}

const UpdateTheme = ()=>{
   
  const apiurl = process.env.REACT_APP_API_URL + "/users/updateselectedtheme/"+user._id;
          let apiParams = {
              method:"POST",
              headers:{
                theme:selectedTheme,
              
              }
        }
        fetch(apiurl, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
             
            //   alert("Theme Updated Successfully");
              handleClose()
            }
          })
          .catch(e => console.log(e)) 
        }

  return (

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{overflow:'scroll',textAlign:"center"}}
        > 

<div id="theme_small__mob">
	<button id="select_btn_grp_mob_theme_page" onClick={()=>UpdateTheme()}>
		<svg class="select_btn_live_theme_mob">
			<rect id="select_btn_live_theme_mob" rx="10" ry="10" x="0" y="0" width="149" height="54">
			</rect>
		</svg>
		<div id="select_website_1_op_live_theme">
			<span>Select</span>
		</div>
	</button>
	<img id="close_small_theme_selector" src="./Themes/ThemesSelection/close_small_theme_selector.png" srcset="./Themes/ThemesSelection/close_small_theme_selector.png 1x" onClick={()=>handleClose()}/>
		
	<div id="Scroll_theme_edit_grp_mob">
		<div id="emerald_grp_mob">
			<img id="layout_1_small_theme_mob_emera" src="./Themes/ThemesSelection/layout_1_small_theme_mob_emera.png" srcset="./Themes/ThemesSelection/layout_1_small_theme_mob_emera.png 1x" />
				
			<input type="radio" class="radio_btn_1_small_theme_emeral" value={selectedTheme} checked={selectedTheme==="Enchanting Emerald"} onChange={()=>setSelectedTheme("Enchanting Emerald")}/>
				
			<div id="Enchanting_Emerald_small_theme_mob">
				<span>Enchanting Emerald</span>
			</div>
			<img id="web_1_mob_small_theme_mob" src="./Themes/ThemesSelection/web_1_mob_small_theme_mob.png" srcset="./Themes/ThemesSelection/web_1_mob_small_theme_mob.png 1x" />
				
		</div>
		<div id="flamingo_fiesto_grp_mob">
			<img id="website_2_layout_small_theme_m" src="./Themes/ThemesSelection/website_2_layout_small_theme_m.png" srcset="./Themes/ThemesSelection/website_2_layout_small_theme_m.png 1x" />
				
			<input type="radio" class="radio_btn_2_small_theme_fiesta" value={selectedTheme} checked={selectedTheme==="Flamingo Fiesta"} onChange={()=>setSelectedTheme("Flamingo Fiesta")} />
			
			<div id="Flamingo_Fiesta_lbl_small_them_mob">
				<span>Flamingo Fiesta</span>
			</div>
			<img id="web_2_mob_small_theme_mob" src="./Themes/ThemesSelection/web_2_mob_small_theme_new.png" srcset="./Themes/ThemesSelection/web_2_mob_small_theme_new.png 1x" />
				
		</div>
		<div id="ocean_bliss_grp_">
			<img id="mob_bliss_layout_small_theme" src="./Themes/ThemesSelection/mob_bliss_layout_small_theme.png" srcset="./Themes/ThemesSelection/mob_bliss_layout_small_theme.png 1x" />
				
			<input type="radio" class="radio_btn_3_small_theme_mob_bl" value={selectedTheme} checked={selectedTheme==="Oceanic Bliss"} onChange={()=>setSelectedTheme("Oceanic Bliss")}/>
			
			<div id="ocean_blue_lbl_small_theme_mob">
				<span>Oceanic Bliss</span>
			</div>
			<img id="web_3_pc_small_theme_mob_bliss" src="./Themes/ThemesSelection/web_3_pc_small_theme_mob_bliss.png" srcset="./Themes/ThemesSelection/web_3_pc_small_theme_mob_bliss.png 1x" />
				
		</div>
		<div id="grp_new_edit_mob">
			<img id="layout_4_small_theme_edit_mob" src="./Themes/ThemesSelection/layout_4_small_theme_edit_mob.png" srcset="./Themes/ThemesSelection/layout_4_small_theme_edit_mob.png 1x" />
				
			<input type="radio" class="radio_btn_4_small_theme_edit_m" value={selectedTheme} checked={selectedTheme==="Harmony Haven"} onChange={()=>setSelectedTheme("Harmony Haven")} />
				
			<div id="sahara_small_theme_edit_mob">
				<span>Harmony Haven</span>
			</div>
			<img id="web_4_mob_small_theme_edit_mob" src="./Themes/ThemesSelection/web_4_mob_small_theme_edit_mob.png" srcset="./Themes/ThemesSelection/web_4_mob_small_theme_edit_mob.png 1x" />
				
			<img id="layout_5_small_theme_edit_mob" src="./Themes/ThemesSelection/layout_5_small_theme_edit_mob.png" srcset="./Themes/ThemesSelection/layout_5_small_theme_edit_mob.png 1x" />
				
			<input type="radio" class="radio_btn_5_small_theme_edit_m" value={selectedTheme} checked={selectedTheme==="Shadow Space"} onChange={()=>setSelectedTheme("Shadow Space")}/>
				
			<div id="shadow_space_small_theme_edit_mob">
				<span>Shadow Space</span>
			</div>
			<img id="web_5_mob_small_theme_edit_mob" src="./Themes/ThemesSelection/web_5_mob_small_theme_edit_mob.png" srcset="./Themes/ThemesSelection/web_5_mob_small_theme_edit_mob.png 1x" />
				
		</div>

		<div id="knowledge_grp_mob_them_7654">
			<img id="layout_7_small_theme_mob" src="./Themes/ThemesSelection/layout_6_small_theme.png" srcset="./Themes/ThemesSelection/layout_6_small_theme.png 1x" />
				
			<img id="web_7_mob_small_theme_mob" src="./Themes/ThemesSelection/web_6_mob_small_theme.png" srcset="./Themes/ThemesSelection/web_6_mob_small_theme.png 1x" />
				
			<input type="radio" class="radio_btn_7_small_theme_mob_fa" value={selectedTheme} checked={selectedTheme==="Knowledge Korner"} onChange={()=>setSelectedTheme("Knowledge Korner")}/>
				
			<div id="drive_velocity_small_theme_mob">
				<span>Knowledge Korner</span>
			</div>
		</div>
		<div id="drive_velocity_grp_mob_them_6">
			<img id="layout_8_small_theme_mob" src="./Themes/ThemesSelection/layout_7_small_theme.png" srcset="./Themes/ThemesSelection/layout_7_small_theme.png 1x" /> 
				
			<img id="web_8_mob_small_theme_mob" src="./Themes/ThemesSelection/web_7_mob_small_theme.png" srcset="./Themes/ThemesSelection/web_7_mob_small_theme.png 1x" />
				
			<input type="radio" class="radio_btn_7_small_theme_mob" value={selectedTheme} checked={selectedTheme==="Drive Velocity"} onChange={()=>setSelectedTheme("Drive Velocity")}/>
				
			<div id="ride_revolutionbigtheme_mob">
				<span>Drive Velocity</span>
			</div>
		</div>
		<div id="ride_bike_theme_mob">
			<img id="layout_9_small_theme_mob_ey" src="./Themes/ThemesSelection/layout_8_small_theme.png" srcset="./Themes/ThemesSelection/layout_8_small_theme.png 1x" />
				
			<img id="web_9_mob_small_theme_mob_ez" src="./Themes/ThemesSelection/web_8_mob_small_theme.png" srcset="./Themes/ThemesSelection/web_8_mob_small_theme.png 1x" />
				
			<input type="radio" class="radio_btn_9_small_theme_mob_e" value={selectedTheme} checked={selectedTheme==="Ride Revolution"} onChange={()=>setSelectedTheme("Ride Revolution")}/>
				
			<div id="fashion_finds_small_theme_mob_e">
				<span>Ride Revolution</span>
			</div>
		</div>
		<div id="fashion_finds_grp_dummy_theme_mob">
			<img id="layout_9_small_theme_dummy_mob" src="./Themes/ThemesSelection/layout_9_small_theme.png" srcset="./Themes/ThemesSelection/layout_9_small_theme.png 1x" />
				
			<img id="web_9_mob_small_theme_dummy_mo" src="./Themes/ThemesSelection/web_9_mob_small_theme.png" srcset="./Themes/ThemesSelection/web_9_mob_small_theme.png 1x" />
				
			<input type="radio" class="radio_btn_9_small_theme_dummy_" value={selectedTheme} checked={selectedTheme==="Fashion Finds"} onChange={()=>setSelectedTheme("Fashion Finds")}/>
				
			<div id="fashion_finds_small_theme_dumm">
				<span>Fashion Finds</span>
			</div>
		</div>
		<div id="bike_grp_dummy_theme_mob">
			<img id="layout_8_small_theme_dummy_mob" src="./Themes/ThemesSelection/JourneyJunkiesPC.png" srcset="./Themes/ThemesSelection/JourneyJunkiesPC.png 1x" />
				
			<img id="web_8_mob_small_theme_dummy_mo" src="./Themes/ThemesSelection/JourneyJunkiesMob.png" srcset="./Themes/ThemesSelection/JourneyJunkiesMob.png 1x" />
				
			<input type="radio" class="radio_btn_7_small_theme_dummy__eq" value={selectedTheme} checked={selectedTheme==="Journey Junkies"} onChange={()=>setSelectedTheme("Journey Junkies")}/>
			
			<div id="ride_revolutionbigtheme_dummy_">
				<span>Journey Junkies</span>
			</div>
		</div>
		<div id="car_grp_dummy_theme_mob">
			<img id="layout_7_small_theme_dummy_mob" src="./Themes/ThemesSelection/MobileMarvelsPc.png" srcset="./Themes/ThemesSelection/MobileMarvelsPc.png 1x" />
				
			<img id="web_7_mob_small_theme_dummy_mo" src="./Themes/ThemesSelection/MobileMarvelsMob.png" srcset="./Themes/ThemesSelection/MobileMarvelsMob.png 1x" />
				
			<input type="radio" class="radio_btn_7_small_theme_dummy_" value={selectedTheme} checked={selectedTheme==="Mobile Marvels"} onChange={()=>setSelectedTheme("Mobile Marvels")} />
			
			<div id="drive_velocity_small_theme_dum">
				<span>Mobile Marvels</span>
			</div>
		</div>
		<div id="school_grp_dummy_theme_mob">
			<img id="layout_6_small_theme_dummy_mob" src="./Themes/ThemesSelection/PawPalace_PC.png" srcset="./Themes/ThemesSelection/PawPalace_PC.png 1x" />
				
			<input type="radio" class="radio_btn_6_small_theme_dummy_" value={selectedTheme} checked={selectedTheme==="Paw Palace"} onChange={()=>setSelectedTheme("Paw Palace")}/>
				
			<div id="knowledge_kornersmall_theme_du">
				<span>Paw Palace</span>
			</div>
			<img id="web_6_mob_small_theme_dummy_mo" src="./Themes/ThemesSelection/PawPalace_mob.png" srcset="./Themes/ThemesSelection/PawPalace_mob.png 1x" />
				
		</div>
		<div id="shadow__grp_dummy_theme_mob">
			<img id="layout_5_small_theme_dummy_mob" src="./Themes/ThemesSelection/Chef_s_Kitchen_pc.png" srcset="./Themes/ThemesSelection/Chef_s_Kitchen_pc.png 1x" />
				
			<input type="radio" class="radio_btn_5_small_theme_dummy_" value={selectedTheme} checked={selectedTheme==="Chef's Kitchen"} onChange={()=>setSelectedTheme("Chef's Kitchen")}/>
				
			<div id="shadow_space_small_theme_dummy">
				<span>Chef's Kitchen</span>
			</div>
			<img id="web_5_mob_small_theme_dummy_mo" src="./Themes/ThemesSelection/Chef_s_Kitchen_mob.png" srcset="./Themes/ThemesSelection/Chef_s_Kitchen_mob.png 1x" />
				
		</div>
		<div id="med_grp_dummy_theme_mob">
			<img id="layout_4_small_theme_dummy_mob" src="./Themes/ThemesSelection/FeatheredScapethemePc.png" srcset="./Themes/ThemesSelection/FeatheredScapethemePc.png 1x" />
				
			<input type="radio" class="radio_btn_4_small_theme_dummy_" value={selectedTheme} checked={selectedTheme==="Feathered Scape"} onChange={()=>setSelectedTheme("Feathered Scape")}/>
				
			<div id="sahara_small_theme_dummy_mob">
				<span>Feathered Scape</span>
			</div>
			<img id="web_4_mob_small_theme_dummy_mo" src="./Themes/ThemesSelection/FeatheredScapethemeMob.png" srcset="./Themes/ThemesSelection/FeatheredScapethemeMob.png 1x" />
				
		</div>
		<div id="fash_grp_theme_mob">
			<img id="layout_9_small_theme_mob" src="./Themes/ThemesSelection/FurniGemPC.png" srcset="./Themes/ThemesSelection/FurniGemPC.png 1x" />
				
			<img id="web_9_mob_small_theme_mob" src="./Themes/ThemesSelection/FurniGemMob.png" srcset="./Themes/ThemesSelection/FurniGemMob.png 1x" />
				
			<input type="radio" class="radio_btn_9_small_theme_mob" value={selectedTheme} checked={selectedTheme==="Furni Gem"} onChange={()=>setSelectedTheme("Furni Gem")}/>
				
			<div id="fashion_finds_small_theme_mob">
				<span>Furni Gem</span>
			</div>
		</div>
		<div id="car16_grp_dummy_theme_mob">
			<img id="layout_16small_theme_dummy_mob" src="./Themes/ThemesSelection/GamersHub_Pc.png" srcset="./Themes/ThemesSelection/GamersHub_Pc.png 1x" />
				
			<img id="web_16mob_small_theme_dummy_mo" src="./Themes/ThemesSelection/GamersHub_Mob.png" srcset="./Themes/ThemesSelection/GamersHub_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_16small_theme_dummy_" value={selectedTheme} checked={selectedTheme==="Gamers Hub"} onChange={()=>setSelectedTheme("Gamers Hub")}/>
				
			<div id="drive_16velocity_small_theme_d">
				<span>Gamers Hub</span>
			</div>
		</div>
		<div id="school_17grp_dummy_theme_mob">
			<img id="layout_17small_theme_dummy_mob" src="./Themes/ThemesSelection/TeaCorner_PC.png" srcset="./Themes/ThemesSelection/TeaCorner_PC.png 1x" />
				
			<input type="radio" class="radio_btn_17small_theme_dummy_" value={selectedTheme} checked={selectedTheme==="Tea Corner"} onChange={()=>setSelectedTheme("Tea Corner")}/>
				
			<div id="knowledge17_kornersmall_theme_">
				<span>Tea Corner</span>
			</div>
			<img id="web_17mob_small_theme_dummy_mo" src="./Themes/ThemesSelection/TeaCorner_Mob.png" srcset="./Themes/ThemesSelection/TeaCorner_Mob.png 1x" />
				
		</div>
		<div id="shadow_18_grp_dummy_theme_mob">
			<img id="layout_18small_theme_dummy_mob" src="./Themes/ThemesSelection/SharpStyles_pc.png" srcset="./Themes/ThemesSelection/SharpStyles_pc.png 1x" />
				
			<input type="radio" class="radio_btn_18small_theme_dummy_" value={selectedTheme} checked={selectedTheme==="Sharp Styles"} onChange={()=>setSelectedTheme("Sharp Styles")}/>
				
			<div id="shadow_18space_small_theme_dum">
				<span>Sharp Styles</span>
			</div>
			<img id="web_18mob_small_theme_dummy_mo" src="./Themes/ThemesSelection/sharpStyles_Mob.png" srcset="./Themes/ThemesSelection/sharpStyles_Mob.png 1x" />
				
		</div>
		<div id="med_19grp_dummy_theme_mob">
			<img id="layout_19small_theme_dummy_mob" src="./Themes/ThemesSelection/RealtyScape_pc.png" srcset="./Themes/ThemesSelection/RealtyScape_pc.png 1x" />
				
			<input type="radio" class="radio_btn_19small_theme_dummy_" value={selectedTheme} checked={selectedTheme==="Realty Scape"} onChange={()=>setSelectedTheme("Realty Scape")}/>
				
			<div id="sahara_19_small_theme_dummy_mo">
				<span>Realty Scape</span>
			</div>
			<img id="web_19mob_small_theme_dummy_mo" src="./Themes/ThemesSelection/RealtyScape_Mob.png" srcset="./Themes/ThemesSelection/RealtyScape_Mob.png 1x" />
				
		</div>
		<div id="fash_20grp_theme_mob">
			<img id="layout_20small_theme_mob" src="./Themes/ThemesSelection/KnowledgeSpace_Pc.png" srcset="./Themes/ThemesSelection/KnowledgeSpace_Pc.png 1x" />
				
			<img id="web_20mob_small_theme_mob" src="./Themes/ThemesSelection/KnowledgeSpace_Mob.png" srcset="./Themes/ThemesSelection/KnowledgeSpace_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_20small_theme_mob" value={selectedTheme} checked={selectedTheme==="Knowledge Space"} onChange={()=>setSelectedTheme("Knowledge Space")}/>
			
			<div id="fashion_20_finds_small_theme_m">
				<span>Knowledge Space</span>
			</div>
		</div>
		<div id="fash_21grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/BakersStop_Pc.png" srcset="./Themes/ThemesSelection/BakersStop_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/BakersStop_Mob.png" srcset="./Themes/ThemesSelection/BakersStop_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Bakers Stop"} onChange={()=>setSelectedTheme("Bakers Stop")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Bakers Stop</span>
			</div>
		</div>
		<div id="fash_22grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/HealthHub_Pc.png" srcset="./Themes/ThemesSelection/HealthHub_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/HealthHub_Mob.png" srcset="./Themes/ThemesSelection/HealthHub_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Health Hub"} onChange={()=>setSelectedTheme("Health Hub")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Health Hub</span>
			</div>
		</div>
		<div id="fash_23grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/ChaatsParadice_Pc.png" srcset="./Themes/ThemesSelection/ChaatsParadice_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/ChaatsParadice_Mob.png" srcset="./Themes/ThemesSelection/ChaatsParadice_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Chaats Paradice"} onChange={()=>setSelectedTheme("Chaats Paradice")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Chaats Paradice</span>
			</div>
		</div>
		<div id="fash_24grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/FlowMasters_Pc.png" srcset="./Themes/ThemesSelection/FlowMasters_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/FlowMasters_Mob.png" srcset="./Themes/ThemesSelection/FlowMasters_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Flow Masters"} onChange={()=>setSelectedTheme("Flow Masters")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Flow Masters</span>
			</div>
		</div>
		<div id="fash_25grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/CaptureSphere_Pc.png" srcset="./Themes/ThemesSelection/CaptureSphere_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/CaptureSphere_Mob.png" srcset="./Themes/ThemesSelection/CaptureSphere_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Capture Sphere"} onChange={()=>setSelectedTheme("Capture Sphere")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Capture Sphere</span>
			</div>
		</div>
		<div id="fash_26grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/PlantPlates_Pc.png" srcset="./Themes/ThemesSelection/PlantPlates_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/PlantPlates_Mob.png" srcset="./Themes/ThemesSelection/PlantPlates_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Plant Plates"} onChange={()=>setSelectedTheme("Plant Plates")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Plant Plates</span>
			</div>
		</div>
		<div id="fash_27grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/ElectroTech_Pc.png" srcset="./Themes/ThemesSelection/ElectroTech_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/ElectroTech_Mob.png" srcset="./Themes/ThemesSelection/ElectroTech_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Electro Tech"} onChange={()=>setSelectedTheme("Electro Tech")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Electro Tech</span>
			</div>
		</div>
	
		<div id="fash_28grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/VerdantHarvest_Pc.png" srcset="./Themes/ThemesSelection/VerdantHarvest_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/VerdantHarvest_Mob.png" srcset="./Themes/ThemesSelection/VerdantHarvest_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Verdant Harvest"} onChange={()=>setSelectedTheme("Verdant Harvest")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Verdant Harvest</span>
			</div>
		</div>
		<div id="fash_29grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/FruitFiesta_Pc.png" srcset="./Themes/ThemesSelection/FruitFiesta_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/FruitFiesta_Mob.png" srcset="./Themes/ThemesSelection/FruitFiesta_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Fruit Fiesta"} onChange={()=>setSelectedTheme("Fruit Fiesta")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Fruit Fiesta</span>
			</div>
		</div>
		<div id="fash_30grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/FreshSip_Pc.png" srcset="./Themes/ThemesSelection/FreshSip_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/FreshSip_Mob.png" srcset="./Themes/ThemesSelection/FreshSip_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Fresh Sip"} onChange={()=>setSelectedTheme("Fresh Sip")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Fresh Sip</span>
			</div>
		</div>
		<div id="fash_31grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/ShoeStride_Pc.png" srcset="./Themes/ThemesSelection/ShoeStride_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/ShoeStride_Mob.png" srcset="./Themes/ThemesSelection/ShoeStride_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Shoe Stride"} onChange={()=>setSelectedTheme("Shoe Stride")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Shoe Stride</span>
			</div>
		</div>
		<div id="fash_32grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/SoleCrafted_Pc.png" srcset="./Themes/ThemesSelection/SoleCrafted_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/SoleCrafted_Mob.png" srcset="./Themes/ThemesSelection/SoleCrafted_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Sole Crafted"} onChange={()=>setSelectedTheme("Sole Crafted")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Sole Crafted</span>
			</div>
		</div>
		<div id="fash_33grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/InkwellHaven_Pc.png" srcset="./Themes/ThemesSelection/InkwellHaven_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/InkwellHaven_Mob.png" srcset="./Themes/ThemesSelection/InkwellHaven_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Inkwell Haven"} onChange={()=>setSelectedTheme("Inkwell Haven")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Inkwell Haven</span>
			</div>
		</div>
		<div id="fash_34grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/TextureFusion_Pc.png" srcset="./Themes/ThemesSelection/TextureFusion_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/TextureFusion_Mob.png" srcset="./Themes/ThemesSelection/TextureFusion_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Texture Fusion"} onChange={()=>setSelectedTheme("Texture Fusion")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Texture Fusion</span>
			</div>
		</div>
		<div id="fash_35grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/ColorFusion_Pc.png" srcset="./Themes/ThemesSelection/ColorFusion_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/ColorFusion_Mob.png" srcset="./Themes/ThemesSelection/ColorFusion_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Color Fusion"} onChange={()=>setSelectedTheme("Color Fusion")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Color Fusion</span>
			</div>
		</div>
		<div id="fash_36grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/CleanFabrica_Pc.png" srcset="./Themes/ThemesSelection/CleanFabrica_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/CleanFabrica_Mob.png" srcset="./Themes/ThemesSelection/CleanFabrica_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Clean Fabrica"} onChange={()=>setSelectedTheme("Clean Fabrica")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Clean Fabrica</span>
			</div>
		</div>
		<div id="fash_37grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/PerfumePulse_Pc.png" srcset="./Themes/ThemesSelection/PerfumePulse_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/PerfumePulse_Mob.png" srcset="./Themes/ThemesSelection/PerfumePulse_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Perfume Pulse"} onChange={()=>setSelectedTheme("Perfume Pulse")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Perfume Pulse</span>
			</div>
		</div>
		<div id="fash_38grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/PunctureWizards_Pc.png" srcset="./Themes/ThemesSelection/PunctureWizards_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/PunctureWizards_Mob.png" srcset="./Themes/ThemesSelection/PunctureWizards_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Puncture Wizards"} onChange={()=>setSelectedTheme("Puncture Wizards")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Puncture Wizards</span>
			</div>
		</div>
		<div id="fash_39grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/CycleHaven_Pc.png" srcset="./Themes/ThemesSelection/CycleHaven_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/CycleHaven_Mob.png" srcset="./Themes/ThemesSelection/CycleHaven_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Cycle Haven"} onChange={()=>setSelectedTheme("Cycle Haven")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Cycle Haven</span>
			</div>
		</div>
		<div id="fash_40grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/SweetTreat_Pc.png" srcset="./Themes/ThemesSelection/SweetTreat_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/SweetTreat_Mob.png" srcset="./Themes/ThemesSelection/SweetTreat_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Sweet Treat"} onChange={()=>setSelectedTheme("Sweet Treat")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Sweet Treat</span>
			</div>
		</div>
		<div id="fash_41grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/RadiantLocks_Pc.png" srcset="./Themes/ThemesSelection/RadiantLocks_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/RadiantLocks_Mob.png" srcset="./Themes/ThemesSelection/RadiantLocks_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Radiant Locks"} onChange={()=>setSelectedTheme("Radiant Locks")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Radiant Locks</span>
			</div>
		</div>
		<div id="fash_42grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/TrueVision_Pc.png" srcset="./Themes/ThemesSelection/TrueVision_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/TrueVision_Mob.png" srcset="./Themes/ThemesSelection/TrueVision_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="True Vision"} onChange={()=>setSelectedTheme("True Vision")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>True Vision</span>
			</div>
		</div>
		<div id="fash_43grp_theme_mob">
			<img id="layout_21_small_theme_mob" src="./Themes/ThemesSelection/TechTrekker_Pc.png" srcset="./Themes/ThemesSelection/TechTrekker_Pc.png 1x" />
				
			<img id="web_21_mob_small_theme_mob" src="./Themes/ThemesSelection/TechTrekker_Mob.png" srcset="./Themes/ThemesSelection/TechTrekker_Mob.png 1x" />
				
			<input type="radio" class="radio_btn_21small_theme_mob" value={selectedTheme} checked={selectedTheme==="Tech Trekker"} onChange={()=>setSelectedTheme("Tech Trekker")}/>
			
			<div id="fashion_21_finds_small_theme_m">
				<span>Tech Trekker</span>
			</div>
		</div>
	</div>
</div>

        </Modal>
   
  )
}

export default ThemesSetUpMob
