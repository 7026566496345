import React,{useState,useMemo, useContext,useEffect} from 'react'
import { Typography,AppBar,Button } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import VideoPlayer from './Components/VideoPlayer';
import Options from './Components/Options';
import Notifications from './Components/Notifications';
import './App.css'
import {ContextProvider,SocketContext} from './SocketContext';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
// import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import MeetingRoomUI from './Components/MeetingRoomUI';
import NotificationsUI from './Components/NotificationsUI';

const useStyles = makeStyles((theme) => ({
    appBar: {
      borderRadius: 15,
      margin: '30px 100px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '600px',
      border: '2px solid black',
      "@media (max-width: 1440px)": {
        mobileMenuStyle: {
            width: '90%',
         },
        }
    },
    // image: {
    //   marginLeft: '15px',
    // },
    wrapper: {
       display: 'flex',
      // flexDirection: 'column',
      alignSelf: 'center',
      width: '50%',
      // height:"auto",
      marginTop: "15px"
    },
  }));


const MeetingHomeUI = (props) => {
  
  const classes = useStyles();
  var navigate = useNavigate();
  const [user, setUser] = useState(null);
 
    
//  useEffect(()=>{

//   },[user]);

const handlelogout = () =>{
  localStorage.clear();
  setUser(null);
  window.location.href = "/login";
}

const EditUser = () =>{
  navigate("/edituser");
}

const AddRequirements = ()=>{
  navigate("/requirement");
}

const AddOfferings = ()=>{
  navigate("/offerings");
}

const Suggestions = ()=>{
  navigate("/suggestions");
}

  return (

<ContextProvider>
    <MeetingRoomUI />
    <NotificationsUI />
</ContextProvider>
    
  )
}

export default MeetingHomeUI