import React from 'react';
import "../../../styles/TextureFusion.css";

function TextureFusion(props) {
  return (
    <div id="TextureFusion">
        <div id="TFP_PC_34Group_15171">
            <img id="TFP_PC_34jj-ying-7JX0-bfiuxQ-unsplash" src="./Themes/TextureFusion/jj-ying-7JX0-bfiuxQ-unsplash.png" srcset="./Themes/TextureFusion/jj-ying-7JX0-bfiuxQ-unsplash.png 1x" />
                
            <div id="TFP_PC_34Group_15040">
                <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="TFP_PC_34Rectangle_737">
                    <rect id="TFP_PC_34Rectangle_737" rx="0" ry="0" x="0" y="0" width="538" height="302">
                    </rect>
                </iframe>
            </div>
            <div id="TFP_PC_34socials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
                <div title="LinkedIn" id="TFP_PC_34linked_in_grp_shadow_space_web">
                    <svg class="TFP_PC_34med_shadow_space_website_7">
                        <ellipse id="TFP_PC_34med_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                        </ellipse>
                    </svg>
                    <div id="TFP_PC_34linked_in_shadow_space_website">
					<a href={props.data.linkedInUrl} target="_blank">
                        <svg class="TFP_PC_34n_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
                            <path id="TFP_PC_34n_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
                            </path>
                        </svg>
                    </a>
                        <svg class="TFP_PC_34linked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
                            <path id="TFP_PC_34linked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
                            </path>
                        </svg>
                        <svg class="TFP_PC_34top_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
                            <path id="TFP_PC_34top_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
                            </path>
                        </svg>
                    </div>
                </div>:""}
			{props.data.instagramUrl?	
                <div title="Instagram" id="TFP_PC_34Insta_grp_shadow_space_website">
                    <svg class="TFP_PC_34shadow_space_website_7">
                        <ellipse id="TFP_PC_34shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                        </ellipse>
                    </svg>
				<a href={props.data.instagramUrl} target="_blank">
                    <img id="TFP_PC_34instagramshadow_space_website_" src="./Themes/TextureFusion/instagramshadow_space_website_.png" srcset="./Themes/TextureFusion/instagramshadow_space_website_.png 1x" />
                        
                    </a>
                </div>:""}
			{props.data.whatsAppNumber?
                <div title="WhatsApp" id="TFP_PC_34whatsapp_grp_shadow_space_webs">
                    <svg class="TFP_PC_34med_shadow_space_website_7_">
                        <ellipse id="TFP_PC_34med_shadow_space_website_7_" rx="24" ry="24" cx="24" cy="24">
                        </ellipse>
                    </svg>
                    <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="TFP_PC_34whatsapp_lbl_shadow_space_webs" src="./Themes/TextureFusion/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/TextureFusion/whatsapp_lbl_shadow_space_webs.png 1x" />
                     </a>   
                </div>:""}
            </div>
            <div id="TFP_PC_34n_234567890_mobile_number_shad">
                <span>{props.data.businessPhone}</span>
            </div>
            <div id="TFP_PC_34affanghanioperatelive_email_sh">
                <span>{props.data.businessEmail}</span>
            </div>
            <div id="TFP_PC_34address_med_shadow_space_websi">
                <span>{props.data.businessAddress}</span>
            </div>
            <img id="TFP_PC_34location_img_shadow_space_webs" src="./Themes/TextureFusion/location_img_shadow_space_webs.png" srcset="./Themes/TextureFusion/location_img_shadow_space_webs.png 1x" />
                
            <img id="TFP_PC_34email_address_shadow_space_web" src="./Themes/TextureFusion/email_address_shadow_space_web.png" srcset="./Themes/TextureFusion/email_address_shadow_space_web.png 1x" />
                
            <img id="TFP_PC_34phone_number_shadow_space_webs" src="./Themes/TextureFusion/phone_number_shadow_space_webs.png" srcset="./Themes/TextureFusion/phone_number_shadow_space_webs.png 1x" />
                
            <div id="TFP_PC_34n_Copyright_Operate_Live_OPC_P">
                <span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
            </div>
            <div id="TFP_PC_34Contact_Us">
                <span>Contact Us!</span>
            </div>
        </div>
        <div id="TFP_PC_34Group_15117">
            <img id="TFP_PC_34christian-holzinger-CUY_YHhCFl" src="./Themes/TextureFusion/christian-holzinger-CUY_YHhCFl.png" srcset="./Themes/TextureFusion/christian-holzinger-CUY_YHhCFl.png 1x" />
                
            <svg class="TFP_PC_34Rectangle_748">
                <rect id="TFP_PC_34Rectangle_748" rx="0" ry="0" x="0" y="0" width="1366" height="663">
                </rect>
            </svg>
            <img id="TFP_PC_34Anime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/TextureFusion/Anime_Edit.png"} />
                
            <div id="TFP_PC_34Your_Business_Name">
                <span>{props.data.businessName}</span>
            </div>
            <div id="TFP_PC_34Elevate_Your_Space_with_Unique">
                <span>"Elevate Your Space with Unique Texture Designs: <br/>Where Creativity and Texture Converge for a <br/>Truly Extraordinary Environment!"</span>
            </div>
            <div id="TFP_PC_34Group_15113">
                <svg class="TFP_PC_34Rectangle_3">
                    <rect id="TFP_PC_34Rectangle_3" rx="24.5" ry="24.5" x="0" y="0" width="177" height="49">
                    </rect>
                </svg>
                <div id="TFP_PC_34Available_Now">
                    <span>Explore More</span>
                </div>
            </div>
        </div>
        <div id="TFP_PC_34Group_15169">
            <img id="TFP_PC_34pawel-czerwinski-eBd0dPuR-bo-u" src="./Themes/TextureFusion/pawel-czerwinski-eBd0dPuR-bo-u.png" srcset="./Themes/TextureFusion/pawel-czerwinski-eBd0dPuR-bo-u.png 1x" />
                
            <div id="TFP_PC_34Group_15141">
                <svg class="TFP_PC_34Rectangle_752">
                    <rect id="TFP_PC_34Rectangle_752" rx="10" ry="10" x="0" y="0" width="915" height="376">
                    </rect>
                </svg>
                <img id="TFP_PC_34n_3430759_5249503" src="./Themes/TextureFusion/n_3430759_5249503.png" srcset="./Themes/TextureFusion/n_3430759_5249503.png 1x" />
                    
                <div id="TFP_PC_34Scroll_Group_28">
                    <div id="TFP_PC_34It_is_a_long_established_fact_">
                        <span>{props.data.vision}</span>
                    </div>
                </div>
                <div id="TFP_PC_34VISION">
                    <span>VISION</span>
                </div>
            </div>
            <div id="TFP_PC_34Group_15142">
                <svg class="TFP_PC_34Rectangle_752_bu">
                    <rect id="TFP_PC_34Rectangle_752_bu" rx="10" ry="10" x="0" y="0" width="915" height="376">
                    </rect>
                </svg>
                <img id="TFP_PC_34n_60450_OFC6C30" src="./Themes/TextureFusion/n_60450_OFC6C30.png" srcset="./Themes/TextureFusion/n_60450_OFC6C30.png 1x" />
                    
                <div id="TFP_PC_34Scroll_Group_28_bw">
                    <div id="TFP_PC_34It_is_a_long_established_fact__bx">
                        <span>{props.data.mission}</span>
                    </div>
                </div>
                <div id="TFP_PC_34MISSION">
                    <span>MISSION</span>
                </div>
            </div>
            <div id="TFP_PC_34Group_15143">
                <svg class="TFP_PC_34Rectangle_752_b">
                    <rect id="TFP_PC_34Rectangle_752_b" rx="10" ry="10" x="0" y="0" width="915" height="376">
                    </rect>
                </svg>
                <img id="TFP_PC_34n_20566_OCCYZ60" src="./Themes/TextureFusion/n_20566_OCCYZ60.png" srcset="./Themes/TextureFusion/n_20566_OCCYZ60.png 1x" />
                    
                <div id="TFP_PC_34Scroll_Group_28_b">
                    <div id="TFP_PC_34It_is_a_long_established_fact__b">
                        <span>{props.data.principles}</span>
                    </div>
                </div>
                <div id="TFP_PC_34PRINCIPLES">
                    <span>PRINCIPLES</span>
                </div>
            </div>
        </div>
        <div id="TFP_PC_34Group_15170">
            <div id="TFP_PC_34FRESH_DESIGNS">
                <span>WE PROVIDE</span>
            </div>
            <div id="TFP_PC_34Group_15145">
                <svg class="TFP_PC_34Rectangle_753">
                    <rect id="TFP_PC_34Rectangle_753" rx="10" ry="10" x="0" y="0" width="185" height="61">
                    </rect>
                </svg>
                <div id="TFP_PC_34View_More">
                    <span>View More</span>
                </div>
            </div>
            <div id="TFP_PC_34Scroll_Group_91">
    {props.offeringsData.map((off,idx)=>(
                <div id="TFP_PC_34Group_15144">
                    <svg class="TFP_PC_34Rectangle_754">
                        <rect id="TFP_PC_34Rectangle_754" rx="10" ry="10" x="0" y="0" width="243" height="331">
                        </rect>
                    </svg>
                    <img id="TFP_PC_34luca-laurence-FseXc3OsIic-unsp" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/TextureFusion/luca-laurence-FseXc3OsIic-unsp.png"} />
                        
                    <div id="TFP_PC_34Title">
                        <span>{off.offeringTitle}</span>
                    </div>
                    <div id="TFP_PC_34n_10000">
                        <span>₹ {off.budget}</span>
                    </div>
                </div>))}
            </div>
        </div>
    </div>
  )
}

export default TextureFusion