import React, { useState, useEffect, useContext } from "react";
import { Button, Divider } from "@mui/material";
import "../../styles/AddAlarmToCalander.css";
import Modal from "@mui/material/Modal";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";

const AddAlarmToCalander = (props) => {

	const {user} = useContext(UserContext)
	const [alarmStartOn,setAlarmStartOn] = useState(new Date().toISOString().substring(0,16))
	const [alarmTitle,setAlarmTitle] = useState("")
	// const [snoozeTime,setSnoozeTime] = useState("")
	const [alarmDiscription,setAlarmDiscription] = useState("")


	useEffect(()=>{

		if(props.dateAndTime){
		
			setAlarmStartOn(props.dateAndTime)
		}
		
		  },[props.dateAndTime])


	const addAlarm = () => {

        if(alarmTitle.length === 0){
  
      return alert("Please Enter Alert Title!!")
  
      }
  
      if(alarmDiscription.length === undefined || alarmDiscription.length === 0){
       
       return alert("Please Enter Alarm Description!!")
      }
      if(alarmStartOn.length === undefined || alarmStartOn.length ==="" ){
   
        return alert("Please select Alarm Start Details!!")
      }
  
     let apiURL = process.env.REACT_APP_API_URL + "/alarms/addalarm";
     let apiParams = {
       method: "POST",
       headers: {

	   alarmtitle:alarmTitle,
       eventdiscription:encodeURIComponent(alarmDiscription),
       alarmstarton:alarmStartOn,
       
       userid:user._id,
       username:user.name,
       
       },
     };
     fetch(apiURL, apiParams)
       .then((res) => (res.status === 200 ? res.json() : res.text()))
       .then((repos) => {
       if (repos.data) {
        props.close()
         alert("alarm Added Successfully");

       } else alert(repos.message || repos);
       })
       .catch((err) => alert(err));
     };

  return (
    <div>	
    <div id="add_btn_grp_add_event_details" onClick={()=>{addAlarm()}}>
    <svg class="add_btn_bdr_add_calender_event">
        <rect id="add_btn_bdr_add_calender_event" rx="7" ry="7" x="0" y="0" width="120" height="40">
        </rect>
    </svg>
    <div id="Add_btn_add_calender_event">
        <span>Add</span>
    </div>
   </div>
    <div id="alarm_grp_calender_pop_alrm_po">
		<div id="start_at_grp_add_alrm_pop">
			<input type="datetime-local" class="start_at_bdr_add_alrm_pop"  value={alarmStartOn} onChange={(e)=>setAlarmStartOn(e.target.value)}/>
			
			<div id="start_at_lbl_add_alrm_pop">
				<span>Start At</span>
			</div>
		</div>
		<div id="description_grp_add_alrm_pop">
			<textarea placeholder="Description" class="descp_bdr_add_alrm_pop" value={alarmDiscription} onChange={(e)=>setAlarmDiscription(e.target.value)} />
			
			<div id="Description_lbl_add_alrm_pop">
				<span>Description</span>
			</div>
		</div>
		
		<div id="title_grp_add_alrm_pop">
			<input placeholder="Title" class="title_bdr_add_alrm_pop" value={alarmTitle}  onChange={(e)=>setAlarmTitle(e.target.value)} />
				
			<div id="title_lbl_add_alrm_pop">
				<span>Title</span>
			</div>
		</div>
		{/* <div id="snooze_grp_add_alrm_pop">
			<select class="snooze_bdr_add_alrm_pop" value={snoozeTime} onChange={(e)=>setSnoozeTime(e.target.value)}>
				<option value="5">5</option>
				<option value="10">10</option>
				<option value="15">15</option>
				
			</select>
			<div id="snooze_timelbl_add_alrm_pop">
				<span>Snooze Timing</span>
			</div>
			<div >
			<svg class="button_snooze_bdr_add_alrm_pop">
				<rect id="button_snooze_bdr_add_alrm_pop" rx="5" ry="5" x="0" y="0" width="40" height="40">
				</rect>
			</svg>
			
			<img id="snooze_1" src="./CalanderDashboard/snooze_img_calender.png" srcset="./CalanderDashboard/snooze_img_calender.png 1x" />
			</div>
		</div> */}
	</div>
	</div>
  )
}

export default AddAlarmToCalander
