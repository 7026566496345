import React, { useEffect, useState, useContext} from 'react'
import '../../styles/CalenderProfileMobUI.css';
import { UserContext } from '../UserContext';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Calendar from 'react-calendar';

const CalenderProfileMobUI = (props) => {
  const navigate = useNavigate();
	const [date,setDate] = useState(new Date().toISOString().substring(0,16))

  return (
	//onClick={()=>navigate('/calenderDasboard')}
	<div id="calender_mob">
	<Calendar value={date} onViewChange={()=>navigate('/calenderDasboard')} onChange={()=>navigate('/calenderDasboard')}/>
   </div>

  )
}

export default CalenderProfileMobUI
