import React, { useState, useEffect, useContext } from "react";
import "../../../styles/HarmonyHaven.css";
import { UserContext } from '../../UserContext';
import { useNavigate,useLocation, Navigate } from "react-router-dom";

const HarmonyHaven = (props) => {
  return (
    <div>
      <div id="Website_4">
	<svg class="princi_desgin_bdr_med_web_4_pc">
		<rect id="princi_desgin_bdr_med_web_4_pc" rx="10" ry="10" x="0" y="0" width="246" height="392">
		</rect>
	</svg>
	<svg class="vision_desgin_bdr_med_web_4_pc">
		<rect id="vision_desgin_bdr_med_web_4_pc" rx="10" ry="10" x="0" y="0" width="246" height="392">
		</rect>
	</svg>
	<svg class="mission_desgin_bdr_med_web_4_p">
		<rect id="mission_desgin_bdr_med_web_4_p" rx="10" ry="10" x="0" y="0" width="246" height="392">
		</rect>
	</svg>
	<svg class="design_main_page" viewBox="5100.037 16976.803 965.786 759.197">
		<path id="design_main_page" d="M 5100.037109375 17736 L 5211.2470703125 17649.951171875 C 5211.2470703125 17649.951171875 5217.4931640625 17643.4609375 5234.92626953125 17643.4609375 C 5252.359375 17643.4609375 5675.66455078125 17643.337890625 5675.66455078125 17643.337890625 C 5675.66455078125 17643.337890625 5684.9345703125 17644.72265625 5684.6611328125 17636.240234375 C 5684.3876953125 17627.7578125 5684.6611328125 17339.40234375 5684.6611328125 17339.40234375 C 5684.6611328125 17339.40234375 5684.9287109375 17335.126953125 5688.66455078125 17331.763671875 C 5692.400390625 17328.400390625 6065.82275390625 16976.802734375 6065.82275390625 16976.802734375 L 6065.82275390625 17736 L 5100.037109375 17736 Z">
		</path>
	</svg>
	<h1 id="Heading_left_med_web_4_pc" split-by="letter" letter-animation="breath">
		<span>{props.data.businessName && props.data.businessName.length > 0?props.data.businessName.split(" ")[0]:props.data.name}</span>
	</h1>

	<div id="menu_grp_med_web_4_pc">

    <a href="#profile_pic_logo_web_4_pc">
		<div id="home_med_web_4_pc">
			<div id="Homemed_web_4_pc">
				<span>Home</span>
			</div>
			<img id="home-icon-silhouette_med_web_4" src="./Themes/HarmonyHaven/home-icon-silhouette_med_web_4.png" srcset="./Themes/HarmonyHaven/home-icon-silhouette_med_web_4.png 1x" />
				
		</div></a>
    <a href="#oofering_lbl_med_web_4_pc">
		<div id="offering_med_web_4_pc">

			<div id="Offerings_med_web_4_pc">
				<span>Offerings</span>
			</div>
			<img id="discount_med_web_4_pc" src="./Themes/HarmonyHaven/discount_med_web_4_pc.png" srcset="./Themes/HarmonyHaven/discount_med_web_4_pc.png 1x" />
				
		</div>
    </a>
    <a href="#footer_med_web_4_pc">
		<div id="contact_grp_med_web_4_pc">
			<div id="Contact_lbl_med_web_4_pc">
				<span>Contact</span>
			</div>
			<img id="contact_img_med_web_4_pc" src="./Themes/HarmonyHaven/contact_img_med_web_4_pc.png" srcset="./Themes/HarmonyHaven/contact_img_med_web_4_pc.png 1x" />
				
		</div>
    </a>
	</div>
	<div id="Heading_med_web_4_pc">
		<span>{props.data.businessName && props.data.businessName.length > 0 ? props.data.businessName.split(" ")[1]:""}</span>
	</div>
	<img id="markus-wink_img_centre_med_web" src="./Themes/HarmonyHaven/markus-wink_img_centre_med_web.png" srcset="./Themes/HarmonyHaven/markus-wink_img_centre_med_web.png 1x" />
		
	<div id="Scroll_intro_med_web_4_pc">
		<div id="Lorem_ipsum_dolor_sit_amet_hh_pc">
			<span>{props.data.introduction}</span>
		</div>
	</div>
	<div id="text_our_partner_bdr_med_web_4">
		<span>Our Amazing Partners</span>
	</div>
	<div id="Scroll_scroll_bdr_med_web_4_pc">
    <marquee direction="up">
    {props.partnersData && props.partnersData.map(partner=>
        <div onClick={()=>window.location.href="https://"+partner.nickName+".operate.live"}>
		<div id="g_and_google_grp">
			<div id="google_lbl_partnermed_web_4_pc">
				<span>{partner.businessName && partner.businessName.length>0?partner.businessName:partner.name}</span>
			</div>
			<img id="google_1img_med_web_4_pc" src={partner.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(partner.imageUrl.split('/')[5]):"./Themes/office.png"} />
				
		</div>
        </div>
            )}
        </marquee>
	</div>
	<img id="marijuana_img_bdr_med_web_4_pc" src="./Themes/HarmonyHaven/marijuana_img_bdr_med_web_4_pc.png" srcset="./Themes/HarmonyHaven/marijuana_img_bdr_med_web_4_pc.png 1x" />
		
	<div id="follow_us_btn__grp_web_4_pc">
		<svg class="bdr_med_web_4_pc_cl">
			<rect id="bdr_med_web_4_pc_cl" rx="10" ry="10" x="0" y="0" width="200" height="41">
			</rect>
		</svg>
		<div id="Follow_Us_med_web_4_pc_cm">
			<span>Follow Us </span>
		</div>
		<svg class="arrow_follow_us_med_web_4_pc_cn" viewBox="0 2.647 20 19.493">
			<path id="arrow_follow_us_med_web_4_pc_cn" d="M 8.503515243530273 3.95292592048645 L 9.494475364685059 2.961965322494507 C 9.914072036743164 2.542366743087769 10.59256649017334 2.542366743087769 11.00769996643066 2.961965322494507 L 19.68530082702637 11.63510417938232 C 20.10490036010742 12.05470180511475 20.10490036010742 12.73319339752197 19.68530082702637 13.1483268737793 L 11.00769901275635 21.8259334564209 C 10.58810329437256 22.24552536010742 9.90960693359375 22.24552536010742 9.494475364685059 21.8259334564209 L 8.503514289855957 20.83497047424316 C 8.07945442199707 20.41090965270996 8.088381767272949 19.71902465820312 8.521368980407715 19.30389022827148 L 13.90023326873779 14.17946434020996 L 1.071308970451355 14.17946434020996 C 0.4776252210140228 14.17946434020996 0 13.70183372497559 0 13.10815334320068 L 0 11.67973899841309 C 0 11.08605861663818 0.4776252210140228 10.60843181610107 1.071308970451355 10.60843181610107 L 13.90023326873779 10.60843181610107 L 8.521369934082031 5.484004974365234 C 8.083918571472168 5.06887149810791 8.074991226196289 4.376983165740967 8.503515243530273 3.95292592048645 Z">
			</path>
		</svg>
	</div>
	<div id="all_grp_mission_vision_princi">
		<div id="mission_all_med_web_4_pc">
			<svg class="bdr_mission_med_web_4_pc">
				<rect id="bdr_mission_med_web_4_pc" rx="10" ry="10" x="0" y="0" width="247" height="392">
				</rect>
			</svg>
			<img id="box_1_website_med_web_4_pc" src="./Themes/HarmonyHaven/box_1_website_med_web_4_pc.png" srcset="./Themes/HarmonyHaven/box_1_website_med_web_4_pc.png 1x" />
				
			<div id="mission_lbl_med_web_4_pc">
				<span>Mission</span>
			</div>
			<div id="Scroll_mission_med_web_4_pc">
				<div id="Lorem_ipsum_dolor_sit_amet_con_c">
					<span>{props.data.mission}</span>
				</div>
			</div>
		</div>
		<div id="vision_grp_all_med_web_4_pc">
			<svg class="bdr_vision_back_med_web_4_pc">
				<rect id="bdr_vision_back_med_web_4_pc" rx="10" ry="10" x="0" y="0" width="247" height="392">
				</rect>
			</svg>
			<img id="box_2_website_1_med_web_4_pc" src="./Themes/HarmonyHaven/box_2_website_1_med_web_4_pc.png" srcset="./Themes/HarmonyHaven/box_2_website_1_med_web_4_pc.png 1x" />
				
			<div id="vision_lbl_med_web_4_pc">
				<span>Vision</span>
			</div>
			<div id="Scroll_vision_med_web_4_pc">
				<div id="Lorem_ipsum_dolor_sit_amet_con_dc">
					<span>{props.data.vision}</span>
				</div>
			</div>
		</div>
		<div id="principle_grp_all_med_web_4_pc">
			<svg class="princi_bdr_akll_med_web_4_pc">
				<rect id="princi_bdr_akll_med_web_4_pc" rx="10" ry="10" x="0" y="0" width="247" height="392">
				</rect>
			</svg>
			<img id="box_3_off_med_web_4_pc_op_live" src="./Themes/HarmonyHaven/box_3_off_med_web_4_pc_op_live.png" srcset="./Themes/HarmonyHaven/box_3_off_med_web_4_pc_op_live.png 1x" />
				
			<div id="princi_med_web_4_pc_lbl">
				<span>Principles</span>
			</div>
			<div id="Scroll_prrinci_details_med_web">
				<div id="Lorem_ipsum_dolor_sit_amet_con_do">
					<span>{props.data.principles}</span>
				</div>
			</div>
		</div>
	</div>
	<div id="oofering_lbl_med_web_4_pc">
		<span>My Offerings</span>
	</div>
	<div id="Scroll_offering_med_web_4_pc">
    {props.offeringsData.map((rec,idx)=>(
		<div id="offering_crd_1_grp_med_web_4_p">
			<svg class="title_bdr_med_web_4_pc">
				<rect id="title_bdr_med_web_4_pc" rx="7" ry="7" x="0" y="0" width="172" height="32">
				</rect>
			</svg>
			<div id="BiziBees_PVT_LTD_med_web_4_pc">
				<span>{rec.offeringTitle}</span>
			</div>
			<img id="img_border_med_web_4_pc_of" src="./Themes/HarmonyHaven/img_border_med_web_4_pc_of.png" srcset="./Themes/HarmonyHaven/img_border_med_web_4_pc_of.png 1x" />
				
			<img id="chuttersna_med_web_4_pc_img" src={rec.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(rec.defaultOfferingsImageUrl.split('/')[5]):"./Themes/HarmonyHaven/profilebackgroundEm.png" } />
				
		</div>
        ))}
	</div>

	<iframe class="map_area_med_web_4_pc" width="1366" height="330" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"}>
	<rect id="map_area_med_web_4_pc" rx="0" ry="0" x="0" y="0" width="1366" height="330">
		</rect>
	</iframe>
	<svg class="map_top_med_web_4_pc" viewBox="-30.902 0 1396.902 23">
		<path id="map_top_med_web_4_pc" d="M -30.90185546875 0 L -30.90185546875 23 L 1366 22.78886604309082 L -30.90185546875 0 Z">
		</path>
	</svg>
	<svg class="map_below_desion_med_web_4_pc" viewBox="0 0 1366 23">
		<path id="map_below_desion_med_web_4_pc" d="M 1366 0 L 0 0 L 1366 23 L 1366 0 Z">
		</path>
	</svg>
	<svg class="footer_med_web_4_pc">
		<rect id="footer_med_web_4_pc" rx="0" ry="0" x="0" y="0" width="1367" height="299">
		</rect>
	</svg>
	<div id="n_234567890_mobile_number_webs">
		<span>{props.data.businessPhone}</span>
	</div>
	<div id="affanghanioperatelive_email_we">
		<span>{props.data.businessEmail}</span>
	</div>
	<div id="address_med_web_4_pc">
		<span>{props.data.businessAddress}</span>
	</div>
	<div id="Connect_with_us_and_unlock_lim_hh_pc">
		<span>Discover a world of limitless possibilities by connecting with us today!</span>
	</div>
	<img id="location_img_med_web_4_pc" src="./Themes/HarmonyHaven/location_img_med_web_4_pc.png" srcset="./Themes/HarmonyHaven/location_img_med_web_4_pc.png 1x" />
		
	<img id="email_address_med_web_4_pc" src="./Themes/HarmonyHaven/email_address_med_web_4_pc.png" srcset="./Themes/HarmonyHaven/email_address_med_web_4_pc.png 1x" />
		
	<img id="phone_number_med_web_4_pc" src="./Themes/HarmonyHaven/phone_number_med_web_4_pc.png" srcset="./Themes/HarmonyHaven/phone_number_med_web_4_pc.png 1x" />
		
	<img id="quote_left_med_web_4_pc" src="./Themes/HarmonyHaven/quote_left_med_web_4_pc.png" srcset="./Themes/HarmonyHaven/quote_left_med_web_4_pc.png 1x" />
		
	<img id="quote_right_med_web_4_pc" src="./Themes/HarmonyHaven/quote_right_med_web_4_pc.png" srcset="./Themes/HarmonyHaven/quote_right_med_web_4_pc.png 1x" />
		
	<a href="https://operate.live" target="_blank">
	<div id="n_Copyright_Operate_Live_med_w">
		<span>© Copyright Operate Live (OPC) Private Limited. All Rights Reserved</span>
	</div>
	</a>
	<div id="socials_grp_med_web_4_pc">
	{props.data.linkedInUrl?	
    <div title="LinkedIn" id="linked_in_grp_med_web_4_pc">
			<svg class="Ellipse_5_med_web_4_pc">
				<ellipse id="Ellipse_5_med_web_4_pc" rx="32.5" ry="32.5" cx="32.5" cy="32.5">
				</ellipse>
			</svg>
			<div id="linked_in_website_4_layout">
				<a href={props.data.linkedInUrl} target="_blank">
				<svg class="n_website_2_layout" viewBox="-15.255 0 20.919 19.736">
					<path id="n_website_2_layout" d="M -2.165393829345703 9.679508821136551e-07 C -5.699716567993164 9.679508821136551e-07 -7.914352416992188 1.914318561553955 -8.323646545410156 3.255455732345581 L -8.323646545410156 0.1879152059555054 L -15.2549991607666 0.1879152059555054 C -15.16490459442139 1.816501379013062 -15.2549991607666 19.7360897064209 -15.2549991607666 19.7360897064209 L -8.323646545410156 19.7360897064209 L -8.323646545410156 9.158007621765137 C -8.323646545410156 8.570239067077637 -8.351963043212891 7.982472896575928 -8.17348575592041 7.560311794281006 C -7.696406841278076 6.384778022766113 -6.672748565673828 5.164625644683838 -4.83222484588623 5.164625644683838 C -2.426241397857666 5.164625644683838 -1.331366658210754 6.972543716430664 -1.331366658210754 9.620498657226562 L -1.331366658210754 19.7360897064209 L 5.66434383392334 19.7360897064209 L 5.66434383392334 8.866268157958984 C 5.66434383392334 2.811842679977417 2.219258308410645 9.679508821136551e-07 -2.165393829345703 9.679508821136551e-07">
					</path>
				</svg>
			</a>
				<svg class="linked_in_i_down" viewBox="0.931 -22.783 6.169 19.549">
					<path id="linked_in_i_down" d="M 0.9310001134872437 -3.233965396881104 L 7.100403785705566 -3.233965396881104 L 7.100403785705566 -22.78300476074219 L 0.9310001134872437 -22.78300476074219 L 0.9310001134872437 -3.233965396881104 Z">
					</path>
				</svg>
				<svg class="top_dot_i_website_2_layout" viewBox="-4.392 0 7.489 6.783">
					<path id="top_dot_i_website_2_layout" d="M -0.6234287023544312 0 C -2.90156078338623 0 -4.392000198364258 1.462981224060059 -4.392000198364258 3.394460201263428 C -4.392000198364258 5.288183689117432 -2.944462776184082 6.782914161682129 -0.7109499573707581 6.782914161682129 L -0.6663313508033752 6.782914161682129 C 1.652987360954285 6.782914161682129 3.097091197967529 5.283035755157471 3.097091197967529 3.388453245162964 C 3.05247163772583 1.459548950195312 1.652987360954285 0 -0.6234287023544312 0">
					</path>
				</svg>
			</div>
		</div>:""}

        {props.data.instagramUrl?	
		<div title="Instagram" id="Insta_grp_med_web_4_pc">
			<svg class="Ellipse_4_med_web_4_pc">
				<ellipse id="Ellipse_4_med_web_4_pc" rx="32.5" ry="32.5" cx="32.5" cy="32.5">
				</ellipse>
			</svg>
			<a href={props.data.instagramUrl} target="_blank">
			<img id="instagram_website_4_layout" src="./Themes/HarmonyHaven/instagram_website_4_layout.png" srcset="./Themes/HarmonyHaven/instagram_website_4_layout.png 1x" />
			</a>
		</div>:""}

		{props.data.whatsAppNumber?<div title="WhatsApp" id="whatsapp_grp_med_web_4_pc">
			<svg class="Ellipse_3_med_web_4_pc">
				<ellipse id="Ellipse_3_med_web_4_pc" rx="32.5" ry="32.5" cx="32.5" cy="32.5">
				</ellipse>
			</svg>
            <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
			<img id="whatsapp_lbl_website4_layout" src="./Themes/HarmonyHaven/whatsapp_lbl_website4_layout.png" srcset="./Themes/HarmonyHaven/whatsapp_lbl_website4_layout.png 1x" />
            </a>
		</div>:""}
	</div>

	<svg class="medincine_bdr_web_4_pc">
		<rect id="medincine_bdr_web_4_pc" rx="0" ry="0" x="0" y="0" width="1366" height="70">
		</rect>
	</svg>
	<marquee loop='999' scrollamount='18' id="Medicine_is_not_only_a_science">
		<span>"Medicine is not only a science; it is an art. It does not consist of compounding pills and plasters; it deals with the very processes of life, which must be understood before they may be guided."</span>
	</marquee >
	<img id="profile_pic_logo_web_4_pc" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/HarmonyHaven/user_pfp_profile_page.png"} />
		
</div>
    </div>
  )
}

export default HarmonyHaven
