import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
 import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../styles/BargainPopUpPC.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Currencies from "./Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const BargainPopUpPC = (props) => {

  //useState
  const { user, setUser} = useContext(UserContext);
  const [openBargainPopUp,setOpenBargainPopUp] = useState(false);
  const [offeredCurrency,setOfferedCurrency] = useState({});
    const [openModel,setOpenModel] = useState(false);
    const [openMeetingModel,setOpenMeetingModel] = useState(false);
    const [status,setStatus] = useState([]);
    const [biddings,setBiddings] = useState("");
    const [deliveryDeadline, setDeliveryDeadline] = useState(new Date().toISOString());
    const [deliveryLocation, setDeliveryLocation] = useState();
    const [description, setDescription] = useState("");
    const [services, setServices] = useState("");
    const [budget,setBudget] = useState(0);
    const [remarks,setRemarks] = useState("");
    const [needToBeDelivered,setNeedToBeDelivered]= useState(false);
    const [companyName, setCompanyName] = useState("");
    const [hours, setHours]= useState("hours");
    const [bargainedTime,setBargainedTime] = useState("");
    const [bargainedAmount,setBargainedAmount] = useState("");
    const [offeringTime,setOfferingTime] = useState("")
    const [offHours,setOffHours] = useState("hours")
    const [ selectedCommunity,setSelectedCommunity] = useState([])
    const [communities,setCommunities] = useState([]);
   const [requirementId,setRequirementId]=useState("");
    const [offeringId,setOfferingId]=useState("");

    const [title,setTitle]= useState("");
    const [createdById,setCreatedById]=useState("");
    const [createdByName,setCreatedByName]=useState("");
    const [bargainedId,setBargainedId] = useState();
    
    const [bargainedCurrency,setBargainedCurrency] = useState({})
   



  //useEffect
  useEffect (()=>{
if(props){
  if(props.openBarPopUp){
    setOpenBargainPopUp(props.openBarPopUp)
 
  if(props.selectedBargain){
   
    setOfferingId(props.selectedBargain.id);
    setTitle(props.selectedBargain.title);
    setCreatedById(props.selectedBargain.vendorId);
    setCreatedByName(props.selectedBargain.vendorName);
    getBargains(props.selectedBargain.id)
  }
}
}
  },[props.openBarPopUp])

const handleClose = ()=>{
  setOpenBargainPopUp(false)
  props.closeBarPopUp()
}


 //get Bargains of comunity
 const getBargains = (id)=>{

  let apiURL=process.env.REACT_APP_API_URL + "/bargain/getbargain/"+user._id; 
  let apiParams = {
    headers:{
      offeringsid:id,
  }}
  fetch(apiURL,apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
          
         setBargainedId(repos.data[0] && repos.data[0]._id)
         setBargainedAmount(repos.data[0] && repos.data[0].bargainedAmount);
         setBargainedCurrency(repos.data[0] && repos.data[0].bargainedCurrency && (repos.data[0].bargainedCurrency));
         setBargainedTime(repos.data[0] && repos.data[0].bargainedHours);
         setNeedToBeDelivered(repos.data[0] && repos.data[0].needToBeDelivered);
         setRemarks(repos.data[0] && repos.data[0].remarks);
      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}


const addBargains = ()=>{
      
  setOpenModel(false)
  let BargainedTime=0;
  if(hours==="hours"){
   BargainedTime = bargainedTime;
  }else{
   BargainedTime = bargainedTime*8;
  }
  let apiURL=process.env.REACT_APP_API_URL + "/bargain/addbargains";
  let apiParams = {
      method:"POST",
      headers:{
       offeringtitle:title,
        offeringid:offeringId,
        vendorid:createdById,
        vendorname:createdByName,
        bargainedamount:bargainedAmount,
        bargainedcurrency:encodeURIComponent(bargainedCurrency),
        bargainedhours:BargainedTime,
        needtobedelivered:needToBeDelivered,
        remarks:encodeURIComponent(remarks),
        customerid:user._id,
        customername:user.name,
        status:"Submitted"
      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        // cleartext()
        alert("Bargained Added Successfully");
        handleClose()
      //  setTimeout(() => {
      //    getOfferings()
      // }, 1500);
      
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
}

const updateBargain = (id)=>{
   
  setOpenModel(false)
     let BargainedTime=0;
     if(hours==="hours"){
      BargainedTime = bargainedTime;
     }else{
      BargainedTime = bargainedTime*8;
     }
     let apiURL=process.env.REACT_APP_API_URL + "/bargain/updatebargainsdata/"+id;
     let apiParams = {
         method:"POST",
         headers:{
           offeringid:offeringId,
           bargainedamount:bargainedAmount,
           //bargainedcurrency:encodeURIComponent(JSON.stringify(Currencies.filter(curr=>curr.cc===bargainedCurrency)[0])),
           bargainedcurrency:encodeURIComponent(bargainedCurrency),
           bargainedhours:BargainedTime,
           needtobedelivered:needToBeDelivered,
           remarks:encodeURIComponent(remarks),
           userid:user._id,
           username:user.name,
           status:"Updated"
         },
        
     };
     fetch(apiURL, apiParams)
     .then((res)=> (res.status===200 ? res.json() : res.text()))
     .then((repos)=> {
         if (repos.data) {

           alert("Bargained Updated Successfully");
           handleClose()
        //   setTimeout(() => {
        //     getOfferings()
        //  }, 1500);
         
         }else alert(repos.message || repos);
     }) 
     .catch((err) => alert(err));
 }
 

 const cancelBargain = (id,status)=>{
    
  let apiURL=process.env.REACT_APP_API_URL + "/bargain/updatebargains/"+id;
  let apiParams = {
      method:"POST",
      headers:{ 
      offeringid:offeringId,
      userid:user._id,
      username:user.name,
      status:status,
      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
       
        alert("Offering Status Updated Successfully!!");
        handleClose()
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));

 }



  return (
    <div>
        <Modal
        open={openBargainPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
  
  <div id="pop_up-2_Bargain_pc_bar">
	<img id="bargainBg_pc" src="n_411.png" srcset="n_411.png 1x, n_411@2x.png 2x" />
		
	<svg class="outerborder_pc_bar">
		<rect id="outerborder_pc_bar" rx="48" ry="48" x="0" y="-172" width="118" height="345">
		</rect>
	</svg>
	<div id="Bargain_lbl_pc_">
		<span>Bargain</span>
	</div>
	<div id="Group_bargained_amount_pc">
		<input placeholder="Bargained Amount" class="bargained_amount_bar_pc" value={bargainedAmount} onChange={(e)=> {
         setBargainedAmount(e.target.value);}}/>
			{/* <rect id="Rectangle_23" rx="0" ry="0" x="0" y="0" width="138" height="36">
			</rect>
		</input> */}
		<select class="currency_barg_pc" value={bargainedCurrency}
                                // defaultValue={companyName}
                                onChange={(e) => {
                                  setBargainedCurrency(e.target.value);
                                  
                                }}>
 {/* {Currencies &&
                                  Currencies.map((currency) => (
                                    <option value={currency.cc}> {currency.name}</option>
                                    
                                  ))} */}
                                  <option>----Select----</option>
            {Currencies &&
              Currencies.map((currency) => (
                <option value={JSON.stringify(currency)}>
                  {" "}
                  {currency.name}
                </option>
              ))}

		
     
		</select>
		<input placeholder="Estimated Time" class="estimated_time_bar_pc"value={bargainedTime} onChange={(e)=> {
         setBargainedTime(e.target.value);}}/>
			{/* <rect id="Rectangle_23_v" rx="0" ry="0" x="0" y="0" width="138" height="36">
			</rect>
		</input> */}
		<select class="estimated_hours_pc_bar"  value={hours}
                                onChange={(e) => {
                                  setHours(e.target.value);
                                }}>
			<option value="hours">Hours</option>
      <option value="day">Days</option>
		</select>
	</div>
	<input type="checkbox" placeholder="Remarks/Info" class="needToBedelivered_pc_barg" checked={needToBeDelivered} onChange={()=>setNeedToBeDelivered(!needToBeDelivered)}/>
		
	<div id="Need_to_be_Delivered_for_Locat_pc">
		<span>Need to be Delivered for Location</span>
	</div>
	<textarea placeholder="Remark/Info" class="remarks_info_pc_bar"  value={remarks} onChange={(e)=> {
       setRemarks(e.target.value);}}   />
		
    {bargainedId && bargainedId.length > 0 ?
    <div id="Group_update_pc_bar" onClick={()=>updateBargain(bargainedId)}>
		<svg class="update_border_pc_bar">
			<rect id="update_border_pc_bar" rx="15" ry="15" x="0" y="0" width="101" height="37">
			</rect>
		</svg>
		<div id="Update_lbl_pc_bar">
			<span>Update</span>
		</div>
	</div>:<div id="Group_update_pc_bar" onClick={()=>addBargains()}>
		<svg class="update_border_pc_bar">
			<rect id="update_border_pc_bar" rx="15" ry="15" x="0" y="0" width="101" height="37">
			</rect>
		</svg>
		<div id="Update_lbl_pc_bar">
			<span>Submit</span>
		</div>
	</div>}
  {bargainedId && bargainedId.length > 0 ?
	<div id="Group_discard_pc_bar" onClick={()=>cancelBargain(bargainedId,"Cancelled")}>
		<svg class="border_discard_pc_bar">
			<rect id="border_discard_pc_bar" rx="15" ry="15" x="0" y="0" width="101" height="37">
			</rect>
		</svg>
		<div id="Discard_lbl_pc_">
			<span>Discard</span>
		</div>
	</div>:
	<div id="Group_discard_pc_bar" onClick={()=>handleClose()}>
		<svg class="border_discard_pc_bar">
			<rect id="border_discard_pc_bar" rx="15" ry="15" x="0" y="0" width="101" height="37">
			</rect>
		</svg>
		<div id="Discard_lbl_pc_">
			<span>Cancel</span>
		</div>
	</div>
  }
	<img title="Close" id="close_barg_pc"  onClick={()=>{handleClose()}} src="close2.png" srcset="close2.png 1x, close2@2x.png 2x" />
		
</div>
      </Modal>
    </div>
  )
}

export default BargainPopUpPC
