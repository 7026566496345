import React from 'react';
import "../../../styles/FreshSipMob.css";

function FreshSipMob(props) {
  return (
    <div id="FreshSipHub_Mob">
        <div id="FSH_MoB_30Group_15129">
            <div id="FSH_MoB_30Group_15124">
                <img id="FSH_MoB_30jugoslocos-i8JfQDc4Ha8-unsplas" src="./Themes/FreshSip/jugoslocos-i8JfQDc4Ha8-unsplas.png" srcset="./Themes/FreshSip/jugoslocos-i8JfQDc4Ha8-unsplas.png 1x" />
                    
                <svg class="FSH_MoB_30Rectangle_749">
                    <rect id="FSH_MoB_30Rectangle_749" rx="0" ry="0" x="0" y="0" width="375" height="684">
                    </rect>
                </svg>
            </div>
            <img id="FSH_MoB_30Anime_Edit" src={props.data.imageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(props.data.imageUrl.split('/')[5]):"./Themes/FreshSip/Anime_Edit.png"} />
                
            <div id="FSH_MoB_30Your_Business_Name">
                <span>{props.data.businessName}</span>
            </div>
            <div id="FSH_MoB_30Revitalize_Your_Life_with_the_">
                <span>"Revitalize Your Life with the Freshness <br/>of Nature's Bounty – <br/>One Juice at a Time!"</span>
            </div>
            <div id="FSH_MoB_30Group_15113">
                <svg class="FSH_MoB_30Rectangle_3">
                    <rect id="FSH_MoB_30Rectangle_3" rx="24.5" ry="24.5" x="0" y="0" width="177" height="49">
                    </rect>
                </svg>
                <div id="FSH_MoB_30Available_Now">
                    <span>Available Now</span>
                </div>
            </div>
        </div>
        <div id="FSH_MoB_30Group_15134">
            <div id="FSH_MoB_30Group_15126">
                <img id="FSH_MoB_30tangerine-newt-hINQgaTqg7Q-uns" src="./Themes/FreshSip/tangerine-newt-hINQgaTqg7Q-uns.png" srcset="./Themes/FreshSip/tangerine-newt-hINQgaTqg7Q-uns.png 1x"/>
                    
                <div id="FSH_MoB_30Scroll_Group_28">
                    <div id="FSH_MoB_30It_is_a_long_established_fact_">
                        <span>{props.data.vision}</span>
                    </div>
                </div>
                <div id="FSH_MoB_30VISION">
                    <span>VISION</span>
                </div>
            </div>
            <div id="FSH_MoB_30Group_15130">
                <img id="FSH_MoB_30whitney-wright-TgQkxQc-t_U-uns" src="./Themes/FreshSip/whitney-wright-TgQkxQc-t_U-uns.png" srcset="./Themes/FreshSip/whitney-wright-TgQkxQc-t_U-uns.png 1x" />
                    
                <div id="FSH_MoB_30Scroll_Group_28_bb">
                    <div id="FSH_MoB_30It_is_a_long_established_fact__bc">
                        <span>{props.data.mission}</span>
                    </div>
                </div>
                <div id="FSH_MoB_30MISSION">
                    <span>MISSION</span>
                </div>
            </div>
            <div id="FSH_MoB_30Group_15131">
                <img id="FSH_MoB_30alina-karpenko-jH1PBHevj38-uns" src="./Themes/FreshSip/alina-karpenko-jH1PBHevj38-uns.png" srcset="./Themes/FreshSip/alina-karpenko-jH1PBHevj38-uns.png 1x" />
                    
                <div id="FSH_MoB_30Scroll_Group_28_bg">
                    <div id="FSH_MoB_30It_is_a_long_established_fact__bh">
                        <span>{props.data.principles}</span>
                    </div>
                </div>
                <div id="FSH_MoB_30PRINCIPLES">
                    <span>PRINCIPLES</span>
                </div>
            </div>
        </div>
        <div id="FSH_MoB_30Group_15133">
            <div id="FSH_MoB_30Our_Greatest_Drinks">
                <span>Our Greatest Drinks</span>
            </div>
            <div id="FSH_MoB_30Scroll_Group_90">
	{props.offeringsData.map((off,idx)=>(
                <div id="FSH_MoB_30Group_15128">
                    <svg class="FSH_MoB_30Rectangle_750_bo">
                        <linearGradient id="FSH_MoB_30Rectangle_750_bo" spreadMethod="pad" x1="-0.871" x2="1" y1="-0.401" y2="1">
                            <stop offset="0" stop-color="#FSH_MoB_30eba163" stop-opacity="1"></stop>
                            <stop offset="1" stop-color="#FSH_MoB_30fff" stop-opacity="1"></stop>
                        </linearGradient>
                        <rect id="FSH_MoB_30Rectangle_750_bo" rx="7" ry="7" x="0" y="0" width="203" height="254">
                        </rect>
                    </svg>
                    <img id="FSH_MoB_30sam-hojati-lw8GflbJwLc-unsplas" src={off.defaultOfferingsImageUrl?process.env.REACT_APP_GOOGLEDRIVE_VIEW_IMG+(off.defaultOfferingsImageUrl.split('/')[5]):"./Themes/FreshSip/sam-hojati-lw8GflbJwLc-unsplas.png"} />
                        
                    <div id="FSH_MoB_30Juice_1">
                        <span>{off.offeringTitle}</span>
                    </div>
                </div>))}
            </div>
        </div>
        <div id="FSH_MoB_30Group_15132">
            <div id="FSH_MoB_30Group_15040">
                <iframe src={"https://maps.google.com/maps?width=100%25&height=600&hl=en&q="+((props.data.mapLatitude && (props.data.mapLatitude.toString()).length > 0)?props.data.mapLatitude.toString():"13.027696798394835")+","+((props.data.mapLongitude && (props.data.mapLongitude.toString()).length > 0)?props.data.mapLongitude.toString():"77.61844225211368")+"&t=&z=20&ie=UTF8&iwloc=B&output=embed&embedded=true;"} width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="FSH_MoB_30Rectangle_737">
                    <rect id="FSH_MoB_30Rectangle_737" rx="0" ry="0" x="0" y="0" width="281" height="158">
                    </rect>
                </iframe>
            </div>
            <div id="FSH_MoB_30Group_15050">
                <div id="FSH_MoB_30socials_grp_shadow_space_websi">
		{props.data.linkedInUrl?
                    <div title="LinkedIn" id="FSH_MoB_30linked_in_grp_shadow_space_web">
                        <svg class="FSH_MoB_30med_shadow_space_website_7">
                            <ellipse id="FSH_MoB_30med_shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
                        <div id="FSH_MoB_30linked_in_shadow_space_website">
					<a href={props.data.linkedInUrl} target="_blank">
                            <svg class="FSH_MoB_30n_website_7_layout_" viewBox="-15.255 0 15.439 14.566">
                                <path id="FSH_MoB_30n_website_7_layout_" d="M -5.594671249389648 3.814697265625e-06 C -8.203055381774902 3.814697265625e-06 -9.837490081787109 1.412798166275024 -10.13955497741699 2.402577638626099 L -10.13955497741699 0.1386871188879013 L -15.2549991607666 0.1386871188879013 C -15.18850803375244 1.3406081199646 -15.2549991607666 14.56553649902344 -15.2549991607666 14.56553649902344 L -10.13955497741699 14.56553649902344 L -10.13955497741699 6.758750915527344 C -10.13955497741699 6.324968814849854 -10.16045379638672 5.891188621520996 -10.02873420715332 5.579627513885498 C -9.676643371582031 4.71206521987915 -8.921167373657227 3.811574220657349 -7.562833309173584 3.811574220657349 C -5.78718090057373 3.811574220657349 -4.979146957397461 5.145845413208008 -4.979146957397461 7.100076198577881 L -4.979146957397461 14.56553649902344 L 0.1837937831878662 14.56553649902344 L 0.1837937831878662 6.543442726135254 C 0.1837937831878662 2.075184345245361 -2.358731746673584 3.814697265625e-06 -5.594671249389648 3.814697265625e-06">
                                </path>
                            </svg>
                        </a>
                            <svg class="FSH_MoB_30linked_in_i_shadow_space_websi" viewBox="0.931 -22.783 4.553 14.427">
                                <path id="FSH_MoB_30linked_in_i_shadow_space_websi" d="M 0.9310000538825989 -8.35551643371582 L 5.484113693237305 -8.35551643371582 L 5.484113693237305 -22.78300476074219 L 0.9310000538825989 -22.78300476074219 L 0.9310000538825989 -8.35551643371582 Z">
                                </path>
                            </svg>
                            <svg class="FSH_MoB_30top_dot_i_shadow_space_website" viewBox="-4.392 0 5.527 5.006">
                                <path id="FSH_MoB_30top_dot_i_shadow_space_website" d="M -1.610737085342407 0 C -3.292033195495605 0 -4.392000198364258 1.079702377319336 -4.392000198364258 2.505163192749023 C -4.392000198364258 3.902759790420532 -3.323695659637451 5.005893707275391 -1.675329208374023 5.005893707275391 L -1.642399907112122 5.005893707275391 C 0.06929254531860352 5.005893707275391 1.135063052177429 3.898960590362549 1.135063052177429 2.50072979927063 C 1.10213315486908 1.077169299125671 0.06929254531860352 0 -1.610737085342407 0">
                                </path>
                            </svg>
                        </div>
                    </div>:""}
			{props.data.instagramUrl?	
                    <div title="Instagram" id="FSH_MoB_30Insta_grp_shadow_space_website">
                        <svg class="FSH_MoB_30shadow_space_website_7">
                            <ellipse id="FSH_MoB_30shadow_space_website_7" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
				<a href={props.data.instagramUrl} target="_blank">
                        <img id="FSH_MoB_30instagramshadow_space_website_" src="./Themes/FreshSip/instagramshadow_space_website_.png" srcset="./Themes/FreshSip/instagramshadow_space_website_.png 1x" />
                            
                        </a>
                    </div>:""}
			{props.data.whatsAppNumber?
                    <div title="WhatsApp" id="FSH_MoB_30whatsapp_grp_shadow_space_webs">
                        <svg class="FSH_MoB_30med_shadow_space_website_7_b">
                            <ellipse id="FSH_MoB_30med_shadow_space_website_7_b" rx="24" ry="24" cx="24" cy="24">
                            </ellipse>
                        </svg>
                        <a href={"https://api.whatsapp.com/send?phone="+props.data.whatsAppNumber.substring(props.data.whatsAppNumber.length-10)} target="_blank">
				<img id="FSH_MoB_30whatsapp_lbl_shadow_space_webs" src="./Themes/FreshSip/whatsapp_lbl_shadow_space_webs.png" srcset="./Themes/FreshSip/whatsapp_lbl_shadow_space_webs.png 1x" />
                        </a>    
                    </div>:""}
                </div>
                <div id="FSH_MoB_30n_234567890_mobile_number_shad">
                    <span>{props.data.businessPhone}</span>
                </div>
                <div id="FSH_MoB_30affanghanioperatelive_email_sh">
                    <span>{props.data.businessEmail}</span>
                </div>
                <div id="FSH_MoB_30address_med_shadow_space_websi">
                    <span>{props.data.businessAddress}</span>
                </div>
                <img id="FSH_MoB_30location_img_shadow_space_webs" src="./Themes/FreshSip/location_img_shadow_space_webs.png" srcset="./Themes/FreshSip/location_img_shadow_space_webs.png 1x" />
                    
                <img id="FSH_MoB_30email_address_shadow_space_web" src="./Themes/FreshSip/email_address_shadow_space_web.png" srcset="./Themes/FreshSip/email_address_shadow_space_web.png 1x" />
                    
                <img id="FSH_MoB_30phone_number_shadow_space_webs" src="./Themes/FreshSip/phone_number_shadow_space_webs.png" srcset="./Themes/FreshSip/phone_number_shadow_space_webs.png 1x" />
                    
                <div id="FSH_MoB_30n_Copyright_Operate_Live_OPC_P">
                    <span>© Copyright Operate Live (OPC) Private Limited.<br/> All Rights Reserved</span>
                </div>
                <div id="FSH_MoB_30Contact_Us">
                    <span>Contact Us!</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FreshSipMob