import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
 import { DataGrid, GridToolbar} from '@mui/x-data-grid'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import '../../styles/CommunityOfferingsMobUI.css';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import Currencies from "../Currencies.json";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BargainPopUpPC from '../BargainPopUpPC';

import OfferingsViewMorePopUp from '../OfferingsViewMorePopUp';
import TrophiesAndCountMob from './TrophiesAndCountMob';
import MyPortfolioSmallOffCardMob from '../PC/MyPortfolioSmallOffCardMob';
import MyPortfolioBigOffCardMob from './MyPortfolioBigOffCardMob';
import MyPortfolioOffCardsMob from './MyPortfolioOffCardsMob';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style4 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const style5 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const style7 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const style6 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 600,
  maxWidth: 1200,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

}; 

const CommunityOfferingsMobUI = (props) => {

  //usestate
  var navigate = useNavigate();
    const [jobsData,setJobsData]=useState([]);
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [mobile, setMobile] = useState("");
   
    const [open, setOpen] = React.useState(false);
    const [openApplicant, setOpenApplicant] = React.useState(false);
   
    const [jobId, setJobId] = React.useState();
    const [jobTitle, setJobTitle] = React.useState("");
    const [openGridModel,setOpenGridModel] = useState(false);
   
    const [offeredCurrency,setOfferedCurrency] = useState("");
    const [openBargainModel,setOpenBargainModel] = useState(false);
    const [openMeetingModel,setOpenMeetingModel] = useState(false);
    const [status,setStatus] = useState([]);
    const [biddings,setBiddings] = useState("");
    const [deliveryDeadline, setDeliveryDeadline] = useState(new Date().toISOString());
    const [deliveryLocation, setDeliveryLocation] = useState();
    const [description, setDescription] = useState("");
    const [services, setServices] = useState("");
    const [budget,setBudget] = useState(0);
    const [remarks,setRemarks] = useState("");
    const [needToBeDelivered,setNeedToBeDelivered]= useState(false);
    const [companyName, setCompanyName] = useState("");
    const [hours, setHours]= useState("hours");
    const [bargainedTime,setBargainedTime] = useState("");
    const [bargainedAmount,setBargainedAmount] = useState("");
  
   const [requirementId,setRequirementId]=useState("");
   const [selectedBargain,setSelectedBargain]=useState("");
    const [offeringId,setOfferingId]=useState("");

    const [title,setTitle]= useState("");
    const [createdById,setCreatedById]=useState("");
    const [createdByName,setCreatedByName]=useState("");
  
    const [bargainedCurrency,setBargainedCurrency] = useState("")
   
    const [searchParams] = useSearchParams();
    const [offerings,setOfferings]=useState([]);

    const { user, setUser} = useContext(UserContext);
    const[isViewMoreShown,setViewMoreShown]=useState(false)
    const[isViewMore,setViewMore]=useState(false)
    const [meetingTitle,setMeetingTitle] = useState("");
    const [agenda,setAgenda] = useState("");
    const [meetingRemarks,setMeetingRemarks] = useState("");
    const [ requirementTitle,setRequirementTitle] = useState("");
    const [meetingDateTime,setMeetingDateTime] = useState(new Date().toISOString().substring(0,16));
    const [previousRemarks,setPreviousRemarks] = useState("");
    const [customerId,setCustomerId] = useState("");
    const [customerName,setCustomerName] = useState("");
    const [bargainedId,setBargainedId] = useState();
    const [offeringTime,setOfferingTime] = useState("")
    const [offHours,setOffHours] = useState("hours")
    const [ selectedCommunity,setSelectedCommunity] = useState([])
    const [communities,setCommunities] = useState([]);
    const [selectedCommunities,setSelectedCommunities] = useState([]);
    const [viewMorePopup,setViewMorePopup] = useState(false);
    const [viewMoreData,setViewMoreData] = useState(false);


    //UseEffect
    useEffect(()=>{
        if(user){
          getStatus()
          getOfferings()
        }
       
        
    },[user])
      
    useEffect(()=>{
        cleartext()
    },[openApplicant])
      

    var cols = [
      {field:'id', headerName: 'id', hide: true },
      {field:'offeringsId', headerName: 'reqId',hide: true  },
      {field:'accept', headerName: 'Accept',width: 60, renderCell: (params) =>{
        return[
         <div>
       {params.row.status==="Rejected" ? "": <CheckIcon style={{color:params.row && params.row.status==="Accepted"?"green":""}} onClick={()=>{updateStatus(params.row,"Accepted")}}/>}
        </div>
        ];},},  
        {field:'reject', headerName: 'Reject',width: 60, renderCell: (params) =>{
          return[
           <div>
         {params.row.status==="Accepted" ? "":  <ClearIcon  style={{color:params.row && params.row.status==="Rejected"?"red":""}} onClick={()=>{updateStatus(params.row,"Rejected")}}/>}
          </div>
          ];},}, 
          {field:'meeting', headerName: 'Meeting',width: 70, renderCell: (params) =>{
            return[
             <div>
            <CalendarMonthIcon onClick={()=>{openScheduleModel(params.row)}}/>
            </div>
            ];},},
      {field:'offeringsTitle', headerName: 'Title',width: 200,  },
      {field:'bargainedAmount', headerName: 'Amount' ,width: 100, },      
      {field:'bargainedHours', headerName: 'Hours' ,width: 100, },      
      {field:'needToBeDelivered', headerName: 'Need To Be Delivered' ,width: 150,},      
      {field:'customerName', headerName: 'Customer Name' ,width: 150, },      
      {field:'remarks', headerName: 'Remarks' ,width: 400, },      
      {field:'customerid', headerName: 'Customer ID',hide: true,width: 100,},      
     
  ];

 


  const addBid=(id,title,vendorId,vendorName)=>{
    setSelectedBargain({id,title,vendorId,vendorName})
    setOfferingId(id);
    setTitle(title);
    setCreatedById(vendorId);
    setCreatedByName(vendorName);
    getBargains(id)
    setOpenBargainModel(true);

    }

    const setCloseGridModel = ()=>{
      setOpenGridModel(false);
  }

  const openScheduleModel = (row) =>{
    console.log(row)
    //  setOfferingsId(row.offeringsId);
     setMeetingTitle(row.offeringsTitle);
     setCustomerId(row.customerid);
     setCustomerName(row.customerName)
    setOpenMeetingModel(true);
  }


  //get Bargains of comunity
  const getBargains = (id)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/bargain/getbargain/"+user._id; 
    let apiParams = {
      headers:{
        offeringsid:id,
    }}
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
            console.log(repos.data)
           setBargainedId(repos.data[0] && repos.data[0]._id)
           setBargainedAmount(repos.data[0] && repos.data[0].bargainedAmount);
           setBargainedCurrency(repos.data[0] && repos.data[0].bargainedCurrency ? JSON.parse(repos.data[0].bargainedCurrency).cc:"INR");
           setBargainedTime(repos.data[0] && repos.data[0].bargainedHours);
           setNeedToBeDelivered(repos.data[0] && repos.data[0].needToBeDelivered);
           setRemarks(repos.data[0] && repos.data[0].remarks);
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
  
  const updateStatus = (row,status)=>{

    let apiURL=process.env.REACT_APP_API_URL + "/bargain/updatebargains/"+row.id;
    let apiParams = {
        method:"POST",
        headers:{
          userid:user._id,
          username:user.name,
        status:status,
  
        },
       
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
         
          alert("Offering Status Updated Successfully!!");
          
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
    }
    
 const closeGridModel = () => setOpenGridModel(false);

    const addBargains = ()=>{
      
      setOpenBargainModel(false)
       let BargainedTime=0;
       if(hours==="hours"){
        BargainedTime = bargainedTime;
       }else{
        BargainedTime = bargainedTime*8;
       }
       let apiURL=process.env.REACT_APP_API_URL + "/bargain/addbargains";
       let apiParams = {
           method:"POST",
           headers:{
            offeringtitle:title,
             offeringid:offeringId,
             vendorid:createdById,
             vendorname:createdByName,
             bargainedamount:bargainedAmount,
             bargainedcurrency:encodeURIComponent(JSON.stringify(bargainedCurrency)),
             bargainedhours:BargainedTime,
             needtobedelivered:needToBeDelivered,
             remarks:encodeURIComponent(remarks),
             customerid:user._id,
             customername:user.name,
             status:"Submitted"
           },
          
       };
       fetch(apiURL, apiParams)
       .then((res)=> (res.status===200 ? res.json() : res.text()))
       .then((repos)=> {
           if (repos.data) {
             cleartext()
             alert("Bargained Added Successfully");
             
            setTimeout(() => {
              getOfferings()
           }, 1500);
           
           }else alert(repos.message || repos);
       }) 
       .catch((err) => alert(err));
   }

   const updateBargain = (id)=>{
   
    setOpenBargainModel(false)
       let BargainedTime=0;
       if(hours==="hours"){
        BargainedTime = bargainedTime;
       }else{
        BargainedTime = bargainedTime*8;
       }
       let apiURL=process.env.REACT_APP_API_URL + "/bargain/updatebargainsdata/"+id;
       let apiParams = {
           method:"POST",
           headers:{
           
             bargainedamount:bargainedAmount,
             bargainedcurrency:encodeURIComponent(JSON.stringify(Currencies.filter(curr=>curr.cc===bargainedCurrency)[0])),
             bargainedhours:BargainedTime,
             needtobedelivered:needToBeDelivered,
             remarks:encodeURIComponent(remarks),
             status:"Submitted"
           },
          
       };
       fetch(apiURL, apiParams)
       .then((res)=> (res.status===200 ? res.json() : res.text()))
       .then((repos)=> {
           if (repos.data) {

             alert("Bargained Updated Successfully");
             
          //   setTimeout(() => {
          //     getOfferings()
          //  }, 1500);
           
           }else alert(repos.message || repos);
       }) 
       .catch((err) => alert(err));
   }
   
   const cancelBargain = (id,status)=>{
    
    let apiURL=process.env.REACT_APP_API_URL + "/bargain/updatebargains/"+id;
    let apiParams = {
        method:"POST",
        headers:{ 
        status:status,
        },
       
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
         
          alert("Offering Status Updated Successfully!!");
          
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));
  
   }

   const getStatus = ()=>{
 
    let apiURL = process.env.REACT_APP_API_URL + "/connection/getstatus/" + user._id;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
            console.log(repos.data)
            setStatus(repos.data)
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };
   
 const closeModel = () => setOpenBargainModel(false);

   
 const handleClose = () => {
    setOpenApplicant(false); 
}


 const handleView = () => {

  //  setOpenView(true);
}

const ScheduleMeeting = () =>{
  if(meetingTitle ===""||undefined)
  {
    return alert("Please Enter Meeting Title !!")
  }else if(agenda===""||undefined){
    return alert("Please Enter Agenda Of Meeting !!")
  }
  setOpenMeetingModel(false)
  let apiURL=process.env.REACT_APP_API_URL + "/meetings/schedulemeetings";
    let apiParams = {
        method:"POST",
        headers:{
          // requirementtitle:requirementTitle,
          // requirementid:requirementId,
          requesterid:user._id,
          requestername:user.name,
          respondentid:customerId,
          respondentname:customerName,
          meetingtitle:meetingTitle,
          meetingdatetime:meetingDateTime,
          agenda:encodeURIComponent(agenda),
          remarks:encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+meetingRemarks),
          status:"Requested",



        },
       
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        
          alert("Meeting Scheduled Successfully");
          
         setTimeout(() => {
          getOfferings()
        }, 1500);
        
        }else alert(repos.message || repos);
    }) 
    .catch((err) => alert(err));

 }

   //Get my Communities
   const getCommunities = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/community/getmycommunity/"+user._id; 
  
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
            // console.log(repos.data) 
          setCommunities(repos.data)
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }

  
//Get applicant by id
  const getOfferings = ()=>{
       
    let apiURL=process.env.REACT_APP_API_URL + "/offerings/getallofferingsbyid/" +user._id ;
    let apiParams = {
        method:"GET",
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
    console.log(repos.data)
        setOfferings(repos.data)
         
        
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
 

  const cleartext = ()=>{
    setServices("");
    setDescription("");
    setDeliveryLocation("");
    setOfferingTime("");
    setBudget("");
    setOfferedCurrency("")
  }


//Add Jobs
const addOffering = () =>{
  setOpenApplicant(true)
  getCommunities()
}


const addOfferings = ()=>{
    setOpenApplicant(false)
    let OfferingTime=0;
    if(offHours==="hours"){
      OfferingTime = offeringTime;
    }else{
      OfferingTime = offeringTime*8;
    }
  let apiURL=process.env.REACT_APP_API_URL + "/offerings/addoffering";
  let apiParams = {
      method:"POST",
      headers:{
        offeringtitle:services,
        budget:budget,
        offeredcurrency:encodeURIComponent(JSON.stringify(offeredCurrency)),
        deliverylocation:encodeURIComponent(deliveryLocation),
        description:encodeURIComponent(description),
        selectedcommunity:encodeURIComponent(JSON.stringify(selectedCommunity)),
        deliveryduration:OfferingTime,
        vendorid:user._id,
        vendorname:user.name,

      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        cleartext();
        alert("Offerings Added Successfully");
      
    //    props.showAlert("Job Added Successfully","success")
       setTimeout(() => {
        getOfferings()
      }, 1500);
      
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
}

const showGrid = (id)=>{
    
  getAllBiddings(id);
  setOpenGridModel(true)
}   

const closeMeetingModel = () => setOpenMeetingModel(false);

const setCloseMeetingModel = ()=>{
  setOpenMeetingModel(false);
}

const getAllBiddings = (offeringId)=>{

  let apiURL=process.env.REACT_APP_API_URL + "/bargain/getallbargains/"+offeringId; 

  fetch(apiURL)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
          console.log(repos.data)
        setBiddings(repos.data)
       
      }else alert(repos.message || repos);  
  }) 
  .catch((err) => alert(err));
}


//Disable Job
 const updateIsActive = (id) =>{

  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
  let apiURL=process.env.REACT_APP_API_URL + "/offerings/updateofferingstatus/" +id;
  let apiParams = {
    method:"POST",
    headers:{
      active:false
    },
 };

 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
       alert("Offering Removed Successfully");
      // props.showAlert("Job Removed Successfully","success")
      setTimeout(() => {
        getOfferings()
      }, 2000);
     }else alert(repos.message || repos);
 }) 
 .catch((err) => alert(err));

}

  const setClose = ()=>{
    setOpenApplicant(false)
}

const openViewMorePopUp = (rec)=>{
  setViewMoreData(rec)
  setViewMorePopup(true)
}



// const rowData = [];      

const rowData = (biddings||[]).map((bid) => {
       
  return{
 
  id: bid._id,
  offeringsId:bid.offeringsId,
  offeringsTitle:bid.offeringsTitle,
  bargainedAmount:bid.bargainedAmount,
  bargainedHours:bid.bargainedHours,
  needToBeDelivered:bid.needToBeDelivered,
  customerName:bid.customerName,
  customerid:bid.customerId,
  remarks:bid.remarks,
  status:bid.status,
  };
});


  return (
    <div>
  <div id="Com_OFFM_off_mob">
	<div id="Com_OFFM_Group_128">
		<svg class="Com_OFFM_desgin_3_com_off">
			<rect id="Com_OFFM_desgin_3_com_off" rx="0" ry="0" x="0" y="0" width="100%" height="207">
			</rect>
		</svg>
		<div id="Com_OFFM_recently_added_com_off">
			<span>Recently Added by You</span>
		</div>
		<div id="Com_OFFM_explore_aws_com_off">
			<span>Explore Awesome OFFERINGS</span>
		</div>

		<div id="Com_OFFM_Scroll_Group_6">
    {offerings.filter(off=>off.vendorId !== user._id).map((off,idx)=>(<div onClick={(e)=>(e.target.id==="OFFM_online-shopping_reqoffeven") && props.offeringSelected(off._id)}>
			<MyPortfolioOffCardsMob idx={idx} data={off} reload={()=>getOfferings()}/>
      </div>
      ))}
		</div>

	</div>

	<div id="Com_OFFM_Group_127">
		<img id="Com_OFFM_desgin_2_ocom_off" src="./MyPortfolio/Offerings/desgin_2_offreqevent_off_PC.png" srcset="./MyPortfolio/Offerings/desgin_2_offreqevent_off_PC.png 1x" />
			
		<svg class="Com_OFFM_design_1_co_off">
			<rect id="Com_OFFM_design_1_co_off" rx="0" ry="0" x="0" y="0" width="100%" height="99">
			</rect>
		</svg>
		<div id="Com_OFFM_And_then_there_was_offreq">
			<span>Check Out the Trending Offerings</span>
		</div>
	</div>
	<div id="Com_OFFM_trwnding_this_week_offreqevent">
		<span>Trending This Week</span>
	</div>
	<div id="Com_OFFM_top_product_offreqevent">
		<span>TOP PRODUCTS</span>
	</div>
	<div id="Com_OFFM_Scroll_Group_7">
  
		<div id="Com_OFFM_n__card_grp_com_off_off">

    {offerings.filter(off=>off.vendorId !== user._id).map((off,idx)=>
{
if((idx+1)%5===0){ {
       return (
        <table style={{width:"100%"}}>
        <tr><td  onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx-4]._id)}><MyPortfolioSmallOffCardMob data={offerings.filter(off=>off.vendorId !== user._id)[idx-4]} reload={()=>getOfferings()}/></td><td  onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx-3]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx-3]} reload={()=>getOfferings()}/></td></tr>
        <tr><td onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx-1]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx-1]} reload={()=>getOfferings()}/></td><td onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx]} reload={()=>getOfferings()}/></td></tr>
        <tr><td colSpan="2" onClick={(e)=>e.target.id==="OFFM_Image_trending__off_" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx-2]._id)}><MyPortfolioBigOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx-2]} reload={()=>getOfferings()}/></td></tr>
        </table>
        
       )
      }}
      
      if(idx === offerings.filter(off=>off.vendorId !== user._id).length - 1)
      {
      
        switch (offerings.filter(off=>off.vendorId !== user._id).length%5)
{
  case 1:
    return  <table style={{width:"100%"}}>
        <tr><td onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx]} reload={()=>getOfferings()}/></td><td></td></tr>
        <tr><td></td><td></td></tr>
        <tr><td colSpan="2" ></td></tr>
        </table>
        
  break;
  case 2:
    return   <table style={{width:"100%"}}>
        <tr><td onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx-1]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx-1]} reload={()=>getOfferings()}/></td><td onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx]} reload={()=>getOfferings()}/></td></tr>
        <tr><td></td><td></td></tr>
        <tr><td colSpan="2" ></td></tr>
        </table>
  break;
  case 3:
    return  <table style={{width:"100%"}}>
        <tr><td onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx-2]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx-2]} reload={()=>getOfferings()}/></td><td onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx-1]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx-1]} reload={()=>getOfferings()}/></td></tr>
        <tr><td onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" &&  props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx]} reload={()=>getOfferings()}/></td><td></td></tr>
        <tr><td colSpan="2"></td></tr>
        </table>
  break;
  case 4:
   return <table style={{width:"100%"}}>
        <tr><td onClick={(e)=>e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx-3]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx-3]} reload={()=>getOfferings()}/></td><td onClick={(e)=> e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx-2]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx-2]} reload={()=>getOfferings()}/></td></tr>
        <tr><td onClick={(e)=>e.target.id ==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx-1]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx-1]} reload={()=>getOfferings()}/></td><td onClick={(e)=> e.target.id==="OFFM_Image_small_4_trendin" && props.offeringSelected(offerings.filter(off=>off.vendorId !== user._id)[idx]._id)}><MyPortfolioSmallOffCardMob  data={offerings.filter(off=>off.vendorId !== user._id)[idx]} reload={()=>getOfferings()}/></td></tr>
        </table>
  break;
}
    }      

      })}
     
		</div>
	</div>
</div>

   <BargainPopUpPC  openBarPopUp={openBargainModel} selectedBargain={selectedBargain} closeBarPopUp={()=>{setOpenBargainModel(false);}}/>

   {/* View More Pop Up */}

<OfferingsViewMorePopUp type="CO" openViewMore={viewMorePopup} viewMoreData={viewMoreData} closeViewMore={()=>setViewMorePopup(false)}/>

 </div>
  
  );
}

export default CommunityOfferingsMobUI
